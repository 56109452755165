import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const FacebookCovermakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Facebook Cover Maker - Create Custom Facebook Covers"
        description="Create custom Facebook Covers with our Facebook Cover Maker App and photo editor"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/1facebook_cover_maker_app"
        keywords="Facebook cover photos, create Facebook cover, Facebook cover photo size"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Facebook Cover Maker</p>
          <p className="desc">
            First impressions count. piZap’s Facebook Cover Maker can help!
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/facebook_cover_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/facebook_cover_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Facebook Cover Maker</span>
            <span className="desc">
              How many times have you set a Facebook cover photo, thought it
              looked good, and then later realized it was a misaligned mess when
              viewing it from a different device? Never again. Adding a Facebook
              cover photo just got a lot easier thanks to piZap’s Facebook Cover
              Maker tool.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Facebook Cover Maker Overview</span>
            <span className="desc">
              Ever wonder why so many people (maybe even you) have a headless
              Facebook cover photo or some other poor crop job no one would
              willingly pick? Facebook cover photo size is 851 x 315 pixels. If
              you upload a photo that doesn’t fit these parameters you can end
              up with a cropped mess. piZap’s Facebook Cover Maker ensures this
              embarrassing issue never befalls your page again. piZap is more
              than just a Facebook cover maker; it’s a graphic design maker and
              collage maker, with photo editing tools to boot. Easily pair
              features to create a picture-perfect Facebook cover.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/facebook_cover_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/facebook_cover_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Create Facebook Covers</span>
            <span className="desc">
              The premise behind piZap is to make it easy for everyone to create
              and share beautiful photos without any previous design or editing
              experience. This same ideology went into the creation of the
              Facebook Cover Maker tool. At long last, you can cut down the time
              and energy it takes to post a Facebook cover photo that looks
              great on all screen sizes. To make things even easier, you can
              post completed cover photos directly to Facebook from inside the
              piZap app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Facebook Cover Maker </span>
            <span className="desc">
              Sharing things on social media is fun. In proof, millions and
              billions of posts hit the Internet each day. What’s not fun is
              sharing something that doesn’t look so good… like an
              inappropriately cropped Facebook cover photo. What’s equally
              un-fun is fussing with the same cover photo for hours to no avail.
              Let piZap put the fun back in sharing your favorite photos.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/facebook_cover_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/facebook_cover_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>One App, Countless Features </h3>
            </span>
            <span className="desc">
              piZap is no one-trick pony! Rest assured, piZap has far more to
              offer because let’s be real, how many Facebook covers can you
              really make? Add to your Facebook in other ways by making a
              collage or using piZap’s MEME maker to put your followers into
              fits of laughter. And, if any of your pictures need touch ups,
              cropping, filter changes, or other edits, you’re one-click away
              from piZap’s photo editing tools.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default FacebookCovermakerPage;
