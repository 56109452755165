import React, { useEffect, useState, useMemo } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import LoginMain from "./LoginMain.react";
import LoginRegister from "./LoginRegister.react";
import { useNavigate } from "react-router-dom";
import HelmetDetails from "./HelmetDetails";

function getStateFromStores() {
  return {
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    lastLogins: UserStore.getLastLogins(),
  };
}

const SignupPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    if (UserStore.getUser()) {
      navigate("/app/start");
    } else {
      UserStore.addChangeListener(_onChange);
      if (UserStore.getUser() === null) {
        UserActionCreators.updateUserData(UserStore.getAccessToken());
      }

      return () => {
        UserStore.removeChangeListener(_onChange);
      };
    }
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const logUserIn = (e) => {
    let userName = e.currentTarget.attributes["datakey"].value;
    if (userName) {
      for (let x = 0; x < data.lastLogins.length; x++) {
        if (data.lastLogins[x].user.UserName === userName) {
          UserActionCreators.userLoggedIn(data.lastLogins[x]);
        }
      }
    }
  };

  let lastLoginsComp = null;
  if (data.lastLogins) {
    lastLoginsComp = data.lastLogins.map((item, index) => {
      let name = item.user.Name || item.user.UserName;
      let gplus = null;
      let twitter = null;
      let fb = null;
      let pro = null;
      if (item.user.FBUserId) {
        fb = <i className="icon-facebook-light"></i>;
      }
      if (item.user.GooglePlusUserId) {
        gplus = <i className="icon-gplus-light"></i>;
      }
      if (item.user.TwitterUserId) {
        twitter = <i className="icon-twitter-light"></i>;
      }
      if (item.user.isPRO) {
        pro = (
          <img
            className="recentLoginPro"
            src="https://static.pizap.com/html5/resources/editor/pizap_pro.svg"
            alt="piZap pro logo"
          />
        );
      }
      return (
        <div
          className="recentLogin clickable"
          key={index}
          onClick={logUserIn}
          datakey={item.user.UserName}
        >
          <img className="user-img" src={item.user.ProfileImage} alt="" />

          <div className="recentLoginDetails">
            <div>{name}</div>
            <div className="recentEmail">{item.user.Email}</div>
            <div>
              {gplus}
              {twitter}
              {fb}
            </div>
          </div>
          {pro}
        </div>
      );
    });
  }
  let lastLoginColumn = null;
  if (lastLoginsComp) {
    lastLoginColumn = (
      <div className="loginColumns">
        <div className="loginHeading">Recent logins</div>
        <div className="loginSelectAccount">Select your account</div>
        <div className="recentLoginContainer">{lastLoginsComp}</div>
      </div>
    );
  }

  // let comp = <LoginMain />;

  // if (UserStore.getLoginMode() === "register") {
  //   comp = <LoginRegister />;
  // }

  // if (UserStore.getLoginMode() === "forgot") {
  //   comp = <LoginForgot />;
  // }

  const comp = useMemo(() => {
    if (UserStore.getLoginMode() === "register") {
      return <LoginRegister />;
    } else {
      return <LoginMain />;
    }
  }, [UserStore.getLoginMode()]);

  return (
    <div className="loginPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app/signup"
        keywords=""
      />
      <div className="colorBarContainer"></div>
      <div className="loginContainer">
        <div className="loginColumnsWrapper">
          {lastLoginColumn}
          <div className="loginColumns">
            <div className="loginHeading">Create an account</div>
            {comp}
          </div>
        </div>
      </div>
      <div className="floatLogo" onClick={() => navigate("/")}>
        <img
          className="logo-text"
          src="//cdn.pizap.com/pizapfiles/images/pizap_logo_rounded_2023.510ca6fa.png"
          alt="pizapLogo"
        />
      </div>
    </div>
  );
};

export default SignupPage;
