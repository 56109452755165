import React, { useEffect } from "react";
import UserStore from "../stores/UserStore";
import LoginMain from "./LoginMain.react";
import LoginEmail from "./LoginEmail.react";
import LoginRegister from "./LoginRegister.react";
import LoginForgot from "./LoginForgot.react";

const Login = () => {
  let _isMounted = false;
  useEffect(() => {
    _isMounted = true;
    UserStore.addChangeListener(_onChange);

    return () => {
      _isMounted = false;
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    try {
      if (_isMounted) UserStore.getLoginMode();
    } catch (e) {}
  };

  let comp = <LoginMain />;
  if (UserStore.getLoginMode() === "email") {
    comp = <LoginEmail />;
  }
  if (UserStore.getLoginMode() === "register") {
    comp = <LoginRegister />;
  }
  if (UserStore.getLoginMode() === "forgot") {
    comp = <LoginForgot />;
  }

  if (UserStore.getLoginMode() === "save") {
    comp = (
      <div className="saveLogin">
        <div className="largeHeading">Create an account</div>
        <div className="normalText">
          You need to create a free account
          <br />
          so you can save your image
        </div>
        <LoginMain />
      </div>
    );
  }
  return (
    <div className="login-dialog">
      <div className="login-container">
        <div className="login-logo-container">
          <i className="icon icon-pizap"></i>
          <div className="colorBar">
            <div className="orange" />
            <div className="yellow" />
            <div className="green" />
            <div className="lightBlue" />
            <div className="blue" />
          </div>
        </div>
        {comp}
      </div>
    </div>
  );
};

export default Login;
