import React, { useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import JSONUtils from "../utils/JSONUtils";
import EditorStore from "../stores/EditorStore";

const INITIAL_STATE = {
  name: "",
  email: "",
  message: "",
};

const ShareEmail = () => {
  const [data, setData] = useState(INITIAL_STATE);

  const _onCancel = () => {
    EditorActionCreators.ShowEmail(false, null);
  };

  const _onNameChange = (e) => {
    setData((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const _onEmailChange = (e) => {
    setData((prevState) => ({ ...prevState, email: e.target.value }));
  };

  const onMessageChange = (e) => {
    setData((prevState) => ({
      ...prevState,
      message: e.target.value,
    }));
  };

  const _onSend = () => {
    const myData =
      "recipName=" +
      encodeURIComponent(data.name) +
      "&recipEmail=" +
      encodeURIComponent(data.email) +
      "&recipMessage=" +
      encodeURIComponent(data.message) +
      "&url=" +
      encodeURIComponent(EditorStore.getSavedCloudKey());
    JSONUtils.getJSON(
      "https://www.pizap.com/emailFriend",
      "POST",
      myData,
      function () {
        EditorActionCreators.ShowEmail(false, null);
      }
    );
  };

  return (
    <div className="embedEmail" align="center">
      <div className="photopicker-navbar">
        <div className="photopicker-title">Email this piZap</div>
        <a
          id="save-done-btn"
          className="photopicker-navbar-right-btn bold"
          onClick={_onCancel}
        >
          X
        </a>
      </div>
      <div className="space-between mt-2">
        <span>Name:</span>
        <input
          type="text"
          placeholder="Recipient Name"
          required={true}
          onChange={_onNameChange}
        />
      </div>
      <br />
      <div className="space-between">
        <span>Email: </span>
        <input
          type="email"
          value={data.email}
          placeholder="Recipient Email"
          required={true}
          onChange={(e) => _onEmailChange(e)}
        />
      </div>
      <textarea
        className="mt-2"
        placeholder="Message"
        value={data.message}
        onChange={(e) => onMessageChange(e)}
      ></textarea>
      <button className="sendEmail" onClick={_onSend}>
        Send Email
      </button>
    </div>
  );
};

export default ShareEmail;
