import React, { useEffect, useState, useMemo } from "react";
import ColorPicker from "./ColorPicker.react";
import EditorStore from "../stores/EditorStore";
import EditorActionCreators from "../actions/EditorActionCreators";
import * as PIXI from "pixi.js";
import { ReactComponent as AddImageIcon } from "../images/editor/photo_icon.svg";
import RangeSlider from "./RangeSlider";

function getStateFromStores() {
  return {
    blankColor: EditorStore.getBlankColor(),
    frameColor:
      window.editor.getBaseObject() && window.editor.getBaseObject().color
        ? window.editor.getBaseObject().color
        : 0xffffff,
    frameCorner:
      window.editor.getBaseObject() && window.editor.getBaseObject().corner
        ? window.editor.getBaseObject().corner
        : 0,
    frameSpacing:
      window.editor.getBaseObject() && window.editor.getBaseObject().spacing
        ? window.editor.getBaseObject().spacing
        : 0.04,
    collageBackground: EditorStore.getDesignBackground(),
  };
}

const MenuCollage = () => {
  const [storesData, setStoresData] = useState(getStateFromStores());

  const _onChange = () => {
    setStoresData(getStateFromStores());
  };
  const colorPicked = (colorCode) => {
    let bo = window.editor.getBaseObject();
    bo.color = colorCode.replace("#", "0x");
    _onSpacingChange(bo.spacing);
    bo.beginFill(colorCode.replace("#", "0x"), 1);
    bo.drawRect(0, 0, bo.width, bo.height);
    bo.endFill();
    window.editor.deleteDesignBackground();
    setStoresData(getStateFromStores());
  };

  const _onLayout = (event) => {
    EditorStore.setShowCollagePicker(true);
  };
  const _onSpacingChange = (value) => {
    let mask;
    window.editor.getBaseObject().spacing = value;
    let spacer = parseInt(value, 10);
    for (let x = 0; x < window.editor.getBaseObject().children.length; x++) {
      if (window.editor.getBaseObject().children[x].mask) {
        if (window.editor.getBaseObject().isRectangle) {
          mask = new PIXI.Graphics();
          let rect = window.editor.getBaseObject().children[x].initialRect;
          let rectX = rect.x;
          let rectY = rect.y;
          let rectWidth = rect.width;
          let rectHeight = rect.height;

          if (rect.x === 0) {
            rectX += spacer;
            rectWidth -= spacer * 1.5;
          } else {
            rectX += spacer / 2;
            rectWidth -= spacer;
          }
          if (rect.y === 0) {
            rectY += spacer;
            rectHeight -= spacer * 1.5;
          } else {
            rectY += spacer / 2;
            rectHeight -= spacer;
          }
          if (
            rect.y + rect.height + spacer + 20 >=
            window.editor.getBaseObject().height
          ) {
            rectHeight -= spacer / 2;
          }
          if (
            rect.x + rect.width + spacer + 20 >=
            window.editor.getBaseObject().width
          ) {
            rectWidth -= spacer / 2;
          }
          mask.beginFill(0, 1);
          mask.drawRoundedRect(
            rectX,
            rectY,
            rectWidth,
            rectHeight,
            parseInt(window.editor.getBaseObject().corner, 10)
          );
          mask.endFill();
          window.editor.getBaseObject().children[x].mask = null;
          window.editor.getBaseObject().children[x].mask = mask;
        } else {
          mask = window.editor.getBaseObject().children[x].mask;
          window.editor.getBaseObject().children[x].mask = null;
          window.editor.getBaseObject().children[x].mask = mask;
        }
      }
    }
    setStoresData(getStateFromStores());
    window.editor.autoSave();
  };
  const _onRoundingChange = (value) => {
    window.editor.getBaseObject().corner = value;

    let spacer = parseInt(window.editor.getBaseObject().spacing, 10);
    for (let x = 0; x < window.editor.getBaseObject().children.length; x++) {
      if (window.editor.getBaseObject().children[x].mask) {
        let mask = new PIXI.Graphics();
        let rect = window.editor.getBaseObject().children[x].initialRect;
        let rectX = rect.x;
        let rectY = rect.y;
        let rectWidth = rect.width;
        let rectHeight = rect.height;

        if (rect.x === 0) {
          rectX += spacer;
          rectWidth -= spacer * 1.5;
        } else {
          rectX += spacer / 2;
          rectWidth -= spacer;
        }
        if (rect.y === 0) {
          rectY += spacer;
          rectHeight -= spacer * 1.5;
        } else {
          rectY += spacer / 2;
          rectHeight -= spacer;
        }
        if (
          rect.y + rect.height + spacer + 20 >=
          window.editor.getBaseObject().height
        ) {
          rectHeight -= spacer / 2;
        }
        if (
          rect.x + rect.width + spacer + 20 >=
          window.editor.getBaseObject().width
        ) {
          rectWidth -= spacer / 2;
        }
        mask.beginFill(0, 1);
        mask.drawRoundedRect(
          rectX,
          rectY,
          rectWidth,
          rectHeight,
          parseInt(window.editor.getBaseObject().corner, 10)
        );
        mask.endFill();
        window.editor.getBaseObject().children[x].mask = null;
        window.editor.getBaseObject().children[x].mask = mask;
      }
    }
    setStoresData(getStateFromStores());
    window.editor.autoSave();
  };
  const _onCollageBackground = (event) => {
    EditorActionCreators.ShowFilePicker(true, "collageBackground");
  };
  const _onCollageBackgroundRemove = (event) => {
    window.editor.deleteDesignBackground();
    window.editor.autoSave();
  };

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const backgroundImage = useMemo(() => {
    if (
      window.editor.getBaseObject() &&
      window.editor.getBaseObject().overlayCollage
    ) {
      return null;
    }
    if (storesData.collageBackground) {
      return (
        <div
          className="delete-background-image clickable"
          onClick={_onCollageBackgroundRemove}
          title="Delete Background Image"
        >
          <AddImageIcon className="add-background-image" width={20} />
          <span>Delete Background Image</span>
        </div>
      );
    } else {
      return (
        <div
          className="background-image my-btn clickable"
          onClick={_onCollageBackground}
          title="Add Background Image"
        >
          <AddImageIcon className="add-background-image" width={20} />
          <span>Add background image</span>
        </div>
      );
    }
  }, [storesData.collageBackground, window.editor.getBaseObject()]);

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        <span>Edit Collage</span>
      </div>
      <div className="textTypeContainer">
        <div className="space-between mb-2">
          {window.editor.getBaseObject() &&
          window.editor.getBaseObject().HasBackground ? null : (
            <ColorPicker
              iconClass="brush-color"
              text="Frame Color"
              onColor={colorPicked}
              initialColor={storesData.frameColor}
              opacity={1}
            />
          )}
          <div className="clickable clickable-button" onClick={_onLayout}>
            Change Layout
          </div>
        </div>
        {window.editor.getBaseObject() &&
          window.editor.getBaseObject().isRectangle &&
          window.editor.getBaseObject().isRectangle === true && (
            <div>
              <RangeSlider
                className="blue-slider"
                min={0}
                max={25}
                step={1}
                value={storesData.frameSpacing}
                title="Spacing"
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                handleChange={_onSpacingChange}
                isReset={false}
                handleReset={() => {}}
                isBox={false}
                startPoint={0}
              />
              <RangeSlider
                className="blue-slider"
                value={storesData.frameCorner}
                min={0}
                max={80}
                step={1}
                title="Rounding Coners"
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                handleChange={_onRoundingChange}
                isReset={false}
                handleReset={() => {}}
                isBox={false}
                startPoint={0}
              />
            </div>
          )}
        {backgroundImage}
      </div>
    </div>
  );
};

export default MenuCollage;
