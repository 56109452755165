import React, { useEffect, useState, useMemo } from "react";
import AssetStore from "../stores/AssetStore";
import EditorStore from "../stores/EditorStore";
import MenuItem from "./MenuItem.react";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import { ReactComponent as IconDelete } from "../images/editor/delete_icon.svg";
import { ReactComponent as IconCompare } from "../images/editor/compare_icon.svg";
import RangeSlider from "./RangeSlider";

function getStateFromStores() {
  return {
    folders: AssetStore.getEffectFolders(),
    selectedItem: AssetStore.getSelectedItem(),
    selectedFolderItems: AssetStore.getEffectFolderDetails(),
    selectedObject: EditorStore.getSelectedObject(),
    zoom:
      EditorStore.getBackgroundEffect() &&
      EditorStore.getBackgroundEffect().zoom
        ? EditorStore.getBackgroundEffect().zoom
        : 1,
  };
}

const MenuEffect = () => {
  const [storesData, setStoresData] = useState(getStateFromStores());

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      AssetStore.addChangeListener(_onChange);
      EditorStore.addChangeListener(_onChange);
    }

    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setStoresData(getStateFromStores());
  };

  const _onSubMenuClick = (event) => {
    LeftMenuActionCreators.StickerFolderSelected(null);
  };

  const _onRemove = () => {
    if (EditorStore.getEditorType() === "collage") {
      if (window.editor.getSelectedObject()) {
        window.editor.getSelectedObject().filters = null;
      }
    } else {
      window.editor.getBaseObject().removeChildren();
    }
    EditorStore.setBackgroundEffect(null);
    window.editor.getEffectBorderContainer().removeChildren();
    window.editor.getBaseObject().filters = null;

    if (EditorStore.getDesignBackground()) {
      EditorStore.getDesignBackground().filters = null;
    }
    EditorStore.setImageSettings(EditorStore.getImageSettings());
    window.editor.realApplyEffect({ Effect: [] });
    AssetStore.emitChange();
  };

  const _onCategorySelected = (category) => {
    LeftMenuActionCreators.StickerFolderSelected(category);
  };

  const _autoAdjust = (event) => {
    let item = {
      Name: "Auto-Fix",
      Thumb: "thms/autofix.jpg",
      Effect: [
        { filter: "brightness", value: "0.14" },
        { filter: "contrast", value: "0.87" },
        { filter: "saturation", value: "0.01" },
      ],
      zoom: 1,
    };
    EditorStore.setBackgroundEffect(item);
    window.editor.applyEffect(item);
  };

  const handleCompare = () => {
    window.editor.compare();
  };

  const handleCompareUp = () => {
    window.editor.compareEnd();
  };

  const _onZoomReset = () => {
    EditorStore.getBackgroundEffect().zoom = 1;
    let effect = JSON.parse(JSON.stringify(EditorStore.getBackgroundEffect()));

    for (let x = 0; x < effect.Effect.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value);
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value);
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value);
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2);
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3);
          break;
        default:
          effect.Effect[x].value = effect.Effect[x].value;
      }
    }
    window.editor.realApplyEffect(effect, true);
    setStoresData(getStateFromStores());
  };

  const _onZoomChange = (value) => {
    if (
      EditorStore.getBackgroundEffect() &&
      EditorStore.getBackgroundEffect().zoom
    ) {
      EditorStore.getBackgroundEffect().zoom = value;
    }
    let effect = JSON.parse(JSON.stringify(EditorStore.getBackgroundEffect()));
    if (effect.Effect) {
      for (let x = 0; x < effect.Effect.length; x++) {
        switch (effect.Effect[x].filter) {
          case "contrast":
            effect.Effect[x].value =
              0.8 - (0.8 - effect.Effect[x].value) * value;
            break;
          case "denoise":
            effect.Effect[x].value = 50 - (50 - effect.Effect[x].value) * value;
            break;
          case "rgb":
            effect.Effect[x].value = 1 - (1 - effect.Effect[x].value) * value;
            effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2) * value;
            effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3) * value;
            break;
          default:
            if (!isNaN(effect.Effect[x].value))
              effect.Effect[x].value = effect.Effect[x].value * value;
        }
      }
      window.editor.realApplyEffect(effect, true);
      setStoresData(getStateFromStores());
    }
  };

  const folderListItems = useMemo(() => {
    if (storesData.selectedItem) {
      return (
        <>
          {EditorStore.getBackgroundEffect() && (
            <>
              <div className="d-flex">
                <div
                  className="clickable remove-filter-btn semi-clickable-button"
                  onClick={_onRemove}
                >
                  <IconDelete width={16} />
                  &nbsp;Remove Filter
                </div>
                <div
                  className="clickable remove-filter-btn semi-clickable-button"
                  onMouseDown={handleCompare}
                  onMouseUp={handleCompareUp}
                >
                  <IconCompare width={16} />
                  &nbsp;Compare
                </div>
              </div>
              <div className="sub-menu" style={{ margin: "20px" }}>
                <RangeSlider
                  title="Intensity"
                  min={0.01}
                  max={1}
                  step={0.01}
                  value={storesData.zoom}
                  handleChange={_onZoomChange}
                  handleReset={_onZoomReset}
                  isReset={storesData.zoom !== 1}
                  isBox={false}
                  handleMouseEnter={() => {}}
                  handleMouseLeave={() => {}}
                  startPoint={0}
                />
              </div>
            </>
          )}
          {storesData.selectedFolderItems.map((item, idx) => {
            return (
              <MenuItem
                key={idx}
                item={item}
                type="effect"
                setSelectedEffect={() => {}}
                selectedEffect={
                  EditorStore.getBackgroundEffect() &&
                  EditorStore.getBackgroundEffect().Name
                }
                setIntensity={() => {}}
              />
            );
          })}
        </>
      );
    } else {
      return storesData.folders.map((item, idx) => {
        if (idx === 0) {
          return (
            <div
              className="auto-adjust-btn my-btn"
              key={idx}
              onClick={_autoAdjust}
            >
              <svg height={20} viewBox="0 0 359.08 372.46">
                <path
                  className="st0"
                  d="M306.88,81.85q3.6-8.3-.3-15.35a16.86,16.86,0,0,0-16.25-1.25l-41.3,14L215.33,53.5q-9-7.64-16.8-4.45-5.13,4.16-5.75,11.75v47.7l-35.6,25.1q-8.56,5.55-7.6,11.1.45,8.2,8.6,11.45l32.4,9.2L2.43,353.45q-5.31,6.36,1,16.55,9,5.05,15.3-.25L206.78,181.6,216,214q3.25,8.15,11.45,8.6,5.55.95,11.1-7.6l25.1-35.6h47.7q7.6-.6,11.75-5.75,3.2-7.85-4.45-16.8l-25.75-33.7,14-41.3m-35.6,33.65q-5.4,11.6,0,18.45l15,21.3H260.48q-11.35-.15-14.3,7l-14.3,21.6L222,152.05l-33.7-11.8,21.6-14.3q7.14-2.94,7-14.3V85.9l21.3,15q6.86,5.4,18.45,0l21.2-6.6-6.6,21.25m39.25,99.75,5.05,19L309.93,255l20-6.35,20,5.95-6.8-19.2,7.3-20.4-21,6.65-19-6.4m48.55-107.1-9.55-4.6-4.8-10.15-5.25,10.15-9.3,4.65,8.8,5.1,5.5,9.65,5-9.65,9.55-5.15M247.48,265.6l-8.25,15.85-14.5,7.25,13.75,8,8.65,15.1,7.85-15.1,14.9-8.05L255,281.45l-7.5-15.85M281.88,23,267,15.85,259.48,0l-8.25,15.85-14.5,7.25L250.48,31l8.65,15.1L267,31l14.9-8M99,51.3,81,60.2,98.08,70l10.65,18.6,9.7-18.6,18.35-10L118.43,51.3l-9.25-19.65Z"
                />
              </svg>
              <span>&nbsp;&nbsp;Autofix</span>
            </div>
          );
        } else {
          return (
            <div
              key={idx}
              className="mt-2 filters-item"
              onClick={() => _onCategorySelected(item)}
            >
              <img
                src={item.Thumb}
                width={60}
                height={60}
                style={{ borderRadius: "4px" }}
                alt="not found"
              />
              <span className="filter-label">{item.Name}</span>
            </div>
          );
        }
      });
    }
  }, [storesData]);

  const title = useMemo(() => {
    if (storesData.selectedItem) {
      return (
        <div className="clickable d-flex" onClick={_onSubMenuClick}>
          <svg viewBox="0 0 960 560" transform="rotate(90)" height="16">
            <path
              className="st0"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          <span>{storesData.selectedItem.Name} Filters</span>
        </div>
      );
    } else {
      return <span>Filters</span>;
    }
  }, [storesData]);

  return (
    <div className="menu-container large" id="menu-container">
      <div className="menu-header">
        <div className="photos-nav">{title}</div>
      </div>
      <div
        className={`scrollContainer`}
        style={{ paddingTop: "20px", borderSpacing: "8px", width: "100%" }}
      >
        {folderListItems}
      </div>
    </div>
  );
};

export default MenuEffect;
