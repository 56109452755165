import React, { useEffect, useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserStore from "../stores/UserStore";
import { WithContext as ReactTags } from "react-tag-input";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

function getStateFromStores() {
  return {
    isAdmin: UserStore.getUser().isAdmin,
    selectedProject: UserStore.getSelectedProject(),
    tags: UserStore.getSelectedProjectTags(),
  };
}

const TagDialog = () => {
  const [data, setData] = useState(getStateFromStores());
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.showTagDialog(false);
  };

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handleDelete = (i) => {
    //        tags = tags.filter((tag, index) => index !== i);
    EditorActionCreators.deleteTag(i);
    UserStore.emitChange();
  };

  const handleAddition = (tag) => {
    EditorActionCreators.addTag(tag.text);
  };

  const handleDrag = (tag, currPos, newPos) => {
    // const newTags = tags.slice();

    // newTags.splice(currPos, 1);
    // newTags.splice(newPos, 0, tag);

    // // re-render
    // tags = newTags;
    UserStore.emitChange();
  };

  let titleText = "Edit Tags";

  return (
    <div className="filepicker">
      <div id="filepicker-dialog" className="filepicker-dialog active">
        <div className="filepicker-dialog-box">
          <div className="filepicker-navbar">
            <div className="filepicker-title">Edit Tags</div>
            <div className="close-btn" onClick={_cancelClick}>
              <CloseSVG height={12} />
            </div>
          </div>
          <ReactTags
            tags={data.tags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            delimiters={delimiters}
          />
        </div>
      </div>
    </div>
  );
};

export default TagDialog;
