import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";

const StickerErrorModal = () => {
  const handleClickCancel = () => {
    EditorActionCreators.ShowStickerLoadError(false);
  };

  return (
    <div className="filepicker-dialog active">
      <div className="sticker-error-modal" align="center">
        <div className="modal-body">
          <span>
            Sorry there was a problem with this web image, please try another
            one.
          </span>
        </div>
        <div className="modal-footer">
          <div className="clickable confirm-btn" onClick={handleClickCancel}>
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickerErrorModal;
