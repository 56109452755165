import React, { useEffect, useState } from "react";
import UserActionCreators from "../actions/UserActionCreators";
import EditorStore from "../stores/EditorStore";

const LoginForgot = () => {
  let auth2;
  const [username, setUsername] = useState("");
  const [valideate, setValidate] = useState(true);

  useEffect(() => {
    if (window.gapi && window.gapi.auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: "369311205870.apps.googleusercontent.com",
        scope: "profile",
        fetch_basic_profile: false,
      });
      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(signinChanged);
      // Listen for changes to current user.
      auth2.currentUser.listen(userChanged);
    }
  }, []);

  const _onForgot = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_SERVER_API}/user/username/${encodeURI(
        username
      )}`,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      }
    )
      .then(function (res) {
        if (res.status === 200) return res.json();
        else {
          setValidate(false);
        }
      })
      .then((data) => {
        if (data && data.username) {
          UserActionCreators.forgotPassword(data.username);
          UserActionCreators.loginMode("forgotResponse");
        } else {
          setValidate(false);
        }
      });
  };
  const onCancel = () => {
    UserActionCreators.loginMode("email");
  };
  const _facebookClick = () => {
    if (EditorStore.isElectron()) {
      window.FBLogin();
    } else {
      window.FB.login(
        function (response) {
          if (response.status === "connected") {
            var myData = JSON.stringify({
              facebookToken: response.authResponse.accessToken,
            });
            UserActionCreators.logFacebookIn(myData);
          } else if (response.status === "not_authorized") {
            //document.getElementById('status').innerHTML = 'Please log into this app.';
          } else {
            //document.getElementById('status').innerHTML = 'Please log into Facebook.';
          }
        },
        { scope: "email" }
      );
    }
  };

  const _gplusClick = () => {
    window.editor.setProcessGoogle(true);
    if (!auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: "369311205870.apps.googleusercontent.com",
        scope: "profile",
        fetch_basic_profile: false,
      });

      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(signinChanged);
      // Listen for changes to current user.
      auth2.currentUser.listen(userChanged);
    }
    auth2.signIn().then(() => {
      console.log("signin finished");
    });
  };

  const signinChanged = (user) => {
    if (user && user.isSignedIn()) window.editor.googleLogin(user);
  };

  const userChanged = (user) => {
    if (user && user.isSignedIn()) window.editor.googleLogin(user);
  };

  const _twitterClick = () => {
    var left = window.screen.width / 2 - 400;
    var top = window.screen.height / 2 - 300;
    window.open(
      "/apiv2/auth/twitter/login",
      "",
      "width=800,height=500,top=" + top + ",left=" + left
    );
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
    setValidate(true);
  };

  return (
    <div className="dialog-background">
      <div className="login-main-forgot">
        <div className="cancel clickable" onClick={onCancel}>
          Cancel
        </div>
        <div className="forgotTitle">Forgot your password?</div>
        <div className="forgotWait">
          <span>
            <b>WAIT!</b> Are you <b>SURE</b> you did not sign up with a{" "}
            <span className="facebook clickable" onClick={_facebookClick}>
              Facebook
            </span>
            {" or "}
            <span className="google clickable" onClick={_gplusClick}>
              Google{" "}
            </span>
            ID? If you did you will not have a username or password.
          </span>
        </div>

        <span className="login-instruction">
          If you are sure you signed up with a username and can't
          remember your password, fill out&nbsp;
          <a href="https://digipal.wufoo.com/forms/pizap-change-password-request">
             this form
          </a>
        </span>


      </div>
    </div>
  );
};

export default LoginForgot;
