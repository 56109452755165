import React from "react";
import AccordionSubCategory from "./Accordion.SubCategory.react";

const AccordionCategory = (props) => {
  const { category, onSelected, expanded } = props;

  const _onSelected = (event) => {
    event.preventDefault();
    if (category.Categories.length === 1) {
      onSelected(category.Categories[0]);
    } else {
      onSelected(category);
    }
  };

  const _onSubSelected = (subCategory) => {
    onSelected(subCategory);
  };

  return (
    <div>
      <div className="accordion-category clickable" onClick={_onSelected}>
        <div className="image-wrapper">
          <img src={category.Thumb} alt="Not Found" />
        </div>
        <span className="category-name">{category.Name}</span>
        {expanded ? (
          <img
            className="arrowdown"
            src="https://static.pizap.com/html5/resources/editor/downarrow.svg"
            alt="Not Found"
          />
        ) : category.Categories.length > 1 ? (
          <img
            className="arrowright"
            src="https://static.pizap.com/html5/resources/editor/rightarrow.svg"
            alt="Not Found"
          />
        ) : null}
      </div>
      <div className="accordion-sub-category-container">
        {expanded
          ? category.Categories.map((cat, index) => {
              return (
                <AccordionSubCategory
                  category={cat}
                  onSelected={_onSubSelected}
                  key={index}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default AccordionCategory;
