import LeftMenu from "./LeftMenu.react";
import ProTeaser from "./ProTeaser.react";
import ProGet from "./ProGet.react";
import ProGetSave from "./ProGetSave.react";
import React, { useEffect, useState } from "react";
import EditorProperties from "./EditorProperties.react";
import PickerMenu from "./PickerMenu.react";
import FilePicker from "./FilePicker.react";
import CropPicker from "./CropPicker.react";
import DesignPicker from "./DesignPicker.react";
import CollagePicker from "./CollagePicker.react";
import StartMenu from "./StartMenu.react";
import Navigation from "./Navigation.react";
import Spinner from "./Spinner.react";
import TouchupZoom from "./TouchupZoom.react";
import ShareEmail from "./ShareEmail.react";
import UserStore from "../stores/UserStore";
import CutoutInstructions from "./CutoutInstructions.react";
import CutoutStyle from "./CutoutStyle.react";
import CutoutTrace from "./CutoutTrace.react";
import CutoutZoom from "./CutoutZoom.react";
import CutoutRefine from "./CutoutRefine.react";
import Compare from "./Compare.react";
import CustomSize from "./CustomSize.react";
import Share from "./Share.react";
import Save from "./Save.react";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import LeftMenuStore from "../stores/LeftMenuStore";
import Login from "./Login.react";
import LockedButton from "./LockedButton.react";
import ContextMenu from "./ContextMenu.react";
import EditorActionCreators from "../actions/EditorActionCreators";
import Dexie from "dexie";
import touchup from "../editor/touchup";
import ColorWindow from "./ColorWindow.react";
import { getURLParameter } from "../utils/utils";
import StickerErrorModal from "./StickerErrorModal.react";
import UsageLimitModal from "./UsageLimitModal";
import AIErrorModal from "./AIErrorModal";
import watermarkImage from "../images/editor/pizap_watermark.png";
import { ReactComponent as IconCrown } from "../images/editor/pro_crown.svg";
import AssetStore from "../stores/AssetStore";
import DeleteAccountConfirmModal from "./DeleteAccountConfirmModal";
import HelmetDetails from "./HelmetDetails";

function getStateFromStores() {
  return {
    selectedObject: EditorStore.getSelectedObject(),
    stickerContainer: window.editor.getStickerContainer(),
    showPro: UserStore.showPro(),
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showLogin: UserStore.showLogin(),
    showFilePicker: EditorStore.showFilePicker(),
    showCropPicker: EditorStore.showCropPicker(),
    showShareDialog: EditorStore.showShareDialog(),
    pickerState: EditorStore.pickerState(),
    showEmail: EditorStore.showEmail(),
    showStartMenu: EditorStore.showStartMenu(),
    showSticker: EditorStore.showSticker(),
    showSpinner: EditorStore.showSpinner(),
    showSaveQuality: EditorStore.showSaveQuality(),
    showCutoutInstructions: EditorStore.showCutoutInstructions(),
    showCutoutStyle: EditorStore.showCutoutStyle(),
    showCutoutTrace: EditorStore.showCutoutTrace(),
    showCutoutZoom: EditorStore.showCutoutZoom(),
    showCutoutRefine: EditorStore.showCutoutRefine(),
    showDesignPicker: EditorStore.getShowDesignPicker(),
    showCollagePicker: EditorStore.getShowCollagePicker(),
    showCustomSize: EditorStore.showCustomSize(),
    showProjectLoad: EditorStore.showProjectLoad(),
    designBackground: EditorStore.getDesignBackground(),
    menuItem: LeftMenuStore.get(),
    showColorWindow: EditorStore.getShowColorPicker(),
    showUsageLimit: EditorStore.showUsageLimit(),
    showAIError: EditorStore.getAIError(),
    showDeleteAccount: EditorStore.showDeleteAccount(),
    featuredTemplates: AssetStore.getFeaturedTemplates(),
  };
}

const MainContent = () => {
  const editorType = getURLParameter("type");
  const [contentData, setContentData] = useState(getStateFromStores());
  const url = getURLParameter("url");
  const project = getURLParameter("project");

  const watermark = PIXI.Sprite.fromImage(watermarkImage);
  watermark.scale.set(0.1);
  watermark.buttonMode = true;
  watermark.interactive = true;
  watermark.cursor = "pointer";
  watermark.isMark = true;
  watermark.on("click", () => {
    UserActionCreators.showProGet(true);
  });

  const updateWatermark = () => {
    if (
      !UserStore.getUser().isPRO &&
      contentData.stickerContainer &&
      contentData.stickerContainer !== undefined
    ) {
      const mainContent = document.getElementById("mainContent");
      const canvas = mainContent.getElementsByTagName("canvas");
      for (let i = 0; i < contentData.stickerContainer.children.length; i++) {
        if (contentData.stickerContainer.children[i]?.isMark) {
          contentData.stickerContainer.removeChild(
            contentData.stickerContainer.children[i]
          );
        }
      }
      contentData.stickerContainer.addChild(watermark);
      watermark.position.set(
        canvas[0]?.clientWidth - 120,
        canvas[0]?.clientHeight - 30
      );
      contentData.stickerContainer.setChildIndex(
        watermark,
        contentData.stickerContainer.children.length - 1
      );
    }
  };

  const handleCanvasClick = (e) => {
    e.stopPropagation();
    EditorActionCreators.ShowContextMenu(false);
    if (e.target.className === "content") {
      if (LeftMenuStore.get() === "text_props") {
        LeftMenuStore.setMenuSelected("text");
      } else if (LeftMenuStore.get() === "edit_graphic") {
        AssetStore.setRememberPhotos(true);
        LeftMenuStore.setMenuSelected("photos");
      } else if (LeftMenuStore.get() === "edit_graphic_svg") {
        AssetStore.setRememberStickers(true);
        LeftMenuStore.setMenuSelected("sticker");
      }
      window.editor.setSelectedObject(null);
      window.editor.stickerSelector.cancelSelector();
      EditorActionCreators.ShowContextMenu(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateWatermark();
    }, 200);
  }, [
    contentData.stickerContainer?.children.length,
    window.editor.getBaseObject()?.width,
    window.editor.getBaseObject()?.height,
  ]);

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", updateWatermark);
    }
    document
      .getElementById("mainContent")
      .addEventListener("click", handleCanvasClick);

    return () => {
      if (window) {
        window.removeEventListener("resize", updateWatermark);
      }
      if (document.getElementById("mainContent"))
        document
          .getElementById("mainContent")
          .removeEventListener("click", handleCanvasClick);
    };
  }, []);

  const loadProject = async (project) => {
    EditorStore.setShowSpinner(true);
    fetch(project, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then(async (data) => {
        const res = JSON.stringify(data);
        await window.editor.import(res);
      })
      .catch((e) => {
        window.editor.addPicture(project);
        setTimeout(() => {
          EditorStore.setShowSpinner(false);
        }, 2000);
      });
  };

  useEffect(async () => {
    if (editorType) {
      EditorStore.setEditorType(editorType);
      if (editorType === "edit") {
        EditorStore.setShowFilePicker(true);
      }
    } else {
      EditorStore.setEditorType("edit");
      EditorStore.setShowFilePicker(true);
    }

    if (editorType === "eraser") {
      LeftMenuStore.setMenuSelected("design");
    } else {
      LeftMenuStore.setMenuSelected("init");
    }

    if (url) {
      EditorActionCreators.ShowFilePicker(false);
      window.editor.addPicture(url);
    }

    if (project) {
      if (project === "latestProject") {
        let db = new Dexie("pizapProjects");
        db.version(1).stores({
          projects: "id,created,createdBy, mode",
        });
        db.open();
        db.projects
          .where("id")
          .equals("latestProject")
          .each(function (p) {
            window.editor.import(JSON.stringify(p));
            EditorActionCreators.ShowFilePicker(false);
          });
      } else {
        EditorActionCreators.ShowFilePicker(false);
        await loadProject(project);
      }
    }

    document.addEventListener("keydown", function (event) {
      if (
        (event.ctrlKey && event.key === "z" && event.shiftKey) ||
        (event.metaKey && event.key === "z" && event.shiftKey)
      ) {
        if (LeftMenuStore.get() === "touchup") {
          touchup.CommandManager.redo();
        } else {
          window.editor.CommandManager.redo();
        }
      } else {
        if (
          (event.ctrlKey && event.key === "z") ||
          (event.metaKey && event.key === "z")
        ) {
          if (LeftMenuStore.get() === "touchup") {
            touchup.CommandManager.undo();
          } else {
            window.editor.CommandManager.undo();
          }
        }
      }
    });

    UserStore.addChangeListener(_onChange);
    EditorStore.addChangeListener(_onChange);
    LeftMenuStore.addChangeListener(_onChange);
    EditorStore.emitChange();
    return () => {
      EditorStore.setEditorType("clear");
      UserStore.removeChangeListener(_onChange);
      EditorStore.removeChangeListener(_onChange);
      LeftMenuStore.removeChangeListener(_onChange);
      EditorStore.emitChange();
    };
  }, []);

  const _onChange = () => {
    setContentData(getStateFromStores());
  };

  return (
    <div className="middle pro">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app"
        keywords="piZap Editor, Photo Editor, Photo effects editor"
      />
      <div id="dialogContainer">
        {contentData.showCutoutZoom ? <CutoutZoom /> : null}
        {contentData.showFilePicker ? <PickerMenu /> : null}
        {contentData.showStartMenu ? <StartMenu /> : null}
        {contentData.showCropPicker ? <CropPicker /> : null}
        {contentData.showDesignPicker ? <DesignPicker /> : null}
        {contentData.showCollagePicker ? <CollagePicker /> : null}
        {contentData.showCustomSize ? <CustomSize /> : null}
        {contentData.showSaveQuality ? <Save /> : null}
        {contentData.pickerState ? (
          <FilePicker type={contentData.pickerState} />
        ) : null}
        {contentData.showShareDialog ? <Share /> : null}
        {contentData.showLogin ? <Login /> : null}
        {contentData.showSpinner ? <Spinner /> : null}
        {contentData.showSticker ? <StickerErrorModal /> : null}
        {contentData.showUsageLimit ? <UsageLimitModal /> : null}
        {contentData.showProjectLoad ? <Spinner /> : null}
        {contentData.showEmail ? <ShareEmail /> : null}
        {contentData.showAIError ? <AIErrorModal /> : null}
        {window.editor &&
        window.editor.getBaseObject() &&
        window.editor.getBaseObject().filters !== null &&
        (LeftMenuStore.get() === "main" ||
          LeftMenuStore.get() === "init" ||
          LeftMenuStore.get() === "design") ? (
          <Compare />
        ) : null}
        {EditorStore.getTouchupTool() && contentData.menuItem === "touchup" ? (
          <TouchupZoom />
        ) : null}
        {contentData.showColorWindow && <ColorWindow />}
      </div>
      <div className="navbar">
        <Navigation />
      </div>
      <div className="container" id="container">
        <EditorProperties />
        <LockedButton />
        <ContextMenu />
        <div className="content" id="mainContent"></div>
      </div>
      <LeftMenu />
      {!UserStore.getUser().isPRO &&
        contentData.stickerContainer &&
        contentData.stickerContainer !== undefined && (
          <div
            className="rm-watermark"
            onClick={() => {
              UserActionCreators.showProGet(true);
            }}
          >
            <IconCrown />
            Remove watermark
          </div>
        )}
      <div id="touchupSliderPreview"></div>
      <div className="proContainerContents">
        {contentData.showProGetSave ? <ProGetSave /> : null}
        {contentData.showProGet ? <ProGet /> : null}
        {contentData.showPro ? <ProTeaser /> : null}
        {contentData.showCutoutInstructions ? <CutoutInstructions /> : null}
        {contentData.showCutoutRefine ? <CutoutRefine /> : null}
        {contentData.showCutoutTrace ? <CutoutTrace /> : null}
        {contentData.showCutoutStyle ? <CutoutStyle /> : null}
        {contentData.showDeleteAccount ? <DeleteAccountConfirmModal /> : null}
      </div>
    </div>
  );
};

export default MainContent;
