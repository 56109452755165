import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserStore from "../stores/UserStore";
import { isIE, isLegacyEdge } from "./utils";

const ProtectedRoute = ({ children }) => {
  if (isIE() || isLegacyEdge()) {
    return <Navigate to="/app/browser" replace />;
  }

  if (!UserStore.getUser()) {
    return <Navigate to="/app/login" replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
