import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const ChristmasCardMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Christmas Card Maker | Christmas Card Design App"
        description="Online Christmas Card Maker and Design Tool. Quickly and easily create a custom Christmas card with our easy online photo editor"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/christmas_card_maker_app"
        keywords="Christmas Card Maker, Christmas Card Designer, Make Cards Online"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Custom Christmas Card Maker</p>
          <p className="desc">
            Spread Holiday cheer with DIY photo Christmas cards
          </p>
          <StartButton
            text="Edit a Card →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/christmas_card_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/christmas_card_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Custom Christmas Card Maker</span>
            <span className="desc">
              Tis the season to make your own photo Christmas cards and let your
              loved ones know you’re thinking of them. Christmas cards spread
              joy near and far, but let’s be real, the holiday cards at the
              store all look the same. Kick it up a notch with custom photo
              Christmas cards your friends and family will remember long after
              the holiday season comes to an end.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap’s Christmas Card Maker Tool</span>
            <span className="desc">
              piZap is packed with photo editing and design features, including
              the Christmas Card Maker. A holiday favorite, this tool offers a
              frustration-free platform to create custom cards using photos,
              Christmas stickers, text boxes, thought bubbles, etc. Make the
              perfect card for any holiday to share online or print and mail.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/christmas_card_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/christmas_card_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              The Easy Way to Make Photo Christmas Cards
            </span>
            <span className="desc">
              piZap’s Christmas Card Maker gives you the tools to bring your
              imagination to life regardless how much experience you have
              designing things online. Users get to enjoy advanced design and
              editing tools without all the confusion. That’s because piZap is
              specially designed so that everything you need is right there,
              within the click of a button or two. After playing around for a
              few minutes you’ll already feel like a pro.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              Holly Jolly Good Time Christmas Card Maker
            </span>
            <span className="desc">
              We want you to have a Holly Jolly Christmas, and our easy to use
              Christmas Card Maker helps you pass on the joy to everyone on your
              list. Don’t go broke or waste an entire day making photo Christmas
              cards, piZap makes it easy, fun and fast. Other tools, like
              piZap’s emoji maker, can help add an extra special kick of fun to
              your photo Christmas cards. Or, use piZap’s MEME maker to create a
              uniquely Christmeme Card!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/christmas_card_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/christmas_card_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Walking in a Features Wonderland</h3>
            </span>
            <span className="desc">
              piZap is the Santa Clause of Christmas card makers, for one
              because it offers so much more than a basic card making tool.
              There’s editing tools, a collage maker, MEME maker, graphic design
              creator; all of this and more packed into one app--must be piZap!
              With these tools at your fingertips just imagine the cheery packed
              holiday cards you can create this year.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default ChristmasCardMakerPage;
