import keyMirror from "keymirror";

const ActionTypes = keyMirror({
  LEFT_MENU_SELECTED: null,
  ASSETS_RECEIVED: null,
  STICKER_SEARCH: null,
  STICKER_BING_SEARCH: null,
  STICKER_FOLDER_SELECTED: null,
  FILE_PICKER_CATEGORY_SELECTED: null,
  CUTOUTS_RETRIEVE: null,
  USER_LOGGED_IN: null,
  LOG_USER_IN: null,
  FORGOT_PASSWORD: null,
  USER_LEGACY_LOGGED_IN: null,
  EFFECT_SELECTED: null,
  FACEBOOK_GET_FOLDERS: null,
  EDITOR_OBJECT_SELECTED: null,
  EDITOR_PROPERTIES_MINIFIED: null,
  EDITOR_INIT_PHOTO: null,
  EDITOR_REMOVE_STICKER: null,
  EDITOR_COPY_STICKER: null,
  EDITOR_MOVE_FRONT: null,
  EDITOR_MOVE_FORWARD: null,
  EDITOR_MOVE_BACK: null,
  EDITOR_MOVE_BACKWARD: null,
  PAINT_CHANGE_COLOR: null,
  CUSTOM_BORDER: null,
  COLOR_PICKER_PREVIEW: null,
  CUSTOM_BORDER_WIDTH: null,
  CUSTOM_BORDER_COLOR: null,
  TOUCHUP_BRUSH_SIZE: null,
  TOUCHUP_FADE: null,
  TOUCHUP_HARDNESS: null,
  PAINT_BRUSH_SIZE: null,
  PAINT_ALPHA: null,
  PAINT_BLUR: null,
  PAINT_MODE: null,
  TEXT_FONT: null,
  TEXT_FONT_COLOR: null,
  TEXT_GLOW_COLOR: null,
  TEXT_OUTLINE_COLOR: null,
  TEXT_BACKGROUND_COLOR: null,
  TEXT_DROPSHADOW_COLOR: null,
  TEXT_FONT_SIZE: null,
  TEXT_GLOW_SLIDER_VAL: null,
  TEXT_GLOW_DISTANCE_SLIDER_VAL: null,
  TEXT_OUTLINE_SLIDER_VAL: null,
  TEXT_DROPSHADOW_ALPHA_SLIDER_VAL: null,
  TEXT_DROPSHADOW_BLUR_SLIDER_VAL: null,
  TEXT_DROPSHADOW_ANGLE_SLIDER_VAL: null,
  TEXT_DROPSHADOW_DISTANCE_SLIDER_VAL: null,
  TEXT_CHANGE: null,
  TEXT_LINE_HEIGHT: null,
  TEXT_SPACING: null,
  TEXT_CURVE: null,
  TEXT_ALIGN: null,
  TEXT_BOLD: null,
  TEXT_ITALIC: null,
  LOCAL_FONTS: null,
  GLOW_ENABLED: null,
  OUTLINE_ENABLED: null,
  BACKGROUND_ENABLED: null,
  DROPSHADOW_ENABLED: null,
  CUTOUT_SAVE: null,
  CUTOUT_MODE: null,
  CUTOUT_VIEW: null,
  EDITOR_MODE: null,
  EDITOR_IMAGE_SETTINGS: null,
  EDITOR_HOTSPOT_SETTINGS: null,
  LOGOUT: null,
  SAVE: null,
  SHOW_PRO: null,
  SHOW_PRO_GET: null,
  SHOW_PRO_GET_SAVE: null,
  SHOW_LOGIN: null,
  PRINT: null,
  POST_LOGIN_URL: null,
  UPGRADE_PURCHASED: null,
  SHOW_FILE_PICKER: null,
  SHOW_CROP_PICKER: null,
  SHOW_TAG_DIALOG: null,
  ADD_TAG: null,
  GET_TAG: null,
  GET_APPLICATIONS: null,
  DELETE_TAG: null,
  PICKER_STATE: null,
  SHOW_SHARE_DIALOG: null,
  SHOW_FEEDBACK: null,
  SHOW_CUSTOM_SIZE: null,
  SHOW_EMBED: null,
  SHOW_LINK: null,
  SHOW_EMAIL: null,
  SHOW_SPINNER: null,
  SHOW_STICKER_ERROR: null,
  SHOW_USAGE_LIMIT: null,
  SHOW_SAVE_QUALITY: null,
  SHOW_HAMBURGER_MENU: null,
  SHOW_ACCOUNT_MENU: null,
  SHOW_CUTOUT_INSTRUCTIONS: null,
  SHOW_CUTOUT_STYLE: null,
  SHOW_CUTOUT_TRACE: null,
  SHOW_ACCOUNT: null,
  PROFILE_PHOTO: null,
  UPDATE_EMAIL: null,
  UPDATE_NAME: null,
  SHOW_DESIGN_PICKER: null,
  SHOW_COLOR_PICKER: null,
  SHOW_COLLAGE_PICKER: null,
  SHOW_CUTOUT_REFINE: null,
  SHOW_CUTOUT_ZOOM: null,
  FONT_CATEGORY_SELECTED: null,
  LOGIN_MODE: null,
  SHOW_NOWEBGL: null,
  SHOW_PROPERTIES_COLLAGE: null,
  COLLAGE_HOTSPOT_SELECTED: null,
  COLLAGE_HOTSPOT_ACTIVE_SELECTED: null,
  TOUCHUP_TOOL: null,
  TOUCHUP_COLOR: null,
  PROJECT_SAVE: null,
  CREATE_FOLDER: null,
  DELETE_CUTOUT: null,
  DELETE_PROJECT: null,
  DELETE_FOLDER: null,
  DELETE_ASSET: null,
  DUPLICATE_PROJECT: null,
  MOVE_CUTOUT: null,
  MOVE_PROJECT: null,
  MOVE_PHOTO: null,
  PROJECT_TITLE_CHANGE: null,
  UPDATE_FOLDER: null,
  UPLOAD_PROGRESS: null,
  GET_FEATURED_GRAPHICS: null,
  LOAD_PRINT_PRODUCTS: null,
  REMOVE_BACKGROUND: null,
  DELETE_PHOTO: null,
  RESET_MENU: null,
  UPDATE_BLACK_COLOR: null,
  GRAPHIC_GLOW_ENABLED: null,
  GRAPHIC_OUTLINE_ENABLED: null,
  GRAPHIC_DROPSHADOW_ENABLED: null,
  GRAPHIC_DROPSHADOW_BLUR_SLIDER_VAL: null,
  GRAPHIC_GLOW_COLOR: null,
  GRAPHIC_GLOW_SLIDER_VAL: null,
  GRAPHIC_DROPSHADOW_ANGLE_SLIDER_VAL: null,
  GRAPHIC_DROPSHADOW_DISTANCE_SLIDER_VAL: null,
  GRAPHIC_DROPSHADOW_ALPHA_SLIDER_VAL: null,
  GRAPHIC_DROPSHADOW_COLOR: null,
  SHOW_GRAPHIC_CUTOUT_STYLE: null,
  LOAD_TEXT_TEMPLATES: null,
  PICKER_SEARCH: null,
  START_SEARCH: null,
  SHOW_CONTEXT_MENU: null,
  LOAD_CATEGORIES: null,
  LOAD_APPLICATIONS: null,
  SHOW_DELETE_ACCOUNT: null,
  SHOW_AI_ERROR: null,
  LOAD_AI_STYLES: null,
  LOAD_AI_SUGGESTIONS: null,
  LOAD_GRAPHIC: null,
  LOAD_AI_TEMPLATES: null,
});

export default ActionTypes;
