import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const LogoMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Logo Maker App | Design a Logo Online"
        description="Our logo maker app lets you design logos online."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/logo_maker_app"
        keywords="Logo Maker App, Design a Logo Online"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">The "No Experience Required" Logo Maker</p>
          <p className="desc">
            Finally, a quick & easy way to design your own logo
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=edit");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/logo_maker_design_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/logo_maker_design_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Logo Maker</span>
            <span className="desc">
              piZap offers a Logo Maker tool you’re going to want to use! Get
              ready to zap your vision into a digital reality. A good logo adds
              professionalism, purpose, and an identifying factor for your
              business or brand… but they’re expensive. And what if someone
              designs a logo you don’t even like? piZap changes everything by
              offering an easy to use online Logo Maker tool.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap's Logo Maker Tool</span>
            <span className="desc">
              piZap’s logo maker tool is easy to use while still offering a lot
              of flexibility. The transparent backgrounds feature makes it easy
              to design logos or professional looking web graphics. Choose from
              countless styles, layouts, colors, and hundreds of fonts— with
              more added all the time.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/logo_maker_design_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/logo_maker_design_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Easy Online Logo Maker</span>
            <span className="desc">
              At long last, an app that makes it fun and simple to access some
              of the best photo editing and design tools in the industry. This
              includes the ability to create professional and striking logos
              using the in-app Logo Maker. Each button and tool is carefully
              labeled and easy to access, so you can design your own logo, sans
              frustration.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Flexible & Fun Logo Creator</span>
            <span className="desc">
              Logo designers are pricey and no one knows your vision like you
              do, so why not design your own logo? Perhaps you think it’s too
              boring, difficult, or downright un-fun. Think again! piZap is the
              fun photo editing app with that extra pizazz perfectionists and
              professionals are seeking. piZap helps ensure your design looks
              good thanks to its many integrated tools and features.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/logo_maker_design_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/logo_maker_design_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Logo Maker + Many More Features</h3>
            </span>
            <span className="desc">
              piZap is so much more than just an online logo maker. Make use of
              many photo editing tools, thousands of stickers and hundreds of
              fonts—with more added all the time. Use transparent backgrounds to
              create web design graphics, or create a collage to showcase on
              your website or social media profile. piZap puts countless photo
              editing tools and design features right at your fingertips!
              Download piZap’s online logo maker for your desktop computer or
              mobile device today to see what all the buzz is about.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default LogoMakerPage;
