import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserStore from "../stores/UserStore";
import "../styles/admin.css";

const AdminHeader = () => {
  const user = UserStore.getUser();
  const navigate = useNavigate();
  const burgerRef = useRef(null);
  const menuRef = useRef(null);

  const handleBurgerClick = () => {
    if (menuRef && menuRef.current) {
      menuRef.current.classList.toggle("is-active");
    }
    if (burgerRef && burgerRef.current) {
      burgerRef.current.classList.toggle("is-active");
    }
  };

  return (
    <>
      <header className="admin-header">
        <nav className="admin-navbar admin-container">
          <Link to="/" className="brand">
            <img
              src="https://static.pizap.com/html5/resources/editor/pizap_logo.svg"
              height={24}
              alt="logo"
            />
          </Link>
          <div className="burger" onClick={handleBurgerClick} ref={burgerRef}>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <div className="menu" ref={menuRef}>
            <ul className="menu-inner">
              <li className="menu-item">
                <Link to="/adminv3" className="menu-link">
                  Users
                </Link>
              </li>
              {user.UserName !== "vhub2013" ? (
                <>
                  <li className="menu-item">
                    <Link to="/adminv3/graphics" className="menu-link">
                      Graphics
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/adminv3/templates" className="menu-link">
                      Templates
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/adminv3/categories" className="menu-link">
                      Categories
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/adminv3/applications" className="menu-link">
                      Applications
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
          {user ? (
            <button onClick={() => {}} className="menu-block">
              Logout
            </button>
          ) : (
            <button
              onClick={() => {
                navigate("/login");
              }}
              className="menu-block"
            >
              Join
            </button>
          )}
        </nav>
      </header>
    </>
  );
};

export default AdminHeader;
