import * as PIXI from "pixi.js";
import RotateControlImg from "../images/editor/rotate_control.png";
import WhiteCircleImg from "../images/editor/white_circle.png";
import WhitePillImg from "../images/editor/white_pill.png";
import WhitePill2Img from "../images/editor/white_pill_2.png";

var selectorCutoutContainer;
var imgObject;

function calculateGetScaledHeight(width, height, newWidth) {
  return (height * newWidth) / width;
}

var StickerCutoutSelector = {
  // leftTop: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // rightTop: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // LeftBottom: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // RightBottom: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // MiddleTop: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // MiddleBottom: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // MiddleLeft: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // MiddleRight: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  // ),
  // rotate: PIXI.Sprite.fromImage(
  //   "https://static.pizap.com/html5/resources/editor/rotate-control.svg"
  // ),
  leftTop: PIXI.Sprite.fromImage(WhiteCircleImg),
  rightTop: PIXI.Sprite.fromImage(WhiteCircleImg),
  LeftBottom: PIXI.Sprite.fromImage(WhiteCircleImg),
  RightBottom: PIXI.Sprite.fromImage(WhiteCircleImg),
  MiddleTop: PIXI.Sprite.fromImage(WhitePillImg),
  MiddleBottom: PIXI.Sprite.fromImage(WhitePillImg),
  MiddleLeft: PIXI.Sprite.fromImage(WhitePill2Img),
  MiddleRight: PIXI.Sprite.fromImage(WhitePill2Img),
  rotate: PIXI.Sprite.fromImage(RotateControlImg),
  border: new PIXI.Graphics(),
  originalX: 0,
  originalY: 0,
  getSelectorCutoutContainer: function () {
    return selectorCutoutContainer;
  },
  setCropCutoutObject: function (object) {
    imgObject = object;
    StickerCutoutSelector.updateSelector();
  },
  getCropCutoutObject: function () {
    return imgObject;
  },
  init: function () {
    selectorCutoutContainer = new PIXI.Container();
    selectorCutoutContainer.addChild(this.border);
    this.leftTop.anchor.set(0.5);
    this.leftTop.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.leftTop.interactive = true;
    this.leftTop.buttonMode = true;
    this.leftTop.renderable = false;
    this.leftTop.cursor = "nw-resize";
    selectorCutoutContainer.addChild(this.leftTop);
    this.leftTop
      .on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.rightTop.anchor.set(0.5);
    this.rightTop.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.rightTop.interactive = true;
    this.rightTop.buttonMode = true;
    this.rightTop.renderable = false;
    this.rightTop.cursor = "ne-resize";
    selectorCutoutContainer.addChild(this.rightTop);
    this.rightTop
      .on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.LeftBottom.anchor.set(0.5);
    this.LeftBottom.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.LeftBottom.interactive = true;
    this.LeftBottom.buttonMode = true;
    this.LeftBottom.renderable = false;
    this.LeftBottom.cursor = "sw-resize";
    selectorCutoutContainer.addChild(this.LeftBottom);
    this.LeftBottom.on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.RightBottom.anchor.set(0.5);
    this.RightBottom.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.RightBottom.interactive = true;
    this.RightBottom.buttonMode = true;
    this.RightBottom.renderable = false;
    this.RightBottom.cursor = "se-resize";
    selectorCutoutContainer.addChild(this.RightBottom);
    this.RightBottom.on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.MiddleTop.anchor.set(0.5);
    this.MiddleTop.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.MiddleTop.interactive = true;
    this.MiddleTop.buttonMode = true;
    this.MiddleTop.renderable = false;
    this.MiddleTop.cursor = "n-resize";
    selectorCutoutContainer.addChild(this.MiddleTop);
    this.MiddleTop.on("mousedown", this.onHeightStart)
      .on("touchstart", this.onHeightStart)
      .on("mouseup", this.onHeightEnd)
      .on("mouseupoutside", this.onHeightEnd)
      .on("touchend", this.onHeightEnd)
      .on("touchendoutside", this.onHeightEnd)
      .on("mousemove", this.onHeightMove)
      .on("touchmove", this.onHeightMove);

    this.MiddleBottom.anchor.set(0.5);
    this.MiddleBottom.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.MiddleBottom.interactive = true;
    this.MiddleBottom.buttonMode = true;
    this.MiddleBottom.renderable = false;
    this.MiddleBottom.cursor = "s-resize";
    selectorCutoutContainer.addChild(this.MiddleBottom);
    this.MiddleBottom.on("mousedown", this.onHeightStart)
      .on("touchstart", this.onHeightStart)
      .on("mouseup", this.onHeightEnd)
      .on("mouseupoutside", this.onHeightEnd)
      .on("touchend", this.onHeightEnd)
      .on("touchendoutside", this.onHeightEnd)
      .on("mousemove", this.onHeightMove)
      .on("touchmove", this.onHeightMove);

    this.MiddleLeft.anchor.set(0.5);
    this.MiddleLeft.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.MiddleLeft.interactive = true;
    this.MiddleLeft.buttonMode = true;
    this.MiddleLeft.renderable = false;
    this.MiddleLeft.cursor = "w-resize";
    selectorCutoutContainer.addChild(this.MiddleLeft);
    this.MiddleLeft.on("mousedown", this.onWidthStart)
      .on("touchstart", this.onWidthStart)
      .on("mouseup", this.onWidthEnd)
      .on("mouseupoutside", this.onWidthEnd)
      .on("touchend", this.onWidthEnd)
      .on("touchendoutside", this.onWidthEnd)
      .on("mousemove", this.onWidthMove)
      .on("touchmove", this.onWidthMove);

    this.MiddleRight.anchor.set(0.5);
    this.MiddleRight.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.MiddleRight.interactive = true;
    this.MiddleRight.buttonMode = true;
    this.MiddleRight.renderable = false;
    this.MiddleRight.cursor = "e-resize";
    selectorCutoutContainer.addChild(this.MiddleRight);
    this.MiddleRight.on("mousedown", this.onWidthStart)
      .on("touchstart", this.onWidthStart)
      .on("mouseup", this.onWidthEnd)
      .on("mouseupoutside", this.onWidthEnd)
      .on("touchend", this.onWidthEnd)
      .on("touchendoutside", this.onWidthEnd)
      .on("mousemove", this.onWidthMove)
      .on("touchmove", this.onWidthMove);

    this.rotate.anchor.set(0.5);
    this.rotate.scale.set(0.5 / window.editor.getCutoutStage().scale.x);
    this.rotate.interactive = true;
    this.rotate.buttonMode = true;
    this.rotate.renderable = false;
    this.rotate.cursor = "alias";
    selectorCutoutContainer.addChild(this.rotate);
    this.rotate
      .on("mousedown", this.onRotateStart)
      .on("touchstart", this.onRotateStart)
      .on("mouseup", this.onRotateEnd)
      .on("mouseupoutside", this.onRotateEnd)
      .on("touchend", this.onRotateEnd)
      .on("touchendoutside", this.onRotateEnd)
      .on("mousemove", this.onRotateMove)
      .on("touchmove", this.onRotateMove);
  },
  updateSelector: function () {
    if (window.editor.getSelectedObject() && imgObject) {
      selectorCutoutContainer.x = window.editor.getSelectedObject().x;
      selectorCutoutContainer.y = window.editor.getSelectedObject().y;
      var width = window.editor.getSelectedObject().width;
      if (window.editor.getSelectedObject() instanceof PIXI.Text) {
        width = window.editor.getSelectedObject().style.wordWrapWidth;
      }
      var height = window.editor.getSelectedObject().height;

      this.border.clear();
      this.border.lineStyle(
        1 / window.editor.getCutoutStage().scale.x,
        0xffffff,
        0.75
      );
      this.border.drawRect(0 - width / 2, 0 - height / 2, width, height);
      if (window.editor.getSelectedObject().crop) {
      } else {
        this.border.moveTo(0, 0 - height / 2);
        this.border.lineTo(
          0,
          0 - height / 2 - 35 / window.editor.getCutoutStage().scale.x
        );
      }
      this.rotate.position.x = 0;
      this.rotate.position.y =
        0 - height / 2 - 35 / window.editor.getCutoutStage().scale.x;
      this.rotate.renderable = !window.editor.getSelectedObject().crop;
      this.leftTop.position.x = 0 - width / 2;
      this.leftTop.position.y = 0 - height / 2;
      this.leftTop.renderable = true;
      this.rightTop.position.x = width / 2;
      this.rightTop.position.y = 0 - height / 2;
      this.rightTop.renderable = true;
      this.LeftBottom.position.x = 0 - width / 2;
      this.LeftBottom.position.y = height / 2;
      this.LeftBottom.renderable = true;
      this.RightBottom.position.x = width / 2;
      this.RightBottom.position.y = height / 2;
      this.RightBottom.renderable = true;
      this.MiddleBottom.position.x = 0;
      this.MiddleBottom.position.y = height / 2;
      this.MiddleBottom.renderable = true;
      this.MiddleTop.position.x = 0;
      this.MiddleTop.position.y = 0 - height / 2;
      this.MiddleTop.renderable = true;
      this.MiddleLeft.position.x = 0 - width / 2;
      this.MiddleLeft.position.y = 0;
      this.MiddleLeft.renderable = true;
      this.MiddleRight.position.x = width / 2;
      this.MiddleRight.position.y = 0;

      this.MiddleRight.renderable = true;
      selectorCutoutContainer.rotation =
        window.editor.getSelectedObject().rotation;
      if (window.editor.getSelectedObject() instanceof PIXI.Text) {
        this.MiddleBottom.renderable = false;
        this.MiddleTop.renderable = false;
        this.leftTop.renderable = false;
        this.rightTop.renderable = false;
        this.LeftBottom.renderable = false;
        this.RightBottom.renderable = false;
      }
    } else {
      this.rotate.renderable = false;
      this.leftTop.renderable = false;
      this.rightTop.renderable = false;
      this.LeftBottom.renderable = false;
      this.RightBottom.renderable = false;
      this.MiddleBottom.renderable = false;
      this.MiddleLeft.renderable = false;
      this.MiddleRight.renderable = false;
      this.MiddleTop.renderable = false;
      this.border.clear();
    }
  },
  onScaleStart: function (event) {
    event.stopPropagation();
    window.editor.getSelectedObject().data = event.data;
    window.editor.getSelectedObject().scaling = true;
    window.editor.getSelectedObject().dragging = false;
    window.editor.scaleStartWidth = window.editor.getSelectedObject().width;
    window.editor.scaleStartHeight = window.editor.getSelectedObject().height;
    this.originalX =
      window.editor.getSelectedObject().width -
      event.data.global.x -
      window.editor.getSelectedObject().x;
  },
  onScaleEnd: function () {
    var id = window.editor.getSelectedObject().id;
    var startwidth = window.editor.scaleStartWidth;
    var startheight = window.editor.scaleStartHeight;
    var finishwidth = window.editor.getSelectedObject().width;
    var finishheight = window.editor.getSelectedObject().height;
    window.editor.getSelectedObject().scaling = false;
    window.editor.getSelectedObject().data = null;
    window.editor.CommandManager.execute({
      execute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        if (sticker && sticker.svg === true) {
          var saveScale = window.editor.getCutoutStage().scale.x;
          var xmlText = sticker.sourceUrl;
          var source = new Image();
          source.setAttribute("crossOrigin", "anonymous");
          source.width = finishwidth * saveScale;
          source.height = finishheight * saveScale;
          source.onload = function () {
            var newsticker = new PIXI.Sprite(
              new PIXI.Texture.fromCanvas(source)
            );
            newsticker.id = sticker.id;
            newsticker.interactive = true;
            newsticker.buttonMode = true;
            newsticker.position = sticker.position;
            newsticker.anchor.set(0.5);
            newsticker.scale.set(1);
            newsticker.sourceUrl = sticker.sourceUrl;
            newsticker.svg = true;
            newsticker.width = finishwidth;
            newsticker.height = finishheight;
            newsticker.cursor = "move";
            newsticker.rotation = sticker.rotation;

            imgObject.removeChildren(1);

            imgObject.addChild(newsticker);
            imgObject.mask = newsticker;
            window.editor.setSelectedObject(newsticker);

            newsticker
              .on("mousedown", window.editor.onCutoutDragStart)
              .on("touchstart", window.editor.onCutoutDragStart)
              .on("mouseup", window.editor.onCutoutDragEnd)
              .on("mouseupoutside", window.editor.onCutoutDragEnd)
              .on("touchend", window.editor.onCutoutDragEnd)
              .on("touchendoutside", window.editor.onCutoutDragEnd)
              .on("mousemove", window.editor.onCutoutDragMove)
              .on("touchmove", window.editor.onCutoutDragMove);
          };
          source.src = xmlText;
        } else {
          sticker.width = finishwidth;
          sticker.height = finishheight;
        }
      },
      unexecute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        if (sticker) {
          sticker.width = startwidth;
          sticker.height = startheight;
        }
      },
    });
  },
  onScaleMove: function (event) {
    if (
      window.editor.getSelectedObject() &&
      window.editor.getSelectedObject().scaling
    ) {
      var tempX =
        event.data.global.x / window.editor.getCutoutStage().scale.x -
        window.editor.getSelectedObject().x;
      var tempY =
        event.data.global.y / window.editor.getCutoutStage().scale.y -
        window.editor.getSelectedObject().y;

      var rotatedX =
        tempX * Math.cos(window.editor.getSelectedObject().rotation) +
        tempY * Math.sin(window.editor.getSelectedObject().rotation);

      var unRotatedX = rotatedX + window.editor.getSelectedObject().x;

      var newWidth =
        Math.abs(unRotatedX - window.editor.getSelectedObject().x) * 2;
      var height = calculateGetScaledHeight(
        window.editor.getSelectedObject().width,
        window.editor.getSelectedObject().height,
        newWidth
      );
      window.editor.getSelectedObject().width = newWidth;
      window.editor.getSelectedObject().height = height;
      StickerCutoutSelector.updateSelector();
      window.editor.resizeText(window.editor.getSelectedObject());
      if (window.editor.getSelectedObject().crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          window.editor.getSelectedObject().x -
            window.editor.getSelectedObject().width / 2,
          window.editor.getSelectedObject().y -
            window.editor.getSelectedObject().height / 2,
          window.editor.getSelectedObject().width,
          window.editor.getSelectedObject().height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
  onHeightStart: function (event) {
    event.stopPropagation();
    window.editor.getSelectedObject().data = event.data;
    window.editor.getSelectedObject().skewHeight = true;
    window.editor.getSelectedObject().skewWidth = false;
    window.editor.getSelectedObject().dragging = false;
    window.editor.getSelectedObject().scaling = false;
    if (window.editor.getSelectedObject() instanceof PIXI.Container) {
      window.editor.scaleStartHeight =
        window.editor.getSelectedObject()._height;
      this.originalY =
        window.editor.getSelectedObject().y +
        window.editor.getSelectedObject()._height / 2;
      window.editor.scaleStartWidth = window.editor.getSelectedObject()._width;
      window.editor.scaleStartHeight =
        window.editor.getSelectedObject()._height;
      this.originalX =
        window.editor.getSelectedObject()._width -
        event.data.global.x -
        window.editor.getSelectedObject().x;
    } else {
      window.editor.scaleStartHeight = window.editor.getSelectedObject().height;
      this.originalY =
        window.editor.getSelectedObject().height -
        Math.abs(event.data.global.y - window.editor.getSelectedObject().y) * 2;
      window.editor.scaleStartWidth = window.editor.getSelectedObject().width;
      window.editor.scaleStartHeight = window.editor.getSelectedObject().height;
      this.originalX =
        window.editor.getSelectedObject().width -
        event.data.global.x -
        window.editor.getSelectedObject().x;
    }
  },
  onHeightEnd: function () {
    var id = window.editor.getSelectedObject().id;
    var startheight = window.editor.scaleStartHeight;
    var finishheight = window.editor.getSelectedObject().height;
    window.editor.getSelectedObject().skewHeight = false;
    window.editor.getSelectedObject().skewWidth = false;
    window.editor.getSelectedObject().scaling = false;
    window.editor.getSelectedObject().data = null;
    window.editor.CommandManager.execute({
      execute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        sticker.height = finishheight;
      },
      unexecute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        sticker.height = startheight;
      },
    });
  },
  onHeightMove: function (event) {
    if (
      window.editor.getSelectedObject() &&
      window.editor.getSelectedObject().skewHeight
    ) {
      var offsetX = Math.pow(
        Math.abs(
          event.data.global.x / window.editor.getCutoutStage().scale.x -
            window.editor.getSelectedObject().x
        ),
        2
      );
      var offsetY = Math.pow(
        Math.abs(
          event.data.global.y / window.editor.getCutoutStage().scale.y -
            window.editor.getSelectedObject().y
        ),
        2
      );
      var radius = Math.sqrt(offsetX + offsetY);

      //var newHeight = (Math.abs(event.data.global.y - selectedObject.y) * 2) - this.originalY;
      window.editor.getSelectedObject().height = radius * 2;
      StickerCutoutSelector.updateSelector();
      window.editor.resizeText(window.editor.getSelectedObject());
      if (window.editor.getSelectedObject().crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          window.editor.getSelectedObject().x -
            window.editor.getSelectedObject().width / 2,
          window.editor.getSelectedObject().y -
            window.editor.getSelectedObject().height / 2,
          window.editor.getSelectedObject().width,
          window.editor.getSelectedObject().height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
  onWidthStart: function (event) {
    event.stopPropagation();
    if (window.editor.getSelectedObject()) {
      window.editor.getSelectedObject().data = event.data;
      window.editor.getSelectedObject().skewHeight = false;
      window.editor.getSelectedObject().skewWidth = true;
      window.editor.getSelectedObject().scaling = false;
      window.editor.getSelectedObject().dragging = false;
      window.editor.scaleStartWidth = window.editor.getSelectedObject().width;
      window.editor.scaleStartHeight = window.editor.getSelectedObject().height;
      this.originalX =
        window.editor.getSelectedObject().width -
        Math.abs(event.data.global.x - window.editor.getSelectedObject().x) * 2;
    }
  },
  onWidthEnd: function () {
    if (window.editor.getSelectedObject()) {
      var id = window.editor.getSelectedObject().id;
      var startwidth = window.editor.scaleStartWidth;
      var finishwidth = window.editor.getSelectedObject().width;
      window.editor.getSelectedObject().scaling = false;
      window.editor.getSelectedObject().skewHeight = false;
      window.editor.getSelectedObject().skewWidth = false;
      window.editor.getSelectedObject().data = null;
      window.editor.CommandManager.execute({
        execute: function () {
          var sticker = window.editor.getCutoutStickerById(id);
          if (sticker) sticker.width = finishwidth;
        },
        unexecute: function () {
          var sticker = window.editor.getCutoutStickerById(id);
          sticker.width = startwidth;
        },
      });
    }
  },
  onWidthMove: function (event) {
    var radius;
    var offsetX;
    var offsetY;
    if (
      window.editor.getSelectedObject() &&
      window.editor.getSelectedObject().skewWidth
    ) {
      if (window.editor.getSelectedObject() instanceof PIXI.Text) {
        offsetX = Math.pow(
          Math.abs(
            event.data.global.x / window.editor.getCutoutStage().scale.x -
              window.editor.getSelectedObject().x
          ),
          2
        );
        offsetY = Math.pow(
          Math.abs(
            event.data.global.y / window.editor.getCutoutStage().scale.y -
              window.editor.getSelectedObject().y
          ),
          2
        );
        radius = Math.sqrt(offsetX + offsetY);
        var longestWord = 0;
        // var words = window.editor.getSelectedObject().text.split(" ");
        var words = window.editor.getSelectedObject().text.split("\n");
        for (var i = 0; i < words.length; i++) {
          var wordLength = window.editor
            .getSelectedObject()
            .context.measureText(words[i]).width;
          if (wordLength > longestWord) longestWord = wordLength;
        }
        var style = window.editor.getSelectedObject().style;
        style.wordWrapWidth = radius * 2;
        if (longestWord > style.wordWrapWidth)
          style.wordWrapWidth = longestWord;
        if (style.wordWrapWidth > window.editor.getBaseObject().width * 0.8) {
          style.wordWrapWidth = window.editor.getBaseObject().width * 0.8;
        }
        window.editor.getSelectedObject().style = style;
        StickerCutoutSelector.updateSelector();
      } else {
        offsetX = Math.pow(
          Math.abs(
            event.data.global.x / window.editor.getCutoutStage().scale.x -
              window.editor.getSelectedObject().x
          ),
          2
        );
        offsetY = Math.pow(
          Math.abs(
            event.data.global.y / window.editor.getCutoutStage().scale.y -
              window.editor.getSelectedObject().y
          ),
          2
        );
        radius = Math.sqrt(offsetX + offsetY);

        window.editor.getSelectedObject().width = radius * 2;
        StickerCutoutSelector.updateSelector();
        window.editor.resizeText(window.editor.getSelectedObject());
        if (window.editor.getSelectedObject().crop) {
          var mask = new PIXI.Graphics();
          mask.beginFill(0xffffff, 1);
          mask.drawRect(
            window.editor.getSelectedObject().x -
              window.editor.getSelectedObject().width / 2,
            window.editor.getSelectedObject().y -
              window.editor.getSelectedObject().height / 2,
            window.editor.getSelectedObject().width,
            window.editor.getSelectedObject().height
          );
          mask.endFill();
          window.editor.getBaseObject().mask = mask;
        }
      }
    }
  },
  onRotateStart: function (event) {
    event.stopPropagation();
    window.editor.getSelectedObject().rotating = true;
    window.editor.getSelectedObject().scaling = false;
    window.editor.getSelectedObject().skewHeight = false;
    window.editor.getSelectedObject().skewWidth = false;
  },
  onRotateMove: function (event) {
    if (
      window.editor.getSelectedObject() &&
      window.editor.getSelectedObject().rotating
    ) {
      var rotateRadian = Math.atan2(
        window.editor.getSelectedObject().y -
          event.data.global.y / window.editor.getCutoutStage().scale.x,
        window.editor.getSelectedObject().x -
          event.data.global.x / window.editor.getCutoutStage().scale.x
      );
      window.editor.getSelectedObject().rotation = rotateRadian - 1.6;
      if (window.editor.getSelectedObject().childId) {
        var sticker = window.editor.getStickerById(
          window.editor.getSelectedObject().childId
        );
        sticker.rotation = rotateRadian - 1.6;
      }

      StickerCutoutSelector.updateSelector();
    }
  },
  onRotateEnd: function () {
    var id = window.editor.getSelectedObject().id;
    window.editor.getSelectedObject().rotating = false;
    window.editor.getSelectedObject().scaling = false;
    window.editor.getSelectedObject().skewHeight = false;
    window.editor.getSelectedObject().skewWidth = false;
    window.editor.getSelectedObject().data = null;
    var finishRotation = window.editor.getSelectedObject().rotation;
    var startRotation = window.editor.rotateStart;
    window.editor.CommandManager.execute({
      execute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        sticker.rotation = finishRotation;
      },
      unexecute: function () {
        var sticker = window.editor.getCutoutStickerById(id);
        sticker.rotation = startRotation;
      },
    });
  },
};
export default StickerCutoutSelector;
