import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const CutoutTrace = () => {
  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutTrace(false);
  };
  const _onNext = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutTrace(false);
  };
  const _onBack = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutTrace(false);
    window.editor.onCutoutCropReset();
  };

  return (
    <div className="dialog-background">
      <div className="cutout-dialog-box cutout-style-dialog">
        <div className="filepicker-navbar">
          <div className="cutout-title">
            Step 2: Click points around the edge
          </div>
          <div className="close-btn" onClick={_cancelClick}>
            <CloseSVG height={12} />
          </div>
        </div>
        <p>
          Connect the dots by CLICKING POINTS AROUND THE EDGE of your shape.
        </p>
        <p>
          Make sure to go ALL THE WAY AROUND back to the first point to complete
          the shape.
        </p>
        <p>
          Don’t worry if you make a mistake, you will be able to fix it in step
          3.
        </p>
        <div className="cutout-traces-images">
          <img
            src="https://static.pizap.com/html5/resources/editor/cutout-trace1.jpg"
            alt=""
          />
          <img
            src="https://static.pizap.com/html5/resources/editor/cutout-trace2.jpg"
            alt=""
          />
          <img
            src="https://static.pizap.com/html5/resources/editor/cutout-trace3.jpg"
            alt=""
          />
        </div>
        <button
          className="btn my-btn cutoutSkipBtn"
          content="SKIP"
          onClick={_onBack}
        >
          Back
        </button>
        <button className="btn my-btn cutoutFinishBtn" onClick={_onNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CutoutTrace;
