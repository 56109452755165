import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { useNavigate } from "react-router-dom";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import Lockr from "lockr";

const DeleteAccountConfirmModal = () => {
  const navigate = useNavigate();
  const handleClickCancel = () => {
    EditorActionCreators.showDeleteAccount(false);
  };

  const handleClickDelete = async () => {
    var logins = Lockr.get("logins");
    if (!logins) logins = [];
    const updatedLogins = logins.filter(
      (login) => login.user.UserName !== UserStore.getUser().UserName
    );
    Lockr.set("logins", updatedLogins);
    await fetch(
      `${process.env.REACT_APP_SERVER_API}/user/${encodeURI(
        UserStore.getUser().UserName
      )}`,
      {
        method: "delete",
        headers: new Headers({
          Authorization: `Bearer ${UserStore.getAccessToken()}`,
          "Content-Type": "application/json",
        }),
      }
    );
    UserActionCreators.logout();
  };

  return (
    <div className="filepicker-dialog active">
      <div className="sticker-error-modal" align="center">
        <div className="modal-body">
          <span>
            Are you sure you want to permanently delete your account and all
            assosiated data?
          </span>
        </div>
        <div className="modal-footer d-flex">
          <button className="clickable delete-btn" onClick={handleClickCancel}>
            Cancel
          </button>
          <div className="clickable confirm-btn" onClick={handleClickDelete}>
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountConfirmModal;
