import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const YoutubeCovermakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="YouTube Cover Maker | YouTube Header Banner Creator"
        description="Design a YouTube Cover for your YouTube Channel with our online YouTube Cover Maker."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/youtube_cover_maker_app"
        keywords="YouTube Cover Maker, YouTube header cover creator"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">YouTube Cover Maker</p>
          <p className="desc">
            Attract more people to your channel with the best YouTube Banner
            Maker
          </p>
          <StartButton
            text="Edit a photo →"
            handleClick={() => {
              navigate("/app/?type=edit");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/youtube_cover_maker_editor_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/youtube_cover_maker_editor_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">YouTube Cover Maker</span>
            <span className="desc">
              What does your YouTube Banner say about you? The YouTube Cover
              Maker by piZap transforms lackluster or unproportioned banners
              into eye-catching works of art. And it’s super simple! All you
              must do is upload your photo and click a few buttons. You can
              download the YouTube Cover Maker app or use directly on a desktop
              computer.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">YouTube Cover Maker Tools</span>
            <span className="desc">
              Catchy YouTube banners are a basic necessity, hence why we added a
              YouTube Cover Maker tool to piZap’s photo editing features. The
              YouTube banner size is 2560 x 1440 pixels. Simply upload the photo
              you’d like to use and piZap automatically formats your image for
              proper sizing. Don’t have a photo this large? Consider creating a
              collage of smaller photos, which can easily be done within the
              piZap app. With so many tools at your disposal, anything is
              possible.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/youtube_cover_maker_editor_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/youtube_cover_maker_editor_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Easily Create the Perfect YouTube Cover
            </span>
            <span className="desc">
              It’s a lot of work creating a super-star YouTube channel. Why not
              let piZap zap some of the hard work right out of your life? The
              YouTube Cover Maker feature is incredibly easy to use. After all,
              piZap is on a mission to make it easier than ever to edit photos
              and create digital designs. Furthermore, you can use piZap’s array
              of editing tools to adjust brightness, fix flaws, add stickers,
              and so forth, all to make your banner photo that much better. It’s
              time to enjoy a world where creating a YouTube banner doesn’t
              provoke you to hit your head against the wall.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Frustration-free Fun</span>
            <span className="desc">
              Save yourself some headaches and start having fun creating YouTube
              banners. Insert more fun to your cover banner by adding stickers
              or text boxes with special font effects. Or, keep it simple with
              clean photo edits easily uploaded to the YouTube Cover Maker tool
              for resizing. Your options are unlimited and all at your
              fingertips. That’s what makes piZap so fun to use for everyone.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/youtube_cover_maker_editor_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/youtube_cover_maker_editor_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>YouTube Cover Maker + Many More Features</h3>
            </span>
            <span className="desc">
              piZap offers a lot more than just a place to create YouTube
              banners. You can also create cover photos for Instagram, Facebook
              and other popular social media sites. Plus, you can edit your
              photos to perfection using piZap’s basic editing tools. Add a
              filter or make your cover photo black and white. With all the
              features piZap offers, your YouTube page will look better than
              ever—see for yourself, for free!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default YoutubeCovermakerPage;
