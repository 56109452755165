import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PhotoCropPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Photo Cropping Tool | Crop a Photo Online"
        description="Online photo cropping tool lets you crop a photo online to the desired size."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/photo_crop_tool_app"
        keywords="photo cropping tool, crop photo, photo crop tool"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Photo Cropping Tool</p>
          <p className="desc">
            Crop photos like a pro in seconds using piZap’s Photo Cropping Tool
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/start");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_crop_tool_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_crop_tool_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Photo Cropping Tool</span>
            <span className="desc">
              Meet the ultimate Photo Cropping Tool that lets you crop photos
              like an editing expert. piZap gives you the tools to edit your
              photos the way you want.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Photo Cropping Tool Overviews</span>
            <span className="desc">
              Finally, a photo cropping tool that lets you crop your photos any
              way you want. Remove an unwanted photo bomber or crop photos to
              fit a specific size with the click of a button. There are in-app
              tools that’ll automatically crop your photos to fit Facebook,
              YouTube, or Twitter cover photos. There’s also a Cut-Out Tool to
              remove any person or object from one image and move it to another
              image or background.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_crop_tool_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_crop_tool_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">How to Easily Crop Photos</span>
            <span className="desc">
              Not all photo cropping tools are made equal, not even close. piZap
              takes the cake for the best way to crop photos, simply because
              it’s easy to use without being limiting. Typically, easy photo
              editing apps don’t offer much variety in terms of how you can crop
              photos, that’s where piZap is different. You have so many options
              to crop photos and even more options to edit and design your newly
              cropped designs. Get ready to feel like a pro in minutes!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Way to Crop Photos</span>
            <span className="desc">
              Not only is piZap’s photo cropping tool easy to use but it’s fun
              to use too. Pictures that never served any purpose gain value
              thanks to the fact you can crop and edit them any way you want.
              How fun is that? Get excited about adding borders and frames to
              cropped photos, along with stickers, text effects and playful
              fonts. piZap makes it so fun to edit and crop your photos you just
              might get addicted—don’t say we didn’t warn you!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_crop_tool_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_crop_tool_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Photo Cropping Tool + SO Much More</span>
            <span className="desc">
              piZap’s Photo Cropping Tool is just the start of many photo
              editing and design tools packed into this app. In fact, piZap is
              ready to zap your pictures and picture-related creations to
              another galaxy thanks to the number of tools and features at your
              fingertips. Change filters and add borders, stickers, and text
              effects; you can even create a custom Emoji. Take your cropped
              photos and turn them into invitations, posters, MEMEs, and more.
              It’s time to take your photos to the next level in a zap, all
              thanks to piZap! .
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PhotoCropPage;
