import React, { useEffect, useState } from "react";
import EditorStore from "../../stores/EditorStore";
import ColorPicker from "../ColorPicker.react";
import RangeSlider from "../RangeSlider";
import LeftMenuActionCreators from "../../actions/LeftMenuActionCreators";

function getStateFromStores() {
  return {
    topColor: window.editor.meme.top.getColor(),
    bottomColor: window.editor.meme.bottom.getColor(),
    topText: window.editor.meme.top.getText(),
    bottomText: window.editor.meme.bottom.getText(),
    topSize: window.editor.meme.top.getSize(),
    bottomSize: window.editor.meme.bottom.getSize(),
  };
}

const MenuMeme = () => {
  const [data, setData] = useState(getStateFromStores());
  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _topText = (event) => {
    window.editor.meme.top.setText(event.target.value);
  };

  const _onTopSizeChange = (value) => {
    window.editor.meme.top.setSize(value);
  };

  const handleTopTextReset = () => {
    window.editor.meme.top.setSize(28);
  };

  const _onBottomSizeChange = (value) => {
    window.editor.meme.bottom.setSize(value);
  };

  const handleBottomTextReset = () => {
    window.editor.meme.bottom.setSize(28);
  };

  const _onTopColorPicked = (colorCode) => {
    window.editor.meme.top.setColor(colorCode);
  };

  const _onBottomColorPicked = (colorCode) => {
    window.editor.meme.bottom.setColor(colorCode);
  };

  const _bottomText = (event) => {
    window.editor.meme.bottom.setText(event.target.value);
  };

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _onTopClear = () => {
    window.editor.meme.top.setText("");
  };

  const _onBottomClear = () => {
    window.editor.meme.bottom.setText("");
  };

  const handleClickBack = () => {
    LeftMenuActionCreators.MenuSelected("text");
    window.editor.setSelectedObject(null);
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header" onClick={handleClickBack}>
        <div className="clickable d-flex-start">
          <svg viewBox="0 0 960 560" transform="rotate(90)" height={14}>
            <path
              className="st0"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          <span>Meme</span>
        </div>
      </div>
      <div className="scrollContainer memeContainer">
        <div id="menu-meme-top">
          <span className="memeHeader">Top Text:</span>
          <div className="d-flex">
            <ColorPicker
              text="Color"
              onColor={_onTopColorPicked}
              initialColor={data.topColor}
              opacity={1}
            />
            <input
              id="memeTopText"
              name="memeTopText"
              type="text"
              className="upper meme-text"
              onChange={_topText}
              value={data.topText ? data.topText : ""}
              placeholder="Enter text here"
            />
            {data.topText && (
              <span className="memeClear clickable" onClick={_onTopClear}>
                X
              </span>
            )}
          </div>
          <div className="meme-font-size">
            <RangeSlider
              title="Font Size"
              min={20}
              max={300}
              step={2}
              value={data.topSize}
              handleChange={_onTopSizeChange}
              handleReset={handleTopTextReset}
              isReset={data.topSize !== 28}
              isBox={false}
              handleMouseEnter={() => {}}
              handleMouseLeave={() => {}}
              startPoint={20}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div id="menu-meme-bottom">
          <span className="memeHeader">Bottom Text:</span>
          <div className="d-flex">
            <ColorPicker
              text="Color"
              onColor={_onBottomColorPicked}
              initialColor={data.bottomColor}
              opacity={1}
            />
            <input
              id="memeBottomText"
              name="memeBottomText"
              type="text"
              className="upper meme-text"
              onChange={_bottomText}
              value={data.bottomText ? data.bottomText : ""}
              placeholder="Enter text here"
            />
            {data.bottomText && (
              <span className="memeClear clickable" onClick={_onBottomClear}>
                X
              </span>
            )}
          </div>
          <div className="meme-font-size">
            <RangeSlider
              title="Font Size"
              min={20}
              max={300}
              step={2}
              value={data.bottomSize}
              handleChange={_onBottomSizeChange}
              handleReset={handleBottomTextReset}
              isReset={data.bottomSize !== 28}
              isBox={false}
              handleMouseEnter={() => {}}
              handleMouseLeave={() => {}}
              startPoint={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuMeme;
