import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import TextEdit from "./TextEdit.react";
import { ReactComponent as ProBadgeSVG } from "../images/editor/pro_badge.svg";
import PlayStoreBadge from "../images/editor/play_store_badge.png";
import AppStoreBadge from "../images/editor/app_store_badge.png";

const UserStatus = () => {
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef();
  const user = UserStore.getUser();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const _logout = () => {
    setIsMenuOpen(false);
    UserActionCreators.logout();
    UserActionCreators.showHamburgerMenu(false);
  };

  const _reportBug = () => {
    setIsMenuOpen(false);
    UserActionCreators.showHamburgerMenu(false);
    window.open("https://digipal.wufoo.com/forms/wf7ewo30714ggj/", "_blank");
  };

  const _onHelp = (event) => {
    setIsMenuOpen(false);
    UserActionCreators.showHamburgerMenu(false);
    window.open("https://pizapsupport.zendesk.com", "_blank");
  };

  const handleDeleteAccount = (e) => {
    EditorActionCreators.showDeleteAccount(true);
    setIsMenuOpen(false);
  };

  const _invite = () => {
    UserActionCreators.showHamburgerMenu(false);
    window.FB.ui({
      method: "send",
      name: "Photo Editor & Collage Maker",
      link: "https://www.pizap.com",
      picture:
        "https://cdn.pizap.com/pizapfiles/images/pizap-screenshot-invite.jpg",
    });
  };

  const onProClick = () => {
    setIsMenuOpen(false);
    if (UserStore.isPro()) {
      fetch(`${process.env.REACT_APP_SERVER_API}/user/managepro`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (proResp) {
          if (proResp.url) {
            window.open(proResp.url, "_blank");
          }
        });
    } else {
      UserActionCreators.showProGet(true);
    }
  };

  const emailEdited = (newEmail) => {
    EditorActionCreators.updateEmail(newEmail);
    UserStore.emitChange();
  };

  const handleSetEmailEdit = () => {
    setIsEmailEdit(true);
  };

  const getLoginType = useCallback(() => {
    if (user) {
      if (user?.GooglePlusUserId) {
        return "Google";
      } else if (user?.fbuserId || /^[0-9]*$/.test(user.UserName)) {
        return "Facebook";
      } else if (user?.twitterUserId) {
        return "Twitter";
      } else {
        return "Email";
      }
    }
  }, [user]);

  return (
    <div ref={ref}>
      <div className="hamburger main-profile-image">
        <span
          className="profile-name"
          onClick={() => setIsMenuOpen((oldState) => !oldState)}
          title="menu"
        >
          <img src="//cdn.pizap.com/images/pizap_user_icon.png" width={25} />
        </span>
        {isMenuOpen && (
          <div className="hamburgerMenu main-page">
            {!UserStore.isPro() ? (
              <div className="proStatus">
                <span className="proStatus-left" onClick={onProClick}>
                  <ProBadgeSVG />
                  <span style={{ paddingLeft: "15px !important" }}>
                    Try&nbsp;
                  </span>
                  <b style={{ fontWeight: "bold", cursor: "pointer" }}>
                    piZap Pro&nbsp;
                  </b>
                  for free
                </span>
                <a nohref="true" onClick={onProClick}>
                  learn more
                </a>
              </div>
            ) : (
              <div className="proStatus">
                <span className="proStatus-left">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1500 1080"
                    enableBackground="new 0 0 1500 1080"
                    height="22"
                  >
                    <g transform="matrix( 8.704086303710938, 0, 0, 8.704086303710938, 109.4,28) ">
                      <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                        <g>
                          <g id="Symbol_1_0_Layer0_0_FILL">
                            <path
                              fill="#FFC200"
                              d="M19.4,112.5c0,1.4,0.5,2.7,1.5,3.7s2.2,1.5,3.6,1.5h98.2c1.4,0,2.7-0.5,3.7-1.5s1.5-2.2,1.5-3.7
					s-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5H24.5c-1.4,0-2.6,0.5-3.6,1.5C19.9,109.9,19.4,111.1,19.4,112.5 M127.9,40.3
					c0,2.7,0.9,4.9,2.8,6.8c0.1,0.1,0.2,0.2,0.2,0.2l-30.2,16L76.9,18.8c1.3-0.5,2.5-1.3,3.6-2.3c1.9-1.9,2.8-4.1,2.8-6.8
					s-0.9-4.9-2.8-6.8c-0.4-0.4-0.8-0.7-1.2-1C77.7,0.6,75.8,0,73.7,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.7,1-6.5,2.8
					C65,4.7,64.1,7,64.1,9.6s0.9,4.9,2.8,6.8c1,1,2.2,1.8,3.5,2.2l-24,44.7l-30.1-16c0.1-0.1,0.1-0.2,0.2-0.2
					c1.9-1.9,2.8-4.1,2.8-6.8c0-2.7-0.9-5-2.8-6.8c-1.9-1.9-4.1-2.8-6.8-2.8c-0.8,0-1.5,0.1-2.2,0.2c-1.7,0.4-3.3,1.2-4.6,2.5
					c-1.5,1.5-2.4,3.3-2.7,5.4C0,39.4,0,39.8,0,40.3c0,2.7,0.9,4.9,2.8,6.8C4.7,49,7,50,9.6,50c0.6,0,1.2,0,1.8-0.2l10.2,49.3h104
					l10.2-49.3c0.6,0.1,1.1,0.2,1.8,0.2c2.7,0,4.9-1,6.8-2.8c1.9-1.9,2.8-4.1,2.8-6.8c0-0.5,0-1-0.1-1.4c-0.3-2.1-1.2-3.9-2.7-5.4
					c-1.4-1.3-2.9-2.2-4.6-2.5c-0.7-0.2-1.4-0.2-2.2-0.2c-2.7,0-4.9,0.9-6.8,2.8C128.8,35.4,127.9,37.7,127.9,40.3z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <b style={{ fontWeight: "bold", paddingLeft: "15px" }}>
                    piZap Pro
                  </b>
                  <span className="proStatusPro">active</span>
                </span>
                <a nohref="true" onClick={onProClick}>
                  manage
                </a>
              </div>
            )}
            <div className="divide-line"></div>
            {user && user.UserName && (
              <div className="hamburgerProfile">
                <div className="userInfo">
                  <div className="loginType">
                    <div className="label">Login type: </div>
                    {getLoginType()}
                  </div>
                  <div className="accountId">
                    <div className="label">Account ID: </div>
                    {user.UserName}
                  </div>
                  {getLoginType() === "Email" && (
                    <div className="email">
                      <div className="email-left">
                        {!isEmailEdit && <div className="label">Email: </div>}
                        <TextEdit
                          text={user.Email}
                          onEditing={true}
                          expanded={isEmailEdit}
                          setEdit={setIsEmailEdit}
                          handleChange={emailEdited}
                        />
                      </div>
                      {!isEmailEdit && (
                        <div
                          className="email-right"
                          onClick={handleSetEmailEdit}
                        >
                          edit
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="divide-line"></div>
            <div className="profile-public">
              <span onClick={_reportBug}>Feedback &amp; Suggestions</span>
              <span onClick={_invite}>Invite Friends on Facebook</span>
              <Link to="/privacypolicy">
                <span>Privacy &amp; Terms</span>
              </Link>
              {user && user.UserName && <span onClick={_logout}>Log Out</span>}
              <span onClick={_onHelp}>Help</span>
              <span onClick={handleDeleteAccount}>Delete Account</span>
            </div>
            <div className="divide-line"></div>
            <div className="d-flex app-store-container">
              <div className="app-store">
                <a
                  href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8"
                  target="_blank"
                >
                  <img src={AppStoreBadge} alt="App Store" />
                </a>
              </div>
              <div className="app-store">
                <a
                  href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap"
                  target="_blank"
                >
                  <img src={PlayStoreBadge} alt="Play Store" />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserStatus;
