import React from 'react';

const AccordionSubCategory = (props) => {

    const { category, onSelected } = props;

    const _onSelected = (event) => {
        event.preventDefault();
        onSelected(category);
    }

    return (
        <div className="clickable" onClick={_onSelected}>
            <div className="accordion-sub-category">
                <div className="image-wrapper">
                    <img src={category.Thumb} alt="thumbnail" />
                </div>
                <span className="category-name">{category.Name}</span>
            </div>
        </div>
    );
}

export default AccordionSubCategory;
