import React, { useEffect, useState, useRef, useMemo } from "react";
import AssetStore from "../stores/AssetStore";
import UserStore from "../stores/UserStore";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import JSONUtils from "../utils/JSONUtils";
import CustomStickers from "./CustomStikers";
import StickerScrollPhotos from "./StickerScrollPhotos.react";
import PixabayImageSrc from "../images/editor/pixabay_logo.svg";
import UnsplashImageSrc from "../images/editor/unsplash_wordmark_logo.svg";
import { ReactComponent as IconSearch } from "../images/editor/search_icon.svg";
import Masonry from "react-responsive-masonry";
import MasonryItem from "./MasonryItem";
import Lockr from "lockr";
import EditorStore from "../stores/EditorStore";
import SkeletonLoading from "./SkeletonLoadingImage";
import IconEmpty from "../images/editor/empty-folder.png";

const getStateFromStores = () => {
  return {
    featuredBackgrounds: AssetStore.getFeaturedBackgrounds(),
    featuredBackgroundsMeme: AssetStore.getFeaturedBackgroundsMeme(),
    featuredBackgroundsEmoji: AssetStore.getFeaturedBackgroundsEmoji(),
    folders: AssetStore.getAllStickerSVGFolders(),
    featuredGraphics: AssetStore.getFeaturedGraphics(),
    cachedKey: AssetStore.getCachedPhotos(),
    uploads: UserStore.getProjectAssets(),
    rememberPhotos: AssetStore.getRememberPhotos(),
  };
};

const MenuPhotos = (props) => {
  const [storesData, setStoresData] = useState(getStateFromStores());
  const stickerContainer = useRef();
  const [showResults, setShowResults] = useState(false);
  const [isSeeAll, setIsSeeAll] = useState(false);
  const [selectedTab, setSelectedTab] = useState("pixabay");
  const [isShowTopNav, setIsShowTopNav] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [results, setResults] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [backgrounds, setBackgrounds] = useState([]);
  const featuredApp = Lockr.get("featuredApp");
  useEffect(() => {
    if (storesData.cachedKey.title && storesData.rememberPhotos) {
      if (storesData.cachedKey.title === "Uploads") {
        setIsShowTopNav(false);
      } else {
        setIsShowTopNav(true);
      }
      setSelectedTab(storesData.cachedKey.activeTab);
      setSearchText(storesData.cachedKey.title);
      setIsSeeAll(true);
      setShowResults(true);
      let items = [];
      if (
        featuredApp === "emoji" &&
        storesData.featuredBackgroundsEmoji &&
        storesData.featuredBackgroundsEmoji.length > 0
      ) {
        items = storesData.featuredBackgroundsEmoji;
      } else if (
        featuredApp === "meme" &&
        storesData.featuredBackgroundsMeme &&
        storesData.featuredBackgroundsMeme.length > 0
      ) {
        items = storesData.featuredBackgroundsMeme;
      } else {
        items = storesData.featuredBackgrounds;
      }
      const item = items.filter(
        (item) =>
          item.tag.toLowerCase() === storesData.cachedKey.title.toLowerCase()
      );

      const isPro = UserStore.isPro();
      let data = [];
      if (item.length > 0) {
        data = item[0].pizapAssets.map((i, idx) => {
          let isFree = true;
          if (
            i.tags &&
            i.tags.find(
              (element) =>
                typeof element.toLowerCase === "function" &&
                element.toLowerCase() === "pizappro"
            ) &&
            !isPro
          ) {
            i.thumbnailCaption = <ProCrown key={i.imageName} />;
            isFree = false;
          }
          return {
            icon: i.thumbnail,
            isProOnly: isFree,
            src: i.url,
          };
        });
      } else {
        data = [];
      }
      LeftMenuActionCreators.StickerFolderSelected({
        Name: storesData.cachedKey.title,
        items:
          storesData.cachedKey.title === "Uploads"
            ? storesData.cachedKey.items
            : data,
        isGoogle: false,
      });
      if (selectedTab !== "piZap") setResults(storesData.cachedKey.items);
    } else {
      clearSearch();
    }
  }, [storesData.rememberPhotos]);

  useEffect(() => {
    if (
      featuredApp === "emoji" &&
      storesData.featuredBackgroundsEmoji &&
      storesData.featuredBackgroundsEmoji.length > 0
    ) {
      setBackgrounds(storesData.featuredBackgroundsEmoji);
    } else if (
      featuredApp === "meme" &&
      storesData.featuredBackgroundsMeme &&
      storesData.featuredBackgroundsMeme.length > 0
    ) {
      setBackgrounds(storesData.featuredBackgroundsMeme);
    } else {
      setBackgrounds(storesData.featuredBackgrounds);
    }
  }, [
    storesData.featuredBackgrounds,
    storesData.featuredBackgroundsEmoji,
    storesData.featuredBackgroundsMeme,
    featuredApp,
  ]);

  useEffect(() => {
    if (props.isReset) {
      clearSearch();
    }
  }, [props.isReset]);

  useEffect(() => {
    if (stickerContainer && stickerContainer.current) {
      stickerContainer.current.scrollTop = 0;
    }
  }, [showResults]);

  useEffect(() => {
    const handleChange = () => {
      setStoresData(getStateFromStores());
    };
    AssetStore.addChangeListener(handleChange);
    UserStore.addChangeListener(handleChange);
    return () => {
      AssetStore.removeChangeListener(handleChange);
      UserStore.removeChangeListener(handleChange);
    };
  }, []);

  const handleClickProStock = async () => {
    setSelectedTab("piZap");
  };

  const handleClickPixabay = () => {
    setSelectedTab("pixabay");
    setIsLoading(true);
    JSONUtils.getJSON(
      "https://www.pizap.com/pixabay/search?q=" + encodeURI(searchText),
      "GET",
      null,
      function (data) {
        if (data.hits) {
          const res = data.hits.map((item) => {
            return {
              key: item.id,
              src: item.imageURL,
              icon: item.previewURL,
              originalItem: item,
            };
          });
          setResults(res);
          setIsLoading(false);
          AssetStore.setCachedPhotos({
            title: searchText,
            activeTab: "pixabay",
            items: res,
          });
        }
      }
    );
    AssetStore.emitChange();
  };

  const handleClickUnsplash = () => {
    setSelectedTab("unsplash");
    setIsLoading(true);
    JSONUtils.getJSON(
      `${process.env.REACT_APP_SERVER_API}/unsplash?query=${encodeURI(
        searchText
      )}`,
      "GET",
      null,
      function (data) {
        if (data.total) {
          setIsLoading(false);
          const res = data.results.map((item) => {
            return {
              key: item.id,
              src: item.urls.regular,
              icon: item.urls.thumb,
              originalItem: item,
            };
          });
          setResults(res);
          AssetStore.setCachedPhotos({
            title: searchText,
            activeTab: "unsplash",
            items: res,
          });
        }
      }
    );
    AssetStore.emitChange();
  };

  const clearSearch = () => {
    setSearchText("");
    setSearchResults([]);
    setResults([]);
    setShowResults(false);
    setIsSeeAll(false);
    setIsSearch(false);
    AssetStore.setCachedPhotos({
      title: null,
      activeTab: "piZap",
      items: null,
    });
    AssetStore.setSelectedItem(null);
  };

  const keyPress = async (e) => {
    if (e.key === "Enter") {
      setIsSeeAll(false);
      setSelectedTab("pixabay");
      setIsShowTopNav(true);
      setShowResults(true);
      setResults([]);
      handleClickPixabay();
      setIsSearch(true);
      LeftMenuActionCreators.ResetMenu(false);
    }
  };

  const addYourOwn = () => {
    EditorStore.setFilePickerTool(null);
    EditorActionCreators.ShowFilePicker(true);
    window.editor.setSelectedObject(null);
  };

  const handleChangeSearch = (event) => {
    setSearchText(event.currentTarget.value);
  };

  const showAllResults = (key, items) => {
    LeftMenuActionCreators.ResetMenu(false);
    setStoresData(getStateFromStores());
    setIsSeeAll(true);
    setShowResults(true);
    setSelectedTab("piZap");
    setResults([]);
    setSearchResults([]);
    setSearchText(key);
    if (key === "Uploads") {
      setIsShowTopNav(false);
      let newItems = items.map((item) => {
        return {
          src: item.src,
          icon: item.thumbnail,
          isProOnly: false,
        };
      });
      LeftMenuActionCreators.StickerFolderSelected({
        Name: key,
        items: newItems,
        isGoogle: false,
      });
      AssetStore.setCachedPhotos({
        title: key,
        activeTab: "piZap",
        items: newItems,
      });
    } else {
      setIsShowTopNav(true);
      let newItems = items.map((item, index) => {
        let isPRO = false;
        if (index >= 2) isPRO = true;
        return {
          src: item.url,
          icon: item.thumbnail,
          isProOnly: isPRO,
        };
      });
      LeftMenuActionCreators.StickerFolderSelected({
        Name: key,
        items: newItems,
        isGoogle: false,
      });
      AssetStore.setCachedPhotos({
        title: key,
        activeTab: "piZap",
        items: newItems,
      });
    }
  };

  const folderListItems = useMemo(() => {
    if (showResults) {
      if (isLoading) {
        return (
          <div className="menu-photo-skeleton-container">
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
            <SkeletonLoading width={125} height={93} />
          </div>
        );
      } else {
        if (selectedTab === "piZap") {
          if (
            AssetStore.getSelectedItem() &&
            AssetStore.getSelectedItem().items
          ) {
            return (
              <Masonry columnsCount={2} gutter="10px">
                {AssetStore.getSelectedItem().items.map((item, idx) => {
                  return (
                    <MasonryItem
                      key={idx}
                      item={item}
                      type="sticker"
                      isbing={false}
                    />
                  );
                })}
              </Masonry>
            );
          } else {
            return (
              <img
                className="empty-folder"
                style={{ width: "96px !important" }}
                src={IconEmpty}
              />
            );
          }
        } else if (selectedTab === "pixabay") {
          if (results && results.length > 0) {
            return (
              <Masonry columnsCount={2} gutter="10px">
                {results.map((item, idx) => {
                  return (
                    <MasonryItem
                      key={idx}
                      item={item}
                      type="sticker"
                      isbing={false}
                    />
                  );
                })}
              </Masonry>
            );
          } else {
            return (
              <img
                className="empty-folder"
                style={{ width: "96px !important" }}
                src={IconEmpty}
              />
            );
          }
        } else if (selectedTab === "unsplash") {
          if (results && results.length > 0) {
            return (
              <Masonry columnsCount={2} gutter="10px">
                {results.map((item, idx) => {
                  return (
                    <MasonryItem
                      key={idx}
                      item={item}
                      type="sticker"
                      isbing={false}
                    />
                  );
                })}
              </Masonry>
            );
          } else {
            return (
              <img
                className="empty-folder"
                style={{ width: "96px !important" }}
                src={IconEmpty}
              />
            );
          }
        }
      }
    } else {
      return null;
    }
  }, [showResults, selectedTab, isLoading, results, searchResults, isSeeAll]);

  const content = useMemo(() => {
    if (showResults) {
      return (
        <div
          ref={stickerContainer}
          className="graphicContainer"
          style={{
            padding: "12px",
            height:
              showResults && isShowTopNav
                ? "calc(100% - 102px)"
                : "calc(100% - 68px)",
          }}
        >
          {folderListItems}
        </div>
      );
    } else {
      return (
        backgrounds &&
        backgrounds.length > 0 && (
          <div
            className="scrollContainer stickerMenuContainer"
            ref={stickerContainer}
          >
            {storesData.uploads && storesData.uploads.length > 0 && (
              <CustomStickers
                items={storesData.uploads}
                categoryName="Uploads"
                seeall={showAllResults}
              />
            )}
            {backgrounds.map((listItem, index) => {
              return (
                <StickerScrollPhotos
                  key={index}
                  items={listItem.pizapAssets}
                  categoryName={listItem.tag}
                  seeall={showAllResults}
                />
              );
            })}
          </div>
        )
      );
    }
  }, [
    showResults,
    backgrounds,
    folderListItems,
    isShowTopNav,
    storesData.uploads,
  ]);

  const handleAITool = () => {
    EditorActionCreators.SetPickerState("ai_image");
    EditorActionCreators.ShowFilePicker(true, "ai_image");
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        {showResults ? (
          <div className="photos-nav">
            <div className="clickable d-flex" onClick={() => clearSearch()}>
              <svg viewBox="0 0 960 560" transform="rotate(90)" height={16}>
                <path
                  className="st0"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </svg>
              <span>{searchText}</span>
            </div>
            {selectedTab === "Pixabay" ? (
              <a href="https://pixabay.com" target="_blank">
                <img src={PixabayImageSrc} alt="pixabay" />
              </a>
            ) : selectedTab === "Unsplash" ? (
              <a href="https://unsplash.com" target="_blank">
                <img src={UnsplashImageSrc} alt="unsplash" />
              </a>
            ) : null}
          </div>
        ) : (
          <span className="default-span">Add Pic</span>
        )}
      </div>

      {!showResults && (
        <div>
          <div className="graphicsButtons space-between">
            <div className="upload my-btn" onClick={addYourOwn}>
              <span>Add Your Own</span>
            </div>
            <div className="upload my-btn" onClick={handleAITool}>
              <span>A.I. Image Maker</span>
            </div>
          </div>

          <div className="google-search">
            <input
              type="text"
              placeholder="Search Stock Images"
              onChange={handleChangeSearch}
              value={searchText}
              onKeyPress={keyPress}
            />
            <IconSearch />
          </div>
        </div>
      )}
      {showResults && isShowTopNav && (
        <div className="photosTabs">
          {!isSearch && (
            <div
              className={`clickable${
                selectedTab === "piZap" ? " selected" : ""
              }`}
              onClick={handleClickProStock}
            >
              <span>piZap</span>
            </div>
          )}
          <div
            className={`clickable${
              selectedTab === "pixabay" ? " selected" : ""
            }`}
            style={{
              width: isSearch ? "50%" : "33.333%",
            }}
            onClick={handleClickPixabay}
          >
            <span>Pixabay</span>
          </div>
          <div
            className={`clickable${
              selectedTab === "unsplash" ? " selected" : ""
            }`}
            style={{
              width: isSearch ? "50%" : "33.333%",
            }}
            onClick={handleClickUnsplash}
          >
            <span>Unsplash</span>
          </div>
        </div>
      )}
      {backgrounds && backgrounds.length > 0 && content}
    </div>
  );
};

export default MenuPhotos;
