import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const OnlineDesignPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Online Design Tool | Online Graphic Design Tool"
        description="Our online graphic design tool lets you create unique designs online."
        keywords="Online design tool, online graphic design tool"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/design_tool"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">All-Purpose Online Design Tool</p>
          <p className="desc">
            Enjoy unlimited potential on one easy-to-use graphic design app
          </p>
          <StartButton
            text="Start a Design →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app.jpg"
            alt="flyer-maker"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/online_design_tool_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Online Design Tool</span>
            <span className="desc">
              piZap’s Online Design Tool lets you create unique designs as if
              it’s as easy as putting toppings on a pizza pie! No need to take
              design classes or spend hundreds of dollars on pricey designer
              fees. piZap offers the easiest and most affordable way to score
              picture-perfect designs, DIY-style. From business cards to party
              invitations there are thousands of design templates to choose
              from. Design online from your desktop computer or download the app
              for mobile devices.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Online Design Tool Overview</span>
            <span className="desc">
              PiZap’s tools were inspired by the world’s best professional-grade
              editing and design features. The graphic design app turns
              complicated concepts and processes into easy-to-use features that
              are accessible to everyone. Add text boxes and choose from
              hundreds of fonts, change the background, adjust canvas sizing;
              you name it and this design tool offers an easy way to do it. How
              else do you think we got so many good reviews?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/online_design_tool_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/online_design_tool_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Finally, an Online Design Tool Anyone Can Use
            </span>
            <span className="desc">
              No matter what your experience or background is, piZap lets you
              create beautiful designs, from a full-page spread to a logo or web
              graphic with transparent-backing. We aren’t pulling your leg when
              we say just about anyone can design online using piZap. It’s
              really that easy. But just because it’s easy to use doesn’t mean
              designs are dumbed-down or look unprofessional. In fact, piZap is
              packed with advanced options and features.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
        <div className="section reverse">
          <div className="section-description">
            <span className="title">Online Design Made Fun</span>
            <span className="desc">
              Does designing something sound like hours on end of ZZZZZZ +
              Snores? Skip the boredom and zap over to the fun side! piZap is
              carefully designed to offer a fun, not frustrating, experience.
              Designed with ease-of-use and plenty of options for creativity,
              piZap’s online design tool keeps the fun coming your way. Add a
              MEME, a sticker, and/or a text box to your design; play around
              until you create something you love.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/online_design_tool_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/online_design_tool_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">So Many Features, Unlimited Designs</span>
            <span className="desc">
              Are you wondering if the design you’re dreaming up is too custom
              for an online design tool described as “easy to use?” piZap’s
              graphic design app offers customizable features so you can design
              whatever you’re daydreaming. Start by picking a stock size canvas
              or create a custom canvas size. From there, you have countless
              features to play with. There are hundreds of fonts, thousands of
              stickers, as well as different backgrounds and filters. Add more
              pizazz to a hilarious holiday card by using the in-app meme maker
              to create a custom meme. The design world is at your fingertips!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default OnlineDesignPage;
