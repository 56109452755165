import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("rootElement not found");

ReactDOM.render(<App />, rootElement);

reportWebVitals();
