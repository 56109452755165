import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as EditPhotoImage } from "../images/editor/edit_a_photo_icon.svg";
import { ReactComponent as BlackCanvasImage } from "../images/editor/blank_canvas_icon.svg";
import { ReactComponent as CollageMakerImage } from "../images/editor/collage_maker_icon.svg";
import { ReactComponent as BackgroundRemoverImage } from "../images/editor/background_remover_icon.svg";
import { ReactComponent as AIImage } from "../images/editor/ai_maker_icon.svg";
import { ReactComponent as SocialMediaImage } from "../images/editor/social_media_icon.svg";
import { ReactComponent as MemeMakerImage } from "../images/editor/meme_maker_icon.svg";
import { ReactComponent as LogoMakerImage } from "../images/editor/logos_maker_icon.svg";
import { ReactComponent as CardsInvitationImage } from "../images/editor/cards_and_invitations_icon.svg";
import { ReactComponent as EmojiMakerImage } from "../images/editor/emoji_maker_icon.svg";

const QuickControls = () => {
  const navigate = useNavigate();

  const handleClickAIMaker = async () => {
    EditorActionCreators.SetPickerState("ai_image");
    EditorActionCreators.ShowFilePicker(true, "ai_image");
    navigate("/app/?type=edit");
  };

  return (
    <div className="quickControls">
      <div className="quick-control-title">What will you create today?</div>
      <div className="quickControl clickable">
        <a href="/app/" target="_blank" rel="noopener noreferrer">
          <div className="quick-icon">
            <EditPhotoImage />
          </div>
          <span>
            Edit <br />
            Photo
          </span>
        </a>
      </div>
      <div className="quickControl clickable">
        <Link to="/app/applications/meme">
          <div className="quick-icon">
            <MemeMakerImage />
          </div>
          <span>
            Meme <br /> Maker
          </span>
        </Link>
      </div>
      <div className="quickControl clickable">
        <a href="/app/?type=design" target="_blank" rel="noopener noreferrer">
          <div className="quick-icon">
            <BlackCanvasImage />
          </div>
          <span>Blank Canvas</span>
        </a>
      </div>
      <div className="quickControl clickable">
        <a href="/app/?type=collage" target="_blank" rel="noopener noreferrer">
          <div className="quick-icon">
            <CollageMakerImage />
          </div>
          <span>Collage Maker</span>
        </a>
      </div>
      <div className="quickControl clickable">
        <a href="/app/?type=eraser" target="_blank" rel="noopener noreferrer">
          <div className="quick-icon">
            <BackgroundRemoverImage />
          </div>
          <span>Background Remover</span>
        </a>
      </div>
      <div className="quickControl clickable">
        <div onClick={handleClickAIMaker}>
          <div className="quick-icon">
            <AIImage />
          </div>
          <span>AI Image Maker</span>
        </div>
      </div>
      <div className="quickControl clickable">
        <Link to="/app/applications/social">
          <div className="quick-icon">
            <SocialMediaImage />
          </div>
          <span>Social Media</span>
        </Link>
      </div>
      
      <div className="quickControl clickable">
        <Link to="/app/applications/logo">
          <div className="quick-icon">
            <LogoMakerImage />
          </div>
          <span>
            Logo <br /> Maker
          </span>
        </Link>
      </div>
      <div className="quickControl clickable">
        <Link to="/app/applications/cards">
          <div className="quick-icon">
            <CardsInvitationImage />
          </div>
          <span>Cards & Invitations</span>
        </Link>
      </div>
      <div className="quickControl clickable">
        <Link to="/app/applications/emoji">
          <div className="quick-icon">
            <EmojiMakerImage />
          </div>
          <span>Emoji Maker</span>
        </Link>
      </div>
    </div>
  );
};

export default QuickControls;
