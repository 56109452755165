import React, { useEffect, useMemo, useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import EditorStore from "../stores/EditorStore";
import touchup from "../editor/touchup";
import RangeSlider from "./RangeSlider";

function getStateFromStores() {
  return {
    zoom: touchup.getStage() ? touchup.getStage().scale.x : 0,
  };
}

const TouchupZoom = () => {
  let isMounted = false;
  let _min = touchup.getStageInitialZoom();
  let _max = _min * 4;
  const [storesData, setStoresData] = useState(getStateFromStores());

  useEffect(() => {
    isMounted = true;
    _min = touchup.getStageInitialZoom();
    _max = _min * 4;
    EditorStore.addChangeListener(_onChange);
    return () => {
      isMounted = false;
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    if (isMounted) setStoresData(getStateFromStores());
  };

  const _onMove = (event) => {
    touchup.setPanMode(!touchup.getPanMode());
  };

  const _onZoomChange = (value) => {
    let sticker = touchup.getStage();
    const oldWidth = sticker.width;
    const oldHeight = sticker.height;
    // const oldScale = sticker.scale.x;
    sticker.scale.x = value;
    sticker.scale.y = value;
    let newX = sticker.position.x;
    let newY = sticker.position.y;
    sticker.position.x += (oldWidth - sticker.width) / 2;
    sticker.position.y += (oldHeight - sticker.height) / 2;
    if (newX > 0) {
      sticker.position.x = 0;
    }
    if (newY > 0) {
      sticker.position.y = 0;
    }
    if (newX + sticker.width < touchup.getRenderer().view.clientWidth) {
      sticker.position.x =
        touchup.getRenderer().view.clientWidth - sticker.width;
    }
    if (newY + sticker.height < touchup.getRenderer().view.clientHeight) {
      sticker.position.y =
        touchup.getRenderer().view.clientHeight - sticker.height;
    }
    // const letiance = 1 + (value - oldScale) / _min;
    // EditorActionCreators.TouchupBrushSize(
    //   EditorStore.getTouchupBrushSize() * letiance
    // );
    // // console.log(value, oldScale, EditorStore.getTouchupBrushSize());
    // touchup.setBrushSize(EditorStore.getTouchupBrushSize() * letiance);

    setStoresData(getStateFromStores());
  };

  const _onReset = (event) => {
    touchup.getStage().position.x = 0;
    touchup.getStage().position.y = 0;
    touchup.getStage().scale.x = touchup.getStageInitialZoom();
    touchup.getStage().scale.y = touchup.getStageInitialZoom();
    touchup.setPanMode(false);
    EditorStore.emitChange();

    EditorActionCreators.TouchupBrushSize(
      touchup.getTouchupTool().item.brushSize
    );
    touchup.setBrushSize(touchup.getTouchupTool().item.brushSize);
  };

  const _onCompare = (event) => {
    touchup.compare();
  };

  const _onCompareUp = (event) => {
    touchup.compareEnd();
  };
  const _onPanOK = (event) => {
    touchup.setPanMode(false);
  };

  const content = useMemo(() => {
    if (touchup.getPanMode()) {
      return (
        <div className="touchupPanModeOk">
          <span>Done with move?</span>
          <button className="btn touchupZoomOk" content="OK" onClick={_onPanOK}>
            OK
          </button>
        </div>
      );
    } else {
      return (
        <RangeSlider
          title="Zoom"
          min={_min}
          max={_max}
          step={0.01}
          handleMouseEnter={() => {}}
          handleMouseLeave={() => {}}
          value={storesData.zoom}
          handleChange={_onZoomChange}
          isReset={storesData.zoom !== _min}
          handleReset={() => _onZoomChange(_min)}
          isBox={false}
          startPoint={_min}
        />
      );
    }
  }, [touchup.getPanMode(), storesData.zoom]);

  return (
    <div className="touchup-zoom-container">
      <div className="touchup-zoom">{content}</div>
      <div className="compare-container clickable">
        <div
          className="background-compare"
          title="Hold to compare to original"
          onMouseDown={_onCompare}
          onMouseUp={_onCompareUp}
        >
          <svg viewBox="0 0 253.1 360.7" height="18 ">
            <g transform="matrix( 2.2499847412109375, 0, 0, 2.2499847412109375, 282.95,200.25) ">
              <path
                className="a"
                d="M-13.3-58.7c0-1.7-0.6-3.1-1.8-4.4c-1.3-1.3-2.7-1.9-4.4-1.9h-43.7v-17.8c0-1.7-0.6-3.1-1.9-4.3
                                        c-1.3-1.3-2.7-1.9-4.4-1.9c-1.7,0-3.1,0.6-4.3,1.9c-1.3,1.2-1.9,2.7-1.9,4.3V-65h-43.7c-1.7,0-3.1,0.6-4.3,1.9
                                        c-1.3,1.2-1.9,2.7-1.9,4.4v100c0,1.7,0.6,3.1,1.9,4.4c1.2,1.2,2.7,1.9,4.3,1.9h43.7V65c0,1.7,0.6,3.1,1.9,4.4
                                        c1.2,1.2,2.7,1.8,4.3,1.8c1.7,0,3.1-0.6,4.4-1.8c1.2-1.3,1.9-2.7,1.9-4.4V47.5h43.7c1.7,0,3.1-0.6,4.4-1.9
                                        c1.2-1.3,1.8-2.7,1.8-4.4V-58.7 M-69.5-52.5V35h-43.7v-87.5H-69.5z"
              />
            </g>
          </svg>
          <span>Compare</span>
        </div>
      </div>
      {storesData.zoom !== _min && (
        <div className="compare-container">
          <div
            className={`clickable ${touchup.getPanMode() ? `active` : ``}`}
            onClick={_onMove}
            title="Move tool"
          >
            <svg viewBox="0 0 21.9 23.13" height="18">
              <path
                className="handa"
                d="M382.15,183.95a0.52,0.52,0,0,0,0,.3v0.1l0-.4m-3.3-5.3a0.57,0.57,0,0,0-.2.2,37.14,37.14,0,0,0-.95,7.6q0-.35,0-0.65a0.63,0.63,0,0,1-.15.3,16.7,16.7,0,0,1-.1-2.15q0-.65,0-1.9-0.5-4.6-2.55-4.6t-2.1,3.6a25.31,25.31,0,0,0,.45,3,22.58,22.58,0,0,0,.45,2.8,22.58,22.58,0,0,1-.45-2.8,13.88,13.88,0,0,0-.75-2.15q-1.25-3.3-2.9-3.3a1.32,1.32,0,0,0-1.25.75,3.05,3.05,0,0,0-.4,1.7,20.5,20.5,0,0,0,.8,3.35q0.85,2.9,1.2,3.8l1,2.2-1-2.2a3.88,3.88,0,0,0-3.3-2.2,1.33,1.33,0,0,0-1.4,1.6q0,2.5,3.5,5.8,3,2.85,2.85,5.45a18.29,18.29,0,0,0,10.2,0,37.27,37.27,0,0,1,.45-4,21.15,21.15,0,0,1,2-5.25,27.4,27.4,0,0,0,1.9-4.8,2,2,0,0,0-2.2-2.7,1.68,1.68,0,0,0-.95.35,1.75,1.75,0,0,0-.65.85s0,0.07,0,.1h0a8.37,8.37,0,0,0-.55-3.25,0.14,0.14,0,0,0-.1-0.1,30,30,0,0,1,.45,4.3v0.1q0,0.1,0,.35l0.05-.5v-0.1a0.52,0.52,0,0,1,0-.3l0,0.4-0.05.5-0.35,2.3,0.35-2.3q0-.25,0-0.35l-0.1.05a0.28,0.28,0,0,1,.1-0.15,30,30,0,0,0-.45-4.3q-0.7-1.85-1.8-1.85A1.3,1.3,0,0,0,378.85,178.65Z"
                transform="translate(-364.75 -176.95)"
              />
              <path
                className="handb"
                d="M382.3,183.45s0-.07,0-0.1a1.75,1.75,0,0,1,.65-0.85,1.68,1.68,0,0,1,.95-0.35,2,2,0,0,1,2.2,2.7,27.4,27.4,0,0,1-1.9,4.8,21.15,21.15,0,0,0-2,5.25,37.27,37.27,0,0,0-.45,4,18.29,18.29,0,0,1-10.2,0q0.1-2.6-2.85-5.45-3.5-3.3-3.5-5.8a1.33,1.33,0,0,1,1.4-1.6,3.88,3.88,0,0,1,3.3,2.2q-0.35-.9-1.2-3.8a20.5,20.5,0,0,1-.8-3.35,3.05,3.05,0,0,1,.4-1.7,1.32,1.32,0,0,1,1.25-.75q1.65,0,2.9,3.3a13.88,13.88,0,0,1,.75,2.15,25.31,25.31,0,0,1-.45-3q0-3.6,2.1-3.6t2.55,4.6q0,1.25,0,1.9a16.7,16.7,0,0,0,.1,2.15,0.63,0.63,0,0,0,.15-0.3q0,0.3,0,.65a37.14,37.14,0,0,1,.95-7.6,0.57,0.57,0,0,1,.2-0.2,1.3,1.3,0,0,1,.95-0.4q1.1,0,1.8,1.85a0.14,0.14,0,0,1,.1.1,8.37,8.37,0,0,1,.55,3.25m-0.65-3.35a30,30,0,0,1,.45,4.3m0,0.1q0,0.1,0,.35l0.05-.5v-0.1a0.52,0.52,0,0,1,0-.3l0,0.4M370,188.2l1,2.2m11.1-5.55-0.35,2.3m-8-.3a22.58,22.58,0,0,1-.45-2.8"
                transform="translate(-364.75 -176.95)"
              />
            </svg>
          </div>
          <div className="clickable" onClick={_onReset} title="Fit to window">
            <svg viewBox="0 0 308.8 308.8" height="18">
              <g transform="matrix( 15.99951171875, 0, 0, 15.99951171875, -4132.35,-2999.85) ">
                <path
                  className="a"
                  d="M258.6,199.3c-0.2,0.2-0.3,0.4-0.3,0.7v5.8c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3h5.8
            c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-4.8V200c0-0.3-0.1-0.5-0.3-0.7
            c-0.2-0.2-0.4-0.3-0.7-0.3C259,199,258.8,199.1,258.6,199.3 M277.6,205.8V200c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3
            c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v4.8h-4.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
            c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h5.6c0.3,0,0.5-0.1,0.7-0.3S277.6,206.1,277.6,205.8 M275.6,189.5v4.6
            c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-5.6c0-0.3-0.1-0.5-0.3-0.7
            s-0.4-0.3-0.7-0.3h-5.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3H275.6
            M265.8,187.8c-0.2-0.2-0.4-0.3-0.7-0.3h-5.8c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7v5.6c0,0.3,0.1,0.5,0.3,0.7
            c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-4.6h4.8c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
            C266.1,188.2,266,188,265.8,187.8z"
                />
              </g>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default TouchupZoom;
