import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const MemeMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="MEME Maker App - Create MEMEs online"
        description="Make MEMEs online with our online MEME Maker App. It's fun and free to use"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/1meme_maker_generator_app"
        keywords="MEME Maker app, MEME Creator online, generate MEME"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Easy to Use MEME Maker</p>
          <p className="desc">
            It’s never been easier to make an original MEME
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=edit");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker_creator_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker_creator_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">MEME Maker</span>
            <span className="desc">
              My, oh my, look at that MEME Maker! MEMEs have quickly become
              everyone’s favorite way to provoke laughter, share ideas, and even
              gain more followers. Stop sharing the same MEMEs over and over and
              make your own—no design talent necessary! All you need is piZap’s
              easy to use MEME creator app and you’re on your way to winning the
              Internet.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">MEME Maker Overview</span>
            <span className="desc">
              piZap’s MEME creator app allows you to insert any picture and then
              add top text and/or bottom text in classic MEME-style bold impact
              font, but of course. It’s easy to add and adjust text as needed to
              get your punch line across. You don’t have to stick with the basic
              MEME outline, piZap is all about facilitating your inner
              creativity. Easily add stickers (there are thousands to choose
              from) to make your MEME that much better.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker_creator_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker_creator_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Finally, a MEME Maker That’s Easy to Use
            </span>
            <span className="desc">
              Get ready… chances are you’re going to turn into a MEME making
              machine. Once you start making MEMEs on piZap it’s hard to stop.
              Within minutes you’ll already feel like a pro thanks to the easily
              navigable interface with one-click tool options at your
              fingertips. All it takes is a good image paired with the right
              clever, snarky, or downright hilarious text, and boom this app
              easily turns your ideas into MEMEs with the potential to go viral.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Me Wanna Have MEME Fun!</span>
            <span className="desc">
              piZap’s MEMEs creator app offers endless fun and laughter… even if
              you’re making MEMEs all by your lonesome. After you’re done
              patting yourself on the back over your hilarious creation, it’s
              time to share it. We make it super simple to save and then share
              all your MEME creations to Facebook and other popular social
              networking sites.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker_creator_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker_creator_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Lots of Features = MEME Making for Days </h3>
            </span>
            <span className="desc">
              We can’t talk about piZap’s MEME Maker features without mentioning
              the KEEP CALM AND tool that allows you to pick from an assortment
              of MEME stickers featuring some of the most iconic MEME
              characters. piZap is so much more than just a MEME creator app,
              it’s also an editing and collage making tool. Plus, piZap offers
              free storage for all your MEMEs. These features and more let you
              create classic, cool and totally unique MEMEs.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default MemeMakerPage;
