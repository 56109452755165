import React from "react";
import LeftMenuActionCreators from "../../actions/LeftMenuActionCreators";

const SpeechBubbles = () => {
  const _bubbleSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("text_props");
    window.editor.addText(
      "",
      "https://static.pizap.com/html5/resources/editor/comic_bubble.svg",
      193,
      130,
      0.5,
      0.5
    );
  };

  const _squareSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("text_props");
    window.editor.addText(
      "",
      "https://static.pizap.com/html5/resources/editor/square_bubble.svg",
      153,
      114,
      0.1,
      0.9
    );
  };

  const _talkSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("text_props");
    window.editor.addText(
      "",
      "https://static.pizap.com/html5/resources/editor/round_bubble.svg",
      162,
      102,
      0.5,
      0.5
    );
  };

  const _thoughtSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("text_props");
    window.editor.addText(
      "",
      "https://static.pizap.com/html5/resources/editor/thought_bubble.svg",
      173,
      112,
      0.5,
      0.5
    );
  };

  const talkStart = (e) => {
    e.dataTransfer.setData("text", "talk");
  };

  const bubbleStart = (e) => {
    e.dataTransfer.setData("text", "bubble");
  };

  const squareStart = (e) => {
    e.dataTransfer.setData("text", "square");
  };

  const thoughtStart = (e) => {
    e.dataTransfer.setData("text", "thought");
  };

  return (
    <div className="menu-container center" id="menu-container">
      <div
        className="menu-header"
        onClick={() => {
          LeftMenuActionCreators.MenuSelected("text");
          window.editor.setSelectedObject(null);
        }}
      >
        <div className="clickable d-flex-start">
          <svg viewBox="0 0 960 560" transform="rotate(90)" height={14}>
            <path
              className="st0"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          <span>Speech Bubbles</span>
        </div>
      </div>
      <div className="textTypeContainer">
        <button
          className="btn addText"
          onClick={_talkSelected}
          onDragStart={talkStart}
        >
          <img
            src="https://static.pizap.com/html5/resources/editor/bubble_talk.png"
            alt=""
          />
          Add Talk
        </button>
        <button
          className="btn addText"
          onClick={_bubbleSelected}
          onDragStart={bubbleStart}
        >
          <img
            src="https://static.pizap.com/html5/resources/editor/bubble_comic.png"
            alt=""
          />
          Add Shout
        </button>
        <button
          className="btn addText"
          onClick={_thoughtSelected}
          onDragStart={thoughtStart}
        >
          <img
            src="https://static.pizap.com/html5/resources/editor/bubble_thought.png"
            alt=""
          />
          Add Thought
        </button>
        <button
          className="btn addText"
          onClick={_squareSelected}
          onDragStart={squareStart}
        >
          <img
            src="https://static.pizap.com/html5/resources/editor/bubble_square.png"
            alt=""
          />
          Add Square
        </button>
      </div>
    </div>
  );
};

export default SpeechBubbles;
