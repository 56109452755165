import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const CutoutInstructions = () => {
  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutInstructions(false);
  };
  const _nextClicked = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutStyle(true);
  };

  return (
    <div className="dialog-background">
      <div className="cutout-dialog-box">
        <div className="filepicker-navbar">
          <div className="cutout-title">Cutout Tool</div>
          <div className="close-btn" onClick={_cancelClick}>
            <CloseSVG height={12} />
          </div>
        </div>
        <p>
          The Cutout tool will ask you to load a second photo and then "cut out"
          a section of that photo and make it into a sticker or cutout shape to
          then add to your original photo.
        </p>
        <p>
          For example if your main photo is of Paris you could then load a
          second photo of yourself in the Cutout tool. You would then cut
          yourself out of this second photo and then add the result to the photo
          of Paris.
        </p>
        <img
          src="https://static.pizap.com/html5/resources/editor/cutout-instructions.jpg"
          width="550"
          alt="cutoput information"
        />
        <button className="btn my-btn cutoutFinishBtn" onClick={_nextClicked}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CutoutInstructions;
