import React, { useEffect, useRef, useState } from "react";
import UserStore from "../stores/UserStore";

const TextEdit = (props) => {
  const [tempText, setTempText] = useState(props.text);
  const [needSelect, setNeedSelect] = useState(false);
  const editTextRef = useRef();

  useEffect(() => {
    if (editTextRef.current && needSelect) {
      setNeedSelect(false);
      editTextRef.current.focus();
      editTextRef.current.select();
    }
  }, [editTextRef, needSelect]);

  const editClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.expanded) setNeedSelect(true);
    UserStore.emitChange();
  };

  const onTextEdit = (event) => {
    setTempText(event.target.value);
    UserStore.emitChange();
  };

  const textEditCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.setEdit(false);
    setNeedSelect(false);
    UserStore.emitChange();
  };

  const textEditSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.setEdit(false);
    props.handleChange(tempText);
    setNeedSelect(false);
  };

  let textEditClasses = "textedit";
  textEditClasses += " active";
  if (!props.expanded) {
    return (
      <div className={textEditClasses} onClick={editClick}>
        {props.text}
      </div>
    );
  } else {
    return (
      <div className="textedit-container clickable" onClick={editClick}>
        <input
          className="texteditInput"
          value={tempText ? tempText : props.text}
          ref={editTextRef}
          onChange={onTextEdit}
        />
        <div className="inplaceeditcontrols">
          <svg
            className="close"
            viewBox="0 0 18.1 18.7"
            onClick={textEditCancel}
          >
            <path
              className="st0"
              d="M82.7,91.15a1.8,1.8,0,0,0,1.05-.35,0.87,0.87,0,0,0,.5-0.85,2,2,0,0,0-.4-1L77.1,81.65l6.5-7.1a1.48,1.48,0,0,0,.5-1.05,0.93,0.93,0,0,0-1-1.05,2.64,2.64,0,0,0-1.45.2l-6.3,7-6.5-7a1.09,1.09,0,0,0-.7-0.2,2.19,2.19,0,0,0-1,.1,0.84,0.84,0,0,0-.8.95,1.56,1.56,0,0,0,.45,1.05l6.55,7.1L66.55,88.9a1.72,1.72,0,0,0-.4,1.1q0,1.05,1.35,1.05a1.82,1.82,0,0,0,1.15-.35l6.65-7,6.45,7A1.14,1.14,0,0,0,82.7,91.15Z"
              transform="translate(-66.15 -72.45)"
            />
          </svg>
          <svg className="check" viewBox="0 0 21.1 18.6" onClick={textEditSave}>
            <path
              className="st0"
              d="M583.5,141.85l12.75-15.7-3.7-2.9-10,12.6-4.75-4-2.65,3.25Z"
              transform="translate(-575.15 -123.25)"
            />
          </svg>
        </div>
      </div>
    );
  }
};

export default TextEdit;
