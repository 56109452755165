import React, { useEffect, useState } from "react";
import { ReactComponent as IconReportFlag } from "../images/editor/flag_icon.svg";
import UserActionCreators from "../actions/UserActionCreators";
import EditorStore from "../stores/EditorStore";
import ShareImageDialog from "../components/ShareImageDialog";
import HelmetDetails from "../components/HelmetDetails";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function getStateFromStores() {
  return {
    showShareDialog: EditorStore.showShareDialog(),
  };
}

const ShareImagePage = () => {
  const [contentData, setContentData] = useState(getStateFromStores());
  const [imageUrl, setImageUrl] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const url = params.get("image");
    setImageUrl(url);
  }, []);

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    EditorStore.emitChange();
    return () => {
      EditorStore.removeChangeListener(_onChange);
      EditorStore.emitChange();
    };
  }, []);

  const _onChange = () => {
    setContentData(getStateFromStores());
  };

  const hanldeImageLoad = () => {
    setImageLoaded(true);
  };

  const downloadImageByUrl = async (url, fileName = "piZap_image") => {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(`Failed to fetch image: ${response.statusText}`);

      const imageBlob = await response.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);

      const anchorElement = document.createElement("a");
      anchorElement.href = imageObjectURL;
      anchorElement.download = fileName;
      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      URL.revokeObjectURL(imageObjectURL);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <>
      <HelmetDetails
        title="Created at piZap.com"
        description="Design Simplified"
        imageUrl={imageUrl}
        webUrl={`https://www.pizap.com/app/share?=${imageUrl}`}
        keywords=""
      />
      <div className="share-image-container">
        <a className="pizap-logo" href="https://www.pizap.com/app/start">
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_logo_rounded_2023.510ca6fa.png"
            width="80px"
            height="80px"
          />
        </a>
        <div className="share-image-header">
          <div
            className="download-btn clickable"
            title="Download"
            onClick={() => {
              downloadImageByUrl(imageUrl, imageUrl.split("/").pop());
            }}
          >
            <svg
              version="1.0"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 56.4 58.4"
              enableBackground="new 0 0 56.4 58.4"
              height="17"
            >
              <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                <g>
                  <g id="Layer0_0_FILL">
                    <path
                      fill="#FFFFFF"
                      d="M56.4,38.8h-4.2v11.5c0,0,0,0.1,0,0.1c-0.1,2.3-1.4,3.6-3.7,3.9H7.9c-2.4-0.3-3.6-1.6-3.7-4V38.8H0v13.5
				c0.3,2,1,3.5,2.2,4.5c1.1,0.9,2.7,1.5,4.9,1.6h42.2c2.2-0.1,3.8-0.7,4.9-1.6c1.2-1,1.9-2.5,2.2-4.5V38.8 M28.1,0
				c-0.9,0-1.6,0.6-2,1.8v39.1L21,35.8l-3.4-3.4c-0.8-0.5-1.6-0.4-2.4,0.2c-0.6,0.5-0.7,1.4-0.3,2.4l0.7,0.7l11.1,11.2l0.1,0.1
				c0.5,0.3,0.9,0.5,1.4,0.6c0.5,0,0.9-0.2,1.4-0.6l0.1-0.1l11.1-11.2l0.7-0.7c0.4-1.1,0.3-1.9-0.3-2.4c-0.8-0.7-1.6-0.8-2.4-0.2
				l-3.4,3.4l-5.1,5.1V1.8c-0.5-1.2-1.1-1.7-2-1.8c0,0-0.1,0-0.1,0C28.2,0,28.1,0,28.1,0z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span>Download</span>
          </div>
          <div
            className="share-btn clickable"
            title="Share"
            onClick={() => {
              UserActionCreators.showShareDialog(true);
            }}
          >
            <svg
              version="1.0"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 56.4 72.4"
              enableBackground="new 0 0 56.4 72.4"
              height="17"
            >
              <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                <g>
                  <g id="Layer0_0_FILL">
                    <path
                      fill="#FFFFFF"
                      d="M41.2,15c0.6-0.5,0.7-1.4,0.3-2.5L29.6,0.6l-0.1-0.1C29.1,0.2,28.6,0,28.2,0c-0.4,0-0.9,0.2-1.3,0.5
				l-0.1,0.1L14.9,12.5c-0.4,1.1-0.3,1.9,0.3,2.5c0.8,0.7,1.6,0.8,2.4,0.2L26,6.8v39.1c0.5,1.2,1.2,1.8,2.1,1.8c0,0,0.1,0,0.1,0
				c0,0,0.1,0,0.1,0c0.9,0,1.6-0.6,2.1-1.8V6.8l8.4,8.5C39.6,15.8,40.4,15.7,41.2,15 M49.3,20.8H37.8v4.1h10.8
				c2.4,0.3,3.6,1.6,3.7,4v35.4c0,0,0,0.1,0,0.1c-0.1,2.3-1.4,3.6-3.7,3.9H7.9c-2.4-0.3-3.6-1.6-3.7-4V28.9c0.1-2.4,1.3-3.7,3.7-4
				h10.8v-4.1H7.1c-2.2,0.1-3.8,0.7-4.9,1.6c-1.2,1-1.9,2.5-2.2,4.5v39.3c0.3,2,1,3.5,2.2,4.5c1.1,0.9,2.7,1.5,4.9,1.6h42.2
				c2.2-0.1,3.8-0.7,4.9-1.6c1.2-1,1.9-2.5,2.2-4.5V26.9c-0.3-2-1-3.5-2.2-4.5C53.1,21.5,51.5,21,49.3,20.8z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <span>Share</span>
          </div>
          <div
            className="report-btn clickable"
            title="report"
            onClick={() => {
              window.open(
                `https://digipal.wufoo.com/forms/x1ot86wd0cg2lo3/?field2=${imageUrl}`
              );
            }}
          >
            <IconReportFlag height={16} />
            <span>Report Image</span>
          </div>
        </div>
        <div className="share-image-content">
          {!imageLoaded && (
            <SkeletonTheme
              baseColor="#262b33"
              width={550}
              height={550}
              highlightColor="#141414"
            >
              <Skeleton count={1} />
            </SkeletonTheme>
          )}
          <img
            src={imageUrl}
            alt="share-image"
            onLoad={hanldeImageLoad}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
        </div>
        <div className="share-image-footer">
          <span
            className="clickable"
            onClick={() => window.open("https://www.pizap.com/app/start")}
          >
            Try piZap free {">"}
          </span>
        </div>
      </div>
      {contentData.showShareDialog && <ShareImageDialog imageUrl={imageUrl} />}
    </>
  );
};

export default ShareImagePage;
