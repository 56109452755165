import React, { useState } from "react";
import JSONUtils from "../utils/JSONUtils";
import UserActionCreators from "../actions/UserActionCreators";
import UserStore from "../stores/UserStore";
import { Link } from "react-router-dom";

const INITIAL_STATE = {
  username: "",
  password: "",
  email: "",
  usernameValidationMessage: "",
  emailValidationMessage: "",
  passwordValidationMessage: "",
};

const LoginRegister = () => {
  const [regData, setRegData] = useState(INITIAL_STATE);

  const _onRegister = (event) => {
    event.preventDefault();
    const usernameRegex = /^[a-zA-Z0-9.\-_$@*!]{3,30}$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{4,}$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!usernameRegex.test(regData.username)) {
      alert("Username is invalid");
      return;
    }
    if (!passwordRegex.test(regData.password)) {
      alert("Password is invalid");
      return;
    }
    if (!emailRegex.test(regData.email)) {
      alert("Email is invalid");
      return;
    }

    let myData =
      "mobile=1&isajax=1&username=" +
      encodeURIComponent(regData.username) +
      "&password=" +
      encodeURIComponent(regData.password) +
      "&email=" +
      encodeURIComponent(regData.email) +
      "&email_c=" +
      encodeURIComponent(regData.email) +
      "&password_c=" +
      encodeURIComponent(regData.password);

    JSONUtils.getJSON(
      `${process.env.REACT_APP_SERVER_API}/user`,
      "POST",
      myData,
      (data) => {
        if (typeof data.ErrorMessage !== "undefined" && data.ErrorMessage) {
          alert(data.ErrorMessage);
          typeof console !== "undefined" && console.log(data.ErrorMessage);
          return;
        }
        UserActionCreators.userLoggedIn(data);
      }
    );
  };

  const checkEmail = (event) => {
    let emailValidationMessage = null;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(regData.email)) {
      emailValidationMessage = "Please enter a valid email address";
    } else {
      fetch(
        `${process.env.REACT_APP_SERVER_API}/user/email/${encodeURI(
          regData.email
        )}`,
        {
          method: "get",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((resEmail) => {
          return resEmail.json();
        })
        .then((dataEmail) => {
          if (dataEmail && dataEmail.length > 0) {
            let foundPizapAccount = false;
            let foundFacebook = false;
            let foundGoogle = false;
            let foundTwitter = false;

            dataEmail.forEach((item) => {
              if (item.isPizap) foundPizapAccount = true;
              if (item.isFacebook) foundFacebook = true;
              if (item.isGoogle) foundGoogle = true;
              if (item.isTwitter) foundTwitter = true;
            });

            if (
              foundPizapAccount ||
              foundFacebook ||
              foundTwitter ||
              foundGoogle
            ) {
              emailValidationMessage = "";
              if (foundPizapAccount)
                emailValidationMessage += "Pizap account found. ";
              if (foundFacebook)
                emailValidationMessage += "Facebook account found. ";
              if (foundGoogle)
                emailValidationMessage += "Google account found. ";
              if (foundTwitter)
                emailValidationMessage += "Twitter account found.";
            }
            UserStore.emitChange();
          }
        });
    }
    setRegData((prevState) => ({ ...prevState, emailValidationMessage }));
    UserStore.emitChange();
  };

  const checkPassword = (event) => {
    let passwordValidationMessage = null;
    var passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{4,}$/;
    if (!passwordRegex.test(regData.password)) {
      passwordValidationMessage =
        "Your password must be at least 4 characters long with 1 number. Please try another.";
    }
    UserStore.emitChange();
    setRegData((prevState) => ({ ...prevState, passwordValidationMessage }));
  };

  const checkUserName = (event) => {
    let usernameValidationMessage = "";
    UserStore.setLoginMessage("");
    UserStore.emitChange();
    if (regData.username) {
      const usernameRegex = /^[a-zA-Z0-9.\-_$@*!]{3,30}$/;
      if (!usernameRegex.test(regData.username)) {
        usernameValidationMessage = "Username is invalid";
        setRegData((prevState) => ({
          ...prevState,
          usernameValidationMessage,
        }));
        UserStore.emitChange();
        return;
      }

      fetch(
        `${process.env.REACT_APP_SERVER_API}/user/username/${encodeURI(
          regData.username
        )}`,
        {
          method: "get",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then((res) => {
          if (res.status === 200) return res.json();
          return null;
        })
        .then((data) => {
          if (data && data.username) {
            usernameValidationMessage =
              "The username you’ve entered is already in use. Please choose another username.";
            UserStore.emitChange();
          }
        });
    }
    setRegData((prevState) => ({ ...prevState, usernameValidationMessage }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="login-main-register">
      <span className="register-instruction">Sign up with Email</span>
      <div className="validateContainer">
        {regData.usernameValidationMessage ? (
          <div className="validateMessage">
            <img
              src="https://cdn.pizap.com/pizapfiles/images/alert_red_arrow.png"
              alt="red arrow"
            />
            <span>{regData.usernameValidationMessage}</span>
          </div>
        ) : null}
        <input
          type="text"
          placeholder="Username"
          name="username"
          value={regData.username}
          onChange={handleChange}
          onBlur={checkUserName}
          maxLength="75"
        />
      </div>
      <div className="validateContainer">
        {regData.emailValidationMessage ? (
          <div className="validateMessage">
            <img
              src="https://cdn.pizap.com/pizapfiles/images/alert_red_arrow.png"
              alt="red arrow"
            />
            <span>{regData.emailValidationMessage}</span>
          </div>
        ) : null}
        <input
          type="text"
          placeholder="Email"
          name="email"
          value={regData.email}
          onChange={handleChange}
          onBlur={checkEmail}
          maxLength="250"
        />
      </div>
      <div className="validateContainer">
        {regData.passwordValidationMessage ? (
          <div className="validateMessage">
            <img
              src="https://cdn.pizap.com/pizapfiles/images/alert_red_arrow.png"
              alt="red arrow"
            />
            <span>{regData.passwordValidationMessage}</span>
          </div>
        ) : null}
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={regData.password}
          onChange={handleChange}
          onBlur={checkPassword}
          maxLength="72"
        />
      </div>
      <a className="btn btn-green" onClick={_onRegister}>
        Create account
      </a>
      <div className="create-account">
        Already Registered? <Link to="/app/login">Sign In</Link>
      </div>
    </div>
  );
};

export default LoginRegister;
