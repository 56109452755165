import React from "react";
import ProCrownBadge from "../images/editor/pro-crown-badge.png";

const PrpCrown = () => {
  return (
    <div className="pro-crown">
      <img src={ProCrownBadge} className="pro-overlay" alt="pro" />
    </div>
  );
};

export default PrpCrown;
