import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PhotoBackgroundPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Photo Backgrounds | Textures"
        description="Add photo backgrounds and textures for your designs with piZap Photo Editor."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/photo_backgrounds_textures_app"
        keywords="photo backgrounds, photo textures, online photo editor backgrounds and textures and stock photos"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">
            Photo Editor Adds Photo Backgrounds and Textures
          </p>
          <p className="desc">
            piZap makes it easy to change photo backgrounds and add photo
            textures
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/start");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_backgrounds_textures_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_backgrounds_textures_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Photo Backgrounds and Textures</span>
            <span className="desc">
              It’s time to take your pics to the next level! piZap makes complex
              editing tools accessible to everyone, including an online photo
              editor that adds photo backgrounds and textures for that ‘wow’
              factor professional photos radiate with.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              Tool Overview: Photo Backgrounds & Textures
            </span>
            <span className="desc">
              piZap’s basic editing tools aren’t so basic. Of course, this
              online photo editor comes with all the basics, like the ability to
              rotate images, change filters, and add text effects and stickers.
              But it also includes many more tools, like the option to add photo
              backgrounds and textures. The Cut-Out Tool lets you cut out any
              person or thing from an image and past it into another image or
              background—a trick previously reserved to Photoshop experts.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_backgrounds_textures_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_backgrounds_textures_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              The Easiest Way to Change Photo Background and Textures
            </span>
            <span className="desc">
              Are you wondering how to add photo backgrounds and textures?
              There’s no need to read a long boring manual or watch a two-hour
              YouTube tutorial. Googling ‘how to change photo background’ or
              ‘how to add texture to photo’ might prove more complicated than
              telling. That’s where piZap comes in handy, offering the easiest
              way to kick your photos up a notch by adding spellbinding
              backgrounds and eye popping textures. These easy to use features
              can be used to spruce up your website or simply post better
              pictures on Instagram and Facebook.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              Fun Photo Editor Adds Background and Texture
            </span>
            <span className="desc">
              Fun isn’t always the word associated with an online photo editor
              used to change background images and image texture, but we think
              it should be. piZap offers countless ways to edit your pics all
              while having fun. Fun is at your fingertips when it’s as easy as
              picking a different filter to change texture background. piZap
              keeps the fun coming with additional tools and features. Add
              borders, frames, stickers, doodles and text boxes, or create a
              MEME—all these features and more are readily accessible within the
              piZap app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_backgrounds_textures_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_backgrounds_textures_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Photo Backgrounds and Textures + Many More Features
            </span>
            <span className="desc">
              Add a unique photo background to any image or cut an image out of
              one picture and past it into a different background. It’s just as
              simple to add texture to your photos using piZap. But that’s not
              all this photo editor has to offer, the list of editing tools is
              long. Embellish backgrounds with speech bubbles, glowing text,
              sparkle effects, + more. These same features help add more texture
              and dimension to your photos. Did we mention you can try piZap for
              free? It’s true!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PhotoBackgroundPage;
