import React, { useEffect, useRef, useState } from "react";
import EditorStore from "../stores/EditorStore";
import EditorActionCreators from "../actions/EditorActionCreators";
import RangeSlider from "./RangeSlider";
import { ReactComponent as DeleteIcon } from "../images/editor/delete_icon.svg";
import { ReactComponent as ReplaceIcon } from "../images/editor/replace_icon.svg";
import { ReactComponent as RotateIcon } from "../images/editor/rotate-right.svg";
import { ReactComponent as FlipVerticalIcon } from "../images/editor/flip_vertical_icon.svg";
import { ReactComponent as FlipHorizontalIcon } from "../images/editor/flip_horizontal_icon.svg";
import FiltersIcon from "../images/editor/filter_icon.svg";
import AdjustIcon from "../images/editor/adjust_icon.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AssetStore from "../stores/AssetStore";
import MenuItem from "./MenuItem.react";
import CarouselButtonGroup from "./CarouselButtonGroup";
import { CarouselResponsive } from "../utils/utils";
import LeftMenuStore from "../stores/LeftMenuStore";

function getStateFromStores() {
  return {
    settings: EditorStore.getActiveHotspotSettings(),
    selectedHotspot: EditorStore.getSelectedActiveCollageHotspot(),
    collageHighlighted: EditorStore.getCollageHighlighted(),
  };
}
const MenuHotspot = () => {
  const [storesData, setStoresData] = useState(getStateFromStores());
  const [filterChecked, setFilterChecked] = useState(false);
  const [adjustmentsChecked, setAdjustmentsChecked] = useState(false);
  const [intensity, setIntensity] = useState(1);
  const [selectedEffect, setSelectedEffect] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
      window.editor.cropCancel();
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      storesData.selectedHotspot?.effect &&
      storesData.selectedHotspot?.effect.Name &&
      storesData.selectedHotspot?.effect.zoom
    ) {
      setSelectedEffect(storesData.selectedHotspot?.effect);
      setFilterChecked(true);
      setIntensity(storesData.selectedHotspot?.effect.zoom);
    } else {
      setSelectedEffect(null);
    }
    if (
      storesData.settings.brightness !== 0 ||
      storesData.settings.temperature !== 0 ||
      storesData.settings.contrast !== 0.8 ||
      storesData.settings.hue !== 0 ||
      storesData.settings.red !== 1 ||
      storesData.settings.green !== 1 ||
      storesData.settings.blue !== 1 ||
      storesData.settings.noise !== 0 ||
      storesData.settings.denoise !== 50 ||
      storesData.settings.temperature !== 0 ||
      storesData.settings.saturation !== 0
    ) {
      setAdjustmentsChecked(true);
    } else {
      setAdjustmentsChecked(false);
    }
  }, [storesData.selectedHotspot?.effect, storesData.settings]);

  useEffect(() => {
    const element = document.getElementById("animated-element");
    element?.classList.add("animate");
    setTimeout(() => {
      element?.classList?.remove("animate");
    }, 200);
  }, [storesData.selectedHotspot]);

  const _onStraightenChange = (value) => {
    if (storesData.selectedHotspot) {
      for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
        if (storesData.selectedHotspot.children[a].CollageBackground) {
          let bo = storesData.selectedHotspot.children[a];
          if (!bo) return;
          let oldStraighten = storesData.settings.straighten
            ? storesData.settings.straighten
            : 0;
          let oldStraightenScale = bo.straightenScale ? bo.straightenScale : 1;
          let oldScale = bo.scale.x ? bo.scale.x : 1;
          window.editor.CommandManager.execute({
            execute: function () {
              EditorStore.getActiveHotspotSettings().straighten = value;
              let rotate = bo.rotate ? bo.rotate : 0;
              bo.rotation = rotate + parseFloat(value);
              let width = bo.width ? bo.width : bo._width;
              let height = bo.height ? bo.height : bo._height;
              if (width > height) {
                width = bo.height ? bo.height : bo._height;
                height = bo.width ? bo.width : bo._width;
              }
              let a = Math.atan(height / width);
              let len1 = width / 2 / Math.cos(a - Math.abs(bo.rotation));
              let len2 = Math.sqrt(
                Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
              );
              let scale = len2 / len1;
              bo.scale.set(initialZoom * scale, initialZoom * scale);
              bo.straightenScale = scale;
              EditorActionCreators.ModifyHotspotSettings(storesData.settings);
            },
            unexecute: function () {
              bo.rotation = oldStraighten;
              storesData.settings.straighten = oldStraighten;
              bo.scale.set(oldScale);
              bo.straightenScale = oldStraightenScale;
              EditorActionCreators.ModifyHotspotSettings(storesData.settings);
            },
          });
        }
      }
    }
  };

  const _onStraightenReset = () => {
    if (storesData.selectedHotspot) {
      for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
        if (storesData.selectedHotspot.children[a].CollageBackground) {
          let bo = storesData.selectedHotspot.children[a];
          if (!bo) return;
          let oldStraighten = storesData.settings.straighten
            ? storesData.settings.straighten
            : 0;
          let oldStraightenScale = bo.straightenScale ? bo.straightenScale : 1;
          let oldScale = bo.scale.x ? bo.scale.x : 1;
          window.editor.CommandManager.execute({
            execute: function () {
              EditorStore.getActiveHotspotSettings().straighten = 0;
              let rotate = bo.rotate ? bo.rotate : 0;
              bo.rotation = rotate;
              let width = bo.width ? bo.width : bo._width;
              let height = bo.height ? bo.height : bo._height;
              if (width > height) {
                width = bo.height ? bo.height : bo._height;
                height = bo.width ? bo.width : bo._width;
              }
              let a = Math.atan(height / width);
              let len1 = width / 2 / Math.cos(a - Math.abs(bo.rotation));
              let len2 = Math.sqrt(
                Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
              );
              let scale = len2 / len1;
              bo.scale.set(initialZoom, initialZoom);
              bo.straightenScale = scale;
              EditorActionCreators.ModifyHotspotSettings(storesData.settings);
            },
            unexecute: function () {
              bo.rotation = oldStraighten;
              storesData.settings.straighten = oldStraighten;
              bo.scale.set(oldScale);
              bo.straightenScale = oldStraightenScale;
              EditorActionCreators.ModifyHotspotSettings(storesData.settings);
            },
          });
        }
      }
    }
  };

  const _onTemperatureChange = (value) => {
    EditorStore.getActiveHotspotSettings().temperature = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };
  const _onBrightnessChange = (value) => {
    EditorStore.getActiveHotspotSettings().brightness = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };
  const _onContrastChange = (value) => {
    EditorStore.getActiveHotspotSettings().contrast = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };
  const _onSaturationChange = (value) => {
    EditorStore.getActiveHotspotSettings().saturation = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };
  const _onHueChange = (value) => {
    EditorStore.getActiveHotspotSettings().hue = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  // const _onSharpenChange = (value) => {
  //   EditorStore.getActiveHotspotSettings().sharpen = value;
  //   EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  // };
  // const _onBlurChange = (value) => {
  //   EditorStore.getActiveHotspotSettings().blur = value;
  //   EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  // };

  const _onDenoiseChange = (value) => {
    EditorStore.getActiveHotspotSettings().denoise = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onRedChange = (value) => {
    EditorStore.getActiveHotspotSettings().red = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onGreenChange = (value) => {
    EditorStore.getActiveHotspotSettings().green = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onBlueChange = (value) => {
    EditorStore.getActiveHotspotSettings().blue = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onNoiseChange = (value) => {
    EditorStore.getActiveHotspotSettings().noise = value;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onRedReset = () => {
    EditorStore.getActiveHotspotSettings().red = 1;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onGreenReset = () => {
    EditorStore.getActiveHotspotSettings().green = 1;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onBlueReset = () => {
    EditorStore.getActiveHotspotSettings().blue = 1;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onNoiseReset = () => {
    EditorStore.getActiveHotspotSettings().noise = 0;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onZoomChange = (value) => {
    if (storesData.selectedHotspot) {
      for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
        if (storesData.selectedHotspot.children[a].CollageBackground) {
          EditorStore.getSelectedActiveCollageHotspot().children[a].scale.x =
            EditorStore.getSelectedActiveCollageHotspot().children[a].scale.x >
            0
              ? parseFloat(value)
              : parseFloat(value) * -1;
          EditorStore.getSelectedActiveCollageHotspot().children[a].scale.y =
            EditorStore.getSelectedActiveCollageHotspot().children[a].scale.y >
            0
              ? parseFloat(value)
              : parseFloat(value) * -1;
          EditorStore.emitChange();
        }
      }
    }
  };

  const _onChange = () => {
    if (isMounted.current) {
      setStoresData(getStateFromStores());
    }
  };

  const _onRotate = () => {
    if (storesData.selectedHotspot) {
      for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
        if (storesData.selectedHotspot.children[a].CollageBackground) {
          window.editor.rotateHotspot(storesData.selectedHotspot.children[a]);
        }
      }
      EditorStore.emitChange();
    }
  };

  const _onBrightReset = (event) => {
    EditorStore.getActiveHotspotSettings().brightness = 0;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onContrastReset = (event) => {
    EditorStore.getActiveHotspotSettings().contrast = 0.8;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onTempReset = (event) => {
    EditorStore.getActiveHotspotSettings().temperature = 0;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onSatReset = (event) => {
    EditorStore.getActiveHotspotSettings().saturation = 0;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onDenoiseReset = (event) => {
    EditorStore.getActiveHotspotSettings().denoise = 50;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  const _onHueReset = (event) => {
    EditorStore.getActiveHotspotSettings().hue = 0;
    EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  };

  // const _onSharpenReset = (event) => {
  //   EditorStore.getActiveHotspotSettings().sharpen = 0;
  //   EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  // };

  // const _onBlurReset = (event) => {
  //   EditorStore.getActiveHotspotSettings().blur = 0;
  //   EditorActionCreators.ModifyHotspotSettings(storesData.settings);
  // };

  const _onZoomReset = (event) => {
    if (storesData.selectedHotspot) {
      for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
        if (storesData.selectedHotspot.children[a].CollageBackground) {
          EditorStore.getSelectedActiveCollageHotspot().children[a].scale.x =
            storesData.selectedHotspot.children[a].initialZoom;
          EditorStore.getSelectedActiveCollageHotspot().children[a].scale.y =
            storesData.selectedHotspot.children[a].initialZoom;
          var rect = storesData.selectedHotspot.initialRect;
          EditorStore.getSelectedActiveCollageHotspot().children[a].position.x =
            rect.width / 2;
          EditorStore.getSelectedActiveCollageHotspot().children[a].position.y =
            rect.height / 2;
          EditorStore.emitChange();
        }
      }
    }
  };

  const _onFlipHorizontal = (event) => {
    event.stopPropagation();
    for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
      if (storesData.selectedHotspot.children[a].CollageBackground) {
        EditorStore.getSelectedActiveCollageHotspot().children[a].scale.x *= -1;
      }
    }
    EditorStore.emitChange();
  };

  const _onFlipVertical = (event) => {
    event.stopPropagation();
    for (var a = 0; a < storesData.selectedHotspot.children.length; a++) {
      if (storesData.selectedHotspot.children[a].CollageBackground) {
        EditorStore.getSelectedActiveCollageHotspot().children[a].scale.y *= -1;
      }
    }
    EditorStore.emitChange();
  };

  let chs;
  let initialZoom = 1;
  let maxZoom = 3;
  let chsScale = 1;

  for (var a = 0; a < storesData.selectedHotspot?.children.length; a++) {
    if (storesData.selectedHotspot.children[a].CollageBackground) {
      chs = storesData.selectedHotspot.children[a];
      initialZoom = chs.initialZoom;
      maxZoom = initialZoom * 3;
      chsScale = chs.scale.x;
    }
  }

  const removeEffect = () => {
    if (EditorStore.getEditorType() === "collage") {
      if (window.editor.getSelectedObject()) {
        window.editor.getSelectedObject().filters = null;
      }
    } else {
      window.editor.getBaseObject().removeChildren();
    }
    EditorStore.setCurrentEffect(null);
    window.editor.getEffectBorderContainer().removeChildren();
    window.editor.getBaseObject().filters = null;

    if (EditorStore.getDesignBackground()) {
      EditorStore.getDesignBackground().filters = null;
    }
    EditorStore.setActiveHotspotSettings(
      EditorStore.getActiveHotspotSettings()
    );
    window.editor.realApplyEffect({ Effect: [] });
    AssetStore.emitChange();
  };

  const handleChangeFilter = (e) => {
    setFilterChecked(e.target.checked);
    if (!e.target.checked) {
      removeEffect();
      EditorStore.setCollageHotspotEffect(null);
      setSelectedEffect(null);
    }
  };

  const handleChangeAdjustment = (e) => {
    setAdjustmentsChecked(e.target.checked);
    if (!e.target.checked) {
      _onBrightReset();
      _onContrastReset();
      _onTempReset();
      _onHueReset();
      _onSatReset();
      _onDenoiseReset();
      _onNoiseReset();
      _onRedReset();
      _onBlueReset();
      _onGreenReset();
    }
  };

  const handleChangeIntensity = (value) => {
    setIntensity(value);
    EditorStore.setCollageHotspotEffect({
      ...EditorStore.getCollageHotspotEffect(),
      zoom: value,
    });
    let effect = JSON.parse(
      JSON.stringify(EditorStore.getCollageHotspotEffect())
    );
    for (let x = 0; x < effect.Effect?.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value) * value;
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value) * value;
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value) * value;
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2) * value;
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3) * value;
          break;
        default:
          if (!isNaN(effect.Effect[x].value))
            effect.Effect[x].value = effect.Effect[x].value * value;
      }
    }
    window.editor.realApplyEffect(effect, true);
  };

  const handleResetIntensity = () => {
    setIntensity(1);
    EditorStore.getCollageHotspotEffect().zoom = 1;
    let effect = JSON.parse(
      JSON.stringify(EditorStore.getCollageHotspotEffect())
    );
    for (let x = 0; x < effect.Effect?.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value);
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value);
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value);
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2);
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3);
          break;
        default:
          effect.Effect[x].value = effect.Effect[x].value;
      }
    }
    window.editor.realApplyEffect(effect, true);
  };

  const handleDeleteBackground = (e) => {
    e.preventDefault();
    for (let a = 0; a < storesData.selectedHotspot.children.length; a++) {
      if (storesData.selectedHotspot.children[a].CollageBackground) {
        storesData.selectedHotspot.removeChildAt(a);
        EditorStore.getSelectedActiveCollageHotspot().filters = null;
        AssetStore.emitChange();
        EditorActionCreators.CollageHotspotSelected(null);
        EditorActionCreators.CollageHotspotActiveSelected(null);
      }
    }
  };

  const handleClickDone = () => {
    EditorActionCreators.CollageHotspotSelected(null);
    EditorActionCreators.CollageHotspotActiveSelected(null);
  };

  const handleReplaceBackground = (e) => {
    e.preventDefault();
    for (let a = 0; a < storesData.selectedHotspot.children.length; a++) {
      if (storesData.selectedHotspot.children[a].CollageBackground) {
        storesData.selectedHotspot.removeChildAt(a);
        LeftMenuStore.setMenuSelected("main");
        EditorStore.getSelectedActiveCollageHotspot().filters = null;
        AssetStore.emitChange();
      }
    }
    EditorActionCreators.ShowFilePicker(true, "collage");
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        <span>Edit Photo</span>
      </div>
      <div id="animated-element" className="scroll-container">
        <div className="effectContainer">
          <div className="space-between range-slider">
            <div
              className="clickable semi-clickable-button"
              onClick={handleDeleteBackground}
            >
              <DeleteIcon width={16} />
              <span className="category-name">&nbsp;Delete</span>
            </div>
            <div
              className="clickable semi-clickable-button"
              onClick={handleReplaceBackground}
            >
              <ReplaceIcon width={16} />
              <span className="category-name">&nbsp;Replace</span>
            </div>
            <div className="my-btn done-btn" onClick={handleClickDone}>
              <span className="category-name">Done</span>
            </div>
          </div>
          <RangeSlider
            title="Size"
            min={initialZoom}
            max={maxZoom}
            step={0.01}
            value={Math.abs(chsScale).toFixed(2)}
            handleChange={_onZoomChange}
            handleReset={_onZoomReset}
            isReset={
              chs &&
              Math.abs(chs.initialZoom.toFixed(2)) !==
                Math.abs(chs.scale.x.toFixed(2))
            }
            isBox={false}
            handleMouseEnter={() => {}}
            handleMouseLeave={() => {}}
            startPoint={initialZoom}
          />
          <RangeSlider
            title="Straighten"
            min={-0.785398}
            max={0.785398}
            step={0.001}
            value={storesData.settings.straighten}
            isReset={storesData.settings.straighten !== 0}
            handleChange={_onStraightenChange}
            handleReset={_onStraightenReset}
            isBox={false}
            handleMouseEnter={() => {}}
            handleMouseLeave={() => {}}
            startPoint={0}
          />
          <div className="space-between mt-2">
            <div
              className="clickable semi-clickable-button"
              onClick={_onRotate}
            >
              <RotateIcon width={16} />
              <span className="category-name">&nbsp;&nbsp;Rotate</span>
            </div>
            <span>Flip</span>
            <FlipHorizontalIcon
              className="clickable semi-clickable-button"
              onClick={_onFlipHorizontal}
              width={16}
            />
            <FlipVerticalIcon
              className="clickable semi-clickable-button"
              onClick={_onFlipVertical}
              width={16}
              height={16}
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="effectContainer">
          <div className="space-between">
            <div className="space-between">
              <div className="round-rect">
                <img
                  src={FiltersIcon}
                  className="image-border-radius"
                  width={22}
                  alt="filters-icon"
                />
              </div>
              <span className="text-control-title">Filters</span>
            </div>
            <div className="transparent">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={filterChecked}
                  onChange={(e) => handleChangeFilter(e)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {filterChecked && (
            <Carousel
              className="mt-2"
              responsive={CarouselResponsive}
              infinite={true}
              autoPlay={false}
              arrows={false}
              customButtonGroup={<CarouselButtonGroup />}
            >
              {AssetStore.getEffectFolderDetails().map((item, idx) => {
                let show = true;
                if (EditorStore.getSelectedObject()) {
                  if (item.item.Effect) {
                    for (let x = 0; x < item.item.Effect.length; x++) {
                      if (item.item.Effect[x].filter.match(/blend/i))
                        show = false;
                    }
                  }
                }
                if (show) {
                  return (
                    <MenuItem
                      key={idx}
                      item={item}
                      type="effect"
                      selectedEffect={selectedEffect}
                      setIntensity={setIntensity}
                      setSelectedEffect={setSelectedEffect}
                    />
                  );
                }
              })}
            </Carousel>
          )}
          {filterChecked && selectedEffect && (
            <div className="sub-menu">
              <RangeSlider
                name="sub-menu"
                title="Intensity"
                min={0}
                max={1}
                step={0.01}
                value={intensity}
                handleChange={handleChangeIntensity}
                isReset={intensity !== 1}
                handleReset={handleResetIntensity}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
            </div>
          )}
          <div className="space-between mt-2">
            <div className="space-between">
              <div className="round-rect">
                <img
                  src={AdjustIcon}
                  className="image-border-radius"
                  width={22}
                  alt="adjust-icon"
                />
              </div>
              <span className="text-control-title">Adjust</span>
            </div>
            <div className="transparent">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={adjustmentsChecked}
                  onChange={(e) => handleChangeAdjustment(e)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {adjustmentsChecked && (
            <div className="sub-menu mt-2">
              <RangeSlider
                title="Brightness"
                min={-1}
                max={1}
                step={0.01}
                value={storesData.settings.brightness}
                handleChange={_onBrightnessChange}
                handleReset={_onBrightReset}
                isReset={storesData.settings.brightness !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Contrast"
                min={0}
                max={1.6}
                step={0.01}
                value={storesData.settings.contrast}
                handleChange={_onContrastChange}
                handleReset={_onContrastReset}
                isReset={storesData.settings.contrast !== 0.8}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0.8}
              />
              <RangeSlider
                title="Temperature"
                name="temperature-slider"
                min={-100}
                max={100}
                step={1}
                value={storesData.settings.temperature}
                handleChange={_onTemperatureChange}
                handleReset={_onTempReset}
                isReset={storesData.settings.temperature !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Saturation"
                min={-1}
                max={1}
                step={0.01}
                value={storesData.settings.saturation}
                handleChange={_onSaturationChange}
                handleReset={_onSatReset}
                isReset={storesData.settings.saturation !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Hue"
                name="tint-slider"
                min={-180}
                max={180}
                step={1}
                value={storesData.settings.hue}
                handleChange={_onHueChange}
                handleReset={_onHueReset}
                isReset={storesData.settings.hue !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Soften"
                min={0}
                max={50}
                step={1}
                value={storesData.settings.denoise}
                handleChange={_onDenoiseChange}
                handleReset={_onDenoiseReset}
                isReset={storesData.settings.denoise !== 50}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Noise"
                min={0}
                max={1}
                step={0.01}
                value={storesData.settings.noise}
                handleChange={_onNoiseChange}
                handleReset={_onNoiseReset}
                isReset={storesData.settings.noise !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Blue-Red"
                name="red-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.red}
                handleChange={_onRedChange}
                handleReset={_onRedReset}
                isReset={storesData.settings.red !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
              <RangeSlider
                title="Purple-Green"
                name="green-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.green}
                handleChange={_onGreenChange}
                handleReset={_onGreenReset}
                isReset={storesData.settings.green !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
              <RangeSlider
                title="Yellow-Blue"
                name="blue-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.blue}
                handleChange={_onBlueChange}
                handleReset={_onBlueReset}
                isReset={storesData.settings.blue !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuHotspot;
