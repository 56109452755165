import EditorActionCreators from "../actions/EditorActionCreators";
import React, { useEffect, useState } from "react";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import { ReactComponent as DeleteIcon } from "../images/editor/delete_icon.svg";
import { ReactComponent as BackwardIcon } from "../images/editor/send_backward_icon.svg";
import { ReactComponent as BackIcon } from "../images/editor/send_to_back_icon.svg";
import { ReactComponent as ForwardIcon } from "../images/editor/bring_forward_icon.svg";
import { ReactComponent as FrontIcon } from "../images/editor/bring_to_front.svg";
import { ReactComponent as DuplicateIcon } from "../images/editor/duplicate_icon.svg";
import { ReactComponent as LayerUpDownIcon } from "../images/editor/layer_up_down_icon.svg";
import LeftMenuStore from "../stores/LeftMenuStore";
import AssetStore from "../stores/AssetStore";

function getStateFromStores() {
  return {
    selectedObject: EditorStore.getSelectedObject(),
  };
}

const EditorPropertiesSticker = () => {
  const [data, setData] = useState(getStateFromStores());
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [isShowContext, setIsShowContext] = useState(false);
  const [addBackClass, setAddBackClass] = useState("");
  const [addFrontClass, setAddFrontClass] = useState("");
  const [addContextClass, setAddContextClass] = useState("");
  const [editorPropertyShow, setEditorPropertyShow] = useState(true);

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    UserStore.addChangeListener(_onChange);

    return () => {
      EditorStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _onDelete = () => {
    EditorActionCreators.RemoveSticker(data.selectedObject);
    if (LeftMenuStore.get() === "text_props") {
      LeftMenuStore.setMenuSelected("text");
    } else if (LeftMenuStore.get() === "edit_graphic") {
      AssetStore.setRememberPhotos(true);
      LeftMenuStore.setMenuSelected("photos");
    } else if (LeftMenuStore.get() === "edit_graphic_svg") {
      AssetStore.setRememberStickers(true);
      LeftMenuStore.setMenuSelected("sticker");
    }
    window.editor.setSelectedObject(null);
    window.editor.stickerSelector.cancelSelector();
    EditorActionCreators.ShowContextMenu(false);
    window.editor.autoSave();
  };

  const _onCopy = () => {
    EditorActionCreators.CopySticker(data.selectedObject);
    window.editor.autoSave();
  };

  const handleShowContext = () => {
    setIsShowContext(!isShowContext);
  };

  const _onMoveFront = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveFront(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMoveForward = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveForward(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMoveBack = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveBack(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMoveBackward = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveBackward(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMover = (event) => {
    event.stopPropagation();
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onMoveEnd);
    setOffsetX(event.clientX - event.currentTarget.offsetParent.offsetLeft);
    setOffsetY(event.clientY - event.currentTarget.offsetParent.offsetTop);
  };

  const onMoveEnd = (event) => {
    event.stopPropagation();
    document.removeEventListener("mouseup", onMoveEnd);
    document.removeEventListener("mousemove", onMove);
  };

  const onMove = (event) => {
    event.stopPropagation();
    EditorStore.setPropManualPosition(true);
    let currentX = event.clientX - offsetX;
    let currentY = event.clientY - offsetY;

    let edp = document.getElementById("editor-properties");
    if (edp) {
      edp.style.top = currentY + "px";
      edp.style.left = currentX + "px";
    }
  };

  useEffect(() => {
    if (
      data.selectedObject &&
      data.selectedObject.parent &&
      data.selectedObject.parent.getChildIndex(data.selectedObject) === 0
    ) {
      setAddBackClass("disabled-menu");
    } else setAddBackClass("");
    const minCount = UserStore.isPro() ? 1 : 2;
    if (
      data.selectedObject &&
      data.selectedObject.parent &&
      data.selectedObject.parent.children.length - minCount ===
        data.selectedObject.parent.getChildIndex(data.selectedObject)
    ) {
      setAddFrontClass("disabled-menu");
    } else {
      setAddFrontClass("");
    }
  }, [data?.selectedObject?.parent?.getChildIndex(data.selectedObject)]);

  useEffect(() => {
    setIsShowContext(false);
    if (
      data.selectedObject &&
      data.selectedObject.parent &&
      data.selectedObject.parent.children.length === 1
    ) {
      setAddContextClass("disabled-menu");
    } else {
      setAddContextClass("");
    }

    if (
      data.selectedObject === null ||
      (data.selectedObject &&
        (data.selectedObject?.DesignBackground ||
          data.selectedObject?.CollageBackground))
    ) {
      setEditorPropertyShow(false);
    } else {
      setEditorPropertyShow(true);
    }
  }, [data.selectedObject]);

  return (
    <div
      id="editor-properties"
      className={`editor-properties-holder-pro ${
        editorPropertyShow ? "" : "hidden"
      }`}
    >
      <div className="editor-properties-dialog">
        <div className="property-mover clickable" onMouseDown={_onMover}></div>
        <div
          className="prop-group clickable"
          onClick={_onDelete}
          title="Delete"
        >
          <DeleteIcon width={18} />
        </div>
        <div
          className="prop-group clickable"
          onClick={_onCopy}
          title="Duplicate"
        >
          <DuplicateIcon width={18} />
        </div>
        <div
          className={`prop-group clickable  ${addContextClass}`}
          onClick={handleShowContext}
          title="Layer Front & Back"
        >
          <LayerUpDownIcon width={18} />
        </div>
        <div
          className="layer-context-menu"
          id="editor-properties-context"
          style={{ display: isShowContext ? "block" : "none" }}
        >
          <div
            className={`prop-group clickable ${addBackClass}`}
            onClick={_onMoveBackward}
            title="Send backward"
          >
            <BackwardIcon width={18} />
            <span className="context-title">Send backward</span>
          </div>
          <div
            className={`prop-group clickable ${addBackClass}`}
            onClick={_onMoveBack}
            title="Send to back"
          >
            <BackIcon width={18} />
            <span className="context-title">Send to back</span>
          </div>
          <div
            className={`prop-group clickable ${addFrontClass}`}
            onClick={_onMoveForward}
            title="Bring forward"
          >
            <ForwardIcon width={18} />
            <span className="context-title">Bring forward</span>
          </div>
          <div
            className={`prop-group clickable ${addFrontClass}`}
            onClick={_onMoveFront}
            title="Bring to front"
          >
            <FrontIcon width={18} />
            <span className="context-title">Bring to front</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorPropertiesSticker;
