import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const FlyerMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Flyer Maker App | Make Flyer Online"
        description="Quickly and easily make flyers online with our Flyer Maker App."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/flyer_maker_app"
        keywords="Flyer maker app, make flyer online, make documents, photo editor"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Ultimate Flyer Maker App</p>
          <p className="desc">
            From party flyers to lost dog flyers, it’s simple + fun to make your
            own flyer
          </p>
          <StartButton
            text="Edit a Flyer →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">piZap Adds the Fly to Flyer Maker</span>
            <span className="desc">
              piZap’s Flyer Maker app allows you to make your own flyers that
              stand out and get noticed. No matter if you need a party flyer
              maker, or a more professional flyer template, piZap has the tools
              and features that get flyers noticed.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Flyer Maker Tool Overview</span>
            <span className="desc">
              ATTENTION: piZap’s tools are designed to get your flyers noticed!
              Get better results from flyers while creating them quickly and
              easily from your desktop computer or mobile device. The Flyer
              Maker tool comes with flyer templates, but don’t feel like your
              flyer must fit a standard mold. piZap offers flexible tools so you
              can unleash your creativity. Customize flyer templates to any
              shape or size, add custom logos and graphics; add stickers, text
              effects + more!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Easy Way to Make Your Own Flyer</span>
            <span className="desc">
              With so many options you might worry that it’ll take forever to
              make your own flyer. And even after spending all that time—will
              your DIY flyer look good? We understand your concerns and that’s
              why our number one goal is to create an app anyone can use while
              still offering awesome editing and design tools that’ll make your
              flyer pop. These same tools are often reserved to professionals,
              but not anymore! For instance, thanks to piZap’s Cut-Out tool,
              it’s easy to crop a person or thing out of one photo and add it to
              your flyer.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Have Fun Making Fly Flyers</span>
            <span className="desc">
              Pick from different flyer templates or create your own size and
              shape. Add bold fonts or sleek fonts; add stickers or custom made
              Emojis. Did we mention piZap gives you the tools to create logos
              and graphic designs with transparent backgrounds? With this many
              easily accessible tools at your fingertips just imagine all the
              fun you could have making your own flyers. Furthermore, it’s a lot
              of fun to share eye-catching flyers and watch interest and
              engagement go through the roof.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/flyer_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Flyer Maker + So Much More</span>
            <span className="desc">
              piZap offers all the Flyer Maker features you need to design
              creative flyers for any event. BUT that’s not all, piZap is so
              much more than just a Flyer Maker. It’s a full design and editing
              app that gives you countless features right at your fingertips,
              from Paint Tools to a MEME maker there are endless ways to dress
              up flyers, photos and more. Plus, piZap makes it easy to share
              your creations to social media.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default FlyerMakerPage;
