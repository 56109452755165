import EditorActionCreators from "../actions/EditorActionCreators";
import React, { useEffect, useState, useCallback } from "react";
import EditorStore from "../stores/EditorStore";
import ColorPicker from "./ColorPicker.react";
import RangeSlider from "./RangeSlider";

function getStateFromStores() {
  return {
    paintColor: EditorStore.getPaintColor(),
    brushSize: EditorStore.getBrushSize(),
    paintBlur: EditorStore.getPaintBlur(),
    paintMode: EditorStore.getPaintMode(),
  };
}
const MenuPaint = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const colorPicked = (colorCode) => {
    EditorActionCreators.PaintChangeColor(colorCode);
  };

  const _onBrushSizeChange = (value) => {
    EditorActionCreators.PaintBrushSize(value);
  };

  const _onBlurChange = (value) => {
    EditorActionCreators.PaintBlur(value);
  };

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const setEraseMode = () => {
    if (data.paintMode === "paint") EditorActionCreators.PaintMode("erase");
    else EditorActionCreators.PaintMode("paint");
  };

  const brushImage = useCallback(() => {
    const bSize = parseInt(data.brushSize, 10);
    let canvas = document.createElement("canvas");
    canvas.width = bSize + 2;
    canvas.height = bSize + 2;

    let ctx = canvas.getContext("2d");

    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.width / 2,
      bSize / 2,
      0,
      2 * Math.PI,
      false
    );
    ctx.fillStyle = data.paintColor;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = data.paintColor;
    ctx.stroke();

    return canvas.toDataURL("image/png");
  }, [data.brushSize, data.paintColor]);

  return (
    <div className="menu-container paint-container" id="menu-container">
      <div className="menu-header">
        <span>Paint</span>
      </div>
      <div className="textTypeContainer">
        <RangeSlider
          title="Brush Size"
          name=""
          min={2}
          max={50}
          step={1}
          value={data.brushSize}
          handleChange={_onBrushSizeChange}
          handleReset={() => {}}
          isReset={false}
          isBox={false}
          handleMouseEnter={() => {}}
          handleMouseLeave={() => {}}
          startPoint={2}
        />
        <div className="brushHolder">
          <img src={brushImage()} alt="" />
        </div>
        <RangeSlider
          title="Blur"
          name=""
          min={0}
          max={1}
          step={0.01}
          value={data.paintBlur}
          handleChange={_onBlurChange}
          handleReset={() => {}}
          isReset={false}
          isBox={false}
          handleMouseEnter={() => {}}
          handleMouseLeave={() => {}}
          startPoint={0}
        />
        <div
          className="d-flex space-between"
          style={{
            alignItems: "baseline",
          }}
        >
          <ColorPicker
            iconClass="brush-color"
            text="Color"
            onColor={colorPicked}
            initialColor={data.paintColor}
            opacity={1}
          />

          {/* <div className="transparent">
            <span>Erase</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={data.paintMode === "erase"}
                onChange={(e) => setEraseMode(e)}
              />
              <span className="slider round"></span>
            </label>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MenuPaint;
