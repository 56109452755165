import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const HolidayCardMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Holiday Card Maker | Online Holiday Card Designer"
        description="Holiday Card Maker lets you quickly and easily create holiday cards online."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/holiday_card_maker_app"
        keywords="Holiday Card Maker, Holiday Card Designer, Christmas Card Maker"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">The Holly Jolly Holiday Card Maker</p>
          <p className="desc">
            Say Happy Holidays like you mean it with custom holiday photo cards
          </p>
          <StartButton
            text="Start a Design →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/holiday_card_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/holiday_card_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Holiday Card Maker App</span>
            <span className="desc">
              piZap’s holiday card maker is easy to use and offers endless ways
              to customize your creation. We are all about family, and so are
              the holidays. Why not show your loved ones how much they mean to
              you this year with a custom holiday photo card?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap’s Holiday Card Maker Tool</span>
            <span className="desc">
              piZap’s Holiday Card Maker tool can turn one photo or a collage of
              photos into a treasured holiday card. Create cards that spark all
              the feels using text boxes, stickers, text effects and more.
              Prefer to keep your holiday photo cards on the funny side? Use
              piZap’s MEME maker to create a modern-age holiday card like none
              other.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/holiday_card_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/holiday_card_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              The Easy Way to Make Custom Holiday Photo Cards
            </span>
            <span className="desc">
              All too often custom holiday card makers don’t offer any room for
              creativity, that’s where piZap is unique. We want you to have the
              freedom to express yourself, so feel free to drag text boxes where
              you want them, use different fonts on the same card, and browse
              through thousands of stickers—with an incredible collection for
              just about every holiday. It only takes a few minutes of clicking
              around to get the hang of things, which means you’ll be a
              professional holiday card maker in no time!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Holiday Card Maker </span>
            <span className="desc">
              No need to let the frustration of a confusing card maker get in
              the way of your holiday joy. piZap helps you zap together stunning
              holiday photo cards so instead of feeling frustrated, you’re
              having fun. So much fun in fact, our Holiday Card Maker is often
              referred to as addicting. Spruce up cards with sparkle or glowing
              text, or stick to the basics, just get ready to have the most fun
              you’ve ever had making holiday photo cards.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/holiday_card_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/holiday_card_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Holiday Card Maker + Many More Features
            </span>
            <span className="desc">
              Did we mention you can make your own custom Emoji and add it to
              your card? That’s because piZap is so much more than just a card
              making tool. It’s also a complete editing app, collage creator,
              MEME creator, graphic design maker + more! All these features let
              you explore your creativity to the fullest. So, what are you
              waiting for? Give your loved ones the heartfelt gift of a custom
              holiday photo card.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default HolidayCardMakerPage;
