import React, { useEffect, useState, useMemo } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import Login from "./Login.react";
import UserLeftMenu from "./UserLeftMenu.react";
import Gallery from "react-grid-gallery";
import ThumbOverlay from "./ThumbOverLay.react";
import TagDialog from "./TagDialog.react";
import ProGetSave from "./ProGetSave.react";
import ProGet from "./ProGet.react";
import ProTeaser from "./ProTeaser.react";
import { useParams } from "react-router-dom";
import ProCrown from "./ProCrown.react";
import Header from "../layouts/Header";
import HelmetDetails from "./HelmetDetails";

function getStateFromStores() {
  return {
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    templates: UserStore.getSelectedProjectTagProjects(),
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showTagDialog: UserStore.getShowTagDialog(),
  };
}

const Templates = () => {
  const [data, setData] = useState(getStateFromStores());
  let { tag } = useParams();
  const isPro = UserStore.isPro();

  useEffect(() => {
    UserActionCreators.getTemplates(tag);
    UserStore.addChangeListener(_onChange);
    if (UserStore.getUser() === null) {
      UserActionCreators.updateUserData(UserStore.getAccessToken());
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, [tag]);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const loginDialog = useMemo(() => {
    if (data.showLogin) {
      return <Login />;
    } else if (data.showTagDialog) {
      return <TagDialog />;
    } else if (data.showProGetSave) {
      return <ProGetSave />;
    } else if (data.showProGet) {
      return <ProGet />;
    } else if (data.showPro) {
      return <ProTeaser />;
    }
  }, [data]);

  const images = useMemo(() => {
    let sequenceArray = [];
    let createdArray = [];
    if (data.templates) {
      data.templates.map((item) => {
        if (item.sequence) {
          sequenceArray.push(item);
        } else {
          createdArray.push(item);
        }

        sequenceArray.sort((a, b) => {
          return a.sequence - b.sequence;
        });

        createdArray.sort((a, b) => {
          return a.created - b.created;
        });
      });

      return [...sequenceArray, ...createdArray].map((i, index) => {
        let isFree = true;
        const newTags = [];
        let thumbnailCaption = null;
        i.tags.map((tag, idx) => {
          if (tag.toLowerCase() === "pizappro" && !isPro) {
            thumbnailCaption = <ProCrown key={index} />;
            isFree = false;
          }
          newTags.push({
            key: `tag-${index}-${idx}`,
            value: tag,
            title: tag,
          });
        });

        return {
          ...i,
          tags: newTags,
          customOverlay: (
            <ThumbOverlay
              key={`tag-${index}`}
              project={i}
              hideActions={true}
              isFree={isFree}
            />
          ),
          isFree: isFree,
          thumbnailCaption: thumbnailCaption,
        };
      });
    }
  }, [data.templates]);

  return (
    <div className="startPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl={`https://www.pizap.com/app/templates/${tag}`}
        keywords=""
      />
      <Header />
      <div className="container" id="container">
        {loginDialog}
        <div className="exportGallery">
          {images.length === 0 ? null : (
            <Gallery
              images={images}
              enableImageSelection={false}
              enableLightbox={false}
              rowHeight={140}
            />
          )}
        </div>
      </div>
      <UserLeftMenu />
    </div>
  );
};

export default Templates;
