import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const ThankCardPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Thank You Card Maker | Design Thank You Cards"
        description="Create thank you cards with our online thank you card maker"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/thank_you_card_maker_app"
        keywords="Thank you card maker, make thank you cards, greeting card designer"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Thank You Card Maker</p>
          <p className="desc">
            Express your gratitude with Custom Thank You Cards
          </p>
          <StartButton
            text="Make a Card →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/thank_you_card_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/thank_you_card_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Thank You Card Maker</span>
            <span className="desc">
              From casual photo thank you cards to classy wedding thank you
              cards, piZap’s Thank You Card Maker offers the ideal platform to
              say thanks like you mean it. It’s officially time to ditch the
              standard bulk-made thank you cards sold at a store near you. After
              all, there’s nothing more heartfelt than a custom thank you card.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              A Closer Look at piZap’s Thank You Card Maker
            </span>
            <span className="desc">
              piZap’s Thank You Card Maker Tool is packed with features that
              make it affordable and easy to create custom thank you cards for
              everyone on your list. To get started, pick the size and shape
              thank you card template you prefer. From there you can add photos
              edited in-app using piZap’s basic editing tools. You can add
              stickers (there are thousands to choose from), create your own
              custom Emojis, and pick from hundreds of fonts and text effects.
              piZap has Paint Tools too so you can doodle directly on your card.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/thank_you_card_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/thank_you_card_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Custom Thank You Cards Made Simple</span>
            <span className="desc">
              If all these features sound overwhelming, think again. We make no
              jokes when we say ANYONE can learn to use piZap in as little as a
              few minutes. piZap incorporates some of the best photo editing and
              design tools made simple to use and easy to access. From kids to
              senior citizens, all ages enjoy using piZap. Welcome to a world
              where it’s easy to create professional looking custom thank you
              cards that take the cake—no prior card making experience
              necessary.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Thank You Card Maker</span>
            <span className="desc">
              Writing out endless lists of ‘thank you’ cards has a reputation as
              a tedious chore—but what if expressing gratitude could be fun?
              piZap believes it should be just as fun to make thank you cards as
              it is to receive them. That’s why we included numerous tools
              that’ll let your imagination run wild. You can thank us later!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/thank_you_card_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/thank_you_card_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Thank You Card Maker + Many More Features
            </span>
            <span className="desc">
              piZap offers more than just a way to make your own thank you cards
              on desktop computers and mobile devices. piZap offers a wide
              variety of complete editing and design tools that’ll help you take
              your ‘thank you’ cards to the next level. Plus, piZap has the
              tools to create MEMEs, collages, graphic designs and so much more.
              Did we mention you can also create invitations, flyers, and
              business cards? You get all that and more with piZap’s Thank You
              Card Maker.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default ThankCardPage;
