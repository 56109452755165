import React, { useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import EditorStore from "../stores/EditorStore";

const CustomSize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [units, setUnits] = useState("pixels");
  const [error, setError] = useState(null);

  const _onUnits = (event) => {
    setUnits(event.currentTarget.value);
    if (units === "inches") {
      setWidth(width / 300);
      setHeight(height / 300);
    } else {
      setWidth(width * 300);
      setHeight(height * 300);
    }
  };

  const onCustomWidth = (event) => {
    setWidth(parseInt(event.currentTarget.value, 10));
  };

  const onCustomHeight = (event) => {
    setHeight(parseInt(event.currentTarget.value, 10));
  };

  const _onOK = () => {
    let finalWidth = width;
    let finalHeight = height;
    if (units === "inches") {
      finalWidth *= 300;
      finalHeight *= 300;
    }
    if (finalHeight > 3840 || finalWidth > 3840) {
      setError("Max dimension is 3840");
      return;
    }
    let p = {
      key: "Custom",
      name: "Custom",
      icon: "",
      item: {
        Name: "Custom",
        Width: finalWidth,
        Height: finalHeight,
        AllowCustom: false,
      },
    };
    window.editor.addDesign(p, () => {
      EditorStore.setShowSpinner(false);
    });
  };

  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.showCustomSize(false);
  };

  return (
    <div className="filepicker-dialog active">
      <div className="customSizeDialog" align="center">
        <div className="photopicker-navbar">
          <div className="photopicker-title">Custom Size</div>
          <a
            id="filepicker-cancel-btn"
            className="filepicker-navbar-right-btn"
            onClick={_cancelClick}
          >
            Cancel
          </a>
        </div>
        <div className="customSizeContainer">
          <div className="customSize">
            <input
              type="number"
              onChange={onCustomWidth}
              value={width}
              max="3840"
              min="0"
            />
            <span> X </span>
            <input
              type="number"
              onChange={onCustomHeight}
              value={height}
              max="3840"
              min="0"
            />
            <select onChange={_onUnits} value={units} id="units">
              <option value="pixels">pixels</option>
              <option value="inches">inches</option>
            </select>
            <div>
              <span>{error && error}</span>
            </div>
            <div>
              <button className="btn customSizeOK" onClick={_onOK}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSize;
