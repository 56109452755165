import React from "react";
import UserActionCreators from "../actions/UserActionCreators";

const LoginForgotResponse = () => {
  const onCancel = () => {
    UserActionCreators.loginMode("email");
  };

  return (
    <div className="login-main-forgotResponse">
      <div className="cancel clickable" onClick={onCancel}>
        Cancel
      </div>
      <div className="forgotTitle">Request Submitted</div>
      <div className="forgot-content">
        Please check your email for a link to reset your password. If you do not
        receive the email, please check your spam folder or contact us using{" "}
        <a href="https://digipal.wufoo.com/forms/pizap-change-password-request">
          this from
        </a>
      </div>
    </div>
  );
};

export default LoginForgotResponse;
