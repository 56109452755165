import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { useNavigate } from "react-router-dom";

const UsageLimitModal = () => {
  const navigate = useNavigate();
  const handleClickCancel = () => {
    EditorActionCreators.ShowUsageLimitModal(false);
    if (!window.editor.getBaseObject()) {
      navigate("/app/start");
    }
  };

  return (
    <div className="filepicker-dialog active">
      <div className="sticker-error-modal usage-limit-modal" align="center">
        <div className="modal-body">
          <span>
            Daily AI Image Limit reached: Pro users can create up to 15 images
            per day. <br /> We know you hate this limit and many of you have let
            us know about it. We've set this new limit due to the SIGNIFICANT
            server and computing resources required for image generation, which
            incurs EXTREMELY high costs. Maintaining this limit is essential for
            our sustainability and to avoid jeopardizing our service. We
            appreciate your understanding and are working to enhance our
            capacity. We expect prices to come down over the coming year.
            <br /> We will be adding optional upgrades in the near future for
            those who require higher daily limits.
          </span>
        </div>
        <div className="modal-footer">
          <div className="clickable confirm-btn" onClick={handleClickCancel}>
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageLimitModal;
