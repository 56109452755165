import React, { useEffect, useState } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import EditorStore from "../stores/EditorStore";
import LeftMenuStore from "../stores/LeftMenuStore";
import UserStatus from "./UserStatus.react";
import Undo from "./Undo.react";

function getStateFromStores() {
  return {
    mode: EditorStore.getEditorMode(),
    menu: LeftMenuStore.get(),
    statusMessage: EditorStore.getStatusMessage(),
  };
}

const Navigation = () => {
  const [storesData, setStoresData] = useState(getStateFromStores());

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    EditorStore.addChangeListener(_onChange);
    LeftMenuStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
      EditorStore.removeChangeListener(_onChange);
      LeftMenuStore.removeChangeListener(_onChange);
    };
  }, []);

  const _download = (event) => {
    window.editor.setSelectedObject(null);
    EditorActionCreators.ShowSaveQuality(true);
  };

  const _share = (event) => {
    UserActionCreators.showShareDialog(true);
  };

  const _onChange = () => {
    setStoresData(getStateFromStores());
  };

  const onProClick = () => {
    UserActionCreators.showProGet(true);
  };

  return (
    <div className="headerBtnBar">
      <div className="projectTitleContainer">
        <Undo />
        <div className="statusMessage">{storesData.statusMessage}</div>
      </div>
      <UserStatus />
      <div className="save-share-group">
        {storesData.mode !== "crop" &&
          storesData.menu !== "cutout" &&
          !EditorStore.getTouchupTool() &&
          !EditorStore.isElectron() && (
            <div
              className="saveBtn clickable"
              onClick={_download}
              title="Download"
            >
              <svg
                version="1.0"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 56.4 58.4"
                enableBackground="new 0 0 56.4 58.4"
                height="17"
              >
                <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                  <g>
                    <g id="Layer0_0_FILL">
                      <path
                        fill="#FFFFFF"
                        d="M56.4,38.8h-4.2v11.5c0,0,0,0.1,0,0.1c-0.1,2.3-1.4,3.6-3.7,3.9H7.9c-2.4-0.3-3.6-1.6-3.7-4V38.8H0v13.5
				c0.3,2,1,3.5,2.2,4.5c1.1,0.9,2.7,1.5,4.9,1.6h42.2c2.2-0.1,3.8-0.7,4.9-1.6c1.2-1,1.9-2.5,2.2-4.5V38.8 M28.1,0
				c-0.9,0-1.6,0.6-2,1.8v39.1L21,35.8l-3.4-3.4c-0.8-0.5-1.6-0.4-2.4,0.2c-0.6,0.5-0.7,1.4-0.3,2.4l0.7,0.7l11.1,11.2l0.1,0.1
				c0.5,0.3,0.9,0.5,1.4,0.6c0.5,0,0.9-0.2,1.4-0.6l0.1-0.1l11.1-11.2l0.7-0.7c0.4-1.1,0.3-1.9-0.3-2.4c-0.8-0.7-1.6-0.8-2.4-0.2
				l-3.4,3.4l-5.1,5.1V1.8c-0.5-1.2-1.1-1.7-2-1.8c0,0-0.1,0-0.1,0C28.2,0,28.1,0,28.1,0z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <span>Download</span>
            </div>
          )}
        {storesData.mode !== "crop" &&
          storesData.menu !== "cutout" &&
          !EditorStore.getTouchupTool() && (
            <div className="shareBtn clickable" onClick={_share} title="Share">
              <svg
                version="1.0"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 56.4 72.4"
                enableBackground="new 0 0 56.4 72.4"
                height="17"
              >
                <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                  <g>
                    <g id="Layer0_0_FILL">
                      <path
                        fill="#FFFFFF"
                        d="M41.2,15c0.6-0.5,0.7-1.4,0.3-2.5L29.6,0.6l-0.1-0.1C29.1,0.2,28.6,0,28.2,0c-0.4,0-0.9,0.2-1.3,0.5
				l-0.1,0.1L14.9,12.5c-0.4,1.1-0.3,1.9,0.3,2.5c0.8,0.7,1.6,0.8,2.4,0.2L26,6.8v39.1c0.5,1.2,1.2,1.8,2.1,1.8c0,0,0.1,0,0.1,0
				c0,0,0.1,0,0.1,0c0.9,0,1.6-0.6,2.1-1.8V6.8l8.4,8.5C39.6,15.8,40.4,15.7,41.2,15 M49.3,20.8H37.8v4.1h10.8
				c2.4,0.3,3.6,1.6,3.7,4v35.4c0,0,0,0.1,0,0.1c-0.1,2.3-1.4,3.6-3.7,3.9H7.9c-2.4-0.3-3.6-1.6-3.7-4V28.9c0.1-2.4,1.3-3.7,3.7-4
				h10.8v-4.1H7.1c-2.2,0.1-3.8,0.7-4.9,1.6c-1.2,1-1.9,2.5-2.2,4.5v39.3c0.3,2,1,3.5,2.2,4.5c1.1,0.9,2.7,1.5,4.9,1.6h42.2
				c2.2-0.1,3.8-0.7,4.9-1.6c1.2-1,1.9-2.5,2.2-4.5V26.9c-0.3-2-1-3.5-2.2-4.5C53.1,21.5,51.5,21,49.3,20.8z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <span>Share</span>
            </div>
          )}
      </div>
      {!UserStore.isPro() && (
        <div
          className="pro-teaser clickable"
          onClick={onProClick}
          title="Try piZap Pro"
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1500 1080"
            enableBackground="new 0 0 1500 1080"
            height="15"
          >
            <g transform="matrix( 8.704086303710938, 0, 0, 8.704086303710938, 109.4,28) ">
              <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                <g>
                  <g id="Symbol_1_0_Layer0_0_FILL">
                    <path
                      fill="#FFC200"
                      d="M19.4,112.5c0,1.4,0.5,2.7,1.5,3.7s2.2,1.5,3.6,1.5h98.2c1.4,0,2.7-0.5,3.7-1.5s1.5-2.2,1.5-3.7
					s-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5H24.5c-1.4,0-2.6,0.5-3.6,1.5C19.9,109.9,19.4,111.1,19.4,112.5 M127.9,40.3
					c0,2.7,0.9,4.9,2.8,6.8c0.1,0.1,0.2,0.2,0.2,0.2l-30.2,16L76.9,18.8c1.3-0.5,2.5-1.3,3.6-2.3c1.9-1.9,2.8-4.1,2.8-6.8
					s-0.9-4.9-2.8-6.8c-0.4-0.4-0.8-0.7-1.2-1C77.7,0.6,75.8,0,73.7,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.7,1-6.5,2.8
					C65,4.7,64.1,7,64.1,9.6s0.9,4.9,2.8,6.8c1,1,2.2,1.8,3.5,2.2l-24,44.7l-30.1-16c0.1-0.1,0.1-0.2,0.2-0.2
					c1.9-1.9,2.8-4.1,2.8-6.8c0-2.7-0.9-5-2.8-6.8c-1.9-1.9-4.1-2.8-6.8-2.8c-0.8,0-1.5,0.1-2.2,0.2c-1.7,0.4-3.3,1.2-4.6,2.5
					c-1.5,1.5-2.4,3.3-2.7,5.4C0,39.4,0,39.8,0,40.3c0,2.7,0.9,4.9,2.8,6.8C4.7,49,7,50,9.6,50c0.6,0,1.2,0,1.8-0.2l10.2,49.3h104
					l10.2-49.3c0.6,0.1,1.1,0.2,1.8,0.2c2.7,0,4.9-1,6.8-2.8c1.9-1.9,2.8-4.1,2.8-6.8c0-0.5,0-1-0.1-1.4c-0.3-2.1-1.2-3.9-2.7-5.4
					c-1.4-1.3-2.9-2.2-4.6-2.5c-0.7-0.2-1.4-0.2-2.2-0.2c-2.7,0-4.9,0.9-6.8,2.8C128.8,35.4,127.9,37.7,127.9,40.3z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span>
            Try <b style={{ fontWeight: "bold" }}>piZap Pro</b>
          </span>
        </div>
      )}
    </div>
  );
};

export default Navigation;
