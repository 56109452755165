import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const BlemishRemoverToolPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Blemish Remover App | Photo Touch Up and Blemish Remover"
        description="Use our blemish remover app to make your photos look flawless. With a few clicks you can zap away any blemishes or wrinkles."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/blemish_remover_tool_app"
        keywords="Blemish remover app, blemish remover tool, airbrush and wrinkle remover"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">The Best Blemish Remover Tool</p>
          <p className="desc">Banish blemishes with the BEST blemish remover</p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/blemish_remover_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/blemish_remover_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Best Blemish Remover Tool</span>
            <span className="desc">
              From your brother to your mother, everyone has blemishes. Why not
              let piZap’s Blemish Remover Tool transform your blunders into
              silky smooth perfection? It’s easy and fun to be flawless!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Touch Up Tool: The Blemish Remover </span>
            <span className="desc">
              Have you ever wondered how people post such perfect selfies—do
              they even have pores, zits, or normal-people skin? Thanks to
              piZap’s Best Blemish Remover Tool you can join the flawless face
              club. In-app tools let you smooth out imperfections, remove zits
              and make your photos blemish-free. But that’s not all! piZap is
              packed with countless other touch up tools, as well as photo
              editing and design features.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/blemish_remover_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/blemish_remover_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easy to Use Blemish Remover</span>
            <span className="desc">
              If you think retouching photos to remove flaws is difficult that’s
              because you haven’t tried piZap yet. You’ll be zapping away your
              imperfections in seconds. In fact, it only takes a few minutes to
              feel like a pro. That’s because piZap is designed for ease of use.
              No matter how much editing experience you have, it’s finally
              simple to remove blemishes and make photos look naturally perfect.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Have Fun Erasing Blemishes</span>
            <span className="desc">
              Your imperfections are truly what make you beautiful and set you
              apart in a crowd, but that big about-to-pop zit, not so much!
              There are certain blemishes no one wants to show in their
              pictures. It’s time to enjoy the thrill that comes with smoothing
              out, deleting and retouching blemishes. You never know when a zit
              is going to show up on the tip of your nose, but with piZap you
              can continue snapping selfies worry-free. After all, you can
              delete that zit, or any other imperfection, in a matter of
              seconds.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/blemish_remover_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/blemish_remover_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Blemish Remover + Features Galore </h3>
            </span>
            <span className="desc">
              Once you save your retouched photos using piZap’s free storage,
              you can easily share photos on social media directly from in the
              app. piZap is so much more than just a Blemish Remover Tool—it’s
              also a complete photo editing app that allows you to add all kinds
              of things to your pics including filters, text effects, stickers,
              and even custom Emojis. Plus, in-app tools make it easy to create
              MEMEs, posters, business cards, graphic designs, and the list goes
              on. So, what are you waiting for? It’s time to zap away blemishes!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default BlemishRemoverToolPage;
