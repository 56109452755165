import React, { useEffect, useState, useRef } from "react";
import RangeSlider from "../RangeSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ColorPicker from "../ColorPicker.react";
import { ReactComponent as RotateIcon } from "../../images/editor/rotate-right.svg";
import { ReactComponent as FlipHorizontalIcon } from "../../images/editor/flip_horizontal_icon.svg";
import { ReactComponent as FlipVerticalIcon } from "../../images/editor/flip_vertical_icon.svg";
import { ReactComponent as BackgroundRemoverIcon } from "../../images/editor/background_remover_icon.svg";
import { ReactComponent as CropIcon } from "../../images/editor/crop_icon.svg";
import { ReactComponent as ImageUnlock } from "../../images/editor/unlock.svg";
import ShadowIcon from "../../images/editor/graphic_shadow_icon.png";
import GlowIcon from "../../images/editor/graphic_glow_icon.png";
import AdjustIcon from "../../images/editor/adjust_icon.svg";
import FiltersIcon from "../../images/editor/filter_icon.svg";
import LeftMenuStore from "../../stores/LeftMenuStore";
import EditorStore from "../../stores/EditorStore";
import * as PIXI from "pixi.js";
import UserStore from "../../stores/UserStore";
import JSONUtils from "../../utils/JSONUtils";
import EditorActionCreators from "../../actions/EditorActionCreators";
import * as Filters from "pixi-filters";
import EffectItem from "../EffectItem";
import AssetStore from "../../stores/AssetStore";
import MenuItem from "../MenuItem.react";
import CarouselButtonGroup from "../CarouselButtonGroup";
import { CarouselResponsive, decimalToHex } from "../../utils/utils";
import { ReactComponent as DeleteIcon } from "../../images/editor/delete_icon.svg";

function getStateFromStores() {
  return {
    effects: AssetStore.getEffectFolderDetails(),
    selectedObject: EditorStore.getSelectedObject(),
    glowVal: EditorStore.getGlowSliderVal(),
    glowColor: EditorStore.getGlowColor(),
    dropShadowColor: EditorStore.getDropShadowColor(),
    dropShadowAlpha: EditorStore.getDropShadowAlpha(),
    dropShadowBlur: EditorStore.getDropShadowBlur(),
    dropShadowAngle: EditorStore.getDropShadowAngle(),
    dropShadowDistance: EditorStore.getDropShadowDistance(),
    settings: EditorStore.getImageSettings(),
    mode: EditorStore.getEditorMode(),
    cropType: EditorStore.getSelectedCropType(),
  };
}

const EditGraphic = () => {
  const object = EditorStore.getSelectedObject();
  const [data, setData] = useState(getStateFromStores());
  const [isCheckedShadow, setIsCheckedShadow] = useState(false);
  const [isCheckedGlow, setIsCheckedGlow] = useState(false);
  const [isCheckedFilters, setIsCheckedFilters] = useState(false);
  const [isCheckedAdjust, setIsCheckedAdjust] = useState(false);
  const [isAdjustmentsSeeAll, setIsAdjustmentsSeeAll] = useState(false);
  const [intensity, setIntensity] = useState(
    EditorStore.getGraphicEffect() && EditorStore.getGraphicEffect().zoom
      ? EditorStore.getGraphicEffect().zoom
      : 1
  );
  const [isShow, setIsShow] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    EditorStore.addChangeListener(_onChnage);
    UserStore.addChangeListener(_onChnage);
    return () => {
      // Indicate that the component has unmounted
      isMounted.current = false;
      EditorStore.removeChangeListener(_onChnage);
      UserStore.removeChangeListener(_onChnage);
    };
  }, []);

  const _onChnage = () => {
    if (isMounted.current) {
      setData(getStateFromStores());
    }
  };

  const handleChangeTransparency = (value) => {
    if (
      EditorStore.getSelectedObject() &&
      EditorStore.getSelectedObject().alpha
    ) {
      EditorStore.getSelectedObject().alpha = value === 0 ? 0.01 : value;
      EditorStore.emitChange();
      window.editor.autoSave();
    }
  };

  const handelResetTransparency = () => {
    if (
      EditorStore.getSelectedObject() &&
      EditorStore.getSelectedObject().alpha
    ) {
      EditorStore.getSelectedObject().alpha = 1;
      EditorStore.emitChange();
      window.editor.autoSave();
    }
  };

  const handleColorPick = (colorCode) => {
    if (data.selectedObject) {
      data.selectedObject.fillColor = colorCode;
      if (
        data.selectedObject.sourceUrl &&
        data.selectedObject.sourceUrl.startsWith("data:")
      ) {
        var parser = new DOMParser();
        var response = parser.parseFromString(
          decodeURIComponent(
            data.selectedObject.sourceUrl.replace(
              "data:image/svg+xml;utf8,",
              ""
            )
          ),
          "application/xml"
        );
        var style = response.documentElement.getElementsByTagName("style");
        style[0].innerHTML =
          "path{fill:" + colorCode + ";stroke:" + colorCode + ";}";

        data.selectedObject.sourceUrl =
          "data:image/svg+xml;utf8," +
          encodeURIComponent(new XMLSerializer().serializeToString(response));
        window.editor.redrawSvg(
          data.selectedObject,
          data.selectedObject.width,
          data.selectedObject.height
        );
      } else {
        data.selectedObject.originalUrl = data.selectedObject.sourceUrl;
        JSONUtils.getXML(
          data.selectedObject.sourceUrl,
          "GET",
          null,
          function (response) {
            var style = response.documentElement.getElementsByTagName("style");
            style[0].innerHTML =
              "path{fill:" + colorCode + ";stroke:" + colorCode + ";}";

            data.selectedObject.sourceUrl =
              "data:image/svg+xml;utf8," +
              encodeURIComponent(
                new XMLSerializer().serializeToString(response)
              );
            window.editor.redrawSvg(
              data.selectedObject,
              data.selectedObject.width,
              data.selectedObject.height
            );
          }
        );
      }
    }
  };

  const handleRotate = (e) => {
    e.stopPropagation();
    if (data.selectedObject) {
      EditorStore.getSelectedObject().rotation += 1.5708;
      if (data.selectedObject.rotation === 1.5708 + 1.5708 + 1.5708 + 1.5708) {
        EditorStore.getSelectedObject().rotation = 0;
      }
      window.editor.stickerSelector.updateSelector();
      EditorStore.emitChange();
      window.editor.autoSave();
    }
  };

  const handleFlipHorizontal = (e) => {
    e.stopPropagation();
    EditorStore.getSelectedObject().scale.x *= -1;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const handleFlipVertical = (e) => {
    e.stopPropagation();
    EditorStore.getSelectedObject().scale.y *= -1;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const handleChangeGlow = (e) => {
    setIsCheckedGlow(e.target.checked);
    EditorActionCreators.GraphicGlowEnable(e.target.checked);
  };

  const handleGlowSliderChange = (value) => {
    EditorActionCreators.GraphicGlowSliderChange(parseFloat(value));
  };

  const glowColorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.GraphicGlowColor(color);
  };

  const handleChangeShadow = (e) => {
    setIsCheckedShadow(e.target.checked);
    EditorActionCreators.GraphicDropShadowEnabled(e.target.checked);
  };

  const handleChangeAdjustments = (e) => {
    setIsCheckedAdjust(e.target.checked);
    if (!e.target.checked) {
      handleBrightReset();
      handleContrastReset();
      handleTemperatureReset();
      handleHueReset();
      handleSatReset();
      handleDenoiseReset();
      handleNoiseReset();
      handleRedReset();
      handleBlueReset();
      handleGreenReset();
    }
  };

  const handleSeeAllAdjustments = () => {
    setIsAdjustmentsSeeAll(true);
  };

  const removeEffect = () => {
    EditorStore.setGraphicEffect(null);
    window.editor.getEffectBorderContainer().removeChildren();
    EditorStore.setImageSettings(EditorStore.getImageSettings());
    window.editor.realApplyEffect({ Effect: [] });
    AssetStore.emitChange();
  };

  const handleChangeFilter = (e) => {
    setIsCheckedFilters(e.target.checked);
    if (!e.target.checked) {
      removeEffect();
      setIntensity(1);
    }
  };

  const handleClickBackgroundRmove = (e) => {
    e.preventDefault();
    EditorActionCreators.ShowGraphicCutoutStyle(true);
    window.editor.removeCutout();
  };

  const handleClickCrop = (e) => {
    e.preventDefault();
    EditorStore.setGraphicCutoutStyle(true);
    if (data.settings.straighten && data.settings.straighten !== 0) {
      let bo = EditorStore.getSelectedObject();
      bo.rotation -= data.settings.straighten;
      EditorStore.getImageSettings().straighten = 0;
      bo.scale.set(
        EditorStore.getSelectedObject().initialScaleX,
        EditorStore.getSelectedObject().initialScaleY
      );
      bo.straightenScale = 1;
    }
    EditorActionCreators.EditorMode("crop");
  };

  const handleChangeColor = (value) => {
    const filters = EditorStore.getSelectedObject().filters || [];
    let colorMatrix = new PIXI.filters.ColorMatrixFilter();
    colorMatrix.resolution = window.editor.getStartingDevicePixelRatio();
    colorMatrix.hue(1 + parseFloat(value), true);
    if (data.selectedObject && data.selectedObject._selectedBlendMode) {
      switch (data.selectedObject._selectedBlendMode) {
        case "NORMAL":
          colorMatrix.blendMode = PIXI.BLEND_MODES.NORMAL;
          break;
        case "ADD":
          colorMatrix.blendMode = PIXI.BLEND_MODES.ADD;
          break;
        case "MULTIPLY":
          colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
          break;
        case "SCREEN":
          colorMatrix.blendMode = PIXI.BLEND_MODES.SCREEN;
          break;
        default:
      }
      EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.NORMAL;
    }
    for (let i = 0; i < filters.length; i++) {
      if (filters[i] instanceof PIXI.filters.ColorMatrixFilter) {
        filters.splice(i, 1);
      }
    }
    filters.push(colorMatrix);
    EditorStore.getSelectedObject().filters = filters;
    EditorStore.getSelectedObject().hue = value;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const handleResetColor = () => {
    const filters = EditorStore.getSelectedObject().filters;
    let colorMatrix = new PIXI.filters.ColorMatrixFilter();
    colorMatrix.resolution = window.editor.getStartingDevicePixelRatio();
    colorMatrix.hue(1, true);
    if (data.selectedObject && data.selectedObject._selectedBlendMode) {
      switch (data.selectedObject._selectedBlendMode) {
        case "NORMAL":
          colorMatrix.blendMode = PIXI.BLEND_MODES.NORMAL;
          break;
        case "ADD":
          colorMatrix.blendMode = PIXI.BLEND_MODES.ADD;
          break;
        case "MULTIPLY":
          colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
          break;
        case "SCREEN":
          colorMatrix.blendMode = PIXI.BLEND_MODES.SCREEN;
          break;
        default:
      }
      EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.NORMAL;
    }
    for (let i = 0; i < filters.length; i++) {
      if (filters[i] instanceof PIXI.filters.ColorMatrixFilter) {
        filters.splice(i, 1);
      }
    }
    filters.push(colorMatrix);
    EditorStore.getSelectedObject().filters = filters;
    EditorStore.getSelectedObject().hue = 0;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  useEffect(() => {
    if (
      object &&
      object?.sourceUrl &&
      object.sourceUrl.substr(object.sourceUrl.length - 3, 3) === "jpg"
    ) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }

    if (object) {
      if (object.filters !== null) {
        setIsCheckedShadow(false);
        setIsCheckedGlow(false);
        for (let z = 0; z < object.filters.length; z++) {
          if (object.filters[z] instanceof Filters.DropShadowFilter) {
            setIsCheckedShadow(true);
          }
          if (object.filters[z] instanceof Filters.GlowFilter) {
            setIsCheckedGlow(true);
          }
        }
      } else {
        setIsCheckedShadow(false);
        setIsCheckedGlow(false);
      }
      if (
        object.imageSettings?.blue !== 1 ||
        object.imageSettings?.brightness !== 0 ||
        object.imageSettings?.contrast !== 0.8 ||
        object.imageSettings?.denoise !== 50 ||
        object.imageSettings?.green !== 1 ||
        object.imageSettings?.hue !== 0 ||
        object.imageSettings?.noise !== 0 ||
        object.imageSettings?.red !== 1 ||
        object.imageSettings?.saturation !== 0 ||
        object.imageSettings?.temperature !== 0
      ) {
        EditorActionCreators.ModifyImageSettings(object.imageSettings);
        setIsCheckedAdjust(true);
      } else {
        setIsCheckedAdjust(false);
      }
      if (EditorStore.getGraphicEffect()) {
        setIsCheckedFilters(true);
      } else {
        setIsCheckedFilters(false);
      }
      setIsAdjustmentsSeeAll(false);
    }
  }, [object, isShow]);

  useEffect(() => {
    if (object) {
      const element = document.getElementById("animated-element");
      element?.classList.add("animate");
      setTimeout(() => {
        element?.classList?.remove("animate");
      }, 200);
    }
  }, [object?.id]);

  const _onBlendMode = (e) => {
    e.stopPropagation();
    if (EditorStore.getSelectedObject()?.pluginName) {
      EditorStore.getSelectedObject().pluginName = "sprite";
    }
    switch (e.target.value) {
      case "NORMAL":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.NORMAL;
        break;
      case "ADD":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.ADD;
        break;
      case "MULTIPLY":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.MULTIPLY;
        break;
      case "SCREEN":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.SCREEN;
        break;
      case "OVERLAY":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.OVERLAY;
        EditorStore.getSelectedObject().pluginName = "picture";
        break;
      case "DARKEN":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.DARKEN;
        break;
      case "LIGHTEN":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.LIGHTEN;
        break;
      case "COLOR_DODGE":
        EditorStore.getSelectedObject().blendMode =
          PIXI.BLEND_MODES.COLOR_DODGE;
        break;
      case "COLOR_BURN":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.COLOR_BURN;
        break;
      case "HARD_LIGHT":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.HARD_LIGHT;
        break;
      case "SOFT_LIGHT":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.SOFT_LIGHT;
        break;
      case "DIFFERENCE":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.DIFFERENCE;
        break;
      case "EXCLUSION":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.EXCLUSION;
        break;
      case "HUE":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.HUE;
        break;
      case "SATURATION":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.SATURATION;
        break;
      case "COLOR":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.COLOR;
        break;
      case "LUMINOSITY":
        EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.LUMINOSITY;
        break;
      default:
    }

    if (
      data.selectedObject?.filters &&
      data.selectedObject.filters?.length > 0
    ) {
      for (let x = 0; x < data.selectedObject.filters.length; x++) {
        EditorStore.getSelectedObject().filters[x].blendMode =
          data.selectedObject.blendMode;
      }
      EditorStore.getSelectedObject().blendMode = PIXI.BLEND_MODES.NORMAL;
    }
    EditorStore.getSelectedObject()._selectedBlendMode = e.target.value;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const handleBlurChange = async (value) => {
    EditorActionCreators.GraphicDropShadowBlurSliderChange(parseFloat(value));
  };

  const handleAlphaChange = async (value) => {
    EditorActionCreators.GraphicDropShadowAlphaSliderChange(parseFloat(value));
  };

  const handleAngleChange = async (value) => {
    EditorActionCreators.GraphicDropShadowAngleSliderChange(parseFloat(value));
  };

  const handleDistanceChange = async (value) => {
    EditorActionCreators.GraphicDropShadowDistanceSliderChange(
      parseFloat(value)
    );
  };

  const dropGraphicShadowColorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.GraphicDropShadowColor(color);
  };

  const handleChangeIntensity = (value) => {
    EditorStore.getGraphicEffect().zoom = value;
    let effect = JSON.parse(JSON.stringify(EditorStore.getGraphicEffect()));
    for (let x = 0; x < effect.Effect.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value) * value;
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value) * value;
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value) * value;
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2) * value;
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3) * value;
          break;
        default:
          if (!isNaN(effect.Effect[x].value))
            effect.Effect[x].value = effect.Effect[x].value * value;
      }
    }
    window.editor.realApplyEffect(effect, true);
    setIntensity(value);
  };

  const handleResetIntensity = () => {
    setIntensity(1);
    EditorStore.getGraphicEffect().zoom = 1;
    let effect = JSON.parse(JSON.stringify(EditorStore.getGraphicEffect()));
    for (let x = 0; x < effect.Effect.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value);
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value);
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value);
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2);
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3);
          break;
        default:
          effect.Effect[x].value = effect.Effect[x].value;
      }
    }
    window.editor.realApplyEffect(effect, true);
  };

  const handleBrightnessChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      brightness: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleBrightReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      brightness: 0,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleContrastChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      contrast: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleContrastReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      contrast: 0.8,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleHueChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      hue: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleHueReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      hue: 0,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleTemperatureChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      temperature: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleTemperatureReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      temperature: 0,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleSaturationChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      saturation: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleSatReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      saturation: 0,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleDenoiseChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      denoise: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleDenoiseReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      denoise: 50,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleNoiseChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      noise: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleNoiseReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      noise: 0,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleRedChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      red: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleRedReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      red: 1,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleBlueChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      blue: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleBlueReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      blue: 1,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleGreenChange = (value) => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      green: value,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleGreenReset = () => {
    EditorActionCreators.ModifyImageSettings({
      ...EditorStore.getImageSettings(),
      green: 1,
    });
    const blur = new PIXI.filters.BlurFilter();
    blur.blur = 0;
    blur.padding = 40;
    blur.resolution = 2;
    blur.quality = 1;
    EditorStore.getSelectedObject().filters = [
      blur,
      ...EditorStore.getSelectedObject().filters,
    ];
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    setIsAdjustmentsSeeAll(false);
  };

  const handleClickDone = () => {
    if (
      window.editor.getSelectedObject()?.sourceUrl &&
      window.editor
        .getSelectedObject()
        .sourceUrl.substr(
          window.editor.getSelectedObject().sourceUrl.length - 3,
          3
        ) === "jpg"
    ) {
      AssetStore.setRememberPhotos(true);
      LeftMenuStore.setMenuSelected("photos");
    } else {
      AssetStore.setRememberStickers(true);
      LeftMenuStore.setMenuSelected("sticker");
    }
    window.editor.stickerSelector.cancelSelector();
    window.editor.setSelectedObject(null);
  };

  const handleClickDelete = () => {
    const selectedSticker = EditorStore.getSelectedObject();
    EditorActionCreators.RemoveSticker(EditorStore.getSelectedObject());
    if (selectedSticker && selectedSticker.svg === true) {
      LeftMenuStore.setMenuSelected("sticker");
    } else {
      LeftMenuStore.setMenuSelected("photos");
    }
    window.editor.autoSave();
  };

  const _onCropCancel = () => {
    EditorActionCreators.ShowGraphicCutoutStyle(false);
    window.editor.removeCutout();
    EditorActionCreators.EditorMode("view");
  };

  const _onCropFinish = () => {
    window.editor.cropGraphicFinish();
    EditorActionCreators.EditorMode("view");
  };

  const _onLocked = (event) => {
    event.stopPropagation();
    if (EditorStore.getSelectedObject()) {
      EditorStore.getSelectedObject().locked =
        !EditorStore.getSelectedObject().locked;
      EditorStore.emitChange();
      window.editor.setSelectedObject(EditorStore.getSelectedObject());
    }
  };

  const _onPermLock = () => {
    EditorStore.getSelectedObject().locked = true;
    EditorStore.getSelectedObject().permLocked = true;
    EditorStore.getSelectedObject().interactive = false;
    handleClickDone();
    EditorStore.emitChange();
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        <div className="photos-nav">
          {object?.permLocked ? (
            <span>Image Permanently Locked</span>
          ) : object?.locked ? (
            <span>Image Locked</span>
          ) : isAdjustmentsSeeAll ? (
            <div
              className="d-flex clickable"
              onClick={(e) => handleClickBack(e)}
            >
              <svg viewBox="0 0 960 560" transform="rotate(90)" height={14}>
                <path
                  className="st0"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </svg>
              <span>Adjustments</span>
            </div>
          ) : (
            <span>Edit Image</span>
          )}
          {object?.locked
            ? null
            : data.mode !== "crop" && (
                <div className="custom-select">
                  <select
                    value={
                      object?._selectedBlendMode === undefined
                        ? "NORMAL"
                        : object._selectedBlendMode
                    }
                    onChange={_onBlendMode}
                  >
                    <option value="NORMAL">NORMAL</option>
                    <option value="ADD">ADD</option>
                    <option value="MULTIPLY">MULTIPLY</option>
                    <option value="SCREEN">SCREEN</option>
                    {window.editor.getStartingDevicePixelRatio() === 1 && (
                      <option value="OVERLAY">OVERLAY</option>
                    )}
                  </select>
                </div>
              )}
        </div>
      </div>
      {object?.locked ? (
        <>
          <div className="unlock-btn clickable" onClick={_onLocked}>
            <ImageUnlock /> &nbsp;
            <span>Unlock</span>
          </div>
          <span className="perm-lock clickable" onClick={_onPermLock}>
            lock permanently
          </span>
        </>
      ) : data.mode === "crop" ? (
        <div className="cropMenus">
          <div className="space-between">
            <button className="btn mainCropCancel" onClick={_onCropCancel}>
              Cancel
            </button>
            <button
              className="btn mainCropFinish my-btn"
              onClick={_onCropFinish}
            >
              Apply
            </button>
          </div>
        </div>
      ) : (
        <div id="animated-element" className="scroll-container">
          {!isAdjustmentsSeeAll && (
            <div className="effectContainer">
              <div className="space-evenly range-slider">
                <div
                  className="clickable semi-clickable-button"
                  onClick={handleClickDelete}
                >
                  <DeleteIcon width={16} />
                  <span className="category-name">&nbsp;Delete</span>
                </div>
                <div className="my-btn done-btn" onClick={handleClickDone}>
                  <span className="category-name">Done</span>
                </div>
              </div>
              {LeftMenuStore.get() === "edit_graphic" && (
                <div className="space-between mb-2">
                  <div className="space-between">
                    <div
                      className="clickable semi-clickable-button"
                      onClick={(e) => handleClickBackgroundRmove(e)}
                    >
                      <BackgroundRemoverIcon width={16} />
                      <span className="category-name">
                        &nbsp;Background Remover
                      </span>
                    </div>
                  </div>
                  <div className="space-between">
                    <div
                      className="clickable semi-clickable-button"
                      onClick={(e) => handleClickCrop(e)}
                    >
                      <CropIcon width={16} />
                      <span className="category-name">&nbsp;Crop</span>
                    </div>
                  </div>
                </div>
              )}
              {LeftMenuStore.get() === "edit_graphic" && (
                <div
                  className="space-between"
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ width: "45%" }}>
                    <RangeSlider
                      title="Transparency"
                      id="alphaRange"
                      value={data.selectedObject?.alpha}
                      min={0}
                      max={1}
                      step={0.01}
                      handleChange={handleChangeTransparency}
                      isReset={data.selectedObject?.alpha !== 1}
                      handleReset={(e) => handelResetTransparency(e)}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <RangeSlider
                      title="Color"
                      name="tint-slider"
                      value={
                        data.selectedObject?.hue ? data.selectedObject?.hue : 0
                      }
                      min={-180}
                      max={180}
                      step={1}
                      handleChange={(value) => handleChangeColor(value)}
                      handleReset={() => handleResetColor()}
                      isReset={
                        data.selectedObject?.hue !== undefined &&
                        data.selectedObject?.hue !== 0
                      }
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                  </div>
                </div>
              )}
              {LeftMenuStore.get() === "edit_graphic_svg" && (
                <div className="space-between">
                  <div
                    style={{
                      width:
                        data.selectedObject?.fillColor !== undefined
                          ? "60%"
                          : "45%",
                    }}
                  >
                    <RangeSlider
                      title="Transparency"
                      min={0}
                      max={1}
                      step={0.01}
                      value={
                        data.selectedObject?.alpha
                          ? data.selectedObject?.alpha
                          : 1
                      }
                      handleChange={handleChangeTransparency}
                      isReset={data.selectedObject?.alpha !== 1}
                      handleReset={() => handelResetTransparency()}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                  </div>
                  <div
                    style={{
                      width:
                        data.selectedObject?.fillColor !== undefined
                          ? "30%"
                          : "45%",
                    }}
                  >
                    {data.selectedObject?.fillColor !== undefined ? (
                      <ColorPicker
                        iconClass="outline"
                        layout="vertical"
                        location="editor"
                        text="Color"
                        onColor={handleColorPick}
                        initialColor={data.selectedObject?.fillColor}
                        opacity={1}
                      />
                    ) : (
                      <RangeSlider
                        title="Color"
                        name="tint-slider"
                        value={
                          data.selectedObject?.hue
                            ? data.selectedObject?.hue
                            : 0
                        }
                        min={-180}
                        max={180}
                        step={1}
                        handleChange={(value) => handleChangeColor(value)}
                        handleReset={() => handleResetColor()}
                        isReset={
                          data.selectedObject?.hue !== undefined &&
                          data.selectedObject?.hue !== 0
                        }
                        isBox={false}
                        handleMouseEnter={() => window.editor.hideGizmo(true)}
                        handleMouseLeave={() => window.editor.hideGizmo(false)}
                        startPoint={0}
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="space-between" style={{ marginTop: "4px" }}>
                <div
                  className="clickable semi-clickable-button"
                  onClick={handleRotate}
                >
                  <RotateIcon width={22} />
                  <span className="category-name">&nbsp;Rotate</span>
                </div>
                <span style={{ paddingLeft: "10px" }}>Flip</span>
                <FlipHorizontalIcon
                  className="clickable semi-clickable-button"
                  onClick={handleFlipHorizontal}
                  width={22}
                />
                <FlipVerticalIcon
                  className="clickable semi-clickable-button"
                  onClick={handleFlipVertical}
                  width={22}
                  height={20}
                />
              </div>
            </div>
          )}
          {!isAdjustmentsSeeAll && <div className="divider"></div>}
          <div className="textTypeContainer">
            {!isAdjustmentsSeeAll && (
              <>
                <div className="space-between">
                  <div className="space-between">
                    <img
                      src={ShadowIcon}
                      className="image-border-radius"
                      height={50}
                      alt="outline-icon"
                    />
                    <span className="text-control-title">Shadow</span>
                  </div>
                  <div className="transparent">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isCheckedShadow}
                        onChange={(e) => handleChangeShadow(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {isCheckedShadow && !isAdjustmentsSeeAll && (
                  <div className="mt-2 sub-menu">
                    <RangeSlider
                      title="Blur"
                      name="clickable"
                      handleChange={handleBlurChange}
                      min={0}
                      max={3}
                      step={0.1}
                      value={data?.dropShadowBlur}
                      handleReset={() => {}}
                      isReset={false}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Offset"
                      name="clickable"
                      handleChange={handleDistanceChange}
                      min={1}
                      max={15}
                      step={1}
                      value={data?.dropShadowDistance}
                      handleReset={() => {}}
                      isReset={false}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={1}
                    />
                    <RangeSlider
                      title="Transparency"
                      name="clickable"
                      handleChange={handleAlphaChange}
                      min={0.1}
                      max={1}
                      step={0.1}
                      value={data?.dropShadowAlpha}
                      handleReset={() => {}}
                      isReset={false}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0.1}
                    />
                    <RangeSlider
                      title="Angle"
                      name="clickable"
                      handleChange={handleAngleChange}
                      min={0}
                      max={359}
                      step={1}
                      value={data?.dropShadowAngle}
                      handleReset={() => {}}
                      isReset={false}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                    <ColorPicker
                      text="Color"
                      onColor={dropGraphicShadowColorPicked}
                      initialColor={`#${decimalToHex(data.dropShadowColor)}`}
                      opacity={1}
                    />
                  </div>
                )}
                <div className="space-between mt-2">
                  <div className="space-between">
                    <img
                      src={GlowIcon}
                      className="image-border-radius"
                      height={50}
                      alt="outline-icon"
                    />
                    <span className="text-control-title">Glow</span>
                  </div>
                  <div className="transparent">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isCheckedGlow}
                        onChange={(e) => handleChangeGlow(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {isCheckedGlow && (
                  <div className="mt-2">
                    <div className="sub-menu space-between">
                      <div className="slider-color-left">
                        <RangeSlider
                          title=""
                          name="text-controls-slider"
                          handleChange={handleGlowSliderChange}
                          min={0}
                          max={4}
                          step={0.1}
                          value={data.glowVal}
                          handleReset={() => {}}
                          isReset={false}
                          isBox={false}
                          handleMouseEnter={() => window.editor.hideGizmo(true)}
                          handleMouseLeave={() =>
                            window.editor.hideGizmo(false)
                          }
                          startPoint={0}
                        />
                      </div>
                      <ColorPicker
                        text=""
                        onColor={glowColorPicked}
                        initialColor={`#${decimalToHex(data.glowColor)}`}
                        opacity={1}
                      />
                    </div>
                  </div>
                )}
                <div className="space-between mt-2">
                  <div className="space-between">
                    <div className="round-rect">
                      <img
                        src={FiltersIcon}
                        className="image-border-radius"
                        width={22}
                        alt="filters-icon"
                      />
                    </div>
                    <span className="text-control-title">Filters</span>
                  </div>
                  <div className="transparent">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={isCheckedFilters}
                        onChange={(e) => handleChangeFilter(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {isCheckedFilters && (
                  <Carousel
                    className="mt-2"
                    responsive={CarouselResponsive}
                    infinite={true}
                    autoPlay={false}
                    arrows={false}
                    customButtonGroup={<CarouselButtonGroup />}
                  >
                    {data.effects.map((item, idx) => {
                      let show = true;
                      if (EditorStore.getSelectedObject()) {
                        if (item.item.Effect) {
                          for (let x = 0; x < item.item.Effect.length; x++) {
                            if (item.item.Effect[x].filter.match(/blend/i))
                              show = false;
                          }
                        }
                      }
                      if (show) {
                        return (
                          <MenuItem
                            key={idx}
                            item={item}
                            type="effect"
                            selectedEffect={EditorStore.getGraphicEffect()}
                            setSelectedEffect={EditorStore.setGraphicEffect}
                            setIntensity={setIntensity}
                          />
                        );
                      }
                    })}
                  </Carousel>
                )}
                {isCheckedFilters && EditorStore.getGraphicEffect() && (
                  <div className="sub-menu">
                    <RangeSlider
                      title="Intensity"
                      min={0}
                      max={1}
                      step={0.01}
                      value={intensity}
                      handleChange={handleChangeIntensity}
                      isReset={intensity !== 1}
                      handleReset={handleResetIntensity}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                  </div>
                )}
              </>
            )}
            {!isAdjustmentsSeeAll && (
              <EffectItem
                imgSrc={AdjustIcon}
                title="Adjust"
                isChecked={isCheckedAdjust}
                handleChange={(e) => handleChangeAdjustments(e)}
                seeAll={handleSeeAllAdjustments}
              />
            )}
            {isCheckedAdjust && (
              <div className={isAdjustmentsSeeAll ? "" : "sub-menu"}>
                <RangeSlider
                  title="Brightness"
                  min={-1}
                  max={1}
                  step={0.01}
                  value={data.settings?.brightness}
                  handleChange={handleBrightnessChange}
                  handleReset={handleBrightReset}
                  isReset={data.settings?.brightness !== 0}
                  isBox={false}
                  handleMouseEnter={() => window.editor.hideGizmo(true)}
                  handleMouseLeave={() => window.editor.hideGizmo(false)}
                  startPoint={0}
                />
                <RangeSlider
                  title="Contrast"
                  min={0}
                  max={1.6}
                  step={0.01}
                  value={data.settings?.contrast}
                  handleChange={handleContrastChange}
                  handleReset={handleContrastReset}
                  isReset={data.settings?.contrast !== 0.8}
                  isBox={false}
                  handleMouseEnter={() => window.editor.hideGizmo(true)}
                  handleMouseLeave={() => window.editor.hideGizmo(false)}
                  startPoint={0.8}
                />
                <RangeSlider
                  title="Temperature"
                  name="temperature-slider"
                  min={-100}
                  max={100}
                  step={1}
                  value={data.settings?.temperature}
                  handleChange={handleTemperatureChange}
                  handleReset={handleTemperatureReset}
                  isReset={data.settings?.temperature !== 0}
                  isBox={false}
                  handleMouseEnter={() => window.editor.hideGizmo(true)}
                  handleMouseLeave={() => window.editor.hideGizmo(false)}
                  startPoint={0}
                />
                <RangeSlider
                  title="Tint"
                  name="tint-slider"
                  min={-180}
                  max={180}
                  step={1}
                  value={data.settings?.hue}
                  handleChange={handleHueChange}
                  handleReset={handleHueReset}
                  isReset={data.settings?.hue !== 0}
                  isBox={false}
                  handleMouseEnter={() => window.editor.hideGizmo(true)}
                  handleMouseLeave={() => window.editor.hideGizmo(false)}
                  startPoint={0}
                />
                <RangeSlider
                  title="Saturation"
                  min={-1}
                  max={1}
                  step={0.01}
                  value={data.settings?.saturation}
                  handleChange={handleSaturationChange}
                  handleReset={handleSatReset}
                  isReset={data.settings?.saturation !== 0}
                  isBox={false}
                  handleMouseEnter={() => window.editor.hideGizmo(true)}
                  handleMouseLeave={() => window.editor.hideGizmo(false)}
                  startPoint={0}
                />
                {isAdjustmentsSeeAll && (
                  <>
                    <RangeSlider
                      title="Soften"
                      min={0}
                      max={50}
                      step={1}
                      value={data.settings?.denoise}
                      handleChange={handleDenoiseChange}
                      handleReset={handleDenoiseReset}
                      isReset={data.settings?.denoise !== 50}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Noise"
                      min={0}
                      max={1}
                      step={0.01}
                      value={data.settings?.noise}
                      handleChange={handleNoiseChange}
                      handleReset={handleNoiseReset}
                      isReset={data.settings?.noise !== 0}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Blue-Red"
                      name="red-slider"
                      min={0}
                      max={2}
                      step={0.1}
                      value={data.settings?.red}
                      handleChange={handleRedChange}
                      handleReset={handleRedReset}
                      isReset={data.settings?.red !== 1}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={1}
                    />
                    <RangeSlider
                      title="Purple-Green"
                      name="green-slider"
                      min={0}
                      max={2}
                      step={0.1}
                      value={data.settings?.green}
                      handleChange={handleGreenChange}
                      handleReset={handleGreenReset}
                      isReset={data.settings?.green !== 1}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={1}
                    />
                    <RangeSlider
                      title="Yellow-Blue"
                      name="blue-slider"
                      min={0}
                      max={2}
                      step={0.1}
                      value={data.settings?.blue}
                      handleChange={handleBlueChange}
                      handleReset={handleBlueReset}
                      isReset={data.settings?.blue !== 1}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={1}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditGraphic;
