import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const FaceEditorPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Face Editor App | Touch Up and Face Editing Effects"
        description="piZap’s Face Editor Tool allows you to edit your face in countless ways. It's quick, easy and fun."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/face_editor_app"
        keywords="Face Editor App, Edit face and touch up app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Face Editor and Touch Ups</p>
          <p className="desc">
            More than a photo makeup app, piZap is the ultimate Face Editor
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/face_editor_touch_up_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/face_editor_touch_up_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">EpiZap’s Face Editor Feature</span>
            <span className="desc">
              piZap’s Face Editor Tool allows you to edit your face in countless
              ways. Don’t get us wrong, we think you’re naturally beautiful just
              the way you are. But not every picture can always reflect your
              most beautiful self. Hence why so many people search for a ‘photo
              makeup app’ or ‘face editor online.’ Lucky for you, you’ve
              stumbled on the best face editor out there!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Face Editor Tool by piZap</span>
            <span className="desc">
              piZap is packed with editing and design tools, including the Face
              Editor feature. Change your eye color, remove wrinkles and erase
              blemishes in a zap! Other features included with piZap’s face
              editor app include tools to whiten your teeth, add airbrush makeup
              and lip tint, plus so much more. Use the app to play up photos and
              then post directly to social media within the app. No pressure to
              share, it’s just as fun to play around with different tools to
              find the look that flatters you best.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/face_editor_touch_up_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/face_editor_touch_up_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Easy to Use Face Editor</span>
            <span className="desc">
              piZap is for everyone—from kids to grandparents; yes, even your
              grandma could learn how to use this app. piZap is carefully
              designed with awesome face editing tools that are all easy to
              access with the click of a button. Everything is labeled so you
              know what to do within minutes of playing around inside the face
              editor app. Plus, piZap makes it easy to add filters, stickers or
              text effects.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Have Fun Editing Your Face</span>
            <span className="desc">
              Apply makeup, erase that zit on your chin, or remove the pesky
              wrinkle between your brows. It’s fun to swipe your face into
              perfection using piZap’s Face Editor tools. So fun in fact, some
              people fall into a black hole of face editing and can’t seem to
              get back out… after all, there are just so many pics to edit!
              Careful now, you don’t want to get too carried away. Then again,
              time spent having fun is never time wasted… especially if that
              time earns you more likes on social media—am I right?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/face_editor_touch_up_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/face_editor_touch_up_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Edit Your Face & Then Some</h3>
            </span>
            <span className="desc">
              piZap offers the latest Face Editor tools but that’s not all!
              piZap is a complete editing and design app with countless features
              just waiting for you. When you’re done editing your face, you can
              add stickers, text effects, and custom Emojis. The app also lets
              you create MEMEs, flyers, greeting cards, posters, graphic
              designs, logos, and more. So, what are you waiting for?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default FaceEditorPage;
