import React, { useState, useEffect } from "react";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import SpeechBubblesImage from "../images/editor/speech_bubbles.png";
import MemeTextImage from "../images/editor/meme_text.png";
import Lockr from "lockr";
import UserStore from "../stores/UserStore";
import AssetStore from "../stores/AssetStore";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import EditorStore from "../stores/EditorStore";

const getStateFromStores = () => {
  return {
    textTemplates: AssetStore.getTextTemplates(),
  };
};

const MenuTextType = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    if (data.textTemplates === null) {
      AssetActionCreators.loadTextTemplates();
    }
    AssetStore.addChangeListener(_onChange);
    return () => {
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores(data));
  };

  const handleClickAddText = (e, fontSize) => {
    e.preventDefault();
    Lockr.set("fontSize", fontSize);
    LeftMenuActionCreators.MenuSelected("text_props");
  };

  const handleClickMeme = (e) => {
    e.preventDefault();
    LeftMenuActionCreators.MenuSelected("meme");
    window.editor.setSelectedObject(null);
  };

  const handleClickBubble = (e) => {
    e.preventDefault();
    LeftMenuActionCreators.MenuSelected("speech");
    window.editor.setSelectedObject(null);
  };

  const handleClickTextStyle = (src) => {
    EditorStore.setShowSpinner(true);
    fetch(src, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + UserStore.getAccessToken(),
        "Content-Type": "application/json",
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((results) => {
        window.editor.addTextStyle(results);
      })
      .then((res) => {
        EditorStore.setShowSpinner(false);
      })
      .catch((e) => {
        EditorStore.setShowSpinner(false);
      });
  };

  return (
    <div className="menu-container center" id="menu-container">
      <div className="menu-header">
        <span>Text</span>
      </div>
      <div className="scrollContainer textTypeContainer">
        <button
          className="btn addText heading"
          onClick={(e) => handleClickAddText(e, 80)}
        >
          Add a heading
        </button>
        <button
          className="btn addText subheading"
          onClick={(e) => handleClickAddText(e, 45)}
        >
          Add a subheading
        </button>
        <button
          className="btn addText small"
          onClick={(e) => handleClickAddText(e, 25)}
        >
          Add a small text
        </button>
        <div className="image-text-group">
          <img
            className="clickable image-text"
            onClick={handleClickBubble}
            src={SpeechBubblesImage}
            alt="speech bubbles"
            width={115}
          />
          <img
            className="clickable image-text"
            onClick={(e) => handleClickMeme(e)}
            src={MemeTextImage}
            alt="meme"
            width={115}
          />
          {data.textTemplates?.map((item, idx) => {
            return (
              <img
                key={idx}
                onClick={(e) => handleClickTextStyle(item.src)}
                className="clickable image-text"
                src={item.thumbnail}
                alt={item.imageName}
                width={115}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MenuTextType;
