import JSONUtils from "../utils/JSONUtils";
import UserStore from "../stores/UserStore";
import EditorDispatcher from "../dispatcher/EditorDispatcher";
import ActionTypes from "../constants/EditorConstants";

export default {
  logUserIn: function (loginData) {
    EditorDispatcher.dispatch({
      type: ActionTypes.LOG_USER_IN,
      data: loginData,
    });
  },
  forgotPassword: function (email) {
    EditorDispatcher.dispatch({
      type: ActionTypes.FORGOT_PASSWORD,
      data: email,
    });
  },
  logFacebookIn: function (loginData) {
    var self = this;
    JSONUtils.postJSON(
      `${process.env.REACT_APP_SERVER_API}/auth/facebook`,
      "POST",
      loginData,
      function (data) {
        self.userLoggedIn(data);
      }
    );
  },
  logGoogleIn: function (loginData) {
    var self = this;
    JSONUtils.postJSON(
      `${process.env.REACT_APP_SERVER_API}/auth/google`,
      "POST",
      loginData,
      function (data) {
        self.userLoggedIn(data);
      }
    );
  },
  updateUserData: function (access_token) {
    fetch(`${process.env.REACT_APP_SERVER_API}/user/me`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (updatedUser) {
        UserStore.setUser(updatedUser);
      })
      .catch((e) => console.log(e));
  },
  getTemplates: function (tag) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GET_TAG,
      data: tag,
    });
  },
  getApplications: function (applicationName) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GET_APPLICATIONS,
      data: applicationName,
    });
  },
  userLoggedIn: function (data) {
    EditorDispatcher.dispatch({
      type: ActionTypes.USER_LOGGED_IN,
      data: data,
    });
  },
  legacyLoggedIn: function (data) {
    EditorDispatcher.dispatch({
      type: ActionTypes.USER_LEGACY_LOGGED_IN,
      data: data,
    });
  },
  logout: function (data) {
    EditorDispatcher.dispatch({
      type: ActionTypes.LOGOUT,
      data: data,
    });
  },
  showPro: function (data) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_PRO,
      data: data,
    });
  },
  showProGet: function (data) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_PRO_GET,
      data: data,
    });
  },
  showProGetSave: function (data) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_PRO_GET_SAVE,
      data: data,
    });
  },
  print: function () {
    EditorDispatcher.dispatch({
      type: ActionTypes.PRINT,
    });
  },
  showLogin: function (data, postLoginUrl, mode) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_LOGIN,
      data: data,
      postLoginUrl: postLoginUrl,
      mode: mode,
    });
  },
  showShareDialog: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_SHARE_DIALOG,
      data: value,
    });
  },
  showHamburgerMenu: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_HAMBURGER_MENU,
      data: value,
    });
  },

  loginMode: function (mode) {
    EditorDispatcher.dispatch({
      type: ActionTypes.LOGIN_MODE,
      data: mode,
    });
  },
  saveProject: function (navigateLocation) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PROJECT_SAVE,
      data: navigateLocation,
    });
  },
  deleteFolder: function (folder) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_FOLDER,
      data: folder,
    });
  },
  uploadProgress: function (progress) {
    EditorDispatcher.dispatch({
      type: ActionTypes.UPLOAD_PROGRESS,
      data: progress,
    });
  },
  loadPrintProducts: function () {
    EditorDispatcher.dispatch({
      type: ActionTypes.LOAD_PRINT_PRODUCTS,
    });
  },
};
