import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import EditorStore from "../stores/EditorStore";

const CustomStickers = (props) => {
  const addSticker = (src) => {
    EditorActionCreators.ShowFilePicker(false);
    window.editor.addSticker(src, null, null, () => {
      EditorStore.setShowSpinner(false);
    });
  };

  const dragStart = (e, src) => {
    e.dataTransfer.setData("text", src);
  };

  const seeAll = (key, items) => {
    props.seeall(key, items);
  };

  return (
    <div className="sticker-scroll">
      <div className="sticker-scroll-topbar">
        <span className="sticker-scroll-name">{props.categoryName}</span>
        <span className="sticker-scroll-count">{props.items?.length}</span>
        <div
          className="sticker-scroll-seeall clickable"
          onClick={() => seeAll(props.categoryName, props.items)}
        >
          See all
        </div>
      </div>
      <div className="sticker-scroll-items-row">
        <div className="sticker-scroll-items">
          <div className="sticker-scroll_items-overflow">
            {props.items?.slice(0, 5)?.map((item, index) => {
              return (
                <div className="sticker-item" key={index}>
                  <img
                    className="clickable"
                    src={item.thumbnail}
                    onClick={() => addSticker(item.src)}
                    alt="download"
                    onDragStart={(e) => dragStart(e, item.src)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomStickers;
