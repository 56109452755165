import React, { useEffect, useMemo, useState } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import Login from "./Login.react";
import UserLeftMenu from "./UserLeftMenu.react";
import Gallery from "react-grid-gallery";
import Header from "../layouts/Header";
import HelmetDetails from "./HelmetDetails";

function getStateFromStores() {
  return {
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    assets: UserStore.getProjectAssets(),
  };
}

const Assets = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    if (UserStore.getUser() === null) {
      UserActionCreators.updateUserData(UserStore.getAccessToken());
    }
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  return (
    <div className="startPage">
      <HelmetDetails
        keywords=""
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app/assets"
      />
      <Header />
      <div className="container" id="container">
        {data.showLogin && <Login />}
        <div className="cutoutGallery">
          <Gallery
            images={data.assets}
            enableImageSelection={false}
            enableLightbox={false}
          />
        </div>
      </div>
      <UserLeftMenu selected="assets" />
    </div>
  );
};

export default Assets;
