import EditorDispatcher from "../dispatcher/EditorDispatcher";
import ActionTypes from "../constants/EditorConstants";

export const receiveAssets = (data) => {
  EditorDispatcher.dispatch({
    type: ActionTypes.ASSETS_RECEIVED,
    data: data,
  });
};

export const loadCategories = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_CATEGORIES,
  });
};

export const loadApplications = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_APPLICATIONS,
  });
};

export const loadGraphics = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_GRAPHIC,
  });
};

export const loadAiStyles = (data) => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_AI_STYLES,
    data: data,
  });
};

export const loadAISuggestions = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_AI_SUGGESTIONS,
  });
};

export const loadAITemplates = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_AI_TEMPLATES,
  });
};

export const loadTextTemplates = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.LOAD_TEXT_TEMPLATES,
  });
};

export const setSearchResults = (data) => {
  EditorDispatcher.dispatch({
    type: ActionTypes.PICKER_SEARCH,
    data: data,
  });
};

export const setStartSearchResults = (data) => {
  EditorDispatcher.dispatch({
    type: ActionTypes.START_SEARCH,
    data: data,
  });
};

export const getFeaturedGraphics = () => {
  EditorDispatcher.dispatch({
    type: ActionTypes.GET_FEATURED_GRAPHICS,
  });
};

export const searchStickers = (query) => {
  EditorDispatcher.dispatch({
    type: ActionTypes.STICKER_SEARCH,
    data: query,
  });
};

export const searchStickerBingResults = (query) => {
  EditorDispatcher.dispatch({
    type: ActionTypes.STICKER_BING_SEARCH,
    data: query,
  });
};
