import React, { useEffect, useState } from "react";
import AssetStore from "../stores/AssetStore";
import EditorStore from "../stores/EditorStore";
import FontCategory from "./FontCategory.react";
import FontItem from "./FontItem.react";
import Lockr from "lockr";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

function getStateFromStores(oldState) {
  return {
    type: oldState.type,
    selectedItem: AssetStore.getSelectedItem(),
  };
}

const FontPicker = (props) => {
  const [data, setData] = useState(getStateFromStores({ type: props.type }));

  useEffect(() => {
    AssetStore.addChangeListener(_onChange);
    if (Lockr.get("fontCategory")) {
      let fc = Lockr.get("fontCategory");
      AssetStore.getAllFontFolders().map(function (item) {
        if (item.key === fc) {
          AssetStore.setSelectedItem(item);
        }
        return null;
      });
    } else {
      AssetStore.setSelectedItem(AssetStore.getAllFontFolders()[1]);
    }
    AssetStore.emitChange();

    return () => {
      const localFonts = EditorStore.getLocalFonts();
      if (EditorStore.isElectron() && !localFonts) {
        window.GetFonts();
      }
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _cancelClick = (event) => {
    event.preventDefault();
    props.onHide();
  };

  const _onChange = () => {
    setData(getStateFromStores(data));
  };

  const onScroll = (event) => {
    let top = event.currentTarget.scrollTop;
    let bottom =
      event.currentTarget.scrollTop + event.currentTarget.clientHeight + 100;
    for (let i = 0; i < event.currentTarget.children.length; i++) {
      let el = event.currentTarget.children[i];
      if (el.style.fontFamily === "") {
        if (el.offsetTop >= top && el.offsetTop <= bottom) {
          let font = el.getAttribute("data-fontname");
          if (font) {
            el.style.fontFamily = font;
          }
        }
      }
    }
  };

  const onLocalFontSelected = (event) => {
    let font = event.currentTarget.attributes["data-fontname"].value;
    props.onSelected(font, true);
  };

  let categories = AssetStore.getAllFontFolders().map((item, index) => {
    let selected = data.selectedItem && data.selectedItem.key === item.key;
    return <FontCategory key={index} item={item} selected={selected} />;
  });

  let items = "";
  let containerClasses = "photopicker-photos fonts-container";
  if (data.selectedItem) {
    if (data.selectedItem.items.length === 0) {
      let count = 0;
      let lastOption = "";
      items = [];
      containerClasses = "photopicker-photos yourfonts-container";
      let localFonts = EditorStore.getLocalFonts();
      if (localFonts && localFonts.length) {
        localFonts.forEach(function (option) {
          if (lastOption !== option) {
            count++;
            let fontStyle = { fontFamily: option };
            if (count > 48) fontStyle = {};
            items.push(
              <div
                className="font-option clickable font-yours"
                key={option}
                data-pro="false"
                data-fontname={option}
                style={fontStyle}
                onClick={onLocalFontSelected}
              >
                {option}
              </div>
            );
          }
          lastOption = option;
        });
      }
    } else {
      items = data.selectedItem.items.map((item, index) => {
        const _item = {
          ...item,
          isProOnly: index >= 3 ? true : false,
        };
        return (
          <FontItem key={index} item={_item} onSelected={props.onSelected} />
        );
      });
    }
  }

  return (
    <div className="photopicker" id="photopicker">
      <div id="photopicker-dialog" className="photopicker-dialog">
        <div className="fontpicker-dialog-box">
          <div className="photopicker-navbar">
            <div className="photopicker-title">Select a font</div>
            <div className="close-btn" onClick={_cancelClick}>
              <CloseSVG height={12} />
            </div>
          </div>

          <div id="photopicker-container">
            <div id="photopicker-albums-list" className="photopicker-list">
              {categories}
            </div>
            <div className={containerClasses} onScroll={onScroll}>
              {items}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontPicker;
