import React, { useState, useEffect } from "react";
import AdminHeader from "../layouts/AdminHeader";
import UserStore from "../stores/UserStore";
import MaterialTable from "material-table";
import PatchedPagination from "../components/admin/PatchedPagination";

const AdminCategoriesPage = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [applications, setApplications] = useState([]);
  const [items, setItems] = useState([]);
  const accessToken = UserStore.getAccessToken();
  let [columns, setColumns] = useState([
    { title: "Application", field: "application", lookup: applications },
    {
      title: "Category",
      field: "category",
      lookup: {
        GRAPHICS: "Graphics",
        TEMPLATES: "Templates",
        BACKGROUNDS: "Backgrounds",
      },
    },
    { title: "Tag", field: "tag" },
    { title: "Sequence", field: "sequence", type: "numeric" },
    { title: "Name", field: "name" },
    { title: "Active", field: "active", type: "boolean" },
  ]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_API}/tagCategory`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.data);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
    fetch(`${process.env.REACT_APP_SERVER_API}/applications`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          var obj = result.reduce(function (acc, cur, i) {
            acc[cur.id] = cur.name;
            return acc;
          }, {});
          setApplications(obj);
          setColumns([
            { title: "Application", field: "application", lookup: obj },
            {
              title: "Category",
              field: "category",
              lookup: {
                GRAPHICS: "Graphics",
                TEMPLATES: "Templates",
                BACKGROUNDS: "Backgrounds",
              },
            },
            { title: "Tag", field: "tag" },
            { title: "Sequence", field: "sequence", type: "numeric" },
            { title: "Name", field: "name" },
            { title: "Active", field: "active", type: "boolean" },
          ]);
        },
        (error) => {
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <section className="admin-section banner banner-section">
        <AdminHeader />
        {isLoaded ? (
          <div className="item-b">
            <MaterialTable
              title="Tag Categories"
              columns={columns}
              data={items}
              options={{
                filtering: true,
                pageSizeOptions: [5, 10, 20, 100],
                pageSize: 20,
              }}
              components={{
                Pagination: PatchedPagination,
              }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      fetch(`${process.env.REACT_APP_SERVER_API}/tagCategory`, {
                        method: "POST",
                        headers: new Headers({
                          Authorization: `Bearer ${accessToken}`,
                          "content-type": "application/json",
                        }),
                        body: JSON.stringify(newData),
                      })
                        .then((res) => {
                          if (res.status === 200) {
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...items];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      fetch(
                        `${
                          process.env.REACT_APP_SERVER_API
                        }/tagCategory/${encodeURIComponent(newData.id)}`,
                        {
                          method: "PUT",
                          headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            "content-type": "application/json",
                          }),
                          body: JSON.stringify(newData),
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...items];
                      const index = oldData.tableData.id;
                      const record = dataDelete[index];
                      dataDelete.splice(index, 1);
                      fetch(
                        `${
                          process.env.REACT_APP_SERVER_API
                        }/tagCategory/${encodeURIComponent(record.id)}`,
                        {
                          method: "DELETE",
                          headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                          }),
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
              }}
            />
          </div>
        ) : (
          <div className="loading"></div>
        )}
      </section>
    );
  }
};

export default AdminCategoriesPage;
