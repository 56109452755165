import EditorDispatcher from "../dispatcher/EditorDispatcher";
import JSONUtils from "../utils/JSONUtils";
import { EventEmitter } from "events";
import assign from "object-assign";
import ActionTypes from "../constants/EditorConstants";

EventEmitter.defaultMaxListeners = 15;
var CHANGE_EVENT = "change";
var _folders = [];
var _folderItems = [];
var fbNamespace = "local_photo_editor";
var baseUrl = "https://www.pizap.com";
var fbAppId = "158391354186658";

const FacebookStore = assign({}, EventEmitter.prototype, {
  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  getFolders: function () {
    return _folders;
  },
  getFolderItems: function () {
    return _folderItems;
  },
  getFbNamespace: function () {
    return fbNamespace;
  },
  getBaseUrl: function () {
    return baseUrl;
  },
  getFbAppId: function () {
    return fbAppId;
  },
  setBaseUrl: function (_baseUrl) {
    baseUrl = _baseUrl;
  },
  setFbNamespace: function (_fbNamespace) {
    fbNamespace = _fbNamespace;
  },
  setFbAppId: function (_fbAppId) {
    fbAppId = _fbAppId;
  },
});
var afterCursor = null;

FacebookStore.dispatchToken = EditorDispatcher.register(function (action) {
  switch (action.type) {
    case ActionTypes.PICKER_STATE:
      if (action.value === "facebook") {
        _folderItems = [];
        window.FB.api("/me/albums", "get", null, function (response) {
          if (response && response.data) {
            _folders = response.data;
            FacebookStore.emitChange();
          }
        });
        break;
      }
      if (action.value === "flickr") {
        _folderItems = [];
        JSONUtils.getJSON(
          "https://www.pizap.com/flickr/getPhotos?page=1",
          "GET",
          null,
          function (e) {
            _folderItems = [];
            if (e && e.photos && e.photos.photo) {
              for (var i = 0; i < e.photos.photo.length; i++) {
                var base =
                  "https://farm" +
                  e.photos.photo[i].farm +
                  ".staticflickr.com/" +
                  e.photos.photo[i].server +
                  "/" +
                  e.photos.photo[i].id +
                  "_" +
                  e.photos.photo[i].secret;
                var p = {
                  key: e.photos.photo[i].id,
                  src: base + ".jpg",
                  icon: base + "_m.jpg",
                };
                _folderItems.push(p);
              }
            }
            FacebookStore.emitChange();
          }
        );
        break;
      }
      break;

    case ActionTypes.FACEBOOK_GET_FOLDERS:
      _folderItems = [];
      window.FB.api("/me/albums", "get", null, function (response) {
        if (response && response.data) {
          _folders = response.data;
          FacebookStore.emitChange();
        }
      });
      break;
    case ActionTypes.FILE_PICKER_CATEGORY_SELECTED:
      if (action.itemType === "facebook") {
        window.FB.api(
          "/" + action.data.id + "/photos?fields=images,picture",
          { after: afterCursor },
          function (response) {
            if (response && !response.error) {
              _folderItems = response.data;
              FacebookStore.emitChange();
            }
          }
        );
        //_folderItems = [];
        //FacebookStore.emitChange();
      }
      break;
    default:
    // do nothing
  }
});

export default FacebookStore;
