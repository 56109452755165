const isIE = () => {
  return (
    navigator.appName === "Microsoft Internet Explorer" ||
    (navigator.appName === "Netscape" &&
      new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})").exec(
        navigator.userAgent
      ) != null)
  );
};

const isLegacyEdge = () => {
  return new RegExp("Edge/").exec(navigator.userAgent) != null;
};

const getURLParameter = (name) => {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=([^&;]+?)(&|#|;|$)").exec(
        window.location.search
      ) || [null, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
};

const randomGen = () => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 5);
};

const decimalToHex = (d) => {
  return Number(d).toString(16).padStart(6, "0");
};

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const checkUsageLimit = (imgSize) => {
  const currentDate = getCurrentDate();
  const count = imgSize === "square" ? 1 : 2;
  const usageCount = localStorage.getItem("usageCount") || 0;
  const lastUsedDate = localStorage.getItem("lastUsedDate");
  if (lastUsedDate === currentDate) {
    if (usageCount >= 15) {
      return false;
    } else {
      localStorage.setItem("usageCount", Number(usageCount) + count);
    }
  } else {
    localStorage.setItem("usageCount", count);
    localStorage.setItem("lastUsedDate", currentDate);
  }
  return true;
};

const CarouselResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

const AICarouselResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const identifyDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
};

const hasTransparency = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const data = imageData.data;
      for (let i = 3; i < data.length; i += 4) {
        if (data[i] < 255) {
          resolve(true); // Image has transparency
          return;
        }
      }
      resolve(false); // Image does not have transparency
    };
    img.onerror = function () {
      reject(new Error("Failed to load image"));
    };
    img.src = imageUrl;
  });
};

const getContrastColor = (hexColor) => {
  var r = parseInt(hexColor.substr(1, 2), 16);
  var g = parseInt(hexColor.substr(3, 2), 16);
  var b = parseInt(hexColor.substr(5, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#FFFFFF";
};

const getOppositeColor = (color) => {
  // Remove the # symbol if it exists
  color = color.replace("#", "");
  // Convert the color to a number
  const num = parseInt(color, 16);
  // Get the opposite color using bitwise XOR
  const oppositeNum = num ^ 0xffffff;
  // Convert the opposite color back to hexadecimal
  const oppositeColor = "#" + oppositeNum.toString(16).padStart(6, "0");
  return oppositeColor;
};

function checkImageTransparency(imageUrl, callback) {
  // Create an image element
  const img = new Image();
  // Allow cross-origin resource sharing (CORS) to access pixel data
  img.crossOrigin = 'Anonymous';

  // Once the image is loaded, draw it to the canvas
  img.onload = function() {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to the image dimensions
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Try to get the image data
      try {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const data = imageData.data; // RGBA data

          // Check for transparency
          for (let i = 0; i < data.length; i += 4) {
              if (data[i + 3] < 255) {
                  // Image has transparency
                  callback(true);
                  return;
              }
          }
          // No transparency found
          callback(false);
      } catch (e) {
          // Cross-origin error or other error
          console.error('Error checking image transparency:', e);
          callback(false);
      }
  };

  // Handle errors, such as when an image is not found or is cross-origin without proper CORS headers
  img.onerror = function() {
      console.error('There was an error loading the image.');
      callback(false);
  };

  // Set the source of the image to the provided URL
  img.src = imageUrl;
}

export {
  isIE,
  isLegacyEdge,
  getURLParameter,
  randomGen,
  decimalToHex,
  checkUsageLimit,
  CarouselResponsive,
  AICarouselResponsive,
  hasTransparency,
  identifyDevice,
  getContrastColor,
  getOppositeColor,
  checkImageTransparency
};
