import * as PIXI from "pixi.js";

export default {
  init: function (d, scale) {
    var rtn = new PIXI.Graphics();
    rtn.svgpath = d;
    rtn.beginFill(0, 1);
    this.drawPathNode(d, rtn, scale);
    rtn.currentPath.shape.closed = true;
    rtn.closePath();
    rtn.endFill();
    return rtn;
  },

  drawPathNode: function (d, g, scale) {
    d = d.trim();
    var commands = d.match(/[a-df-z][^a-df-z]*/gi);
    var command, lastCoord, lastControl;

    var pathIndex = 0;
    var j, argslen;
    var lastPathCoord = { x: 0, y: 0 };
    lastControl = { x: 0, y: 0 };
    for (var i = 0, len = commands.length; i < len; i++) {
      command = commands[i];
      var commandType = command[0];
      var args = command
        .slice(1)
        .trim()
        .split(/[\s,]+|(?=\s?[+-])/);

      for (j = 0, argslen = args.length; j < argslen; j++) {
        args[j] = parseFloat(args[j]) * scale;
      }

      var offset = {
        x: 0,
        y: 0,
      };
      if (commandType === commandType.toLowerCase()) {
        // Relative positions
        offset = lastCoord;
      }

      switch (commandType.toLowerCase()) {
        // moveto command
        case "m":
          args[0] += offset.x;
          args[1] += offset.y;
          g.moveTo(args[0], args[1]);
          lastCoord = { x: args[0], y: args[1] };
          break;
        // lineto command
        case "l":
          for (var q = 0; q < args.length; q += 2) {
            args[q] += offset.x;
            args[q + 1] += offset.y;

            g.lineTo(args[q], args[q + 1]);
            lastCoord = { x: args[q], y: args[q + 1] };
          }
          break;
        // curveto command
        case "c":
          for (var k = 0, klen = args.length; k < klen; k += 2) {
            args[k] += offset.x;
            args[k + 1] += offset.y;
          }

          g.bezierCurveTo(args[0], args[1], args[2], args[3], args[4], args[5]);
          lastCoord = { x: args[4], y: args[5] };
          lastControl = { x: args[2], y: args[3] };
          break;
        // vertial lineto command
        case "v":
          args[0] += offset.y;

          g.lineTo(lastCoord.x, args[0]);
          lastCoord.y = args[0];
          break;
        // horizontal lineto command
        case "h":
          args[0] += offset.x;

          g.lineTo(args[0], lastCoord.y);
          lastCoord.x = args[0];
          break;
        // quadratic curve command
        case "q":
        case "s":
          for (var l = 0, llen = args.length; l < llen; l += 2) {
            args[l] += offset.x;
            args[l + 1] += offset.y;
          }

          var rx = 2 * lastCoord.x - lastControl.x;
          var ry = 2 * lastCoord.y - lastControl.y;

          g.bezierCurveTo(rx, ry, args[0], args[1], args[2], args[3]);
          lastCoord = { x: args[2], y: args[3] };
          lastControl = { x: args[0], y: args[1] };
          break;
        // closepath command
        case "z":
          // Z command is handled by M
          break;
        default:
          throw new Error(
            "Could not handle path command: " +
              commandType +
              " " +
              args.join(",")
          );
      }
    }

    if (pathIndex > 1) {
      // Move from lastCoord to lastPathCoord
      g.lineTo(lastPathCoord.x, lastCoord.y);
      g.lineTo(lastPathCoord.x, lastPathCoord.y);
    }
  },
};
