import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PhotoEffectspage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Photo Effects and Filters | Online Photo Editor Effects"
        description="Quickly add photo effects and filters to photos with over 100 Instagram style filters and effects."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/photo_effects_filters_app"
        keywords="Photo Effects, photo filters, instagram sytle filters"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">
            The Editing App Known for its Dazzling Photo Effects
          </p>
          <p className="desc">
            Meet the many photo effects offered on piZap’s photo editor app
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/start");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_effects_filters_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_effects_filters_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Photo Effects & Filters</span>
            <span className="desc">
              From basic edits to unique photo effects and filters, piZap offers
              a superior way to edit pictures with a Zap! Simply upload your
              image to the app and enjoy countless free editing tools at your
              fingertips. Plus, piZap is easy to use for people of all photo
              editing backgrounds and levels of experience—even if you’re new to
              this whole ‘editing your pictures’ thing.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Photo Effects Tool Overview s</span>
            <span className="desc">
              piZap is jam-packed with photo effect tools, so many in fact, time
              tends to just fade away after you start using it. A variety of
              editing tools allow you to rotate images and fix flaws; adjust
              filters, saturation, contrast, and brightness. Add pops of color
              via stickers or spark all the feels by adding text boxes. You can
              also Zap MEMEs, collages and graphic designs into creation all on
              the same app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_effects_filters_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_effects_filters_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Create Beautiful Photo Effects</span>
            <span className="desc">
              Get ready to feel like a professional photo editor in
              minutes—that’s how easy it is to use piZap’s Photo Effects.
              piZap’s photo editing tools are inspired by the latest and
              greatest editing tools employed by professionals. We took these
              same concepts and made them easily accessible to everyone, no
              photo editing experience necessary. Want to share your photos on
              Facebook or another popular social media site? We make it easy to
              share your creations directly in the app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Fun Photo Effects</span>
            <span className="desc">
              The pictures that get the most likes are anything but basic. Have
              fun kicking “basic” to the curb with piZap’s various photo
              effects. There are tons of filters and textures to choose from;
              create a glittering or glowing effect in seconds. Shift tint,
              saturations, brightness, and contrast; choose from basic
              Instagram-type filters or go for something more bold and unique.
              Create stunning professional quality edits or add a little more
              fun by using special in-app features like the Fun Filters
              collection.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_effects_filters_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_effects_filters_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Lots of Photo Effects & Features</h3>
            </span>
            <span className="desc">
              With more photo effects added all the time, piZap offers the ideal
              platform to edit pictures. Did we mention there are thousands of
              stickers to choose from? Including holiday collections, specialty
              designs, flowers, sparkles, and the list goes on. With hundreds of
              text effects and fonts to choose from you can add headers, funny
              quotes and more. Furthermore, you can save as many photo edits as
              you want for free! Create perfect Facebook, Twitter and YouTube
              cover photos with the click of a button. There are endless photo
              effects features packed into piZap. The best part of all? You can
              try it for free!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PhotoEffectspage;
