import SparkMD5 from "spark-md5";
import qq from "fine-uploader/lib/s3";
import UserActionCreators from "../actions/UserActionCreators";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";

export default {
  uploads: [],
  dataURItoBlob: function (dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  },
  updateSaveStatus: function () {
    var statucMessage = "";
    if (this.uploads !== null && this.uploads.length > 0) {
      this.uploads.forEach(function (item) {
        statucMessage += item.textStatus;
      });
    }
    if (statucMessage !== "") {
      EditorStore.setStatusMessage(statucMessage);
      EditorStore.emitChange();
    } else {
      if (this.uploads.length > 0) {
        EditorStore.setStatusMessage(this.uploads.length + " uploads ");
        EditorStore.emitChange();
      } else {
        EditorStore.setStatusMessage("");
        EditorStore.emitChange();
      }
    }
  },
  createAsset: function (url, callback) {
    var self = this;
    if (url == null) return;
    var mimeType = url.split(",")[0].split(":")[1].split(";")[0];
    var spark = new SparkMD5();
    spark.append(url);
    var hexHash = spark.end();
    const uploader = new qq.s3.FineUploaderBasic({
      request: {
        endpoint: "https://s3-us-west-1.amazonaws.com/imagesw.pizap.com",
        accessKey: "AKIARRX2DH6EURCDHDHL",
      },
      cors: {
        //all requests are expected to be cross-domain requests
        expected: true,

        //if you want cookies to be sent along with the request
        sendCredentials: true,
        region: "s3-us-west-1",
      },
      signature: {
        endpoint: `${process.env.REACT_APP_SERVER_API}/cutouts/create/${hexHash}`,
        customHeaders: {
          Authorization: "Bearer " + UserStore.getAccessToken(),
        },
      },
      objectProperties: {
        reducedRedundancy: true,
        region: "us-west-1",
        acl: "public-read",
        key: function (fileId) {
          return UserStore.getUser().UserName + "_" + hexHash + ".png";
        },
      },
      retry: { enableAuto: true },
      chunking: { enabled: false },
      resume: { enabled: true },
      callbacks: {
        onComplete: function (id) {
          if (callback)
            callback(
              url,
              "https://s3-us-west-1.amazonaws.com/imagesw.pizap.com/" +
                UserStore.getUser().UserName +
                "_" +
                hexHash +
                ".png"
            );
        },
        onProgress: function (id, name, uploadedBytes, totalBytes) {
          UserActionCreators.uploadProgress((uploadedBytes / totalBytes) * 100);
        },
      },
    });

    uploader.addFiles([self.dataURItoBlob(url)]);
    var i = document.createElement("img");
    var thumbHeight;
    i.onload = function () {
      //Store project record for user
      thumbHeight = window.editor.stickerSelector.calculateGetScaledHeight(
        i.width,
        i.height,
        480
      );
      var canvas = document.createElement("canvas");
      canvas.width = 480;
      canvas.height = thumbHeight;
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(i, 0, 0, canvas.width, canvas.height);
      const thumbuploader = new qq.s3.FineUploaderBasic({
        request: {
          endpoint: "https://s3-us-west-1.amazonaws.com/thumbnailsw.pizap.com",
          accessKey: "AKIARRX2DH6EURCDHDHL",
        },
        cors: {
          //all requests are expected to be cross-domain requests
          expected: true,
          //if you want cookies to be sent along with the request
          sendCredentials: true,
          region: "s3-us-west-1",
        },
        signature: {
          endpoint: `${process.env.REACT_APP_SERVER_API}/cutouts/createThumb/hexHash`,
          customHeaders: {
            Authorization: "Bearer " + UserStore.getAccessToken(),
          },
        },
        objectProperties: {
          reducedRedundancy: true,
          region: "us-west-1",
          acl: "public-read",
          key: function (fileId) {
            return UserStore.getUser().UserName + "_" + hexHash + ".png";
          },
        },
        uploadSuccess: {
          endpoint: `${process.env.REACT_APP_SERVER_API}/cutouts`,
          customHeaders: {
            Authorization: "Bearer " + UserStore.getAccessToken(),
          },
          params: {
            ImageName: UserStore.getUser().UserName + "_" + hexHash + ".png",
            ThumbWidth: 480,
            ThumbHeight: thumbHeight,
            ImageWidth: i.width,
            ImageHeight: i.height,
            ImageSize: url.length,
            MimeType: mimeType,
          },
        },
        retry: { enableAuto: true },
        chunking: { enabled: true },
        resume: { enabled: true },
      });
      canvas.toBlob(
        function (blob) {
          thumbuploader.addFiles(blob);
        },
        "image/jpeg",
        0.6
      );
    };
    i.src = url;
  },
};
