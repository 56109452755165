import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const ThankYouPage = () => {
  const [data, setData] = useState(getStateFromStores);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  return (
    <>
      <Header />
      <HelmetDetails
        title="Thank You | Free online photo editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/thankyou"
        keywords="pizap, pizap.com, online, photo, editor, photograph, picture, fonts, cut-out, stickers, collage, maker, free, opacity, alpha, edit, editing, adjustments, online, facebook, camera, digital, image, crop, fix, photoshop, gimp, photoshop"
      />
      <div className="thankspart">
        <div className="thankspart-content">
          <img src="//cdn.pizap.com/pizapfiles/images/thankyou-header.png" />
          <div className="thankspartner">
            <a
              href="https://welcometo.freepik.com/landing/fp-ads-doll-com.html?coupon=30pizap#utm_source=pizap&amp;utm_medium=collabo&amp;utm_campaign=pizap"
              target="_blank"
            >
              <img src="http://cdn.pizap.com/pizapfiles/images/freepik-thankyou01.jpg" />
              <img src="http://cdn.pizap.com/pizapfiles/images/freepik-thankyou02.jpg" />
            </a>
            <div style={{ alignContent: "center" }}>
              <p>
                Freepik offers users high quality graphic designs, exclusive
                illustrations and graphic resources carefully selected by our
                design team in order to provide great content that can be used
                for both personal and commercial projects.
              </p>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default ThankYouPage;
