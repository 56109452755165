import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import StartButton from "../components/StartButton";
import HelmetDetails from "../components/HelmetDetails";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const FrameBordersPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <Header />
      <HelmetDetails
        title="Photo Frames Maker | Borders Capture App"
        description="Online Photo Frames Maker and Design Tool. Quickly and easily create a Photo Frames with our easy online photo editor"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/photo_frames_borders_app"
        keywords="Photo Frames Maker, Christmas Card Designer, Make Cards Online"
      />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Photo Frames Made Fun</p>
          <p className="desc">
            Easily add photo frames and borders that capture attention
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_frame_border_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_frame_border_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Photo Frames and Borders</span>
            <span className="desc">
              piZap welcomes you to try easily customizable free online picture
              frames and borders. Photo frames can change the look of an image
              or even your entire Instagram page. They can also make your photos
              stand out more and get more likes. Easily add photo frames from
              your desktop or a mobile device using piZap.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap’s Photo Frames Tool</span>
            <span className="desc">
              piZap makes it easy to add photo frames that are white, bright;
              thick, thin; matte, sparkly—you name it, piZap’s got it. Simply
              take your pick of the best and most varied photo frames out there.
              piZap offers much more than just a photo frames tool, it’s also a
              photo editing app, MEME maker, collage maker, and graphic design
              creator to boot.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_frame_border_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_frame_border_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Add Picture Frames and Borders</span>
            <span className="desc">
              Finally, you can take your pictures to the next level and you
              don’t have to be a photo editing expert. piZap was designed to
              make photo editing easy, and that includes the ability to add
              photo borders without gritting your teeth. You know those
              Instagram accounts with larger white spaces between each photo?
              You can create the same look on your profile by adding the
              same-size white border to every photo you post on the gram. It’s
              just as easy to get creative with vibrant or unique frames and
              borders—piZap offers so many to choose from!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Fun Photo Frames for All</span>
            <span className="desc">
              These aren’t your basic filters found in every other app, piZap
              filters are designed around fun. Borders are also made to
              represent your life and memories, and that’s obvious when you look
              through the wide selection available. And for those times when you
              want to keep it simple with white or black photo frames, piZap
              makes it easy to do that too.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_frame_border_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo_frame_border_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Borders, Frames, & a Flurry of Other Features</h3>
            </span>
            <span className="desc">
              While you’re adding free online picture frames, why not make use
              of the many tools and features piZap offers. Add stickers or text
              to your photos using in-app editing tools. Add an even more
              personalized touch to photo borders using Paint Tools. Or, make a
              MEME and add a border to it. Did we mention there’s a custom Emoji
              maker too? Try all this and more for free!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default FrameBordersPage;
