import React, { useEffect, useState, useMemo, useRef } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import UserLeftMenu from "./UserLeftMenu.react";
import Gallery from "react-grid-gallery";
import ThumbOverlay from "./ThumbOverLay.react";
import Header from "../layouts/Header";
import HelmetDetails from "./HelmetDetails";
import Login from "./Login.react";
import ProGetSave from "./ProGetSave.react";
import ProGet from "./ProGet.react";
import ProTeaser from "./ProTeaser.react";
import IconEmpty from "../images/editor/empty-folder.png";

function getStateFromStores() {
  return {
    cutouts: UserStore.getCutouts(),
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showTagDialog: UserStore.getShowTagDialog(),
  };
}

const Cutouts = () => {
  const [data, setData] = useState(getStateFromStores());
  const [visibleImages, setVisibleImages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const galleryRef = useRef(null);

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    if (UserStore.getUser() === null) {
      UserActionCreators.updateUserData(UserStore.getAccessToken());
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  });

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    loadMoreImages();
  }, [data.cutouts]);

  const loadMoreImages = () => {
    const newStartIndex = startIndex + 100;
    if (data.cutouts && data.cutouts?.length > 0) {
      const newVisibleImages = data.cutouts
        .slice(0, newStartIndex)
        .map((item) => {
          return {
            ...item,
            customOverlay: <ThumbOverlay cutout={item} />,
          };
        });
      setVisibleImages(newVisibleImages);
      setStartIndex(newStartIndex);
    } else {
      setVisibleImages([]);
      setStartIndex(0);
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = galleryRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 300) {
      loadMoreImages();
    }
  };

  useEffect(() => {
    galleryRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      galleryRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [visibleImages, startIndex]);

  const loginDialog = useMemo(() => {
    if (data.showLogin) {
      return <Login />;
    }
    if (data.showProGetSave) {
      return <ProGetSave />;
    }
    if (data.showProGet) {
      return <ProGet />;
    }
    if (data.showPro) {
      return <ProTeaser />;
    }
    if (data.showTagDialog) {
      return <TagDialog />;
    }
  }, [data]);

  return (
    <div className="startPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app/cutouts"
        keywords=""
      />
      <Header />
      {loginDialog}
      <div className="uploaded" id="container">
        <div
          className="cutoutGallery"
          ref={galleryRef}
          style={{ maxHeight: "1000px", overflowY: "auto" }}
        >
          {visibleImages.length > 0 ? (
            <Gallery
              images={visibleImages}
              enableImageSelection={false}
              enableLightbox={false}
              rowHeight={140}
            />
          ) : (
            <img className="empty-folder" src={IconEmpty} />
          )}
        </div>
      </div>
      <UserLeftMenu selected="cutouts" />
    </div>
  );
};

export default Cutouts;
