import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const WeddingInvitationPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Wedding Invitation Maker | Design Wedding Invitations Online"
        description="Our online wedding invitation maker lets you design your own wedding invitations."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/wedding_invitation_maker_app"
        keywords="Wedding invitation maker, wedding invites designer, create, online"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Wedding Invitation Maker</p>
          <p className="desc">
            Beautiful DIY wedding invitations at your fingertips
          </p>
          <StartButton
            text="Start new design →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wedding_invitation_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wedding_invitation_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Wedding Invitation Maker</span>
            <span className="desc">
              Make beautiful wedding invitations online using piZap’s Invitation
              Maker Tool. It’s easy, fun, and the stunning results speak for
              themselves. Plus, you can use this same app to create custom photo
              Thank You Cards, Bridal Shower Invitations, and more!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              Tool Overview: The Wedding Invitation Maker
            </span>
            <span className="desc">
              The Wedding Invitation Maker is one of many invitation makers
              included with piZap. Your wedding is the most important day of
              your life, so we made sure to include all the right tools, fonts
              and stickers to represent your big day. Create personal
              invitations by adding a photo, or stick to classic sophistication
              using gold curly fonts and floral designs. That’s the great thing
              about piZap’s many tools and features, they give you the freedom
              to unleash your inner style and creativity.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/wedding_invitation_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wedding_invitation_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easy to Use Wedding Invitation App</span>
            <span className="desc">
              DIY wedding invitations don’t have to be a tireless challenge.
              piZap’s Wedding Invitation Maker makes it easy to add text to
              engagement photos or insert love-themed stickers. There are
              hundreds of fonts and text effects to choose from, including
              plenty of glittery wedding-ready fonts. As for stickers, there are
              thousands conveniently grouped into categories for easy browsing.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              Have Fun Making Your Own Wedding Invitations Online
            </span>
            <span className="desc">
              It’s not fun to spend $1,000+ on paper wedding invitations. That’s
              why so many couples are turning to the idea of making their own
              wedding invitations online. piZap’s Wedding Invitation Maker is
              designed so that it’s fun for couples to do just that. There are
              too many other tedious chores associated with wedding planning—why
              not make it fun to create your wedding invitations?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/wedding_invitation_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wedding_invitation_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Wedding Invitation Maker + Many More Features
            </span>
            <span className="desc">
              Making custom wedding invitations online has never been easier,
              but that’s not the only reason people are cheering for piZap. For
              starters, you have countless photo editing, design, and touch up
              tools at your fingertips. You can use these features on separate
              projects or to enhance certain aspects of your wedding
              invitations. For instance, use piZap’s logo maker to create a
              pretty combination of the bride and groom’s initials to add to the
              wedding invitation.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default WeddingInvitationPage;
