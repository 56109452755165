import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import LeftMenuStore from "../stores/LeftMenuStore";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import React, { useEffect, useState, useMemo } from "react";
import MenuMain from "./MenuMain.react";
import MenuGraphics from "./MenuGraphics.react";
import MenuPhotos from "./MenuPhotos.react";
import MenuText from "./LeftMenu/MenuText";
import MenuCollage from "./MenuCollage.react";
import MenuBorder from "./MenuBorder.react";
import MenuBorderCustom from "./MenuBorderCustom.react";
import MenuMeme from "./LeftMenu/MenuMeme";
import MenuPaint from "./MenuPaint.react";
import MenuDesign from "./MenuDesign.react";
import MenuEffect from "./MenuEffect.react";
import MenuTextType from "./MenuTextType.react";
import MenuCutout from "./MenuCutout.react";
import MenuHotspot from "./MenuHotspot.react";
import MenuTouchup from "./MenuTouchup.react";
import SpeechBubbles from "./LeftMenu/SpeechBubbles";
import EditGraphic from "./LeftMenu/EditGraphic";
import AssetStore from "../stores/AssetStore";
import { ReactComponent as IconResize } from "../images/editor/resize_icon.svg";
import Lockr from "lockr";

function getStateFromStores() {
  return {
    menuSelected: !LeftMenuStore.get()
      ? EditorStore.getEditorType() === "collage"
        ? "collage"
        : "main"
      : LeftMenuStore.get(),
    editorType: EditorStore.getEditorType(),
  };
}

function getMenu(menu) {
  switch (menu) {
    case "main":
      return <MenuMain />;
    case "init":
      return <MenuMain />;
    case "design":
      if (
        EditorStore.getDesignBackground() ||
        EditorStore.getEditorType() !== "design"
      ) {
        return <MenuMain />;
      } else {
        return <MenuDesign />;
      }
    case "sticker":
      return <MenuGraphics isReset={LeftMenuStore.isResetMenu()} />;
    case "effect":
      return <MenuEffect />;
    case "text":
      return <MenuTextType />;
    case "text_props":
      return <MenuText />;
    case "collage":
      return <MenuCollage />;
    case "border":
      return <MenuBorder />;
    case "border_custom":
      return <MenuBorderCustom />;
    case "meme":
      return <MenuMeme />;
    case "speech":
      return <SpeechBubbles />;
    case "paint":
      return <MenuPaint />;
    case "touchup":
      return <MenuTouchup />;
    case "photos":
      return <MenuPhotos isReset={LeftMenuStore.isResetMenu()} />;
    case "cutout":
      return <MenuCutout />;
    case "collagehotspot":
      return <MenuHotspot />;
    case "edit_graphic_svg":
      return window.editor.getSelectedObject() ? (
        <EditGraphic />
      ) : (
        <MenuGraphics isReset={LeftMenuStore.isResetMenu()} />
      );
    case "edit_graphic":
      return window.editor.getSelectedObject() ? (
        <EditGraphic />
      ) : (
        <MenuPhotos isReset={LeftMenuStore.isResetMenu()} />
      );
    default:
      return null;
  }
}

const LeftMenu = () => {
  const [data, setData] = useState(getStateFromStores());
  useEffect(() => {
    LeftMenuStore.addChangeListener(_onChange);
    EditorStore.addChangeListener(_onChange);
    UserStore.addChangeListener(_onChange);
    return () => {
      LeftMenuStore.removeChangeListener(_onChange);
      EditorStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _menuSelected = (event) => {
    event.preventDefault();
    if (EditorStore.getEditorType() === "design") {
      LeftMenuActionCreators.MenuSelected("design");
    } else {
      LeftMenuActionCreators.MenuSelected("main");
    }
    window.editor.setSelectedObject(null);
  };

  const _collageSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("collage");
    window.editor.setSelectedObject(null);
  };

  const _stickerSelected = (event) => {
    event.preventDefault();
    if (LeftMenuStore.get() === "sticker") {
      AssetStore.setCachedStickers({
        title: "",
        activeTab: "piZap",
        items: [],
        isPro: false,
      });
      AssetStore.setRememberStickers(false);
    }
    LeftMenuActionCreators.MenuSelected("sticker");
    if (
      window.editor.getSelectedObject() === null &&
      LeftMenuStore.get() === "sticker"
    ) {
      LeftMenuActionCreators.ResetMenu(true);
    } else {
      LeftMenuActionCreators.ResetMenu(false);
    }
    window.editor.setSelectedObject(null);
  };

  const _textSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("text");
    window.editor.setSelectedObject(null);
  };

  const _cutoutSelected = (event) => {
    event.preventDefault();
    window.editor.removeCutout();
    EditorActionCreators.ShowCutoutInstructions(true);
    EditorActionCreators.ShowGraphicCutoutStyle(false);
  };

  const _resizeSelected = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowDesignPicker(true);
  };

  const _borderSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("border");
    window.editor.setSelectedObject(null);
  };

  const _effectSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("effect");
    window.editor.setSelectedObject(null);
  };

  const photosSelected = (e) => {
    e.preventDefault();
    AssetStore.setRememberPhotos(false);
    LeftMenuActionCreators.MenuSelected("photos");
    LeftMenuActionCreators.ResetMenu(true);
    window.editor.setSelectedObject(null);
  };

  const _paintSelected = (event) => {
    event.preventDefault();
    LeftMenuActionCreators.MenuSelected("paint");
    window.editor.setSelectedObject(null);
  };

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const goHome = async () => {
    Lockr.set("featuredApp", "pizap");
    await window.editor.saveProject("/app/start");
  };

  const editPanel = useMemo(() => {
    return getMenu(data.menuSelected);
  }, [data]);

  const mainMenu = useMemo(() => {
    if (data.editorType !== "collage") {
      if (data.editorType === "design") {
        return (
          <li
            className={`list clickable ${
              data.menuSelected === "main" ||
              data.menuSelected === "design" ||
              data.menuSelected === "touchup"
                ? `is-active`
                : ``
            }`}
            title="Background"
            onClick={(e) => _menuSelected(e)}
          >
            <span className="icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 465.9 466"
                enableBackground="new 0 0 465.9 466"
              >
                <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                  <g>
                    <g id="Layer0_0_FILL">
                      <path
                        fill="#FFFFFF"
                        d="M394.7,13.2C377.3,4.4,358,0,336.9,0H129C93.4,0,63,12.6,37.8,37.8C12.6,62.9,0,93.4,0,129v208
        c0,35.6,12.6,66,37.8,91.2C63,453.3,93.4,466,129,466h207.9c35.6,0,66-12.6,91.2-37.8c25.2-25.2,37.8-55.6,37.8-91.2V129
        c0-8.6-0.7-16.9-2.2-24.9c-3.7-20-11.9-38-24.8-54.2c-3.3-4.2-6.9-8.2-10.8-12.1C417.9,27.5,406.7,19.3,394.7,13.2 M279.2,50.7
        h35.1c13,0,25.2,2.2,36.4,6.5L57.2,350.8c-4.3-11.3-6.5-23.4-6.5-36.5v-35.1L279.2,50.7 M151.7,50.7h44.6L50.7,196.2v-44.6
        c0-27.9,9.8-51.7,29.5-71.4C100,60.5,123.8,50.7,151.7,50.7 M152.2,415.8l-0.5-0.5c-22.3,0-42-6.3-59.1-19L396.3,92.5
        c12.6,17.1,19,36.8,19,59.1l0.5,0.5L152.2,415.8 M385.7,385.8c-19.7,19.7-43.4,29.5-71.3,29.5h-75.7l176.7-176.7v75.7
        C415.3,342.2,405.4,366,385.7,385.8z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span className="text">Background</span>
          </li>
        );
      } else {
        return (
          <li
            className={`list clickable ${
              data.menuSelected === "main" || data.menuSelected === "design"
                ? `is-active`
                : ``
            }`}
            title="Basic Edits"
            onClick={_menuSelected}
          >
            <span className="icon">
              <svg viewBox="0 0 979.9 980">
                <path
                  className="a"
                  d="M173.3,336.7C83.5,336.7,10,263.2,10,173.3S83.5,10,173.3,10,336.6,83.5,336.6,173.3,263.2,336.7,173.3,336.7Zm0-245c-44.9,0-81.7,36.8-81.7,81.7a81.92,81.92,0,0,0,81.7,81.7c44.9,0,81.7-36.8,81.7-81.7A81.86,81.86,0,0,0,173.3,91.7Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M663.3,663.3C573.5,663.3,500,589.8,500,500s73.5-163.3,163.3-163.3S826.6,410.2,826.6,500,753.2,663.3,663.3,663.3Zm0-245A81.7,81.7,0,1,0,745,500,81.92,81.92,0,0,0,663.3,418.3Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M949.2,214.2H295.8c-24.5,0-40.8-16.3-40.8-40.8s16.3-40.8,40.8-40.8H949.1c24.5,0,40.8,16.3,40.8,40.8S973.7,214.2,949.2,214.2Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M949.2,540.8H785.8c-24.5,0-40.8-16.3-40.8-40.8s16.3-40.8,40.8-40.8H949.1c24.5,0,40.8,16.3,40.8,40.8S973.7,540.8,949.2,540.8Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M540.8,540.8H50.8C26.3,540.8,10,524.5,10,500s16.3-40.8,40.8-40.8h490c24.5,0,40.8,16.3,40.8,40.8S565.3,540.8,540.8,540.8Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M418.3,990C328.5,990,255,916.5,255,826.7s73.5-163.3,163.3-163.3,163.3,73.5,163.3,163.3S508.2,990,418.3,990Zm0-245c-44.9,0-81.7,36.8-81.7,81.7s36.8,81.7,81.7,81.7S500,871.6,500,826.7,463.2,745,418.3,745Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M949.2,867.5H540.8c-24.5,0-40.8-16.3-40.8-40.8s16.3-40.8,40.8-40.8H949.1c24.5,0,40.8,16.3,40.8,40.8S973.7,867.5,949.2,867.5Z"
                  transform="translate(-10 -10)"
                />
                <path
                  className="a"
                  d="M295.8,867.5H50.8c-24.5,0-40.8-16.3-40.8-40.8s16.3-40.8,40.8-40.8h245c24.5,0,40.8,16.3,40.8,40.8S320.3,867.5,295.8,867.5Z"
                  transform="translate(-10 -10)"
                />
              </svg>
            </span>
            <span className="text">Basic Edits</span>
          </li>
        );
      }
    } else {
      return (
        <li
          className={`list clickable ${
            data.menuSelected === "collage" ? `is-active` : ``
          }`}
          onClick={_collageSelected}
          title="Collage"
        >
          <span className="icon">
            <svg viewBox="0 0 20.59 20.61">
              <path
                className="a"
                d="M176.59,14.32a0.6,0.6,0,0,0-.68-0.68h-8.15a0.6,0.6,0,0,0-.68.68v8.15a0.6,0.6,0,0,0,.68.68h8.15a0.6,0.6,0,0,0,.68-0.68V14.32Z"
                transform="translate(-156 -13.64)"
              />
              <path
                className="a"
                d="M176.59,25.42a0.6,0.6,0,0,0-.68-0.68h-8.15a0.6,0.6,0,0,0-.68.68v8.15a0.6,0.6,0,0,0,.68.68h8.15a0.6,0.6,0,0,0,.68-0.68V25.42Z"
                transform="translate(-156 -13.64)"
              />
              <path
                className="a"
                d="M165.55,33.4V14.3a0.6,0.6,0,0,0-.7-0.65H156.7a0.6,0.6,0,0,0-.7.65V33.55a0.6,0.6,0,0,0,.7.65h8.15a0.6,0.6,0,0,0,.7-0.65V33.4Z"
                transform="translate(-156 -13.64)"
              />
            </svg>
          </span>
          <span className="text">Collage</span>
        </li>
      );
    }
  }, [data.editorType, data.menuSelected]);

  useEffect(() => {
    if (
      data.menuSelected === "main" ||
      data.menuSelected === "collage" ||
      data.menuSelected === "design"
    ) {
      window.editor.showOutline(true);
    } else {
      window.editor.showOutline(false);
    }
  }, [data.menuSelected]);

  return (
    <div className="leftmenu pro menu-bar">
      <aside className="left-sidebar">
        <div className="logo-div">
          {EditorStore.isElectron() ? (
            <img
              className="logo-text"
              src="https://static.pizap.com/html5/resources/editor/pizap_logo.svg"
              alt="piZap editor collage maker"
            />
          ) : (
            <a nohref="true" onClick={goHome} className="clickable">
              <svg viewBox="0 0 960 560" transform="rotate(90)">
                <path
                  className="a"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </svg>
              <div
                style={{
                  marginBottom: "13px",
                }}
              >
                Home
              </div>
            </a>
          )}
        </div>
        <span className="colorBar">
          <div className="orange" />
          <div className="yellow" />
          <div className="green" />
          <div className="lightBlue" />
          <div className="blue" />
        </span>
        <div className="side-navigation">
          <ul>
            {mainMenu}
            <li
              className={`list clickable ${
                data.menuSelected === "text" ||
                data.menuSelected === "meme" ||
                data.menuSelected === "speech"
                  ? "is-active"
                  : ""
              }`}
              title="Text"
              onClick={_textSelected}
            >
              <span className="icon">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 585.6 586.7"
                  enableBackground="new 0 0 585.6 586.7"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M585.6,30.2c0.2-17.8-9.8-27.9-30.2-30.2H30.2C9.8,2.3-0.2,12.3,0,30.2v123.8c0,0.3,0,0.5,0,0.8
          c0.4,8.6,3.7,16.1,9.9,22.2c6.6,6.6,14.6,9.9,23.9,9.9c9.3,0,17.3-3.3,23.9-9.9c6.2-6.2,9.5-13.6,9.8-22.2c0-0.3,0-0.5,0-0.8
          V72.7h184.8v446.5h-39.7c-0.3,0-0.5,0-0.8,0c-8.6,0.3-16,3.6-22.2,9.8c-6.6,6.6-9.9,14.6-9.9,23.9c0,9.3,3.3,17.3,9.9,23.8
          c6.2,6.2,13.6,9.5,22.2,9.9c0.3,0,0.5,0,0.8,0H373c0.3,0,0.5,0,0.8,0c8.6-0.4,16.1-3.7,22.2-9.9c6.6-6.6,9.9-14.5,9.9-23.8
          c0-9.3-3.3-17.3-9.9-23.9c-6.2-6.2-13.6-9.5-22.2-9.8c-0.3,0-0.5,0-0.8,0h-39.7V72.7h184.8v81.2c0,0.3,0,0.5,0,0.8
          c0.4,8.6,3.7,16.1,9.9,22.2c6.6,6.6,14.5,9.9,23.8,9.9c9.3,0,17.3-3.3,23.9-9.9c6.2-6.2,9.5-13.6,9.8-22.2c0-0.3,0.1-0.5,0.1-0.8
          V30.2z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text">Text</span>
            </li>
            <li
              className={`list clickable ${
                data.menuSelected === "sticker" ? "is-active" : ""
              }`}
              onClick={_stickerSelected}
              title="Stickers"
            >
              <span className="icon">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 382.2 324.8"
                  enableBackground="new 0 0 382.2 324.8"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M191.6,52.3l-1.3,0.1c-39.9-55.7-87-67-141.4-33.9C0.6,57-11.9,104.9,11.4,162.3
				c31.3,60.2,87,112.9,167.2,158.2c3.5,2.7,7.2,4.1,11.1,4.2c0.7,0,1.4,0,2.1-0.1c0.2,0,0.3,0,0.5,0c3.8-0.1,7.5-1.5,11-4.2
				c20.7-11.7,39.8-23.9,57.2-36.6l15.5-8l49.8,24.7c13.6,4.6,20.5-0.3,20.6-14.7l-2-14.2l-5.4-38.8l37.8-39.6
				c8.2-11.8,5.4-19.7-8.3-23.8l-1.7-0.2c1.3-2.3,2.5-4.6,3.8-6.9c23.3-57.4,11.4-105-35.6-142.8C279.3-14.4,231.5-3.4,191.6,52.3
				 M276.6,148.5l0.3,0.2c8.2,17.1,13.6,27.6,16.1,31.5c0.6,0.9,1.1,1.5,1.3,1.6c3.6,4.6,10,7,19.1,7.4l25.8,4.8l-21.8,24.4
				c-2.4,3.3-3.9,7.4-4.4,12.2c-0.5,4.9-0.7,8.6-0.5,11l2.5,22.9l-29.6-15.4c-2.3-0.3-5.7-0.3-10.2,0c-6.4,0.5-11.1,1.7-14,3.6
				l-23.8,11.3v-0.2l4-24.1c0.5-3.5,0.6-6.8,0.2-9.8c-0.4-4.3-1.7-8.1-3.8-11.5c-3.5-5.6-5.9-8.6-7.1-9l-17.2-17.1l34-4.8
				c5.4-1.3,8.8-3.1,10.2-5.4c1.4-2.3,2.8-4.8,4.2-7.5L276.6,148.5 M203.6,110.9c-0.8-14.5,1.7-27.9,7.5-40.4
				c24.7-41.9,58.7-52.7,102.1-32.3C355,64.1,366.7,99.3,348.4,144c-2.7,7.1-6,14.1-10,21.2l-23.7-3.2l-26.3-49.1
				c-8.4-11.6-16.8-11.6-25.2,0l-25.8,47.3l-54.6,10.5c-13.6,4.6-16.1,12.6-7.5,24.1l38.9,37.8l-6.9,53.6
				c-5.4,3.3-10.9,6.6-16.5,9.9C105.7,246.2,53.3,195.6,33.5,144.1C15.2,99.5,28,63.2,71.8,35.3c41.4-18.4,74.4-6.6,99,35.3
				c5.8,12.5,8.6,25.6,8.5,39.4c2.8,4.5,6.3,6.9,10.3,7.3c0.8,0.1,1.5,0.1,2.3,0c0.1,0,0.2,0,0.3,0
				C196.3,116.8,200.1,114.7,203.6,110.9z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text">Stickers</span>
            </li>
            {data.editorType !== "collage" && (
              <li
                className={`list clickable ${
                  data.menuSelected === "photos" ? "is-active" : ""
                }`}
                onClick={photosSelected}
                title="Photos"
              >
                <span className="icon">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 636.9 578"
                    enableBackground="new 0 0 636.9 578"
                  >
                    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                      <g>
                        <g id="Layer0_0_FILL">
                          <path
                            fill="#FFFFFF"
                            d="M17.3,468.6c9.2,19.4,21.9,37.3,38.4,53.7C92.8,559.4,137.5,578,190,578h256.9c52.5,0,97.3-18.5,134.4-55.7
				c2.7-2.7,5.3-5.4,7.8-8.2L453.6,382c-6.6-6.1-13.2-6.3-19.8-0.5l-78,74.8l-13.5,12.3l-7-7c-1.7-1.1-3.3-2.6-4.7-4.7L182.4,276.1
				L17.3,468.6 M581.3,55.7C544.2,18.6,499.4,0,446.9,0H190C137.5,0,92.8,18.6,55.7,55.7C18.5,92.7,0,137.5,0,190v190.1l132.8-155.1
				c36.7-39,71-40,102.9-2.9l118.2,147L399,324c7.3-10,14.8-17.5,22.5-22.6l25.4-25.4l33,32.9c5,4.2,10,9.2,15,15.1l130.6,130.5
				c7.6-20.6,11.4-42.8,11.4-66.5V190C636.9,137.5,618.4,92.7,581.3,55.7 M447,145.9c14.4,0,26.6,5.1,36.8,15.2
				c10.2,10.2,15.2,22.4,15.2,36.8c0,14.4-5.1,26.6-15.2,36.8c-10.1,10.2-22.4,15.2-36.8,15.2s-26.6-5.1-36.8-15.2
				c-10.1-10.1-15.2-22.4-15.2-36.8c0-14.4,5.1-26.6,15.2-36.8C420.4,151,432.6,145.9,447,145.9z"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span className="text">Add Pic</span>
              </li>
            )}
            <li
              className={`list clickable ${
                data.menuSelected === "effect" ? "is-active" : ""
              }`}
              onClick={_effectSelected}
              title="Filters"
            >
              <span className="icon">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 800 895.8"
                  enableBackground="new 0 0 800 895.8"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M423.4,80.9l62.5,18.6l19.9,65.5l18.4-64.1l63.4-19.7l-63.1-19.2L505,0l-18.9,62.7L423.4,80.9 M126.6,168.5
				l79.6-24.7L127,119.6l-24.5-77.8l-23.8,78.7L0,143.4l78.5,23.4l25,82.3L126.6,168.5 M696.2,421.8l-23.8,78.8l-78.8,22.8
				l78.6,23.5l25,82.4l23.1-80.6l79.7-24.8l-79.3-24.2L696.2,421.8 M527.8,458.8l-59.7-59.6C321,544.8,176,690.9,33,837.5
				c0,0-0.1,0.1-0.2,0.2c-5,5.4-5,12.1,0.2,20l33.3,34.2c8.7,5.2,17.4,5.2,26.2,0C237.6,748.9,382.7,604.5,527.8,458.8 M689.6,202.6
				c-7-4.9-14-5-21-0.4C612,257.3,555.8,312.5,499.8,367.8l59.5,59.5c54.6-54.9,109.1-109.9,163.6-165.2c7.1-8.7,7.1-17.4,0-26.1
				L689.6,202.6 M375.3,254.1l-39.2-124.5l-38.1,126l-126,36.5l125.7,37.6l40,131.8l37-129L502.2,293L375.3,254.1z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text">Filters</span>
            </li>
            <li
              className={`list clickable ${
                data.menuSelected === "border" ? "is-active" : ""
              }`}
              onClick={_borderSelected}
              title="Borders"
            >
              <span className="icon">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 633.9 634"
                  enableBackground="new 0 0 633.9 634"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M457.9,37.1c-2.8-7.4-7.3-14.1-13.3-20.1C433.3,5.6,419.6,0,403.6,0s-29.7,5.6-41,16.9
				c-6,6-10.5,12.8-13.3,20.1h-63.7c-2.8-7.4-7.3-14.1-13.3-20.1C261,5.6,247.3,0,231.3,0s-29.7,5.6-41,16.9
				c-6,6-10.5,12.8-13.3,20.1h-63.7C110.5,29.7,106,23,100,16.9C88.7,5.6,75,0,59,0S29.3,5.6,18,16.9C6.6,28.3,1,42,1,58
				c0,16,5.6,29.7,17,41c4.9,4.9,10.3,8.8,16.1,11.6v67.5c-5.8,2.8-11.2,6.6-16.1,11.5c-11.3,11.3-17,25-17,41.1
				c0,16,5.6,29.7,17,41c4.9,4.9,10.3,8.8,16.1,11.5v67.5c-5.8,2.8-11.2,6.6-16.1,11.5c-11.3,11.3-17,25-17,41s5.6,29.7,17,41
				c4.9,4.9,10.3,8.8,16.1,11.5v67c-6.2,2.8-11.9,6.8-17.1,12C5.6,546.2,0,559.9,0,576s5.6,29.7,17,41c11.3,11.3,25,17,41,17
				c16,0,29.7-5.7,41-17c4.3-4.3,7.9-9,10.5-14.1h69.2c2.7,5,6.2,9.7,10.5,14.1c11.3,11.3,25,17,41,17c16,0,29.7-5.7,41-17
				c4.3-4.3,7.9-9,10.5-14.1H351c2.7,5,6.2,9.7,10.5,14.1c11.3,11.3,25,17,41,17c16,0,29.7-5.7,41-17c4.3-4.3,7.9-9,10.6-14.1h69.2
				c2.7,5,6.2,9.7,10.5,14.1c11.3,11.3,25,17,41.1,17c16,0,29.7-5.7,41-17c11.3-11.3,17-25,17-41s-5.7-29.7-17-41.1
				c-4.9-4.9-10.2-8.7-16-11.5v-67.1c6.2-2.8,11.8-6.8,17-12c11.3-11.3,17-25,17-41s-5.7-29.7-17-41c-5.2-5.2-10.8-9.2-17-12v-66.6
				c6.2-2.8,11.8-6.8,17-12c11.3-11.3,17-25,17-41c0-16-5.7-29.7-17-41.1c-5.2-5.2-10.8-9.2-17-11.9V111c6.2-2.8,11.8-6.8,17-12
				c11.3-11.3,17-25,17-41c0-16-5.7-29.7-17-41.1C605.6,5.6,591.9,0,575.9,0c-16,0-29.7,5.6-41.1,16.9c-6,6-10.4,12.8-13.2,20.1
				H457.9 M557.9,79v482H76V79H557.9z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text">Borders</span>
            </li>
            <li
              className={`list clickable ${
                data.menuSelected === "cutout" ? "is-active" : ""
              }`}
              onClick={_cutoutSelected}
              title="Cutouts"
            >
              <span className="icon">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 475.6 459"
                  enableBackground="new 0 0 475.6 459"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M38.2,371c-0.3-1.6-0.7-3.2-1.3-5c-1.2-3.5-2.5-6-3.9-7.4c-3.4-3.4-7.6-5.2-12.4-5.2s-9,1.7-12.4,5.2
				c-2.3,2.3-4,7.1-5,14.4v33.8c0,0.5,0,1.1,0,1.6h0.2C4.4,421,8.6,432,16.2,441.2h0c9.8,12.1,21.7,18,35.7,17.8h374.8
				c14,0.3,25.9-5.7,35.8-17.8c7.6-9.2,11.9-20.2,12.9-32.8h0.2c0-0.5,0-1,0-1.6V211.5c0-14.9-4.4-27.7-13.1-38.4h-0.1
				c-9.8-12.1-21.7-18-35.7-17.8h-72.2l0,0c-6.6,1.1-11.1,2.8-13.4,5.1c-3.4,3.4-5.2,7.6-5.2,12.4c0,0.7,0,1.3,0.1,2
				c0.4,4,2.1,7.4,5,10.4c3.4,3.4,7.6,5.2,12.4,5.2l73.2-0.1c3.3,0.2,6.1,1.8,8.6,4.8v0.1c3.5,4.5,5.3,9.9,5.3,16.2v193
				c-0.3,5.7-2.1,10.6-5.2,14.7c-2.4,3-5.3,4.6-8.6,4.9H52c-3.3-0.2-6.1-1.8-8.5-4.8v-0.1c-3.2-4.1-4.9-9-5.2-14.7h0V371 M69.5,0
				C50.6,0,34.5,6.7,21,20C7.7,33.4,1,49.6,1,68.5s6.7,35,20,48.4c4.1,4.1,11.8,8.6,22.9,13.4c9.3,3.9,15.1,6,17.5,6.2l77.9,34.1
				L42,211.5c-8,3.4-15.3,8.4-22,15C6.7,239.9,0,256,0,274.9c0,18.9,6.7,35,20,48.4c13.4,13.4,29.6,20.1,48.5,20.1s35-6.7,48.4-20.1
				c13.4-13.4,20.1-29.5,20.1-48.4c0-18.9-6.7-35-20.1-48.5c-2.6-2.6-5.4-5-8.2-7.2l-1.2-0.8l70.5-30.2l156,71c0.1,0,0.1,0,0.2,0
				c4.5,0.2,8.5-1.2,11.8-4.2c1.7-1.5,2.9-3.1,3.8-5c0.9-1.9,1.4-4.1,1.5-6.4c0.1-2.3-0.2-4.5-1-6.5c-0.7-1.9-1.8-3.7-3.3-5.4
				c-0.5-0.5-1.7-1.4-3.6-2.6c-2.1-1.3-3.6-2.1-4.6-2.2L217,171.5l132-56.7c0.1-0.1,0.1-0.1,0.1-0.1c3.2-3.2,4.8-7,4.8-11.5
				c0-2.2-0.4-4.3-1.1-6.1c-0.8-2-2.1-3.8-3.7-5.5s-3.4-2.9-5.4-3.6c-1.9-0.8-4-1.1-6.2-1.1c-1.2,0-2.4,0.1-3.5,0.3l-155.7,66.9
				l-67.5-30.7c2.5-1.9,4.9-4,7.2-6.3c13.4-13.4,20.1-29.5,20.1-48.4s-6.7-35.1-20.1-48.5C104.5,6.7,88.4,0,69.5,0 M37,70
				c0-9.4,3.3-17.4,10-24c6.6-6.6,14.7-10,24-10s17.4,3.3,24,10c6.6,6.6,10,14.6,10,24s-3.3,17.4-10,24c-6.6,6.6-14.7,10-24,10
				l-0.5,0.9l-2.2-1C60.1,103.3,52.9,100,47,94C40.3,87.4,37,79.4,37,70 M36,276.4c0-9.4,3.3-17.4,10-24.1c6.6-6.6,14.7-9.9,24-9.9
				s17.4,3.3,24,9.9c6.6,6.6,10,14.7,10,24.1c0,9.4-3.3,17.4-10,24c-6.6,6.6-14.7,10-24,10s-17.4-3.3-24-10
				C39.3,293.8,36,285.8,36,276.4z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text">Cutouts</span>
            </li>
            {data.editorType === "design" && (
              <li
                className={`list clickable`}
                onClick={_resizeSelected}
                title="Resize"
              >
                <span className="icon">
                  <IconResize />
                </span>
                <span className="text">Resize</span>
              </li>
            )}
            <li
              className={`list clickable ${
                data.menuSelected === "paint" ? "is-active" : ""
              }`}
              onClick={_paintSelected}
              title="Draw"
            >
              <span className="icon">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 671.5 677.4"
                  enableBackground="new 0 0 671.5 677.4"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer1_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M659.3,46.8L620.5,9.7c-16.4-12.9-34.2-12.9-53.2,0L360,217l91.3,90.6l208-207.9
				C675.5,82.1,675.5,64.4,659.3,46.8 M301.3,280.6l-23,110.4l111.8-22L301.3,280.6z"
                        />
                      </g>
                    </g>
                  </g>
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M153.4,472c-1.1-0.5-2.1-1.1-3-1.7c-1.5-1.1-2.8-2.3-4.1-3.5c-1.1-1-2.1-2.1-3-3.2c-0.1-7.9,6.9-15.2,21-22
				c14.1-6.7,27.6-11.9,40.5-15.4c12.9-3.5,11.4-9.9-4.5-19.3c-15.9-9.4-42.5-1.5-79.7,23.5c-37.2,25-38.8,47.5-4.8,67.6
				c34.1,20.1,63.1,32,87,35.8c34.6,5.5,70.6,12.1,108.2,19.8c12.4,6.1,14.2,14.6,5.2,25.6c-11.3,8.2-34.7,15.6-70.4,22.4
				c-35.7,6.8-72.2,5.6-109.5-3.5c-37.3-9.1-65.3-19.6-84.1-31.6c-18.7-12-34.4-9.9-46.8,6.4c-12.5,16.2-2.9,35.3,28.9,57.1
				c31.8,21.8,67.6,35.5,107.6,41c35.2,6.2,73.1,7.8,113.7,4.9c39.3-2.1,80.3-16.5,122.9-43.2c42.6-26.7,50.2-53.6,22.8-80.7
				c-6.8-6.7-14.5-12.9-23-18.4c-25.7-16.6-59.2-27.7-100.5-33.2c-13.2-1.1-26.2-2.8-39.2-5c-25.5-4.3-50.6-10.8-75.2-19.3
				c-1.6-0.7-3.2-1.4-4.8-2.1C156.6,473.5,155,472.8,153.4,472z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span className="text">Draw</span>
            </li>
            {data.menuSelected !== "edit_graphic_svg" &&
              data.menuSelected !== "text_props" &&
              data.menuSelected !== "edit_graphic" && (
                <div className="indicator"></div>
              )}
          </ul>
        </div>
      </aside>
      {editPanel}
    </div>
  );
};

export default LeftMenu;
