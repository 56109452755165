import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import LeftMenuStore from "../stores/LeftMenuStore";

export default {
  displayAds: function () {
    const showAds = UserStore.showAds();

    if (showAds) {
      if (EditorStore.isElectron()) {
      } else {
        window.googletag.cmd.push(function () {
          var slot2 = window.googletag.defineSlot(
            "/10683603/pizap.com_editor_160x600_af_2",
            [160, 600],
            "right-ad"
          );
          //                    var slot3 = window.googletag.defineSlot('/10683603/pizap.com_editor_728x90_af', [728, 90], 'bottom-ad');
          slot2.addService(window.googletag.pubads());
          //                    slot3.addService(window.googletag.pubads());
          window.googletag.enableServices();
          window.onload = function () {
            setInterval(function () {
              if (
                EditorStore.getEditorMode() !== "cutout" &&
                EditorStore.getEditorMode() !== "paint" &&
                LeftMenuStore.get() !== "cutout"
              ) {
                window.googletag.pubads().refresh([slot2]);
              }
            }, 60000);
          };
        });
        //                window.googletag.cmd.push(function () {
        //                    window.googletag.display('bottom-ad');
        //                });
        window.googletag.cmd.push(function () {
          window.googletag.display("right-ad");
        });
      }
    }
  },
  setupCursors: function (rend) {
    rend.plugins.interaction.cursorStyles["nw-resize"] = "nw-resize";
    rend.plugins.interaction.cursorStyles["ne-resize"] = "ne-resize";
    rend.plugins.interaction.cursorStyles["sw-resize"] = "sw-resize";
    rend.plugins.interaction.cursorStyles["se-resize"] = "se-resize";
    rend.plugins.interaction.cursorStyles["n-resize"] = "n-resize";
    rend.plugins.interaction.cursorStyles["s-resize"] = "s-resize";
    rend.plugins.interaction.cursorStyles["e-resize"] = "e-resize";
    rend.plugins.interaction.cursorStyles["w-resize"] = "w-resize";
    rend.plugins.interaction.cursorStyles["crosshair"] = "crosshair";
    rend.plugins.interaction.cursorStyles["move"] = "move";
    rend.plugins.interaction.cursorStyles["alias"] = "alias";
    rend.plugins.interaction.cursorStyles["color"] =
      "url(./images/editor/color-dropper-icon.png) 1 23, auto;";
  },
  getBase64Image: function (img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
  },
  rgb2hex: function (rgb) {
    rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);

    function hex(x) {
      return ("0" + parseInt(x, 10).toString(16)).slice(-2);
    }

    return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
  },
  scaleSave: function (maxWidth, maxHeight, imgWidth, imgHeight) {
    var scale_width = maxWidth / imgWidth;
    var scale_height = maxHeight / imgHeight;

    var scale = Math.min(scale_width, scale_height);
    if (scale > 1) {
      scale = 1;
    }
    var width = imgWidth * scale; // 608
    var height = imgHeight * scale; // 550

    return { width: width, height: height };
  },
  scaleToFit: function (maxWidth, maxHeight, imgWidth, imgHeight) {
    var scale_width = maxWidth / imgWidth;
    var scale_height = maxHeight / imgHeight;

    var scale = Math.min(scale_width, scale_height);

    var width = imgWidth * scale; // 608
    var height = imgHeight * scale; // 550

    var x = (maxWidth - width) / 2; // 52
    var y = (maxHeight - height) / 2;
    return { x: x, y: y, width: Math.ceil(width), height: Math.ceil(height) };
  },
  scaleToFill: function (maxWidth, maxHeight, imgWidth, imgHeight) {
    var scale_width = maxWidth / imgWidth;
    var scale_height = maxHeight / imgHeight;

    var scale = Math.max(scale_width, scale_height);
    return scale;
  },
};
