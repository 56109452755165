import * as PIXI from "pixi.js";

let cropContainer;
let cropObject;
let scaleMode = "";
let fixedAspect = false;

function calculateGetScaledHeight(width, height, newWidth) {
  return (height * newWidth) / width;
}

const CropSelector = {
  sizeText: new PIXI.Text("", {
    fill: 0xffffff,
    dropShadow: true,
    fontSize: 12,
    dropShadowDistance: 2,
  }),
  leftTop: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  rightTop: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  LeftBottom: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  RightBottom: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  MiddleTop: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  MiddleBottom: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  MiddleLeft: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  MiddleRight: PIXI.Sprite.fromImage("images/editor/scale-circle.svg"),
  border: new PIXI.Graphics(),
  originalX: 0,
  originalY: 0,
  getCropContainer: function () {
    return cropContainer;
  },
  setCropObject: function (object) {
    cropObject = object;
    this.updateSelector();
  },
  getCropObject: function () {
    return cropObject;
  },
  setFixedAspect: function (value) {
    fixedAspect = value;
  },
  getFixedAspect: function () {
    return fixedAspect;
  },
  init: function () {
    cropContainer = new PIXI.Container();
    cropContainer.addChild(this.border);
    cropContainer.addChild(this.sizeText);
    this.leftTop.anchor.set(0.5);
    this.leftTop.scale.set(0.5 / window.editor.getScale());
    this.leftTop.interactive = true;
    this.leftTop.buttonMode = true;
    this.leftTop.cursor = "nw-resize";
    this.leftTop.location = "leftTop";
    cropContainer.addChild(this.leftTop);
    this.leftTop
      .on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.rightTop.anchor.set(0.5);
    this.rightTop.scale.set(0.5 / window.editor.getScale());
    this.rightTop.interactive = true;
    this.rightTop.buttonMode = true;
    this.rightTop.renderable = false;
    this.rightTop.cursor = "ne-resize";
    this.rightTop.location = "rightTop";
    cropContainer.addChild(this.rightTop);
    this.rightTop
      .on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.LeftBottom.anchor.set(0.5);
    this.LeftBottom.scale.set(0.5 / window.editor.getScale());
    this.LeftBottom.interactive = true;
    this.LeftBottom.buttonMode = true;
    this.LeftBottom.renderable = false;
    this.LeftBottom.cursor = "sw-resize";
    this.LeftBottom.location = "LeftBottom";
    cropContainer.addChild(this.LeftBottom);
    this.LeftBottom.on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.RightBottom.anchor.set(0.5);
    this.RightBottom.scale.set(0.5 / window.editor.getScale());
    this.RightBottom.interactive = true;
    this.RightBottom.buttonMode = true;
    this.RightBottom.renderable = false;
    this.RightBottom.cursor = "se-resize";
    this.RightBottom.location = "RightBottom";
    cropContainer.addChild(this.RightBottom);
    this.RightBottom.on("mousedown", this.onScaleStart)
      .on("touchstart", this.onScaleStart)
      .on("mouseup", this.onScaleEnd)
      .on("mouseupoutside", this.onScaleEnd)
      .on("touchend", this.onScaleEnd)
      .on("touchendoutside", this.onScaleEnd)
      .on("mousemove", this.onScaleMove)
      .on("touchmove", this.onScaleMove);

    this.MiddleTop.anchor.set(0.5);
    this.MiddleTop.scale.set(0.5 / window.editor.getScale());
    this.MiddleTop.interactive = true;
    this.MiddleTop.buttonMode = true;
    this.MiddleTop.renderable = false;
    this.MiddleTop.cursor = "n-resize";
    cropContainer.addChild(this.MiddleTop);
    this.MiddleTop.on("mousedown", this.onHeightTopStart)
      .on("touchstart", this.onHeightTopStart)
      .on("mouseup", this.onHeightTopEnd)
      .on("mouseupoutside", this.onHeightTopEnd)
      .on("touchend", this.onHeightTopEnd)
      .on("touchendoutside", this.onHeightTopEnd)
      .on("mousemove", this.onHeightTopMove)
      .on("touchmove", this.onHeightTopMove);

    this.MiddleBottom.anchor.set(0.5);
    this.MiddleBottom.scale.set(0.5 / window.editor.getScale());
    this.MiddleBottom.interactive = true;
    this.MiddleBottom.buttonMode = true;
    this.MiddleBottom.renderable = false;
    this.MiddleBottom.cursor = "s-resize";
    cropContainer.addChild(this.MiddleBottom);
    this.MiddleBottom.on("mousedown", this.onHeightBottomStart)
      .on("touchstart", this.onHeightBottomStart)
      .on("mouseup", this.onHeightBottomEnd)
      .on("mouseupoutside", this.onHeightBottomEnd)
      .on("touchend", this.onHeightBottomEnd)
      .on("touchendoutside", this.onHeightBottomEnd)
      .on("mousemove", this.onHeightBottomMove)
      .on("touchmove", this.onHeightBottomMove);

    this.MiddleLeft.anchor.set(0.5);
    this.MiddleLeft.scale.set(0.5 / window.editor.getScale());
    this.MiddleLeft.interactive = true;
    this.MiddleLeft.buttonMode = true;
    this.MiddleLeft.renderable = false;
    this.MiddleLeft.cursor = "w-resize";
    cropContainer.addChild(this.MiddleLeft);
    this.MiddleLeft.on("mousedown", this.onWidthLeftStart)
      .on("touchstart", this.onWidthLeftStart)
      .on("mouseup", this.onWidthLeftEnd)
      .on("mouseupoutside", this.onWidthLeftEnd)
      .on("touchend", this.onWidthLeftEnd)
      .on("touchendoutside", this.onWidthLeftEnd)
      .on("mousemove", this.onWidthLeftMove)
      .on("touchmove", this.onWidthLeftMove);

    this.MiddleRight.anchor.set(0.5);
    this.MiddleRight.scale.set(0.5 / window.editor.getScale());
    this.MiddleRight.interactive = true;
    this.MiddleRight.buttonMode = true;
    this.MiddleRight.renderable = false;
    this.MiddleRight.cursor = "e-resize";
    cropContainer.addChild(this.MiddleRight);
    this.MiddleRight.on("mousedown", this.onWidthRightStart)
      .on("touchstart", this.onWidthRightStart)
      .on("mouseup", this.onWidthRightEnd)
      .on("mouseupoutside", this.onWidthRightEnd)
      .on("touchend", this.onWidthRightEnd)
      .on("touchendoutside", this.onWidthRightEnd)
      .on("mousemove", this.onWidthRightMove)
      .on("touchmove", this.onWidthRightMove);
  },
  updateSelector: function () {
    if (cropObject && cropContainer) {
      cropContainer.x = cropObject.x;
      cropContainer.y = cropObject.y;
      var width = cropObject.width;
      var height = cropObject.height;
      var handleSize = 30;
      this.border.clear();

      this.sizeText.position.set(5 - width / 2, 5 - height / 2);
      this.sizeText.text =
        Math.floor(width / window.editor.getBaseObject().scale.x) +
        "x" +
        Math.floor(height / window.editor.getBaseObject().scale.x);

      this.border.lineStyle(1 / window.editor.getStage().scale.x, 0x000000, 1);
      this.border.drawRect(
        0 - width / 2 - 1,
        0 - height / 2 - 1,
        width + 2,
        height + 2
      );
      this.border.lineStyle(1 / window.editor.getStage().scale.x, 0xffffff, 1);
      this.border.drawRect(
        0 - width / 2,
        0 - height / 2,
        width - 1,
        height - 1
      );
      this.border.lineStyle(
        1 / window.editor.getStage().scale.x,
        0xffffff,
        0.5
      );
      this.border.moveTo(0 - (width / 2 - width / 3), 0 - height / 2);
      this.border.lineTo(0 - (width / 2 - width / 3), height / 2);
      this.border.moveTo(width / 2 - width / 3, 0 - height / 2);
      this.border.lineTo(width / 2 - width / 3, height / 2);

      this.border.moveTo(0 - width / 2, 0 - (height / 2 - height / 3));
      this.border.lineTo(width / 2, 0 - (height / 2 - height / 3));
      this.border.moveTo(0 - width / 2, height / 2 - height / 3);
      this.border.lineTo(width / 2, height / 2 - height / 3);

      this.border.lineStyle(1 / window.editor.getStage().scale.x, 0xb1b1b1, 1);
      this.border.beginFill(0xb1b1b1, 0.75);
      //top left
      this.border.drawRect(
        0 - width / 2 - 8,
        0 - height / 2 - 8,
        width / handleSize,
        3 / window.editor.getStage().scale.x
      );
      this.border.drawRect(
        0 - width / 2 - 8,
        0 - height / 2 - 8,
        3 / window.editor.getStage().scale.x,
        width / handleSize
      );

      //bottom left
      this.border.drawRect(
        0 - width / 2 - 8,
        height / 2 - 3 / window.editor.getStage().scale.x + 8,
        width / handleSize,
        3 / window.editor.getStage().scale.x
      );
      this.border.drawRect(
        0 - width / 2 - 8,
        height / 2 - width / handleSize + 8,
        3 / window.editor.getStage().scale.x,
        width / handleSize
      );

      //top right
      this.border.drawRect(
        width / 2 - width / handleSize + 8,
        0 - height / 2 - 8,
        width / handleSize,
        3 / window.editor.getStage().scale.x
      );
      this.border.drawRect(
        width / 2 - 3 / window.editor.getStage().scale.x + 8,
        0 - height / 2 - 8,
        3 / window.editor.getStage().scale.x,
        width / handleSize
      );

      //bottom right
      this.border.drawRect(
        width / 2 - width / handleSize + 8,
        height / 2 - 3 / window.editor.getStage().scale.x + 8,
        width / handleSize,
        3 / window.editor.getStage().scale.x
      );
      this.border.drawRect(
        width / 2 - 3 / window.editor.getStage().scale.x + 8,
        height / 2 - width / handleSize + 8,
        3 / window.editor.getStage().scale.x,
        width / handleSize
      );
      if (fixedAspect) {
      } else {
        //center top
        this.border.drawRect(
          0 - width / (handleSize * 2),
          0 - height / 2 - 8,
          width / handleSize,
          3 / window.editor.getStage().scale.x
        );

        //center bottom
        this.border.drawRect(
          0 - width / (handleSize * 2),
          height / 2 - 3 / window.editor.getStage().scale.x + 8,
          width / handleSize,
          3 / window.editor.getStage().scale.x
        );

        //center left
        this.border.drawRect(
          0 - width / 2 - 8,
          0 - width / (handleSize * 2),
          3 / window.editor.getStage().scale.x,
          width / handleSize
        );

        //center right
        this.border.drawRect(
          width / 2 - 3 / window.editor.getStage().scale.x + 8,
          0 - width / (handleSize * 2),
          3 / window.editor.getStage().scale.x,
          width / handleSize
        );
      }

      this.border.endFill(0xb1b1b1, 0.75);

      this.leftTop.position.x = 0 - width / 2;
      this.leftTop.position.y = 0 - height / 2;
      this.leftTop.renderable = false;
      this.leftTop.width = width / 10;
      this.leftTop.height = height / 10;

      this.rightTop.position.x = width / 2;
      this.rightTop.position.y = 0 - height / 2;
      this.rightTop.renderable = false;
      this.rightTop.width = width / 10;
      this.rightTop.height = height / 10;

      this.LeftBottom.position.x = 0 - width / 2;
      this.LeftBottom.position.y = height / 2;
      this.LeftBottom.renderable = false;
      this.LeftBottom.width = width / 10;
      this.LeftBottom.height = height / 10;

      this.RightBottom.position.x = width / 2;
      this.RightBottom.position.y = height / 2;
      this.RightBottom.renderable = false;
      this.RightBottom.width = width / 10;
      this.RightBottom.height = height / 10;
      if (fixedAspect) {
        this.MiddleBottom.renderable = false;
        this.MiddleLeft.renderable = false;
        this.MiddleRight.renderable = false;
        this.MiddleTop.renderable = false;
      } else {
        this.MiddleBottom.position.x = 0;
        this.MiddleBottom.position.y = height / 2;
        this.MiddleBottom.renderable = false;
        this.MiddleBottom.width = width / 10;
        this.MiddleBottom.height = height / 10;

        this.MiddleTop.position.x = 0;
        this.MiddleTop.position.y = 0 - height / 2;
        this.MiddleTop.renderable = false;
        this.MiddleTop.width = width / 10;
        this.MiddleTop.height = height / 10;

        this.MiddleLeft.position.x = 0 - width / 2;
        this.MiddleLeft.position.y = 0;
        this.MiddleLeft.renderable = false;
        this.MiddleLeft.width = width / 10;
        this.MiddleLeft.height = height / 10;

        this.MiddleRight.position.x = width / 2;
        this.MiddleRight.position.y = 0;
        this.MiddleRight.renderable = false;
        this.MiddleRight.width = width / 10;
        this.MiddleRight.height = height / 10;
      }
    } else {
      this.leftTop.renderable = false;
      this.rightTop.renderable = false;
      this.LeftBottom.renderable = false;
      this.RightBottom.renderable = false;
      this.MiddleBottom.renderable = false;
      this.MiddleLeft.renderable = false;
      this.MiddleRight.renderable = false;
      this.MiddleTop.renderable = false;
      this.border.clear();
    }
  },
  onScaleStart: function (event) {
    event.stopPropagation();
    cropObject.data = event.data;
    cropObject.scaling = true;
    cropObject.dragging = false;
    window.editor.scaleStartWidth = cropObject.width;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalX = cropObject.width - event.data.global.x - cropObject.x;
    document.addEventListener("mouseup", this.onScaleEnd);
    if (scaleMode !== "fixed") {
      scaleMode = event.currentTarget.location;
    }
  },
  onScaleEnd: function () {
    cropObject.scaling = false;
    cropObject.data = null;
    document.removeEventListener("mouseup", this.onScaleEnd);
  },
  onScaleMove: function (event) {
    if (cropObject && cropObject.scaling) {
      var tempX =
        event.data.global.x / window.editor.getStage().scale.x - cropObject.x;
      var tempY =
        event.data.global.y / window.editor.getStage().scale.y - cropObject.y;

      var rotatedX =
        tempX * Math.cos(cropObject.rotation) +
        tempY * Math.sin(cropObject.rotation);

      var unRotatedX = rotatedX + cropObject.x;

      var newWidth = Math.abs(unRotatedX - cropObject.x) * 2;
      var height = calculateGetScaledHeight(
        cropObject.width,
        cropObject.height,
        newWidth
      );
      if (newWidth < 20 || height < 20) return;

      var orgWidth = cropObject.width;
      var orgHeight = cropObject.height;
      switch (scaleMode) {
        case "leftTop":
          if (cropObject.position.x - newWidth / 2 <= 0) return;
          if (cropObject.position.y - height / 2 <= 0) return;
          if (fixedAspect === true) {
            cropObject.width =
              cropObject.width +
              (cropObject.position.x -
                cropObject.width / 2 -
                event.data.global.x / window.editor.getStage().scale.x);
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x +
              cropObject.width / 2;
            cropObject.height = calculateGetScaledHeight(
              orgWidth,
              orgHeight,
              cropObject.width
            );
            cropObject.position.y += (orgHeight - cropObject.height) / 2;
          } else {
            cropObject.width =
              cropObject.width +
              (cropObject.position.x -
                cropObject.width / 2 -
                event.data.global.x / window.editor.getStage().scale.x);
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x +
              cropObject.width / 2;
            cropObject.height =
              cropObject.height +
              (cropObject.position.y -
                cropObject.height / 2 -
                event.data.global.y / window.editor.getStage().scale.y);
            cropObject.position.y =
              event.data.global.y / window.editor.getStage().scale.y +
              cropObject.height / 2;
          }
          break;
        case "LeftBottom":
          if (cropObject.position.x - newWidth / 2 <= 0) return;
          if (
            cropObject.position.y + height / 2 >=
            window.editor.getBaseObject().height
          )
            return;
          if (fixedAspect === true) {
            cropObject.width =
              cropObject.width +
              (cropObject.position.x -
                cropObject.width / 2 -
                event.data.global.x / window.editor.getStage().scale.x);
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x +
              cropObject.width / 2;
            cropObject.height = calculateGetScaledHeight(
              orgWidth,
              orgHeight,
              cropObject.width
            );
            cropObject.position.y -= (orgHeight - cropObject.height) / 2;
          } else {
            cropObject.width =
              cropObject.width +
              (cropObject.position.x -
                cropObject.width / 2 -
                event.data.global.x / window.editor.getStage().scale.x);
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x +
              cropObject.width / 2;
            cropObject.height =
              cropObject.position.y -
              cropObject.height / 2 -
              event.data.global.y / window.editor.getStage().scale.y;
            cropObject.position.y =
              event.data.global.y / window.editor.getStage().scale.y -
              cropObject.height / 2;
          }
          break;
        case "rightTop":
          if (cropObject.position.y - height / 2 <= 0) return;
          if (
            cropObject.position.x + newWidth / 2 >=
            window.editor.getBaseObject().width
          )
            return;
          if (fixedAspect === true) {
            cropObject.width =
              cropObject.position.x -
              cropObject.width / 2 -
              event.data.global.x / window.editor.getStage().scale.x;
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x -
              cropObject.width / 2;
            cropObject.height = calculateGetScaledHeight(
              orgWidth,
              orgHeight,
              cropObject.width
            );
            cropObject.position.y += (orgHeight - cropObject.height) / 2;
          } else {
            cropObject.width =
              cropObject.position.x -
              cropObject.width / 2 -
              event.data.global.x / window.editor.getStage().scale.x;
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x -
              cropObject.width / 2;
            cropObject.height =
              cropObject.height +
              (cropObject.position.y -
                cropObject.height / 2 -
                event.data.global.y / window.editor.getStage().scale.y);
            cropObject.position.y =
              event.data.global.y / window.editor.getStage().scale.y +
              cropObject.height / 2;
          }
          break;
        case "RightBottom":
          if (
            cropObject.position.y + height / 2 >=
            window.editor.getBaseObject().height
          )
            return;
          if (
            cropObject.position.x + newWidth / 2 >=
            window.editor.getBaseObject().width
          )
            return;
          if (fixedAspect === true) {
            cropObject.width =
              cropObject.position.x -
              cropObject.width / 2 -
              event.data.global.x / window.editor.getStage().scale.x;
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x -
              cropObject.width / 2;
            cropObject.height = calculateGetScaledHeight(
              orgWidth,
              orgHeight,
              cropObject.width
            );
            cropObject.position.y -= (orgHeight - cropObject.height) / 2;
          } else {
            cropObject.width =
              cropObject.position.x -
              cropObject.width / 2 -
              event.data.global.x / window.editor.getStage().scale.x;
            cropObject.position.x =
              event.data.global.x / window.editor.getStage().scale.x -
              cropObject.width / 2;
            cropObject.height =
              cropObject.position.y -
              cropObject.height / 2 -
              event.data.global.y / window.editor.getStage().scale.y;
            cropObject.position.y =
              event.data.global.y / window.editor.getStage().scale.y -
              cropObject.height / 2;
          }
          break;
        default:
          cropObject.width = newWidth;
          cropObject.height = height;
          break;
      }
      CropSelector.updateSelector();
      if (cropObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropObject.x - cropObject.width / 2,
          cropObject.y - cropObject.height / 2,
          cropObject.width,
          cropObject.height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
  onHeightTopStart: function (event) {
    event.stopPropagation();
    cropObject.data = event.data;
    cropObject.skewTopHeight = true;
    cropObject.skewBottomHeight = false;
    cropObject.skewWidth = false;
    cropObject.dragging = false;
    cropObject.scaling = false;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalY = cropObject.y;
    window.editor.scaleStartWidth = cropObject.width;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalX = cropObject.x;
  },
  onHeightTopEnd: function () {
    cropObject.skewTopHeight = false;
    cropObject.skewBottomHeight = false;
    cropObject.skewWidth = false;
    cropObject.scaling = false;
    cropObject.data = null;
  },
  onHeightBottomStart: function (event) {
    event.stopPropagation();
    cropObject.data = event.data;
    cropObject.skewTopHeight = false;
    cropObject.skewBottomHeight = true;
    cropObject.skewWidth = false;
    cropObject.dragging = false;
    cropObject.scaling = false;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalY = cropObject.y;
    window.editor.scaleStartWidth = cropObject.width;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalX = cropObject.x;
  },
  onHeightBottomEnd: function () {
    cropObject.skewTopHeight = false;
    cropObject.skewBottomHeight = false;
    cropObject.skewWidth = false;
    cropObject.scaling = false;
    cropObject.data = null;
  },
  onHeightTopMove: function (event) {
    if (cropObject && cropObject.skewTopHeight) {
      if (event.data.global.y / window.editor.getStage().scale.y < 0) return;

      if (
        this.originalY +
          window.editor.scaleStartHeight / 2 -
          event.data.global.y / window.editor.getStage().scale.y <
        20
      )
        return;
      cropObject.height =
        this.originalY +
        window.editor.scaleStartHeight / 2 -
        event.data.global.y / window.editor.getStage().scale.y;
      cropObject.position.y =
        event.data.global.y / window.editor.getStage().scale.y +
        cropObject.height / 2;
      CropSelector.updateSelector();
      if (cropObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropObject.x - cropObject.width / 2,
          cropObject.y - cropObject.height / 2,
          cropObject.width,
          cropObject.height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
  onHeightBottomMove: function (event) {
    if (cropObject && cropObject.skewBottomHeight) {
      if (
        window.editor.getBaseObject().rotation === 1.5708 ||
        window.editor.getBaseObject().rotation === 1.5708 * 3
      ) {
        if (
          event.data.global.y / window.editor.getStage().scale.y >
          window.editor.getBaseObject().width
        )
          return;
      } else {
        if (
          event.data.global.y / window.editor.getStage().scale.y >
          window.editor.getBaseObject().height
        )
          return;
      }
      if (
        event.data.global.y / window.editor.getStage().scale.y -
          (this.originalY - window.editor.scaleStartHeight / 2) <
        20
      )
        return;
      cropObject.height =
        event.data.global.y / window.editor.getStage().scale.y -
        (this.originalY - window.editor.scaleStartHeight / 2);
      cropObject.position.y =
        event.data.global.y / window.editor.getStage().scale.y -
        cropObject.height / 2;
      CropSelector.updateSelector();
      if (cropObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropObject.x - cropObject.width / 2,
          cropObject.y - cropObject.height / 2,
          cropObject.width,
          cropObject.height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
  onWidthLeftStart: function (event) {
    event.stopPropagation();
    cropObject.data = event.data;
    cropObject.skewHeight = false;
    cropObject.skewLeftWidth = true;
    cropObject.skewRightWidth = false;
    cropObject.scaling = false;
    cropObject.dragging = false;
    window.editor.scaleStartWidth = cropObject.width;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalX = cropObject.x;
  },
  onWidthLeftEnd: function () {
    cropObject.scaling = false;
    cropObject.skewHeight = false;
    cropObject.skewLeftWidth = false;
    cropObject.skewRightWidth = false;
    cropObject.data = null;
  },
  onWidthRightStart: function (event) {
    event.stopPropagation();
    cropObject.data = event.data;
    cropObject.skewHeight = false;
    cropObject.skewLeftWidth = false;
    cropObject.skewRightWidth = true;
    cropObject.scaling = false;
    cropObject.dragging = false;
    window.editor.scaleStartWidth = cropObject.width;
    window.editor.scaleStartHeight = cropObject.height;
    this.originalX = cropObject.x;
  },
  onWidthRightEnd: function () {
    cropObject.scaling = false;
    cropObject.skewHeight = false;
    cropObject.skewLeftWidth = false;
    cropObject.skewRightWidth = false;
    cropObject.data = null;
  },
  onWidthLeftMove: function (event) {
    if (cropObject && cropObject.skewLeftWidth) {
      if (event.data.global.x / window.editor.getStage().scale.x < 0) return;

      if (
        this.originalX +
          window.editor.scaleStartWidth / 2 -
          event.data.global.x / window.editor.getStage().scale.x <
        20
      )
        return;
      cropObject.width =
        this.originalX +
        window.editor.scaleStartWidth / 2 -
        event.data.global.x / window.editor.getStage().scale.x;
      cropObject.position.x =
        event.data.global.x / window.editor.getStage().scale.x +
        cropObject.width / 2;

      CropSelector.updateSelector();
      if (cropObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropObject.x - cropObject.width / 2,
          cropObject.y - cropObject.height / 2,
          cropObject.width,
          cropObject.height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
  onWidthRightMove: function (event) {
    if (cropObject && cropObject.skewRightWidth) {
      if (
        window.editor.getBaseObject().rotation === 1.5708 ||
        window.editor.getBaseObject().rotation === 1.5708 * 3
      ) {
        if (
          event.data.global.x / window.editor.getStage().scale.x >
          window.editor.getBaseObject().height
        )
          return;
      } else {
        if (
          event.data.global.x / window.editor.getStage().scale.x >
          window.editor.getBaseObject().width
        )
          return;
      }
      if (
        event.data.global.x / window.editor.getStage().scale.x -
          (this.originalX - window.editor.scaleStartWidth / 2) <
        20
      )
        return;
      cropObject.width =
        event.data.global.x / window.editor.getStage().scale.x -
        (this.originalX - window.editor.scaleStartWidth / 2);
      cropObject.position.x =
        event.data.global.x / window.editor.getStage().scale.x -
        cropObject.width / 2;

      CropSelector.updateSelector();
      if (cropObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropObject.x - cropObject.width / 2,
          cropObject.y - cropObject.height / 2,
          cropObject.width,
          cropObject.height
        );
        mask.endFill();
        window.editor.getBaseObject().mask = mask;
      }
    }
  },
};

export default CropSelector;
