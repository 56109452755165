import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const CustomCardPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Custom Card Maker | Online Card Design App"
        description="With our custom card maker you can design your own cards online. It's quick and easy."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/custom_card_maker_app"
        keywords="custom card maker, online card design tool, make cards online , greeting cards, thank you cards"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Custom Card Maker</p>
          <p className="desc">
            Make your own card for that extra special touch
          </p>
          <StartButton
            text="Edit a Card →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/custom_card_maker_app_b.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/custom_card_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Custom Card Maker</span>
            <span className="desc">
              Turn your images and designs into personalized cards using piZap’s
              easy to use Card Maker. With countless stickers, text effects,
              fonts and photo editing tools, this is easily the best custom card
              maker for Christmas cards, birthday cards, and every other
              occasion!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap’s Card Maker Tool</span>
            <span className="desc">
              Adorn your DIY card with stickers, customizable Emojis, pictures,
              graphic designs and more—all by simply using piZap’s wide variety
              of tools. The Card Maker tool lets you express yourself in so many
              ways—no Hallmark card could compete. Create something frilly and
              sentimental, or go for glowing text and a hilarious collage of
              photos. Go ahead, let your imagination run wild.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/custom_card_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/custom_card_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">We Make it Easy to Make Your Own Card</span>
            <span className="desc">
              From basic cards to detailed masterpieces, it’s easy to make all
              kinds of cards using piZap. It’s also easy to flip back and forth
              to other editing tools, for instance create a collage or custom
              Emoji and add it to your card. Font makes a big impact on the look
              of a card, rest assured there are hundreds of hand-picked fonts
              and text effects to play with. No experience necessary, creativity
              recommended.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Card Maker</span>
            <span className="desc">
              Card making just got addictively fun. Don’t let some other card
              maker confuse you, waste your time, or restrict your creativity.
              piZap would never. In one word piZap is fun. Not only is it fun to
              share your online custom greeting cards with loved ones, but it’s
              just as fun to make cards using piZap’s frustration-free tools.
              Get ready to feel like a pro in no time as you zap together
              beautiful, heartfelt and personalized cards.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/custom_card_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/custom_card_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Features on Features</span>
            <span className="desc">
              Just because we keep things basic and understandable doesn’t mean
              you’re stuck with basic cards. Anything but! Our goal was to
              create an app that’s easy to use while still offering awesome
              photo editing tools and features typically reserved to
              professionals. Make holiday cards, birthday cards, Valentine’s Day
              cards—you name it and we have the stickers, fonts, layouts, and
              flexible editing tools to help you create it.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default CustomCardPage;
