import * as PIXI from "pixi.js";

let cropCutoutContainer;

function calculateGetScaledHeight(width, height, newWidth) {
  return (height * newWidth) / width;
}
var CropCutoutSelector = {
  leftTop: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  rightTop: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  LeftBottom: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  RightBottom: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  MiddleTop: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  MiddleBottom: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  MiddleLeft: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  MiddleRight: PIXI.Sprite.fromImage(
    "https://static.pizap.com/html5/resources/editor/scale-circle.svg"
  ),
  border: new PIXI.Graphics(),
  originalX: 0,
  originalY: 0,
  scaleMode: "",
  fixedAspect: false,
  getCropCutoutContainer: function () {
    return cropCutoutContainer;
  },
  init: function () {
    cropCutoutContainer = new PIXI.Container();
    cropCutoutContainer.addChild(CropCutoutSelector.border);
    CropCutoutSelector.leftTop.anchor.set(0.5);
    CropCutoutSelector.leftTop.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.leftTop.interactive = true;
    CropCutoutSelector.leftTop.buttonMode = true;
    CropCutoutSelector.leftTop.renderable = false;
    CropCutoutSelector.leftTop.cursor = "nw-resize";
    CropCutoutSelector.leftTop.location = "leftTop";
    cropCutoutContainer.addChild(CropCutoutSelector.leftTop);
    CropCutoutSelector.leftTop
      .on("mouseover", CropCutoutSelector.onMouseOver)
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onScaleStart)
      .on("touchstart", CropCutoutSelector.onScaleStart)
      .on("mouseup", CropCutoutSelector.onScaleEnd)
      .on("mouseupoutside", CropCutoutSelector.onScaleEnd)
      .on("touchend", CropCutoutSelector.onScaleEnd)
      .on("touchendoutside", CropCutoutSelector.onScaleEnd)
      .on("mousemove", CropCutoutSelector.onScaleMove)
      .on("touchmove", CropCutoutSelector.onScaleMove);
    CropCutoutSelector.rightTop.anchor.set(0.5);
    CropCutoutSelector.rightTop.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.rightTop.interactive = true;
    CropCutoutSelector.rightTop.buttonMode = true;
    CropCutoutSelector.rightTop.renderable = false;
    CropCutoutSelector.rightTop.cursor = "ne-resize";
    CropCutoutSelector.rightTop.location = "rightTop";
    cropCutoutContainer.addChild(CropCutoutSelector.rightTop);
    CropCutoutSelector.rightTop
      .on("mouseover", CropCutoutSelector.onMouseOver)
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onScaleStart)
      .on("touchstart", CropCutoutSelector.onScaleStart)
      .on("mouseup", CropCutoutSelector.onScaleEnd)
      .on("mouseupoutside", CropCutoutSelector.onScaleEnd)
      .on("touchend", CropCutoutSelector.onScaleEnd)
      .on("touchendoutside", CropCutoutSelector.onScaleEnd)
      .on("mousemove", CropCutoutSelector.onScaleMove)
      .on("touchmove", CropCutoutSelector.onScaleMove);

    CropCutoutSelector.LeftBottom.anchor.set(0.5);
    CropCutoutSelector.LeftBottom.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.LeftBottom.interactive = true;
    CropCutoutSelector.LeftBottom.buttonMode = true;
    CropCutoutSelector.LeftBottom.renderable = false;
    CropCutoutSelector.LeftBottom.cursor = "sw-resize";
    CropCutoutSelector.LeftBottom.location = "LeftBottom";
    cropCutoutContainer.addChild(CropCutoutSelector.LeftBottom);
    CropCutoutSelector.LeftBottom.on(
      "mouseover",
      CropCutoutSelector.onMouseOver
    )
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onScaleStart)
      .on("touchstart", CropCutoutSelector.onScaleStart)
      .on("mouseup", CropCutoutSelector.onScaleEnd)
      .on("mouseupoutside", CropCutoutSelector.onScaleEnd)
      .on("touchend", CropCutoutSelector.onScaleEnd)
      .on("touchendoutside", CropCutoutSelector.onScaleEnd)
      .on("mousemove", CropCutoutSelector.onScaleMove)
      .on("touchmove", CropCutoutSelector.onScaleMove);

    CropCutoutSelector.RightBottom.anchor.set(0.5);
    CropCutoutSelector.RightBottom.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.RightBottom.interactive = true;
    CropCutoutSelector.RightBottom.buttonMode = true;
    CropCutoutSelector.RightBottom.renderable = false;
    CropCutoutSelector.RightBottom.cursor = "se-resize";
    CropCutoutSelector.RightBottom.location = "RightBottom";
    cropCutoutContainer.addChild(CropCutoutSelector.RightBottom);
    CropCutoutSelector.RightBottom.on(
      "mouseover",
      CropCutoutSelector.onMouseOver
    )
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onScaleStart)
      .on("touchstart", CropCutoutSelector.onScaleStart)
      .on("mouseup", CropCutoutSelector.onScaleEnd)
      .on("mouseupoutside", CropCutoutSelector.onScaleEnd)
      .on("touchend", CropCutoutSelector.onScaleEnd)
      .on("touchendoutside", CropCutoutSelector.onScaleEnd)
      .on("mousemove", CropCutoutSelector.onScaleMove)
      .on("touchmove", CropCutoutSelector.onScaleMove);

    CropCutoutSelector.MiddleTop.anchor.set(0.5);
    CropCutoutSelector.MiddleTop.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.MiddleTop.interactive = true;
    CropCutoutSelector.MiddleTop.buttonMode = true;
    CropCutoutSelector.MiddleTop.renderable = false;
    CropCutoutSelector.MiddleTop.cursor = "n-resize";
    cropCutoutContainer.addChild(CropCutoutSelector.MiddleTop);
    CropCutoutSelector.MiddleTop.on("mouseover", CropCutoutSelector.onMouseOver)
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onHeightTopStart)
      .on("touchstart", CropCutoutSelector.onHeightTopStart)
      .on("mouseup", CropCutoutSelector.onHeightTopEnd)
      .on("mouseupoutside", CropCutoutSelector.onHeightTopEnd)
      .on("touchend", CropCutoutSelector.onHeightTopEnd)
      .on("touchendoutside", CropCutoutSelector.onHeightTopEnd)
      .on("mousemove", CropCutoutSelector.onHeightTopMove)
      .on("touchmove", CropCutoutSelector.onHeightTopMove);

    CropCutoutSelector.MiddleBottom.anchor.set(0.5);
    CropCutoutSelector.MiddleBottom.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.MiddleBottom.interactive = true;
    CropCutoutSelector.MiddleBottom.buttonMode = true;
    CropCutoutSelector.MiddleBottom.renderable = false;
    CropCutoutSelector.MiddleBottom.cursor = "s-resize";
    cropCutoutContainer.addChild(CropCutoutSelector.MiddleBottom);
    CropCutoutSelector.MiddleBottom.on(
      "mouseover",
      CropCutoutSelector.onMouseOver
    )
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onHeightBottomStart)
      .on("touchstart", CropCutoutSelector.onHeightBottomStart)
      .on("mouseup", CropCutoutSelector.onHeightBottomEnd)
      .on("mouseupoutside", CropCutoutSelector.onHeightBottomEnd)
      .on("touchend", CropCutoutSelector.onHeightBottomEnd)
      .on("touchendoutside", CropCutoutSelector.onHeightBottomEnd)
      .on("mousemove", CropCutoutSelector.onHeightBottomMove)
      .on("touchmove", CropCutoutSelector.onHeightBottomMove);

    CropCutoutSelector.MiddleLeft.anchor.set(0.5);
    CropCutoutSelector.MiddleLeft.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.MiddleLeft.interactive = true;
    CropCutoutSelector.MiddleLeft.buttonMode = true;
    CropCutoutSelector.MiddleLeft.renderable = false;
    CropCutoutSelector.MiddleLeft.cursor = "w-resize";
    cropCutoutContainer.addChild(CropCutoutSelector.MiddleLeft);
    CropCutoutSelector.MiddleLeft.on(
      "mouseover",
      CropCutoutSelector.onMouseOver
    )
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onWidthLeftStart)
      .on("touchstart", CropCutoutSelector.onWidthLeftStart)
      .on("mouseup", CropCutoutSelector.onWidthLeftEnd)
      .on("mouseupoutside", CropCutoutSelector.onWidthLeftEnd)
      .on("touchend", CropCutoutSelector.onWidthLeftEnd)
      .on("touchendoutside", CropCutoutSelector.onWidthLeftEnd)
      .on("mousemove", CropCutoutSelector.onWidthLeftMove)
      .on("touchmove", CropCutoutSelector.onWidthLeftMove);

    CropCutoutSelector.MiddleRight.anchor.set(0.5);
    CropCutoutSelector.MiddleRight.scale.set(0.5 / window.editor.getScale());
    CropCutoutSelector.MiddleRight.interactive = true;
    CropCutoutSelector.MiddleRight.buttonMode = true;
    CropCutoutSelector.MiddleRight.renderable = false;
    CropCutoutSelector.MiddleRight.cursor = "e-resize";
    cropCutoutContainer.addChild(CropCutoutSelector.MiddleRight);
    CropCutoutSelector.MiddleRight.on(
      "mouseover",
      CropCutoutSelector.onMouseOver
    )
      .on("mouseout", CropCutoutSelector.onMouseOut)
      .on("mousedown", CropCutoutSelector.onWidthRightStart)
      .on("touchstart", CropCutoutSelector.onWidthRightStart)
      .on("mouseup", CropCutoutSelector.onWidthRightEnd)
      .on("mouseupoutside", CropCutoutSelector.onWidthRightEnd)
      .on("touchend", CropCutoutSelector.onWidthRightEnd)
      .on("touchendoutside", CropCutoutSelector.onWidthRightEnd)
      .on("mousemove", CropCutoutSelector.onWidthRightMove)
      .on("touchmove", CropCutoutSelector.onWidthRightMove);
    CropCutoutSelector.updateSelector();
  },
  updateSelector: function () {
    if (window.editor.getCropCutoutObject()) {
      cropCutoutContainer.x = window.editor.getCropCutoutObject().x;
      cropCutoutContainer.y = window.editor.getCropCutoutObject().y;
      var width = window.editor.getCropCutoutObject().width;
      var height = window.editor.getCropCutoutObject().height;

      CropCutoutSelector.border.blendMode = PIXI.BLEND_MODES.ADD;
      CropCutoutSelector.border.clear();
      CropCutoutSelector.border.lineStyle(
        1 / window.editor.getCutoutStage().scale.x,
        0xffffff,
        0.2
      );
      CropCutoutSelector.border.drawRect(0, 0, width, height);
      CropCutoutSelector.border.moveTo(width / 3, 0);
      CropCutoutSelector.border.lineTo(width / 3, height);
      CropCutoutSelector.border.moveTo((width / 3) * 2, 0);
      CropCutoutSelector.border.lineTo((width / 3) * 2, height);

      CropCutoutSelector.border.moveTo(0, height / 3);
      CropCutoutSelector.border.lineTo(width, height / 3);
      CropCutoutSelector.border.moveTo(0, 2 * (height / 3));
      CropCutoutSelector.border.lineTo(width, 2 * (height / 3));

      CropCutoutSelector.border.lineStyle(
        1 / window.editor.getCutoutStage().scale.x,
        0xffffff,
        0.2
      );
      CropCutoutSelector.border.beginFill(0xffffff, 0.5);
      //top left
      CropCutoutSelector.border.drawRect(
        0,
        0,
        width / 20,
        3 / window.editor.getCutoutStage().scale.x
      );
      CropCutoutSelector.border.drawRect(
        0,
        0,
        3 / window.editor.getCutoutStage().scale.x,
        width / 20
      );

      //bottom left
      CropCutoutSelector.border.drawRect(
        0,
        height - 3 / window.editor.getCutoutStage().scale.x,
        width / 20,
        3 / window.editor.getCutoutStage().scale.x
      );
      CropCutoutSelector.border.drawRect(
        0,
        height - width / 20,
        3 / window.editor.getCutoutStage().scale.x,
        width / 20
      );

      //top right
      CropCutoutSelector.border.drawRect(
        width - width / 20,
        0,
        width / 20,
        3 / window.editor.getCutoutStage().scale.x
      );
      CropCutoutSelector.border.drawRect(
        width - 3 / window.editor.getCutoutStage().scale.x,
        0,
        3 / window.editor.getCutoutStage().scale.x,
        width / 20
      );

      //bottom right
      CropCutoutSelector.border.drawRect(
        width - width / 20,
        height - 3 / window.editor.getCutoutStage().scale.x,
        width / 20,
        3 / window.editor.getCutoutStage().scale.x
      );
      CropCutoutSelector.border.drawRect(
        width - 3 / window.editor.getCutoutStage().scale.x,
        height - width / 20,
        3 / window.editor.getCutoutStage().scale.x,
        width / 20
      );

      //center top
      CropCutoutSelector.border.drawRect(
        width / 2 - width / 40,
        0,
        width / 20,
        3 / window.editor.getCutoutStage().scale.x
      );

      //center bottom
      CropCutoutSelector.border.drawRect(
        width / 2 - width / 40,
        height - 3 / window.editor.getCutoutStage().scale.x,
        width / 20,
        3 / window.editor.getCutoutStage().scale.x
      );

      //center left
      CropCutoutSelector.border.drawRect(
        0,
        height / 2 - width / 40,
        3 / window.editor.getCutoutStage().scale.x,
        width / 20
      );

      //center right
      CropCutoutSelector.border.drawRect(
        width - 3 / window.editor.getCutoutStage().scale.x,
        height / 2 - width / 40,
        3 / window.editor.getCutoutStage().scale.x,
        width / 20
      );

      CropCutoutSelector.border.endFill(0xffffff, 0.5);

      CropCutoutSelector.leftTop.position.x = 0;
      CropCutoutSelector.leftTop.position.y = 0;
      CropCutoutSelector.leftTop.renderable = false;
      CropCutoutSelector.leftTop.width = width / 10;
      CropCutoutSelector.leftTop.height = height / 10;

      CropCutoutSelector.rightTop.position.x = width;
      CropCutoutSelector.rightTop.position.y = 0;
      CropCutoutSelector.rightTop.renderable = false;
      CropCutoutSelector.rightTop.width = width / 10;
      CropCutoutSelector.rightTop.height = height / 10;

      CropCutoutSelector.LeftBottom.position.x = 0;
      CropCutoutSelector.LeftBottom.position.y = height;
      CropCutoutSelector.LeftBottom.renderable = false;
      CropCutoutSelector.LeftBottom.width = width / 10;
      CropCutoutSelector.LeftBottom.height = height / 10;

      CropCutoutSelector.RightBottom.position.x = width;
      CropCutoutSelector.RightBottom.position.y = height;
      CropCutoutSelector.RightBottom.renderable = false;
      CropCutoutSelector.RightBottom.width = width / 10;
      CropCutoutSelector.RightBottom.height = height / 10;

      CropCutoutSelector.MiddleBottom.position.x = width / 2;
      CropCutoutSelector.MiddleBottom.position.y = height;
      CropCutoutSelector.MiddleBottom.renderable = false;
      CropCutoutSelector.MiddleBottom.width = width / 10;
      CropCutoutSelector.MiddleBottom.height = height / 10;

      CropCutoutSelector.MiddleTop.position.x = width / 2;
      CropCutoutSelector.MiddleTop.position.y = 0;
      CropCutoutSelector.MiddleTop.renderable = false;
      CropCutoutSelector.MiddleTop.width = width / 10;
      CropCutoutSelector.MiddleTop.height = height / 10;

      CropCutoutSelector.MiddleLeft.position.x = 0;
      CropCutoutSelector.MiddleLeft.position.y = height / 2;
      CropCutoutSelector.MiddleLeft.renderable = false;
      CropCutoutSelector.MiddleLeft.width = width / 10;
      CropCutoutSelector.MiddleLeft.height = height / 10;

      CropCutoutSelector.MiddleRight.position.x = width;
      CropCutoutSelector.MiddleRight.position.y = height / 2;
      CropCutoutSelector.MiddleRight.renderable = false;
      CropCutoutSelector.MiddleRight.width = width / 10;
      CropCutoutSelector.MiddleRight.height = height / 10;
    } else {
      CropCutoutSelector.leftTop.renderable = false;
      CropCutoutSelector.rightTop.renderable = false;
      CropCutoutSelector.LeftBottom.renderable = false;
      CropCutoutSelector.RightBottom.renderable = false;
      CropCutoutSelector.MiddleBottom.renderable = false;
      CropCutoutSelector.MiddleLeft.renderable = false;
      CropCutoutSelector.MiddleRight.renderable = false;
      CropCutoutSelector.MiddleTop.renderable = false;
      CropCutoutSelector.border.clear();
    }
  },
  onMouseOver: function (event) {},
  onMouseOut: function () {},
  onScaleStart: function (event) {
    event.stopPropagation();
    window.editor.getCropCutoutObject().data = event.data;
    window.editor.getCropCutoutObject().scaling = true;
    window.editor.getCropCutoutObject().dragging = false;
    window.editor.scaleStartWidth = window.editor.getCropCutoutObject().width;
    window.editor.scaleStartHeight = window.editor.getCropCutoutObject().height;
    CropCutoutSelector.originalX =
      window.editor.getCropCutoutObject().width -
      event.data.global.x -
      window.editor.getCropCutoutObject().x;
    document.addEventListener("mouseup", CropCutoutSelector.onScaleEnd);
    if (CropCutoutSelector.scaleMode !== "fixed") {
      CropCutoutSelector.scaleMode = event.currentTarget.location;
    }
  },
  onScaleEnd: function () {
    window.editor.getCropCutoutObject().scaling = false;
    window.editor.getCropCutoutObject().data = null;
    document.removeEventListener("mouseup", CropCutoutSelector.onScaleEnd);
  },
  onScaleMove: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    if (cropCutoutObject && cropCutoutObject.scaling) {
      var tempX =
        event.data.global.x / window.editor.getCutoutStage().scale.x -
        cropCutoutObject.x;
      var tempY =
        event.data.global.y / window.editor.getCutoutStage().scale.y -
        cropCutoutObject.y;

      var rotatedX =
        tempX * Math.cos(cropCutoutObject.rotation) +
        tempY * Math.sin(cropCutoutObject.rotation);

      var unRotatedX = rotatedX + cropCutoutObject.x;

      var newWidth = Math.abs(unRotatedX - cropCutoutObject.x) * 2;
      var height = calculateGetScaledHeight(
        cropCutoutObject.width,
        cropCutoutObject.height,
        newWidth
      );

      switch (CropCutoutSelector.scaleMode) {
        case "leftTop":
          if (cropCutoutObject.position.x <= 0) {
            return;
          }
          if (cropCutoutObject.position.y <= 0) {
            return;
          }
          cropCutoutObject.width =
            cropCutoutObject.width +
            (cropCutoutObject.position.x -
              event.data.global.x / window.editor.getCutoutStage().scale.x);
          cropCutoutObject.position.x =
            event.data.global.x / window.editor.getCutoutStage().scale.x;
          cropCutoutObject.height =
            cropCutoutObject.height +
            (cropCutoutObject.position.y -
              event.data.global.y / window.editor.getCutoutStage().scale.y);
          cropCutoutObject.position.y =
            event.data.global.y / window.editor.getCutoutStage().scale.y;
          break;
        case "LeftBottom":
          if (cropCutoutObject.position.x <= 0) return;
          if (
            cropCutoutObject.position.y + height >=
            window.editor.getImgObject().height
          )
            return;
          cropCutoutObject.width =
            cropCutoutObject.width +
            (cropCutoutObject.position.x -
              event.data.global.x / window.editor.getCutoutStage().scale.x);
          cropCutoutObject.position.x =
            event.data.global.x / window.editor.getCutoutStage().scale.x;
          cropCutoutObject.height =
            event.data.global.y / window.editor.getCutoutStage().scale.y -
            cropCutoutObject.position.y;
          break;
        case "rightTop":
          if (cropCutoutObject.position.y <= 0) return;
          if (
            cropCutoutObject.position.x +
              (event.data.global.x / window.editor.getCutoutStage().scale.x -
                cropCutoutObject.position.x) >=
            window.editor.getImgObject().width
          )
            return;
          cropCutoutObject.width =
            event.data.global.x / window.editor.getCutoutStage().scale.x -
            cropCutoutObject.position.x;
          cropCutoutObject.height =
            cropCutoutObject.height +
            (cropCutoutObject.position.y -
              event.data.global.y / window.editor.getCutoutStage().scale.y);
          cropCutoutObject.position.y =
            event.data.global.y / window.editor.getCutoutStage().scale.y;
          break;
        case "RightBottom":
          if (
            cropCutoutObject.position.y +
              (event.data.global.y / window.editor.getCutoutStage().scale.y -
                cropCutoutObject.position.y) >=
            window.editor.getImgObject().height
          )
            return;
          if (
            cropCutoutObject.position.x +
              (event.data.global.x / window.editor.getCutoutStage().scale.x -
                cropCutoutObject.position.x) >=
            window.editor.getImgObject().width
          )
            return;
          cropCutoutObject.width =
            event.data.global.x / window.editor.getCutoutStage().scale.x -
            cropCutoutObject.position.x;
          cropCutoutObject.height =
            event.data.global.y / window.editor.getCutoutStage().scale.y -
            cropCutoutObject.position.y;
          break;
        default:
          //                    console.log("other");
          //                    cropCutoutObject.width = newWidth;
          //                    cropCutoutObject.height = height;
          break;
      }
      CropCutoutSelector.updateSelector();
      if (cropCutoutObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropCutoutObject.x * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.y * window.editor.getImgObject().parent.scale.y,
          cropCutoutObject.width * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.height * window.editor.getImgObject().parent.scale.y
        );
        mask.endFill();
        window.editor.getImgObject().mask = mask;
      }
    }
  },
  onHeightTopStart: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    event.stopPropagation();
    cropCutoutObject.data = event.data;
    cropCutoutObject.skewTopHeight = true;
    cropCutoutObject.skewBottomHeight = false;
    cropCutoutObject.skewWidth = false;
    cropCutoutObject.dragging = false;
    cropCutoutObject.scaling = false;
    window.editor.scaleStartHeight = cropCutoutObject.height;
    CropCutoutSelector.originalY = cropCutoutObject.y;
    window.editor.scaleStartWidth = cropCutoutObject.width;
    window.editor.scaleStartHeight = cropCutoutObject.height;
    CropCutoutSelector.originalX = cropCutoutObject.x;
  },
  onHeightTopEnd: function () {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    cropCutoutObject.skewTopHeight = false;
    cropCutoutObject.skewBottomHeight = false;
    cropCutoutObject.skewWidth = false;
    cropCutoutObject.scaling = false;
    cropCutoutObject.data = null;
  },
  onHeightBottomStart: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    event.stopPropagation();
    cropCutoutObject.data = event.data;
    cropCutoutObject.skewTopHeight = false;
    cropCutoutObject.skewBottomHeight = true;
    cropCutoutObject.skewWidth = false;
    cropCutoutObject.dragging = false;
    cropCutoutObject.scaling = false;
    window.editor.scaleStartHeight = cropCutoutObject.height;
    CropCutoutSelector.originalY = cropCutoutObject.y;
    window.editor.scaleStartWidth = cropCutoutObject.width;
    window.editor.scaleStartHeight = cropCutoutObject.height;
    CropCutoutSelector.originalX = cropCutoutObject.x;
  },
  onHeightBottomEnd: function () {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    cropCutoutObject.skewTopHeight = false;
    cropCutoutObject.skewBottomHeight = false;
    cropCutoutObject.skewWidth = false;
    cropCutoutObject.scaling = false;
    cropCutoutObject.data = null;
  },
  onHeightTopMove: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    if (cropCutoutObject && cropCutoutObject.skewTopHeight) {
      if (event.data.global.y / window.editor.getCutoutStage().scale.y < 0)
        return;

      if (
        CropCutoutSelector.originalY +
          window.editor.scaleStartHeight / 2 -
          event.data.global.y / window.editor.getCutoutStage().scale.y <
        20
      )
        return;
      var bottom = cropCutoutObject.position.y + cropCutoutObject.height;
      cropCutoutObject.height =
        bottom - event.data.global.y / window.editor.getCutoutStage().scale.y;
      cropCutoutObject.position.y =
        event.data.global.y / window.editor.getCutoutStage().scale.y;

      CropCutoutSelector.updateSelector();
      if (cropCutoutObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropCutoutObject.x * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.y * window.editor.getImgObject().parent.scale.y,
          cropCutoutObject.width * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.height * window.editor.getImgObject().parent.scale.y
        );
        mask.endFill();
        window.editor.getImgObject().mask = mask;
      }
    }
  },
  onHeightBottomMove: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    if (cropCutoutObject && cropCutoutObject.skewBottomHeight) {
      if (
        event.data.global.y / window.editor.getCutoutStage().scale.y >
        window.editor.getImgObject().height
      )
        return;
      if (
        event.data.global.y / window.editor.getCutoutStage().scale.y -
          (CropCutoutSelector.originalY - window.editor.scaleStartHeight) <
        20
      )
        return;
      cropCutoutObject.height =
        event.data.global.y / window.editor.getCutoutStage().scale.y -
        cropCutoutObject.position.y;
      CropCutoutSelector.updateSelector();
      if (cropCutoutObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropCutoutObject.x * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.y * window.editor.getImgObject().parent.scale.y,
          cropCutoutObject.width * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.height * window.editor.getImgObject().parent.scale.y
        );
        mask.endFill();
        window.editor.getImgObject().mask = mask;
      }
    }
  },
  onWidthLeftStart: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    event.stopPropagation();
    cropCutoutObject.data = event.data;
    cropCutoutObject.skewHeight = false;
    cropCutoutObject.skewLeftWidth = true;
    cropCutoutObject.skewRightWidth = false;
    cropCutoutObject.scaling = false;
    cropCutoutObject.dragging = false;
    window.editor.scaleStartWidth = cropCutoutObject.width;
    window.editor.scaleStartHeight = cropCutoutObject.height;
    CropCutoutSelector.originalX = cropCutoutObject.x;
  },
  onWidthLeftEnd: function () {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    cropCutoutObject.scaling = false;
    cropCutoutObject.skewHeight = false;
    cropCutoutObject.skewLeftWidth = false;
    cropCutoutObject.skewRightWidth = false;
    cropCutoutObject.data = null;
  },
  onWidthRightStart: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    event.stopPropagation();
    cropCutoutObject.data = event.data;
    cropCutoutObject.skewHeight = false;
    cropCutoutObject.skewLeftWidth = false;
    cropCutoutObject.skewRightWidth = true;
    cropCutoutObject.scaling = false;
    cropCutoutObject.dragging = false;
    window.editor.scaleStartWidth = cropCutoutObject.width;
    window.editor.scaleStartHeight = cropCutoutObject.height;
    CropCutoutSelector.originalX = cropCutoutObject.x;
  },
  onWidthRightEnd: function () {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    cropCutoutObject.scaling = false;
    cropCutoutObject.skewHeight = false;
    cropCutoutObject.skewLeftWidth = false;
    cropCutoutObject.skewRightWidth = false;
    cropCutoutObject.data = null;
  },
  onWidthLeftMove: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    if (cropCutoutObject && cropCutoutObject.skewLeftWidth) {
      if (event.data.global.x / window.editor.getCutoutStage().scale.x < 0)
        return;

      if (
        CropCutoutSelector.originalX +
          window.editor.scaleStartWidth / 2 -
          event.data.global.x / window.editor.getCutoutStage().scale.x <
        20
      )
        return;
      var right = cropCutoutObject.position.x + cropCutoutObject.width;
      cropCutoutObject.width =
        right - event.data.global.x / window.editor.getCutoutStage().scale.x;
      cropCutoutObject.position.x =
        event.data.global.x / window.editor.getCutoutStage().scale.x;

      CropCutoutSelector.updateSelector();
      if (cropCutoutObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropCutoutObject.x * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.y * window.editor.getImgObject().parent.scale.y,
          cropCutoutObject.width * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.height * window.editor.getImgObject().parent.scale.y
        );
        mask.endFill();
        window.editor.getImgObject().mask = mask;
      }
    }
  },
  onWidthRightMove: function (event) {
    var cropCutoutObject = window.editor.getCropCutoutObject();
    if (cropCutoutObject && cropCutoutObject.skewRightWidth) {
      if (
        window.editor.getImgObject().rotation === 1.5708 ||
        window.editor.getImgObject().rotation === 1.5708 * 3
      ) {
        if (
          event.data.global.x / window.editor.getCutoutStage().scale.x >
          window.editor.getImgObject().height
        )
          return;
      } else {
        if (
          event.data.global.x / window.editor.getCutoutStage().scale.x >
          window.editor.getImgObject().width
        )
          return;
      }
      if (
        event.data.global.x / window.editor.getCutoutStage().scale.x -
          (CropCutoutSelector.originalX - window.editor.scaleStartWidth / 2) <
        20
      )
        return;
      cropCutoutObject.width =
        event.data.global.x / window.editor.getCutoutStage().scale.x -
        cropCutoutObject.position.x;

      CropCutoutSelector.updateSelector();
      if (cropCutoutObject.crop) {
        var mask = new PIXI.Graphics();
        mask.beginFill(0xffffff, 1);
        mask.drawRect(
          cropCutoutObject.x * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.y * window.editor.getImgObject().parent.scale.y,
          cropCutoutObject.width * window.editor.getImgObject().parent.scale.x,
          cropCutoutObject.height * window.editor.getImgObject().parent.scale.y
        );
        mask.endFill();
        window.editor.getImgObject().mask = mask;
      }
    }
  },
};
export default CropCutoutSelector;
