import React from "react";
import Header from "../layouts/Header";
import HelmetDetails from "./HelmetDetails";

const Browser = () => {
  return (
    <>
      <Header />
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app/browser"
        keywords="piZap Web Browser"
      />
      <div className="browserPage">
        <div className="browserMessage">
          <div>
            Oops! Your browser is out of date or not supported by piZap.
          </div>
          <div>Please upgrade to one of these free modern browsers:</div>
          <div className="browserItems">
            <div className="browserItem">
              <img
                className="browserLogo"
                src="https://static.pizap.com/html5/resources/editor/chrome_logo.png"
                alt="Download chrome"
              />
              <span>Google Chrome</span>
              <a href="https://www.google.com/chrome">
                <img
                  className="downloadButton"
                  src="https://static.pizap.com/html5/resources/editor/download_browser_button.png"
                  alt="download"
                />{" "}
              </a>
            </div>
            <div className="browserItem">
              <img
                className="browserLogo"
                src="https://static.pizap.com/html5/resources/editor/edge_logo.png"
                alt="Download chrome"
              />
              <span>Microsoft Edge</span>
              <a href="https://www.microsoft.com/edge">
                <img
                  className="downloadButton"
                  src="https://static.pizap.com/html5/resources/editor/download_browser_button.png"
                  alt="download"
                />{" "}
              </a>
            </div>
            <div className="browserItem">
              <img
                className="browserLogo"
                src="https://static.pizap.com/html5/resources/editor/firefox_logo.png"
                alt="Download chrome"
              />
              <span>Firefox</span>
              <a href="https://www.mozilla.org/firefox/new/">
                <img
                  className="downloadButton"
                  src="https://static.pizap.com/html5/resources/editor/download_browser_button.png"
                  alt="download"
                />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Browser;
