import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import UserStore from "../stores/UserStore";
import MaterialTable from "material-table";
import AdminHeader from "../layouts/AdminHeader";
import AssetStore from "../stores/AssetStore";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import PatchedPagination from "../components/admin/PatchedPagination";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const getStateFromStores = () => {
  return {
    templates: AssetStore.getTemplates(),
  };
};

const AdminTemplatesPage = () => {
  const [data, setData] = useState(getStateFromStores());
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (data.templates === null) {
        AssetActionCreators.loadAITemplates();
      }
      AssetStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  let [columns] = useState([
    {
      title: "Thumb",
      field: "thumbnail",
      render: (rowData) => (
        <img className="thumbIcon" src={rowData.thumbnail} alt="icon" />
      ),
    },
    { title: "Age", field: "age" },
    { title: "Caption", field: "caption" },
    { title: "Sequence", field: "sequence", type: "numeric" },
    {
      title: "Tags",
      field: "tags",
      render: (rowData) => (
        <>
          {rowData.tags.map((tag) => {
            let icon;
            return (
              <Chip
                key={tag}
                icon={icon}
                label={tag}
                onDelete={handleTagDelete(rowData, tag)}
                className={classes.chip}
              />
            );
          })}
        </>
      ),
    },
  ]);

  useEffect(() => {
    if (data.templates) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [data.templates]);

  const handleTagDelete = (chipToDelete, tag) => () => {
    data.templates.forEach((item, index) => {
      if (item.imageName === chipToDelete.imageName) {
        item.tags = item.tags.filter((chip) => chip !== tag);
        fetch(
          `${process.env.REACT_APP_SERVER_API}/projectTag/${encodeURIComponent(
            item.imageName
          )}/${encodeURIComponent(tag)}`,
          {
            method: "DELETE",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
              "content-type": "application/json",
            }),
          }
        ).then((res) => {
          console.log("resolved");
        });
      }
    });
  };

  return (
    <section className="admin-section banner banner-section">
      <AdminHeader />
      {isLoaded ? (
        <div className="item-b">
          <MaterialTable
            title="Pizap Templates"
            columns={columns}
            data={data.templates}
            options={{
              filtering: true,
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 100],
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = data.templates;
                    const index = oldData.tableData.id;
                    if (
                      newData.tags !== null &&
                      newData.tags !== "" &&
                      typeof newData.tags === "string"
                    ) {
                      newData.tags = newData.tags.split(",");
                    }
                    dataUpdate[index] = newData;
                    fetch(
                      `${
                        process.env.REACT_APP_SERVER_API
                      }/pizapTemplate/${encodeURIComponent(newData.imageName)}`,
                      {
                        method: "PUT",
                        headers: new Headers({
                          Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          "content-type": "application/json",
                        }),
                        body: JSON.stringify(newData),
                      }
                    )
                      .then((res) => {
                        if (res.status === 200) {
                          AssetStore.setTemplates([...dataUpdate]);
                          AssetStore.emitChange();
                          resolve();
                        } else {
                          reject();
                        }
                      })
                      .catch((e) => {
                        reject(e);
                      });
                  }, 1000);
                }),
            }}
            components={{
              Pagination: PatchedPagination,
            }}
          />
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </section>
  );
};

export default AdminTemplatesPage;
