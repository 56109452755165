import AssetData from "../assets.json";
import * as AssetActionCreators from "../actions/AssetActionCreators";

export default {
  readTextFile: function (file, callback) {
    var rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType("application/json");
    rawFile.open("GET", file, true);
    rawFile.onreadystatechange = function () {
      if (rawFile.readyState === 4 && rawFile.status === 200) {
        callback(rawFile.responseText);
      }
    };
    rawFile.send(null);
  },
  readAssetData: function () {
    AssetActionCreators.receiveAssets(AssetData);
  },
  getJSON: function (url, methodType, myData, successHandler, errorHandler) {
    var xhr = new XMLHttpRequest();
    xhr.open(methodType, url, true);
    xhr.onreadystatechange = function () {
      var status;
      var data;
      // http://xhr.spec.whatwg.org/#dom-xmlhttprequest-readystate
      if (xhr.readyState === 4) {
        // `DONE`
        status = xhr.status;
        if (status === 200) {
          try {
            data = JSON.parse(xhr.responseText);
            successHandler && successHandler(data);
          } catch (e) {
            successHandler && successHandler(xhr.responseText);
          }
        } else {
          errorHandler && errorHandler(status);
        }
      }
    };
    xhr.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
    if (myData !== null) xhr.send(myData);
    else xhr.send();
  },
  postJSON: function (url, methodType, myData, successHandler, errorHandler) {
    var xhr = new XMLHttpRequest();
    xhr.open(methodType, url, true);
    xhr.onreadystatechange = function () {
      var status;
      var data;
      // http://xhr.spec.whatwg.org/#dom-xmlhttprequest-readystate
      if (xhr.readyState === 4) {
        // `DONE`
        status = xhr.status;
        if (status === 200) {
          try {
            data = JSON.parse(xhr.responseText);
            successHandler && successHandler(data);
          } catch (e) {
            successHandler && successHandler(xhr.responseText);
          }
        } else {
          errorHandler && errorHandler(status);
        }
      }
    };
    xhr.setRequestHeader("Content-type", "application/json; charset=UTF-8");
    if (myData !== null) xhr.send(myData);
    else xhr.send();
  },
  getBinary: function (url, methodType, myData, successHandler, errorHandler) {
    var xhr = new XMLHttpRequest();
    xhr.open(methodType, url, true);
    xhr.responseType = "blob";
    xhr.onreadystatechange = function () {
      var status;
      if (xhr.readyState === 4) {
        // `DONE`
        status = xhr.status;
        if (status === 200) {
          successHandler && successHandler(xhr.response);
        } else {
          errorHandler && errorHandler(status);
        }
      }
    };
    if (myData !== null) xhr.send(myData);
    else xhr.send();
  },
  getXML: function (url, methodType, myData, successHandler, errorHandler) {
    var xhr = new XMLHttpRequest();
    xhr.open(methodType, url, true);
    xhr.overrideMimeType("text/xml");
    xhr.onreadystatechange = function () {
      var status;
      var data;
      // http://xhr.spec.whatwg.org/#dom-xmlhttprequest-readystate
      if (xhr.readyState === 4) {
        // `DONE`
        status = xhr.status;
        if (status === 200) {
          try {
            data = xhr.responseXML;
            successHandler && successHandler(data);
          } catch (e) {
            console.log(e);
          }
        } else {
          errorHandler && errorHandler(status);
        }
      }
    };
    xhr.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
    if (myData !== null) xhr.send(myData);
    else xhr.send();
  },
  guid: function () {
    return (
      this.s4() +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      this.s4() +
      this.s4()
    );
  },
  s4: function () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  },
};
