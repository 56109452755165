import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import React, { useState, useEffect } from "react";
import EditorDispatcher from "../dispatcher/EditorDispatcher";
import UserStore from "../stores/UserStore";
import LeftMenuStore from "../stores/LeftMenuStore";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import Touchup from "../editor/touchup";
import ProCrown from "./ProCrown.react";
import ActionTypes from "../constants/EditorConstants";
import AssetStore from "../stores/AssetStore";
import SkeletonLoading from "./SkeletonLoadingImage";

const MenuItem = (props) => {
  const { item, type } = props;
  const selectedMenu = LeftMenuStore.get();
  const [style, setStyle] = useState("none");
  const isPro = UserStore.isPro();
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (
      selectedMenu === "design" ||
      selectedMenu === "effect" ||
      selectedMenu === "main"
    ) {
      if (
        EditorStore.getBackgroundEffect() &&
        EditorStore.getBackgroundEffect().Name === item.name
      ) {
        setStyle("2px solid rgb(0 109 255");
      } else {
        setStyle("none");
      }
    } else {
      if (props.selectedEffect && props.selectedEffect.Name === item.name) {
        setStyle("2px solid rgb(0 109 255");
      } else {
        setStyle("none");
      }
    }
    // } else if (selectedMenu === "edit_graphic") {
    //   if (
    //     EditorStore.getGraphicEffect() &&
    //     EditorStore.getGraphicEffect().Name === item.name
    //   ) {
    //     setStyle("2px solid rgb(0 109 255");
    //   } else {
    //     setStyle("none");
    //   }
    // } else if (selectedMenu === "collagehotspot") {
    //   if (
    //     EditorStore.getCollageHotspotEffect() &&
    //     EditorStore.getCollageHotspotEffect().Name === item.name
    //   ) {
    //     setStyle("2px solid rgb(0 109 255");
    //   } else {
    //     setStyle("none");
    //   }
    // }
  }, [
    EditorStore.getBackgroundEffect(),
    EditorStore.getGraphicEffect(),
    EditorStore.getCollageHotspotEffect(),
    props.selectedEffect,
  ]);

  const itemSelected = () => {
    if (item.isProOnly && !isPro) {
      UserActionCreators.showProGet(true);
    } else {
      switch (type) {
        case "sticker":
          LeftMenuActionCreators.StickerFolderSelected(item);
          break;
        case "effect":
          if (item.item) {
            if (
              selectedMenu === "main" ||
              selectedMenu === "design" ||
              selectedMenu === "effect"
            ) {
              window.editor.realApplyEffect({ Effect: [] });
              EditorStore.setBackgroundEffect(item.item);
              props.setSelectedEffect(item);
              AssetStore.emitChange();
            } else if (
              selectedMenu === "edit_graphic" ||
              selectedMenu === "edit_graphic_svg"
            ) {
              EditorStore.setGraphicEffect(item.item);
            } else if (selectedMenu === "collagehotspot") {
              EditorStore.setCollageHotspotEffect(item.item);
              props.setSelectedEffect(item);
            }
            item.item.zoom = 1;
            props.setIntensity(1);
            window.editor.applyEffect(item.item);
          } else {
            LeftMenuActionCreators.StickerFolderSelected(item);
          }
          break;
        case "border":
          LeftMenuActionCreators.StickerFolderSelected(item);
          break;
        case "background":
        case "design":
        case "facebook":
        case "collage":
          EditorDispatcher.dispatch({
            type: ActionTypes.FILE_PICKER_CATEGORY_SELECTED,
            data: item,
            itemType: type,
          });
          break;
        case "touchup":
          if (LeftMenuStore.get() !== "touchup") {
            LeftMenuActionCreators.MenuSelected("touchup");
            window.editor.setSelectedObject(null);
          }
          EditorDispatcher.dispatch({
            type: ActionTypes.TOUCHUP_TOOL,
            data: item,
          });
          Touchup.init(window.editor.getBaseObject().texture, item);
          break;
        default:
      }
    }

    if (props.selected) {
      props.selected(item);
    }
  };

  if (type === "background") proItem = null;

  return (
    <div
      className={`photopicker-list-item ${item.active ? "active" : ""}`}
      data-id={item.key}
      onClick={itemSelected}
    >
      {!imageLoaded && <SkeletonLoading width="70px" height="70px" />}
      <img
        src={item.icon}
        alt={item.icon}
        style={{
          display: imageLoaded ? "block" : "none",
          border: `${style}`,
        }}
        onLoad={handleImageLoad}
      />
      <span className="name">{item.name}</span>
      {item.isProOnly && !isPro && props.type !== "collage" && <ProCrown />}
    </div>
  );
};

export default MenuItem;
