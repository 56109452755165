import React, { useEffect, useMemo, useState, useCallback } from "react";
import AssetStore from "../stores/AssetStore";
import EditorStore from "../stores/EditorStore";
import MenuItemDetail from "./MenuItemDetail.react";
import Accordion from "./Accordion.react";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import { ReactComponent as DeleteIcon } from "../images/editor/delete_icon.svg";

function getStateFromStores() {
  let f = AssetStore.getBorderFolders();
  if (
    EditorStore.getEditorType() === "design" ||
    EditorStore.getEditorType() === "collage" ||
    (window.editor.getBaseObject() &&
      window.editor.getBaseObject().straightenScale)
  ) {
    f = f.filter(function (obj) {
      return obj.Name !== "Custom";
    });
  }
  return {
    folders: f,
    selectedItem: AssetStore.getSelectedItem(),
    selectedFolderItems: AssetStore.getBorderFolderDetails(),
    borderContainer: window.editor.getBorderContainer(),
  };
}

function getFolderDetailListItem(item) {
  return <MenuItemDetail key={item.key} item={item} type="border" />;
}

const MenuBorder = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    AssetStore.addChangeListener(_onChange);
    return () => {
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _onSubMenuClick = () => {
    LeftMenuActionCreators.StickerFolderSelected(null);
  };

  const _onRemove = () => {
    window.editor.getBorderContainer().removeChildren();
    if (EditorStore.getCustomBorder() === true) {
      window.editor.customBorderRemove();
    }
    AssetStore.emitChange();
  };

  const _onCategorySelected = (category) => {
    if (category && category.Name === "Custom") {
      LeftMenuActionCreators.MenuSelected("border_custom");
    } else {
      LeftMenuActionCreators.StickerFolderSelected(category);
    }
  };

  const subMenu = useMemo(() => {
    if (data.selectedItem && !data.selectedItem?.items) {
      return (
        <div onClick={_onSubMenuClick} className="clickable d-flex">
          <svg viewBox="0 0 960 560" transform="rotate(90)" height="16">
            <path
              className="st0"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          <span>{data.selectedItem.Name} Borders</span>
        </div>
      );
    } else return <span>Borders</span>;
  }, [data.selectedItem]);

  const folderListItems = useMemo(() => {
    if (data.selectedItem && !data.selectedItem?.items) {
      return (
        <>
          {(window.editor.getBorderContainer()?.children?.length > 0 ||
            EditorStore.getCustomBorder() === true) && (
            <div
              className="clickable remove-border-btn semi-clickable-button"
              onClick={_onRemove}
            >
              <DeleteIcon width={16} />
              &nbsp;Remove Border
            </div>
          )}
          {data.selectedFolderItems.map(getFolderDetailListItem)}
        </>
      );
    } else {
      return (
        <Accordion categories={data.folders} onSelected={_onCategorySelected} />
      );
    }
  }, [
    data.selectedItem,
    EditorStore.getCustomBorder(),
    window.editor.getBorderContainer()?.children?.length,
  ]);

  const borderContainerClasses = useCallback(() => {
    if (data.selectedItem && !data.selectedItem?.items) {
      return "scrollContainer borderContainer";
    } else {
      return "scrollContainer borderContainer borderMainMenu";
    }
  }, [data.selectedItem]);

  return (
    <div className="menu-container large" id="menu-container">
      <div className="menu-header">
        <div className="photos-nav">{subMenu}</div>
      </div>
      <div className={borderContainerClasses()}>{folderListItems}</div>
    </div>
  );
};

export default MenuBorder;
