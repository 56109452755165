import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PhotoEditorPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Photo Editor App | piZap | Photo Editor & Collage Maker App"
        keywords="photo, editor, app, online, photograph, picture, fonts, cut-out, stickers, collage, maker, free, opacity, alpha, edit, editing, adjustments, online, facebook, camera, digital, image, crop, fix, photoshop, gimp, photoshop"
        description="piZap Photo Editor App allows you to quickly and easily edit photos, make collages, create custom designs and more."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/photo_editor_app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">
            It's easy, It's Fun. It's piZap's Photo editor App
          </p>
          <p className="desc">
            You no longer need to be a Photoshop expert to create beautifully
            enhanced photos.
          </p>
          <StartButton text="Edit a Photo →" handleClick={handlePizap} />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/online_photo_editor_beachbike.jpg"
            alt="bg"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The easy photo editor app</span>
            <span className="desc">
              The piZap photo editor app literally zaps your pictures into
              perfection. It’s never been easier to create stunning photos using
              a variety of filters, stickers, and more. Made especially for
              ease-of-use, piZap’s online photo editor can be used on your
              computer or you can download the app to your smartphone or tablet.
              Plus, users receive FREE unlimited storage for edited photos.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Photo Enhancing Tools</span>
            <span className="desc">
              piZap offers a world of picture editing tools at your disposal.
              You can easily make basic edits like crop and rotate; adjust
              brightness, contrast, saturation and tint, as well as add cool
              lighting effects. There are SO many filters to choose from. Take
              your pick between classic filters or more loud and vibrant
              filters. Soft pastel or eye-popping neon, play up the colors and
              styles—we give you the platform to pick as you choose. Plus, our
              online photo editor app includes 1,000+ stickers for additional
              photo enhancing fun.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              The Easiest App to Edit All Your Photos
            </span>
            <span className="desc">
              piZap’s photo editing app makes editing photos fun for everyone.
              It can take years to learn how to use Adobe Photoshop to its full
              potential. We want everyone, from your mother to your grandmother,
              to be able to create picture-perfect edits that rival images
              professionally edited in Adobe Photoshop. That’s why we collected
              the latest and greatest photo editing tools and made them easily
              accessible to everyone via piZap.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Beautifully Fun</span>
            <span className="desc">
              We welcome you to add even more beauty to your pictures while
              having fun swiping and selecting your edits. Add text, doodles, or
              stickers to your photos to ramp up the fun or make people laugh
              and smile. Our sticker collections feature holiday themes, special
              letters, sparkles, hearts, and the list goes on. We have more
              stickers than you’ll find on any other photo editing app, along
              with a hefty selection of unique fonts for text boxes.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-04.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Endless Photo Editor App Features</span>
            <span className="desc">
              piZap’s photo editor app offers several features you might not
              expect, like FREE unlimited photo storage for all edited pics.
              Along with stickers and text boxes, you can add borders and frames
              or doodle directly on pics using integrated paint tools. We make
              it easy to use whatever editing tools you need when you need them.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PhotoEditorPage;
