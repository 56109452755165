import React, { useMemo, useState } from "react";
import Lockr from "lockr";
import AdminHeader from "../layouts/AdminHeader";
import { ReactComponent as IconSearch } from "../images/editor/search_icon.svg";

function AdminHomePage() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState(null);
  const accessToken = Lockr.get("access_token");
  const usernameChange = function (event) {
    setUsername(event.target.value);
  };

  const search = function (event) {
    setIsLoading(true);
    setMessage(null);
    fetch(
      `${process.env.REACT_APP_SERVER_API}/user/search/${encodeURI(username)}`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        setItems(res);
      })
      .catch((e) => {
        setIsLoading(false);
        setMessage("No Results found");
        setItems(null);
      });
  };

  const givePRO = function (event) {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER_API}/user/${encodeURI(username)}/givePRO`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);
        setItems(result);
      })
      .catch((e) => {
        setIsLoading(false);
        setMessage("Request failed");
        setError(error);
      });
  };
  const deleteUser = function (event) {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_API}/user/${encodeURI(username)}`, {
      method: "delete",
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        setMessage("User Deleted");
      })
      .catch((e) => {
        setIsLoading(false);
        setMessage("Request failed");
        setError(e);
      });
  };

  const passwordLink = function (event) {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVER_API}/user/change_password`, {
      method: "post",
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ username: username }),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);
        setMessage(
          "Url is https://www.pizap.com/reset_password?code=" +
            result.passwordRequest.id
        );
      })
      .catch((e) => {
        setIsLoading(false);
        setMessage("Request failed");
        setError(e);
      });
  };

  const results = useMemo(() => {
    return (
      <>
        {items && items.userName && (
          <div className="user-results">
            <h3>
              User Record for {items.name} ({items.userName})
            </h3>
            <div>
              <b>Email:</b> {items.email}
            </div>
            <div>
              <b>Created:</b> {new Date(items.created * 1000).toString()}
            </div>
            <div>
              <b>Number of Photos:</b> {items.numberPhotos}
            </div>
            <div>
              <b>PRO Billing Provider:</b> {items.probillingProvider}
            </div>
            <div>
              <b>PRO Plan:</b> {items.proplan}
            </div>
            <div>
              <b>PRO State:</b> {items.prostate}
            </div>
            <div>
              <b>PRO Activated:</b> {items.proactivated}
            </div>
            <div>
              <b>PRO Cancelled:</b> {items.procancelled}
            </div>
            <div className="button-group">
              <button onClick={givePRO} className="assign-btn">
                Give PRO Lifetime
              </button>
              <button onClick={deleteUser} className="delete-btn">
                Delete User
              </button>
              <button onClick={passwordLink} className="password-link-btn">
                Password Link
              </button>
            </div>
          </div>
        )}
      </>
    );
  }, [items]);

  return (
    <>
      {error ? (
        <>Error: {error?.message}</>
      ) : (
        <section className="admin-section banner banner-section">
          <AdminHeader />
          <div className="wrap">
            <div className="search">
              <input
                type="text"
                className="searchTerm"
                onChange={usernameChange}
                value={username}
                autoFocus
                placeholder="Search UserName, Email, Facebook ID"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    search();
                  }
                }}
              />
              <button type="submit" className="searchButton" onClick={search}>
                <IconSearch />
              </button>
            </div>
            {isLoading ? <div className="loading"></div> : results}
          </div>
          <h3 className="msg">{message}</h3>
        </section>
      )}
    </>
  );
}

export default AdminHomePage;
