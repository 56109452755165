import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const EyeColorPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Eye Color Changer App | Change Eye Color Photo Editor"
        description="Change your eye color in photos quickly and easily with our eye color changer app"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/eye_color_changer_app"
        keywords="Eye color changer app, change eye color, eye color touch up app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Eye Color Changer</p>
          <p className="desc">
            Change your eye color online—it’s quick, easy and fun!
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/eye_color_changer_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/eye_color_changer_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Eye Color Changer</span>
            <span className="desc">
              Have you ever wondered what you’d look like with a different eye
              color? Who hasn’t! piZap’s Eye Color Changer tool allows you to
              alter your eye color for an eye-opening experience.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              Eye Color Changer Tool: What It’s All About
            </span>
            <span className="desc">
              piZap is packed with photo enhancing effects, and the eye color
              changer is a leading fan favorite. The in-app tool lets you pick
              from a variety of beautiful eye color shades; easily swap between
              blue and green to see what looks best on you. The natural looking
              eye color changer is one of piZap’s many photo touch up tools. You
              can also remove blemishes, erase wrinkles, add airbrush makeup +
              more. Change eye color online or download the app for mobile
              devices.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/eye_color_changer_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/eye_color_changer_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Change Your Eye Color</span>
            <span className="desc">
              Changing eye color using Photoshop takes some skill and
              experience, but using piZap to change your eye color is easy for
              everyone. The carefully designed user interface is made for ease
              of use, allowing people of all ages and technical capabilities the
              chance to change eye color online quickly and easily.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Way to Change Your Eye Color</span>
            <span className="desc">
              Get ready to have a good time playing with different eye colors on
              you and all your friends. Save and share with friends and family
              to see what they think of you with different colored eyes. Better
              yet, change your friends’ eye colors and send them their surprise
              transformations. There are endless ways to have fun using piZap’s
              eye color changer.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/eye_color_changer_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/eye_color_changer_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Eye Color Changer + Many More Features</h3>
            </span>
            <span className="desc">
              Once you’re sporting your new eye color, piZap has plenty more
              photo enhancing tools ready for you to enjoy. Remove blemishes and
              wrinkles or whiten your teeth. Not to mention there are hundreds
              of fonts and text effects, plus thousands of stickers you can add
              to your photos. Use the MEME maker or make custom Emojis. piZap
              also offers tools to design and create logos and web graphics.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default EyeColorPage;
