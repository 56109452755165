import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const BusinessCardMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Business Card Maker - Business Card Designer"
        description="Our business card maker app lets you quickly and easily design your own custom business card"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/business_card_maker_app"
        keywords="business card maker, business card designer, create your own business card"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Leading Business Card Maker Tool</p>
          <p className="desc">
            Create your own business cards in a zap with piZap
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/business_card_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/business_card_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Leading Business Card Maker Tool</span>
            <span className="desc">
              piZap is the business card maker for busy people like you. It’s
              simple, it’s fun, and yes, you can really make professional
              looking business cards without any prior design experience. All
              thanks to piZap’s wide variety of editing and design tools that
              are easy to use, including a logo maker!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Business Card Maker Tool </span>
            <span className="desc">
              piZap is packed with features that facilitate its reputation as
              one of the best DIY Business Card Makers. Select your business
              card template and enjoy the flexibility of making your creation
              any size or shape you see fit. Additional in-app design tools kick
              your business card up to the next level. For instance, you can
              easily create logos and web graphics with transparent backgrounds.
              Plus, look to thousands of stickers and hundreds of font styles
              and text effects to create the look you’re envisioning.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/business_card_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/business_card_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Business Card Maker Made Easy </span>
            <span className="desc">
              Are you worried that you won’t have the skills to create
              professional looking business cards? What about logos or text
              boxes—how will you create those? Rest assured, piZap is loaded
              with the features you need to make stunning custom business cards
              for all industries, professions, and purposes. Best of all,
              piZap’s fancy features are easily accessible via the click of a
              button. You’ll feel like a professional business card maker in no
              time using piZap.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Business Card Maker </span>
            <span className="desc">
              piZap’s Business Card Maker is comparable to a mullet. What? Let
              us explain… it looks professional from the front but it’s a lot of
              fun in the back, aka during the creation process. The fun begins
              with the fact it’s FINALLY easy to create stunning effects. So fun
              in fact, whilst rare, it is possible to become addicted to making
              your own business cards—don’t say we didn’t warn you!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/business_card_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/business_card_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Create Your Own Business Card + More</h3>
            </span>
            <span className="desc">
              Did somebody say logo maker? You heard right! piZap is the
              Business Card Maker with much more to offer, including the ability
              to easily create web graphics and logos with transparent
              backgrounds. Plus, its packed with photo editing and design tools
              that’ll take your creations to the next level. With stellar
              business cards in hand, piZap can continue to help you expand your
              reach. Use piZap to edit photos that capture attention or create
              flyers advertising specials and sales; make a funny MEME that gets
              your social media followers sharing. Access all this and more on
              one app--piZap!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default BusinessCardMakerPage;
