import React from "react";
import EditorStore from "../stores/EditorStore";

const StartMenu = () => {
  const _onNewEdit = (event) => {
    EditorStore.setEditorType("edit");
  };

  const _onNewCollage = (event) => {
    if (EditorStore.isElectron()) {
      window.location.href = "./app.html?type=collage";
    } else {
      window.location.href = "./index.html?type=collage";
    }
  };

  const _onNewDesign = (event) => {
    if (EditorStore.isElectron()) {
      window.location.href = "./app.html?type=design";
    } else {
      window.location.href = "./index.html?type=design";
    }
  };

  return (
    <div className="filepicker">
      <div id="filepicker-dialog" className="filepicker-dialog active">
        <div className="startmenu-dialog-box">
          <div className="filepicker-navbar">
            <div className="filepicker-title">Select Edit Type</div>
          </div>

          <div id="filepicker-sources-list" className="filepicker-list">
            <div onClick={_onNewEdit} className="start-menu-item clickable">
              <svg viewBox="0 0 164.8 164.8" height="60">
                <path
                  className="a"
                  d="M41.8,71.2c0,3.2,1.1,5.9,3.4,8.1c2.3,2.3,5,3.4,8.1,3.4c3.2,0,5.9-1.1,8.1-3.4c2.3-2.2,3.4-4.9,3.4-8.1
                            c0-3.2-1.1-5.9-3.4-8.1c-2.2-2.2-4.9-3.4-8.1-3.4c-3.2,0-5.9,1.1-8.1,3.4C43,65.3,41.8,68,41.8,71.2 M76.8,126.2l-23-24.5
                            l-29.3,37.8h120.3l-38.3-47.3L76.8,126.2z"
                />
                <path className="d" d="M5.1,5.1h154.6v154.6H5.1V5.1z" />
              </svg>
              <span>Edit</span>
            </div>
            <div onClick={_onNewCollage} className="start-menu-item clickable">
              <svg viewBox="0 0 164.8 164.9" height="60">
                <path
                  className="a"
                  d="M163.3,1.5c-1-1-2.2-1.5-3.6-1.5H5.1C3.7,0,2.5,0.5,1.5,1.5C0.5,2.5,0,3.7,0,5.1v154.6
                            c0,1.4,0.5,2.6,1.5,3.6c1,1,2.2,1.5,3.6,1.5h154.6c1.4,0,2.6-0.5,3.6-1.5c1-1,1.5-2.2,1.5-3.6V5.1C164.8,3.7,164.3,2.5,163.3,1.5
                             M154.6,10.2v69.7h-45V10.2H154.6 M154.6,84.9v69.8H59.8V84.9H154.6 M10.2,79.9V10.2h94.4v69.7H10.2 M54.8,84.9v69.8H10.2V84.9
                            H54.8z"
                />
              </svg>
              <span>Collage</span>
            </div>
            <div onClick={_onNewDesign} className="start-menu-item clickable">
              <svg viewBox="0 0 164.8 164.9" height="60">
                <path
                  className="a"
                  d="M10.2,138.3H0v21.5c0,1.4,0.5,2.6,1.5,3.6c1,1,2.2,1.5,3.6,1.5h22.6v-10.2H10.2V138.3 M41.4,154.6v10.2h34.8
                            v-10.2H41.4 M10.2,90H0v33.8h10.2V90 M10.2,41.3H0v34h10.2V41.3 M27.6,0H5.1C3.7,0,2.5,0.5,1.5,1.5C0.5,2.5,0,3.7,0,5.1v20.4
                            h10.2V10.2h17.4V0 M76.2,0H41.4v10.2h34.8V0 M123.9,164.9v-10.2H89.4v10.2H123.9 M164.8,138.3h-10.2v16.4h-17.9v10.2h23
                            c1.4,0,2.6-0.5,3.6-1.5c1-1,1.5-2.2,1.5-3.6V138.3 M123.9,10.2V0H89.4v10.2H123.9 M164.8,90h-10.2v33.8h10.2V90 M164.8,41.3
                            h-10.2v34h10.2V41.3 M159.7,0h-23v10.2h17.9v15.3h10.2V5.1c0-1.4-0.5-2.6-1.5-3.6C162.3,0.5,161.1,0,159.7,0z"
                />
                <path
                  className="a"
                  d="M115.6,50.2V69c0,1.3,0.3,2.3,1,2.8c0.6,0.5,1.5,0.8,2.6,0.8s2-0.3,2.6-0.8c0.6-0.5,0.9-1.5,0.9-2.8V44.8
                            H42.1V69c0,1.3,0.3,2.3,1,2.8c0.7,0.5,1.6,0.8,2.6,0.8c1.1,0,2-0.3,2.8-0.8c0.6-0.5,0.9-1.5,0.9-2.8V50.2h29.4v64.4H60.1
                            c-1.7,0-2.9,0.3-3.6,0.8c-0.8,0.5-1.1,1.1-1.1,1.9c0,0.8,0.4,1.4,1.1,1.9c0.7,0.5,1.9,0.7,3.6,0.8h44.6c1.8,0,3-0.3,3.8-0.8
                            c0.7-0.5,1.1-1.1,1.1-1.9c0-0.8-0.4-1.5-1.1-1.9c-0.7-0.5-2-0.8-3.8-0.8H86.1V50.2H115.6z"
                />
              </svg>
              <span>Design</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartMenu;
