import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserStore from "../stores/UserStore";
import { isIE, isLegacyEdge } from "./utils";

const AdminRoute = ({ children }) => {
  const user = UserStore.getUser();
  if (isIE() || isLegacyEdge()) {
    return <Navigate to="/app/browser" replace />;
  }
  if (!user) {
    return <Navigate to="/app/login" replace />;
  } else if (!user.isAdmin) {
    return <Navigate to="/" replace />;
  }
  return children ? children : <Outlet />;
};

export default AdminRoute;
