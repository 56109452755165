import React from "react";
import { useNavigate } from "react-router-dom";

const WelcomeProModal = (props) => {
  const navigate = useNavigate();

  return (
    <div className="filepicker-dialog active">
      <div className="welcome-pro-modal" align="center">
        <div className="modal-header"></div>
        <div className="modal-body">
          <span>
            Welcome to piZap PRO! Get ready to unleash your creativity with our
            super fun and easy-to-use editing tools. Dive into a world of
            endless possibilities, where your imagination is the only limit.
            Let's make some magic together!
          </span>
        </div>
        <div className="modal-footer">
          <div
            className="clickable confirm-btn"
            onClick={() => {
              props.setShowWelcome(false);
            }}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeProModal;
