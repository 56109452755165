import React, { useEffect, useState, useMemo } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import UserLeftMenu from "./UserLeftMenu.react";
import Gallery from "react-grid-gallery";
import ThumbOverlay from "./ThumbOverLay.react";
import ConfirmDialog from "./ConfirmDialog.react";
import TagDialog from "./TagDialog.react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../layouts/Header";
import { ReactComponent as IconDelete } from "../images/editor/delete_icon.svg";
import HelmetDetails from "./HelmetDetails";
import Login from "./Login.react";
import ProGet from "./ProGet.react";
import ProGetSave from "./ProGetSave.react";
import ProTeaser from "./ProTeaser.react";
import IconEmpty from "../images/editor/empty-folder.png";
import { toast, Flip } from "react-toastify";

function getStateFromStores() {
  return {
    photos: UserStore.getPhotos(),
    cutouts: UserStore.getCutouts(),
    projects: UserStore.getProjects(),
    folders: UserStore.getFolders(),
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showTagDialog: UserStore.getShowTagDialog(),
  };
}

const Folders = () => {
  const [data, setData] = useState(getStateFromStores());
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { folder } = useParams();

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    if (UserStore.getUser() === null) {
      UserActionCreators.updateUserData(UserStore.getAccessToken());
    }
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, [folder]);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _onDelete = () => {
    setShowConfirmDialog(true);
    UserStore.emitChange();
  };

  const _onDeleteCancel = () => {
    setShowConfirmDialog(false);
    UserStore.emitChange();
  };

  const _onDeleteAccept = () => {
    setShowConfirmDialog(false);
    UserActionCreators.deleteFolder(folder);
    setTimeout(() => {
      toast.success("Item Deleted!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip,
      });
      navigate("/app/start");
    }, 50);
  };

  let images = [];

  if (data.photos != null) {
    data.photos.forEach((photo) => {
      if (photo.folder === folder) {
        photo.customOverlay = <ThumbOverlay photo={photo} />;
        images.push(photo);
      }
    });
  }
  if (data.cutouts != null) {
    data.cutouts.forEach((photo) => {
      if (photo.folder === folder) {
        photo.customOverlay = <ThumbOverlay cutout={photo} />;
        images.push(photo);
      }
    });
  }
  if (data.projects != null) {
    data.projects.forEach((photo) => {
      if (photo.folder === folder) {
        photo.customOverlay = <ThumbOverlay project={photo} />;
        images.push(photo);
      }
    });
  }

  const loginDialog = useMemo(() => {
    if (data.showLogin) {
      return <Login />;
    }
    if (data.showProGetSave) {
      return <ProGetSave />;
    }
    if (data.showProGet) {
      return <ProGet />;
    }
    if (data.showPro) {
      return <ProTeaser />;
    }
    if (data.showTagDialog) {
      return <TagDialog />;
    }
  }, [data]);

  return (
    <div className="startPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl={`https://www.pizap.com/app/folders/${folder}`}
        keywords=""
      />
      <Header />
      <div className="container" id="container">
        {loginDialog}
        {showConfirmDialog && (
          <ConfirmDialog
            confirmMessage="Are you sure you want to delete this folder? It will not delete the projects, just the folder."
            acceptLabel="Delete"
            cancelFunc={_onDeleteCancel}
            acceptFunc={_onDeleteAccept}
          />
        )}

        {images?.length > 0 ? (
          <div className="exportGallery">
            <Gallery
              images={images}
              enableImageSelection={false}
              enableLightbox={false}
              rowHeight={140}
            />
          </div>
        ) : (
          <img className="empty-folder" src={IconEmpty} />
        )}

        <div
          className="folder-delete clickable st0"
          onClick={_onDelete}
          title="Remove"
        >
          <IconDelete height={16} />
          <span>Delete Folder</span>
        </div>
      </div>
      <UserLeftMenu selected={folder} />
    </div>
  );
};

export default Folders;
