import React, { useEffect, useState } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";

function getStateFromStores() {
  return {
    user: UserStore.getUser(),
  };
}

const ProGetSave = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const onClose = () => {
    EditorActionCreators.ShowSaveQuality(true);
  };
  const _startTrial = () => {
    UserActionCreators.showPro(true);
  };

  const _signin = (event) => {
    UserActionCreators.showLogin(true);
  };

  return (
    <div className="filepicker-dialog active">
      <div className="pro-get-dialog active pro-get-save">
        <div className="inner-container" data-type-id="102">
          <div>
            <img
              className="logo"
              src="https://static.pizap.com/html5/resources/editor/pizap-pro-logo.png"
              width="150"
              alt=""
            />
          </div>
          <div className="colorBar">
            <div className="orange" />
            <div className="yellow" />
            <div className="green" />
            <div className="lightBlue" />
            <div className="blue" />
          </div>
          <div className="largeHeading">
            <span>Get piZap Pro, free</span>
          </div>
          <div className="normalText">
            <span>
              Unlock hundreds of fonts, filters, stickers, borders, stock images
              and more. Try it free for 7 days.
            </span>
          </div>
          <div className="freeTrialButton">
            <button className="clickable" onClick={_startTrial}>
              Start 7-day free trial
            </button>
          </div>
          {(!data.user || !data.user.UserName) && (
            <div className="normalText">
              <span>Already a Pro user? </span>
              <span className="clickable" onClick={_signin}>
                Log In
              </span>
            </div>
          )}
        </div>
        <div className="store-close-btn clickable" onClick={onClose}>
          ×
        </div>
      </div>
    </div>
  );
};

export default ProGetSave;
