import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const TwitterCoverMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Twitter Header Maker App | Create a Custom Twitter Cover"
        description="Easily create a custom Twitter header cover with our Twitter header maker app"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/twitter_cover_maker_app"
        keywords="Twitter header, maker, creator, designer, cover app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Twitter Header Maker</p>
          <p className="desc">
            Improve your Twitter profile with the perfect Twitter cover photo
          </p>
          <StartButton
            text="Edit a photo →"
            handleClick={() => {
              navigate("/app/?type=edit");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/twitter_cover_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/twitter_cover_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Twitter Header Maker</span>
            <span className="desc">
              Your Twitter Cover Photo is one of first things people see when
              they click on your profile page. First impressions count,
              especially in the virtual world of social media. piZap can help
              you stand out from millions of other Twitter accounts thanks to
              the Twitter Header Maker tool.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Tool Overview: Twitter Header Maker</span>
            <span className="desc">
              Use piZap’s Twitter Cover Maker tool to crop photos for the
              perfect cover photo every time. Twitter cover photo size measures
              1500px by 500px. If you try and upload a different size photo,
              well, you know what happens. You end up with a crunched, blurry,
              or poorly cropped mess. piZap’s Twitter Header Maker feature is
              here to save you! It’s hard not to get excited just thinking about
              the wide range of Twitter cover photos—from beautifully serene to
              wildly wonderful—you can make using piZap.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/twitter_cover_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/twitter_cover_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              The Easy Way to Make Twitter Cover Photos
            </span>
            <span className="desc">
              Let’s face it, most of us already spend too much time looking at
              screens each day. There’s no need to spend even more time trying
              to make your Twitter cover photo look good. Simplify the process
              by uploading your photo into piZap’s Twitter Header Maker and
              watch your photo automatically adjust to fit perfectly. To make it
              even easier, you can share your cover photo directly to Twitter
              without leaving the app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Have Fun with Your Twitter Header</span>
            <span className="desc">
              Getting new followers because your Twitter cover photo is that
              good = fun! piZap’s Twitter Header Maker takes a social media
              chore and turns it into an excuse to have some fun. Do you change
              your Twitter header photo all the time, or once in a blue moon?
              Chances are, after trying piZap’s Twitter Header Maker, you’ll be
              changing your cover photo more frequently—it’s really that easy
              and fun!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/twitter_cover_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/twitter_cover_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Explore Countless Features Using the Twitter Header Maker</h3>
            </span>
            <span className="desc">
              piZap doesn’t just adjust photos to fit Twitter’s cover photo, it
              offers so much more. With just one click you can swap over to
              editing tools and give your cover photo a revived look; change the
              filter or saturation, make it black and white, or touch up flaws.
              Did we mention you can cut any person or thing out of one picture
              and insert it into another using one of piZap’s many in-app tools?
              Plus, free user accounts come with free storage for all your photo
              edits, collages, MEMEs, and more!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default TwitterCoverMakerPage;
