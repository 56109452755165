import React, { useEffect, useMemo, useState } from "react";
import AssetStore from "../stores/AssetStore";
import EditorStore from "../stores/EditorStore";
import MenuItem from "./MenuItem.react";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import touchup from "../editor/touchup";
import ColorPicker from "./ColorPicker.react";
import RangeSlider from "./RangeSlider";

function getStateFromStores() {
  return {
    folders: AssetStore.getTouchupFolders(),
    selectedItem: AssetStore.getSelectedItem(),
    touchupTool: EditorStore.getTouchupTool(),
    brushSize: EditorStore.getTouchupBrushSize(),
    brushHardness: EditorStore.getTouchupBrushHardness(),
    fade: EditorStore.getTouchupFade(),
    color: EditorStore.getTouchupColor(),
  };
}

function getFolderDetailListItem(item) {
  return <MenuItem key={item.name} item={item} type="touchup" />;
}

const MenuTouchup = () => {
  let isMounted = false;

  const [storesData, setStoresData] = useState(getStateFromStores());

  const onKeyUp = (event) => {
    event.preventDefault();
    if (event.keyCode === 13 && storesData.touchupTool) {
      _onApply(event);
    }
  };

  useEffect(() => {
    isMounted = true;
    document.addEventListener("keyup", onKeyUp);
    AssetStore.addChangeListener(_onChange);
    EditorStore.addChangeListener(_onChange);
    return () => {
      isMounted = false;
      document.removeEventListener("keyup", onKeyUp);
      AssetStore.removeChangeListener(_onChange);
      EditorStore.removeChangeListener(_onChange);
      touchup.onCancel();
    };
  }, []);

  const _onChange = () => {
    if (isMounted) setStoresData(getStateFromStores());
  };

  const _onBrushSizeChange = (value) => {
    const size = parseInt(value, 10);
    EditorActionCreators.TouchupBrushSize(size);
    touchup.setBrushSize(size);
  };

  const _onBrushHardnessChange = (value) => {
    const size = parseFloat(value);
    EditorActionCreators.TouchupHardness(size);
    touchup.setHardness(size);
  };

  const _onFadeChange = (value) => {
    const size = parseFloat(value);
    EditorActionCreators.TouchupFade(size);
    touchup.setFade(size);
  };

  const _onCancel = (event) => {
    LeftMenuActionCreators.MenuSelected("touchup");
    touchup.onCancel();
  };

  const _onApply = (event) => {
    EditorStore.setShowSpinner(true);
    LeftMenuActionCreators.MenuSelected("touchup");
    touchup.onSave();
  };

  const onColorClick = (event) => {
    EditorActionCreators.TouchupColor(
      event.currentTarget.attributes["data-color"].value
    );

    touchup.updateColor(event.currentTarget.attributes["data-color"].value);
  };

  const colorPicked = (color) => {
    EditorActionCreators.TouchupColor(color);
    touchup.updateColor(color);
  };

  const handleClickSubMenu = (e) => {
    e.preventDefault();
    if (EditorStore.getEditorType() === "design") {
      LeftMenuActionCreators.MenuSelected("design");
    } else {
      LeftMenuActionCreators.MenuSelected("main");
    }
  };

  const toolName = useMemo(() => {
    if (storesData.touchupTool) {
      return (
        <div
          className="d-flex"
          style={{
            justifyContent: "flex-start",
            cursor: "pointer",
          }}
          onClick={_onCancel}
        >
          <svg viewBox="0 0 960 560" transform="rotate(90)" height={18}>
            <path
              className="a"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          {storesData.touchupTool.name}
        </div>
      );
    } else {
      return (
        <div
          className="d-flex clickable"
          style={{
            justifyContent: "flex-start",
          }}
          onClick={handleClickSubMenu}
        >
          <svg viewBox="0 0 960 560" transform="rotate(90)" height={14}>
            <path
              className="st0"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          Touchup
        </div>
      );
    }
  }, [storesData.touchupTool]);

  const folderListItems = useMemo(() => {
    if (storesData?.touchupTool) {
      return (
        <div>
          <RangeSlider
            title="Brush Size"
            min={8}
            max={100}
            step={1}
            value={storesData.brushSize}
            handleChange={_onBrushSizeChange}
            handleReset={() => {}}
            isReset={false}
            isBox={false}
            handleMouseEnter={() => {}}
            handleMouseLeave={() => {}}
            startPoint={8}
          />
          <RangeSlider
            title="Brush Hardness"
            min={1}
            max={15}
            step={0.1}
            value={storesData.brushHardness}
            handleChange={_onBrushHardnessChange}
            handleReset={() => {}}
            isReset={false}
            isBox={false}
            handleMouseEnter={() => {}}
            handleMouseLeave={() => {}}
            startPoint={1}
          />
          <RangeSlider
            title="Fade"
            min={0}
            max={1}
            step={0.01}
            value={storesData.fade}
            handleChange={_onFadeChange}
            handleReset={() => {}}
            isReset={false}
            isBox={false}
            handleMouseEnter={() => {}}
            handleMouseLeave={() => {}}
            startPoint={0}
          />
          <div className="colorContainer">
            <ColorPicker
              iconClass="brush-color"
              text="Color"
              onColor={colorPicked}
              initialColor={storesData.color}
              opacity={1}
            />
            {storesData.touchupTool?.colors && (
              <div className="colorPanels">
                {storesData.touchupTool.colors.map((item, index) => {
                  return (
                    <div
                      className="colorPanel clickable"
                      style={{
                        backgroundColor: item,
                      }}
                      onClick={onColorClick}
                      data-color={item}
                      key={index}
                    ></div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="space-between">
            <button
              className="btn touchupCancelBtn"
              content="CANCEL"
              onClick={_onCancel}
            >
              Cancel
            </button>
            <button
              className="btn touchupApplyBtn my-btn"
              content="APPLY"
              onClick={_onApply}
            >
              Apply
            </button>
          </div>
        </div>
      );
    } else {
      return storesData.folders.map(getFolderDetailListItem);
    }
  }, [storesData]);

  return (
    <div className="menu-container large" id="menu-container">
      <div className="menu-header">
        <div style={{ lineHeight: '2.05'}}>{toolName}</div>
      </div>
      <div className="touchupContainer">{folderListItems}</div>
    </div>
  );
};

export default MenuTouchup;
