import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import React from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import Lockr from "lockr";

const FontCategory = (props) => {
  const isPro = UserStore.isPro();

  const itemSelected = (event) => {
    if (props.item.isProOnly && !isPro) {
      UserActionCreators.showProGet(true);
    } else {
      LeftMenuActionCreators.FontCategorySelected(props.item);
      Lockr.set("fontCategory", props.item.key);
    }
  };

  const onHover = (event) => {
    event.currentTarget.src =
      event.currentTarget.attributes["data-hover"].value;
  };

  const onMouseOut = (event) => {
    event.currentTarget.src = event.currentTarget.attributes["data-src"].value;
  };

  let proItem = null;
  if (props.item.isProOnly && !isPro) {
    proItem = (
      <img
        src="https://static.pizap.com/html5/resources/editor/pizap_pro.svg"
        className="pro-overlay"
        alt="Pizap Pro"
      />
    );
  }

  return (
    <div
      className={
        props.selected
          ? "font-category clickable selected"
          : "font-category clickable"
      }
      data-id={props.item.key}
      onClick={itemSelected}
    >
      <img
        src={props.item.icon}
        data-hover={props.item.icon.replace(".png", "-over.png")}
        data-src={props.item.icon}
        onMouseOver={onHover}
        onMouseOut={onMouseOut}
        alt="Not Found"
      />
      {proItem}
    </div>
  );
};

export default FontCategory;
