import React, { useEffect, useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import traceCutoutImage from "../images/editor/cutout-trace.jpg";
import shapeCutoutImage from "../images/editor/cutout-shapes.jpg";
import aiCutoutImage from "../images/editor/cutout-ai.jpg";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const CutoutStyle = () => {
  const [mode, setMode] = useState(EditorStore.getCutoutMode());

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setMode(EditorStore.getCutoutMode());
  };

  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutStyle(false);
  };

  const _onCustom = (event) => {
    setMode(EditorStore.getCutoutMode());
    EditorActionCreators.CutoutMode("crop");
  };
  const _onShape = (event) => {
    setMode(EditorStore.getCutoutMode());
    EditorActionCreators.CutoutMode("shapes");
  };
  const _onAutomatic = (event) => {
    let isPro = UserStore.isPro();
    if (!isPro) {
      UserActionCreators.showProGet(true);
    } else {
      setMode(EditorStore.getCutoutMode());
      EditorActionCreators.CutoutMode("automatic");
    }
  };
  const _onNext = () => {
    if (EditorStore.getGraphicCutoutStyle()) {
      if (!window.editor.getSelectedObject().sourceUrl) return;
      EditorActionCreators.ShowFilePicker(false);
      window.editor.setTempSelectedObject(window.editor.getSelectedObject());
      window.editor.addPicture(
        window.editor.getSelectedObject().sourceUrl,
        null
      );
    } else {
      EditorActionCreators.ShowFilePicker(true, "cutout");
    }
  };

  return (
    <div className="dialog-background">
      <div className="cutout-dialog-box cutout-style-dialog">
        <div className="cutout-title">
          <div className="cutout-title">Choose a Cutout Method</div>
          <div className="close-btn" onClick={_cancelClick}>
            <CloseSVG height={12} />
          </div>
        </div>
        <div className="cutout-style-container">
          <div className="cutout-style">
            <img
              src={traceCutoutImage}
              onClick={_onCustom}
              alt="trace cutout"
            />
            <label htmlFor="rdo-1" className="btn-radio">
              <input
                type="radio"
                checked={mode === "crop"}
                onChange={_onCustom}
                id="rdo-1"
                name="radio-grp"
              />
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <circle cx="10" cy="10" r="9"></circle>
                <path
                  d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                  className="inner"
                ></path>
                <path
                  d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                  className="outer"
                ></path>
              </svg>
              <span>&nbsp;&nbsp;Trace Outline</span>
            </label>
            <span className="desc">Full Control, manual</span>
          </div>
          <div className="cutout-style">
            <img src={shapeCutoutImage} onClick={_onShape} alt="shape cutout" />
            <label htmlFor="rdo-2" className="btn-radio">
              <input
                type="radio"
                id="rdo-2"
                name="radio-grp"
                checked={mode === "shapes"}
                onChange={_onShape}
              />
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <circle cx="10" cy="10" r="9"></circle>
                <path
                  className="inner"
                  d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                ></path>
                <path
                  d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                  className="outer"
                ></path>
              </svg>
              <span>&nbsp;&nbsp;Shapes</span>
            </label>
          </div>
          <div className="cutout-style">
            <img src={aiCutoutImage} onClick={_onAutomatic} alt="ai coutout" />
            <label htmlFor="rdo-3" className="btn-radio">
              <input
                type="radio"
                id="rdo-3"
                name="radio-grp"
                checked={mode === "automatic"}
                onChange={_onAutomatic}
              />
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <circle cx="10" cy="10" r="9"></circle>
                <path
                  d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                  className="inner"
                ></path>
                <path
                  d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                  className="outer"
                ></path>
              </svg>
              <span>&nbsp;&nbsp;A.I. Magic!&nbsp;&nbsp;</span>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                style={{ marginTop: "-6px" }}
                viewBox="0 0 1500 1080"
                enableBackground="new 0 0 1500 1080"
                height="15"
              >
                <g transform="matrix( 8.704086303710938, 0, 0, 8.704086303710938, 109.4,28) ">
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Symbol_1_0_Layer0_0_FILL">
                        <path
                          fill="#FFC200"
                          d="M19.4,112.5c0,1.4,0.5,2.7,1.5,3.7s2.2,1.5,3.6,1.5h98.2c1.4,0,2.7-0.5,3.7-1.5s1.5-2.2,1.5-3.7
					s-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5H24.5c-1.4,0-2.6,0.5-3.6,1.5C19.9,109.9,19.4,111.1,19.4,112.5 M127.9,40.3
					c0,2.7,0.9,4.9,2.8,6.8c0.1,0.1,0.2,0.2,0.2,0.2l-30.2,16L76.9,18.8c1.3-0.5,2.5-1.3,3.6-2.3c1.9-1.9,2.8-4.1,2.8-6.8
					s-0.9-4.9-2.8-6.8c-0.4-0.4-0.8-0.7-1.2-1C77.7,0.6,75.8,0,73.7,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.7,1-6.5,2.8
					C65,4.7,64.1,7,64.1,9.6s0.9,4.9,2.8,6.8c1,1,2.2,1.8,3.5,2.2l-24,44.7l-30.1-16c0.1-0.1,0.1-0.2,0.2-0.2
					c1.9-1.9,2.8-4.1,2.8-6.8c0-2.7-0.9-5-2.8-6.8c-1.9-1.9-4.1-2.8-6.8-2.8c-0.8,0-1.5,0.1-2.2,0.2c-1.7,0.4-3.3,1.2-4.6,2.5
					c-1.5,1.5-2.4,3.3-2.7,5.4C0,39.4,0,39.8,0,40.3c0,2.7,0.9,4.9,2.8,6.8C4.7,49,7,50,9.6,50c0.6,0,1.2,0,1.8-0.2l10.2,49.3h104
					l10.2-49.3c0.6,0.1,1.1,0.2,1.8,0.2c2.7,0,4.9-1,6.8-2.8c1.9-1.9,2.8-4.1,2.8-6.8c0-0.5,0-1-0.1-1.4c-0.3-2.1-1.2-3.9-2.7-5.4
					c-1.4-1.3-2.9-2.2-4.6-2.5c-0.7-0.2-1.4-0.2-2.2-0.2c-2.7,0-4.9,0.9-6.8,2.8C128.8,35.4,127.9,37.7,127.9,40.3z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </label>
            <span className="desc">Automatic</span>
          </div>
        </div>
        <button className="btn my-btn cutoutFinishBtn" onClick={_onNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CutoutStyle;
