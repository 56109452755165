import React from "react";

const CarouselButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      <button
        className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--left`}
        onClick={() => goToSlide(currentSlide - 2)}
      ></button>
      <button
        className={`react-multiple-carousel__arrow react-multiple-carousel__arrow--right`}
        onClick={() => goToSlide(currentSlide + 2)}
      ></button>
    </div>
  );
};

export default CarouselButtonGroup;
