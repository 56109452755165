import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonItem = () => {
  const viewWidth = window.visualViewport.width - 310;
  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
      <SkeletonTheme
        baseColor="#222429"
        width={viewWidth / 7}
        height={140}
        highlightColor="#262b33"
      >
        <Skeleton count={1} />
      </SkeletonTheme>
    </div>
  );
};

export default SkeletonItem;
