import React, { useEffect, useRef, useState, useMemo } from "react";
import AssetStore from "../stores/AssetStore";
import MenuDesignItem from "./MenuDesignItem.react";
import EditorActionCreators from "../actions/EditorActionCreators";
import Dexie from "dexie";
import LeftMenuStore from "../stores/LeftMenuStore";
import { Link } from "react-router-dom";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

function getStateFromStores() {
  return {
    folderItems: AssetStore.getDesignFolderDetails(),
  };
}

function getFolderItemState(items) {
  return items.map(getDesignFolderItem);
}

function getDesignFolderItem(item) {
  return <MenuDesignItem key={item.key} item={item} type="design" />;
}

const DesignPicker = () => {
  const [data, setData] = useState(getStateFromStores());
  const [initState, setInitState] = useState(false);
  const filesRef = useRef();

  useEffect(() => {
    AssetStore.addChangeListener(_onChange);
    let db = new Dexie("pizapProjects");
    db.version(1).stores({
      projects: "id,created,createdBy, mode",
    });
    db.open();
    db.projects
      .where("id")
      .equals("latestProject")
      .each(function (p) {
        // project = p;
        LeftMenuStore.emitChange();
      });

    return () => {
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _cancelClick = (event) => {
    event.preventDefault();
    AssetStore.setSelectedItem(null);
    EditorActionCreators.ShowDesignPicker(false);
  };

  const _onChange = () => {
    if (
      !initState &&
      AssetStore.getDesignFolders() &&
      AssetStore.getDesignFolders().length > 0
    ) {
      setInitState(true);
      let item = AssetStore.getDesignFolders()[0];
      let i = { name: item.Name, key: item.Name, isProOnly: item.PROOnly };
      AssetStore.setSelectedItem(i);
    }
    if (AssetStore.getDesignFolderDetails() !== data.folderItems) {
      if (filesRef) {
        let node = filesRef.current;
        if (node) {
          node.scrollTop = 0;
        }
      }
    }
    setData(getStateFromStores());
  };

  // const _restoreClick = (event) => {
  //     window.editor.import(JSON.stringify(project));
  //     EditorActionCreators.ShowFilePicker(false);
  //     EditorActionCreators.ShowDesignPicker(false);
  // }

  const closeBtn = useMemo(() => {
    if (window.editor.getRenderer()) {
      return (
        <div className="close-btn" onClick={_cancelClick}>
          <CloseSVG height={12} />
        </div>
      );
    } else {
      return (
        <Link id="photopicker-cancel-btn" className="close-btn" to="/app/start">
          <CloseSVG height={12} />
        </Link>
      );
    }
  }, [window.editor.getRenderer()]);

  return (
    <div className="photopicker designpicker" id="photopicker">
      <div id="photopicker-dialog" className="photopicker-dialog">
        <div className="designpicker-dialog-box">
          <div className="photopicker-navbar">
            <div className="photopicker-title">Select a canvas size</div>
            {closeBtn}
          </div>

          <div id="photopicker-container">
            <div
              id="designpicker-album-files"
              ref={filesRef}
              className="photopicker-photos"
            >
              <div id="scroll-content">
                {getFolderItemState(data.folderItems)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignPicker;
