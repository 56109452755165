import React, { useState, useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useDropzone } from "react-dropzone";
import MaterialTable, { MTableToolbar } from "material-table";
import Paper from "@material-ui/core/Paper";
import AdminHeader from "../layouts/AdminHeader";
import UserStore from "../stores/UserStore";
import { Delete, Edit } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Autocomplete,
} from "@mui/material";
import AssetStore from "../stores/AssetStore";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import PatchedPagination from "../components/admin/PatchedPagination";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const getStateFromStores = () => {
  return {
    graphics: AssetStore.getGraphics(),
  };
};

const AdminGraphicsPage = () => {
  const [data, setData] = useState(getStateFromStores());
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [tags, setTags] = useState("pizapPRO");
  const [type, setType] = useState("Sticker");
  const tableRef = useRef();
  const classes = useStyles();
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(20);
  const [selectedRowsOnPage, setSelectedRowsOnPage] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (data.graphics === null) {
        AssetActionCreators.loadGraphics();
      }
      AssetStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    if (data.graphics) {
      setIsLoading(false);
    } else setIsLoading(true);
  }, [data.graphics]);

  const handleTagDelete = (chipToDelete, tag) => () => {
    data.graphics.forEach((item) => {
      if (item.url === chipToDelete.url) {
        item.tags = item.tags.filter((chip) => chip !== tag);
        fetch(
          `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
            item.url
          )}`,
          {
            method: "PUT",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
              "content-type": "application/json",
            }),
            body: JSON.stringify(item),
          }
        ).then(() => {
          console.log("resolved");
        });
      }
    });
  };
  const handleFeatureDelete = (chipToDelete, feature) => () => {
    data.graphics.forEach((item, index) => {
      if (item.url === chipToDelete.url) {
        item.features = item.features.filter((chip) => chip !== feature);
        fetch(
          `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
            item.url
          )}`,
          {
            method: "PUT",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
              "content-type": "application/json",
            }),
            body: JSON.stringify(item),
          }
        ).then((res) => {
          console.log("resolved");
        });
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    for (let x = 0; x < acceptedFiles.length; x++) {
      formData.append("file" + x, acceptedFiles[x]);
    }

    fetch(`${process.env.REACT_APP_SERVER_API}/pizapAsset/prepare`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${UserStore.getAccessToken()}`,
      }),
      body: formData,
    })
      .then((res) => res.json())
      .then(
        (result) =>
          new Promise((resolve, reject) => {
            AssetStore.setGraphics([...result, ...data.graphics]);
            AssetStore.emitChange();
            resolve();
          })
      );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleDeleteRows = (event, rowData) => {
    setOpen(true);
  };

  const handleEditRows = (event, rowData) => {
    setEditOpen(true);
  };

  const updateRow = async (url, newData) => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
        url
      )}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${UserStore.getAccessToken()}`,
          "content-type": "application/json",
        }),
        body: JSON.stringify(newData),
      }
    );
    return res;
  };

  const handleEditMultiRows = async () => {
    let _items = data.graphics;
    selectedRowsOnPage.forEach(async (rd) => {
      await Promise.all(
        _items.map(async (t) => {
          if (t.tableData.id === rd.tableData.id) {
            const update = {
              ...t,
              tags: tags.split(","),
              type: type,
            };
            const res = await updateRow(t.url, update);
            if (res.status === 200) {
              return update;
            } else {
              return t;
            }
          } else {
            return t;
          }
        })
      );
    });
    AssetStore.setGraphics(_items);
    AssetStore.emitChange();
    setEditOpen(false);
  };

  const handleClickMultiDelete = () => {
    let _items = data.graphics;
    selectedRowsOnPage.forEach((rd) => {
      setTimeout(() => {
        fetch(
          `${process.env.REACT_APP_SERVER_API}/pizapAsset/${encodeURIComponent(
            rd.url
          )}`,
          {
            method: "DELETE",
            headers: new Headers({
              Authorization: `Bearer ${UserStore.getAccessToken()}`,
            }),
          }
        )
          .then((res) => {
            if (res.status === 200) {
              _items = _items.filter((t) => t.tableData.id !== rd.tableData.id);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }, 1000);
    });
    AssetStore.setGraphics(_items);
    AssetStore.emitChange();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditTagsChange = (e) => {
    e.preventDefault();
    setTags(e.target.value);
  };

  const handleEditTypeChange = (e) => {
    e.preventDefault();
    setType(e.target.value);
  };

  const handlePageChange = (pageIndex, pageSize) => {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    setStartRowIndex(startIndex);
    setEndRowIndex(endIndex);
  };

  const handleSelectionChange = (rows) => {
    const currentPageRows = tableRef.current.dataManager.data.slice(
      startRowIndex,
      endRowIndex
    );
    const selectedRowsOnCurrentPage = rows.filter((row) =>
      currentPageRows.find(
        (pageRow) => pageRow.tableData.id === row.tableData.id
      )
    );
    setSelectedRowsOnPage(selectedRowsOnCurrentPage);
  };

  return (
    <section className="admin-section banner banner-section">
      <AdminHeader />
      {!isLoading ? (
        <div className="item-b">
          <Dialog open={editOpen} onClose={handleClose}>
            <DialogTitle>Edit Selected</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="tags"
                label="Tags"
                type="text"
                value={tags}
                fullWidth
                variant="standard"
                onChange={handleEditTagsChange}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                className="mt-5"
                options={[
                  { label: "Sticker", value: "Sticker" },
                  { label: "Background", value: "Background" },
                ]}
                value={type}
                onSelect={handleEditTypeChange}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Choose a type" />
                )}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Cancel</Button>
              <Button onClick={handleEditMultiRows}>Save</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              Are you sure you want to delete these rows?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Once you delete it, you cannot retrieve it.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleClickMultiDelete}>Delete</Button>
            </DialogActions>
          </Dialog>
          <Paper {...getRootProps({ className: "dropzone mb-2" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </Paper>
          {data.graphics && (
            <MaterialTable
              tableRef={tableRef}
              title="Pizap Assets"
              columns={[
                {
                  title: "Thumb",
                  field: "url",
                  render: (rowData) => (
                    <img
                      className="thumbIcon"
                      src={rowData.thumbnail}
                      alt="icon"
                    />
                  ),
                },
                {
                  title: "Type",
                  field: "type",
                  lookup: { Sticker: "Sticker", Background: "Background" },
                },
                { title: "Sequence", field: "sequence", type: "numeric" },
                {
                  title: "Tags",
                  field: "tags",
                  render: (rowData) => (
                    <>
                      {rowData.tags ? (
                        rowData.tags.map((tag) => {
                          let icon;
                          return (
                            <Chip
                              key={tag}
                              icon={icon}
                              label={tag}
                              onDelete={handleTagDelete(rowData, tag)}
                              className={classes.chip}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                },
                {
                  title: "Features",
                  field: "features",
                  render: (rowData) => (
                    <>
                      {rowData.features ? (
                        rowData.features.map((feature) => {
                          let icon;
                          return (
                            <Chip
                              key={feature}
                              icon={icon}
                              label={feature}
                              onDelete={handleFeatureDelete(rowData, feature)}
                              className={classes.chip}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                },
              ]}
              data={data.graphics}
              options={{
                filtering: true,
                pageSizeOptions: [5, 10, 20, 50, 100],
                pageSize: 20,
                selection: true,
                paging: true,
                showTextRowsSelected: false,
                selectionProps: (rowData) => ({
                  checked: selectedRowsOnPage.some(
                    (row) => row.tableData.id === rowData.tableData.id
                  ),
                }),
              }}
              onChangePage={handlePageChange}
              onSelectionChange={handleSelectionChange}
              actions={[
                {
                  icon: () => <Delete />,
                  tooltip: "Delete Rows",
                  onClick: handleDeleteRows,
                },
                {
                  icon: () => <Edit />,
                  tooltip: "Edit Rows",
                  onClick: handleEditRows,
                },
              ]}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (
                        newData.tags !== null &&
                        newData.tags !== "" &&
                        typeof newData.tags === "string"
                      ) {
                        newData.tags = newData.tags.split(",");
                      }
                      if (
                        newData.features !== null &&
                        newData.features !== "" &&
                        typeof newData.features === "string"
                      ) {
                        newData.features = newData.features.split(",");
                      }
                      fetch(`${process.env.REACT_APP_SERVER_API}/pizapAsset`, {
                        method: "POST",
                        headers: new Headers({
                          Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          "content-type": "application/json",
                        }),
                        body: JSON.stringify(newData),
                      })
                        .then((res) => {
                          if (res.status === 200) {
                            AssetStore.setGraphics([...data.graphics, newData]);
                            AssetStore.emitChange();
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...data.graphics];
                      const index = oldData.tableData.id;
                      if (
                        newData.tags !== null &&
                        newData.tags !== "" &&
                        typeof newData.tags === "string"
                      ) {
                        newData.tags = newData.tags.split(",");
                      }
                      if (
                        newData.features !== null &&
                        newData.features !== "" &&
                        typeof newData.features === "string"
                      ) {
                        newData.features = newData.features.split(",");
                      }
                      dataUpdate[index] = newData;
                      fetch(
                        `${
                          process.env.REACT_APP_SERVER_API
                        }/pizapAsset/${encodeURIComponent(newData.url)}`,
                        {
                          method: "PUT",
                          headers: new Headers({
                            Authorization: `Bearer ${UserStore.getAccessToken()}`,
                            "content-type": "application/json",
                          }),
                          body: JSON.stringify(newData),
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            AssetStore.setGraphics([...dataUpdate]);
                            AssetStore.emitChange();
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...data.graphics];
                      const index = oldData.tableData.id;
                      const record = dataDelete[index];
                      dataDelete.splice(index, 1);
                      fetch(
                        `${
                          process.env.REACT_APP_SERVER_API
                        }/pizapAsset/${encodeURIComponent(record.url)}`,
                        {
                          method: "DELETE",
                          headers: new Headers({
                            Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          }),
                        }
                      )
                        .then((res) => {
                          if (res.status === 200) {
                            resolve();
                            AssetStore.setGraphics([...dataDelete]);
                            AssetStore.emitChange();
                            resolve();
                          } else {
                            reject();
                          }
                        })
                        .catch((e) => {
                          reject();
                        });
                    }, 1000);
                  }),
              }}
              components={{
                Pagination: PatchedPagination,
                Toolbar: (props) => (
                  <div>
                    <h6 className="MuiTypography-root MuiTypography-h6">
                      Selected Rows: {selectedRowsOnPage.length}
                    </h6>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
            />
          )}
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </section>
  );
};

export default AdminGraphicsPage;
