import React, { useEffect, useState, useMemo } from "react";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const Save = () => {
  const originalSize = window.editor.getOriginalSize();
  const [stSzie, setStSize] = useState(originalSize);
  const [hiSize, setHiSize] = useState(originalSize);
  const [customSize, setCustomSize] = useState(originalSize);
  const cropType = EditorStore.getSelectedCropType();
  const [quality, setQuality] = useState(UserStore.isPro() ? "hi" : "standard");
  const [fileName, setFileName] = useState("piZap");
  const [extension, setExtension] = useState("jpg");

  useEffect(() => {
    if (
      window.editor.getBaseObject() &&
      (window.editor.getBaseObject().transparent ||
        window.editor.getBaseObject().isTransparent)
    ) {
      setExtension("png");
    }

    if (
      window.editor.getBaseObject() &&
      window.editor.getBaseObject().transparent &&
      extension !== "pizap"
    ) {
      setExtension("png");
    }
  }, [window.editor.getBaseObject()]);

  const onKeyUp = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      _onDone();
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      document.addEventListener("keyup", onKeyUp);
      if (EditorStore.getProjectTitle() !== "Untitled") {
        setFileName(slugify(EditorStore.getProjectTitle()));
      }
    }

    return () => {
      isMounted = false;
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const standardClick = () => {
    setQuality("standard");
  };

  const hiClick = () => {
    if (UserStore.isPro()) {
      setQuality("hi");
    } else {
      UserActionCreators.showProGet(true);
    }
  };

  const customClick = () => {
    if (UserStore.isPro()) {
      setQuality("custom");
    } else {
      UserActionCreators.showProGet(true);
    }
  };

  const onClose = () => {
    EditorActionCreators.ShowSaveQuality(false);
  };

  const _onDone = () => {
    if (quality === "custom") {
      EditorActionCreators.Save(fileName, quality, extension, customSize.width, customSize.height);
    } else {
      if (cropType) {
        if (
          cropType.minWidth &&
          cropType.minWidth > 0 &&
          quality === "standard"
        ) {
          EditorActionCreators.Save(
            fileName,
            "custom",
            extension,
            cropType.minWidth,
            cropType.minHeight
          );
        } else {
          EditorActionCreators.Save(fileName, quality, extension);
        }
      } else {
        EditorActionCreators.Save(fileName, quality, extension);
      }
    }
  };
  const _filenameChanged = (e) => {
    setFileName(e.target.value);
  };

  const _onExtension = (event) => {
    setExtension(event.currentTarget.value);
  };

  const onCustomWidth = (e) => {
    const width = parseInt(e.target.value) ? parseInt(e.target.value) : 1;
    const height = Math.ceil(
      window.editor.stickerSelector.calculateGetScaledHeight(
        originalSize.width,
        originalSize.height,
        parseInt(e.target.value) ? parseInt(e.target.value) : 1
      )
    );
    setCustomSize({ width, height });
  };

  const onCustomHeight = (e) => {
    const height = parseInt(e.target.value) ? parseInt(e.target.value) : 1;
    const width = Math.ceil(
      window.editor.stickerSelector.calculateGetScaledWidth(
        originalSize.width,
        originalSize.height,
        parseInt(e.target.value) ? parseInt(e.target.value) : 1
      )
    );
    setCustomSize({ width, height });
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  useEffect(() => {
    const sum = originalSize.width + originalSize.height;
    if (sum > 2048) {
      setStSize({
        width: Math.ceil((originalSize.width * 2048) / sum),
        height: Math.ceil((originalSize.height * 2048) / sum),
      });
    }
    if (sum > 8360) {
      setHiSize({
        width: Math.ceil((originalSize.width * 8360) / sum),
        height: Math.ceil((originalSize.height * 8360) / sum),
      });
    }

    if (cropType && cropType.minWidth && cropType.minWidth > 0) {
      setStSize({ width: cropType.minWidth, height: cropType.minHeight });
    }
  }, [originalSize, cropType]);

  const customOptions = useMemo(() => {
    return (
      quality === "custom" && (
        <div className="custom-container">
          <div className="customSize">
            <input
              type="number"
              onChange={onCustomWidth}
              value={customSize.width}
              min={1}
            />
            <span> X </span>
            <input
              type="number"
              onChange={onCustomHeight}
              value={customSize.height}
              min={1}
            />
            <span>Pixels</span>
          </div>
        </div>
      )
    );
  }, [quality, customSize]);

  return (
    <div className="dialog-background">
      <div className="saveQualityDialog">
        <div className="photopicker-navbar">
          <div className="photopicker-title">Download</div>
          <div className="close-btn" onClick={onClose}>
            <CloseSVG height={12} />
          </div>
        </div>
        <div className="space-evenly">
          <div className="d-flex-column">
            <span className="savelabel">File Name</span>
            <div className="filename" id="filename">
              <input
                type="text"
                onChange={_filenameChanged}
                placeholder="Input File Name"
                value={fileName}
              />
            </div>
          </div>
          <div className="d-flex-column">
            <span className="savelabel">File Type</span>
            <div className="filename" id="filename">
              <select onChange={_onExtension} value={extension} id="extension">
                <option value="jpg">.jpg</option>
                <option value="png">.png</option>
                {UserStore.isPro() ? (
                  <option value="pizap">.pizap</option>
                ) : null}
              </select>
            </div>
          </div>
        </div>
        <div className="qualityboxes">
          <div className="qualitycontainer">
            <div className={`qualitybox`}>
              <div className="qualitywrap">
                <span className="qualitytext">Standard Quality</span>
                <span className="qualitysize">
                  {stSzie.width}x{stSzie.height}
                </span>
                <label htmlFor="rdo-1" className="btn-radio">
                  <input
                    type="radio"
                    id="rdo-1"
                    name="radio-grp"
                    checked={quality === "standard"}
                    onChange={standardClick}
                  />
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      className="inner"
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                    ></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      className="outer"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>

            <div className={`qualitybox`}>
              {!UserStore.isPro() ? (
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  style={{ marginTop: "-6px" }}
                  viewBox="0 0 1500 1080"
                  enableBackground="new 0 0 1500 1080"
                  height="15"
                >
                  <g transform="matrix( 8.704086303710938, 0, 0, 8.704086303710938, 109.4,28) ">
                    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                      <g>
                        <g id="Symbol_1_0_Layer0_0_FILL">
                          <path
                            fill="#FFC200"
                            d="M19.4,112.5c0,1.4,0.5,2.7,1.5,3.7s2.2,1.5,3.6,1.5h98.2c1.4,0,2.7-0.5,3.7-1.5s1.5-2.2,1.5-3.7
					s-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5H24.5c-1.4,0-2.6,0.5-3.6,1.5C19.9,109.9,19.4,111.1,19.4,112.5 M127.9,40.3
					c0,2.7,0.9,4.9,2.8,6.8c0.1,0.1,0.2,0.2,0.2,0.2l-30.2,16L76.9,18.8c1.3-0.5,2.5-1.3,3.6-2.3c1.9-1.9,2.8-4.1,2.8-6.8
					s-0.9-4.9-2.8-6.8c-0.4-0.4-0.8-0.7-1.2-1C77.7,0.6,75.8,0,73.7,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.7,1-6.5,2.8
					C65,4.7,64.1,7,64.1,9.6s0.9,4.9,2.8,6.8c1,1,2.2,1.8,3.5,2.2l-24,44.7l-30.1-16c0.1-0.1,0.1-0.2,0.2-0.2
					c1.9-1.9,2.8-4.1,2.8-6.8c0-2.7-0.9-5-2.8-6.8c-1.9-1.9-4.1-2.8-6.8-2.8c-0.8,0-1.5,0.1-2.2,0.2c-1.7,0.4-3.3,1.2-4.6,2.5
					c-1.5,1.5-2.4,3.3-2.7,5.4C0,39.4,0,39.8,0,40.3c0,2.7,0.9,4.9,2.8,6.8C4.7,49,7,50,9.6,50c0.6,0,1.2,0,1.8-0.2l10.2,49.3h104
					l10.2-49.3c0.6,0.1,1.1,0.2,1.8,0.2c2.7,0,4.9-1,6.8-2.8c1.9-1.9,2.8-4.1,2.8-6.8c0-0.5,0-1-0.1-1.4c-0.3-2.1-1.2-3.9-2.7-5.4
					c-1.4-1.3-2.9-2.2-4.6-2.5c-0.7-0.2-1.4-0.2-2.2-0.2c-2.7,0-4.9,0.9-6.8,2.8C128.8,35.4,127.9,37.7,127.9,40.3z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              ) : null}
              <div className="qualitywrap">
                <span className="qualitytext">High Quality</span>
                <span className="qualitysize">
                  {hiSize.width}x{hiSize.height}
                </span>
                <label htmlFor="rdo-2" className="btn-radio">
                  <input
                    type="radio"
                    id="rdo-2"
                    name="radio-grp"
                    checked={quality === "hi"}
                    onChange={hiClick}
                  />
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      className="inner"
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                    ></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      className="outer"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>

            <div className={`qualitybox`}>
              {!UserStore.isPro() ? (
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  style={{ marginTop: "-6px" }}
                  viewBox="0 0 1500 1080"
                  enableBackground="new 0 0 1500 1080"
                  height="15"
                >
                  <g transform="matrix( 8.704086303710938, 0, 0, 8.704086303710938, 109.4,28) ">
                    <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                      <g>
                        <g id="Symbol_1_0_Layer0_0_FILL">
                          <path
                            fill="#FFC200"
                            d="M19.4,112.5c0,1.4,0.5,2.7,1.5,3.7s2.2,1.5,3.6,1.5h98.2c1.4,0,2.7-0.5,3.7-1.5s1.5-2.2,1.5-3.7
    s-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5H24.5c-1.4,0-2.6,0.5-3.6,1.5C19.9,109.9,19.4,111.1,19.4,112.5 M127.9,40.3
    c0,2.7,0.9,4.9,2.8,6.8c0.1,0.1,0.2,0.2,0.2,0.2l-30.2,16L76.9,18.8c1.3-0.5,2.5-1.3,3.6-2.3c1.9-1.9,2.8-4.1,2.8-6.8
    s-0.9-4.9-2.8-6.8c-0.4-0.4-0.8-0.7-1.2-1C77.7,0.6,75.8,0,73.7,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.7,1-6.5,2.8
    C65,4.7,64.1,7,64.1,9.6s0.9,4.9,2.8,6.8c1,1,2.2,1.8,3.5,2.2l-24,44.7l-30.1-16c0.1-0.1,0.1-0.2,0.2-0.2
    c1.9-1.9,2.8-4.1,2.8-6.8c0-2.7-0.9-5-2.8-6.8c-1.9-1.9-4.1-2.8-6.8-2.8c-0.8,0-1.5,0.1-2.2,0.2c-1.7,0.4-3.3,1.2-4.6,2.5
    c-1.5,1.5-2.4,3.3-2.7,5.4C0,39.4,0,39.8,0,40.3c0,2.7,0.9,4.9,2.8,6.8C4.7,49,7,50,9.6,50c0.6,0,1.2,0,1.8-0.2l10.2,49.3h104
    l10.2-49.3c0.6,0.1,1.1,0.2,1.8,0.2c2.7,0,4.9-1,6.8-2.8c1.9-1.9,2.8-4.1,2.8-6.8c0-0.5,0-1-0.1-1.4c-0.3-2.1-1.2-3.9-2.7-5.4
    c-1.4-1.3-2.9-2.2-4.6-2.5c-0.7-0.2-1.4-0.2-2.2-0.2c-2.7,0-4.9,0.9-6.8,2.8C128.8,35.4,127.9,37.7,127.9,40.3z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              ) : null}
              <div className="qualitywrap">
                <span className="qualitytext">Custom Size</span>
                <span className="qualitysize">Enter values</span>
                <label htmlFor="rdo-3" className="btn-radio">
                  <input
                    type="radio"
                    id="rdo-3"
                    name="radio-grp"
                    checked={quality === "custom"}
                    onChange={customClick}
                  />
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      className="inner"
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                    ></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      className="outer"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>
          </div>
        </div>
        {customOptions}
        <div className="saveDoneBtns">
          <button onClick={_onDone} className="my-btn">
            <svg
              version="1.0"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 56.4 58.4"
              enableBackground="new 0 0 56.4 58.4"
              height="17"
            >
              <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                <g>
                  <g id="Layer0_0_FILL">
                    <path
                      fill="#FFFFFF"
                      d="M56.4,38.8h-4.2v11.5c0,0,0,0.1,0,0.1c-0.1,2.3-1.4,3.6-3.7,3.9H7.9c-2.4-0.3-3.6-1.6-3.7-4V38.8H0v13.5
				c0.3,2,1,3.5,2.2,4.5c1.1,0.9,2.7,1.5,4.9,1.6h42.2c2.2-0.1,3.8-0.7,4.9-1.6c1.2-1,1.9-2.5,2.2-4.5V38.8 M28.1,0
				c-0.9,0-1.6,0.6-2,1.8v39.1L21,35.8l-3.4-3.4c-0.8-0.5-1.6-0.4-2.4,0.2c-0.6,0.5-0.7,1.4-0.3,2.4l0.7,0.7l11.1,11.2l0.1,0.1
				c0.5,0.3,0.9,0.5,1.4,0.6c0.5,0,0.9-0.2,1.4-0.6l0.1-0.1l11.1-11.2l0.7-0.7c0.4-1.1,0.3-1.9-0.3-2.4c-0.8-0.7-1.6-0.8-2.4-0.2
				l-3.4,3.4l-5.1,5.1V1.8c-0.5-1.2-1.1-1.7-2-1.8c0,0-0.1,0-0.1,0C28.2,0,28.1,0,28.1,0z"
                    />
                  </g>
                </g>
              </g>
            </svg>
            &nbsp; Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default Save;
