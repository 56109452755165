import React from "react";

const ConfirmProjectDialog = (props) => {
  const { cancelFunc, acceptFunc } = props;
  return (
    <div className="confirm-dialog">
      <div className="confirm-dialog-container">
        <div className="confirm-dialog-title">
          <span>
            Are you sure you want to permanently delete this project? This can
            not be undone.
          </span>
          <div className="justify-center">
            <button className="btn confirmAcceptBtn" onClick={acceptFunc}>
              Delete
            </button>
            <button
              className="btn confirmCancelBtn my-btn"
              onClick={cancelFunc}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmProjectDialog;
