import React, { useEffect, useState } from "react";
import UserStore from "../stores/UserStore";
import AssetStore from "../stores/AssetStore";
import UserActionCreators from "../actions/UserActionCreators";
import Login from "./Login.react";
import UserLeftMenu from "./UserLeftMenu.react";
import Gallery from "react-grid-gallery";
import { Link, useNavigate, useParams } from "react-router-dom";
import ThumbOverlay from "./ThumbOverLay.react";
import ProGetSave from "./ProGetSave.react";
import ProGet from "./ProGet.react";
import ProTeaser from "./ProTeaser.react";
import TagDialog from "./TagDialog.react";
import ProCrown from "./ProCrown.react";
import QuickControls from "./QuickControls.react";
import { useMemo } from "react";
import Header from "../layouts/Header";
import HelmetDetails from "./HelmetDetails";

let appName;

function getStateFromStores() {
  return {
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    featuredTemplates: UserStore.getApplications(appName),
    statusMessage: UserStore.getStatusMessage(),
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showTagDialog: UserStore.getShowTagDialog(),
  };
}

const Applications = () => {
  const [data, setData] = useState(getStateFromStores());
  const { app } = useParams();
  const navigate = useNavigate();
  const isPro = UserStore.isPro();

  useEffect(() => {
    appName = app;
    UserActionCreators.getApplications(appName);
    UserStore.addChangeListener(_onChange);
    AssetStore.addChangeListener(_onChange);
    if (UserStore.getUser() === null) {
      UserActionCreators.updateUserData(UserStore.getAccessToken());
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
      AssetStore.removeChangeListener(_onChange);
    };
  }, [app]);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const onAssetSelected = (image) => {
    const asset = data.assets[image];
    navigate("/app/?url=" + asset.src);
  };

  const loginDialog = useMemo(() => {
    if (data.showLogin) {
      return <Login />;
    } else if (data.showProGetSave) {
      return <ProGetSave />;
    } else if (data.showProGet) {
      return <ProGet />;
    } else if (data.showPro) {
      return <ProTeaser />;
    } else if (data.showTagDialog) {
      return <TagDialog />;
    }
  }, [data]);

  return (
    <div className="startPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl={`https://www.pizap.com/app/applications/${app}`}
        keywords=""
      />
      <Header />
      <div className="container" id="container">
        <QuickControls />
        <div className="galleries">
          {data.featuredTemplates !== null &&
            data.featuredTemplates.map((c, index) => {
              const catTemplates = c.photoResponses
                .filter((i) => !i.isTouchup)
                .map((i, index) => {
                  if (!i.oldTags) {
                    i.oldTags = i.tags;
                  }
                  let isFree = true;
                  if (
                    i.oldTags &&
                    i.oldTags.find(
                      (element) =>
                        typeof element.toLowerCase === "function" &&
                        element.toLowerCase() === "pizappro"
                    ) &&
                    !isPro
                  ) {
                    i.thumbnailCaption = <ProCrown key={i.imageName} />;
                    isFree = false;
                  }

                  if (i.oldTags) {
                    i.tags = i.oldTags.map((t) => {
                      return { value: t, title: t };
                    });
                  }
                  i.customOverlay = (
                    <ThumbOverlay
                      key={index}
                      project={i}
                      hideActions={true}
                      isFree={isFree}
                    />
                  );
                  return i;
                });

              return (
                <div className="gallery" key={`gallery-${index}`}>
                  <div className="galleryTitle">
                    <span>{c.name}</span>
                    <Link
                      to={`/app/templates/${encodeURIComponent(c.tag)}`}
                    >{`See all >`}</Link>
                  </div>
                  <Gallery
                    featureTitle={c.name}
                    images={catTemplates}
                    maxRows={1}
                    enableImageSelection={false}
                    enableLightbox={false}
                    onClickThumbnail={onAssetSelected}
                    rowHeight={140}
                  />
                </div>
              );
            })}
        </div>
      </div>
      {loginDialog}
      <UserLeftMenu selected="start" />
    </div>
  );
};

export default Applications;
