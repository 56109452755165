import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import AdminHeader from "../layouts/AdminHeader";
import AssetStore from "../stores/AssetStore";
import UserStore from "../stores/UserStore";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import PatchedPagination from "../components/admin/PatchedPagination";

const getStateFromStores = () => {
  return {
    categories: AssetStore.getCategories(),
    applications: AssetStore.getApplications(),
  };
};

function AdminApplicationsPage() {
  const [data, setData] = useState(getStateFromStores());
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (data.applications === null) {
        AssetActionCreators.loadApplications();
      }
      AssetStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    if (data.applications) setIsLoaded(true);
    else setIsLoaded(false);
  }, [data]);

  return (
    <section className="admin-section banner banner-section">
      <AdminHeader />
      {isLoaded ? (
        <div className="item-b">
          <MaterialTable
            title="Pizap Applications"
            columns={[
              { title: "ID", field: "id" },
              { title: "Name", field: "name" },
              { title: "AppId", field: "appId" },
              { title: "AppStoreId", field: "appStoreId" },
              { title: "Icon", field: "icon" },
              { title: "Sequence", field: "sequence" },
            ]}
            data={data.applications}
            options={{
              filtering: true,
              pageSizeOptions: [5, 10, 20, 100],
              pageSize: 10,
            }}
            components={{
              Pagination: PatchedPagination,
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    fetch(`${process.env.REACT_APP_SERVER_API}/applications`, {
                      method: "POST",
                      headers: new Headers({
                        Authorization: `Bearer ${UserStore.getAccessToken()}`,
                        "content-type": "application/json",
                      }),
                      body: JSON.stringify(newData),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          AssetStore.setApplications([
                            ...data.applications,
                            newData,
                          ]);
                          AssetStore.emitChange();
                          resolve();
                        } else {
                          reject();
                        }
                      })
                      .catch((e) => {
                        reject(e);
                      });
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...data.applications];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    fetch(
                      `${
                        process.env.REACT_APP_SERVER_API
                      }/applications/${encodeURIComponent(newData.id)}`,
                      {
                        method: "PUT",
                        headers: new Headers({
                          Authorization: `Bearer ${UserStore.getAccessToken()}`,
                          "content-type": "application/json",
                        }),
                        body: JSON.stringify(newData),
                      }
                    )
                      .then(() => {
                        AssetStore.setApplications([...dataUpdate]);
                        AssetStore.emitChange();
                        resolve();
                      })
                      .catch((e) => {
                        reject(e);
                      });
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...data.applications];
                    const index = oldData.tableData.id;
                    const record = dataDelete[index];
                    dataDelete.splice(index, 1);
                    fetch(
                      `${
                        process.env.REACT_APP_SERVER_API
                      }/applications/${encodeURIComponent(record.id)}`,
                      {
                        method: "DELETE",
                        headers: new Headers({
                          Authorization: `Bearer ${UserStore.getAccessToken()}`,
                        }),
                      }
                    )
                      .then((res) => {
                        if (res.status === 200) {
                          AssetStore.setApplications([...dataDelete]);
                          AssetStore.emitChange();
                          resolve();
                        } else {
                          reject();
                        }
                      })
                      .catch((e) => {
                        reject(e);
                      });
                  }, 1000);
                }),
            }}
          />
        </div>
      ) : (
        <div className="loading"></div>
      )}
    </section>
  );
}

export default AdminApplicationsPage;
