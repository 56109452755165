import React, { useEffect, useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserStore from "../stores/UserStore";

function getStateFromStores() {
  return {
    uploadProgress: UserStore.getUploadProgress(),
  };
}
const Spinner = () => {
  const [data, setData] = useState(getStateFromStores());
  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowSpinner(false);
  };

  return (
    <div className="filepicker-dialog active">
      {/* <div className="spinnerDialog" >
        <div className="spinner-navbar">
          <div className="photopicker-title">Loading...</div>
          <a
            id="filepicker-cancel-btn"
            className="filepicker-navbar-right-btn"
            onClick={_cancelClick}
          >
            Close
          </a>
        </div>
        <div className="lds-css ng-scope">
          <div className="lds-square">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <progress
          className="buttonProgress"
          value={data.uploadProgress}
          max="100"
        />
      </div> */}
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <div id="loading-text">loading</div>
      </div>
    </div>
  );
};

export default Spinner;
