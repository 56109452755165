import React, { useState } from "react";
import UserActionCreators from "../actions/UserActionCreators";
import EmailIcon from "../images/editor/email_icon.png";
import FacebookIcon from "../images/editor/facebook_logo.png";
import LinkedinIcon from "../images/editor/linkedin_icon.png";
import PinterestIcon from "../images/editor/pinterest.png";
import WhatsappIcon from "../images/editor/whatsapp.png";
import TwitterIcon from "../images/editor/twitter_icon.png";
import CheckIcon from "../images/editor/check.png";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const ShareImageDialog = (props) => {
  const { imageUrl } = props;
  const [copyImageLinkClass, setCopyImageLinkClass] = useState("");

  const onClose = () => {
    UserActionCreators.showShareDialog(false);
  };

  const facebookShare = () => {
    window.FB.ui(
      {
        method: "share",
        href: `https://www.pizap.com/app/share?image=${imageUrl}`,
        hashtag: "#pizap",
      },
      function (response) {}
    );
  };

  const whatsappShare = () => {
    window.open(
      `https://web.whatsapp.com/send?text=${encodeURIComponent(
        `https://www.pizap.com/app/share?image=${imageUrl}`
      )}`,
      "_blank"
    );
  };

  const linkedinShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        `https://www.pizap.com/app/share?image=${imageUrl}`
      )}`,
      "_blank"
    );
  };

  const twitterShare = () => {
    window.open(
      "https://twitter.com/share?url=" +
        encodeURIComponent(
          `https://www.pizap.com/app/share?image=${imageUrl}`
        ) +
        "&via=pizap",
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    );
  };

  const sendEmail = () => {
    window.open(
      `mailto:?subject=${encodeURIComponent(
        "Check this out."
      )}&body=${encodeURIComponent(
        `https://www.pizap.com/app/share?image=${imageUrl}`
      )} Created at https://www.pizap.com`,
      "_blank"
    );
  };

  const handleCopyImageClipboard = () => {
    navigator.clipboard
      .writeText(`https://www.pizap.com/app/share?image=${imageUrl}`)
      .then(() => {
        setCopyImageLinkClass("visible");
        setTimeout(() => {
          setCopyImageLinkClass("");
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <div className="dialog-background">
      <div className="share-dialog-box image-share">
        <div className="photopicker-navbar">
          <div className="photopicker-title">Share</div>
          <div className="close-btn" onClick={onClose}>
            <CloseSVG height={12} />
          </div>
        </div>
        <div className="share-holder">
          <div id="saveCaptionContainer" className="mb-2">
            <span>Link</span>
            <div className="referral-link-input">
              <input
                type="text"
                className="input referal-url-input"
                name="referral"
                readOnly
                defaultValue={`https://www.pizap.com/app/share?image=${imageUrl}`}
              />
              {imageUrl && (
                <button
                  className="button copyLink tooltip"
                  onClick={handleCopyImageClipboard}
                >
                  Copy
                  <span className={`share-tooltiptext ${copyImageLinkClass}`}>
                    <img src={CheckIcon} height={10} />
                    Copied!
                  </span>
                </button>
              )}
            </div>
          </div>
          <span className="share-title">Share image</span>
          <div className="share-sources">
            <div className="share-item" onClick={facebookShare}>
              <img src={FacebookIcon} height={60} width={60} />
              <span className="destinationName">Facebook</span>
            </div>
            <div className="share-item" onClick={whatsappShare}>
              <img src={WhatsappIcon} height={60} width={60} />
              <span className="destinationName">Whatsapp</span>
            </div>
            <div className="share-item" onClick={twitterShare}>
              <img src={TwitterIcon} height={60} width={60} />
              <span className="destinationName">Twitter</span>
            </div>
            <a
              href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
                window.location.href
              )}&media=${encodeURIComponent(
                imageUrl
              )}&description=${encodeURIComponent(
                "Created at piZap.com: Design Simplified"
              )}`}
              target="_blank"
              className="share-item"
            >
              <img src={PinterestIcon} height={60} width={60} />
              <span className="destinationName">Pinterest</span>
            </a>
            <div className="share-item" onClick={linkedinShare}>
              <img src={LinkedinIcon} height={60} width={60} />
              <span className="destinationName">LinkedIn</span>
            </div>
            <div className="share-item" onClick={sendEmail}>
              <img src={EmailIcon} height={60} width={60} />
              <span className="destinationName">Email</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareImageDialog;
