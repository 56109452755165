import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const AddTextPhotoPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Add Text to Photos, write on pictures, fonts"
        description="Quickly and easily add text to photos with over 360 fonts and awesome text styles. Easily write on your pictures."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/add_text_to_photos"
        keywords="Add text to photos, write on pixtures, text on photo, fonts"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Add Text to Photos, It's Easy & Fun!</p>
          <p className="desc">
            Make your pictures say more than one thousand words
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=edit");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/add-text-to-photos.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/add-text-to-photos-app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Add Text to Photos, It’s Easy & Fun!</span>
            <span className="desc">
              piZap makes it easy to add text on photos, and offers the best
              platform for doing so for several key reasons. For one, piZap
              offers hundreds of font styles to choose from with new fonts added
              regularly. Second, piZap is easy to use and lets you add stickers,
              doodles, text bubbles, and so much more to your photos.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap’s Add Text to Photos Tool</span>
            <span className="desc">
              piZap’s Add Text to Photos tool has a lot to offer, starting with
              hundreds of hand-picked fonts. There are regular fonts, bold
              fonts, and fonts that’ll make you say—WOW, I’ve never seen that
              before! From glowing text to fancy fonts, there’s a perfect font
              for everyone. Add text in boxes or get creative with text bubbles.
              Other in-app features include basic editing tools, filters,
              stickers, painting tools and more! Use one or use them all to
              express yourself through photos.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/add-text-to-photos-app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/add-text-to-photos-app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Easy Way to Write on Pictures</span>
            <span className="desc">
              Like all piZap’s tools, the Add Text to Photos feature is easy to
              use. Write on pictures without any hassles. Plus, you have the
              option to insert text or use the Paint Tool to hand write messages
              directly on photos. Drag the text where you want it to go;
              increase or decrease text box size. Add background colors or embed
              text into image background. You don’t need prior editing
              experience to do all this and more, piZap is truly that easy to
              use.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              piZap Makes It Fun to Add Text to Photos
            </span>
            <span className="desc">
              Have fun adding fancy fonts, or kicking it up a notch with text
              that glows. It’s hard not to get addicted to piZap when you
              suddenly feel like some sort of photo editing wizard. Oh, the
              things you can do to your photos with the right tools at your
              fingertips. It’s not fun to post a picture that you know looks
              bad, but sometimes it’s hard to make text on photo look good… but
              not anymore! piZap’s carefully designed text boxes and fonts are
              made to look seamless and professional.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/add-text-to-photos-app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/add-text-to-photos-app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Add Text to Photos + Many More Features
            </span>
            <span className="desc">
              The ability to easily write on pictures is far from the only thing
              piZap offers. This one app comes with a variety of easily
              accessible editing tools and features including a MEME maker,
              collage creator, and graphic design tool. Add stickers or a custom
              Emoji alongside text boxes, or use the Paint tool to add hand
              drawn doodles. piZap offers all this and more, and it’s free to
              try!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default AddTextPhotoPage;
