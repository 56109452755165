import React, { useEffect, useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import ProCrownBadge from "../images/editor/pro-crown-badge.png";
import { useNavigate } from "react-router-dom";
import SkeletonLoading from "./SkeletonLoadingImage";

const ImageGallery = (props) => {
  const navigate = useNavigate();
  const [tooltipData, setTooltipData] = useState({
    id: "",
    user: "",
    platfomrm: "",
    url: "",
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (
      props.item?.originalItem &&
      (props.item?.originalItem?.imageUrl || props.item?.originalItem?.imageURL)
    ) {
      const paresedUrl = new URL(
        props.item.originalItem?.imageUrl || props.item.originalItem?.imageURL
      );
      const hostname = paresedUrl.hostname;
      if (hostname.includes("pixabay")) {
        setTooltipData({
          id: props.item.originalItem.user_id,
          user: props.item.originalItem.user,
          platfomrm: hostname,
          url: props.item.originalItem.imageURL,
        });
      } else if (hostname.includes("unsplash")) {
        setTooltipData({
          user: props.item.originalItem.user.username,
          platfomrm: hostname,
          url: props.item.originalItem.imageUrl,
        });
      }
    }
  }, []);

  const isPro = UserStore.isPro();
  const onItemSelected = (e) => {
    if (
      ((!props.isbing && props.item.isProOnly) ||
        (props.isbing && props.id >= 2)) &&
      !isPro
    ) {
      UserActionCreators.showProGet(true);
    } else {
      EditorActionCreators.ShowFilePicker(false);
      switch (props.type) {
        case "sticker":
          window.editor.addSticker(
            props.item.src,
            null,
            null,
            function () {
              EditorStore.setShowSpinner(false);
            },
            null,
            null,
            null,
            null,
            null,
            props.item.features
          );
          break;
        case "effect":
          window.editor.addSticker(
            props.item.src,
            null,
            null,
            function () {
              EditorStore.setShowSpinner(false);
            },
            null,
            null,
            null,
            null,
            null,
            props.item.features
          );
          break;
        case "border":
          window.editor.addBorder(props.item.src, false);
          break;
        case "design":
          window.editor.addDesign(props.item, () => {
            EditorStore.setShowSpinner(false);
          });
          break;
        case "collage":
          window.editor.initCollage(props.item);
          break;
        case "background":
          window.editor.addPicture(props.item.src);
          break;
        case "facebook":
          if (EditorStore.getFilePickerTool() === "profile") {
            window.editor.addPicture(props.item.icon);
          } else {
            if (props.item.proSource && UserStore.isPro()) {
              window.editor.addPicture(props.item.proSource);
            } else {
              window.editor.addPicture(props.item.src);
            }
          }
          break;
        case "cutout":
          window.editor.addPicture(props.item.src);
          break;
        case "projectAsset":
          window.editor.addPicture(props.item.src);
          break;
        case "shape":
          EditorActionCreators.CutoutMode("shape-selected");
          window.editor.addShape(props.item.src);
          break;
        case "templates":
          navigate(`/app/?project=${props.item.src}`);
          break;
        case "search":
          navigate(`/app/?type=edit`);
          window.editor.addPicture(props.item.src);
          break;
        case "sticker_eraser":
          navigate(`/app/?type=sticker`);
          setTimeout(() => {
            window.editor.addSticker(
              props.item.src,
              null,
              null,
              function () {
                EditorStore.setShowSpinner(false);
              },
              null,
              null,
              null,
              null,
              null,
              props.item.features
            );
            let mainContent = document.getElementById("mainContent");
            let canvas = mainContent.getElementsByTagName("canvas");
            if (canvas) {
              canvas[0].className = "transparent";
            }
          }, 1000);
          break;
        default:
      }
    }
    e.stopPropagation();
  };

  const dragStart = (event) => {
    if (props.item.isProOnly && !isPro) {
      event.preventDefault();
    } else {
      switch (props.type) {
        case "sticker":
          event.dataTransfer.setData("text", props.item.src);
          break;
        case "bing":
          event.dataTransfer.setData("text", props.item.src);
          break;
        default:
          event.preventDefault();
      }
    }
  };

  const handleClickProfile = (e) => {
    if (tooltipData.platfomrm.includes("pixabay")) {
      window.open(
        `https://pixabay.com/users/${tooltipData.user}-${tooltipData.id}`
      );
    } else {
      window.open(`https://unsplash.com/@${tooltipData.user}`);
    }
    e.stopPropagation();
  };

  return (
    <figure
      className="clickable"
      data-id={props.item.key}
      data-src={
        props.item.proSource && isPro ? props.item.proSource : props.item.src
      }
      onClick={(e) => onItemSelected(e)}
    >
      {!imageLoaded && <SkeletonLoading width="100%" height="160px" />}
      <img
        src={props.item.icon}
        alt="Not Found"
        style={{
          display: imageLoaded ? "block" : "none",
          borderRadius: "4px",
        }}
        width="100%"
        onLoad={handleImageLoad}
        onDragStart={dragStart}
      />
      {((!props.isbing && props.item.isProOnly) ||
        (props.isbing && props.id >= 2)) &&
        !isPro && (
          <img
            src={ProCrownBadge}
            className="pro-overlay"
            onDragStart={dragStart}
            alt="pro-overlay"
          />
        )}
      {tooltipData.user && (
        <span className="user-platform" onClick={(e) => handleClickProfile(e)}>
          {tooltipData.user}
        </span>
      )}
    </figure>
  );
};

export default ImageGallery;
