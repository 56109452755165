import React, { useEffect } from "react";
import FacebookStore from "../stores/FacebookStore";

const Facebook = () => {
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FacebookStore.getFbAppId(),
        cookie: true,
        xfbml: false,
        version: "v2.9",
      });

      //            window.FB.getLoginStatus(function(response) {
      //                this.statusChangeCallback(response);
      //            }.bind(this));
      //        }.bind(this);
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    return () => {
      FacebookStore.setBaseUrl("www.pizap.com");
      FacebookStore.setFbNamespace("pizap_photo_editor");
      //            FacebookStore.setFbSiteName('piZap.com');
      FacebookStore.setFbAppId("158391354186658");
    };
  }, []);

  // const statusChangeCallback = (response) => {
  //     if (response.status === 'connected') {
  //         UserActionCreators.facebookLoggedIn(response.authResponse);
  //     } else if (response.status === 'not_authorized') {
  //         //document.getElementById('status').innerHTML = 'Please log into this app.';
  //     } else {
  //         //document.getElementById('status').innerHTML = 'Please log into Facebook.';
  //     }
  // }

  // const checkLoginState = () => {
  //     window.FB.getLoginStatus(function (response) {
  //         statusChangeCallback(response);
  //     }.bind(this));
  // }

  return <div id="fb-root"></div>;
};

export default Facebook;
