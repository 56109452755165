import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorStore from "../stores/EditorStore";
import Lockr from "lockr";
import { toast, Flip } from "react-toastify";

const ThumbOverlay = (props) => {
  const navigate = useNavigate();
  const [showMoveTo, setShowMoveTo] = useState(false);
  const isPro = UserStore.isPro();

  const moveTo = () => {
    setShowMoveTo(!showMoveTo);
  };

  const moveToFolder = (event) => {
    const folder = event.currentTarget.attributes["datakey"].value;
    if (props.cutout) {
      props.cutout.folder = folder;
      EditorActionCreators.moveCutout(props.cutout);
    }

    if (props.asset) {
      props.asset.folder = folder;
      EditorActionCreators.moveCutout(props.asset);
    }

    if (props.project) {
      props.project.folder = folder;
      EditorActionCreators.moveProject(props.project);
    }

    if (props.photo) {
      props.photo.folder = folder;
      EditorActionCreators.movePhoto(props.photo);
    }
    setTimeout(() => {
      toast.success("Move to Folder Success!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip,
      });
    }, 1000);
    setShowMoveTo(false);
  };

  const onDuplicate = (event) => {
    if (props.project) {
      EditorActionCreators.duplicateProject(props.project);
      setTimeout(() => {
        toast.success("Duplicate Success!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Flip,
        });
      }, 1000);
    }
  };

  const onEdit = (event) => {
    if (props.isFree === false && !isPro) {
      UserActionCreators.showProGet(true);
      return;
    }

    if (props.background) {
      window.editor.addPicture(props.background.src);
      EditorActionCreators.ShowFilePicker(false);
    }

    if (props.cutout) {
      window.open(`/app/?url=${props.cutout.src}`, '_blank');
    }

    if (props.asset) {
      window.open(`/app/?url=${props.asset.src}`, '_blank');
    }

    if (props.project) {
      const isStringArray = props.project.tags.every(
        (tag) => typeof tag === "string"
      );
      const tags = isStringArray ? props.project.tags : props.project.oldTags;
      if (tags && tags.some((tag) => tag.includes("emoji"))) {
        Lockr.set("featuredApp", "emoji");
      } else if (tags && tags.some((tag) => tag.includes("meme"))) {
        Lockr.set("featuredApp", "meme");
      } else {
        Lockr.set("featuredApp", "pizap");
      }
      window.open(`/app/?project=${props.project.src}`, '_blank');
    }

    if (props.template) {
      Lockr.set("featuredApp", "pizap");
      window.open(`/app/?project=${props.template.src}`, '_blank');
    }

    if (props.photo) {
      window.open(`/app/?url=${props.photo.src}`, '_blank');
    }
  };



  const onTag = (event) => {
    EditorActionCreators.showTagDialog(props);
  };

  const tag = useMemo(() => {
    return (
      <React.Fragment>
        {UserStore.getUser() && UserStore.getUser().isAdmin && props.project ? (
          <div className="clickable" onClick={onTag}>
            <svg viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
              <path
                className="a"
                d="m121.5,64.2c-31.7,0-57.3,25.7-57.3,57.3 0,31.7 25.7,57.3 57.3,57.3 31.7,0 57.3-25.7 57.3-57.3 0.1-31.7-25.6-57.3-57.3-57.3zm0,94.3c-20.4,0-37-16.6-37-37s16.6-37 37-37c20.4,0 37,16.6 37,37s-16.5,37-37,37z"
              />
              <path
                className="a"
                d="m244.5,29.8c-10.4-11.5-25-17.7-40.7-17.7l-107.3-1.1c-22.9,0-44.8,8.3-60.5,25-16.7,15.7-25,37.6-25,60.5l1,107.4c1,14.6 6.3,29.2 17.7,40.7l256.5,256.4 214.8-214.8-256.5-256.4zm40.7,442l-241.9-241.9c-7.3-7.3-11.5-16.7-11.5-27.1l-1-106.3c0-16.7 7.3-33.4 18.8-45.9 12.5-12.5 29.2-19.8 46.9-19.8l106.3,1c10.4,0 19.8,4.2 27.1,11.5l241.9,241.9-186.6,186.6z"
              />
            </svg>
          </div>
        ) : null}
      </React.Fragment>
    );
  }, [UserStore.getUser(), props.project]);

  const moveComp = useMemo(() => {
    return (
      showMoveTo && (
        <React.Fragment>
          <div className="moveToPopup">
            {UserStore.getFolders().map((i, index) => {
              return (
                <div
                  key={index}
                  datakey={i.id}
                  className="moveToFolder clickable"
                  onClick={moveToFolder}
                >
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 697.7 556"
                  >
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M182.4,0.2c-65.5,0-104.3,0.3-116.5,0.9C53.3,1.7,44.2,3.6,38.6,6.9C8.4,26.2,1.8,36.8,1.4,81.2 C3.1,181-5.8,467.1,7.2,521.3c6,12.5,29.4,28.2,44.5,31.4c-6.4,1.6,184.7,3.9,232.4,2.9v0.3c152.1,0.1,291,0.4,361.7-2.9 c15.1-3.5,38.6-18.9,44.7-31.7c2.3-5,4-14.2,4.9-27.6c-1.5-15.5,8.4-271.2-4.1-347.4c-11.5-24.7-27.9-39.1-57.8-40.7 c-20.9-5-239.3,5.1-252.7-14.5c-7.3-11.1-16.2-45.6-20.3-51.1C325.7-17.3,286.6,5.5,182.4,0.2 M319.8,79.5 c5.2,14.5,7.5,22.2,6.7,22.9c12.7,1.1-286.2,2.4-273.6,1.2c-1.4,1.1-1.6-39.3-0.3-40.4c10.2-18.4,53.1-9.3,128.4-12.2 c77.7,0,119.1,1.1,124.3,3.2C309.9,56.6,314.8,65,319.8,79.5 M346.5,155.6c79.5,1,221.3-2.2,289,4.1c11,5.6,6.2,19.5,7.8,43.6 c5.9,67,2,226.4,2,265.8c-2.5,39.9-4,33.7-43.9,35.4c-88-0.9-218.2,0.4-366.9-0.3c-71.8,0-118,0.1-138.6,0.3 c-20.6,0.2-32.9-1.2-36.9-4.1c-4-2.9-6.4-13.4-7-31.4c-1.8-12.6-0.2-130.8-0.9-185.3c0.1-54.8,0.4-87.8,0.9-99.1 c0.6-14.1,2.4-22.3,5.5-24.4C119.8,151.5,227.7,157.6,346.5,155.6z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <span>{i.name}</span>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )
    );
  });

  const duplicate = useMemo(() => {
    return (
      <React.Fragment>
        {props.project && (
          <div className="clickable" onClick={onDuplicate}>
            <svg viewBox="0 0 387.1 388">
              <title>Duplicate</title>
              <path
                className="a"
                d="M307.6,100.9c0-13.7-6.9-20.6-20.6-20.6H20.6C6.9,80.4,0,87.3,0,100.9v266.5C0,381.1,6.9,388,20.6,388H287
                                    c13.7,0,20.6-6.9,20.6-20.6V100.9 M263.4,340.7H40.6V117.9h222.8V340.7 M387.1,20.6c0-13.7-6.8-20.6-20.5-20.6H100.1
                                    C86.4,0,79.5,6.8,79.5,20.6v16.3l263.4,0.6l-1.5,270.1h25.2c13.7,0,20.5-6.9,20.5-20.6V20.6z"
              />
            </svg>
          </div>
        )}
      </React.Fragment>
    );
  }, [props]);

  const deleteComp = useMemo(() => {
    return (
      <React.Fragment>
        {!props.template && (
          <div
            className="clickable"
            onClick={() => {
              EditorStore.setShowProjectDel(true, props);
            }}
          >
            <svg viewBox="0 0 339.8 406.1">
              <title>Delete</title>
              <path
                className="a"
                d="M325.2,75.8c0.2-2.9-1.5-4.6-5-5.3h-4.9v-9c0-2.7-1.5-4.6-4.7-5.6h-61.2V37.1c-5.4-20.6-16.7-33-34-37.1
                                h-91c-17.3,4.1-28.6,16.5-34,37.1V56H29.2c-3.1,1-4.7,2.9-4.6,5.6v9h-4.9c-3.5,0.6-5.1,2.4-4.9,5.3v17.9H5
                                c-3.7,0.2-5.3,1.9-4.9,4.9v26.6h32.5L32,368c0.2,0.8,0.4,1.8,0.6,2.8c3.9,16.9,16.4,28.6,37.4,35.3h199.8
                                c21-6.6,33.5-18.4,37.4-35.3c0.2-1,0.5-2,0.7-2.8l-0.7-242.8h32.5V98.7c0.4-3.1-1.2-4.8-4.9-4.9h-9.6V75.8 M125.7,42
                                c0.4-4.3,3.3-7.4,8.7-9.3c1.7-0.4,3.4-0.8,5.3-1.2h60.6c1.9,0.4,3.6,0.8,5.3,1.2c5.4,1.9,8.3,5,8.6,9.3V56h-88.5V42 M66,372.7
                                V121.8h207.9v250.8H66 M91,152.1v191.8h32.8V152.1H91 M186.3,152.1h-32.8v191.8h32.8V152.1 M248.8,152.1H216v191.8h32.8V152.1z"
              />
            </svg>
          </div>
        )}
      </React.Fragment>
    );
  }, [props]);

  const moveIcon = useMemo(() => {
    return (
      <React.Fragment>
        {UserStore.getFolders() !== null &&
          UserStore.getFolders().length > 0 &&
          !props.template &&
          !props.cutout &&
          !props.asset && (
            <div className="clickable" onClick={moveTo}>
              <svg viewBox="0 0 511.8 393.9">
                <title>Move to folder</title>
                <path
                  className="a"
                  d="M511.1,379.6l0.7-336c-1.1-6.6-5.3-9.8-12.6-9.4l-308.6,1.9c-8.1,0-14.9-4.4-20.4-13.3l-9.5-13.3
                                c-4.4-5.7-10.1-8.8-17.2-9.5L24.2,0.3C11.8,2.2,4.5,10,2.4,23.7L0,377.1c1.1,11.5,6.7,17.1,16.7,16.8h480.1
                                C506.5,393.7,511.3,389,511.1,379.6 M282.2,93L406,217L282.2,340.9v-75.4H107.1v-96h175.2V93z"
                />
              </svg>
            </div>
          )}
      </React.Fragment>
    );
  });

  return (
    <div className="thumbOverlay">
      <div className="thumbOverlayTop">
        {props.hideActions === true ? null : moveIcon}
        {props.hideActions === true ? null : duplicate}
        {props.hideActions === true ? null : deleteComp}
        {props.hideActions === true ? null : moveComp}
        {props.hideActions === true ? null : tag}
      </div>
      <div className="thumbOverlayEdit clickable" onClick={onEdit}></div>
      <div className="thumbOverlayBottom">
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default ThumbOverlay;
