import EditorDispatcher from "../dispatcher/EditorDispatcher";
import { EventEmitter } from "events";
import assign from "object-assign";
import Lockr from "lockr";
import JSONUtils from "../utils/JSONUtils";
import ActionTypes from "../constants/EditorConstants";

EventEmitter.defaultMaxListeners = 15;
var CHANGE_EVENT = "change";
var _user = Lockr.get("userV2");
var _access_token = Lockr.get("access_token");
var _showPro = false;
var _showProGet = false;
var _showProGetSave = false;
var _showLogin = false;
var _showHamburgerMenu = false;
var _loginMode;
var _postLoginUrl;
var _cutouts;
var _folders;
var _projectAssets;
var _featuredTemplates;
var _appTemplates = [];
var _projects;
var _photos;
var _statusMessage = "";
var _uploadProgress = 0;
var _loginMessage = "";
var _forgotResponse;
var _emailResponse;
var _showTagDialog = false;
var _selectedProject = null;
var _selectedProjectTags = [];
var _selectedProjectTagProjects = [];

function addUser(user) {
  if (!user || !user.user || !user.access_token) return;
  var logins = Lockr.get("logins");
  if (!logins) logins = [];
  var index = -1;
  for (var x = 0; x < logins.length; x++) {
    if (logins[x].user.UserName === user.user.UserName) {
      index = x;
    }
  }
  if (index >= 0) {
    logins.splice(index, 1);
  }
  logins.unshift(user);
  Lockr.set("logins", logins);
}

const UserStore = assign({}, EventEmitter.prototype, {
  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */

  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  getLastLogins: function () {
    return Lockr.get("logins");
  },
  getLegacyUser: function () {
    return Lockr.get("user");
  },
  setStatusMessage: function (message) {
    _statusMessage = message;
    UserStore.emit();
  },
  getForgotResponse: function () {
    return _forgotResponse;
  },
  getShowTagDialog: function () {
    return _showTagDialog;
  },
  getSelectedProject: function () {
    return _selectedProject;
  },
  getSelectedProjectTags: function () {
    return _selectedProjectTags;
  },
  getSelectedProjectTagProjects: function () {
    return _selectedProjectTagProjects;
  },
  getStatusMessage: function () {
    return _statusMessage;
  },
  getUploadProgress() {
    return _uploadProgress;
  },
  setLoginMessage: function (message) {
    _loginMessage = message;
    UserStore.emit();
  },
  getLoginMessage: function () {
    return _loginMessage;
  },
  setEmailResponse: function (response) {
    _emailResponse = response;
    UserStore.emitChange();
  },
  getEmailResponse: function () {
    return _emailResponse;
  },
  colorPicked: function (colorCode) {
    var colorCodes = Lockr.get("colors");
    var newColors = [];
    if (colorCodes) {
      if (Array.isArray(colorCodes)) {
        for (var x = 0; x < colorCodes.length; x++) {
          if (colorCodes[x] !== colorCode) newColors.push(colorCodes[x]);
        }
      }
    }
    newColors.unshift(colorCode);

    Lockr.set("colors", newColors.slice(0, 9));
  },
  getColorHistory: function () {
    return Lockr.get("colors");
  },
  appendProjectAssets: function (newAsset) {
    if (_projectAssets) _projectAssets = [newAsset, ..._projectAssets];
    if (this.getAccessToken() !== "" && !_projectAssets) {
      _projectAssets = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/projectAssets`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _projectAssets = [newAsset, ...resJson];
        })
        .catch((e) => console.log(e));
      UserStore.emitChange();
    }
  },
  getProjectAssets: function () {
    if (_projectAssets) return _projectAssets;
    if (this.getAccessToken() !== "" && !_projectAssets) {
      _projectAssets = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/projectAssets`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _projectAssets = resJson;
          UserStore.emitChange();
        })
        .catch((e) => console.log(e));
    }
    return _projectAssets;
  },
  getFeaturedTemplates: function () {
    if (_featuredTemplates) return _featuredTemplates;
    if (this.getAccessToken() !== "" && _featuredTemplates == null) {
      _featuredTemplates = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/projectTag/tag/featured`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _featuredTemplates = resJson;
          UserStore.emitChange();
        });
    }

    return [];
  },
  getApplications: function (appName) {
    if (_appTemplates && _appTemplates[appName]) return _appTemplates[appName];
    return [];
  },
  setUser: function (user) {
    _user = user;
    Lockr.set("userV2", user);
    if (user && user.Email) {
      window.profitwell("start", { user_email: user.Email });
    }
    UserStore.emitChange();
  },

  setCutouts: function (cutouts) {
    _cutouts = cutouts;
    UserStore.emitChange();
  },
  getCutouts: function () {
    if (_cutouts) return _cutouts;
    if (this.getAccessToken() !== "" && _cutouts == null) {
      _cutouts = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/user/cutouts`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _cutouts = resJson;
          UserStore.emitChange();
        })
        .catch((e) => console.log(e));
    }
  },
  getProjects: function () {
    if (_projects) return _projects;
    else if (this.getAccessToken() !== "") {
      _projects = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/projects`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _projects = resJson;
          UserStore.emitChange();
        })
        .catch((e) => {
          console.log(e);
        });
    }
    return [];
  },
  getPhotos: function () {
    if (_photos) return _photos;
    if (this.getAccessToken() !== "" && _photos == null) {
      _photos = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/user/photos`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _photos = resJson;
          UserStore.emitChange();
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return [];
  },
  getFolders: function () {
    if (_folders) return _folders;
    if (this.getAccessToken() !== "" && _folders == null) {
      _folders = [];
      fetch(`${process.env.REACT_APP_SERVER_API}/folder`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + this.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (resJson) {
          _folders = resJson;
          UserStore.emitChange();
        })
        .catch((e) => console.log(e));
    }
    return [];
  },
  addCutout: function (item) {
    if (!_cutouts) {
      _cutouts = [];
    }
    _cutouts.push(item);
  },
  getUser: function () {
    return _user;
  },
  isPro: function () {
    if (_user && _user.isPRO) return true;

    return false;
  },
  showAds: function () {
    if (_user && _user.isPRO) return false;
    return true;
  },
  showPro: function () {
    return _showPro;
  },
  showProGet: function () {
    return _showProGet;
  },
  showProGetSave: function () {
    return _showProGetSave;
  },
  postLoginUrl: function () {
    return _postLoginUrl;
  },
  showLogin: function () {
    return _showLogin;
  },
  getAccessToken: function () {
    return _access_token;
  },
  getShowHamburgerMenu: function () {
    return _showHamburgerMenu;
  },
  getLoginMode: function () {
    return _loginMode;
  },

  processLoggedIn: function (action) {
    if (Array.isArray(action.data)) {
      var allUsers = action.data;
      if (allUsers.length > 0) {
        for (var x = 0; x < allUsers.length; x++) {
          addUser(allUsers[x]);
        }
        action.data = allUsers[0];
      }
    }
    _user = action.data.user;
    Lockr.set("account", action.data);
    Lockr.set("userV2", action.data.user);
    if (action.data.access_token) {
      Lockr.set("access_token", action.data.access_token);
      addUser(action.data);
    }
    window.dispatchEvent(new Event("resize"));
    if (_user && _user.UserName) {
      _showLogin = false;
      // eb.registerHandler('notifications.' + _user.UserName, messageHandler);
      var redirectUrl =
        decodeURIComponent(
          (new RegExp("[?|&]redirectUrl=([^&;]+?)(&|#|;|$)").exec(
            window.location.search
          ) || [null, ""])[1].replace(/\+/g, "%20")
        ) || null;
      if (redirectUrl) {
        if (redirectUrl.includes("http")) {
          window.location.href = redirectUrl.replace("%USER", _user.UserName);
        } else {
          window.location.href =
            redirectUrl +
            "?jwt=" +
            encodeURIComponent(action.data.access_token);
        }
      } else {
        window.location.href = "/app/start";
      }
    }

    if (_user && _postLoginUrl) {
      if (_postLoginUrl === "showProGetSave") {
        _loginMode = null;
        _showPro = false;
        _showProGet = false;
        if (!UserStore.isPro()) {
          _showProGetSave = true;
        }
        UserStore.emitChange();
      } else {
        var win = window.open(_postLoginUrl, "_blank");
        win.focus();
        _postLoginUrl = null;
      }
    }
    UserStore.emitChange();
  },
});

UserStore.dispatchToken = EditorDispatcher.register(function (action) {
  var formData, obj;
  switch (action.type) {
    case ActionTypes.USER_LEGACY_LOGGED_IN:
      if (
        action.data &&
        action.data.fragment &&
        action.data.fragment.access_token
      ) {
        obj = { legacyToken: action.data.fragment.access_token };
        fetch(`${process.env.REACT_APP_SERVER_API}/auth/legacytoken`, {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
          body: JSON.stringify(obj),
        })
          .then(function (res) {
            return res.json();
          })
          .then(function (userLoggedIn) {
            if (userLoggedIn && userLoggedIn.user) {
              var newObj = { data: userLoggedIn };
              UserStore.processLoggedIn(newObj);
            }
            UserStore.emitChange();
          });
      }
      break;
    case ActionTypes.USER_LOGGED_IN:
      UserStore.processLoggedIn(action);
      break;
    case ActionTypes.FORGOT_PASSWORD:
      _forgotResponse = null;
      UserStore.emitChange();
      fetch(`${process.env.REACT_APP_SERVER_API}/user/change_password`, {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          username: action.data,
        }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          _forgotResponse = data;
          UserStore.emitChange();
        });

      break;
    case ActionTypes.LOG_USER_IN:
      fetch(`${process.env.REACT_APP_SERVER_API}/auth`, {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: action.data,
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          if (data.message) {
            _loginMessage = data.message;
            UserStore.emitChange();
            return;
          }
          var newObj = { data: data };
          UserStore.processLoggedIn(newObj);
        });
      break;
    case ActionTypes.SHOW_SAVE_QUALITY:
      _showProGet = false;
      _showProGetSave = false;
      UserStore.emitChange();
      break;
    case ActionTypes.LOGOUT:
      _user = null;
      _showHamburgerMenu = false;
      Lockr.rm("userV2");
      Lockr.rm("user");
      Lockr.rm("access_token");
      UserStore.emitChange();
      window.dispatchEvent(new Event("resize"));
      window.location.href = "/app/login";
      break;
    case ActionTypes.SHOW_PRO:
      _loginMode = null;
      _showPro = action.data;
      _showProGet = false;
      if (_showPro) {
        if (!process.versions.hasOwnProperty("electron")) {
          window.fbq("track", "ViewContent", {
            value: 5,
            currency: "USD",
          });
        }
      }
      UserStore.emitChange();
      break;
    case ActionTypes.SHOW_TAG_DIALOG:
      if (action.value === false) {
        _showTagDialog = false;
        _selectedProject = null;
        _selectedProjectTags = [];
      } else {
        _showTagDialog = true;
        _selectedProject = action.value;
        _selectedProjectTags = [];
        fetch(
          `${process.env.REACT_APP_SERVER_API}/projectTag/` +
            action.value.project.imageName,
          {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          }
        )
          .then(function (res) {
            return res.json();
          })
          .then(function (data) {
            _selectedProjectTags = data;
            UserStore.emitChange();
          });
      }
      UserStore.emitChange();
      break;
    case ActionTypes.ADD_TAG:
      var body = {
        imageName: _selectedProject.project.imageName,
        tag: action.value,
      };
      fetch(`${process.env.REACT_APP_SERVER_API}/projectTag`, {
        method: "post",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(body),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          _selectedProjectTags = [..._selectedProjectTags, data];
          UserStore.emitChange();
        });
      break;
    case ActionTypes.GET_TAG:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/projectTag/tag/${action.data}`,
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          var finalData = [];
          if (data !== null && data.length > 0) {
            data.forEach((item) => {
              if (item != null && item.userName !== null) finalData.push(item);
            });
          }
          _selectedProjectTagProjects = finalData;
          UserStore.emitChange();
        });
      break;
    case ActionTypes.GET_APPLICATIONS:
      fetch(
        `${
          process.env.REACT_APP_SERVER_API
        }/pizapTemplate/featured?app=${encodeURIComponent(action.data)}`,
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (featured) {
          featured.forEach((category) => {
            if (category.photoResponses) {
              category.photoResponses.sort(function (a, b) {
                var isPROa = false;
                if (a.tags) {
                  a.tags.forEach((tag) => {
                    if (tag === "pizapPRO") isPROa = true;
                  });
                }

                var isPROb = false;
                if (b.tags) {
                  b.tags.forEach((tag) => {
                    if (tag === "pizapPRO") isPROb = true;
                  });
                }

                if (isPROa && !isPROb) return 1;
                if (isPROb && !isPROa) return -1;

                return 0;
              });
            }
          });

          _appTemplates[action.data] = featured;
          UserStore.emitChange();
        });
      break;

    case ActionTypes.DELETE_TAG:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/projectTag/${
          _selectedProject.project.imageName
        }/${_selectedProjectTags[action.value].text}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      ).then(function (res) {
        _selectedProjectTags = _selectedProjectTags.filter(
          (tag, index) => index !== action.value
        );
        UserStore.emitChange();
      });
      break;
    case ActionTypes.SHOW_PRO_GET:
      _loginMode = null;
      _showProGet = action.data;
      UserStore.emitChange();
      break;
    case ActionTypes.SHOW_PRO_GET_SAVE:
      _loginMode = null;
      _showProGetSave = action.data;
      UserStore.emitChange();
      break;
    case ActionTypes.SHOW_LOGIN:
      _loginMode = action.mode;
      _showLogin = action.data;
      _postLoginUrl = action.postLoginUrl;
      UserStore.emitChange();
      break;
    case ActionTypes.POST_LOGIN_URL:
      _postLoginUrl = action.data;
      UserStore.emitChange();
      break;
    case ActionTypes.UPLOAD_PROGRESS:
      _uploadProgress = action.data;
      UserStore.emitChange();
      break;
    case ActionTypes.UPGRADE_PURCHASED:
      if (
        _user &&
        _user.Upgrades &&
        _user.Upgrades.indexOf(action.value) >= 0
      ) {
      } else {
        _user.Upgrades.push(action.data.toString());
        _showPro = false;
        UserStore.emitChange();
        if (!process.versions.hasOwnProperty("electron")) {
          window.fbq("track", "Purchase", {
            value: 5,
            currency: "USD",
          });
        }
      }
      break;
    case ActionTypes.EDITOR_OBJECT_SELECTED:
      _showHamburgerMenu = false;
      UserStore.emitChange();
      break;
    case ActionTypes.SHOW_HAMBURGER_MENU:
      _showHamburgerMenu = action.data;
      UserStore.emitChange();
      break;
    case ActionTypes.LOGIN_MODE:
      _loginMode = action.data;
      UserStore.emitChange();
      break;
    case ActionTypes.PICKER_STATE:
      if (action.value === "cutout" && !_cutouts) {
        UserStore.getCutouts();
      }
      break;
    case ActionTypes.PROFILE_PHOTO:
      var url = action.data;
      _user.ProfileImage = url;
      UserStore.emitChange();
      formData = "imagename=" + encodeURIComponent(url);
      JSONUtils.getJSON(
        "https://www.pizap.com/set_profile_photo?access_token=" +
          UserStore.getAccessToken(),
        "POST",
        formData,
        function (data) {}
      );
      break;
    case ActionTypes.UPDATE_EMAIL:
      obj = { userName: _user.UserName, email: action.data };
      fetch(`${process.env.REACT_APP_SERVER_API}/user/me`, {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (updatedUser) {
          _user = updatedUser;
          UserStore.emitChange();
        });
      break;
    case ActionTypes.CREATE_FOLDER:
      fetch(`${process.env.REACT_APP_SERVER_API}/folder`, {
        method: "post",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({ name: action.data }),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (newFolder) {
          if (!_folders) _folders = [];
          _folders.push(newFolder);
          UserStore.emitChange();
        });

      break;
    case ActionTypes.UPDATE_FOLDER:
      fetch(`${process.env.REACT_APP_SERVER_API}/folder/` + action.data.id, {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(action.data),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (newFolder) {
          UserStore.emitChange();
        });
      break;
    case ActionTypes.DELETE_FOLDER:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/folder/${encodeURIComponent(
          action.data
        )}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      ).then((res) => {
        fetch(`${process.env.REACT_APP_SERVER_API}/folder`, {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        })
          .then(function (res) {
            return res.json();
          })
          .then(function (resJson) {
            _folders = resJson;
            UserStore.emitChange();
          });
      });
      break;

    case ActionTypes.DELETE_CUTOUT:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/photos/asset/${encodeURIComponent(
          action.data.imageName
        )}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      ).then(function (res) {
        if (_cutouts) {
          _cutouts = _cutouts.filter(function (obj) {
            return obj.imageName !== action.data.imageName;
          });
        }
        UserStore.emitChange();
      });
      break;
    case ActionTypes.DELETE_PROJECT:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/projects/${action.data.imageName}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      ).then(function (res) {
        if (_projects) {
          _projects = _projects.filter(function (obj) {
            return obj.imageName !== action.data.imageName;
          });
        }
        UserStore.emitChange();
      });
      break;
    case ActionTypes.DUPLICATE_PROJECT:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/projects?source=${action.data.imageName}`,
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (res) {
          if (_projects) {
            _projects = [res, ..._projects];
            UserStore.emitChange();
          }
        });
      break;
    case ActionTypes.DELETE_PHOTO:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/photos/${encodeURIComponent(
          action.data.imageName
        )}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      ).then(function (res) {
        if (_photos) {
          _photos = _photos.filter(function (obj) {
            return obj.imageName !== action.data.imageName;
          });
        }
        UserStore.emitChange();
      });
      break;
    case ActionTypes.MOVE_CUTOUT:
      obj = { imageName: action.data.imageName, folder: action.data.folder };
      fetch(`${process.env.REACT_APP_SERVER_API}/photos/asset`, {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
      }).then(function (res) {
        var folders = UserStore.getFolders();
        var folderName = "unknown";
        for (var x = 0; x < folders.length; x++) {
          if (folders[x].id === action.data.folder) {
            folderName = folders[x].name;
          }
        }
        UserStore.setStatusMessage("Cutout moved to " + folderName);
        setTimeout(function () {
          UserStore.setStatusMessage("");
          UserStore.emitChange();
        }, 1000);
        UserStore.emitChange();
      });
      break;
    case ActionTypes.MOVE_PHOTO:
      obj = { imageName: action.data.imageName, folder: action.data.folder };
      fetch(`${process.env.REACT_APP_SERVER_API}/photos`, {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
      }).then(function (res) {
        var folders = UserStore.getFolders();
        var folderName = "unknown";
        for (var x = 0; x < folders.length; x++) {
          if (folders[x].id === action.data.folder) {
            folderName = folders[x].name;
          }
        }
        UserStore.setStatusMessage("Image moved to " + folderName);
        setTimeout(function () {
          UserStore.setStatusMessage("");
          UserStore.emitChange();
        }, 1000);

        UserStore.emitChange();
      });
      break;
    case ActionTypes.MOVE_PROJECT:
      obj = { imageName: action.data.imageName, folder: action.data.folder };
      fetch(`${process.env.REACT_APP_SERVER_API}/projects`, {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
      }).then(function (res) {
        var folders = UserStore.getFolders();
        var folderName = "unknown";
        for (var x = 0; x < folders.length; x++) {
          if (folders[x].id === action.data.folder) {
            folderName = folders[x].name;
          }
        }
        UserStore.setStatusMessage("Project moved to " + folderName);
        setTimeout(function () {
          UserStore.setStatusMessage("");
          UserStore.emitChange();
        }, 1000);
        UserStore.emitChange();
      });
      break;
    case ActionTypes.UPDATE_NAME:
      obj = { userName: _user.UserName, name: action.data };
      fetch(`${process.env.REACT_APP_SERVER_API}/user/me`, {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (updatedUser) {
          _user = updatedUser;
          UserStore.emitChange();
        });
      break;
    case ActionTypes.DELETE_ASSET:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/projectAssets/${action.data.imageName}`,
        {
          method: "delete",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      ).then(function (res) {
        if (_projectAssets) {
          _projectAssets = _projectAssets.filter(function (obj) {
            return obj.imageName !== action.data.imageName;
          });
        }
        UserStore.emitChange();
      });

      break;
    default:
    // do nothing
  }
});

export default UserStore;
