import EditorDispatcher from "../dispatcher/EditorDispatcher";
import EditorStore from "./EditorStore";
import { EventEmitter } from "events";
import assign from "object-assign";
import ActionTypes from "../constants/EditorConstants";

EventEmitter.defaultMaxListeners = 15;
let CHANGE_EVENT = "change";
let _selectedMenu = "init";
let _resetMenu = false;

const LeftMenuStore = assign({}, EventEmitter.prototype, {
  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },
  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  get: function () {
    return _selectedMenu;
  },
  setMenuSelected: function (menu) {
    _selectedMenu = menu;
    LeftMenuStore.emitChange();
  },
  isResetMenu: function () {
    return _resetMenu;
  },
});

LeftMenuStore.dispatchToken = EditorDispatcher.register(function (action) {
  switch (action.type) {
    case ActionTypes.LEFT_MENU_SELECTED:
      _selectedMenu = action.menu;
      EditorStore.setShowColorPicker(false);
      EditorStore.setColorSelectorOpen(false);
      let mainContent = document.getElementById("mainContent");
      let baseCanvas = mainContent?.getElementsByTagName("canvas");
      if (_selectedMenu === "paint") {
        const bSize = parseInt(EditorStore.getBrushSize(), 10);
        const canvas = document.createElement("canvas");
        canvas.width = bSize;
        canvas.height = bSize;
        const ctx = canvas.getContext("2d");
        ctx.beginPath();
        ctx.arc(
          canvas.width / 2,
          canvas.width / 2,
          bSize / 2,
          0,
          2 * Math.PI,
          false
        );
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#FFFFFF";
        ctx.stroke();
        const brushImage = canvas.toDataURL("image/png");
        baseCanvas[0].classList.add("cursor-image");
        const root = document.querySelector(":root");
        root.style.setProperty(
          "--custom-cursor",
          "url(" + brushImage + "), auto"
        );
      } else if (baseCanvas) {
        if (baseCanvas[0]?.classList)
          baseCanvas[0].classList.remove("cursor-image");
      }
      LeftMenuStore.emitChange();
      break;
    case ActionTypes.RESET_MENU:
      _resetMenu = action.reset;
      LeftMenuStore.emitChange();
      break;
    case ActionTypes.SHOW_FILE_PICKER:
      if (action.menu) {
        _selectedMenu = action.menu;
        LeftMenuStore.emitChange();
      }
      break;
    case ActionTypes.COLLAGE_HOTSPOT_ACTIVE_SELECTED:
      if (action.hotspot !== null) {
        _selectedMenu = "collagehotspot";
      } else {
        if (EditorStore.getEditorType() === "design") {
          _selectedMenu = "design";
        } else {
          _selectedMenu = "collage";
        }
      }
      LeftMenuStore.emitChange();
      break;
    default:
      break;
  }
});

export default LeftMenuStore;
