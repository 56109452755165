import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonLoading = ({ width, height }) => {
  return (
    <SkeletonTheme
      baseColor="#222429"
      width={width}
      height={height}
      highlightColor="#262b33"
    >
      <Skeleton count={1} />
    </SkeletonTheme>
  );
};

export default SkeletonLoading;
