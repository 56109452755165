import React, { useEffect, useState } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import { ReactComponent as QualitySVG } from "../images/editor/quality_icon.svg";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

function getStateFromStores() {
  return {
    user: UserStore.getUser(),
  };
}

const ProGet = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const onClose = () => {
    UserActionCreators.showProGet(false);
  };

  const _startTrial = () => {
    UserActionCreators.showPro(true);
  };

  const _signin = (event) => {
    UserActionCreators.showLogin(true);
  };

  let loginMessage = (
    <div className="normalText">
      <span>Already a Pro user? </span>
      <span className="clickable" onClick={_signin}>
        Log In
      </span>
    </div>
  );
  if (data.user && data.user.UserName) {
    loginMessage = "";
  }

  return (
    <div className="filepicker-dialog active">
      <div className="trial-dialog active">
        <div className="close-btn" onClick={onClose}>
          <CloseSVG height={12} />
        </div>
        <div className="trial-container">
          <div className="left-side">
            <div className="justify-content">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1500 1080"
                enableBackground="new 0 0 1500 1080"
                height="14"
              >
                <g transform="matrix( 8.704086303710938, 0, 0, 8.704086303710938, 109.4,28) ">
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Symbol_1_0_Layer0_0_FILL">
                        <path
                          fill="#FFC200"
                          d="M19.4,112.5c0,1.4,0.5,2.7,1.5,3.7s2.2,1.5,3.6,1.5h98.2c1.4,0,2.7-0.5,3.7-1.5s1.5-2.2,1.5-3.7
        s-0.5-2.7-1.5-3.7c-1-1-2.2-1.5-3.7-1.5H24.5c-1.4,0-2.6,0.5-3.6,1.5C19.9,109.9,19.4,111.1,19.4,112.5 M127.9,40.3
        c0,2.7,0.9,4.9,2.8,6.8c0.1,0.1,0.2,0.2,0.2,0.2l-30.2,16L76.9,18.8c1.3-0.5,2.5-1.3,3.6-2.3c1.9-1.9,2.8-4.1,2.8-6.8
        s-0.9-4.9-2.8-6.8c-0.4-0.4-0.8-0.7-1.2-1C77.7,0.6,75.8,0,73.7,0c-0.1,0-0.2,0-0.3,0c-2.5,0.1-4.7,1-6.5,2.8
        C65,4.7,64.1,7,64.1,9.6s0.9,4.9,2.8,6.8c1,1,2.2,1.8,3.5,2.2l-24,44.7l-30.1-16c0.1-0.1,0.1-0.2,0.2-0.2
        c1.9-1.9,2.8-4.1,2.8-6.8c0-2.7-0.9-5-2.8-6.8c-1.9-1.9-4.1-2.8-6.8-2.8c-0.8,0-1.5,0.1-2.2,0.2c-1.7,0.4-3.3,1.2-4.6,2.5
        c-1.5,1.5-2.4,3.3-2.7,5.4C0,39.4,0,39.8,0,40.3c0,2.7,0.9,4.9,2.8,6.8C4.7,49,7,50,9.6,50c0.6,0,1.2,0,1.8-0.2l10.2,49.3h104
        l10.2-49.3c0.6,0.1,1.1,0.2,1.8,0.2c2.7,0,4.9-1,6.8-2.8c1.9-1.9,2.8-4.1,2.8-6.8c0-0.5,0-1-0.1-1.4c-0.3-2.1-1.2-3.9-2.7-5.4
        c-1.4-1.3-2.9-2.2-4.6-2.5c-0.7-0.2-1.4-0.2-2.2-0.2c-2.7,0-4.9,0.9-6.8,2.8C128.8,35.4,127.9,37.7,127.9,40.3z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              &nbsp;
              <span className="title">Upgrade to use this feature</span>
            </div>
            <div className="mt-5">
              <span className="try-text">Try piZap Pro for free</span>
            </div>
            <div className="pro-description">
              <div>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 636.9 578"
                  height={18}
                  enableBackground="new 0 0 636.9 578"
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M17.3,468.6c9.2,19.4,21.9,37.3,38.4,53.7C92.8,559.4,137.5,578,190,578h256.9c52.5,0,97.3-18.5,134.4-55.7
				c2.7-2.7,5.3-5.4,7.8-8.2L453.6,382c-6.6-6.1-13.2-6.3-19.8-0.5l-78,74.8l-13.5,12.3l-7-7c-1.7-1.1-3.3-2.6-4.7-4.7L182.4,276.1
				L17.3,468.6 M581.3,55.7C544.2,18.6,499.4,0,446.9,0H190C137.5,0,92.8,18.6,55.7,55.7C18.5,92.7,0,137.5,0,190v190.1l132.8-155.1
				c36.7-39,71-40,102.9-2.9l118.2,147L399,324c7.3-10,14.8-17.5,22.5-22.6l25.4-25.4l33,32.9c5,4.2,10,9.2,15,15.1l130.6,130.5
				c7.6-20.6,11.4-42.8,11.4-66.5V190C636.9,137.5,618.4,92.7,581.3,55.7 M447,145.9c14.4,0,26.6,5.1,36.8,15.2
				c10.2,10.2,15.2,22.4,15.2,36.8c0,14.4-5.1,26.6-15.2,36.8c-10.1,10.2-22.4,15.2-36.8,15.2s-26.6-5.1-36.8-15.2
				c-10.1-10.1-15.2-22.4-15.2-36.8c0-14.4,5.1-26.6,15.2-36.8C420.4,151,432.6,145.9,447,145.9z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span>
                Unlock millions of{" "}
                <b>premium photos, templates, graphics, filters, fonts</b> and
                more
              </span>
            </div>
            <div className="pro-description">
              <div>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 800 895.8"
                  enableBackground="new 0 0 800 895.8"
                  height={20}
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M423.4,80.9l62.5,18.6l19.9,65.5l18.4-64.1l63.4-19.7l-63.1-19.2L505,0l-18.9,62.7L423.4,80.9 M126.6,168.5
				l79.6-24.7L127,119.6l-24.5-77.8l-23.8,78.7L0,143.4l78.5,23.4l25,82.3L126.6,168.5 M696.2,421.8l-23.8,78.8l-78.8,22.8
				l78.6,23.5l25,82.4l23.1-80.6l79.7-24.8l-79.3-24.2L696.2,421.8 M527.8,458.8l-59.7-59.6C321,544.8,176,690.9,33,837.5
				c0,0-0.1,0.1-0.2,0.2c-5,5.4-5,12.1,0.2,20l33.3,34.2c8.7,5.2,17.4,5.2,26.2,0C237.6,748.9,382.7,604.5,527.8,458.8 M689.6,202.6
				c-7-4.9-14-5-21-0.4C612,257.3,555.8,312.5,499.8,367.8l59.5,59.5c54.6-54.9,109.1-109.9,163.6-165.2c7.1-8.7,7.1-17.4,0-26.1
				L689.6,202.6 M375.3,254.1l-39.2-124.5l-38.1,126l-126,36.5l125.7,37.6l40,131.8l37-129L502.2,293L375.3,254.1z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span>
                Design anything with{" "}
                <b>Magic AI Background Remover, Magic Resize </b>
                and <b>AI Image Maker</b>
              </span>
            </div>
            <div className="pro-description">
              <div>
                <QualitySVG height={20} />
              </div>
              <span>
                Design like a pro with <b>No Ads</b> or <b>watermarks</b> and
                save images at
                <b> high-resolution</b>
              </span>
            </div>
            <div className="pro-description">
              <div>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 719.4 668.3"
                  enableBackground="new 0 0 719.4 668.3"
                  height={20}
                >
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <g>
                      <g id="Layer0_0_FILL">
                        <path
                          fill="#FFFFFF"
                          d="M481,59.8L481,59.8C440.4,20,391.3,0,333.8,0c-57.5,0-106.6,20-147.3,59.8v0c-40.2,39.5-60.6,87-61.2,142.5
				c-25.1,9.4-47.9,24.4-68.3,44.9C19,285.1,0,330.9,0,384.5c0,53.6,19,99.4,56.9,137.3c37.9,37.9,83.7,56.9,137.3,56.9
				c21.8,0,42.3-3.1,61.5-9.4c-0.1-20-0.5-40-1.2-60c-18.2,8.9-38.3,13.4-60.3,13.4c-38.2,0-70.8-13.5-97.8-40.5
				c-27-27-40.5-59.5-40.5-97.7c0-38.2,13.5-70.8,40.5-97.8c25.7-25.6,56.4-39.1,92.2-40.3c-1-4.6-2.1-9.8-3.1-15.6
				c-2.1-11.5-3.2-20.1-3.2-25.9c0-40.7,14.8-75.5,44.1-104.4h0.1c29.6-29,65.3-43.5,107.2-43.5c41.9,0,77.6,14.5,107.2,43.5
				c29.4,28.9,44.1,63.7,44.2,104.4c0,11.1-1.1,21.8-3.3,32c-0.3,0.6-1,3-2.2,7.4c-1.2,4.2-1.8,6.5-1.9,7.1c0,0.3-0.1,0.6-0.2,0.9
				c14.1-4.5,29.1-6.8,45-6.8c36.6,0,68.1,11.9,94.6,35.6c1.7,1.5,3.5,3.1,5.1,4.8c27.3,26.8,41,59.2,41.1,97.1
				c0,37.9-13.7,70.2-41.1,97h0c-27.5,27-60.7,40.5-99.7,40.5c-19.3,0-37.2-3.3-53.8-10l-0.7,58.9c17.2,4.7,35.3,7,54.4,7
				c54.3,0,100.6-18.9,139-56.5v0c38.6-37.8,57.9-83.4,57.8-137c0-53.5-19.3-99.2-57.8-137.1c-24.2-23.7-51.5-40-82-48.8
				c-3-0.8-6-1.6-9.1-2.4c-9.3-2.2-18.8-3.7-28.7-4.5C538.6,139.9,518.3,96.4,481,59.8 M366.1,292.3c-0.4,0-0.7,0-1.1,0
				c-6,0.4-11.7,2.6-17.1,6.9c-0.3,0.2-0.5,0.5-0.8,0.7L233.8,413.6c-0.8,0.8-1.4,1.8-1.9,2.9c-5.2,14.4-3.5,25.1,5.1,32
				c0.1,0,0.1,0.1,0.1,0.1c10.7,9,21.4,10,32.1,3c0.5-0.3,1-0.7,1.4-1.1l66.5-66.8v260c0,1,0.2,2,0.5,2.9
				c5.8,14.9,14.8,22.1,26.9,21.7h1.9c6.2,0,11.7-2,16.5-6.2c4.2-3.5,7.7-8.6,10.4-15.5c0.4-0.9,0.6-1.9,0.5-2.9v-260l66.4,66.8
				c0.5,0.4,0.9,0.8,1.4,1.1c10.8,6.9,21.5,5.9,32.1-3.1c0.1,0,0.1-0.1,0.2-0.1c8.6-6.9,10.3-17.5,5-32c-0.4-1.1-1-2.1-1.8-2.9
				L384,299.9c-0.3-0.3-0.6-0.6-1-0.9C377.1,294.9,371.5,292.7,366.1,292.3z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span>
                Enjoy <b>1 TB storage</b> and <b>priority support</b>
              </span>
            </div>
            <div className="mt-5 title">
              Cancel anytime - no strings attached
            </div>
            <div className="mt-5">
              <button className="trial-btn" onClick={_startTrial}>
                Try it free for 7 days
              </button>
            </div>
          </div>
          <div className="right-side">
            <img
              src="https://cdn.pizap.com/pizapfiles/pizap_pro_large.jpg"
              className="pizap-large"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProGet;
