import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PosterMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Poster Maker | Online Poster Designer"
        description="Our online poster maker lets you easily and quickly design custom online posters for print or to create a pdf."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/poster_maker_app"
        keywords="poster maker, poster designer, online poster creator"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">piZap’s Poster Maker Tool</p>
          <p className="desc">
            Make your own poster using piZap’s frustration-free Poster Maker
          </p>
          <StartButton
            text="Edit a Poster →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/poster_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/poster_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Poster Maker Tool</span>
            <span className="desc">
              piZap’s Poster Maker tool allows you to zap uniquely creative
              posters into reality thanks to a wide variety of tools. Sure,
              anyone can make a poster; but not just anyone can create a good
              eye-catching poster that gets its message across… until now. You
              no longer need extensive design experience to make your own poster
              thanks to piZap.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Poster Maker Tool Overview</span>
            <span className="desc">
              Make your own poster advertising a garage sale or an upcoming
              event at your business. Or, have some fun creating a freaky
              Halloween party flyer. No matter what kind of flyer you need to
              make, piZap’s Poster Maker tool has everything you need and then
              some. Incorporate your favorite photos, create custom graphics and
              logos with transparent backgrounds, and/or enjoy adding stickers
              and text boxes with cool font effects. There are thousands of
              stickers and hundreds of fonts to choose from.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/poster_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/poster_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Easily Accessible Poster Making Website & App
            </span>
            <span className="desc">
              piZap was made to make you feel like a professional Poster Maker
              within minutes of first using the app. piZap can be installed onto
              your desktop computer or downloaded to your mobile device. No
              matter what device you plan to make your own poster on, piZap
              makes it incredibly easy to bring your visions to life. While easy
              to navigate, piZap doesn’t cut any corners in terms of originality
              or design creativity and flexibility. Let your imagination run
              wild--no experience necessary!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Way to Make Your Own Poster</span>
            <span className="desc">
              Have more fun than you ever thought possible using piZap’s poster
              making website and app. Make a funny poster or a professional
              poster, or go for a mix of the two. Did we mention there are
              hundreds of handpicked fonts to choose from? Stick with basic
              fonts or drift over to more wild styles and text effects.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/poster_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/poster_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Poster Maker + Many More Features</span>
            <span className="desc">
              Are you making a poster for a bridal shower, your best friend’s
              birthday, or maybe a company holiday party? piZap has the tools,
              stickers, font effects, and so much more to create picture-perfect
              posters for every holiday or event. That’s because piZap is so
              much more than just a Poster Maker tool. It’s packed with editing
              and design tools you can use to make your posters that much
              better. While you’re in the app, you can make greeting cards,
              business cards, hilarious MEMEs, collages, and more! With so many
              easy to use features, piZap is a superstar poster making website
              and app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PosterMakerPage;
