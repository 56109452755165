import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link, useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import ProTeaser from "../components/ProTeaser.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import HelmetDetails from "../components/HelmetDetails";
import { ReactComponent as IconCrown } from "../images/editor/pro_crown.svg";
import { identifyDevice } from "../utils/utils";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
    showLogin: UserStore.showLogin(),
  };
};

const HomePage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();

  const ensureWWW = (path) => {
    const currentHost = window.location.host;
    if (currentHost === 'pizap.com') {
      return `https://www.pizap.com${path}`;
    }
    return path;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (UserStore.getUser()) {
      window.location.href = ensureWWW('/app/start');
    }
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    window.location.href = ensureWWW('/app/start');
  };

  return (
    <div className="home-page">
      <HelmetDetails
        title="piZap: Simple Design & Photo Editor | Collage Maker | Social Media Design Tool"
        description="Design simplified! piZap is your go-to easy design tool, photo editor and collage maker. Create stunning social media posts with our Social Media Design Tool, Instagram Post Designer, and more."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com"
        keywords="Easy Design Tool, Easy Photo Editor, Collage Maker, Social Media Design Tool, Instagram Post Designer, Meme Maker, Logo Maker, A.I. Image Creator, Card Designer, Flyer Designer, Invitation Maker, Wallpaper Designer, Facebook Cover Maker, Twitter Cover Maker"
      />
      <Header />
      <>
        <div className="img-hover-container">
          <p className="title">Design Simplified.</p>
          <p className="desc">
            Creativity has never been this easy - or this much fun.
          </p>
          <div className="mobile-hidden">
            <button
              className="try-btn"
              onClick={() => {
                if (UserStore.getUser()) {
                  window.location.href = ensureWWW('/app/start');
                } else {
                  window.location.href = ensureWWW('/app/signup');
                }
              }}
            >
              Start designing
            </button>
            
            <div style={{ position: "fixed", top: "95px", right: "0", width: "170px", height: "auto" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <div>
                  <a href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8" target="_blank">
                    <img src="https://static.pizap.com/pizapfiles/app_store_badge_2024.png" alt="App Store" style={{ width: "130px" }} />
                  </a>
                </div>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap" target="_blank">
                    <img src="https://static.pizap.com/pizapfiles/play_store_badge_2024.png" alt="Play Store" style={{ width: "130px" }} />
                  </a>
                </div>
              </div>
            </div>

            <div className="images-main">
              <div className="center">
                <img src="//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_screenshot.dc9a1033.png" />
              </div>
            </div>
            <div className="images-decorate">
              <div>
                <img
                  src="//cdn.pizap.com/pizapfiles/images/pizap_template_images_1.7867ffee.png"
                  alt="pizap-template"
                />
              </div>
              <div>
                <img
                  src="//cdn.pizap.com/pizapfiles/images/pizap_template_images_2.8429f054.png"
                  alt="pizap-template"
                />
              </div>
            </div>
          </div>
          <div className="desktop-hidden">
            {identifyDevice() === "iOS" ? (
              <a
                href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8"
                target="_blank"
              >
                <img
                  className="apple-store"
                  src="//cdn.pizap.com/pizapfiles/images/apple-store.png"
                  width="120"
                  alt="apple-store"
                />
              </a>
            ) : (
              <a
                href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap"
                target="_blank"
              >
                <img
                  src="//cdn.pizap.com/pizapfiles/images/google-play.png"
                  width="120"
                  className="google-play"
                  alt="google-play"
                />
              </a>
            )}
            <div className="main-image">
              <img src="//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_mobile.af96022a.png" />
            </div>
          </div>
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_background.f51f5035.jpg"
            alt="bg"
          />
        </div>
        <div className="plan-section mobile-hidden">
          <span className="title">Your creativity, your choice.</span>
          <div className="d-felx space-evenly mt-5 plans">
            <div className="plan-box">
              <div className="title">piZap Free</div>
              <div className="contents">
                Where families, friends, and students come together to create.
                No design skills? No problem!
              </div>
              <button className="round-btn" onClick={handlePizap}>
                Get piZap Free
              </button>
            </div>
            <div className="plan-box">
              <div className="title">
                <IconCrown width={40} height={40} />
                piZap Pro
              </div>
              <div className="contents">
                Endless possibilities, premium content. For design pros,
                freelancers, and go-getters. Unlock all content, tools and
                unlimited project storage.
              </div>
              <button className="round-btn" onClick={handlePizap}>
                Start your free Pro Trial
              </button>
            </div>
          </div>
        </div>
        <div className="section" id="photo_editor">
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_photo_editor_design.JPG"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Skip the learning curve, and still create stunning designs!
            </span>
            <span className="desc">
              Let's face it, Photoshop is hard. piZap is the{" "}
              <Link to="/photo_editor_app">simple photo editor</Link> and{" "}
              <Link to="/online_design_tool_app">design tool</Link> you've been
              searching for! Dive into our user-friendly interface and discover
              how fun and effortless photo transformation can be. Take your
              pictures from ordinary to extraordinary in just a few clicks.{" "}
              <br /> Ready to create Insta-worthy pics?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
        </div>

        <div className="section reverse" id="design">
          <div className="section-description">
            <span className="title">
              Templates Galore: Your Shortcut to Stunning Designs!
            </span>
            <span className="desc">
              Bypass the creative block with our exhaustive template library.
              With thousands of templates to choose from, piZap is the ultimate
              playground for easy graphic design and creative photo collages.
              <br />
              Ready to dive into the world of templates?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_design_templates.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section" id="ai_tools">
          <img
            src="//cdn.pizap.com/pizapfiles/images/ai_text_to_image_maker.JPG"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              The future of image creation with A.I.!
            </span>
            <span className="desc">
              Create eye-catching designs with the click of a button. Unleash
              boundless creativity with piZap's{" "}
              <Link to="">AI Image Maker</Link>, your automated wizard for
              literally creating anything you can imagine. Simply describe your
              image using normal text, click, and voila—you've just created an
              AI-powered masterpiece.
              <br /> Intrigued by the blend of tech and art?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Social media, supercharged!</span>
            <span className="desc">
              Boost your online presence with piZap's{" "}
              <Link to="">social media design tool.</Link>
              Perfect for crafting engaging posts, covers, and even entire
              campaigns. Make your brand pop with easy graphic design options
              and automated image creation. <br />
              Ready to go viral?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/social_media_design_tool.JPG"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section" id="meme_maker">
          <img
            src="//cdn.pizap.com/pizapfiles/images/meme_maker.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Meme your dream!</span>
            <span className="desc">
              In the age of memes, be a meme king (or queen)! piZap's{" "}
              <Link to="/1meme_maker_generator_app">Meme Maker</Link> is your
              playground for fun and engagement. It's so user-friendly, you'll
              go from zero to meme hero in no time. <br />
              Want to meme like a pro?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
        </div>

        <div className="section reverse" id="collage">
          <div className="section-description">
            <span className="title">Collage magic in minutes!</span>
            <span className="desc">
              Why settle for one when you can have many? piZap's simple{" "}
              <Link to="/collage_maker_app">collage maker</Link> lets you create
              artistic photo collages effortlessly. Choose from creative layouts
              and themes for a personalized photo grid that tells your story
              like no other. <br />
              Excited to collage like a boss?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/simple_collage_maker.JPG"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_background_eraser_remover_cutout.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Backgrounds be gone with the power of A.I.!
            </span>
            <span className="desc">
              Say hello to the future with our{" "}
              <Link to="">AI-powered background remover.</Link>
              This tool takes the tedium out of manual editing. Whether it's for
              e-commerce, social media posts, or personal use, piZap's automatic
              photo background removal gets you the focus you crave. <br />
              Ready to clear the stage for what really matters?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Logos that Speak Volumes!</span>
            <span className="desc">
              Your brand deserves to shine, and our logo maker can make that
              happen. With piZap, creating a logo is as easy as pie, offering
              automated designs that don't skimp on creativity. <br />
              Ready to brand like a boss?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_logo_design.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_fonts_text_design.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Dive into a World of Fonts!</span>
            <span className="desc">
              Looking for the perfect font for your project? Look no further!
              With over 350 fonts, piZap's font library is a typographic
              treasure trove. From quirky to classic, we've got the font to
              match your mood.
              <br />
              Ready to express yourself?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">A Universe of Stock Images</span>
            <span className="desc">
              Take your designs to the next level with our vast selection of
              stock images. Whether it's a dramatic background or a quirky
              element, you'll find the perfect photo to make your vision a
              reality. <br />
              Eager to explore our image universe?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_stock_images.JPG"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/pizap_stickers_graphics.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Sticker Mania: Add a Dash of Fun to Any Design!
            </span>
            <span className="desc">
              Adding a finishing touch or a dash of humor is a breeze with our
              sticker library. With thousands of stickers at your disposal, the
              possibilities are endless.
              <br />
              Ready to stick around for some fun?
            </span>
            <StartButton text="Start designing" handleClick={handlePizap} />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </div>
  );
};

export default HomePage;