import React, { useEffect, useRef, useState } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import LeftMenuStore from "../stores/LeftMenuStore";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const ColorPicker = (props) => {
  const prevProps = usePrevious(props);
  const previewRef = useRef();
  const [colorCode, setColorCode] = useState(props.initialColor);

  useEffect(() => {
    let isMounted = true;
    if (props !== prevProps && isMounted) {
      setColorCode(props.initialColor);
      if (previewRef) {
        var pvw = previewRef.current;
        if (pvw && props.initialColor) {
          const _colorCode = props.initialColor.toString(16).padStart(6, "0");
          pvw.style.backgroundColor = _colorCode.replace("0x", "#");
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [props]);

  const colorPicked = (color) => {
    props.onColor(color);
    setColorCode(color);
    UserStore.colorPicked(color);
    if (LeftMenuStore.get() === "design") {
      const _cd = parseInt(color.replace("#", "0x"), 16);
      EditorActionCreators.updateBlankColor(_cd);
    }
    EditorStore.setColorPickerColorCode(color);
  };

  const previewClicked = () => {
    EditorActionCreators.ColorPickerPreviewClicked(colorCode, colorPicked);
  };

  useEffect(() => {
    if (colorCode && typeof colorCode.indexOf !== "function") {
      setColorCode("#" + colorCode.toString(16).padStart(6, "0"));
    }
  }, [colorCode]);

  useEffect(() => {
    const container = document.getElementById("container");
    if (container?.style) {
      container.style.cursor = "inherit";
    }
    if (window.editor.getBaseObject()) {
      window.editor.getBaseObject().parent.interactive = false;
      window.editor.getBaseObject().parent.buttonMode = false;
    }
    if (window.editor.getRenderer() && window.editor.getRenderer().view)
      window.editor.getRenderer().view.style.cursor = "inherit";
  });

  return (
    <div className="color-picker">
      <span>{props.text}</span>
      <div
        className="preview clickable"
        id="preview-paint"
        style={{
          backgroundColor: colorCode,
          opacity: props.opacity,
        }}
        onClick={previewClicked}
        ref={previewRef}
      ></div>
    </div>
  );
};

export default ColorPicker;
