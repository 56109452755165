import React from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import ProCrownBadge from "../images/editor/pro-crown-badge.png";
import EditorStore from "../stores/EditorStore";

const StickerScrollPhotos = (props) => {
  const seeall = () => {
    if (props.seeall) {
      props.seeall(props.categoryName, props.items);
    }
  };

  const addSticker = (event) => {
    let src = event.currentTarget.attributes["data-src"].value;
    let isPROItem = event.currentTarget.attributes["data-pro"].value === "true";
    let isPro = UserStore.isPro();
    if (isPROItem && !isPro) {
      UserActionCreators.showProGet(true);
    } else {
      EditorActionCreators.ShowFilePicker(false);
      window.editor.addSticker(
        src,
        null,
        null,
        () => {
          EditorStore.setShowSpinner(false);
        },
        null,
        null,
        null,
        null,
        null,
        null
      );
    }
  };

  const dragStart = (event) => {
    let src = event.currentTarget.attributes["data-src"].value;
    let isPROItem = event.currentTarget.attributes["data-pro"].value === "true";
    let isPro = UserStore.isPro();

    if (isPROItem && !isPro) {
      event.preventDefault();
    } else {
      event.dataTransfer.setData("text", src);
    }
  };

  let itemComp = null;
  if (props.items) {
    itemComp = null;
    if (props.items && props.items.length) {
      itemComp = props.items.slice(0, 5).map((item, index) => {
        let isPRO = false;
        if (index >= 2) {
          isPRO = true;
        }
        if (isPRO && !UserStore.isPro()) {
          return (
            <div className="sticker-item" key={index}>
              <img
                className="clickable"
                data-src={item.url}
                data-pro={isPRO}
                data-idx={index}
                src={item.thumbnail}
                onClick={addSticker}
                alt="download"
              />
              <img
                src={ProCrownBadge}
                className="pro-overlay"
                onDragStart={dragStart}
                alt=""
              />
            </div>
          );
        } else {
          return (
            <div className="sticker-item" key={index}>
              <img
                className="clickable"
                data-src={item.url}
                data-pro={isPRO}
                data-idx={index}
                src={item.thumbnail}
                onClick={addSticker}
                alt="download"
                onDragStart={dragStart}
              />
            </div>
          );
        }
      });
    }
  }
  return (
    <div className="sticker-scroll">
      <div className="sticker-scroll-topbar">
        <span className="sticker-scroll-name">{props.categoryName}</span>
        <span className="sticker-scroll-count">{props.items.length}</span>
        <div className="sticker-scroll-seeall clickable" onClick={seeall}>
          See all
        </div>
      </div>
      <div className="sticker-scroll-items-row">
        <div className="sticker-scroll-items">
          <div className="sticker-scroll_items-overflow">{itemComp}</div>
        </div>
      </div>
    </div>
  );
};

export default StickerScrollPhotos;
