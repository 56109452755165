import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const TouchupToolPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Touch Up Tool Photo Editor | Makeup and Touch Up Effects"
        description="Easily touch up photos with our touch up tool photo editor. Fix blemishes, wrinkles and more."
        keywords="Touch up tool, touch up app, fix blemishes, fix wrinkles"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/touch_up_tool_app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Touch Up Tool</p>
          <p className="desc">
            The photo touch up app that erases imperfections in a zap
          </p>
          <StartButton
            text="Edit a photo →"
            handleClick={() => {
              navigate("/app");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/touch_up_photo_editor_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/touch_up_photo_editor_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Touch Up Tool</span>
            <span className="desc">
              piZap’s Photo Touch Up Tool offers everything you need to erase
              imperfections and enhance your natural beauty. Get ready to have a
              good time editing your photos to perfection.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              What Does piZap’s Touch Up Tool Offer?
            </span>
            <span className="desc">
              Who’s that model? That’s what people will be saying when they see
              your photos after they’ve been touched up using piZap. Take a
              selfie with no makeup? No worries—you can add lip tent, remove
              blemishes and add airbrush makeup using piZap’s Touch Up Tool. You
              can also whiten your teeth, remove wrinkles, change your eye
              color, and more. Plus, piZap makes it simple to save photos using
              unlimited free storage. It’s just as easy to share your retouched
              photos via social media from directly within the app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/touch_up_photo_editor_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/touch_up_photo_editor_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easy Photo Touch Up App</span>
            <span className="desc">
              piZap is the easiest face touch up app out there! Erase blemishes
              like magic with the click of a button, or change your eye color in
              a flash. piZap combines a wide variety of awesome editing tools
              that are easy to access and utilize. Even the airbrush makeup tool
              is simple to apply, and there’s no need to worry about looking
              like a clown! Everyone is going to want you to edit their photos
              after seeing how good your photo touch ups look. Good thing piZap
              is so easy to use—just tell them to download the face touch up app
              app and they’ll feel like a pro in no time.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Have Fun Touching Up</span>
            <span className="desc">
              piZap’s Touch Up Tool is so easy to use it’s fun! Plus, who
              doesn’t love making themselves or loved ones look better? piZap
              can turn an ordinary selfie and into profile-picture status in
              minutes. On top of Touch Up tools there are plenty of other
              editing and design tools packed inside of piZap. There are tons of
              filters, thousands of stickers, hundreds of fonts and text effects
              + so much more.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/touch_up_photo_editor_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/touch_up_photo_editor_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Touch Up Tool + More Features</span>
            <span className="desc">
              Not only can this face touch up app change your eye color in
              seconds and seamlessly remove a big zit, piZap also offers a whole
              world of other editing and design tools. This one app gives you
              the power to wow your social media followers and entertain
              yourself in the process. Make a funny MEME, create a photo collage
              or even a custom photo wedding invitation—you can seriously do all
              that and more in a zap using piZap.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default TouchupToolPage;
