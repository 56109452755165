import React from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import PrpCrown from "./ProCrown.react";

const FontItem = (props) => {
  const isPro = UserStore.isPro();
  const itemSelected = () => {
    if (props.item.isProOnly && !isPro) {
      UserActionCreators.showProGet(true);
    } else {
      props.onSelected(props.item.Name);
    }
  };

  return (
    <div
      className="font-item clickable"
      data-id={props.item.Name}
      onClick={itemSelected}
      style={{
        backgroundImage: `url(${props.item.Icon?.replace("'", "%27")})`,
      }}
    >
      {props.item.isProOnly && !isPro && <PrpCrown />}
    </div>
  );
};

export default FontItem;
