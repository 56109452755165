import React, { useRef, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import UserActionCreators from "../actions/UserActionCreators";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import TwitterLoginModal from "./TwitterLoginModal";
import { useGoogleLogin } from "@react-oauth/google";

const INITIAL_DATA = {
  loginMessage: UserStore.getLoginMessage(),
  username: "",
  password: "",
  usernameValidationMessage: "",
};

const LoginEmail = () => {
  let auth2;
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [username, setUsername] = useState("");
  const [usernameValidationMessage, setUsernameValidationMessage] =
    useState("");
  const [loginData, setLoginData] = useState(INITIAL_DATA);

  useEffect(() => {
    if (window.gapi && window.gapi.auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: "369311205870.apps.googleusercontent.com",
        scope: "profile",
        fetch_basic_profile: false,
      });
      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(signinChanged);
      // Listen for changes to current user.
      auth2.currentUser.listen(userChanged);
    }
    UserStore.addChangeListener(_onChange);

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setLoginData({
      loginMessage: UserStore.getLoginMessage(),
      username: username,
      password: "",
      usernameValidationMessage: usernameValidationMessage,
    });
  };

  const usernameChanged = (event) => {
    setLoginData({
      loginMessage: UserStore.getLoginMessage(),
      username: event.currentTarget.value,
      password: "",
      usernameValidationMessage: usernameValidationMessage,
    });
  };

  const _onLogin = (event) => {
    event.preventDefault();
    const authData = JSON.stringify({
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    });
    UserActionCreators.logUserIn(authData);
  };

  // const register = (event) => {
  //     UserActionCreators.loginMode("register");
  // }

  const forgotPassword = (event) => {
    UserActionCreators.loginMode("forgot");
  };

  const _facebookClick = (event) => {
    if (EditorStore.isElectron()) {
      window.FBLogin();
    } else {
      window.FB.login(
        function (response) {
          if (response.status === "connected") {
            const myData = JSON.stringify({
              facebookToken: response.authResponse.accessToken,
            });
            UserActionCreators.logFacebookIn(myData);
          } else if (response.status === "not_authorized") {
            //document.getElementById('status').innerHTML = 'Please log into this app.';
          } else {
            //document.getElementById('status').innerHTML = 'Please log into Facebook.';
          }
        },
        { scope: "email" }
      );
    }
  };

  // const loginGoogle = useGoogleLogin({
  //   onSuccess: async (res) => {
  //     try {
  //       const data = await fetch(
  //         "https://www.googleapis.com/oauth2/v3/userinfo",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${res.access_token}`,
  //           },
  //         }
  //       );
  //       const userInfo = await data.json();
  //       const submitData = {
  //         sub: userInfo.sub,
  //         name: userInfo.name,
  //         given_name: userInfo.given_name,
  //         email: userInfo.email,
  //         email_verified: userInfo.email_verified,
  //         picture: userInfo.picture,
  //       };

  //       JSONUtils.postJSON(
  //         `${process.env.REACT_APP_SERVER_API}/auth/google`,
  //         "POST",
  //         submitData,
  //         function (data) {
  //           UserActionCreators.userLoggedIn(data);
  //         }
  //       );
  //     } catch (error) {
  //       console.log("Login Failed");
  //     }
  //   },
  //   onError: () => {
  //     console.log("Login Failed");
  //   },
  // });

  const loginGoogle = (event) => {
    window.editor.setProcessGoogle(true);
    if (!auth2) {
      auth2 = window.gapi.auth2.init({
        client_id: "369311205870.apps.googleusercontent.com",
        scope: "profile",
        fetch_basic_profile: false,
      });
      // Listen for sign-in state changes.
      auth2.isSignedIn.listen(signinChanged);
      // Listen for changes to current user.
      auth2.currentUser.listen(userChanged);
    }
    auth2.signIn().then(() => {
      console.log("SignIn Success.");
    });
  };

  const signinChanged = (user) => {
    if (user && typeof yourFunctionName === "function" && user.isSignedIn())
      window.editor.googleLogin(user);
  };

  const userChanged = (user) => {
    if (user && user.isSignedIn()) window.editor.googleLogin(user);
  };

  const checkUserName = (event) => {
    setUsernameValidationMessage("");
    UserStore.setLoginMessage("");
    const usernameValue = event.currentTarget.value;
    if (usernameValue) {
      fetch(
        `${process.env.REACT_APP_SERVER_API}/user/username/${encodeURI(
          usernameValue
        )}`,
        {
          method: "get",
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        }
      )
        .then(function (res) {
          if (res.status === 200) return res.json();
          return null;
        })
        .then(function (data) {
          if (!data || !data.username) {
            if (usernameValue.indexOf("@") > 0) {
              fetch(
                `${process.env.REACT_APP_SERVER_API}/user/email/${encodeURI(
                  usernameValue
                )}`,
                {
                  method: "get",
                  headers: new Headers({
                    "Content-Type": "application/json",
                  }),
                }
              )
                .then(function (resEmail) {
                  return resEmail.json();
                })
                .then(function (dataEmail) {
                  if (dataEmail && dataEmail.length > 0) {
                    var foundPizapAccount = false;
                    var foundFacebook = false;
                    var foundGoogle = false;
                    var foundTwitter = false;
                    dataEmail.forEach((item) => {
                      if (item.isPizap) {
                        setUsername(item.username);
                        foundPizapAccount = true;
                      } else {
                        if (item.isFacebook) foundFacebook = true;
                        if (item.isGoogle) foundGoogle = true;
                        if (item.isTwitter) foundTwitter = true;
                      }
                    });
                    if (!foundPizapAccount) {
                      setUsernameValidationMessage(
                        "No Pizap account found for this email."
                      );
                      if (foundFacebook)
                        usernameValidationMessage(
                          `${usernameValidationMessage} Facebook account found.`
                        );
                      if (foundGoogle)
                        usernameValidationMessage(
                          `${usernameValidationMessage} Google account found.`
                        );
                      if (foundTwitter)
                        usernameValidationMessage(
                          `${usernameValidationMessage} Twitter account found.`
                        );
                      UserStore.emitChange();
                    } else {
                      setLoginData({
                        loginMessage: UserStore.getLoginMessage(),
                        username: event.currentTarget.value,
                        password: "",
                        usernameValidationMessage: usernameValidationMessage,
                      });
                    }
                  } else {
                    setUsernameValidationMessage(
                      "The email you’ve entered doesn’t match any account. Did you sign up with a Facebook, Google or Twitter ID?"
                    );
                    UserStore.emitChange();
                  }
                });
            } else {
              setUsernameValidationMessage(
                "The username you’ve entered doesn’t match any account."
              );
              UserStore.emitChange();
            }
          }
        });
    }
  };

  return (
    <div className="login-main-email">
      <div className="login-main-btns">
        <a className="btn btn-facebook" onClick={_facebookClick}>
          <i className="icon icon-facebook-light"></i>
          <span>Log in with Facebook</span>
        </a>
        <a className="btn btn-gplus" onClick={loginGoogle}>
          <i className="icon icon-gplus-light"></i>
          <span>Log in with Google</span>
        </a>
        <div className="lineOr">
          <hr width="30%" /> <span>OR</span> <hr width="30%" />
        </div>
        <div className="validateContainer">
          {loginData.usernameValidationMessage ? (
            <div className="validateMessage">
              <img
                src="https://cdn.pizap.com/pizapfiles/images/alert_red_arrow.png"
                alt="red arrow"
              />
              <span>{loginData.usernameValidationMessage}</span>
            </div>
          ) : null}
          <input
            type="text"
            placeholder="Username"
            ref={usernameRef}
            value={loginData.username}
            onChange={usernameChanged}
            onBlur={checkUserName}
            maxLength="75"
          />
        </div>
        <div className="validateContainer">
          {loginData.loginMessage ? (
            <div className="validateMessage">
              <img
                src="https://cdn.pizap.com/pizapfiles/images/alert_red_arrow.png"
                alt="red arrow"
              />
              <span>
                The password you’ve entered is incorrect.{" "}
                <a nohref="true" onClick={forgotPassword}>
                  {" "}
                  Forgot Password?
                </a>
              </span>
            </div>
          ) : null}
          <input
            type="password"
            placeholder="Password"
            ref={passwordRef}
            maxLength="72"
          />
        </div>
        <a className="btn btn-green" onClick={_onLogin}>
          Log in
        </a>

        <div className="create-account">
          New to piZap? <Link to="/app/signup">Sign up</Link>
          <a className="forgot-password" nohref="true" onClick={forgotPassword}>
            Forgot Password
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginEmail;
