import React from "react";

const EffectItem = (props) => {
  const { imgSrc, title, isChecked, handleChange, seeAll } = props;
  return (
    <div className="space-between effect-item">
      <div className="space-between">
        <div className="round-rect">
          <img src={imgSrc} alt="filters" width={22} />
        </div>
        <div className="flex-column">
          <span className="round-rect-span">{title}</span>
          {isChecked && (
            <span className="seel-all clickable" onClick={seeAll}>
              See all
            </span>
          )}
        </div>
      </div>
      <div className="transparent">
        <label className="switch">
          <input type="checkbox" checked={isChecked} onChange={handleChange} />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default EffectItem;
