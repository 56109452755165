import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PrivacyPolicyPage = () => {
  const [data, setData] = useState(getStateFromStores());

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    UserStore.addChangeListener(_onChange);

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetDetails
        title="piZap Privacy and Cookie Policy and Terms of Use"
        description="piZap Privacy and Cookie Policy and Terms of Use"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/privacypolicy"
        keywords="Privacy Policy, Cookie Policy, Terms of Use"
      />
      <Header />
      <div className="privacy-policy">
        <div className="content-wrapper">
          <p>
            At Digital Palette LLC ("we", "us", or "our"), we are committed to
            protecting your privacy. This Privacy Policy outlines how we collect,
            use, disclose, and safeguard your personal data when you use our
            website and mobile applications ("Services"). Please read this Privacy
            Policy carefully. By using our Services, you agree to the collection
            and use of information in accordance with this policy.
          </p>

          <h2>1. Introduction</h2>
          <p>
            Digital Palette LLC is the data controller for the purposes of the
            General Data Protection Regulation (GDPR) and other relevant data
            protection laws. Our registered address is 140 W Franklin St.  Ste 203 PMB 42
            Monterey, CA 93940, USA.
          </p>

          <h2>2. Information We Collect</h2>

          <h3>2.1 Personal Data</h3>
          <p>We may collect and process the following personal data:</p>
          <ul>
            <li>
              <strong>Account Data:</strong> Name, email address, username, and
              public profile pictures.
            </li>
            <li>
              <strong>Usage Data:</strong> IP address, browser type, operating
              system, referral source, pages viewed, and date/time of visits.
            </li>
            <li>
              <strong>Images and Media:</strong> Images you upload or create using
              our Services.
            </li>
            <li>
              <strong>Communication Data:</strong> Information contained in your
              communications with us, including support requests.
            </li>
          </ul>

          <h3>2.2 Cookies and Tracking Technologies</h3>
          <p>
            We use cookies and similar tracking technologies to enhance your
            experience. For detailed information, please refer to our{" "}
            <a href="#cookie-policy">Cookie Policy</a>.
          </p>

          <h2>3. How We Use Your Information</h2>
          <p>
            We process your personal data for the following purposes and under the
            following legal bases:
          </p>
          <ul>
            <li>
              <strong>Providing and Improving Services:</strong> To operate and
              maintain our Services (<em>Contract Performance</em>).
            </li>
            <li>
              <strong>Account Management:</strong> To manage your account and
              provide customer support (<em>Contract Performance</em>).
            </li>
            <li>
              <strong>Communication:</strong> To contact you regarding updates,
              promotions, and for marketing purposes (<em>Consent</em> or{" "}
              <em>Legitimate Interests</em>).
            </li>
            <li>
              <strong>Analytics:</strong> To analyze usage and improve our Services (
              <em>Legitimate Interests</em>).
            </li>
            <li>
              <strong>Legal Compliance:</strong> To comply with legal obligations (
              <em>Legal Obligation</em>).
            </li>
          </ul>

          <h2>4. Legal Basis for Processing Personal Data (GDPR)</h2>
          <p>
            If you are from the European Economic Area (EEA), our legal basis for
            collecting and using your personal information depends on the data and
            the context in which we collect it. We may process your personal data
            because:
          </p>
          <ul>
            <li>
              You have given us permission to do so (<em>Consent</em>).
            </li>
            <li>
              We need to perform a contract with you (<em>Contract Performance</em>
              ).
            </li>
            <li>
              The processing is in our legitimate interests and not overridden by
              your rights (<em>Legitimate Interests</em>).
            </li>
            <li>
              To comply with the law (<em>Legal Obligation</em>).
            </li>
          </ul>

          <h2>5. Your Rights Under GDPR</h2>
          <p>
            If you are a resident of the EEA, you have the following data protection
            rights:
          </p>
          <ul>
            <li>
              <strong>Right to Access:</strong> You have the right to request
              copies of your personal data.
            </li>
            <li>
              <strong>Right to Rectification:</strong> You have the right to
              request that we correct any information you believe is inaccurate.
            </li>
            <li>
              <strong>Right to Erasure:</strong> You have the right to request
              that we erase your personal data.
            </li>
            <li>
              <strong>Right to Restrict Processing:</strong> You have the right to
              request that we restrict the processing of your personal data.
            </li>
            <li>
              <strong>Right to Object:</strong> You have the right to object to our
              processing of your personal data.
            </li>
            <li>
              <strong>Right to Data Portability:</strong> You have the right to
              receive your personal data in a structured, commonly used format.
            </li>
            <li>
              <strong>Right to Withdraw Consent:</strong> If we are processing your
              personal data based on consent, you may withdraw your consent at any
              time.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us at{" "}
            <a href="mailto:support@pizap.com">support@pizap.com</a>. You also have
            the right to lodge a complaint with a supervisory authority in your
            country of residence.
          </p>

          <h2>6. Data Retention and Deletion</h2>
          <p>
            We retain your personal data only as long as necessary for the purposes
            set out in this Privacy Policy. This includes retaining your data to
            comply with legal obligations, resolve disputes, and enforce our
            agreements. Specifically:
          </p>
          <ul>
            <li>Your account data is retained until you delete your account.</li>
            <li>
              If your account is inactive for two years, we may automatically
              delete it and all associated data.
            </li>
          </ul>
          <p>
            <strong>Data Deletion Requests:</strong> You have the right to request
            the deletion of your personal data at any time. To do so, please contact
            us at <a href="mailto:support@pizap.com">support@pizap.com</a> with the
            subject line "Data Deletion Request." We will process your request in
            accordance with applicable laws and our internal policies. You may also
            delete your account by selecting the "Delete Account" button and
            confirming your request. This will automatically delete your account and
            associated data.
          </p>

          <h2>7. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect
            your personal data against unauthorized access, alteration, disclosure,
            or destruction. However, please be aware that no method of transmission
            over the internet or electronic storage is entirely secure.
          </p>

          <h2>8. International Data Transfers</h2>
          <p>
            Your information may be transferred to and maintained on servers located
            outside of your state, province, country, or other governmental
            jurisdiction where data protection laws may differ. If you are located
            outside the United States, please note that we transfer data to the
            United States and process it there. We ensure appropriate safeguards
            are in place to protect your personal data during such transfers.
          </p>

          <h2>9. Disclosure of Your Information</h2>
          <p>We may share your personal data with:</p>
          <ul>
            <li>
              <strong>Service Providers:</strong> Third-party companies that assist
              us in providing our Services (e.g., hosting, analytics, payment
              processing).
            </li>
            <li>
              <strong>Legal Obligations:</strong> When required by law or in response
              to valid requests by public authorities.
            </li>
            <li>
              <strong>Business Transfers:</strong> In connection with any merger,
              sale of company assets, financing, or acquisition of all or a portion
              of our business.
            </li>
          </ul>

          <h2>10. Third-Party Services</h2>
          <p>
            We use third-party services that may collect, store, or process your
            personal data. We have Data Processing Agreements in place with these
            providers to ensure your data is protected. Our third-party partners
            include:
          </p>
          <ul>
            <li>
              <strong>Facebook:</strong> Login and social sharing (
              <a href="https://www.facebook.com/policy.php">Privacy Policy</a>).
            </li>
            <li>
              <strong>Google:</strong> Login (
              <a href="https://policies.google.com/privacy">Privacy Policy</a>).
            </li>

          </ul>

          <h2>11. Children's Privacy</h2>
          <p>
            Our Services are not intended for individuals under the age of 13. We do
            not knowingly collect personal data from children under 13. If you become
            aware that a child has provided us with personal data, please contact us,
            and we will take steps to remove such information.
          </p>

          <h2>12. Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. Any changes will be
            posted on this page with an updated "Last Updated" date. We will notify
            you of significant changes via email or a prominent notice on our
            Services.
          </p>

          <h2>13. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or our
            data practices, please contact us:
          </p>
          <ul>
            <li>
              Email: <a href="mailto:support@pizap.com">support@pizap.com</a>
            </li>
            <li>
              Address: 140 W. Franklin St. STE 203, PMB 42, Monterey, CA, 93940,
              USA
            </li>
          </ul>

          <h2 id="cookie-policy">14. Cookie Policy</h2>
          <p>
            We use cookies and similar tracking technologies to enhance your
            experience. You can manage your cookie preferences through your browser
            settings.
          </p>

          <h2>15. Data Protection Officer</h2>
          <p>
            For privacy-related inquiries, you may contact our Data Protection
            Officer:
          </p>
          <ul>
            <li>
              Email: <a href="mailto:support@pizap.com">support@pizap.com</a> and we will connect you to our data protection officer
            </li>
          </ul>

          <hr />

          <h1>Terms of Use for piZap</h1>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using our website and mobile applications ("Services"),
            you agree to be bound by these Terms of Use ("Terms") and our Privacy
            Policy. If you do not agree to these Terms, please do not use our
            Services.
          </p>

          <h2>2. Eligibility</h2>
          <p>
            You must be at least 13 years old to use our Services. By using the
            Services, you represent and warrant that you meet this age requirement.
            If you are under the age of 16 and a resident of the European Economic
            Area (EEA), you must have parental consent to use our Services.
          </p>

          <h2>3. User Conduct</h2>
          <p>You agree not to engage in any of the following prohibited activities:</p>
          <ul>
            <li>
              Using the Services for any unlawful purpose or in violation of any
              local, state, national, or international law.
            </li>
            <li>Harassing, threatening, or defaming any person or entity.</li>
            <li>
              Uploading or sharing content that infringes on any third-party
              intellectual property rights.
            </li>
            <li>
              Distributing viruses or any malicious code that could harm the
              Services or other users.
            </li>
            <li>
              Collecting or storing personal data about other users without their
              consent.
            </li>
          </ul>

          <h2>4. Intellectual Property Rights</h2>

          <h3>4.1 Our Content</h3>
          <p>
            All content provided through the Services, including text, graphics,
            logos, images, and software, is the property of Digital Palette LLC or
            its licensors and is protected by intellectual property laws.
          </p>

          <h3>4.2 User-Generated Content</h3>
          <p>
            When you upload or create content using our Services ("Your Content"),
            you retain all rights to Your Content. By uploading Your Content, you
            grant us a non-exclusive, worldwide, royalty-free license to use,
            reproduce, modify, and display Your Content solely for the purpose of
            operating and improving the Services.
          </p>

          <h3>4.3 Prohibited Content</h3>
          <p>You agree not to upload or share content that:</p>
          <ul>
            <li>Is illegal, harmful, or promotes illegal activities.</li>
            <li>Infringes on any third-party intellectual property rights.</li>
            <li>Is obscene, pornographic, or otherwise objectionable.</li>
            <li>Contains hate speech or promotes discrimination.</li>
          </ul>

          <h2>5. Account Registration</h2>
          <p>
            You may need to create an account to access certain features of the
            Services. You agree to provide accurate and complete information and to
            keep it updated. You are responsible for maintaining the confidentiality
            of your account credentials and for all activities that occur under your
            account.
          </p>

          <h2>6. Termination</h2>
          <p>
            We reserve the right to suspend or terminate your account at any time,
            with or without cause or notice, for conduct that we believe violates
            these Terms or is harmful to other users or the Services.
          </p>

          <h2>7. Cancellation and Refund Policy</h2>

          <h3>7.1 Cancellation</h3>
          <p>
            You may cancel your piZap PRO subscription at any time through your
            account settings or by contacting us at{" "}
            <a href="mailto:support@pizap.com">support@pizap.com</a>.
          </p>

          <h3>7.2 Refunds</h3>
          <p>
            If you cancel within one week of being billed, you may request a full
            refund by contacting us. If you cancel after one week, your subscription
            will remain active for the paid period, and you will not be billed
            again.
          </p>

          <h2>8. Limitation of Liability</h2>
          <p>
            To the maximum extent permitted by law, Digital Palette LLC shall not
            be liable for any indirect, incidental, special, consequential, or
            punitive damages, including loss of profits, data, or other intangible
            losses resulting from your use of the Services.
          </p>

          <h2>9. Indemnification</h2>
          <p>
            You agree to indemnify and hold harmless Digital Palette LLC, its
            affiliates, and their respective officers, directors, employees, and
            agents from any claims, liabilities, damages, losses, and expenses
            arising from your use of the Services or violation of these Terms.
          </p>

          <h2>10. Modifications to the Terms</h2>
          <p>
            We may update these Terms from time to time. We will notify you of
            significant changes by posting the new Terms on this page and updating
            the "Last Updated" date. Your continued use of the Services after any
            such changes constitutes your acceptance of the new Terms.
          </p>

          <h2>11. Governing Law and Dispute Resolution</h2>
          <p>
            These Terms are governed by and construed in accordance with the laws of
            the State of California, USA, without regard to its conflict of law
            provisions. Any disputes arising out of or relating to these Terms
            shall be resolved through binding arbitration in California, unless
            prohibited by law.
          </p>

          <h2>12. Severability</h2>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable,
            the remaining provisions shall remain in full force and effect.
          </p>

          <h2>13. Entire Agreement</h2>
          <p>
            These Terms, along with our Privacy Policy, constitute the entire
            agreement between you and Digital Palette LLC regarding your use of the
            Services and supersede any prior agreements.
          </p>

          <h2>14. Contact Information</h2>
          <p>If you have any questions about these Terms, please contact us:</p>
          <ul>
            <li>
              Email: <a href="mailto:support@pizap.com">support@pizap.com</a>
            </li>
          </ul>

          <p>
            <em>
              By using piZap, you acknowledge that you have read, understood, and
              agree to be bound by these Terms and our Privacy Policy.
            </em>
          </p>
        </div>
      </div>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PrivacyPolicyPage;
