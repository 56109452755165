import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const CutoutZoom = () => {
  const _cancelClick = (e) => {
    e.preventDefault();
    EditorActionCreators.ShowCutoutZoom(false);
  };

  const _onSkip = () => {
    window.editor.onCutoutStartPointing();
    EditorActionCreators.ShowCutoutTrace(false);
    EditorActionCreators.CutoutMode("point");
    window.editor.onCropComplete();
  };

  const _onNext = (e) => {
    e.preventDefault();
    EditorActionCreators.ShowCutoutZoom(false);
  };

  return (
    <div className="dialog-background">
      <div className="cutout-dialog-box cutout-style-dialog">
        <div className="filepicker-navbar">
          <div className="cutout-title">Step 1: Crop Object</div>
          <div className="close-btn" onClick={_cancelClick}>
            <CloseSVG height={12} />
          </div>
        </div>
        <p>
          Crop the object you want to cut out. This will set the zoom and make
          it easier.
        </p>
        <img
          src="https://static.pizap.com/html5/resources/editor/cutout-crop.jpg"
          width="500"
          alt="cutout crop"
        />
        <div className="space-little">
          <button className="btn cutoutSkipBtn" onClick={_onSkip}>
            Skip
          </button>
          <button className="btn my-btn cutoutZoomBtn" onClick={_onNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CutoutZoom;
