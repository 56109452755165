import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const ClipArtStickerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Clip Art Pictures | Add Stickers to Photos"
        description="Thousands of clip art and stickers for your pictures. Quickly add clip art and stickers to your photos."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/clip_art_pictures_stickers_app"
        keywords="clip art pictures, clip art app, stickers on photos, fun"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Add Clip Art Pictures to Your Photos</p>
          <p className="desc">
            Enjoy thousands of clip art pictures at your fingertips
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/start");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/clip_art_pictures_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/clip_art_pictures_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Add Clip Art Pictures to Your Photos</span>
            <span className="desc">
              piZap is so much more than just a clip art app, get ready to enjoy
              a whole new world of photo editing and design features, starting
              with thousands of clip art pictures and hundreds of fonts and text
              effects.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Stickers & Clip Art Pictures by piZap</span>
            <span className="desc">
              piZap has thousands of stickers and clip-art pictures to choose
              from with more added all the time. Clip art pictures are organized
              by category for easy browsing. From birthdays to Halloween and
              Christmas clip art too, welcome to a world where you have
              countless photo decorating tools at your fingertips. Bring new
              life to your favorite photos or use clip art pictures to decorate
              cards, posters, collages, graphic designs, and MEMEs. You name it
              and piZap’s many in-app tools help you make it happen.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/clip_art_pictures_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/clip_art_pictures_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Add Clip Art Pictures </span>
            <span className="desc">
              Stop struggling to find good clip art pictures on Google—you’re
              missing out on the best of the best decorations via piZap. Not to
              mention, it’s SO much easier to add clip art to your photos and
              designs using this app. There’s no need to save clip art pictures
              and then try and figure out how to get them somewhere else, let
              alone worry if stickers are royalty-free. piZap lets you pick the
              stickers you like, and then point, click and add them right where
              you want. Enjoy more than just images; you can pick from hundreds
              of text and font effects too.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Fun Clip Art Pictures</span>
            <span className="desc">
              Have fun adding Santa clipart or summer-themed clipart. piZap has
              stickers for every season and occasion, which means your options
              are virtually endless. So too is the amount of fun you’ll have
              embellishing your photos, posters, collages, and more. Make an
              extra special birthday shout out to your bestie using clip art
              pictures that represent inside jokes. Or, kick it up a notch and
              add text bubbles that express your undying love for someone.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/clip_art_pictures_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/clip_art_pictures_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Clip Art App with Features Galore</h3>
            </span>
            <span className="desc">
              piZap offers thousands of stickers and clip art pictures but it’s
              so much more than just a clip art app. piZap is a full photo
              editing and design app that lets you do everything from editing
              photos to making hilarious MEMEs. We understand the desire to add
              clip art pictures to things other than photos. That’s why piZap
              makes it easy to create invitations, cards, posters, collages,
              MEMES, flyers and more, all of which you can embellish with clip
              art pictures.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default ClipArtStickerPage;
