import React, { useEffect, useRef, useState, useMemo } from "react";
import UserStore from "../stores/UserStore";
import { Link } from "react-router-dom";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as HomeImage } from "../images/editor/pizap_home_icon.svg";
import { ReactComponent as UploadsImage } from "../images/editor/uploads.svg";
import { ReactComponent as CutoutsImage } from "../images/editor/cutouts.svg";
import { ReactComponent as AllProjectsImage } from "../images/editor/all_projects_icon.svg";
import { ReactComponent as FolderImage } from "../images/editor/folder_icon.svg";
import { ReactComponent as AddFolderImage } from "../images/editor/add_folder_icon.svg";
import { toast, Flip } from "react-toastify";

function getStateFromStores() {
  return {
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    user: UserStore.getUser(),
    folders: UserStore.getFolders(),
    photos: UserStore.getPhotos(),
  };
}

const UserLeftMenu = (props) => {
  const [userMenuState, setUserMenuState] = useState(getStateFromStores());
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempFolderName, setTempFolderName] = useState("");
  const [editFolderName, setEditFolderName] = useState("");
  const [needSelect, setNeedSelect] = useState(false);
  const [addFolderShown, setAddFolderShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const foldernameRef = useRef();

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    if (foldernameRef.current && needSelect) {
      setNeedSelect(false);
      foldernameRef.current.focus();
      foldernameRef.current.select();
    }
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setUserMenuState(getStateFromStores);
  };

  const addFolderFunc = () => {
    setAddFolderShow(false);
    setTempFolderName("");
    UserStore.emitChange();
  };

  const addFolderCancel = () => {
    setAddFolderShow(true);
    setShowError(false);
    setTempFolderName("");
    UserStore.emitChange();
  };

  const addFolderCreate = () => {
    if (tempFolderName === "") {
      setShowError(true);
    } else {
      EditorActionCreators.createFolder(tempFolderName);
      setAddFolderShow(true);
      setShowError(false);
      setTempFolderName("");
      setTimeout(() => {
        toast.success("Folder Create Success!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Flip,
        });
      }, 1000);
    }
  };

  const folderNameChange = (e) => {
    setTempFolderName(e.target.value);
    UserStore.emitChange();
  };

  const editClick = (event) => {
    setIsEditMode(true);
    if (isEditMode) setNeedSelect(true);
    if (userMenuState.folders != null) {
      userMenuState.folders.forEach(function (f) {
        if (f.id === props.selected) {
          setEditFolderName(f.name);
        }
      });
    }
    UserStore.emitChange();
  };
  const onFolderEdit = (event) => {
    setEditFolderName(event.target.value);
    UserStore.emitChange();
  };
  const folderEditCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsEditMode(false);
    setEditFolderName("");
    setNeedSelect(false);
    UserStore.emitChange();
  };
  const folderEditSave = (event) => {
    for (let x = 0; x < userMenuState.folders.length; x++) {
      if (userMenuState.folders[x].id === props.selected) {
        userMenuState.folders[x].name = editFolderName;
        EditorActionCreators.updateFolder(userMenuState.folders[x]);
      }
    }
    event.preventDefault();
    event.stopPropagation();
    setIsEditMode(false);
    setEditFolderName("");
    setNeedSelect(false);
    UserStore.emitChange();
  };

  const folders = useMemo(() => {
    if (userMenuState.folders) {
      return userMenuState.folders.map((i, index) => {
        if (props.selected === i.id) {
          if (!isEditMode) {
            return (
              <div
                key={index}
                className={`startMenuItem clickable folder ${
                  props.selected === i.id ? `active` : ``
                }`}
                onClick={editClick}
              >
                <FolderImage />
                <span className="foldername">{i.name}</span>
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="startMenuItem clickable"
                onClick={editClick}
              >
                <input
                  className="addFolderInput"
                  value={editFolderName}
                  ref={foldernameRef}
                  onChange={onFolderEdit}
                />
                <div className="inplaceeditcontrols">
                  <svg
                    className="close"
                    viewBox="0 0 18.1 18.7"
                    onClick={folderEditCancel}
                  >
                    <path
                      className="st0"
                      d="M82.7,91.15a1.8,1.8,0,0,0,1.05-.35,0.87,0.87,0,0,0,.5-0.85,2,2,0,0,0-.4-1L77.1,81.65l6.5-7.1a1.48,1.48,0,0,0,.5-1.05,0.93,0.93,0,0,0-1-1.05,2.64,2.64,0,0,0-1.45.2l-6.3,7-6.5-7a1.09,1.09,0,0,0-.7-0.2,2.19,2.19,0,0,0-1,.1,0.84,0.84,0,0,0-.8.95,1.56,1.56,0,0,0,.45,1.05l6.55,7.1L66.55,88.9a1.72,1.72,0,0,0-.4,1.1q0,1.05,1.35,1.05a1.82,1.82,0,0,0,1.15-.35l6.65-7,6.45,7A1.14,1.14,0,0,0,82.7,91.15Z"
                      transform="translate(-66.15 -72.45)"
                    />
                  </svg>
                  <svg
                    className="check"
                    viewBox="0 0 21.1 18.6"
                    onClick={folderEditSave}
                  >
                    <path
                      className="st0"
                      d="M583.5,141.85l12.75-15.7-3.7-2.9-10,12.6-4.75-4-2.65,3.25Z"
                      transform="translate(-575.15 -123.25)"
                    />
                  </svg>
                </div>
              </div>
            );
          }
        } else {
          return (
            <Link to={`/app/folders/${i.id}`} key={index}>
              <div
                key={i.id}
                className={`startMenuItem clickable folder ${
                  props.selected === i.id ? `active` : ``
                }`}
              >
                <FolderImage />
                <span className="foldername">{i.name}</span>
              </div>
            </Link>
          );
        }
      });
    }
  }, [props.selected, userMenuState]);

  return (
    <div className="leftmenu">
      <div className="items-container">
        <Link to="/app/start">
          <div
            className={`startMenuItem clickable ${
              props.selected === "start" ? `active` : ``
            }`}
          >
            <HomeImage />
            Home
          </div>
        </Link>
        <Link to="/app/uploaded">
          <div
            className={`startMenuItem clickable ${
              props.selected === "uploaded" ? `active` : ``
            }`}
          >
            <UploadsImage />
            Uploads
          </div>
        </Link>
        <Link to="/app/cutouts">
          <div
            className={`startMenuItem clickable ${
              props.selected === "cutouts" ? `active` : ``
            }`}
          >
            <CutoutsImage />
            Cutouts
          </div>
        </Link>
        <Link to="/app/projects">
          <div
            className={`startMenuItem clickable ${
              props.selected === "project" ? `active` : ``
            }`}
          >
            <AllProjectsImage />
            All projects
          </div>
        </Link>
        {userMenuState.photos !== null ||
          (userMenuState.photos.length !== 0 && (
            <Link to="/app/exports">
              <div
                className={`startMenuItem clickable ${
                  props.selected === "exports" ? `active` : ``
                }`}
              >
                <svg viewBox="0 0 528.6 425">
                  <g transform="matrix( 1, 0, 0, 1, 0,0) ">
                    <path
                      className="st0"
                      d="M468.1,31c-2.6-18.2-13.4-28.5-32.2-31H32.2C13.4,2.5,2.6,12.8,0,31l1.3,310.8c0.6,16.5,10,25.9,28.2,28.3      h409.2c18.2-2.3,27.6-11.8,28.3-28.3L468.1,31 M37.1,336.9V35.2h393.8v301.7H37.1 M79.5,76.5c-8.1,8.3-12.2,18.2-12.2,29.8      c0,11.6,4.1,21.5,12.2,29.7c8.3,8.1,18.2,12.1,29.7,12.1c11.6,0,21.5-4,29.7-12.1c8.1-8.2,12.2-18.1,12.2-29.7      c0-11.6-4.1-21.5-12.2-29.8c-8.3-8.1-18.2-12.1-29.7-12.1C97.7,64.4,87.8,68.4,79.5,76.5 M407.9,201.9l-99.6-89.2l-130,116.4      l-34.2-30.5l-81.8,66.9l0.3,45.8l345.3-0.4V201.9 M527.3,396.8L528.6,86c-2.6-18.2-13.4-28.5-32.2-31h-3.2l-1.8,336.8      l-429.8,0.8v4.3c0.7,16.5,10.1,25.9,28.3,28.2h409.2C517.3,422.7,526.7,413.3,527.3,396.8z"
                    />
                  </g>
                </svg>
                Exported images
              </div>
            </Link>
          ))}
        {folders}
        <div className="addFolderContainer">
          {addFolderShown ? (
            <a onClick={addFolderFunc}>
              <AddFolderImage />
              add folder
            </a>
          ) : (
            <div>
              <input
                className="addFolderInput"
                placeholder="Folder Name"
                value={tempFolderName}
                onChange={folderNameChange}
              />
              {showError && (
                <span className="folder__error">Please input folder name.</span>
              )}
              <div className="addFolderButtons">
                <button className="create-btn btn" onClick={addFolderCreate}>
                  Create
                </button>
                <button className="cancel-btn btn" onClick={addFolderCancel}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserLeftMenu;
