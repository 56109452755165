import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";

const Footer = () => {
  return (
    <div className="st-footer">
      <h2 className="title">
        <a href="http://blog.pizap.com/" target="_blank">
          piZap Tools and Features
        </a>
      </h2>
      <footer>
        <div className="left-column">
          <p>
            piZap Photo Editor is fun and easy to learn online photo editor &
            collage maker. Tons of effects, fonts, stickers, collage layouts,
            borders, frames, and editing tools.
          </p>
          <div className="social-icons">
            <a
              className="button-wrapper"
              target="_blank"
              href="https://www.facebook.com/piZap/"
            >
              <div className="icon-event">
                <svg
                  width="27"
                  height="26"
                  viewBox="0 0 27 26"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fillDefault fillActive"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.1697 13.077C26.1697 6.06512 20.4968 0.380859 13.4989 0.380859C6.50101 0.380859 0.828125 6.06512 0.828125 13.077C0.828125 19.4141 5.46164 24.6665 11.5191 25.619V16.747H8.3019V13.077H11.5191V10.2799C11.5191 7.09792 13.4107 5.3403 16.305 5.3403C17.6913 5.3403 19.1413 5.58827 19.1413 5.58827V8.71272H17.5436C15.9696 8.71272 15.4787 9.69139 15.4787 10.6954V13.077H18.9929L18.4311 16.747H15.4787V25.619C21.5361 24.6665 26.1697 19.4141 26.1697 13.077Z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              className="button-wrapper"
              target="_blank"
              href="https://www.youtube.com/piZap/"
            >
              <div className="icon-event">
                <svg
                  width="27"
                  height="26"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fillDefault fillActive"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM11.333 5.24952C11.7001 5.35027 11.9893 5.64714 12.0874 6.02415C12.2658 6.70742 12.2658 8.13307 12.2658 8.13307C12.2658 8.13307 12.2658 9.55868 12.0874 10.242C11.9893 10.619 11.7001 10.9159 11.333 11.0167C10.6675 11.1997 7.99909 11.1997 7.99909 11.1997C7.99909 11.1997 5.33063 11.1997 4.66517 11.0167C4.298 10.9159 4.00883 10.619 3.9107 10.242C3.73242 9.55868 3.73242 8.13307 3.73242 8.13307C3.73242 8.13307 3.73242 6.70742 3.9107 6.02415C4.00883 5.64714 4.298 5.35027 4.66517 5.24952C5.33063 5.06641 7.99909 5.06641 7.99909 5.06641C7.99909 5.06641 10.6675 5.06641 11.333 5.24952Z"
                  ></path>
                  <path
                    className="fillDefault fillActive"
                    d="M7.19922 9.60026V6.93359L9.33255 8.26698L7.19922 9.60026Z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              className="button-wrapper"
              target="_blank"
              href="https://www.instagram.com/pizap/"
            >
              <div className="icon-event">
                <svg
                  width="27"
                  height="26"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fillDefault fillActive"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM6.24018 3.75918C6.6953 3.73847 6.84072 3.7334 7.99949 3.7334H7.99816C9.15728 3.7334 9.30217 3.73847 9.75729 3.75918C10.2115 3.77998 10.5217 3.85189 10.7937 3.9574C11.0746 4.06629 11.312 4.21207 11.5493 4.44941C11.7866 4.68656 11.9324 4.92461 12.0418 5.20524C12.1466 5.47653 12.2186 5.78658 12.24 6.2408C12.2604 6.69592 12.2658 6.84134 12.2658 8.00011C12.2658 9.15888 12.2604 9.30395 12.24 9.75906C12.2186 10.2131 12.1466 10.5232 12.0418 10.7946C11.9324 11.0752 11.7866 11.3132 11.5493 11.5504C11.3122 11.7877 11.0745 11.9338 10.794 12.0428C10.5225 12.1483 10.2121 12.2202 9.75791 12.241C9.30279 12.2618 9.15781 12.2668 7.99896 12.2668C6.84028 12.2668 6.69494 12.2618 6.23983 12.241C5.78569 12.2202 5.47555 12.1483 5.20408 12.0428C4.92363 11.9338 4.68559 11.7877 4.44852 11.5504C4.21127 11.3132 4.06549 11.0752 3.95642 10.7945C3.851 10.5232 3.77909 10.2132 3.7582 9.75897C3.73758 9.30386 3.73242 9.15888 3.73242 8.00011C3.73242 6.84134 3.73776 6.69583 3.75811 6.24071C3.77856 5.78666 3.85056 5.47653 3.95634 5.20515C4.06567 4.92461 4.21145 4.68656 4.44878 4.44941C4.68594 4.21216 4.92399 4.06638 5.20462 3.9574C5.47591 3.85189 5.78595 3.77998 6.24018 3.75918Z"
                  ></path>
                  <path
                    className="fillDefault fillActive"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.61896 4.50302C7.69328 4.5029 7.77323 4.50294 7.85951 4.50298L8.00172 4.50302C9.14093 4.50302 9.27596 4.50711 9.72583 4.52755C10.1418 4.54658 10.3676 4.61609 10.518 4.67449C10.7171 4.75182 10.8591 4.84427 11.0083 4.9936C11.1577 5.14294 11.2501 5.28516 11.3276 5.48427C11.386 5.6345 11.4556 5.86028 11.4746 6.27628C11.495 6.72607 11.4994 6.86118 11.4994 7.99986C11.4994 9.13853 11.495 9.27365 11.4746 9.72343C11.4555 10.1394 11.386 10.3652 11.3276 10.5154C11.2503 10.7146 11.1577 10.8563 11.0083 11.0056C10.859 11.1549 10.7172 11.2474 10.518 11.3247C10.3678 11.3834 10.1418 11.4527 9.72583 11.4717C9.27604 11.4922 9.14093 11.4966 8.00172 11.4966C6.86242 11.4966 6.7274 11.4922 6.27761 11.4717C5.86161 11.4525 5.63583 11.383 5.48534 11.3246C5.28623 11.2473 5.144 11.1548 4.99467 11.0055C4.84533 10.8562 4.75289 10.7143 4.67537 10.5151C4.61697 10.3649 4.54737 10.1391 4.52844 9.72307C4.508 9.27329 4.50391 9.13818 4.50391 7.99879C4.50391 6.8594 4.508 6.725 4.52844 6.27522C4.54746 5.85921 4.61697 5.63343 4.67537 5.48303C4.75271 5.28392 4.84533 5.14169 4.99467 4.99236C5.144 4.84302 5.28623 4.75058 5.48534 4.67307C5.63574 4.6144 5.86161 4.54506 6.27761 4.52595C6.67122 4.50818 6.82375 4.50284 7.61896 4.50195V4.50302ZM10.2796 5.21112C9.99691 5.21112 9.76758 5.44018 9.76758 5.72294C9.76758 6.00561 9.99691 6.23495 10.2796 6.23495C10.5623 6.23495 10.7916 6.00561 10.7916 5.72294C10.7916 5.44027 10.5623 5.21094 10.2796 5.21094V5.21112ZM5.81055 8.0007C5.81055 6.79068 6.79158 5.80962 8.00159 5.80957C9.21165 5.80957 10.1925 6.79065 10.1925 8.0007C10.1925 9.21076 9.21174 10.1914 8.00168 10.1914C6.79162 10.1914 5.81055 9.21076 5.81055 8.0007Z"
                  ></path>
                  <path
                    className="fillDefault fillActive"
                    d="M8.00036 6.57812C8.78579 6.57812 9.4226 7.21484 9.4226 8.00036C9.4226 8.78579 8.78579 9.4226 8.00036 9.4226C7.21484 9.4226 6.57812 8.78579 6.57812 8.00036C6.57812 7.21484 7.21484 6.57812 8.00036 6.57812Z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              className="button-wrapper"
              target="_blank"
              href="https://www.twitter.com/piZap/"
            >
              <div className="icon-event">
                <svg
                  fill="#000000"
                  width="27"
                  height="27"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fillDefault fillActive"
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z"></path>
                </svg>
              </div>
            </a>
            <a
              className="button-wrapper"
              target="_blank"
              href="https://www.pinterest.com/pizap"
            >
              <div className="icon-event">
                <svg
                  height="27"
                  width="26"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 144 144"
                >
                  <path
                    className="fillDefault fillActive"
                    d="M71.9,5.4C35.1,5.4,5.3,35.2,5.3,72c0,28.2,17.5,52.3,42.3,62c-0.6-5.3-1.1-13.3,0.2-19.1 c1.2-5.2,7.8-33.1,7.8-33.1s-2-4-2-9.9c0-9.3,5.4-16.2,12-16.2c5.7,0,8.4,4.3,8.4,9.4c0,5.7-3.6,14.3-5.5,22.2 c-1.6,6.6,3.3,12,9.9,12c11.8,0,20.9-12.5,20.9-30.5c0-15.9-11.5-27.1-27.8-27.1c-18.9,0-30.1,14.2-30.1,28.9 c0,5.7,2.2,11.9,5,15.2c0.5,0.7,0.6,1.2,0.5,1.9c-0.5,2.1-1.6,6.6-1.8,7.5c-0.3,1.2-1,1.5-2.2,0.9c-8.3-3.9-13.5-16-13.5-25.8 c0-21,15.3-40.3,44-40.3c23.1,0,41,16.5,41,38.4c0,22.9-14.5,41.4-34.5,41.4c-6.7,0-13.1-3.5-15.3-7.6c0,0-3.3,12.7-4.1,15.8 c-1.5,5.8-5.6,13-8.3,17.5c6.2,1.9,12.8,3,19.7,3c36.8,0,66.6-29.8,66.6-66.6C138.5,35.2,108.7,5.4,71.9,5.4z"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
          <div className="d-flex">
            <a
              href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8"
              target="_blank"
            >
              <img
                className="apple-store"
                src="//cdn.pizap.com/pizapfiles/images/apple-store.png"
                width="120"
                alt="apple-store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap"
              target="_blank"
            >
              <img
                src="//cdn.pizap.com/pizapfiles/images/google-play.png"
                width="120"
                className="google-play"
                alt="google-play"
              />
            </a>
          </div>

          <span className="company">@ piZap {new Date().getFullYear()}</span>
        </div>
        <div className="right-column">
          <div className="element-column">
            <ul>
              <li>
                <Link to="/photo_editor_app">Photo Editor App</Link>
              </li>
              <li>
                <Link to="/collage_maker_app">Collage Maker App</Link>
              </li>
              <li>
                <Link to="/online_design_tool_app">Online Design Tool App</Link>
              </li>
              <li>
                <Link to="/touch_up_tool_app">Touch Up Tool App</Link>
              </li>
              <li>
                <Link to="/add_text_to_photos">Add Text to a Photo</Link>
              </li>
              <li>
                <Link to="/custom_card_maker_app">Custom Card Maker App</Link>
              </li>
              <li>
                <Link to="/clip_art_pictures_stickers_app">
                  Clip Art Pictures and Stickers
                </Link>
              </li>
              <li>
                <Link to="/photo_effects_filters_app">
                  Photo Effects Filters App
                </Link>
              </li>
              <li>
                <Link to="/1meme_maker_generator_app">Meme Maker App</Link>
              </li>
            </ul>
          </div>
          <div className="element-column">
            <ul>
              <li>
                <Link to="/photo_frames_borders_app">
                  Photo Frames and Borders App
                </Link>
              </li>
              <li>
                <Link to="/1facebook_cover_maker_app">
                  Facebook Cover Maker Maker App
                </Link>
              </li>
              <li>
                <Link to="/twitter_cover_maker_app">
                  Twitter Cover Maker App
                </Link>
              </li>
              <li>
                <Link to="/logo_maker_app">Logo Maker App</Link>
              </li>
              <li>
                <Link to="/youtube_cover_maker_app">
                  YouTube Cover Maker App
                </Link>
              </li>
              <li>
                <Link to="/airbrush_makeup_app">Airbrush Makeup App</Link>
              </li>
              <li>
                <Link to="/blemish_remover_tool_app">
                  Blemish Remover Tool App
                </Link>
              </li>
              <li>
                <Link to="/business_card_maker_app">
                  Business Card Maker App
                </Link>
              </li>
              <li>
                <Link to="/christmas_card_maker_app">
                  Christmas Card Maker App
                </Link>
              </li>
            </ul>
          </div>
          <div className="element-column">
            <ul>
              <li>
                <Link to="/cut_out_photo_tool_app">Cut Out Photo Tool App</Link>
              </li>
              <li>
                <Link to="/eye_color_changer_app">Eye Color Changer App</Link>
              </li>
              <li>
                <Link to="/face_editor_app">Face Editor App</Link>
              </li>
              <li>
                <Link to="/flyer_maker_app">Flyer Maker App</Link>
              </li>
              <li>
                <Link to="/free_stock_images">Free Stock Images</Link>
              </li>
              <li>
                <Link to="/graphic_design_maker_app">
                  Graphic Design Maker App
                </Link>
              </li>
              <li>
                <Link to="/holiday_card_maker_app">Holiday Card Maker App</Link>
              </li>
              <li>
                <Link to="/lip_tint_changer_app">
                  Lip Tint Changer Maker App
                </Link>
              </li>
              <li>
                <Link to="/photo_backgrounds_textures_app">
                  Photo Backgrounds Textures App
                </Link>
              </li>
            </ul>
          </div>
          <div className="element-column">
            <ul>
              <li>
                <Link to="/photo_crop_tool_app">Photo Crop Tool App</Link>
              </li>
              <li>
                <Link to="/poster_maker_app">Poster Maker App</Link>
              </li>
              <li>
                <Link to="/speech_bubbles_photo_app">
                  Speech Bubbles Photo App
                </Link>
              </li>
              <li>
                <Link to="/teeth_whitening_photo_app">
                  Teeth Whitening Photo App
                </Link>
              </li>
              <li>
                <Link to="/thank_you_card_maker_app">
                  Thank You Card Maker App
                </Link>
              </li>
              <li>
                <Link to="/wedding_invitation_maker_app">
                  Wedding Invitation Maker App
                </Link>
              </li>
              <li>
                <Link to="/wrinkle_remover_app">Wrinkle Remover App</Link>
              </li>
            </ul>
          </div>
          <div className="element-column">
            <ul>
              <li className="addition-pages">
                <a
                  href="https://pizapsupport.zendesk.com/hc/en-us"
                  target="_blank"
                >
                  HELP
                </a>
              </li>
              <li className="addition-pages">
                <a
                  href="https://digipal.wufoo.com/forms/s7p7z9"
                  target="_blank"
                >
                  CONTACT
                </a>
              </li>
              <li className="addition-pages">
                <Link to="/privacypolicy">PRIVACY & TERMS</Link>
              </li>
              <li className="addition-pages">
                <Link to="/thankyou">THANK YOU</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
