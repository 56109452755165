import EditorDispatcher from "../dispatcher/EditorDispatcher";
import ActionTypes from "../constants/EditorConstants";

export default {
  MenuSelected: function (menu) {
    EditorDispatcher.dispatch({
      type: ActionTypes.LEFT_MENU_SELECTED,
      menu: menu,
    });
  },
  ResetMenu: function (reset) {
    EditorDispatcher.dispatch({
      type: ActionTypes.RESET_MENU,
      reset: reset,
    });
  },
  StickerFolderSelected: function (item) {
    EditorDispatcher.dispatch({
      type: ActionTypes.STICKER_FOLDER_SELECTED,
      item: item,
    });
  },
  FontCategorySelected: function (item) {
    EditorDispatcher.dispatch({
      type: ActionTypes.FONT_CATEGORY_SELECTED,
      item: item,
    });
  },
  EffectSelected: function (item) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EFFECT_SELECTED,
      item: item,
    });
  },
  getFacebookFolders: function () {
    EditorDispatcher.dispatch({
      type: ActionTypes.FACEBOOK_GET_FOLDERS,
    });
  },
  saveCutout: function (img) {
    EditorDispatcher.dispatch({
      type: ActionTypes.CUTOUT_SAVE,
      item: img,
    });
  },
};
