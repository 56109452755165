import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const CollageMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Collage Maker | Online Collage Maker App"
        keywords="collage maker, online collage maker, collage design tool, collage photo editor"
        description="With our collage maker you can easily create professional photo collages."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/collage_maker_app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">
            Create Effortless Masterpieces with piZap’s Collage Maker
          </p>
          <p className="desc">
            Easily create professional quality collages online or download the
            collage maker app
          </p>
          <StartButton
            text="Edit a Collage →"
            handleClick={() => {
              navigate("/app/?type=collage");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/collage_maker_app.jpg"
            alt="bg"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/collage_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Create Effortless Masterpieces with piZap’s Collage Maker
            </span>
            <span className="desc">
              What takes a photo collage maker app to the next level? How about
              one that’s easy to use, flexible, and fun… meet piZap’s Collage
              Maker! This diverse online photo collage app offers thousands of
              layouts and designs. There’s something for every holiday, season,
              and event.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Collage Maker Tools by piZap</span>
            <span className="desc">
              You know those fancy-looking collages you see and wonder, how did
              someone make that?! Well, my friends, at long last you don’t have
              to be a computer whiz to create collages of this caliber. piZap’s
              collage tools make it easy to swap layouts, adjust spacing between
              pictures, add background images, alter frame colors, shuffle
              photos, and more! You’ll love making collages with just a few
              photos or 10+ photos, all thanks to the wide variety of collage
              layouts piZap is packed with.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/collage_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/collage_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Collage Maker Made Easy</span>
            <span className="desc">
              NEVER struggle with making online pic collages again. We’ve all
              been there, trying to figure out how to add a photo, swap photos,
              or edit the filter on one photo without changing everything else.
              The struggle is real but it doesn’t have to be. piZap offers a
              combination of the latest and greatest collage making tools, all
              of which have been simplified for ease of use while still
              delivering professional-looking results.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
        <div className="section reverse">
          <div className="section-description">
            <span className="title">Add Fun to Your Photos</span>
            <span className="desc">
              Collecting and arranging photos to create a collage is half of the
              fun but we believe the excitement shouldn’t stop there. After all,
              sharing your masterpiece is often the best part of all. That’s why
              piZap makes it super simple to share your collages directly to the
              most popular social media platforms from inside of the app.
              Additionally, you can save your photo collages just like you would
              on a computer using JPG or transparent PNG. Did we mention we
              offer free unlimited storage space for photo collages and edits?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/collage_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>
        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/collage_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Countless Features on One Collage Maker App
            </span>
            <span className="desc">
              piZap’s photo collage maker includes carefully designed
              interactive tools that allow you to create a pic collage online
              while simultaneously editing photos as needed. Add an extra
              special touch to your online photo collage by adding a sticker or
              two… or three. piZap offers over one-thousand stickers, along with
              dynamic text boxes, unique font styles, and paint tools that let
              you doodle and create shapes to spice up your collages.Plus, free
              photo storage means you can create and save as many collages as
              you want, which is great because once you start using piZap’s
              easy-to-use collage maker app you won’t want to stop!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default CollageMakerPage;
