import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const LipTintChangePage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Lip Tint Tool | Change Your Lip Color in Photos"
        description="Lip tint tool lets you change your lip color in photos. Add lipstick or just change the color slightly in photos of your lips."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/lip_tint_changer_app"
        keywords="lip tint tool, change lip color app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Must-Have Lip Tint Tool</p>
          <p className="desc">
            The add lipstick app + makeup photo editor you need for flawless
            photos
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/start");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/lip_tint_color_tool_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/lip_tint_color_tool_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Lip tint tool</span>
            <span className="desc">
              Looking for an add lipstick app that also offers a full range of
              makeup photo editor tools? Look no further than piZap! Add
              lipstick, eyeshadows and more all on one easy to use makeup photo
              editor.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Lip Tint Tool Overview</span>
            <span className="desc">
              So, you took a great photo but your makeup is looking a little
              less than its best… now what? You can share any photo with
              confidence after using piZap’s makeup photo editor which includes
              an awesome Lip Tint Tool. Pick from natural lip color shades or go
              for something more wild and free like a dark shade of purple or
              bright pop of red. Plus, enjoy access to piZap’s many other in-app
              touch up, editing, and design tools.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/lip_tint_color_tool_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/lip_tint_color_tool_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Add Lipstick App Makes It Easy to Always Look Flawless
            </span>
            <span className="desc">
              Putting lipstick on in real life can be a pain in the you know
              what—it gets on your teeth, goes out of the lines, and can make
              kissing your significant other messy at best. For those times when
              you want to snap a good pic without applying lipstick, rest
              assured piZap will easily add the lip tint you desire. From there,
              you can quickly share your photo to the most popular social media
              sites directly from inside the app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Lip Tint Tool</span>
            <span className="desc">
              Real makeup you can touch is fun and all, but so too is virtual
              makeup accessed through our makeup photo editor. Our goal was to
              make it fun and easy to play around with lip tints, eyeshadows,
              and airbrush makeup. There’s no better way to find the right shade
              of lipstick for your skin tone, eye color, and so forth. Try on
              every shade mess-free without ever busting out a makeup removing
              wipe.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/lip_tint_color_tool_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/lip_tint_color_tool_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Makeup Photo Editor + Many More Features
            </span>
            <span className="desc">
              piZap’s Lip Tint Tool will have your lips looking luscious in no
              time. Other touch up tools offered in-app include a wrinkle
              remover, blemish eraser, and even an eye color changer. Once your
              face is looking fine AF, enjoy making use of piZap’s many other
              editing and design tools. Change filters, add stickers and text
              effects, or create a custom Emoji. Did we mention piZap comes with
              FREE storage for all your designs and edits?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default LipTintChangePage;
