import SparkMD5 from "spark-md5";
import qq from "fine-uploader/lib/s3";
import UserActionCreators from "../actions/UserActionCreators";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";

export default {
  uploads: [],
  dataURItoBlob: function (dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  },
  updateSaveStatus: function () {
    var statucMessage = "";
    if (this.uploads !== null && this.uploads.length > 0) {
      this.uploads.forEach(function (item) {
        statucMessage += item.textStatus;
      });
    }
    if (statucMessage !== "") {
      EditorStore.setStatusMessage(statucMessage);
      EditorStore.emitChange();
    } else {
      if (this.uploads.length > 0) {
        EditorStore.setStatusMessage(this.uploads.length + " uploads ");
        EditorStore.emitChange();
      } else {
        EditorStore.setStatusMessage("");
        EditorStore.emitChange();
      }
    }
  },
  createAsset: function (texture, isTouchup, callback) {
    var self = this;
    var url = texture.dataUrl;
    if (url == null || url.length < 1000) return;
    var mimeType = url.split(",")[0].split(":")[1].split(";")[0];
    var spark = new SparkMD5();
    spark.append(url);
    var hexHash = spark.end();

    var existingAsset;
    if (
      UserStore.getProjectAssets() != null &&
      UserStore.getProjectAssets().length > 0
    ) {
      UserStore.getProjectAssets().forEach((item, idx) => {
        if (item.imageName === hexHash) {
          existingAsset = item;
        }
      });
    }

    if (!existingAsset) {
      const uploader = new qq.s3.FineUploaderBasic({
        request: {
          endpoint: "https://s3-us-west-1.amazonaws.com/imagesw.pizap.com",
          accessKey: "AKIARRX2DH6EURCDHDHL",
        },
        cors: {
          //all requests are expected to be cross-domain requests
          expected: true,

          //if you want cookies to be sent along with the request
          sendCredentials: true,
          region: "s3-us-west-1",
        },
        signature: {
          endpoint: `${
            process.env.REACT_APP_SERVER_API
          }/projectAssets/create/${hexHash}?mime=${encodeURIComponent(
            mimeType
          )}`,
          customHeaders: {
            Authorization: "Bearer " + UserStore.getAccessToken(),
          },
        },
        objectProperties: {
          reducedRedundancy: true,
          region: "us-west-1",
          acl: "public-read",
          key: function (fileId) {
            var mimeExt = "jpg";
            if (mimeType === "image/png") mimeExt = "png";
            if (mimeType === "image/gif") mimeExt = "gif";
            if (mimeType === "image/svg+xml") mimeExt = "svg";
            return UserStore.getUser().UserName + "_" + hexHash + "." + mimeExt;
          },
        },
        retry: { enableAuto: true },
        chunking: { enabled: false },
        resume: { enabled: true },
        callbacks: {
          onComplete: function (id) {
            if (callback) callback(texture, false);
          },
          onProgress: function (id, name, uploadedBytes, totalBytes) {
            UserActionCreators.uploadProgress(
              (uploadedBytes / totalBytes) * 100
            );
          },
        },
      });

      fetch(
        `${
          process.env.REACT_APP_SERVER_API
        }/projectAssets/create/${hexHash}?mime=${encodeURIComponent(mimeType)}`,
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (policy) {
          if (!policy.alreadyExists) {
            uploader.addFiles([self.dataURItoBlob(url)]);
            texture.assetUrl = "https://projects.pizap.com/" + policy.filename;
            var i = document.createElement("img");
            var thumbHeight;
            i.onload = function () {
              //Store project record for user
              thumbHeight =
                window.editor.stickerSelector.calculateGetScaledHeight(
                  i.width,
                  i.height,
                  480
                );
              var canvas = document.createElement("canvas");
              canvas.width = 480;
              canvas.height = thumbHeight;
              var ctx = canvas.getContext("2d");
              ctx.drawImage(i, 0, 0, canvas.width, canvas.height);
              const thumbuploader = new qq.s3.FineUploaderBasic({
                request: {
                  endpoint:
                    "https://s3-us-west-1.amazonaws.com/thumbnailsw.pizap.com",
                  accessKey: "AKIARRX2DH6EURCDHDHL",
                },
                cors: {
                  //all requests are expected to be cross-domain requests
                  expected: true,
                  //if you want cookies to be sent along with the request
                  sendCredentials: true,
                  region: "s3-us-west-1",
                },
                signature: {
                  endpoint: `${process.env.REACT_APP_SERVER_API}/projectAssets/createThumb/${hexHash}`,
                  customHeaders: {
                    Authorization: "Bearer " + UserStore.getAccessToken(),
                  },
                },
                objectProperties: {
                  reducedRedundancy: true,
                  region: "us-west-1",
                  acl: "public-read",
                  key: function (fileId) {
                    return (
                      UserStore.getUser().UserName + "_" + hexHash + ".jpg"
                    );
                  },
                },
                uploadSuccess: {
                  endpoint: `${process.env.REACT_APP_SERVER_API}/projectAssets`,
                  customHeaders: {
                    Authorization: "Bearer " + UserStore.getAccessToken(),
                  },
                  params: {
                    ImageName: hexHash,
                    ThumbWidth: 480,
                    ThumbHeight: thumbHeight,
                    ImageWidth: i.width,
                    ImageHeight: i.height,
                    ImageSize: url.length,
                    MimeType: mimeType,
                    IsTouchup: isTouchup,
                  },
                },
                retry: { enableAuto: true },
                chunking: { enabled: true },
                resume: { enabled: true },
              });
              canvas.toBlob(
                function (blob) {
                  thumbuploader.addFiles(blob);
                },
                "image/jpeg",
                0.8
              );

              //                        thumbuploader.addFiles([canvas]);
            };
            i.src = url;
          } else {
            texture.assetUrl = policy.assetUrl;
            if (callback) callback(texture, false);
          }
        });
    } else {
      texture.assetUrl = existingAsset.src;
      if (callback) callback(texture, false);
    }
  },
};
