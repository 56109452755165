import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import StartButton from "../components/StartButton";
import HelmetDetails from "../components/HelmetDetails";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const AirbrushMakeupPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Airbrush makeup app | airbrush touchup photo editor tool"
        description="Use our airbrush makeup app to easily enhance and touch up your photos with our fun and easy airbrush makeup app."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/airbrush_makeup_app"
        keywords="airbrush makeup app, photo touch up tool, remove blemishes"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Awesome Airbrush Makeup App</p>
          <p className="desc">
            piZap’s Airbrush Makeup App lets you easily apply beautiful makeup
            in minute
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=edit");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/airbrush_makeup_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/airbrush_makeup_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Airbrush Makeup App</span>
            <span className="desc">
              Airbrush Makeup is a hot trend but it usually requires costly
              tools and a trained makeup artist to apply it… but not anymore!
              piZap makes it easy and fun to add airbrush makeup to your photos.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Airbrush Makeup Tool by piZap </span>
            <span className="desc">
              Looking for an airbrush makeup app that applies picture-perfect
              flawless makeup to your photos? Look no further than piZap! This
              complete photo touch up, editing, and design app offers everything
              you need to retouch stunning pictures. Pick from varied shades of
              eyeshadows, blushes and lip stains; apply them all to see what
              looks best on your skin tone. You can also use in-app touch up
              tools to swap your eye color, remove blemishes and erase wrinkles.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/airbrush_makeup_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/airbrush_makeup_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Easily Apply Airbrush Makeup to Photos
            </span>
            <span className="desc">
              Applying airbrush makeup in real life can get tricky. It’s easy to
              look like a clown instead of a queen. You’ll never worry about
              that when using piZap’s airbrush makeup app tool to apply your
              makeup. That’s because piZap is specially designed so that tools
              are easy to access with the click of a button or a swipe of your
              finger. We make it simple to apply natural or more showy makeup
              looks. The best part of all? You don’t need any makeup removing
              wipes!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Way to Apply Airbrush Makeup</span>
            <span className="desc">
              You no longer need to go out and spend a bunch of money buying
              different makeup palettes to try unique makeup looks and colors.
              piZap makes it affordable to have fun with all the palettes.
              Simply download on your desktop computer or mobile device to enjoy
              countless colors and makeup styles. That means you can have
              unbridled fun trying on different colors and looks you might
              otherwise never have a chance to try.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/airbrush_makeup_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/airbrush_makeup_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Add Airbrush Makeup + More</h3>
            </span>
            <span className="desc">
              piZap lets you add airbrush makeup in a zap but that’s far from
              the only thing you can do inside this easy to navigate app. Packed
              with countless photo touch up features, you can edit photos to
              sheer perfection. Plus, use basic editing tools to change filters,
              crop photos, add stickers or custom Emojis, + so much more. No
              other airbrush makeup app comes with this many additional tools
              and features.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default AirbrushMakeupPage;
