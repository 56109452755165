import React, { useEffect } from "react";
import EditorStore from "../stores/EditorStore";
import { ReactComponent as ImageUnlock } from "../images/editor/unlock.svg";

const LockedButton = () => {
  let isMounted = false;
  useEffect(() => {
    isMounted = true;
    EditorStore.addChangeListener(_onChange);

    return () => {
      isMounted = false;
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    try {
      if (isMounted) {
        EditorStore.getSelectedObject();
      }
    } catch (e) {}
  };

  const _onLocked = (event) => {
    event.stopPropagation();
    if (EditorStore.getSelectedObject()) {
      EditorStore.getSelectedObject().locked =
        !EditorStore.getSelectedObject().locked;
      EditorStore.emitChange();
      window.editor.setSelectedObject(EditorStore.getSelectedObject());
    }
  };

  return (
    <div id="lock-button" className="clickable st0" onClick={_onLocked}>
      <ImageUnlock />
      <span>Unlock</span>
    </div>
  );
};

export default LockedButton;
