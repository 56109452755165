import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const CutoutToolPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Photo Cut-Out Tool | Cutout Pictures App"
        description="Cut out one part of an image and add it to another image with our Cut-Out Tool and photo editor"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/cut_out_photo_tool_app"
        keywords="cut-out tool, cut out images, image cropping and cutting tool"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">The Ultimate Cut-Out Tool</p>
          <p className="desc">
            Cropping and cutting images online just got a whole lot easier
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/cut_out_tool_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/cut_out_tool_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Ultimate Cut-Out Tool</span>
            <span className="desc">
              Finally, a cut-out tool that lets you cut out images and redesign
              pictures anyway you want—no photo editing experience necessary!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">piZap’s Cut-Out Tool Overview</span>
            <span className="desc">
              piZap’s Cut-Out Tool lets you remove a person or thing from one
              image and transport it to another image or background—no photo
              editing experience necessary, we promise! Use the Cut-Out Tool to
              make it look like you’re floating on a fluffy cloud or standing
              next to a celebrity. Insert your cut-outs to a new picture, or add
              them to a poster, card, MEME, or anything else you make inside the
              piZap app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/cut_out_tool_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/cut_out_tool_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Cutting Images Online Just Got SO Much Easier
            </span>
            <span className="desc">
              Typically, cutting images online takes specialized tools and
              plenty of experience, but not anymore! Outlining and cropping
              images within photos has never been easier thanks to our specially
              designed Cut-Out Tool. We designed piZap so that it’s as simple as
              1, 2, 3 to cut out images online, and they look good too. Cut-out
              tools once reserved to professionals are now accessible to anyone
              with a desktop computer or mobile device.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">
              <h3>The Fun Cut-Out Tool</h3>
            </span>
            <span className="desc">
              Frustrating cut-out tools are anything but fun, in fact they’re
              downright disappointing. Say goodbye to unnecessarily complicated
              cut-out apps and say hello to piZap. You’ll be making cut-outs
              left and right once you see how fun and easy piZap is. The Cut-Out
              tool is enjoyable to use but even more importantly it facilitates
              fun. Get lost in laughter putting your cat on the moon or your
              best friend on an elephant with Justin Bieber. There’s no limit to
              your creativity so cut-out images and start messing around!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/cut_out_tool_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/cut_out_tool_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              <h3>Cut-Out Tool + Features Galore</h3>
            </span>
            <span className="desc">
              The cut-out tool is AWESOME but it’s not the only reason people
              download piZap every day. There are so many other photo touch up,
              editing, and design features packed inside—just wait until you
              start playing around. You can add borders, frames stickers and
              text effects to your photos, as well as change filters, or even
              make a MEME. Get ready to look like a photo editing pro thanks to
              piZap’s Cut-Out Tool and other features. See what all the buzz is
              about…
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default CutoutToolPage;
