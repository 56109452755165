import React, { useEffect, useState } from "react";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import EditorPropertiesSticker from "./EditorPropertiesSticker.react";

function getStateFromStores() {
  return {
    selectedObject: EditorStore.getSelectedObject(),
    propertiesVisible: EditorStore.getPropertiesVisible(),
    propertiesMinified: EditorStore.getPropertiesMinified(),
  };
}

const EditorProperties = () => {
  const [data, setData] = useState(getStateFromStores());
  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    UserStore.addChangeListener(_onChange);

    return () => {
      EditorStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  return (
    <div>
      {window.editor.getRenderer() &&
      window.editor.getRenderer().view.style.display !== "none" && (
        <EditorPropertiesSticker />
      )}
    </div>
  );
};

export default EditorProperties;
