import React, { useEffect, useState } from "react";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";
import BlueCheck from "../images/editor/check.png";
import { useNavigate } from "react-router-dom";

const ProTeaser = () => {
  const navigate = useNavigate();
  const [dueDate, setDueDate] = useState("");
  const user = UserStore.getUser();
  const [plan, setPlan] = useState({
    timeline: "yearly",
    price: 59.99,
  });

  const onClose = () => {
    UserActionCreators.showPro(false);
  };

  const purchasePro = (planName) => {
    if (user && user.UserName) {
      const url =
        "https://pizap.recurly.com/subscribe/" + planName + "/" + user.UserName;
      const win = window.open(url, "_blank");
      win.focus();
      if (!EditorStore.isElectron()) window.fbq("track", "InitiateCheckout");
    } else {
      navigate("/app/login");
      // UserActionCreators.showLogin(
      //   true,
      //   "https://pizap.recurly.com/subscribe/" + planName + "/"
      // );
    }
  };

  const getDueDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    setDueDate(formattedDate);
  };

  useEffect(() => {
    getDueDate();
  }, []);

  const handleClickNext = () => {
    if (EditorStore.isElectron() && process && process.platform === "darwin") {
      if (plan.timeline === "yearly") {
        window.inApp("pro_annual");
      } else {
        window.inApp("pro_monthly");
      }
    } else {
      if (plan.timeline === "yearly") {
        purchasePro("pro5999");
      } else {
        purchasePro("pro999");
      }
    }
  };

  return (
    <div className="filepicker-dialog active">
      <div className="trial-dialog active">
        <div className="close-btn" onClick={onClose}>
          <CloseSVG height={12} />
        </div>
        <div className="trial-container">
          <div className="left-side">
            <span className="try-text">Try piZap Pro for free</span>
            <div className="pro-description">
              <div>
                <img src={BlueCheck} height={16} />
              </div>
              <span>Free 7 day trial, cancel anytime</span>
            </div>
            <div className="pro-description">
              <div>
                <label htmlFor="rdo-1" className="btn-radio">
                  <input
                    type="radio"
                    checked={plan.timeline === "yearly"}
                    onChange={() =>
                      setPlan({
                        timeline: "yearly",
                        price: 59.99,
                      })
                    }
                    id="rdo-1"
                    name="radio-grp"
                  />
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                      className="inner"
                    ></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      className="outer"
                    ></path>
                  </svg>
                  <div className="payment-plan">
                    <div className="flex-column">
                      <div>
                        <span className="plan">&nbsp;&nbsp;Yearly</span>
                        <span className="red-highlight">
                          BEST VALUE - SAVE $59.99
                        </span>
                      </div>
                      <span className="mt-1 payment-value">
                        &nbsp;&nbsp;$59.99<span> ($4.99/month)</span>
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="pro-description">
              <div>
                <label htmlFor="rdo-2" className="btn-radio">
                  <input
                    type="radio"
                    checked={plan.timeline === "monthly"}
                    onChange={() =>
                      setPlan({
                        timeline: "monthly",
                        price: 9.99,
                      })
                    }
                    id="rdo-2"
                    name="radio-grp"
                  />
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                      className="inner"
                    ></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      className="outer"
                    ></path>
                  </svg>
                  <div className="payment-plan">
                    <div className="flex-column">
                      <div>
                        <span className="plan">&nbsp;&nbsp;Monthly</span>
                      </div>
                      <span className="mt-1 payment-value">
                        &nbsp;&nbsp;$9.99<span></span>
                      </span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="space-between mt-5 option-text">
              <span>{dueDate}</span>
              <span>${plan.price}</span>
            </div>
            <div className="space-between mt-5 option-text-trial">
              <span>
                <b>Due Today</b>&nbsp;
                <span>(7 days free)</span>
              </span>
              <span>
                <b>$0</b>
              </span>
            </div>
            <div className="mt-5">
              <button className="trial-btn my-btn" onClick={handleClickNext}>
                Next
              </button>
            </div>
          </div>
          <div className="right-side">
            <img
              src="https://cdn.pizap.com/pizapfiles/pizap_pro_large.jpg"
              className="pizap-large"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProTeaser;
