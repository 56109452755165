import React, { useEffect, useState, useRef, useMemo } from "react";
import AssetStore from "../stores/AssetStore";
import UserStore from "../stores/UserStore";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import StickerScroll from "./StickerScroll.react";
import Autosuggest from "react-autosuggest";
import CustomStickers from "./CustomStikers";
import UserActionCreators from "../actions/UserActionCreators";
import { ReactComponent as IconSearch } from "../images/editor/search_icon.svg";
import MenuItemDetail from "./MenuItemDetail.react";
import Masonry from "react-responsive-masonry";
import MasonryItem from "./MasonryItem";
import Lockr from "lockr";
import EditorStore from "../stores/EditorStore";
import SkeletonLoading from "./SkeletonLoadingImage";

function getStateFromStores() {
  return {
    isLoading: AssetStore.getMenuDataLoading(),
    folders: AssetStore.getAllStickerSVGFolders(),
    selectedItem: AssetStore.getSelectedItem(),
    cachedStickers: AssetStore.getCachedStickers(),
    featuredGraphics: AssetStore.getFeaturedGraphics(),
    featuredGraphicsEmoji: AssetStore.getFeaturedGraphicsEmoji(),
    featuredGraphicsMeme: AssetStore.getFeaturedGraphicsMeme(),
    bingResults: AssetStore.getStickerBingResults(),
    cutouts: UserStore.getCutouts(),
    rememberStickers: AssetStore.getRememberStickers(),
  };
}

const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

const MenuGraphics = (props) => {
  const [storesData, setStoresData] = useState(getStateFromStores());
  const stickerContainer = useRef();
  const [showResults, setShowResults] = useState(false);
  const [isShowNav, setIsShowNav] = useState(false);
  const [googleTabSelected, setGoogleTabSelected] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTextValue, setSearchTextValue] = useState("");
  const featuredApp = Lockr.get("featuredApp");
  const [stickers, setStickers] = useState([]);
  useEffect(() => {
    if (props.isReset) {
      clearSearch();
    }
  }, [props.isReset]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      AssetStore.addChangeListener(_onChange);
      UserStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    if (
      featuredApp === "emoji" &&
      storesData.featuredGraphicsEmoji.length > 0
    ) {
      setStickers(storesData.featuredGraphicsEmoji);
    } else if (
      featuredApp === "meme" &&
      storesData.featuredGraphicsMeme.length > 0
    ) {
      setStickers(storesData.featuredGraphicsMeme);
    } else {
      setStickers(storesData.featuredGraphics);
    }
  }, [
    storesData.featuredGraphics,
    storesData.featuredGraphicsEmoji,
    storesData.featuredGraphicsMeme,
    featuredApp,
  ]);

  const _onChange = () => {
    setStoresData(getStateFromStores());
  };

  const pizapTabClick = () => {
    setGoogleTabSelected(false);
  };

  const getSuggestionValue = function (suggestion) {
    setSearchTextValue(suggestion);
    return suggestion;
  };

  const bingTabClick = () => {
    if (!UserStore.getUser().isPRO) {
      UserActionCreators.showProGet(true);
    } else {
      setGoogleTabSelected(true);
    }
  };

  const searchText = (e) => {
    setSearchTextValue(e.target.value);
  };

  const clearSearch = () => {
    setSearchTextValue("");
    setShowResults(false);
    setGoogleTabSelected(false);
    AssetStore.setCachedStickers({
      title: null,
      activeTab: "piZap",
      items: [],
      isPro: false,
    });
    AssetStore.setSelectedItem(null);
    setStoresData(getStateFromStores());
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onSearchClick();
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetch(
      `${process.env.REACT_APP_SERVER_API}/pizapAsset/searchTag/` +
        encodeURIComponent(value + "*"),
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (results) {
        setSuggestions(results);
      });
  };

  useEffect(() => {
    if (storesData.cachedStickers.title && storesData.rememberStickers) {
      setSearchTextValue(storesData.cachedStickers.title);
      setShowResults(true);
      if (storesData.cachedStickers.activeTab === "piZap") {
        setGoogleTabSelected(false);
        LeftMenuActionCreators.StickerFolderSelected({
          Name: storesData.cachedStickers.title,
          items: storesData.cachedStickers.items,
          isGoogle: false,
        });
      } else {
        setGoogleTabSelected(true);
      }
    } else {
      clearSearch();
    }
  }, [storesData.rememberStickers]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestionValue }) => {
    event.preventDefault();
    LeftMenuActionCreators.ResetMenu(false);
    setShowResults(true);
    setSearchTextValue(suggestionValue);
    AssetActionCreators.searchStickers(suggestionValue);
    AssetStore.setCachedStickers({
      title: suggestionValue,
      activeTab: "piZap",
      items: [],
      isPro: false,
    });
    if (UserStore.getUser().isPRO) {
      setIsShowNav(true);
      AssetActionCreators.searchStickerBingResults(suggestionValue);
    }
  };

  const onSearchClick = () => {
    if (suggestions.length === 0 && !UserStore.getUser().isPRO) {
      UserActionCreators.showProGet(true);
    } else {
      LeftMenuActionCreators.ResetMenu(false);
      setShowResults(true);
      AssetActionCreators.searchStickers(searchTextValue + "*");
      AssetStore.setCachedStickers({
        title: searchTextValue,
        activeTab: "piZap",
        items: [],
        isPro: false,
      });
      if (UserStore.getUser().isPRO) {
        setIsShowNav(true);
        AssetActionCreators.searchStickerBingResults(searchTextValue);
      }
    }
  };

  const addYourOwn = () => {
    EditorStore.setFilePickerTool(null);
    EditorActionCreators.ShowFilePicker(true);
    window.editor.setSelectedObject(null);
  };

  const showSearchResults = (key, items) => {
    LeftMenuActionCreators.ResetMenu(false);
    setShowResults(true);
    setSearchTextValue(key);
    if (key == "Cutouts") {
      setIsShowNav(false);
      let newItems = items.map((item) => {
        return {
          src: item.src,
          icon: item.thumbnail,
          isProOnly: false,
        };
      });
      newItems.sort(function (a, b) {
        if (a.isProOnly && !b.isProOnly) return 1;
        if (b.isProOnly && !a.isProOnly) return -1;
        return 0;
      });
      LeftMenuActionCreators.StickerFolderSelected({
        Name: key,
        items: newItems,
        isGoogle: false,
      });
      AssetStore.setCachedStickers({
        title: key,
        activeTab: "piZap",
        items: newItems,
        isPro: false,
      });
    } else {
      setIsShowNav(false);
      let newItems = items.map((item) => {
        let isPRO = false;
        if (item.tags) {
          item.tags.forEach((tag) => {
            if (tag === "pizapPRO") isPRO = true;
          });
        }
        return {
          src: item.url,
          icon: item.thumbnail,
          isProOnly: isPRO,
          features: item.features,
        };
      });
      newItems.sort(function (a, b) {
        if (a.isProOnly && !b.isProOnly) return 1;
        if (b.isProOnly && !a.isProOnly) return -1;
        return 0;
      });
      LeftMenuActionCreators.StickerFolderSelected({
        Name: key,
        items: newItems,
        isGoogle: false,
      });
      AssetStore.setCachedStickers({
        title: key,
        activeTab: "piZap",
        items: newItems,
        isPro: false,
      });
    }
  };

  const folderListItems = useMemo(() => {
    if (showResults) {
      if (googleTabSelected) {
        return (
          <Masonry columnsCount={2} gutter="10px">
            {storesData.bingResults &&
              storesData.bingResults.length > 0 &&
              storesData.bingResults.map((item, idx) => {
                return (
                  <MasonryItem
                    key={idx}
                    id={idx}
                    item={item}
                    type="sticker"
                    isbing={true}
                  />
                );
              })}
          </Masonry>
        );
      } else {
        if (storesData.selectedItem && storesData.selectedItem.items) {
          AssetStore.setCachedStickers({
            title: AssetStore.getCachedStickers().title,
            activeTab: "piZap",
            items: storesData.selectedItem.items,
            isPro: false,
          });
          return (
            <>
              {storesData.selectedItem.items.map((item, idx) => {
                return <MenuItemDetail key={idx} item={item} type="sticker" />;
              })}
            </>
          );
        } else {
          return (
            <Masonry columnsCount={2} gutter="10px">
              {storesData.bingResults &&
                storesData.bingResults.length > 0 &&
                storesData.bingResults.map((item, idx) => {
                  return (
                    <MasonryItem
                      key={idx}
                      id={idx}
                      item={item}
                      type="sticker"
                      isbing={true}
                    />
                  );
                })}
            </Masonry>
          );
        }
      }
    } else {
      return null;
    }
  }, [
    showResults,
    storesData.selectedItem,
    googleTabSelected,
    storesData.bingResults,
  ]);

  useEffect(() => {
    if (stickerContainer && stickerContainer.current) {
      stickerContainer.current.scrollTop = 0;
    }
  }, [showResults]);

  const content = useMemo(() => {
    if (storesData.isLoading) {
      return (
        <div
          className="menu-photo-skeleton-container"
          style={{ paddingTop: "12px" }}
        >
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
          <SkeletonLoading width={125} height={93} />
        </div>
      );
    } else {
      if (showResults) {
        return (
          <div
            ref={stickerContainer}
            className="graphicContainer"
            style={{
              padding:
                googleTabSelected || !storesData.selectedItem
                  ? "12px"
                  : "12px 0px 12px 0px",
              background:
                googleTabSelected || !storesData.selectedItem ? "#FFF" : "",
              height:
                showResults &&
                isShowNav &&
                storesData.selectedItem &&
                !googleTabSelected
                  ? "calc(100% - 106px)"
                  : showResults && isShowNav && googleTabSelected
                  ? "calc(100% - 102px)"
                  : "calc(100% - 70px)",
            }}
          >
            {folderListItems}
          </div>
        );
      } else {
        return (
          stickers &&
          stickers.length > 0 && (
            <div
              className="scrollContainer stickerMenuContainer"
              ref={stickerContainer}
            >
              {storesData.cutouts && storesData.cutouts.length > 0 && (
                <CustomStickers
                  items={storesData.cutouts}
                  categoryName="Cutouts"
                  seeall={showSearchResults}
                />
              )}
              {stickers.map((listItem, index) => {
                return (
                  <StickerScroll
                    key={index}
                    items={listItem.pizapAssets}
                    categoryName={listItem.name}
                    seeall={showSearchResults}
                  />
                );
              })}
            </div>
          )
        );
      }
    }
  }, [
    storesData.isLoading,
    storesData.cutouts,
    showResults,
    folderListItems,
    stickers,
    googleTabSelected,
    storesData.selectedItem,
  ]);

  const inputProps = {
    placeholder: "Search Stickers",
    value: searchTextValue,
    onChange: searchText,
    onKeyDown: keyPress,
    type: "text",
  };

  const renderInputComponent = (inputProps) => (
    <div>
      <input {...inputProps} />
    </div>
  );

  const handleAITool = () => {
    EditorActionCreators.SetPickerState("ai_sticker");
    EditorActionCreators.ShowFilePicker(true, "ai_sticker");
  };

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        {showResults ? (
          <div className="clickable d-flex-start" onClick={() => clearSearch()}>
            <svg viewBox="0 0 960 560" transform="rotate(90)" height={14}>
              <path
                className="st0"
                d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
              ></path>
            </svg>
            <span>{searchTextValue}</span>
          </div>
        ) : (
          <span className="default-span">Stickers</span>
        )}
      </div>

      {!showResults && (
        <div>
          <div className="graphicsButtons space-between">
            <div className="my-btn upload clickable" onClick={addYourOwn}>
              <span>Add Your Own</span>
            </div>
            <div
              className="my-btn upload clickable badge-btn"
              onClick={handleAITool}
            >
              <span>A.I. Sticker Maker</span>
              <span className="badge">New</span>
            </div>
          </div>
          <div className="google-search">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              onSuggestionSelected={onSuggestionSelected}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              renderInputComponent={renderInputComponent}
            />
            <IconSearch />
          </div>
        </div>
      )}
      {showResults && isShowNav && storesData.selectedItem && (
        <div className="graphicsTabs">
          <div
            className={`pizapTab clickable ${
              googleTabSelected ? "" : "selected"
            }`}
            onClick={pizapTabClick}
          >
            <span>Stickers</span>
          </div>
          <div
            className={`googleTab clickable ${
              googleTabSelected ? "selected" : ""
            }`}
            onClick={bingTabClick}
          >
            <span>Pro Stickers</span>
          </div>
        </div>
      )}
      {stickers && stickers.length > 0 && content}
    </div>
  );
};

export default MenuGraphics;
