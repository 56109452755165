import * as PIXI from "pixi.js";
const vertex =
  "attribute vec2 aVertexPosition;\nattribute vec2 aTextureCoord;\n\nuniform mat3 projectionMatrix;\n\nvarying vec2 vTextureCoord;\n\nvoid main(void)\n{\n    gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);\n    vTextureCoord = aTextureCoord;\n}";
const fragment =
  "uniform sampler2D uSampler;\n    uniform float exponent;\n    uniform vec2 texSize;\n    varying vec2 vTextureCoord;\n    void main() {\n        vec4 center = texture2D(uSampler, vTextureCoord);\n        vec4 color = vec4(0.0);\n        float total = 0.0;\n        for (float x = -4.0; x <= 4.0; x += 1.0) {\n            for (float y = -4.0; y <= 4.0; y += 1.0) {\n                vec4 smpl = texture2D(uSampler, vTextureCoord + vec2(x, y) / texSize);\n                float weight = 1.0 - abs(dot(smpl.rgb - center.rgb, vec3(0.25)));\n                weight = pow(weight, exponent);\n                color += smpl * weight;\n                total += weight;\n            }\n        }\n        gl_FragColor = color / total;\n    }";

export default class DenoiseFilter extends PIXI.Filter {
  constructor(exponent = 10) {
    super(vertex, fragment);
    this.exponent = exponent;
  }
  apply(filterManager, input, output, clear) {
    this.uniforms.exponent = this.exponent;
    this.uniforms.texSize = new PIXI.Point(
      window.editor.getBaseObject().width,
      window.editor.getBaseObject().height
    );

    filterManager.applyFilter(this, input, output, clear);
  }
  get exponent() {
    return this.uniforms.exponent;
  }

  set exponent(value) {
    this.uniforms.exponent = value;
    this.uniforms.texSize = new PIXI.Point(
      window.editor.getBaseObject().width,
      window.editor.getBaseObject().height
    );
  }
}
