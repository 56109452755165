import React, { useEffect, useState } from "react";
import EditorStore from "../stores/EditorStore";
import touchup from "../editor/touchup";
import LeftMenuStore from "../stores/LeftMenuStore";

function getStateFromStores() {
  return {
    undoCount: EditorStore.getExecuted().length,
    redoCount: EditorStore.getUnexecuted().length,
    touchupUndoCount: EditorStore.getTouchupExecuted().length,
    touchupRedoCount: EditorStore.getTouchupUnexecuted().length,
    menuSelected: !LeftMenuStore.get()
      ? EditorStore.getEditorType() === "collage"
        ? "collage"
        : "main"
      : LeftMenuStore.get(),
  };
}

const Undo = () => {
  const [userState, setUserState] = useState(getStateFromStores());

  useEffect(() => {
    LeftMenuStore.addChangeListener(_onChange);
    EditorStore.addChangeListener(_onChange);
    return () => {
      LeftMenuStore.removeChangeListener(_onChange);
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setUserState(getStateFromStores());
  };

  const _onUndo = () => {
    if (userState.menuSelected === "touchup") {
      touchup.CommandManager.undo();
    } else {
      window.editor.CommandManager.undo();
    }
  };
  const _onRedo = () => {
    if (userState.menuSelected === "touchup") {
      touchup.CommandManager.redo();
    } else {
      window.editor.CommandManager.redo();
    }
  };

  let undoButton;
  let redoButton;

  if (userState.menuSelected === "touchup") {
    if (userState.touchupUndoCount > 0) {
      undoButton = (
        <div className="undoBtn clickable" onClick={_onUndo} title="Undo">
          <svg viewBox="0 0 386 317.3" height="14">
            <path
              className="a"
              d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                    C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                    c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                    L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                    c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                    c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                    c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                    c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                    c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
            />
          </svg>
        </div>
      );
    } else {
      undoButton = (
        <div className="undoBtn disabled" title="Undo">
          <svg viewBox="0 0 386 317.3" height="14">
            <path
              className="a"
              d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                            C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                            c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                            L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                            c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                            c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                            c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                            c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                            c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
            />
          </svg>
        </div>
      );
    }
    if (userState.touchupRedoCount > 0) {
      redoButton = (
        <div className="redoBtn clickable" onClick={_onRedo} title="Redo">
          <svg viewBox="0 0 386 317.3" height="14">
            <path
              transform="translate(386), scale(-1, 1)"
              className="a"
              d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
            />
          </svg>
        </div>
      );
    } else {
      redoButton = (
        <div className="redoBtn disabled" title="Redo">
          <svg viewBox="0 0 386 317.3" height="14">
            <path
              transform="translate(386), scale(-1, 1)"
              className="a"
              d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
            />
          </svg>
        </div>
      );
    }
  } else {
    if (userState.undoCount > 0) {
      undoButton = (
        <div className="undoBtn clickable" onClick={_onUndo} title="Undo">
          <svg viewBox="0 0 386 317.3" height="14">
            <g id="Layer0_0_FILL">
              <path
                className="a"
                d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                    C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                    c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                    L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                    c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                    c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                    c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                    c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                    c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
              />
            </g>
          </svg>
        </div>
      );
    } else {
      undoButton = (
        <div className="undoBtn disabled" title="Undo">
          <svg viewBox="0 0 386 317.3" height="14">
            <g id="Layer0_0_FILL">
              <path
                className="a"
                d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                    C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                    c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                    L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                    c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                    c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                    c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                    c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                    c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
              />
            </g>
          </svg>
        </div>
      );
    }
    if (userState.redoCount > 0) {
      redoButton = (
        <div className="redoBtn clickable" onClick={_onRedo} title="Redo">
          <svg viewBox="0 0 386 317.3" height="14">
            <path
              transform="translate(386), scale(-1, 1)"
              className="a"
              d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
            />
          </svg>
        </div>
      );
    } else {
      redoButton = (
        <div className="redoBtn disabled" title="Redo">
          <svg viewBox="0 0 386 317.3" height="14">
            <path
              transform="translate(386), scale(-1, 1)"
              className="a"
              d="M264.4,73.2h-1C189.9,72.9,118.8,72.5,50,72l46.7-47c2.7-2.8,4-6.3,4-10.3c0-4.2-1.4-7.6-4.3-10.3
                                C93.6,1.5,90.2,0,86.2,0C82,0,78.5,1.5,75.7,4.5L5.5,75.2c-0.5,0.3-0.8,0.6-1,0.8l-0.3,0.5C1.4,79.1,0,82.4,0,86.4
                                c0,4,1.3,7.5,4,10.5l71.7,74.7c2.7,2.8,6,4.3,10,4.5c4.2,0,7.7-1.4,10.5-4.3c3-2.8,4.5-6.3,4.5-10.3c0.2-4.1-1.2-7.6-4-10.5
                                L49,101.4c70.3,0.3,143,0.8,218.1,1.3c3.2,0,6.8,0.3,11,0.8h0.3c19.5,2.7,36.6,11.4,51.5,26.2c18,17.8,27,39.4,27,64.7
                                c0,25.3-9,47-27,65c-16.7,16.5-36.6,25.3-59.7,26.5l-1,0.3c-1.3,0-2.8,0-4.3,0c-0.5,0-1,0-1.5,0c-1.7,0-3.3,0.2-5,0.5
                                c-2.3,0-4.7,0.1-7,0.3c-25.1,0.7-50.1,1.1-75,1.3c-4.2,0-7.7,1.4-10.5,4.3c-2.8,3-4.3,6.5-4.3,10.5c0,4,1.4,7.5,4.3,10.5
                                c3,2.7,6.5,4,10.5,4c25.2,0,50.5-0.3,76-1h0.3c2.3-0.2,4.8-0.3,7.3-0.5c0.3,0,0.8,0,1.3,0c3,0,6.1-0.1,9.3-0.3
                                c0.2-0.2,0.4-0.2,0.8-0.3h0.5c30.6-1.5,57-13.2,79.2-35.3c23.5-23.7,35.2-52.2,35.2-85.7s-11.7-62-35.2-85.7
                                c-19.8-19.6-42.7-31-68.7-34.2c-5.5-0.8-11.3-1.2-17.3-1.3C264.7,73.2,264.5,73.2,264.4,73.2z"
            />
          </svg>
        </div>
      );
    }
  }
  return (
    <div className="undo-holder">
      <div className="undo-dialog">
        {undoButton}
        {redoButton}
      </div>
    </div>
  );
};

export default Undo;
