import EditorActionCreators from "../actions/EditorActionCreators";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import React, { useEffect, useState } from "react";
import AssetStore from "../stores/AssetStore";
import MenuItemDetail from "./MenuItemDetail.react";
import EditorStore from "../stores/EditorStore";

function getStateFromStores() {
  return {
    mode: EditorStore.getCutoutMode(),
    view: EditorStore.getCutoutView(),
    zoom: window.editor.getCutoutZoom(),
    minZoom: window.editor.getCutoutInitialZoom(),
  };
}

function getFolderDetailListItem(item) {
  return <MenuItemDetail key={item.key} item={item} type="shape" />;
}

const MenuCutout = () => {
  const [storesData, setStoresData] = useState(getStateFromStores());

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    document.addEventListener("keyup", onKeyUp);

    return () => {
      window.editor.removeCutout();
      EditorStore.removeChangeListener(_onChange);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  // const _onPreview = (event) => {
  //   EditorActionCreators.CutoutView("preview");
  // };

  // const _onShow = (event) => {
  //   EditorActionCreators.CutoutView("show");
  // };

  const _onSave = () => {
    window.editor.cutoutSave();
  };

  const _onCancel = () => {
    window.editor.removeCutout();
    LeftMenuActionCreators.MenuSelected("main");
  };

  // const _onLinePaint = (event) => {
  //   EditorActionCreators.CutoutMode("paint");
  // };

  // const _onLineDraw = (event) => {
  //   EditorActionCreators.CutoutMode("line");
  // };

  // const _onLineMove = (event) => {
  //   EditorActionCreators.CutoutMode("move");
  // };

  // const _onPoint = (event) => {
  //   window.editor.onCutoutStartPointing();
  //   EditorActionCreators.CutoutMode("point");
  // };

  // const _onCurve = (event) => {
  //   window.editor.onCutoutStartPointing();
  //   EditorActionCreators.CutoutMode("curve");
  // };

  const _onChange = () => {
    setStoresData(getStateFromStores());
  };

  // const _onShapes = () => {
  //   EditorActionCreators.CutoutMode("shapes");
  // };

  const _onResetShape = () => {
    EditorActionCreators.CutoutMode("shapes");
    window.editor.resetShape();
  };

  // const _onCustom = () => {
  //   EditorActionCreators.CutoutMode("crop");
  // };

  // const _onZoomChange = (event) => {
  //   let zoomSize = event.currentTarget.value;
  //   window.editor.onCutoutZoom(zoomSize);
  //   EditorActionCreators.CutoutMode("pan");
  //   setStoresData(getStateFromStores());
  // };

  // const brushSize = (event) => {
  //   let size = event.target.getAttribute("data-size");
  //   EditorActionCreators.PaintBrushSize(size);
  // };

  // const _onPan = (event) => {
  //   EditorActionCreators.CutoutMode("pan");
  // };

  const _onCropSkip = (event) => {
    window.editor.onCutoutStartPointing();
    EditorActionCreators.CutoutMode("point");
  };

  const _onCrop = (event) => {
    window.editor.onCropComplete();
  };

  const onKeyUp = (event) => {
    event.preventDefault();
    if (event.keyCode === 13 && storesData.mode === "crop") {
      _onCrop();
    }
    if (
      event.keyCode === 13 &&
      (storesData.mode === "move" || storesData.mode === "shape-selected")
    ) {
      _onSave();
    }
  };

  let step2class =
    storesData.mode === "point" ||
    storesData.mode === "move" ||
    storesData.mode === "curve"
      ? "status-blue"
      : "status-grey";
  let step2active =
    storesData.mode === "point" ||
    storesData.mode === "move" ||
    storesData.mode === "curve"
      ? "status-text active"
      : "status-text";
  let step3class = storesData.mode === "move" ? "status-blue" : "status-grey";
  let step3active =
    storesData.mode === "move" ? "status-text active" : "status-text";
  let steptext =
    "Crop the object you want to cut out. This will set the zoom and make it easier.";
  let controls2 = "";
  let controls3 = "";

  switch (storesData.mode) {
    case "crop":
      steptext =
        "Crop the object you want to cut out. This will set the zoom and make it easier.";
      controls3 = (
        <button
          className="btn cutoutFinishBtn my-btn"
          content="APPLY"
          onClick={_onCrop}
        >
          Apply
        </button>
      );
      controls2 = (
        <button
          className="btn cutoutSkipBtn"
          content="SKIP"
          onClick={_onCropSkip}
        >
          SKIP
        </button>
      );
      break;
    case "point":
    case "curve":
      steptext =
        "Connect the dots by CLICKING POINTS AROUND THE EDGE of your shape.  Make sure to go ALL THE WAY AROUND back to the first point to complete the shape.  Don’t worry if you make a mistake, you will be able to fix it in step 3.";
      break;
    case "move":
      steptext =
        "Move the points to refine the edges of your cutout.  When you are done click finish below.";
      controls3 = (
        <button
          className="btn my-btn cutoutFinishBtn"
          content="Finish"
          onClick={_onSave}
        >
          Finish
        </button>
      );
      break;
    case "shapes":
      break;
    default:
  }

  let customControls = (
    <div className="textTypeContainer">
      <div className="status-bar">
        <div>
          <div className="status-circle">
            <div className="status-blue">
              <span>1</span>
            </div>
          </div>
          <div className="connector"></div>
          <div className="status-circle">
            <div className={step2class}>
              <span>2</span>
            </div>
          </div>
          <div className="connector"></div>
          <div className="status-circle">
            <div className={step3class}>
              <span>3</span>
            </div>
          </div>
        </div>
        <div className="text-bar">
          <span className="status-text active">Set Zoom</span>
          <span className={step2active}>Click Points</span>
          <span className={step3active}>Refine Edge</span>
        </div>
      </div>
      <span className="cutout-step1">{steptext}</span>
      <div
        className={`${storesData.mode === "move" ? `d-flex` : `space-between`}`}
      >
        {controls2}
        {controls3}
      </div>
    </div>
  );

  if (storesData.mode === "shapes") {
    controls2 = AssetStore.getShapesDetails().map(getFolderDetailListItem);
    customControls = (
      <div className="cutout-shapes-container">
        {controls2}
        {controls3}
      </div>
    );
  }

  if (storesData.mode === "shape-selected") {
    customControls = (
      <div className="textTypeContainer">
        <div className="space-between">
          <button
            className="btn cutoutCancelBtn"
            content="RESET SHAPE"
            onClick={_onCancel}
          >
            Cancel
          </button>
          <button
            className="btn cutoutChangeBtn"
            content="RESET SHAPE"
            onClick={_onResetShape}
          >
            Change Shape
          </button>
        </div>
        <button
          className="btn mt-2 my-btn cutoutFinishBtn"
          content="Finish"
          onClick={_onSave}
        >
          Finish
        </button>
      </div>
    );
  }
  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        <span>Cutout Tool</span>
      </div>
      {customControls}
    </div>
  );
};

export default MenuCutout;
