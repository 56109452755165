import React, { useEffect, useMemo, useState } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import LoginMain from "./LoginMain.react";
import LoginEmail from "./LoginEmail.react";
import LoginRegister from "./LoginRegister.react";
import LoginForgot from "./LoginForgot.react";
import LoginForgotResponse from "./LoginForgotResponse.react";
import { useNavigate } from "react-router-dom";
import HelmetDetails from "./HelmetDetails";

function getStateFromStores() {
  return {
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    lastLogins: UserStore.getLastLogins(),
  };
}

const LoginPage = () => {
  const [data, setData] = useState(getStateFromStores());
  const navigate = useNavigate();
  const _onChange = () => {
    setData(getStateFromStores());
  };

  const logUserIn = (e) => {
    const userName = e.currentTarget.attributes["datakey"].value;
    if (userName) {
      for (var x = 0; x < data.lastLogins.length; x++) {
        if (data.lastLogins[x].user.UserName === userName) {
          UserActionCreators.userLoggedIn(data.lastLogins[x]);
        }
      }
    }
  };

  useEffect(() => {
    if (UserStore.getUser()) {
      navigate("/app/start");
    } else {
      UserStore.addChangeListener(_onChange);
      if (UserStore.getUser() === null) {
        UserActionCreators.updateUserData(UserStore.getAccessToken());
      }
      return () => {
        UserStore.removeChangeListener(_onChange);
      };
    }
  }, []);

  const lastLoginsComp = useMemo(() => {
    return data.lastLogins
      ? data.lastLogins.map((item, index) => {
          return (
            <div
              className="recentLogin clickable"
              key={index}
              onClick={logUserIn}
              datakey={item.user.UserName}
            >
              <img className="user-img" src={item.user.ProfileImage} alt="" />

              <div className="recentLoginDetails">
                <div>{item.user.Name || item.user.UserName}</div>
                <div className="recentEmail">{item.user.Email}</div>
                <div>
                  {item.user.GooglePlusUserId ? (
                    <i className="icon-gplus-light"></i>
                  ) : null}
                  {item.user.GooglePlusUserId ? (
                    <i className="icon-twitter-light"></i>
                  ) : null}
                  {item.user.FBUserId ? (
                    <i className="icon-facebook-light"></i>
                  ) : null}
                </div>
              </div>
              {item.user.isPRO ? (
                <img
                  className="recentLoginPro"
                  src="https://static.pizap.com/html5/resources/editor/pizap_pro.svg"
                  alt="piZap pro logo"
                />
              ) : null}
            </div>
          );
        })
      : null;
  });

  const lastLoginColumn = useMemo(() => {
    return lastLoginsComp ? (
      <div className="loginColumns">
        <div className="loginHeading">Recent logins</div>
        <div className="loginSelectAccount">Select your account</div>
        <div className="recentLoginContainer">{lastLoginsComp}</div>
      </div>
    ) : null;
  });

  const comp = useMemo(() => {
    return UserStore.getLoginMode() === "email" ? (
      <LoginEmail />
    ) : UserStore.getLoginMode() === "register" ? (
      <LoginRegister />
    ) : UserStore.getLoginMode() === "save" ? (
      <div className="saveLogin">
        <div className="largeHeading">Create an account</div>
        <div className="normalText">
          You need to create a free account
          <br />
          so you can save your image
        </div>
        <LoginMain />
      </div>
    ) : (
      <LoginEmail />
    );
  }, [UserStore.getLoginMode()]);

  const forgot = useMemo(() => {
    return UserStore.getLoginMode() === "forgot" ? (
      <LoginForgot />
    ) : UserStore.getLoginMode() === "forgotResponse" ? (
      <LoginForgotResponse />
    ) : null;
  });

  return (
    <div className="loginPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app/login"
        keywords=""
      />
      <div className="colorBarContainer"></div>
      <div className="loginContainer">
        <div className="loginColumnsWrapper">
          {lastLoginColumn}
          <div className="loginColumns">
            <div className="loginHeading">Log in to your account</div>
            <LoginEmail />
          </div>
        </div>
      </div>
      <div
        className="floatLogo"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      >
        <img
          className="logo-text"
          src="//cdn.pizap.com/pizapfiles/images/pizap_logo_rounded_2023.510ca6fa.png"
          alt="piZap editor collage maker"
        />
      </div>
      {forgot}
    </div>
  );
};

export default LoginPage;
