import React, { useEffect, useState } from "react";
import EditorStore from "../stores/EditorStore";
import { SketchPicker } from "react-color";
import EditorActionCreators from "../actions/EditorActionCreators";

function getStateFromStores() {
  return {
    selectedObject: EditorStore.getSelectedObject(),
    colorCode: EditorStore.getColorPickerColorCode(),
    callback: EditorStore.getColorPickerCallback(),
  };
}
function findPos(obj) {
  var curleft = 0;
  var curtop = 0;
  if (obj.offsetParent) {
    do {
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
      obj = obj.offsetParent;
    } while (obj);
    return [curleft, curtop];
  }
}

const ColorWindow = () => {
  const [data, setData] = useState(getStateFromStores());
  const [eyedropperActive, setEyedropperActive] = useState(false);
  let isMounted = false;
  let offsetX = 0;
  let offsetY = 0;
  useEffect(() => {
    isMounted = true;
    if (isMounted) {
      window.editor.setColorWindowShow(false);
      EditorStore.addChangeListener(_onChange);
    }

    return () => {
      isMounted = false;
      EditorStore.removeChangeListener(_onChange);
      window.editor
        .getRenderer()
        .view.removeEventListener("click", onCanvasClick);
    };
  }, []);

  const _onChange = () => {
    try {
      if (isMounted) {
        setData(getStateFromStores());
      }
    } catch (e) {}
  };

  const handleChangeComplete = (color) => {
    if (data.callback) {
      data.callback(color.hex);
    }
  };

  const _onEyedropper = (event) => {
    window.editor.setColorWindowShow(!window.editor.getColorWindowShow());
    setEyedropperActive(!eyedropperActive);
    if (window.editor.getColorWindowShow()) {
      //            window.editor.getRenderer().view.addEventListener('mousemove', this.onCanvasMove, false);
      window.editor.getRenderer().view.addEventListener("click", onCanvasClick);
      window.editor.getRenderer().view.classList.add("eyedropper");
      EditorStore.setColorSelectorOpen(true);
    } else {
      EditorStore.setColorSelectorOpen(false);
      //            window.editor.getRenderer().view.removeEventListener("mousemove", this.onCanvasMove);
      window.editor
        .getRenderer()
        .view.removeEventListener("click", onCanvasClick);
      window.editor.getRenderer().view.classList.remove("eyedropper");
    }
    EditorStore.emitChange();
  };

  const _onOK = (event) => {
    window.editor.setColorWindowShow(false);
    setEyedropperActive(false);
    window.editor
      .getRenderer()
      .view.removeEventListener("click", onCanvasClick);
    window.editor.getRenderer().view.classList.remove("eyedropper");

    EditorActionCreators.ColorPickerShow(false);
  };
  const _onCancel = (event) => {
    setEyedropperActive(false);
    window.editor.setColorWindowShow(false);
    window.editor
      .getRenderer()
      .view.removeEventListener("click", onCanvasClick);
    window.editor.getRenderer().view.classList.remove("eyedropper");
    if (data.callback && EditorStore.getColorPickerOriginalColor()) {
      data.callback(EditorStore.getColorPickerOriginalColor());
    }
    EditorActionCreators.ColorPickerShow(false);
  };
  const _onMover = (event) => {
    event.stopPropagation();
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onMoveEnd);
    offsetX = event.clientX - event.currentTarget.offsetParent.offsetLeft;
    offsetY = event.clientY - event.currentTarget.offsetParent.offsetTop;
  };

  const onMoveEnd = (event) => {
    event.stopPropagation();
    document.removeEventListener("mouseup", onMoveEnd);
    document.removeEventListener("mousemove", onMove);
  };

  const onMove = (event) => {
    event.stopPropagation();
    EditorStore.setPropManualPosition(true);
    let currentX = event.clientX - offsetX;
    let currentY = event.clientY - offsetY;

    let edp = document.getElementById("color-window");
    if (edp) {
      edp.style.top = currentY + "px";
      edp.style.left = currentX + "px";
      edp.style.marginTop = "unset";
      edp.style.marginBottom = "unset";
    }
  };

  const _onDelete = () => {};

  const onCanvasClick = (e) => {
    e.preventDefault();
    let ctxMain = window.editor.getRenderer().view.getContext("webgl");
    let canvasOffset = findPos(window.editor.getRenderer().view);
    let canvasX =
      Math.floor(e.clientX - canvasOffset[0]) *
      window.editor.getStartingDevicePixelRatio();
    let canvasY =
      Math.floor(e.clientY - canvasOffset[1]) *
      window.editor.getStartingDevicePixelRatio();

    // get current pixel
    let pixels = new Uint8Array(4);
    ctxMain.readPixels(
      canvasX,
      ctxMain.drawingBufferHeight - canvasY,
      1,
      1,
      ctxMain.RGBA,
      ctxMain.UNSIGNED_BYTE,
      pixels
    );
    let pixel = pixels;
    let pixelColor =
      "#" +
      pixel[0].toString(16).padStart(2, "0") +
      pixel[1].toString(16).padStart(2, "0") +
      pixel[2].toString(16).padStart(2, "0");
    if (data.callback) {
      data.callback(pixelColor);
    }
    if (window.editor.getColorWindowShow()) _onEyedropper();
    return true;
  };

  return (
    <div id="color-window" className="color-window-holder">
      <div className="color-window-dialog">
        <div className="property-mover" onMouseDown={_onMover}>
          <svg
            viewBox="0 0 501.8 501.7"
            height="16px"
            style={{ padding: "2px" }}
            className={window.editor.getColorWindowShow() ? "active" : ""}
            onClick={_onEyedropper}
          >
            <g transform="matrix( 1, 0, 0, 1, 0,0) ">
              <g id="Layer0_0_FILL">
                <path
                  className="st0"
                  d="M478.5,31.4C460.4,11.1,444.9,0.7,432.1,0c-16.7-0.7-35.9,8.7-57.6,28.2l-77,77l-31.3-31.3
                                        c-8.7-8.1-17.5-8.1-26.3,0l-27.6,27.6c-7.4,8.6-7.4,17.1,0,25.6l23.2,21.9L37.9,346.8c-13.3,16.3-17.4,36-12.5,58.9
                                        C6.4,422-1.9,440,0.4,459.4c1.7,12.5,5.9,21.7,12.5,27.5c7.2,7.9,17,12.7,29.5,14.4c19.5,2.2,37.4-6.1,53.8-25.1
                                        c22.9,4.9,42.5,0.7,58.8-12.5l197.8-197.7l21.9,23.1c8.5,7.4,17.1,7.4,25.6,0l27.5-27.5c8.2-8.8,8.2-17.5,0-26.3l-31.3-31.3
                                        l77-77c19.5-21.6,28.9-40.8,28.2-57.5C501.1,56.8,493.3,44,478.5,31.4 M255.1,171.6l75.1,75.1L218.8,358H68.6L255.1,171.6z"
                />
              </g>
            </g>
          </svg>
          <span onClick={_onCancel} className="clickable right">
            Cancel
          </span>
          <span onClick={_onOK} className="clickable right">
            OK
          </span>
        </div>
        <div
          className="prop-group-template clickable"
          onClick={_onDelete}
          title="Remove"
        >
          <SketchPicker
            color={data.colorCode}
            disableAlpha={true}
            onChange={handleChangeComplete}
            presetColors={[
              "#ffffff",
              "#ebebeb",
              "#d6d6d6",
              "#c2c2c2",
              "#adadad",
              "#999999",
              "#858585",
              "#707070",
              "#5c5c5c",
              "#474747",
              "#333333",
              "#000000",
              "#008DB0",
              "#0052CD",
              "#3B108D",
              "#841598",
              "#A51A50",
              "#F6081E",
              "#EB4B21",
              "#DF7E2C",
              "#DA9B34",
              "#F4ED4D",
              "#BFD146",
              "#559D42",
              "#00A0D2",
              "#005EF4",
              "#5317AC",
              "#A51BB4",
              "#CB205C",
              "#FF312C",
              "#FF622B",
              "#FFA83A",
              "#FFC843",
              "#FDFC68",
              "#D3ED5C",
              "#5BBE52",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorWindow;
