import React, { useEffect, useState } from "react";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import EditorStore from "../stores/EditorStore";
import AssetStore from "../stores/AssetStore";
import ColorPicker from "./ColorPicker.react";
import RangeSlider from "./RangeSlider";
import { ReactComponent as DeleteIcon } from "../images/editor/delete_icon.svg";

function getStateFromStores() {
  return {
    borderContainer: window.editor.getBorderContainer(),
    customWidth: EditorStore.getCustomWidth(),
    customColor: EditorStore.getCustomColor(),
  };
}

const MenuBorderCustom = () => {
  const [data, setData] = useState(getStateFromStores());

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _onSubMenuClick = (event) => {
    LeftMenuActionCreators.MenuSelected("border");
  };

  const _onRemove = (evt) => {
    window.editor.getBorderContainer().removeChildren();
    if (EditorStore.getCustomBorder() === true) {
      window.editor.customBorderRemove();
    }
    AssetStore.emitChange();
  };

  const _onWidthChange = (value) => {
    EditorActionCreators.CustomWidth(value);
  };

  const colorPicked = (colorCode) => {
    EditorActionCreators.CustomColor(colorCode);
  };

  const handleResetWidth = () => {
    EditorActionCreators.CustomWidth(10);
  };

  return (
    <div className="menu-container large" id="menu-container">
      <div className="menu-header">
        <div className="photos-nav">
          <div onClick={_onSubMenuClick} className="clickable d-flex">
            <svg viewBox="0 0 960 560" transform="rotate(90)" height="16">
              <path
                className="st0"
                d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
              ></path>
            </svg>
            <span>Custom Borders</span>
          </div>
        </div>
      </div>

      {(window.editor.getBorderContainer().children.length > 0 ||
        EditorStore.getCustomBorder() === true) && (
        <div
          className="clickable remove-border-btn semi-clickable-button mt-2"
          onClick={_onRemove}
        >
          <DeleteIcon width={16} />
          &nbsp;Remove Border
        </div>
      )}
      <div className="borderCustom space-between">
        <div style={{ width: "60%" }}>
          <RangeSlider
            title="Width"
            min={1}
            max={100}
            step={1}
            value={data.customWidth}
            handleChange={_onWidthChange}
            handleReset={handleResetWidth}
            isReset={data.customWidth !== 10}
            isBox={false}
            handleMouseEnter={() => {}}
            handleMouseLeave={() => {}}
            startPoint={1}
          />
        </div>
        <ColorPicker
          iconClass="brush-color"
          text="Color"
          onColor={colorPicked}
          initialColor={data.customColor}
          opacity={1}
        />
      </div>
    </div>
  );
};

export default MenuBorderCustom;
