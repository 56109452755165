import React from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import HelmetDetails from "../components/HelmetDetails";

const MyPizap = () => {
  return (
    <div className="home-page">
      <Header />
      <HelmetDetails
        title="piZap: Simple Design & Photo Editor | Receive Direct marketing Communications"
        description="Design simplified! piZap is your go-to easy design tool, photo editor and collage maker. Create stunning social media posts with our Social Media Design Tool, Instagram Post Designer, and more."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/gallery/mypizap?duration=account"
        keywords="Easy Design Tool, Easy Photo Editor, Collage Maker, Social Media Design Tool, Instagram Post Designer, Meme Maker, Logo Maker, A.I. Image Creator, Card Designer, Flyer Designer, Invitation Maker, Wallpaper Designer, Facebook Cover Maker, Twitter Cover Maker"
      />
      <div className="thankspart">
        <div className="thankspartner">
          <div style={{ alignContent: "center" }}>
            <p
              style={{
                fontSize: "100px",
                height: "200px",
                textAlign: "center",
              }}
            >
              My piZap
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyPizap;
