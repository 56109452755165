import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import AssetStore from "../stores/AssetStore";
import MenuItem from "./MenuItem.react";
import MenuItemDetail from "./MenuItemDetail.react";
import EditorActionCreators from "../actions/EditorActionCreators";
import Dexie from "dexie";
import LeftMenuStore from "../stores/LeftMenuStore";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

function getStateFromStores() {
  return {
    selectedItem: AssetStore.getSelectedItem(),
    folders: AssetStore.getCollageFolders(),
    folderItems: AssetStore.getCollageFolderDetails(),
  };
}

const CollagePicker = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(getStateFromStores());
  const [project, setProject] = useState(null);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      AssetStore.addChangeListener(_onChange);
      let db = new Dexie("pizapProjects");
      db.version(1).stores({
        projects: "id,created,createdBy, mode",
      });
      db.open();
      db.projects
        .where("id")
        .equals("latestProject")
        .each((p) => {
          setProject(p);
          LeftMenuStore.emitChange();
        });
      mounted.current = true;
    } else {
      let container = document.getElementById("collagepicker-album-files");
      if (container) {
        if (lastSelectedItem !== data.selectedItem) {
          let dom = ReactDOM.findDOMNode(container);
          container.scrollTop = 0;
          if (dom) dom.scrollTop = 0;
          setLastSelectedItem(data.selectedItem);
        }
      }
    }

    return () => {
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const getCollageFolderItem = (item) => {
    return <MenuItemDetail key={item.key} item={item} type="collage" />;
  };

  const getFolderItemState = (items) => {
    return items.map(getCollageFolderItem);
  };
  const getFolders = (folders) => {
    return folders
      .filter((item) => {
        return !item.HasBackground;
      })
      .map(getCollageFolder);
  };

  const getCollageFolder = (item) => {
    let i = {
      active: data.selectedItem?.name
        ? item.Name === data.selectedItem.name
        : true,
      name: item.Name,
      key: item.Name,
      isProOnly: item.PROOnly,
      icon: item.icon,
      isRectangle: item.isRectangle,
      HasBackground: item.HasBackground,
      nonRectangle: item.nonRectangle,
    };
    return <MenuItem key={i.key} item={i} type="collage" />;
  };

  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCollagePicker(false);
  };

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _restoreClick = (event) => {
    window.editor.import(JSON.stringify(project));
    EditorActionCreators.ShowFilePicker(false);
    EditorActionCreators.ShowCollagePicker(false);
  };

  const folders = useMemo(() => {
    return getFolders(data.folders);
  }, [data.selectedItem]);

  const items = useMemo(() => {
    return getFolderItemState(data.folderItems);
  }, [data.selectedItem]);

  const goStart = () => {
    EditorActionCreators.ShowCollagePicker(false);
    navigate("/app/start");
  };

  return (
    <div className="photopicker" id="photopicker">
      <div id="photopicker-dialog" className="photopicker-dialog">
        <div className="photopicker-dialog-box">
          <div className="photopicker-navbar">
            <div className="photopicker-title">Select a Collage Template</div>
            {window.editor.getRenderer() ? (
              <div className="close-btn" onClick={_cancelClick}>
                <CloseSVG height={12} />
              </div>
            ) : (
              <div className="close-btn" onClick={goStart}>
                <CloseSVG height={12} />
              </div>
            )}
          </div>

          <div id="photopicker-container">
            <div id="photopicker-albums-list" className="photopicker-list">
              {project && LeftMenuStore.get() === "init" ? (
                <div
                  className="photopicker-list-item"
                  data-id="restore"
                  onClick={_restoreClick}
                >
                  <img
                    src="https://cdn.pizap.com/pizapfiles/images/pizap-logo-52x52.png"
                    height="25"
                    width="25"
                    alt=""
                  />
                  <span className="name">Restore Last Project</span>
                </div>
              ) : null}
              {folders}
            </div>
            <div id="collagepicker-album-files" className="photopicker-photos">
              <div id="scroll-content">{items}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollagePicker;
