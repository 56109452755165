import React, { useEffect, useState, useMemo, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as DeleteIcon } from "../images/editor/delete_icon.svg";
import { ReactComponent as ReplaceIcon } from "../images/editor/replace_icon.svg";
import { ReactComponent as RotateIcon } from "../images/editor/rotate-right.svg";
import { ReactComponent as FlipVerticalIcon } from "../images/editor/flip_vertical_icon.svg";
import { ReactComponent as CropIcon } from "../images/editor/crop_icon.svg";
import { ReactComponent as FlipHorizontalIcon } from "../images/editor/flip_horizontal_icon.svg";
import FiltersIcon from "../images/editor/filter_icon.svg";
import FaceRetouchIcon from "../images/editor/face_touchup_icon.svg";
import AdjustIcon from "../images/editor/adjust_icon.svg";
import EffectItem from "./EffectItem";
import AssetStore from "../stores/AssetStore";
import LeftMenuActionCreators from "../actions/LeftMenuActionCreators";
import MenuItem from "./MenuItem.react";
import RangeSlider from "./RangeSlider";
import CarouselButtonGroup from "./CarouselButtonGroup";
import { CarouselResponsive } from "../utils/utils";

function getStateFromStores() {
  return {
    editorType: EditorStore.getEditorType(),
    settings: EditorStore.getImageSettings(),
    mode: EditorStore.getEditorMode(),
    cropType: EditorStore.getSelectedCropType(),
    projects: UserStore.getProjects(),
    backgroundColor:
      window.editor.getBaseObject() && window.editor.getBaseObject().fillColor
        ? window.editor.getBaseObject().fillColor
        : 0xffffff,
    faceTouchups: AssetStore.getTouchupFolders(),
  };
}

const MenuMain = () => {
  let _checkboxState = false;
  const [storesData, setStoresData] = useState(getStateFromStores());
  const minSize = EditorStore.getDesignBackground()
    ? EditorStore.getDesignBackground().initialZoom
    : 1;
  const maxSize = EditorStore.getDesignBackground()
    ? EditorStore.getDesignBackground().initialZoom + 2
    : 3;

  const [imageSize, setImageSize] = useState(
    EditorStore.getDesignBackground()
      ? EditorStore.getDesignBackground().scale.x
      : 1
  );
  const [filterChecked, setFilterChecked] = useState(false);
  const [adjustmentsChecked, setAdjustmentsChecked] = useState(false);
  const [faceChecked, setFaceChecked] = useState(false);
  const [backgroundeffect, setBackgroundEffect] = useState(null);
  const [intensity, setIntensity] = useState(
    EditorStore.getBackgroundEffect() && EditorStore.getBackgroundEffect().zoom
      ? EditorStore.getBackgroundEffect().zoom
      : 1
  );
  const [isAdjustSeeAll, setIsAdjustSeeAll] = useState(false);
  const [isFaceSeeAll, setIsFaceSeeAll] = useState(false);
  const onKeyUp = (e) => {
    e.preventDefault();
    if (e.keyCode === 13 && storesData.mode === "crop") {
      _onCropFinish();
    }
  };
  const isMounted = useRef(true);
  const _onStraightenReset = () => {
    EditorStore.getImageSettings().straighten = 0;
    let bo =
      EditorStore.getEditorType() === "design"
        ? EditorStore.getDesignBackground()
        : window.editor.getBaseObject();
    let rotate = bo.rotate ? bo.rotate : 0;
    bo.rotation = rotate;
    let width = bo.width;
    let height = bo.height;

    if (width > height) {
      width = bo.height;
      height = bo.width;
    }
    let a = Math.atan(height / width);
    let len1 = width / 2 / Math.cos(a - Math.abs(bo.rotation));
    let len2 = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
    let scale = len2 / len1;
    if (EditorStore.getEditorType() !== "design") {
      bo.scale.set(bo.initialScaleX * scale, bo.initialScaleY * scale);
    } else {
      zoom(bo.initialZoom * scale);
    }
    bo.straightenScale = scale;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onStraightenChange = (value) => {
    let bo =
      EditorStore.getEditorType() === "design"
        ? EditorStore.getDesignBackground()
        : window.editor.getBaseObject();
    if (!bo) return;
    let oldStraighten = storesData.settings.straighten
      ? storesData.settings.straighten
      : 0;
    let oldStraightenScale = bo.straightenScale ? bo.straightenScale : 1;
    let oldScale = bo.scale.x ? bo.scale.x : 1;
    window.editor.CommandManager.execute({
      execute: function () {
        EditorStore.getImageSettings().straighten = value;
        let rotate = bo.rotate ? bo.rotate : 0;
        bo.rotation = rotate + parseFloat(value);
        let width = bo.width ? bo.width : bo._width;
        let height = bo.height ? bo.height : bo._height;
        if (width > height) {
          width = bo.height ? bo.height : bo._height;
          height = bo.width ? bo.width : bo._width;
        }
        let a = Math.atan(height / width);
        // the length from the center to the corner of the green
        let len1 = width / 2 / Math.cos(a - Math.abs(bo.rotation));
        // the length from the center to the corner of the black
        let len2 = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
        // compute the scaling factor
        let scale = len2 / len1;
        if (EditorStore.getEditorType() !== "design") {
          bo.scale.set(bo.initialScaleX * scale, bo.initialScaleY * scale);
        } else {
          zoom(bo.initialZoom * scale);
        }
        bo.straightenScale = scale;
        EditorActionCreators.ModifyImageSettings(storesData.settings);
      },
      unexecute: function () {
        bo.rotation = oldStraighten;
        storesData.settings.straighten = oldStraighten;
        bo.scale.set(oldScale);
        bo.straightenScale = oldStraightenScale;
        EditorActionCreators.ModifyImageSettings(storesData.settings);
      },
    });
  };

  const _onTemperatureChange = (value) => {
    EditorStore.getImageSettings().temperature = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onBrightnessChange = (value) => {
    EditorStore.getImageSettings().brightness = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onContrastChange = (value) => {
    EditorStore.getImageSettings().contrast = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onSaturationChange = (value) => {
    EditorStore.getImageSettings().saturation = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onHueChange = (value) => {
    EditorStore.getImageSettings().hue = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onRedChange = (value) => {
    EditorStore.getImageSettings().red = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onGreenChange = (value) => {
    EditorStore.getImageSettings().green = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onBlueChange = (value) => {
    EditorStore.getImageSettings().blue = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onNoiseChange = (value) => {
    EditorStore.getImageSettings().noise = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };
  const _onDenoiseChange = (value) => {
    EditorStore.getImageSettings().denoise = value;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onChange = () => {
    if (isMounted.current) setStoresData(getStateFromStores());
  };

  const _onRotate = () => {
    if (
      storesData.settings.straighten &&
      storesData.settings.straighten !== 0
    ) {
      let bo = window.editor.getBaseObject();
      bo.rotation -= storesData.settings.straighten;
      EditorStore.getImageSettings().straighten = 0;
    }
    window.editor.rotate(window.editor.getBaseObject());
  };

  const _onShowCropSelector = () => {
    EditorActionCreators.ShowCropPicker(true);
  };

  const _onCrop = () => {
    if (
      storesData.settings.straighten &&
      storesData.settings.straighten !== 0
    ) {
      let bo = window.editor.getBaseObject();
      bo.rotation -= storesData.settings.straighten;
      EditorStore.getImageSettings().straighten = 0;
      bo.scale.set(
        window.editor.getBaseObject().initialScaleX,
        window.editor.getBaseObject().initialScaleY
      );
      bo.straightenScale = 1;
    }
    EditorActionCreators.EditorMode("crop");
  };

  const _onCropCancel = () => {
    EditorStore.setSelectedCropType(null);
    window.editor.cropCancel();
    EditorActionCreators.EditorMode("view");
  };

  const _onCropFinish = () => {
    window.editor.cropFinish();
    EditorActionCreators.EditorMode("view");
  };

  const _onBrightReset = () => {
    EditorStore.getImageSettings().brightness = 0;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onContrastReset = () => {
    EditorStore.getImageSettings().contrast = 0.8;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onTempReset = () => {
    EditorStore.getImageSettings().temperature = 0;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onSatReset = () => {
    EditorStore.getImageSettings().saturation = 0;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onHueReset = () => {
    EditorStore.getImageSettings().hue = 0;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onRedReset = () => {
    EditorStore.getImageSettings().red = 1;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onGreenReset = () => {
    EditorStore.getImageSettings().green = 1;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onBlueReset = () => {
    EditorStore.getImageSettings().blue = 1;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onNoiseReset = () => {
    EditorStore.getImageSettings().noise = 0;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const _onDenoiseReset = () => {
    EditorStore.getImageSettings().denoise = 50;
    EditorActionCreators.ModifyImageSettings(storesData.settings);
  };

  const handleBackgroundOpacityChange = (value) => {
    EditorStore.getDesignBackground().alpha = value;
    EditorStore.emitChange();
  };

  const handleOpacityReset = () => {
    EditorStore.getDesignBackground().alpha = 1;
    EditorStore.emitChange();
  };

  const handleRotate = () => {
    let sticker = EditorStore.getDesignBackground();
    let baseOffsets = window.editor.getBaseOffsets();
    sticker.rotation += 1.5708;
    if (sticker.rotation === 1.5708 + 1.5708 + 1.5708 + 1.5708) {
      sticker.rotation = 0;
    }
    let offsets = window.editor.scaleToFit(
      baseOffsets.width,
      baseOffsets.height,
      sticker.texture.width,
      sticker.texture.height
    );
    let scale;
    if (sticker.rotation === 1.5708 * 3 || sticker.rotation === 1.5708) {
      if (offsets.width < baseOffsets.height) {
        scale = baseOffsets.height / sticker.texture.width;
        sticker.scale.x = scale;
        sticker.scale.y = scale;
        if (sticker.height < baseOffsets.width) {
          scale = baseOffsets.width / sticker.texture.height;
          sticker.scale.x = scale;
          sticker.scale.y = scale;
        }
      } else {
        scale = baseOffsets.width / sticker.texture.height;
        sticker.scale.x = scale;
        sticker.scale.y = scale;
        if (sticker.width < baseOffsets.height) {
          scale = baseOffsets.height / sticker.texture.width;
          sticker.scale.x = scale;
          sticker.scale.y = scale;
        }
      }
      sticker.position.x = baseOffsets.width / 2;
      sticker.position.y = baseOffsets.height / 2;
    } else {
      if (offsets.height < baseOffsets.height) {
        scale = baseOffsets.height / sticker.texture.height;
        sticker.scale.x = scale;
        sticker.scale.y = scale;
      } else {
        scale = baseOffsets.width / sticker.texture.width;
        sticker.scale.x = scale;
        sticker.scale.y = scale;
      }
      sticker.position.x = baseOffsets.width / 2;
      sticker.position.y = baseOffsets.height / 2;
    }
    sticker.initialZoom = sticker.scale.x;
    setStoresData(getStateFromStores());
  };

  const handleFlipHorizontal = () => {
    EditorStore.getDesignBackground().scale.x *= -1;
  };

  const handleFlipVertical = () => {
    EditorStore.getDesignBackground().scale.y *= -1;
  };

  const _onZoomReset = (event) => {
    setImageSize(EditorStore.getDesignBackground().initialZoom);
    zoom(EditorStore.getDesignBackground().initialZoom);
  };

  const handleReplaceBackground = () => {
    EditorActionCreators.ShowFilePicker(true, "design");
    if (EditorStore.getDesignBackground()) {
      _onStraightenReset();
      EditorStore.setBackgroundEffect(null);
      _onBrightReset();
      _onContrastReset();
      _onTempReset();
      _onHueReset();
      _onSatReset();
      _onDenoiseReset();
      _onNoiseReset();
      _onRedReset();
      _onBlueReset();
      _onGreenReset();
      window.editor.setSelectedObject(null);
      window.editor.deleteDesignBackground();
    }
  };

  const zoom = (_zoom) => {
    let sticker = EditorStore.getDesignBackground();
    if (sticker.scale.x < 0) {
      sticker.scale.x = _zoom * -1;
      sticker.scale.y = _zoom;
    } else {
      sticker.scale.x = _zoom;
      sticker.scale.y = _zoom;
    }
    let newX = sticker.position.x - sticker.width / 2;
    let newY = sticker.position.y - sticker.height / 2;
    if (newX > 0) {
      sticker.position.x = sticker.width / 2;
    }
    if (newY > 0) {
      sticker.position.y = sticker.height / 2;
    }
    if (newX + sticker.width < window.editor.getBaseOffsets().width) {
      sticker.position.x =
        window.editor.getBaseOffsets().width - sticker.width / 2;
    }
    if (newY + sticker.height < window.editor.getBaseOffsets().height) {
      sticker.position.y =
        window.editor.getBaseOffsets().height - sticker.height / 2;
    }
  };

  const removeEffect = () => {
    if (EditorStore.getEditorType() === "collage") {
      if (window.editor.getSelectedObject()) {
        window.editor.getSelectedObject().filters = null;
      }
    } else {
      window.editor.getBaseObject().removeChildren();
    }
    EditorStore.setCurrentEffect(null);
    window.editor.getEffectBorderContainer().removeChildren();
    window.editor.getBaseObject().filters = null;

    if (EditorStore.getDesignBackground()) {
      EditorStore.getDesignBackground().filters = null;
    }
    EditorStore.setImageSettings(EditorStore.getImageSettings());
    window.editor.realApplyEffect({ Effect: [] });
    AssetStore.emitChange();
  };

  const handleChangeFilter = (e) => {
    setFilterChecked(e.target.checked);
    if (!e.target.checked) {
      setBackgroundEffect(null);
      EditorStore.setBackgroundEffect(null);
      removeEffect();
      setIntensity(1);
    }
  };

  const handleChangeAdjustment = (e) => {
    setAdjustmentsChecked(e.target.checked);
    if (!e.target.checked) {
      _onBrightReset();
      _onContrastReset();
      _onTempReset();
      _onHueReset();
      _onSatReset();
      _onDenoiseReset();
      _onNoiseReset();
      _onRedReset();
      _onBlueReset();
      _onGreenReset();
    }
  };

  const handleChangeFace = () => {
    setFaceChecked(!faceChecked);
  };

  const handleSeeAllAdjustment = () => {
    setIsAdjustSeeAll(true);
  };

  const handleSeeAllFace = (e) => {
    setIsFaceSeeAll(true);
    e.preventDefault();
    LeftMenuActionCreators.MenuSelected("touchup");
    window.editor.setSelectedObject(null);
  };

  const handleClickBack = () => {
    setIsAdjustSeeAll(false);
    setIsFaceSeeAll(false);
  };

  const handleChangeIntensity = (value) => {
    EditorStore.getBackgroundEffect().zoom = value;
    let effect = JSON.parse(JSON.stringify(EditorStore.getBackgroundEffect()));
    for (let x = 0; x < effect.Effect.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value) * value;
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value) * value;
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value) * value;
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2) * value;
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3) * value;
          break;
        default:
          if (!isNaN(effect.Effect[x].value))
            effect.Effect[x].value = effect.Effect[x].value * value;
      }
    }
    window.editor.realApplyEffect(effect, true);
    setIntensity(value);
  };

  const handleResetIntensity = () => {
    setIntensity(1);
    EditorStore.getBackgroundEffect().zoom = 1;
    let effect = JSON.parse(JSON.stringify(EditorStore.getBackgroundEffect()));
    for (let x = 0; x < effect.Effect.length; x++) {
      switch (effect.Effect[x].filter) {
        case "contrast":
          effect.Effect[x].value = 0.8 - (0.8 - effect.Effect[x].value);
          break;
        case "denoise":
          effect.Effect[x].value = 50 - (50 - effect.Effect[x].value);
          break;
        case "rgb":
          effect.Effect[x].value = 1 - (1 - effect.Effect[x].value);
          effect.Effect[x].value2 = 1 - (1 - effect.Effect[x].value2);
          effect.Effect[x].value3 = 1 - (1 - effect.Effect[x].value3);
          break;
        default:
          effect.Effect[x].value = effect.Effect[x].value;
      }
    }
    window.editor.realApplyEffect(effect, true);
  };

  const _onDeleteBackground = () => {
    _onStraightenReset();
    EditorStore.setBackgroundEffect(null);
    _onBrightReset();
    _onContrastReset();
    _onTempReset();
    _onHueReset();
    _onSatReset();
    _onDenoiseReset();
    _onNoiseReset();
    _onRedReset();
    _onBlueReset();
    _onGreenReset();
    window.editor.deleteDesignBackground();
  };

  const _onFlipHorizontal = (event) => {
    event.stopPropagation();
    if (window.editor.getBaseObject()) {
      if (window.editor.getBaseObject().scale.x < 0) {
        window.editor.getBaseObject().scale.x *= -1;
        window.editor.getBaseObject().mirrored = false;
      } else {
        window.editor.getBaseObject().scale.x *= -1;
        window.editor.getBaseObject().mirrored = true;
      }
    }
    window.editor.getBaseObject().initialScaleX *= -1;
    EditorStore.emitChange();
  };

  const _onFlipVertical = (event) => {
    event.stopPropagation();
    if (window.editor.getBaseObject()) {
      if (window.editor.getBaseObject().scale.y < 0) {
        window.editor.getBaseObject().scale.y *= -1;
        window.editor.getBaseObject().mirrored = false;
      } else {
        window.editor.getBaseObject().scale.y *= -1;
        window.editor.getBaseObject().mirrored = true;
      }
    }
    window.editor.getBaseObject().initialScaleY *= -1;
    EditorStore.emitChange();
  };

  const _autoAdjust = () => {
    let item = {
      Name: "Auto-Fix",
      Thumb: "thms/autofix.jpg",
      Effect: [
        { filter: "brightness", value: "0.14" },
        { filter: "contrast", value: "0.87" },
        { filter: "saturation", value: "0.01" },
      ],
      zoom: 1,
    };
    EditorStore.setCurrentEffect(item);
    window.editor.applyEffect(item);
  };

  const _onStraightenEnter = (event) => {
    window.editor.drawStraightenGrid();
  };

  const _onStraightenLeave = (event) => {
    window.editor.hideStraightenGrid();
  };

  const colorPicked = (colorCode) => {
    let _colorCode = parseInt(colorCode.replace("#", "0x"), 16);
    let baseObject = window.editor.getBaseObject();
    baseObject.clear();
    baseObject.beginFill(
      _checkboxState ? 0xffffff : _colorCode,
      _checkboxState ? 0.05 : 1
    );
    baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
    baseObject.endFill();
    baseObject.fillColor = _colorCode;
    setStoresData(getStateFromStores());
  };

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      EditorStore.removeChangeListener(_onChange);
      document.removeEventListener("keyup", onKeyUp);
      window.editor.cropCancel();
      // Indicate that the component has unmounted
      isMounted.current = false;
    };
  }, []);

  const handleSizeChange = (value) => {
    setImageSize(value);
    zoom(value);
  };

  useEffect(() => {
    if (
      EditorStore.getBackgroundEffect() &&
      EditorStore.getBackgroundEffect()?.Name
    ) {
      setFilterChecked(true);
      setBackgroundEffect(EditorStore.getBackgroundEffect().Name);
    }

    const adjustmentsValue = EditorStore.getImageSettings();
    if (
      adjustmentsValue.blue !== 1 ||
      adjustmentsValue.brightness !== 0 ||
      adjustmentsValue.contrast !== 0.8 ||
      adjustmentsValue.denoise !== 50 ||
      adjustmentsValue.green !== 1 ||
      adjustmentsValue.hue !== 0 ||
      adjustmentsValue.noise !== 0 ||
      adjustmentsValue.red !== 1 ||
      adjustmentsValue.saturation !== 0 ||
      adjustmentsValue.temperature !== 0
    ) {
      setAdjustmentsChecked(true);
    }
  }, [EditorStore.getSelectedObject()]);

  useEffect(() => {
    const element = document.getElementById("animated-element");
    element?.classList.add("animate");
    setTimeout(() => {
      element?.classList?.remove("animate");
    }, 200);
  }, [EditorStore.getSelectedObject()]);

  const MainMenuContent = useMemo(() => {
    if (storesData.mode === "crop") {
      return (
        <div className="cropMenus">
          <div className="cropTypeSelect" onClick={_onShowCropSelector}>
            <span>{storesData.cropType.Name}</span>
            <div className="arrow-right" />
          </div>
          <div className="space-between">
            <button className="btn mainCropCancel" onClick={_onCropCancel}>
              CANCEL
            </button>
            <button className="btn mainCropFinish" onClick={_onCropFinish}>
              APPLY
            </button>
          </div>
        </div>
      );
    } else if (storesData.mode !== "crop") {
      if (EditorStore.getDesignBackground()) {
        if (isAdjustSeeAll) {
          return (
            <div className="effectContainer scroll-container">
              <RangeSlider
                title="Brightness"
                min={-1}
                max={1}
                step={0.01}
                value={storesData.settings.brightness}
                handleChange={_onBrightnessChange}
                handleReset={_onBrightReset}
                isReset={storesData.settings.brightness !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Contrast"
                min={0}
                max={1.6}
                step={0.01}
                value={storesData.settings.contrast}
                handleChange={_onContrastChange}
                handleReset={_onContrastReset}
                isReset={storesData.settings.contrast !== 0.8}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0.8}
              />
              <RangeSlider
                title="Temperature"
                name="temperature-slider"
                min={-100}
                max={100}
                step={1}
                value={storesData.settings.temperature}
                handleChange={_onTemperatureChange}
                handleReset={_onTempReset}
                isReset={storesData.settings.temperature !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Tint"
                name="tint-slider"
                min={-180}
                max={180}
                step={1}
                value={storesData.settings.hue}
                handleChange={_onHueChange}
                handleReset={_onHueReset}
                isReset={storesData.settings.hue !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Saturation"
                min={-1}
                max={1}
                step={0.01}
                value={storesData.settings.saturation}
                handleChange={_onSaturationChange}
                handleReset={_onSatReset}
                isReset={storesData.settings.saturation !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Soften"
                min={0}
                max={50}
                step={1}
                value={storesData.settings.denoise}
                handleChange={_onDenoiseChange}
                handleReset={_onDenoiseReset}
                isReset={storesData.settings.denoise !== 50}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Noise"
                min={0}
                max={1}
                step={0.01}
                value={storesData.settings.noise}
                handleChange={_onNoiseChange}
                handleReset={_onNoiseReset}
                isReset={storesData.settings.noise !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Blue-Red"
                name="red-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.red}
                handleChange={_onRedChange}
                handleReset={_onRedReset}
                isReset={storesData.settings.red !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
              <RangeSlider
                title="Purple-Green"
                name="green-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.green}
                handleChange={_onGreenChange}
                handleReset={_onGreenReset}
                isReset={storesData.settings.green !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
              <RangeSlider
                title="Yellow-Blue"
                name="blue-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.blue}
                handleChange={_onBlueChange}
                handleReset={_onBlueReset}
                isReset={storesData.settings.blue !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
            </div>
          );
        } else {
          return (
            <div className="scroll-container">
              <div className="effectContainer">
                <div className="space-between range-slider">
                  <div
                    className="clickable semi-clickable-button"
                    onClick={_onDeleteBackground}
                  >
                    <DeleteIcon width={16} />
                    <span className="category-name">&nbsp;Delete</span>
                  </div>
                  <div
                    className="clickable semi-clickable-button"
                    onClick={handleReplaceBackground}
                  >
                    <ReplaceIcon width={16} />
                    <span className="category-name">&nbsp;Replace</span>
                  </div>
                </div>
                <RangeSlider
                  title="Size"
                  min={minSize}
                  max={maxSize}
                  step={0.01}
                  value={imageSize}
                  handleChange={handleSizeChange}
                  isReset={
                    EditorStore.getDesignBackground().initialZoom &&
                    parseFloat(imageSize).toFixed(2) !==
                      parseFloat(
                        EditorStore.getDesignBackground().initialZoom
                      ).toFixed(2)
                  }
                  handleReset={_onZoomReset}
                  isBox={false}
                  handleMouseEnter={() => {}}
                  handleMouseLeave={() => {}}
                  startPoint={minSize}
                />
                <RangeSlider
                  title="Straighten"
                  min={-0.785398}
                  max={0.785398}
                  step={0.001}
                  handleMouseEnter={_onStraightenEnter}
                  handleMouseLeave={_onStraightenLeave}
                  value={
                    storesData.settings.straighten
                      ? storesData.settings.straighten
                      : 0
                  }
                  handleChange={_onStraightenChange}
                  isReset={
                    storesData.settings.straighten &&
                    storesData.settings.straighten !== 0
                  }
                  handleReset={_onStraightenReset}
                  isBox={false}
                  startPoint={0}
                />
                <RangeSlider
                  title="Transparency"
                  min={0}
                  max={1}
                  step={0.01}
                  value={EditorStore.getDesignBackground().alpha}
                  handleChange={handleBackgroundOpacityChange}
                  isReset={EditorStore.getDesignBackground().alpha !== 1}
                  handleReset={handleOpacityReset}
                  isBox={true}
                  handleMouseEnter={() => {}}
                  handleMouseLeave={() => {}}
                  startPoint={0}
                />
                <div className="space-between mt-2">
                  <div
                    className="clickable semi-clickable-button"
                    onClick={handleRotate}
                  >
                    <RotateIcon width={16} />
                    <span className="category-name">&nbsp;&nbsp;Rotate</span>
                  </div>
                  <span>Flip</span>
                  <FlipHorizontalIcon
                    className="clickable semi-clickable-button"
                    onClick={handleFlipHorizontal}
                    width={16}
                  />
                  <FlipVerticalIcon
                    className="clickable semi-clickable-button"
                    onClick={handleFlipVertical}
                    width={16}
                    height={16}
                  />
                </div>
              </div>
              <div className="divider"></div>
              <div className="effectContainer">
                <div className="space-between">
                  <div className="space-between">
                    <div className="round-rect">
                      <img
                        src={FiltersIcon}
                        className="image-border-radius"
                        width={22}
                        alt="filters-icon"
                      />
                    </div>
                    <span className="text-control-title">Filters</span>
                  </div>
                  <div className="transparent">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={filterChecked}
                        onChange={(e) => handleChangeFilter(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {filterChecked && (
                  <Carousel
                    className="mt-2"
                    responsive={CarouselResponsive}
                    infinite={true}
                    autoPlay={false}
                    arrows={false}
                    customButtonGroup={<CarouselButtonGroup />}
                  >
                    {AssetStore.getEffectFolderDetails().map((item, idx) => {
                      let show = true;
                      if (EditorStore.getSelectedObject()) {
                        if (item.item.Effect) {
                          for (let x = 0; x < item.item.Effect.length; x++) {
                            if (item.item.Effect[x].filter.match(/blend/i))
                              show = false;
                          }
                        }
                      }
                      if (show) {
                        return (
                          <MenuItem
                            key={item.key}
                            item={item}
                            type="effect"
                            selectedEffect={
                              EditorStore.getBackgroundEffect()?.Name
                            }
                            setSelectedEffect={setBackgroundEffect}
                            setIntensity={setIntensity}
                          />
                        );
                      }
                    })}
                  </Carousel>
                )}
                {filterChecked && backgroundeffect && (
                  <div className="sub-menu">
                    <RangeSlider
                      name="sub-menu"
                      title="Intensity"
                      min={0}
                      max={1}
                      step={0.01}
                      value={intensity}
                      handleChange={handleChangeIntensity}
                      isReset={intensity !== 1}
                      handleReset={handleResetIntensity}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                  </div>
                )}
                <EffectItem
                  imgSrc={AdjustIcon}
                  title="Adjust"
                  isChecked={adjustmentsChecked}
                  handleChange={(e) => handleChangeAdjustment(e)}
                  seeAll={handleSeeAllAdjustment}
                />
                {adjustmentsChecked && (
                  <div className="sub-menu">
                    <RangeSlider
                      title="Brightness"
                      min={-1}
                      max={1}
                      step={0.01}
                      value={storesData.settings.brightness}
                      handleChange={_onBrightnessChange}
                      handleReset={_onBrightReset}
                      isReset={storesData.settings.brightness !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Contrast"
                      min={0}
                      max={1.6}
                      step={0.01}
                      value={storesData.settings.contrast}
                      handleChange={_onContrastChange}
                      handleReset={_onContrastReset}
                      isReset={storesData.settings.contrast !== 0.8}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0.8}
                    />
                    <RangeSlider
                      title="Temperature"
                      name="temperature-slider"
                      min={-100}
                      max={100}
                      step={1}
                      value={storesData.settings.temperature}
                      handleChange={_onTemperatureChange}
                      handleReset={_onTempReset}
                      isReset={storesData.settings.temperature !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Tint"
                      name="tint-slider"
                      min={-180}
                      max={180}
                      step={1}
                      value={storesData.settings.hue}
                      handleChange={_onHueChange}
                      handleReset={_onHueReset}
                      isReset={storesData.settings.hue !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Saturation"
                      min={-1}
                      max={1}
                      step={0.01}
                      value={storesData.settings.saturation}
                      handleChange={_onSaturationChange}
                      handleReset={_onSatReset}
                      isReset={storesData.settings.saturation !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                  </div>
                )}
                <EffectItem
                  imgSrc={FaceRetouchIcon}
                  title="Face Retouch"
                  isChecked={faceChecked}
                  handleChange={handleChangeFace}
                  seeAll={handleSeeAllFace}
                />
                {faceChecked && (
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    responsive={CarouselResponsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                  >
                    {storesData.faceTouchups.map((item, idx) => {
                      return <MenuItem key={idx} item={item} type="touchup" />;
                    })}
                  </Carousel>
                )}
              </div>
            </div>
          );
        }
      } else {
        if (isAdjustSeeAll) {
          return (
            <div className="effectContainer scroll-container">
              <RangeSlider
                title="Brightness"
                min={-1}
                max={1}
                step={0.01}
                value={storesData.settings.brightness}
                handleChange={_onBrightnessChange}
                handleReset={_onBrightReset}
                isReset={storesData.settings.brightness !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Contrast"
                min={0}
                max={1.6}
                step={0.01}
                value={storesData.settings.contrast}
                handleChange={_onContrastChange}
                handleReset={_onContrastReset}
                isReset={storesData.settings.contrast !== 0.8}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0.8}
              />
              <RangeSlider
                title="Temperature"
                name="temperature-slider"
                min={-100}
                max={100}
                step={1}
                value={storesData.settings.temperature}
                handleChange={_onTemperatureChange}
                handleReset={_onTempReset}
                isReset={storesData.settings.temperature !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Tint"
                name="tint-slider"
                min={-180}
                max={180}
                step={1}
                value={storesData.settings.hue}
                handleChange={_onHueChange}
                handleReset={_onHueReset}
                isReset={storesData.settings.hue !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Saturation"
                min={-1}
                max={1}
                step={0.01}
                value={storesData.settings.saturation}
                handleChange={_onSaturationChange}
                handleReset={_onSatReset}
                isReset={storesData.settings.saturation !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Soften"
                min={0}
                max={50}
                step={1}
                value={storesData.settings.denoise}
                handleChange={_onDenoiseChange}
                handleReset={_onDenoiseReset}
                isReset={storesData.settings.denoise !== 50}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Noise"
                min={0}
                max={1}
                step={0.01}
                value={storesData.settings.noise}
                handleChange={_onNoiseChange}
                handleReset={_onNoiseReset}
                isReset={storesData.settings.noise !== 0}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={0}
              />
              <RangeSlider
                title="Blue-Red"
                name="red-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.red}
                handleChange={_onRedChange}
                handleReset={_onRedReset}
                isReset={storesData.settings.red !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
              <RangeSlider
                title="Purple-Green"
                name="green-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.green}
                handleChange={_onGreenChange}
                handleReset={_onGreenReset}
                isReset={storesData.settings.green !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
              <RangeSlider
                title="Yellow-Blue"
                name="blue-slider"
                min={0}
                max={2}
                step={0.1}
                value={storesData.settings.blue}
                handleChange={_onBlueChange}
                handleReset={_onBlueReset}
                isReset={storesData.settings.blue !== 1}
                isBox={false}
                handleMouseEnter={() => {}}
                handleMouseLeave={() => {}}
                startPoint={1}
              />
            </div>
          );
        } else {
          return (
            <div className="scroll-container">
              <div className="effectContainer">
                <div className="d-flex mb-2">
                  <div
                    className="clickable semi-clickable-button"
                    onClick={_onCrop}
                  >
                    <CropIcon width={16} />
                    <span className="category-name">&nbsp;&nbsp;Crop</span>
                  </div>
                  <div
                    className="clickable semi-clickable-button"
                    onClick={_autoAdjust}
                  >
                    <svg height="16" viewBox="0 0 359.08 372.46">
                      <path
                        className="st0"
                        d="M306.88,81.85q3.6-8.3-.3-15.35a16.86,16.86,0,0,0-16.25-1.25l-41.3,14L215.33,53.5q-9-7.64-16.8-4.45-5.13,4.16-5.75,11.75v47.7l-35.6,25.1q-8.56,5.55-7.6,11.1.45,8.2,8.6,11.45l32.4,9.2L2.43,353.45q-5.31,6.36,1,16.55,9,5.05,15.3-.25L206.78,181.6,216,214q3.25,8.15,11.45,8.6,5.55.95,11.1-7.6l25.1-35.6h47.7q7.6-.6,11.75-5.75,3.2-7.85-4.45-16.8l-25.75-33.7,14-41.3m-35.6,33.65q-5.4,11.6,0,18.45l15,21.3H260.48q-11.35-.15-14.3,7l-14.3,21.6L222,152.05l-33.7-11.8,21.6-14.3q7.14-2.94,7-14.3V85.9l21.3,15q6.86,5.4,18.45,0l21.2-6.6-6.6,21.25m39.25,99.75,5.05,19L309.93,255l20-6.35,20,5.95-6.8-19.2,7.3-20.4-21,6.65-19-6.4m48.55-107.1-9.55-4.6-4.8-10.15-5.25,10.15-9.3,4.65,8.8,5.1,5.5,9.65,5-9.65,9.55-5.15M247.48,265.6l-8.25,15.85-14.5,7.25,13.75,8,8.65,15.1,7.85-15.1,14.9-8.05L255,281.45l-7.5-15.85M281.88,23,267,15.85,259.48,0l-8.25,15.85-14.5,7.25L250.48,31l8.65,15.1L267,31l14.9-8M99,51.3,81,60.2,98.08,70l10.65,18.6,9.7-18.6,18.35-10L118.43,51.3l-9.25-19.65Z"
                      />
                    </svg>
                    <span>&nbsp;&nbsp;Auto Adjust</span>
                  </div>
                </div>
                <RangeSlider
                  title="Straighten"
                  min={-0.785398}
                  max={0.785398}
                  step={0.001}
                  handleMouseEnter={_onStraightenEnter}
                  handleMouseLeave={_onStraightenLeave}
                  value={storesData.settings.straighten}
                  handleChange={_onStraightenChange}
                  isReset={storesData.settings.straighten !== 0}
                  handleReset={_onStraightenReset}
                  isBox={false}
                  startPoint={0}
                />
                <div className="space-between mt-2">
                  <div
                    className="clickable semi-clickable-button"
                    onClick={_onRotate}
                  >
                    <RotateIcon width={16} />
                    <span className="category-name">&nbsp;&nbsp;Rotate</span>
                  </div>
                  <span>Flip</span>
                  <FlipHorizontalIcon
                    className="clickable semi-clickable-button"
                    onClick={_onFlipHorizontal}
                    width={16}
                  />
                  <FlipVerticalIcon
                    className="clickable semi-clickable-button"
                    onClick={_onFlipVertical}
                    width={16}
                    height={20}
                  />
                </div>
              </div>
              <div className="divider"></div>

              <div className="effectContainer">
                <div className="space-between">
                  <div className="space-between">
                    <div className="round-rect">
                      <img
                        src={FiltersIcon}
                        className="image-border-radius"
                        width={22}
                        alt="filters-icon"
                      />
                    </div>
                    <span className="text-control-title">Filters</span>
                  </div>
                  <div className="transparent">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={filterChecked}
                        onChange={(e) => handleChangeFilter(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                {filterChecked && (
                  <Carousel
                    className="mt-2"
                    responsive={CarouselResponsive}
                    infinite={true}
                    autoPlay={false}
                    arrows={false}
                    customButtonGroup={<CarouselButtonGroup />}
                  >
                    {AssetStore.getEffectFolderDetails().map((item, idx) => {
                      let show = true;
                      if (EditorStore.getSelectedObject()) {
                        if (item.item.Effect) {
                          for (let x = 0; x < item.item.Effect.length; x++) {
                            if (item.item.Effect[x].filter.match(/blend/i))
                              show = false;
                          }
                        }
                      }
                      if (show) {
                        return (
                          <MenuItem
                            key={item.key}
                            item={item}
                            type="effect"
                            selectedEffect={backgroundeffect}
                            setSelectedEffect={setBackgroundEffect}
                            setIntensity={setIntensity}
                          />
                        );
                      }
                    })}
                  </Carousel>
                )}
                {filterChecked && backgroundeffect && (
                  <div className="sub-menu">
                    <RangeSlider
                      name="sub-menu"
                      title="Intensity"
                      min={0}
                      max={1}
                      step={0.01}
                      value={intensity}
                      handleChange={handleChangeIntensity}
                      isReset={intensity !== 1}
                      handleReset={handleResetIntensity}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                  </div>
                )}
                <EffectItem
                  imgSrc={AdjustIcon}
                  title="Adjust"
                  isChecked={adjustmentsChecked}
                  handleChange={(e) => handleChangeAdjustment(e)}
                  seeAll={handleSeeAllAdjustment}
                />
                {adjustmentsChecked && (
                  <div className="sub-menu">
                    <RangeSlider
                      title="Brightness"
                      min={-1}
                      max={1}
                      step={0.01}
                      value={storesData.settings.brightness}
                      handleChange={_onBrightnessChange}
                      handleReset={_onBrightReset}
                      isReset={storesData.settings.brightness !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Contrast"
                      min={0}
                      max={1.6}
                      step={0.01}
                      value={storesData.settings.contrast}
                      handleChange={_onContrastChange}
                      handleReset={_onContrastReset}
                      isReset={storesData.settings.contrast !== 0.8}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0.8}
                    />
                    <RangeSlider
                      title="Temperature"
                      name="temperature-slider"
                      min={-100}
                      max={100}
                      step={1}
                      value={storesData.settings.temperature}
                      handleChange={_onTemperatureChange}
                      handleReset={_onTempReset}
                      isReset={storesData.settings.temperature !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Tint"
                      name="tint-slider"
                      min={-180}
                      max={180}
                      step={1}
                      value={storesData.settings.hue}
                      handleChange={_onHueChange}
                      handleReset={_onHueReset}
                      isReset={storesData.settings.hue !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                    <RangeSlider
                      title="Saturation"
                      min={-1}
                      max={1}
                      step={0.01}
                      value={storesData.settings.saturation}
                      handleChange={_onSaturationChange}
                      handleReset={_onSatReset}
                      isReset={storesData.settings.saturation !== 0}
                      isBox={false}
                      handleMouseEnter={() => {}}
                      handleMouseLeave={() => {}}
                      startPoint={0}
                    />
                  </div>
                )}
                <EffectItem
                  imgSrc={FaceRetouchIcon}
                  title="Face Retouch"
                  isChecked={faceChecked}
                  handleChange={handleChangeFace}
                  seeAll={handleSeeAllFace}
                />
                {faceChecked && (
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    responsive={CarouselResponsive}
                    ssr={true}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    containerClass="carousel-container"
                  >
                    {storesData.faceTouchups.map((item, idx) => {
                      return <MenuItem key={idx} item={item} type="touchup" />;
                    })}
                  </Carousel>
                )}
              </div>
            </div>
          );
        }
      }
    }
  }, [
    storesData,
    isAdjustSeeAll,
    isFaceSeeAll,
    EditorStore.getDesignBackground(),
    imageSize,
    filterChecked,
    adjustmentsChecked,
    faceChecked,
    backgroundeffect,
    intensity,
  ]);

  const menuTitle = useMemo(() => {
    if (EditorStore.getEditorType() !== "collage") {
      if (EditorStore.getEditorType() === "design") {
        return isAdjustSeeAll || isFaceSeeAll ? (
          <div className="photos-nav">
            <div className="clickable d-flex" onClick={handleClickBack}>
              <svg viewBox="0 0 960 560" transform="rotate(90)" height={16}>
                <path
                  className="st0"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </svg>
              <span>{isAdjustSeeAll ? "Adjustments" : isFaceSeeAll}</span>
            </div>
          </div>
        ) : (
          <span>Background</span>
        );
      } else {
        return isAdjustSeeAll || isFaceSeeAll ? (
          <div className="photos-nav">
            <div className="clickable d-flex" onClick={handleClickBack}>
              <svg viewBox="0 0 960 560" transform="rotate(90)" height={16}>
                <path
                  className="a"
                  d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
                ></path>
              </svg>
              <span>{isAdjustSeeAll ? "Adjustments" : isFaceSeeAll}</span>
            </div>
          </div>
        ) : (
          <span>Basic Edits</span>
        );
      }
    } else {
      return <span>Edit Collage</span>;
    }
  }, [isAdjustSeeAll, isFaceSeeAll]);

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">{menuTitle}</div>
      {MainMenuContent}
    </div>
  );
};

export default MenuMain;
