import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const GraphicDesignMakerPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Graphic Design Maker | Online Graphic Design Tools"
        description="A fun and easy online graphic design maker. Online graphic design software that is fast and free to try."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/graphic_design_maker_app"
        keywords="Graphic Design Maker, Online graphic editor, online layout tool, design software"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Stress-free Online Graphic Design Maker</p>
          <p className="desc">
            One graphic design app, thousands of ways to create dreamy designs,
            no experience necessaries
          </p>
          <StartButton
            text="Start a Design →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/graphic_design_maker_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/graphic_design_maker_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">piZap Graphic Design Maker</span>
            <span className="desc">
              Kind of like a mullet, piZap’s graphic design maker offers
              something for party animals and professionals… and to those of you
              who consider yourself a combo of both—welcome to the club! No
              design experience necessary, piZap makes it easy to create
              professional logos or web graphics with transparent backgrounds.
              And it’s just as easy to create a tastefully sleazy invitation to
              your best friend’s bachelorette party.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Graphic Design Tool Overview</span>
            <span className="desc">
              You can create online designs using a desktop computer or download
              the graphic design app to your mobile device. No matter if you’re
              on a computer, tablet, or phone, there are thousands of ways to
              use piZap’s interactive tools. The online design process begins
              when you select a preset canvas size or create a custom size
              canvas. From there, you can add images along with customizable
              text boxes and hundreds of unique fonts. You can use piZap’s
              graphic design maker to create online designs on a desktop
              computer or download the app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/graphic_design_maker_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/graphic_design_maker_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Bring Your Visions to Life</span>
            <span className="desc">
              Graphic designers have lots of tricks and tools up their sleeves
              when it comes to creating online designs that get noticed. Anyone
              can use the same tricks and tools, but first they need education
              and experience. But… what if you could use some of the best
              editing and design tricks out there with your current knowledge
              base? You can thanks to piZap! From Thank You cards to custom
              logos, you can do it all with a smile on your face thanks to this
              frustration-free app!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Graphic Design Maker</span>
            <span className="desc">
              piZap simplifies professional-grade graphic design tools so they
              are accessible to everyone. Within minutes of using the Design
              Tool features you are going to feel like a pro—just be careful,
              the thrill is rather addicting. It’s so much fun to use an easy
              app that makes beautiful and unique graphic designs that it can
              kind of start to take over your life. But don’t let it because you
              still need time for piZap’s other equally-fun editing and collage
              tools… like the MEME maker.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/graphic_design_maker_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/graphic_design_maker_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              So Many Graphic Design Features, So Little Time
            </span>
            <span className="desc">
              piZap’s graphic design maker is jam-packed with features.
              Thousands of stickers, hundreds of text fonts; custom backgrounds
              and filters--you name it and piZap makes it easy to accomplish.
              It’s finally fast, easy, and fun to create custom online designs.
              But piZap is so much more than just a graphic design tool, it’s
              also packing a MEME maker, collage creator, as well as basic photo
              editing tools.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default GraphicDesignMakerPage;
