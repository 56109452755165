import EditorStore from "../stores/EditorStore.js";
import * as PIXI from "pixi.js";

var touchupRenderer;
var previewRenderer;
var imgObject;
var touchupStage;
var previewStage;
var painting = false;
var brush;
var previewBrush;
var renderTexture;
var drawPoints = [];
var finalPoints = [];
var imageToReveal;
var renderTextureSprite;
var touchupTool;
var initialZoom;
var panMode = false;
var dragOffsetX = 0;
var dragOffsetY = 0;
var panning = false;
var factor = 1;
var interval;

function animateTouchup() {
  if (touchupRenderer && touchupRenderer.view.style.display === "block") {
    requestAnimationFrame(animateTouchup);
    touchupRenderer.render(touchupStage);
  }
}

function animatePreview() {
  if (previewRenderer && previewRenderer.view.style.display === "block") {
    requestAnimationFrame(animatePreview);
    previewRenderer.render(previewStage);
  }
}
const Touchup = {
  init: function (texture, tool) {
    drawPoints = [];
    finalPoints = [];
    touchupTool = tool;
    const container = document.getElementById("mainContent");
    const mainCanvas = window.editor.getRenderer().view;
    mainCanvas.style.display = "none";
    const offsets = window.editor.getBaseOffsets();
    touchupRenderer = new PIXI.WebGLRenderer(offsets.width, offsets.height, {
      antialias: true,
      transparent: false,
      autoresize: true,
      resolution: window.editor.getStartingDevicePixelRatio(),
      preserveDrawingBuffer: true,
    });
    window.editor.setupCursors(touchupRenderer);

    touchupRenderer.view.style.height = offsets.height + "px";
    touchupRenderer.view.style.width = offsets.width + "px";
    touchupRenderer.view.style.top = mainCanvas.style.top;

    touchupRenderer.view.style.display = "block";
    container.appendChild(touchupRenderer.view);
    requestAnimationFrame(animateTouchup);

    imgObject = new PIXI.Sprite(texture);
    imgObject.width = texture.width;
    imgObject.height = texture.height;
    touchupStage = new PIXI.Container();
    touchupStage.addChild(imgObject);

    let filters = [];
    if (
      tool.item.brightness ||
      tool.item.contrast ||
      tool.item.hue ||
      tool.item.saturation
    ) {
      if (tool.item.brightness) {
        var colorMatrix = new PIXI.filters.ColorMatrixFilter();
        colorMatrix.brightness(parseFloat(tool.item.brightness), true);
        filters.push(colorMatrix);
      }
      if (tool.item.contrast) {
        var colorMatrix2 = new PIXI.filters.ColorMatrixFilter();
        colorMatrix2.contrast(parseFloat(tool.item.contrast), false);
        filters.push(colorMatrix2);
      }
      if (tool.item.saturation) {
        var colorMatrix3 = new PIXI.filters.ColorMatrixFilter();
        colorMatrix3.saturate(parseFloat(tool.item.saturation), true);
        filters.push(colorMatrix3);
      }
      if (tool.item.hue) {
        var colorMatrix4 = new PIXI.filters.ColorMatrixFilter();
        colorMatrix4.hue(tool.item.hue, true);
        filters.push(colorMatrix4);
      }
    }

    var t;
    if (tool.item.blendMode) {
      if (tool.item.blendMode === "overlay") {
        t = this.createColorOverlay(texture, tool.item.colors[0], touchupTool);
        imageToReveal = new PIXI.Sprite(t);
      } else if (tool.item.blendMode === "multiply") {
        t = this.createMultiply(texture, tool.item.colors[0], touchupTool);
        imageToReveal = new PIXI.Sprite(t);
      }
    } else {
      if (tool.item.filter && tool.item.filter === "blur") {
        var blurAmount = (texture.width / 2540) * 12;
        t = this.createBlur(texture, blurAmount, touchupTool);
        imageToReveal = new PIXI.Sprite(t);
      } else {
        if (filters.length > 0) {
          t = this.createFilter(texture, filters, touchupTool);
          imageToReveal = new PIXI.Sprite(t);
        } else {
          imageToReveal = new PIXI.Sprite(texture);
        }
      }
    }
    touchupStage.addChild(imageToReveal);
    imageToReveal.width = texture.width;
    imageToReveal.height = texture.height;
    if (tool.item.fade) {
      imageToReveal.alpha = 1 - tool.item.fade;
    }
    renderTexture = PIXI.RenderTexture.create(texture.width, texture.height);

    renderTextureSprite = new PIXI.Sprite(renderTexture);
    touchupStage.addChild(renderTextureSprite);

    var touchupParam =
      decodeURIComponent(
        (new RegExp("[?|&]touchupmask=([^&;]+?)(&|#|;|$)").exec(
          window.location.search
        ) || [null, ""])[1].replace(/\+/g, "%20")
      ) || null;
    if (touchupParam === null) imageToReveal.mask = renderTextureSprite;

    const oriback =
      EditorStore.getDesignBackground() || window.editor.getBaseObject();
    touchupStage.scale.set(oriback.width / texture.width);
    touchupStage.position.x = oriback.position.x - oriback.width / 2;
    touchupStage.position.y = oriback.position.y - oriback.height / 2;
    factor = Math.sqrt(texture.width / 2540);
    brush = new PIXI.Graphics();
    brush.filters = [
      new PIXI.filters.BlurFilter(
        (EditorStore.getTouchupBrushHardness() - 15) * factor,
        3,
        window.editor.getStartingDevicePixelRatio(),
        15
      ),
    ];

    touchupStage.on("pointerdown", this.onTouchupStart);
    touchupStage.on("pointermove", this.onTouchupMove);
    touchupStage.on("pointerup", this.onTouchupEnd);
    touchupStage.interactive = true;
    //        touchupStage.buttonMode = true;

    var bSize = parseInt(EditorStore.getTouchupBrushSize(), 10);
    var canvas = document.createElement("canvas");
    canvas.width = bSize;
    canvas.height = bSize;

    var ctx = canvas.getContext("2d");

    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.width / 2,
      bSize / 2,
      0,
      2 * Math.PI,
      false
    );
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#FFFFFF";
    ctx.stroke();

    var brushImage = canvas.toDataURL("image/png");
    touchupStage.defaultCursor = "url(" + brushImage + "), auto";
    imageToReveal.interactive = true;
    imageToReveal.cursor = "url(" + brushImage + "), auto";
    initialZoom = touchupStage.scale.x;

    //setup preview
    var previewContainer = document.getElementById("touchupSliderPreview");
    previewContainer.display = "block";

    previewRenderer = new PIXI.WebGLRenderer(120, 120, {
      antialias: true,
      transparent: true,
      autoresize: true,
      resolution: window.editor.getStartingDevicePixelRatio(),
      preserveDrawingBuffer: true,
    });
    previewRenderer.view.style.height = "120px";
    previewRenderer.view.style.width = "120px";
    previewRenderer.view.style.display = "block";
    previewContainer.appendChild(previewRenderer.view);
    previewStage = new PIXI.Container();
    previewBrush = new PIXI.Graphics();
    previewBrush.filters = [
      new PIXI.filters.BlurFilter(
        (EditorStore.getTouchupBrushHardness() - 15) * factor,
        3,
        window.editor.getStartingDevicePixelRatio(),
        15
      ),
    ];
    previewBrush.beginFill(0xffffff);
    previewBrush.drawCircle(
      60,
      60,
      parseInt(EditorStore.getTouchupBrushSize(), 10) / 2
    );
    previewBrush.endFill();
    previewStage.addChild(previewBrush);
    requestAnimationFrame(animatePreview);
    previewContainer.display = "none";
  },
  onTouchupStart: function (event) {
    if (!panMode) {
      painting = true;
      var brushSize = parseInt(EditorStore.getTouchupBrushSize(), 10);
      var currentX =
        (event.data.global.x - touchupStage.position.x + brushSize / 2) /
        touchupStage.scale.x;
      var currentY =
        (event.data.global.y - touchupStage.position.y + brushSize / 2) /
        touchupStage.scale.x;
      drawPoints.push(new PIXI.Point(currentX, currentY));

      brush.beginFill(0xffffff);
      brush.drawCircle(
        currentX,
        currentY,
        brushSize / touchupStage.scale.x / 2
      );
      brush.endFill();
      touchupRenderer.render(brush, renderTexture, true, null, false);
    } else {
      dragOffsetX = event.data.global.x;
      dragOffsetY = event.data.global.y;
      panning = true;
      touchupStage.defaultCursor = "-webkit-grabbing";
      imageToReveal.interactive = true;
      imageToReveal.cursor = "-webkit-grabbing";
    }
  },
  onTouchupMove: function (event) {
    if (panning && panMode) {
      var dx = event.data.global.x - dragOffsetX;
      var dy = event.data.global.y - dragOffsetY;

      if (
        touchupStage.position.x + dx <= 0 &&
        touchupStage.position.x + dx + touchupStage.width >=
          touchupRenderer.view.clientWidth
      ) {
        touchupStage.position.x += dx;
      }
      if (
        touchupStage.position.y + dy <= 0 &&
        touchupStage.position.y + dy + touchupStage.height >=
          touchupRenderer.view.clientHeight
      ) {
        touchupStage.position.y += dy;
      }
      dragOffsetX = event.data.global.x;
      dragOffsetY = event.data.global.y;
      return;
    }
    if (!painting) return;
    var dist = function (a, b) {
      if (a && b && a.x && b.x) {
        var x = a.x - b.x;
        var y = a.y - b.y;
        return x * x + y * y;
      } else {
        return 0;
      }
    };

    var brushSize = parseInt(EditorStore.getTouchupBrushSize(), 10);
    var currentX =
      (event.data.global.x - touchupStage.position.x + brushSize / 2) /
      touchupStage.scale.x;
    var currentY =
      (event.data.global.y - touchupStage.position.y + brushSize / 2) /
      touchupStage.scale.x;

    var p = new PIXI.Point(currentX, currentY);
    var last = drawPoints[drawPoints.length - 1];
    var x, y;
    if (dist(p, last) > 2 && 1 === 0) {
      var m = (last.y - p.y) / (last.x - p.x);

      if (last.x <= p.x) {
        for (x = last.x + 1; x < p.x; x++) {
          y = m * (x - last.x) + last.y;
          drawPoints.push(new PIXI.Point(x, y));
        }
      } else {
        for (x = last.x - 1; x > p.x; x--) {
          y = m * (x - last.x) + last.y;
          drawPoints.push(new PIXI.Point(x, y));
        }
      }
      if (last.y <= p.y) {
        if (m === -Infinity) {
          for (y = last.y + 1; y < p.y; y++) {
            drawPoints.push(new PIXI.Point(p.x, y));
          }
        } else {
          for (y = last.y + 1; y < p.y; y++) {
            x = (y - last.y + last.x * m) / m;
            drawPoints.push(new PIXI.Point(x, y));
          }
        }
      } else {
        if (m === Infinity) {
          for (y = last.y - 1; y > p.y; y--) {
            drawPoints.push(new PIXI.Point(p.x, y));
          }
        } else {
          for (y = last.y - 1; y > p.y; y--) {
            x = (y - last.y + last.x * m) / m;
            drawPoints.push(new PIXI.Point(x, y));
          }
        }
      }
    }

    drawPoints.push(new PIXI.Point(currentX, currentY));
    this.lastDragX = currentX;
    this.lastDragY = currentY;
    var bSize = EditorStore.getTouchupBrushSize();
    brush.clear();
    brush.beginFill(0xffffff);

    for (x = 0; x < finalPoints.length; x++) {
      for (y = 0; y < finalPoints[x].points.length; y++) {
        brush.drawCircle(
          finalPoints[x].points[y].x,
          finalPoints[x].points[y].y,
          finalPoints[x].size / finalPoints[x].zoom / 2
        );
      }
    }

    for (x = 0; x < drawPoints.length; x++) {
      brush.drawCircle(
        drawPoints[x].x,
        drawPoints[x].y,
        bSize / touchupStage.scale.x / 2
      );
    }
    brush.endFill();
    touchupRenderer.render(brush, renderTexture, true, null, false);
  },
  onTouchupEnd: function (event) {
    if (panMode) {
      touchupStage.defaultCursor = "-webkit-grab";
      imageToReveal.interactive = true;
      imageToReveal.cursor = "-webkit-grab";
    }
    var dp = drawPoints;
    Touchup.CommandManager.execute({
      execute: function () {
        painting = false;
        finalPoints.push({
          size: EditorStore.getTouchupBrushSize(),
          points: dp,
          zoom: touchupStage.scale.x,
        });
        drawPoints = [];
        panning = false;
        brush.clear();
        brush.beginFill(0xffffff);
        for (var x = 0; x < finalPoints.length; x++) {
          for (var y = 0; y < finalPoints[x].points.length; y++) {
            brush.drawCircle(
              finalPoints[x].points[y].x,
              finalPoints[x].points[y].y,
              finalPoints[x].size / finalPoints[x].zoom / 2
            );
          }
        }
        brush.endFill();
        touchupRenderer.render(brush, renderTexture, true, null, false);
      },
      unexecute: function () {
        finalPoints.pop();
        brush.clear();
        brush.beginFill(0xffffff);
        for (var x = 0; x < finalPoints.length; x++) {
          for (var y = 0; y < finalPoints[x].points.length; y++) {
            brush.drawCircle(
              finalPoints[x].points[y].x,
              finalPoints[x].points[y].y,
              finalPoints[x].size / finalPoints[x].zoom / 2
            );
          }
        }
        brush.endFill();
        touchupRenderer.render(brush, renderTexture, true, null, false);
      },
    });
  },
  setBrushSize: function (size) {
    var canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;

    var ctx = canvas.getContext("2d");

    ctx.beginPath();
    ctx.arc(
      canvas.width / 2,
      canvas.width / 2,
      size / 2,
      0,
      2 * Math.PI,
      false
    );
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#FFFFFF";
    ctx.stroke();

    var brushImage = canvas.toDataURL("image/png");
    touchupStage.defaultCursor = "url(" + brushImage + "), auto";
    imageToReveal.interactive = true;
    imageToReveal.cursor = "url(" + brushImage + "), auto";
    panMode = false;
    EditorStore.emitChange();
    previewBrush.clear();
    previewBrush.beginFill(0xffffff);
    previewBrush.drawCircle(
      60,
      60,
      parseInt(EditorStore.getTouchupBrushSize(), 10) / 2
    );
    previewBrush.endFill();
    this.showPreview();
  },
  setFade: function (size) {
    imageToReveal.alpha = 1 - size;
    this.setPanMode(false);
    EditorStore.emitChange();
    previewBrush.alpha = 1 - size;
    this.showPreview();
  },
  setHardness: function (size) {
    brush.filters = null;
    if (EditorStore.getTouchupBrushHardness() < 14.8) {
      brush.filters = [
        new PIXI.filters.BlurFilter(
          (EditorStore.getTouchupBrushHardness() - 15) * factor,
          3,
          window.editor.getStartingDevicePixelRatio(),
          15
        ),
      ];
    }
    brush.clear();
    brush.beginFill(0xffffff);
    for (var x = 0; x < finalPoints.length; x++) {
      for (var y = 0; y < finalPoints[x].points.length; y++) {
        brush.drawCircle(
          finalPoints[x].points[y].x,
          finalPoints[x].points[y].y,
          finalPoints[x].size / finalPoints[x].zoom / 2
        );
      }
    }
    brush.endFill();
    touchupRenderer.render(brush, renderTexture, true, null, false);
    this.setPanMode(false);
    EditorStore.emitChange();
    previewBrush.filters = null;
    if (EditorStore.getTouchupBrushHardness() < 14.8) {
      previewBrush.filters = [
        new PIXI.filters.BlurFilter(
          (EditorStore.getTouchupBrushHardness() - 15) * factor,
          3,
          window.editor.getStartingDevicePixelRatio(),
          15
        ),
      ];
    }
    previewBrush.clear();
    previewBrush.beginFill(0xffffff);
    previewBrush.drawCircle(
      60,
      60,
      parseInt(EditorStore.getTouchupBrushSize(), 10) / 2
    );
    previewBrush.endFill();
    this.showPreview();
  },
  onCancel: function () {
    EditorStore.setTouchupTool(null);
    var mainCanvas = window.editor.getRenderer().view;
    mainCanvas.style.display = "block";
    panMode = false;
    panning = false;
    window.editor.getRenderer().render(window.editor.getStage());
    if (touchupRenderer) {
      try {
        touchupRenderer.destroy(true);
      } catch (e) {}
      touchupStage.removeChildren();
      touchupStage.destroy();
      touchupStage = null;
      imageToReveal.destroy();
      imageToReveal = null;
      imgObject = null;
      touchupRenderer = null;
    }
    if (previewRenderer) {
      try {
        previewRenderer.destroy(true);
      } catch (e) {}
      previewStage.removeChildren();
      previewStage.destroy();
      previewStage = null;
      previewBrush.destroy();
      previewBrush = null;
      previewRenderer = null;
    }

    // if (spareRenderer) {
    //     try {
    //         spareRenderer.destroy(true);
    //     } catch (e) {

    //     }
    //     spareRenderer = null;
    // }

    window.editor.stickerSelector.updateSelector();
  },
  onSave: function () {
    touchupRenderer.resize(
      Math.floor(
        imgObject.texture.width / window.editor.getStartingDevicePixelRatio()
      ),
      Math.floor(
        imgObject.texture.height / window.editor.getStartingDevicePixelRatio()
      )
    );
    touchupStage.scale.set(1 / window.editor.getStartingDevicePixelRatio());
    touchupStage.position.x = 0;
    touchupStage.position.y = 0;
    brush.filters = null;
    brush.filters = [
      new PIXI.filters.BlurFilter(
        EditorStore.getTouchupBrushHardness() - 15,
        3,
        window.editor.getStartingDevicePixelRatio(),
        15
      ),
    ];
    brush.clear();
    brush.beginFill(0xffffff);
    for (var x = 0; x < finalPoints.length; x++) {
      for (var y = 0; y < finalPoints[x].points.length; y++) {
        brush.drawCircle(
          finalPoints[x].points[y].x,
          finalPoints[x].points[y].y,
          finalPoints[x].size / finalPoints[x].zoom / 2
        );
      }
    }
    brush.endFill();
    touchupRenderer.render(brush, renderTexture, true, null, false);

    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || window.safari.pushNotification);
    if (isSafari) {
      touchupStage.scale.y *= -1;
      touchupStage.position.y = 0 - touchupStage.height;
    }
    touchupRenderer.render(touchupStage);
    const img = touchupRenderer.view.toDataURL("image/jpeg");
    window.editor.changePhoto(img, true, true);
    this.onCancel();
  },
  createColorOverlay: function (texture, color, tool) {
    var c = parseInt(color.replace("#", "0x"), 16);
    var spareRenderer = new PIXI.CanvasRenderer(texture.width, texture.height, {
      antialias: true,
      transparent: false,
      resolution: 1,
      preserveDrawingBuffer: true,
    });

    var i = new PIXI.Sprite(texture);
    i.width = texture.width;
    i.height = texture.height;
    var s = new PIXI.Container();
    s.addChild(i);
    var g = new PIXI.Graphics();
    g.beginFill(c);
    g.drawRect(0, 0, texture.width, texture.height);
    g.endFill();
    g.blendMode = PIXI.BLEND_MODES.OVERLAY;
    if (tool.item.alpha) g.alpha = tool.item.alpha;
    s.addChild(g);
    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || window.safari.pushNotification);
    if (isSafari) {
      s.scale.y *= -1;
      s.position.y = 0 - s.height;
    }

    spareRenderer.render(s);
    var rtn = PIXI.Texture.fromCanvas(
      spareRenderer.view,
      PIXI.SCALE_MODES.LINEAR
    );
    spareRenderer.destroy(true);
    return rtn;
  },
  createMultiply: function (texture, color, tool) {
    var c = parseInt(color.replace("#", "0x"), 16);
    var spareRenderer = new PIXI.CanvasRenderer(texture.width, texture.height, {
      antialias: true,
      transparent: false,
      resolution: 1,
      preserveDrawingBuffer: true,
    });

    var i = new PIXI.Sprite(texture);
    i.width = texture.width;
    i.height = texture.height;
    var s = new PIXI.Container();
    s.addChild(i);
    var g = new PIXI.Graphics();
    g.beginFill(c);
    g.drawRect(0, 0, texture.width, texture.height);
    g.endFill();
    g.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    if (tool.item.alpha) g.alpha = tool.item.alpha;
    s.addChild(g);
    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || window.safari.pushNotification);
    if (isSafari) {
      s.scale.y *= -1;
      s.position.y = 0 - s.height;
    }

    spareRenderer.render(s);
    var rtn = PIXI.Texture.fromCanvas(
      spareRenderer.view,
      PIXI.SCALE_MODES.LINEAR
    );
    spareRenderer.destroy(true);
    return rtn;
  },
  createBlur: function (texture, blur, tool) {
    var spareWebglRenderer = new PIXI.WebGLRenderer(
      texture.width,
      texture.height,
      {
        antialias: true,
        transparent: false,
        resolution: 1,
        preserveDrawingBuffer: true,
      }
    );
    var i = new PIXI.Sprite(texture);
    i.width = texture.width;
    i.height = texture.height;
    var s = new PIXI.Container();
    i.filters = [new PIXI.filters.BlurFilter(blur, 3, 1, 15)];
    s.addChild(i);

    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || window.safari.pushNotification);
    if (isSafari) {
      s.scale.y *= -1;
      s.position.y = 0 - s.height;
    }

    spareWebglRenderer.render(s);
    spareWebglRenderer.render(s);
    var rtn = PIXI.Texture.fromCanvas(
      spareWebglRenderer.view,
      PIXI.SCALE_MODES.LINEAR
    );
    var interval = setInterval(function () {
      spareWebglRenderer.destroy(true);
      spareWebglRenderer = null;
      clearInterval(interval);
    }, 1000);
    return rtn;
  },
  createFilter: function (texture, filters, tool) {
    var spareWebglRenderer = new PIXI.WebGLRenderer(
      texture.width,
      texture.height,
      {
        antialias: true,
        transparent: false,
        resolution: 1,
        preserveDrawingBuffer: true,
      }
    );

    var i = new PIXI.Sprite(texture);
    i.width = texture.width;
    i.height = texture.height;
    i.filters = filters;
    var s = new PIXI.Container();
    s.addChild(i);

    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window["safari"] || window.safari.pushNotification);
    if (isSafari) {
      s.scale.y *= -1;
      s.position.y = 0 - s.height;
    }

    spareWebglRenderer.render(s);
    spareWebglRenderer.render(s);
    var rtn = PIXI.Texture.fromCanvas(
      spareWebglRenderer.view,
      PIXI.SCALE_MODES.LINEAR
    );
    var interval = setInterval(function () {
      spareWebglRenderer.destroy(true);
      spareWebglRenderer = null;
      clearInterval(interval);
    }, 1000);
    return rtn;
  },

  updateColor: function (color) {
    var texture = imgObject.texture;
    var oldImage = imageToReveal;
    touchupStage && touchupStage.removeChild(oldImage);
    imageToReveal = null;
    oldImage = null;
    var t;
    if (touchupTool.item.blendMode) {
      if (touchupTool.item.blendMode === "overlay") {
        t = this.createColorOverlay(texture, color, touchupTool);
        imageToReveal = new PIXI.Sprite(t);
      } else if (touchupTool.item.blendMode === "multiply") {
        t = this.createMultiply(texture, color, touchupTool);
        imageToReveal = new PIXI.Sprite(t);
      }
    } else {
      if (touchupTool.item.filter && touchupTool.item.filter === "blur") {
        var blurAmount = (texture.width / 2540) * 12;
        t = this.createBlur(texture, blurAmount, touchupTool);
        imageToReveal = new PIXI.Sprite(t);
      } else {
        imageToReveal = new PIXI.Sprite(texture);
      }
    }
    touchupStage && touchupStage.addChild(imageToReveal);
    imageToReveal.width = imgObject.width;
    imageToReveal.height = imgObject.height;
    var touchupParam =
      decodeURIComponent(
        (new RegExp("[?|&]touchupmask=([^&;]+?)(&|#|;|$)").exec(
          window.location.search
        ) || [null, ""])[1].replace(/\+/g, "%20")
      ) || null;
    if (touchupParam === null) imageToReveal.mask = renderTextureSprite;

    if (touchupTool.item.filter) {
      if (touchupTool.item.filter === "blur") {
        imageToReveal.filters = [
          new PIXI.filters.BlurFilter(
            EditorStore.getTouchupBrushHardness(),
            3,
            window.editor.getStartingDevicePixelRatio(),
            5
          ),
        ];
      }
    }
    this.setBrushSize(EditorStore.getTouchupBrushSize());
  },
  showPreview: function () {
    var previewContainer = document.getElementById("touchupSliderPreview");
    previewContainer.style.display = "block";
    clearTimeout(interval);
    interval = setInterval(function () {
      var p = document.getElementById("touchupSliderPreview");
      p.style.display = "none";
    }, 2000);
  },
  getTouchupTool: function () {
    return touchupTool;
  },
  getStage: function () {
    return touchupStage;
  },
  getRenderer: function () {
    return touchupRenderer;
  },
  getStageInitialZoom: function () {
    return initialZoom;
  },
  setPanMode: function (mode) {
    panMode = mode;
    if (panMode) {
      touchupStage.defaultCursor = "-webkit-grab";
      imageToReveal.interactive = true;
      imageToReveal.cursor = "-webkit-grab";
    } else {
      this.setBrushSize(EditorStore.getTouchupBrushSize());
    }
    EditorStore.emitChange();
  },
  getPanMode: function () {
    return panMode;
  },
  compare: function () {
    imageToReveal.renderable = false;
  },
  compareEnd: function () {
    imageToReveal.renderable = true;
  },
  CommandManager: {
    execute: function (cmd) {
      cmd.execute();
      EditorStore.getTouchupExecuted().push(cmd);
      EditorStore.emitChange();
    },
    filter: function (cmd) {
      cmd.execute();
      EditorStore.emitChange();
    },
    undo: function () {
      var cmd1 = EditorStore.getTouchupExecuted().pop();
      if (cmd1 !== undefined) {
        if (cmd1.unexecute !== undefined) {
          cmd1.unexecute();
        }
        EditorStore.getTouchupUnexecuted().push(cmd1);
        EditorStore.emitChange();
      }
    },
    redo: function () {
      var cmd2 = EditorStore.getTouchupUnexecuted().pop();

      if (cmd2 !== undefined) {
        cmd2.execute();
        EditorStore.getTouchupExecuted().push(cmd2);
      }
      EditorStore.emitChange();
    },
  },
};

export default Touchup;
