import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const TeethWhiteningPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Teeth Whitening Photo App | Whiten Teeth in Photos"
        description="Use our teeth whitening effect on our online photo editor to easily whiten your teeth in photos."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/teeth_whitening_photo_app"
        keywords="whitenig teeth photo app, teeth whiter, whiten"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Teeth Whitening Photo App</p>
          <p className="desc">
            A whiter and brighter smile is only a couple clicks away
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/teeth_whitening_photo_editor_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/teeth_whitening_photo_editor_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Teeth Whitening App</span>
            <span className="desc">
              Zip-zap your teeth into pearly white perfection using piZap’s
              teeth whitening app. So much more than just a tool to brighten
              your smile, piZap offers a wide variety of photo enhancing tools.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">What is a Teeth Whitening Tool?</span>
            <span className="desc">
              Zoom in on your smile, point and click; then flip through
              different shades of pearly white to find the ideal look for you.
              The teeth whitening photo editor lets you custom select how much
              or how little you want to brighten your teeth. Whiten your teeth
              by one tone or several tones. piZap is much more than just a teeth
              whitening app and offers other touch up tools to play with. Change
              your eye color, removal blemishes and wrinkles + so much more.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/teeth_whitening_photo_editor_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/teeth_whitening_photo_editor_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">The Easy to Use Teeth Whitening App</span>
            <span className="desc">
              You’re seconds away from a whiter and brighter smile thanks to
              piZap’s easy to use teeth whitening photo editor. It’s not
              uncommon for people to have their teeth professionally whitened
              and then still use teeth whitening apps. Sometimes teeth just
              don’t look white enough in pictures, even if they’re pretty-dang
              white in real life. Luckily, piZap’s carefully designed app is
              easy to use so that you don’t need advanced editing skills to make
              your teeth look several shades lighter, naturally of course.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Teeth Whitening App</span>
            <span className="desc">
              Going to the dentist, using whitening strips, brushing your teeth
              after every meal…none of these things are fun. Luckily, using
              piZap’s teeth whitening app IS fun. For one, it’s incredibly fast.
              You’ll literally have whiter teeth in seconds. Plus, you have
              numerous other photo touch up tools at your fingertips. Change
              your eye color, remove wrinkles, or switch over to editing tools
              and change filters or add stickers to your photos. There’s so much
              fun to be had! Did we mention it’s easy to save and share your
              photos right in the app?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/teeth_whitening_photo_editor_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/teeth_whitening_photo_editor_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Teeth Whitening Photo Editor & More</span>
            <span className="desc">
              piZap is a superstar teeth whitening app, but it’s so much more
              than just that. Packed with features on features, you could spend
              your whole day fixing flaws, changing filters, creating MEMEs, and
              adding stickers to your favorite pics. Get ready to take your
              teeth and photos to the next level. Find out why so many people
              consider piZap the best teeth whitening photo editor.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default TeethWhiteningPage;
