import React from "react";

const ConfirmDialog = (props) => {
  const { confirmMessage, cancelFunc, acceptFunc, acceptLabel } = props;
  return (
    <div className="confirm-dialog">
      <div className="confirm-dialog-container">
        <div className="confirm-dialog-title">
          <span>{confirmMessage}</span>
          <div className="justify-center">
            <button className="btn confirmAcceptBtn" onClick={acceptFunc}>
              {acceptLabel}
            </button>
            <button
              className="btn confirmCancelBtn my-btn"
              onClick={cancelFunc}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
