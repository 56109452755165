import React, { useState, useEffect, useMemo } from "react";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserActionCreators from "../actions/UserActionCreators";
import { ReactComponent as UnlockImage } from "../images/editor/unlock_icon.svg";
import { ReactComponent as DeleteIcon } from "../images/editor/delete_icon.svg";
import { ReactComponent as BackwardIcon } from "../images/editor/send_backward_icon.svg";
import { ReactComponent as BackIcon } from "../images/editor/send_to_back_icon.svg";
import { ReactComponent as ForwardIcon } from "../images/editor/bring_forward_icon.svg";
import { ReactComponent as FrontIcon } from "../images/editor/bring_to_front.svg";
import { ReactComponent as DuplicateIcon } from "../images/editor/duplicate_icon.svg";
import { ReactComponent as AddImageIcon } from "../images/editor/photo_icon.svg";
import LeftMenuStore from "../stores/LeftMenuStore";
import AssetStore from "../stores/AssetStore";

function getPosition(e) {
  let posx = 0;
  let posy = 0;

  if (!e) e = window.event;

  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    posy =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }

  return {
    x: posx,
    y: posy,
  };
}

function positionMenu(e) {
  let menu = document.querySelector("#context-menu");
  let clickCoords = getPosition(e);
  let clickCoordsX = clickCoords.x;
  let clickCoordsY = clickCoords.y;

  let menuWidth = menu.offsetWidth + 4;
  let menuHeight = menu.offsetHeight + 4;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  if (windowWidth - clickCoordsX < menuWidth) {
    menu.style.left = windowWidth - menuWidth + "px";
  } else {
    menu.style.left = clickCoordsX + "px";
  }

  if (windowHeight - clickCoordsY < menuHeight) {
    menu.style.top = windowHeight - menuHeight + "px";
  } else {
    menu.style.top = clickCoordsY + "px";
  }
}

function getStateFromStores() {
  return {
    selectedObject: EditorStore.getSelectedObject(),
    showContextMenu: EditorStore.getContextMenu(),
  };
}

const ContextMenu = () => {
  const [data, setData] = useState(getStateFromStores());
  const [addBackClass, setAddBackClass] = useState("");
  const [addFrontClass, setAddFrontClass] = useState("");

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    UserStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    if (
      data.selectedObject &&
      data.selectedObject.parent &&
      data.selectedObject.parent.getChildIndex(data.selectedObject) === 0
    ) {
      setAddBackClass("disabled-menu");
    } else setAddBackClass("");
    const minCount = UserStore.isPro() ? 1 : 2;
    if (
      data.selectedObject &&
      data.selectedObject.parent &&
      data.selectedObject.parent.children.length - minCount ===
        data.selectedObject.parent.getChildIndex(data.selectedObject)
    ) {
      setAddFrontClass("disabled-menu");
    } else {
      setAddFrontClass("");
    }
  }, [data?.selectedObject?.parent?.getChildIndex(data.selectedObject)]);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    const mainContent = document.getElementById("mainContent");
    mainContent.addEventListener("contextmenu", function (e) {
      if (
        EditorStore.getSelectedObject() != null &&
        !EditorStore.getSelectedObject().CollageBackground &&
        !EditorStore.getSelectedObject().DesignBackground &&
        !EditorStore.getSelectedObject()?.locked
      ) {
        e.preventDefault();
        EditorActionCreators.ShowContextMenu(true);
        positionMenu(e);
      } else {
        EditorActionCreators.ShowContextMenu(false);
      }
    });
  }, []);

  const _onLocked = (event) => {
    event.stopPropagation();
    EditorActionCreators.ShowContextMenu(false);
    EditorStore.getSelectedObject().locked = true;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const handleClickAddPhoto = (e) => {
    e.preventDefault();
    const so = EditorStore.getSelectedObject();
    EditorActionCreators.ShowContextMenu(false);
    window.editor.createStickerHotspot(so);
    EditorStore.emitChange();
    EditorActionCreators.ShowFilePicker(true, "collage");
    window.editor.setCollageSpotSelected(
      window.editor.getCollageSpotSelected()
    );
    window.editor.setSelectedObject(null);
  };

  const _onSave = () => {
    if (UserStore.getUser() && UserStore.getUser().UserName) {
      window.editor.setSelectedObject(null);
      EditorActionCreators.ShowSaveQuality(true);
    } else {
      UserActionCreators.showLogin(true, "showProGetSave", "save");
    }
  };

  const _onMoveFront = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveFront(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMoveForward = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveForward(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMoveBack = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveBack(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onMoveBackward = (event) => {
    event.stopPropagation();
    EditorActionCreators.MoveBackward(data.selectedObject);
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onDelete = (event) => {
    EditorActionCreators.RemoveSticker(EditorStore.getSelectedObject());
    if (LeftMenuStore.get() === "text_props") {
      LeftMenuStore.setMenuSelected("text");
    } else if (LeftMenuStore.get() === "edit_graphic") {
      AssetStore.setRememberPhotos(true);
      LeftMenuStore.setMenuSelected("photos");
    } else if (LeftMenuStore.get() === "edit_graphic_svg") {
      AssetStore.setRememberStickers(true);
      LeftMenuStore.setMenuSelected("sticker");
    }
    window.editor.setSelectedObject(null);
    window.editor.stickerSelector.cancelSelector();
    EditorActionCreators.ShowContextMenu(false);
    window.editor.autoSave();
  };

  const _onCopy = (event) => {
    EditorActionCreators.CopySticker(EditorStore.getSelectedObject());
    window.editor.autoSave();
  };

  const addPhoto = useMemo(() => {
    if (data.selectedObject?.fillColor !== undefined) {
      return (
        <div
          className="prop-group clickable"
          onClick={(e) => handleClickAddPhoto(e)}
          title="Add Photo"
        >
          <AddImageIcon className="add-background-image" width={18} />{" "}
          <span>Add photo</span>
        </div>
      );
    } else {
      return null;
    }
  }, [data.selectedObject]);

  return (
    <nav
      id="context-menu"
      className={`${
        data.showContextMenu
          ? "context-menu context-menu--active"
          : "context-menu"
      }`}
    >
      <div className="prop-group clickable" onClick={_onDelete} title="Remove">
        <DeleteIcon width={18} /> <span>Delete</span>
      </div>
      <div className="prop-group clickable" onClick={_onCopy} title="Duplicate">
        <DuplicateIcon width={18} /> <span>Duplicate</span>
      </div>
      <div className="prop-group clickable" onClick={_onLocked} title="Lock">
        <UnlockImage width={18} /> <span>Lock</span>
      </div>
      {addPhoto}
      <div
        className={`prop-group clickable ${addBackClass}`}
        onClick={_onMoveBackward}
        title="Send backward"
      >
        <BackwardIcon width={18} />
        <span className="context-title">Send backward</span>
      </div>
      <div
        className={`prop-group clickable ${addBackClass}`}
        onClick={_onMoveBack}
        title="Send to back"
      >
        <BackIcon width={18} />
        <span className="context-title">Send to back</span>
      </div>
      <div
        className={`prop-group clickable ${addFrontClass}`}
        onClick={_onMoveForward}
        title="Bring forward"
      >
        <ForwardIcon width={18} />
        <span className="context-title">Bring forward</span>
      </div>
      <div
        className={`prop-group clickable ${addFrontClass}`}
        onClick={_onMoveFront}
        title="Bring to front"
      >
        <FrontIcon width={18} />
        <span className="context-title">Bring to front</span>
      </div>
    </nav>
  );
};

export default ContextMenu;
