import React, { useEffect, useMemo, useState, useRef } from "react";
import UserStore from "../stores/UserStore";
import UserActionCreators from "../actions/UserActionCreators";
import JSONUtils from "../utils/JSONUtils";
import EmailIcon from "../images/editor/email_icon.png";
import FacebookIcon from "../images/editor/facebook_logo.png";
import LinkedinIcon from "../images/editor/linkedin_icon.png";
import PinterestIcon from "../images/editor/pinterest.png";
import WhatsappIcon from "../images/editor/whatsapp.png";
import TwitterIcon from "../images/editor/twitter_icon.png";
import CheckIcon from "../images/editor/check.png";
import EditorStore from "../stores/EditorStore";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const Share = () => {
  const [cloudUrl, setCloudUrl] = useState("");
  const [pizapProgress, setPizapProgress] = useState(0);
  const [pizapSaved, setPizapSaved] = useState(false);
  const [copyShareLinkClass, setCopyShareLinkClass] = useState("");
  const [copyImageLinkClass, setCopyImageLinkClass] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const isMounted = useRef(true);

  const onClose = () => {
    UserActionCreators.showShareDialog(false);
  };

  useEffect(() => {
    if (isMounted.current) {
      pizapSave();
    }
    return () => {
      // Indicate that the component has unmounted
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!EditorStore.getSavedUrl()) {
      window.editor.autoSave();
      setCloudUrl(EditorStore.getSavedUrl());
    } else {
      setCloudUrl(EditorStore.getSavedUrl());
    }
  }, [EditorStore.getSavedUrl()]);

  const pizapSave = (callback) => {
    window.editor.setSelectedObject(null);
    if (!pizapSaved) {
      JSONUtils.getJSON(
        "https://api.pizap.com/image/post?access_token=" +
          encodeURIComponent(UserStore.getAccessToken()),
        "GET",
        null,
        function (response) {
          window.editor.saveToBlob(0, 0, function (blob) {
            let fd = new FormData();
            const ckey = response.ImagePrefix + ".jpg";
            setImageUrl(`https://imagesw.pizap.com/${ckey}`);
            fd.append("x-amz-storage-class", "REDUCED_REDUNDANCY");
            fd.append("policy", response.Policy);
            fd.append("signature", response.Signature);
            fd.append("acl", "public-read");
            fd.append("key", ckey);
            fd.append("AWSAccessKeyId", response.AccessKey);
            fd.append("Content-Type", "image/jpeg");
            fd.append("success_action_status", "201");
            fd.append("file", blob, ckey);
            let xhr = new XMLHttpRequest();
            xhr.upload.addEventListener(
              "progress",
              function (evt) {
                if (evt.lengthComputable) {
                  setPizapProgress(evt.loaded / evt.total / 2);
                }
              },
              false
            );
            xhr.addEventListener(
              "load",
              function (evt) {
                let caption = "pizap";
                JSONUtils.getJSON(
                  "https://api.pizap.com/image/post?publicvalue=no&posttofacebook=no&imageNamePreset=" +
                    ckey +
                    "&title=" +
                    encodeURIComponent(caption) +
                    "&posttotwitter=no&posttotumblr=no&access_token=" +
                    encodeURIComponent(UserStore.getAccessToken()),
                  "GET",
                  null,
                  function (response) {
                    setPizapProgress(100);
                    if (callback) callback();
                  }
                );
              },
              false
            );
            xhr.addEventListener(
              "error",
              function (evt) {
                console.log("upload Failed");
              },
              false
            );
            xhr.addEventListener(
              "abort",
              function (evt) {
                console.log("upload cancelled");
              },
              false
            );

            xhr.open(
              "POST",
              "https://s3-us-west-1.amazonaws.com/imagesw.pizap.com/",
              true
            ); //MUST BE LAST LINE BEFORE YOU SEND
            xhr.send(fd);
          });
        },
        function () {
          console.log("save failed");
        }
      );
      setPizapSaved(true);
    }
  };

  const facebookShare = () => {
    if (pizapProgress >= 1) {
      window.FB.ui(
        {
          method: "share",
          href: imageUrl,
          hashtag: "#pizap",
        },
        function (response) {}
      );
    } else {
      pizapSave(facebookShare);
    }
  };

  const whatsappShare = () => {
    if (pizapProgress >= 1) {
      window.open(
        `https://web.whatsapp.com/send?text=${encodeURIComponent(
          `Created at piZap.com - Design Simplified https://www.pizap.com/app/share?image=${imageUrl}`
        )}`,
        "_blank"
      );
    } else {
      pizapSave(whatsappShare);
    }
  };

  const linkedinShare = () => {
    if (pizapProgress >= 1) {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          imageUrl
        )}`,
        "_blank"
      );
    } else {
      pizapSave(linkedinShare);
    }
  };

  const twitterShare = () => {
    if (pizapProgress >= 1) {
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          `Created at piZap.com: Design Simplified: https://www.pizap.com/app/share?image=${imageUrl}`
        )}&url=${encodeURIComponent(imageUrl)}&hashtags=piZap`,
        "_blank"
      );
    } else {
      pizapSave(twitterShare);
    }
  };

  const sendEmail = () => {
    window.open(
      `mailto:?subject=${encodeURIComponent(
        "Check this out."
      )}&body=${encodeURIComponent(
        `https://www.pizap.com/app/share?image=${imageUrl}`
      )} Created at https://www.pizap.com`,
      "_blank"
    );
  };

  const handleCopyShareClipboard = () => {
    navigator.clipboard
      .writeText(`https://www.pizap.com/app/?project=${cloudUrl}`)
      .then(() => {
        setCopyShareLinkClass("visible");
        setTimeout(() => {
          setCopyShareLinkClass("");
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  const handleCopyImageClipboard = () => {
    navigator.clipboard
      .writeText(`https://www.pizap.com/app/share?image=${imageUrl}`)
      .then(() => {
        setCopyImageLinkClass("visible");
        setTimeout(() => {
          setCopyImageLinkClass("");
        }, 500);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  const pinterestShare = useMemo(() => {
    return (
      <a
        href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
          window.location.href
        )}&url=${encodeURIComponent(imageUrl)}&description=${encodeURIComponent(
          "Created at piZap.com: Design Simplified"
        )}`}
        target="_blank"
        className="share-item"
      >
        <img src={PinterestIcon} height={60} width={60} />
        <span className="destinationName">Pinterest</span>
      </a>
    );
  }, [imageUrl]);

  return (
    <div className="dialog-background">
      <div className="share-dialog-box">
        <div className="photopicker-navbar">
          <div className="photopicker-title">Share</div>
          <div className="close-btn" onClick={onClose}>
            <CloseSVG height={12} />
          </div>
        </div>
        <div className="share-holder">
          <div id="saveCaptionContainer" className="mb-2">
            <span>
              Link to an editable copy of your project
            </span>
            <div className="referral-link-input">
              <input
                type="text"
                className="input referal-url-input"
                name="referral"
                readOnly
                defaultValue={cloudUrl ? cloudUrl : ""}
              />
              {cloudUrl ? (
                <button
                  className="button copyLink tooltip"
                  onClick={handleCopyShareClipboard}
                >
                  Copy
                  <span className={`share-tooltiptext ${copyShareLinkClass}`}>
                    <img src={CheckIcon} height={10} />
                    Copied!
                  </span>
                </button>
              ) : (
                <div className="copy-link-loading"></div>
              )}
            </div>
          </div>
          <div id="saveCaptionContainer" className="mb-2">
            <span>Link to your saved image</span>
            <div className="referral-link-input">
              <input
                type="text"
                className="input referal-url-input"
                name="referral"
                readOnly
                defaultValue={
                  imageUrl
                    ? `https://www.pizap.com/app/share?image=${imageUrl}`
                    : ""
                }
              />
              {imageUrl ? (
                <button
                  className="button copyLink tooltip"
                  onClick={handleCopyImageClipboard}
                >
                  Copy
                  <span className={`share-tooltiptext ${copyImageLinkClass}`}>
                    <img src={CheckIcon} height={10} />
                    Copied!
                  </span>
                </button>
              ) : (
                <div className="copy-link-loading"></div>
              )}
            </div>
          </div>
          <span className="share-title">Share your image on:</span>
          <div className="share-sources">
            <div className="share-item" onClick={facebookShare}>
              <img src={FacebookIcon} height={60} width={60} />
              <span className="destinationName">Facebook</span>
            </div>
            <div className="share-item" onClick={whatsappShare}>
              <img src={WhatsappIcon} height={60} width={60} />
              <span className="destinationName">Whatsapp</span>
            </div>
            <div className="share-item" onClick={twitterShare}>
              <img src={TwitterIcon} height={60} width={60} />
              <span className="destinationName">X "Twitter"</span>
            </div>
            {pinterestShare}
            <div className="share-item" onClick={linkedinShare}>
              <img src={LinkedinIcon} height={60} width={60} />
              <span className="destinationName">LinkedIn</span>
            </div>
            <div className="share-item" onClick={sendEmail}>
              <img src={EmailIcon} height={60} width={60} />
              <span className="destinationName">Email</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
