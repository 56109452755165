import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const FreeStockImagePage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Free Stock Images | Royalty Free Stock Images"
        description="Free stock images and royalty free images to use in your projects. Over 1.6 million free images"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/free_stock_images"
        keywords="free stock images, royalty free images, stock"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Free Stock Images</p>
          <p className="desc">
            The ultimate design and editing app with Free Stock Images
          </p>
          <StartButton
            text="Edit a Stock Photo →"
            handleClick={() => {
              navigate("/app/start");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/free_stock_images_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Free Stock Images</span>
            <span className="desc">
              Enjoy free stock images at your fingertips when you download piZap
              to your mobile device or desktop computer. piZap is packed with
              all the features you need to make stunning websites, business
              cards, flyers, and so much more. Starting with free images.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Tool Overview: Free Stock Images</span>
            <span className="desc">
              piZap is one of the few photo editing and design apps to offer
              free stock images. Aside from professional photos, you can easily
              add your own photos, or choose from thousands of stickers and
              hundreds of fonts and text effects. Use other in-app tools to
              further facilitate your creations. For instance, use the Cut-Out
              Tool to transport one thing or person in an image to another
              background, photo, or design.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Easily Add Free Images to Your Creations
            </span>
            <span className="desc">
              From businesses to social media icons, so many people from all
              walks of life rely on piZap’s editing tools and free stock images
              to wow customers, followers and fans. Why? Because piZap is easy
              to use without slacking off in terms of functionality or
              capabilities. We make it easy to use free images in so many ways.
              No matter your age or editing and design experience, you’ll feel
              like a pro using piZap in no time.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Professional + Fun Free Images</span>
            <span className="desc">
              Get ready to enjoy so many custom editing tools at your
              fingertips, along with a hefty selection of top-notch royalty free
              stock images. Pick from fresh and funky photos or go for something
              more simple or professional. The choice is yours, and so is how
              you edit it. Change brightness, zoom in, adjust filters and more.
              It’s never been more fun to select, edit, and use free stock
              images.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/photo-editor-app-05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Features Galore + Free Stock Images</span>
            <span className="desc">
              Not only does piZap give you access to free stock images, it also
              lets you edit photos and design things in a snap. Use in-app
              editing tools to personalize free images; add filters or stickers
              or bold fonts. You can even make business logos and create web
              graphics. All this and more on one app? It must be piZap! See
              firsthand why piZap has millions of downloads and counting.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default FreeStockImagePage;
