import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const WrinkleRemoverPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Wrinkle Remover Photo Editor | Remove Wrinkles from Photo"
        description="Try the amazing wrinkle remover feature on our online photo editor. Easily and quickly erase wrinkles and blemishes from photos."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/wrinkle_remover_app"
        keywords="Wrinkle remover, remove wrinkles, photo editor, photo touch up app"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Wrinkle Remover Photo Editor</p>
          <p className="desc">
            Meet the wrinkle remover app people can’t stop talking about
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wrinkle_remover_photo_editor_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wrinkle_remover_photo_editor_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Wrinkle Remover Photo Editor</span>
            <span className="desc">
              Never post another selfie with wrinkles again! Thanks to piZap’s
              Wrinkle Remover Photo Editor, it’s never been easier or more
              affordable to zap away fine lines and deep wrinkles.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Wrinkle Remover Photo Editor Tool</span>
            <span className="desc">
              piZap’s vast array of Touch Up Tools wouldn’t be complete without
              a tool to remove wrinkles. It’s only normal for fine lines or deep
              wrinkles to bother you, especially after snapping a close-up
              selfie. Instead of spending 20k on a facelift or injecting Botox
              into your face, you can safely remove lines using piZap’s wrinkle
              remover photo editor. Plus, piZap comes with countless other touch
              up tools to play around with including a blemish remover, airbrush
              makeup tool, and eye color changer.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/wrinkle_remover_photo_editor_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wrinkle_remover_photo_editor_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Easy to Use Wrinkle Remover Photo Editor
            </span>
            <span className="desc">
              piZap’s wrinkle removing feature lets you post a nice selfie
              without the excess lines—and it’s SO much easier than you might
              think. All too often, wrinkle remover apps and other photo editors
              make it difficult to create natural looking edits to your
              pictures. piZap makes it easy to accomplish the look you’re after.
              In fact, you’ll feel like a pro within minutes of playing around
              with the app. Additionally, piZap makes it easy to save and share
              your photos from directly inside the app.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Have Fun Smoothing Out Wrinkles</span>
            <span className="desc">
              piZap makes removing wrinkles as easy as ironing wrinkly clothes.
              But unlike ironing your clothes, it’s a lot of fun to use piZap’s
              wrinkle remover photo editor. Just imagine the thrill of shaving
              years off your face by simply using the tip of your finger. Any
              other blemishes you want to eradicate? You can take care of those
              in a few swipes too! You won’t believe how good your pictures
              look, and what’s more fun than that?
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/wrinkle_remover_photo_editor_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/wrinkle_remover_photo_editor_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Wrinkle Remover App + So Many More Features
            </span>
            <span className="desc">
              piZap is well-known for its awesome Wrinkle Remover Tool but this
              app is anything but a one trick pony. You get a full selection of
              touch up tools, along with photo editing features like the ability
              to change filters, crop photos, or add stickers and text effects.
              You can also make graphic designs, logos, invitations, thank you
              cards, MEMEs and more, all on one app!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default WrinkleRemoverPage;
