import React, { useEffect, useState } from "react";
import AssetStore from "../stores/AssetStore";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import EditorActionCreators from "../actions/EditorActionCreators";
import CropSelector from "../editor/CropSelector";
import { useMemo } from "react";

function getStateFromStores() {
  return {
    assets: AssetStore.get(),
  };
}

const CropPicker = (props) => {
  const [PickerState, setPickerState] = useState(getStateFromStores());
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      AssetStore.addChangeListener(_onChange);
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      isMounted = false;
      AssetStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCropPicker(false);
  };
  const _onChange = () => {
    setPickerState(getStateFromStores(PickerState));
  };
  const _onCategoryClick = (event) => {
    let id = event.currentTarget.attributes["data-id"].value;
    PickerState.assets.Crop.Categories.forEach(function (item) {
      if (item.Name === id) {
        setSelectedItem(item);
        AssetStore.emitChange();
      }
    });
  };
  const _onItemClick = (event) => {
    let id = event.currentTarget.attributes["data-id"].value;
    selectedItem.items.forEach(function (item) {
      if (item.Name === id) {
        let cropSelector = window.editor.getCropSelector();
        let baseObject = window.editor.getBaseObject();
        let min = Math.min(baseObject.width, baseObject.height);

        if (cropSelector) {
          CropSelector.setFixedAspect(true);
          if (item.specialFunction && item.specialFunction === "custom")
            CropSelector.setFixedAspect(false);
          if (item.specialFunction && item.specialFunction === "original") {
            let bo = window.editor.getBaseObject();
            if (bo.width >= bo.height) {
              item.initialWidth = 0.8;
              item.initialHeight = (bo.height / bo.width) * 0.8;
            } else {
              item.initialHeight = 0.8;
              item.initialWidth = (bo.width / bo.height) * 0.8;
            }
          }
          if (item.specialFunction && item.specialFunction === "screen") {
            let width = window.screen.width;
            let height = window.screen.height;
            if (width >= height) {
              item.initialWidth = 0.8;
              item.initialHeight = (height / width) * 0.8;
            } else {
              item.initialHeight = 0.8;
              item.initialWidth = (width / height) * 0.8;
            }
            item.maxWidth = width;
            item.maxHeight = height;
          }
          EditorStore.setSelectedCropType(item);
          window.editor.updateCropRect(
            min * item.initialWidth,
            min * item.initialHeight
          );
        }
      }
    });
    EditorActionCreators.ShowCropPicker(false);
  };

  const categories = useMemo(() => {
    return PickerState.assets.Crop.Categories.map((item, index) => {
      if (!selectedItem) setSelectedItem(item);
      if (item === selectedItem) {
        return (
          <div
            className="cropCategory clickable active"
            data-id={item.Name}
            key={index}
            onClick={_onCategoryClick}
          >
            {item.Name}
          </div>
        );
      } else {
        return (
          <div
            className="cropCategory clickable"
            data-id={item.Name}
            key={index}
            onClick={_onCategoryClick}
          >
            {item.Name}
          </div>
        );
      }
    });
  }, [selectedItem]);

  const items = useMemo(() => {
    if (selectedItem) {
      return selectedItem.items.map((item, index) => {
        let style = {
          width: item.thumbnailWidth,
          height: item.thumbnailHeight,
        };
        if (
          item.specialFunction &&
          item.specialFunction === "screen" &&
          item.Name.indexOf("(") < 0
        ) {
          item.Name =
            item.Name +
            " (" +
            window.screen.width +
            "x" +
            window.screen.height +
            ")";
        }
        return (
          <div
            key={index}
            className="cropItemContainer clickable"
            data-id={item.Name}
            onClick={_onItemClick}
          >
            <div className="cropItemHolder">
              <div style={style} className="cropItem"></div>
            </div>
            <span>{item.Name}</span>
          </div>
        );
      });
    }
  }, [selectedItem]);

  return (
    <div className="photopicker" id="photopicker">
      <div id="photopicker-dialog" className="photopicker-dialog">
        <div className="croppicker-dialog-box">
          <div className="photopicker-navbar">
            <div className="photopicker-title">Select Crop Shape</div>
            <a
              id="photopicker-cancel-btn"
              className="photopicker-navbar-right-btn"
              onClick={_cancelClick}
            >
              Cancel
            </a>
          </div>

          <div id="photopicker-container">
            <div id="photopicker-albums-list" className="photopicker-list">
              {categories}
            </div>
            <div className="photopicker-photos cropRightSide">
              <div id="scroll-content">{items}</div>
            </div>
          </div>
          <div id="photopicker-dialog-loading" className="photopicker-loading">
            Loading&hellip;
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropPicker;
