import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { ReactComponent as CloseSVG } from "../images/editor/close_x.svg";

const CutoutRefine = () => {
  const _cancelClick = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutRefine(false);
  };

  const _onNext = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowCutoutRefine(false);
  };

  return (
    <div className="dialog-background">
      <div className="cutout-dialog-box cutout-style-dialog">
        <div className="filepicker-navbar">
          <div className="cutout-title">Step 3. Refine edge</div>
          <div className="close-btn" onClick={_cancelClick}>
            <CloseSVG height={12} />
          </div>
        </div>
        <p className="center">
          Move the points to refine the edges of your shapes.
        </p>
        <div className="cutout-refine">
          <img
            src="https://static.pizap.com/html5/resources/editor/cutout-refine.jpg"
            alt="cutout refine"
          />
        </div>
        <button className="btn my-btn cutoutFinishBtn" onClick={_onNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default CutoutRefine;
