import React from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import { useNavigate } from "react-router-dom";

const AIErrorModal = () => {
  const navigate = useNavigate();
  const handleClickCancel = () => {
    EditorActionCreators.ShowAIError(false);
    if (!window.editor.getBaseObject()) {
      navigate("/app/start");
    }
  };

  return (
    <div className="filepicker-dialog active">
      <div className="ai-error-modal">
        <div className="modal-body" style={{ textAlign: "left" }}>
          <span>
            🌟 Hey there! Unfortunately we can't create this image. Please
            follow these guidelines:
          </span>
          <ul className="ai-guide">
            <li>
              <b>Inspired by Modern Artists:</b> Love a certain artist’s style
              post-1912? We can’t replicate it exactly, but we can create
              something inspired by it. For example, instead of asking for an
              image "in the style of Picasso," try "an abstract, cubist-inspired
              painting with vibrant colors."
            </li>
            <li>
              <b>Reimagining Characters:</b> Let’s steer clear of directly using
              copyrighted characters. Instead, describe a unique character with
              specific traits for us to bring to life.
            </li>
            <li>
              <b>Original Takes on Public Figures & Celebrities:</b> We value
              originality, so please avoid direct references to public figures
              or celebrities. Let’s create something uniquely yours!
            </li>
            <li>
              <b>Positive and Respectful Creations:</b> Our community is all
              about uplifting content. Please ensure your image requests are
              respectful and non-offensive.
            </li>
          </ul>
          <br />
          <span>We’re excited to see your creativity shine! 🎨</span>
        </div>
        <div className="modal-footer">
          <div className="clickable confirm-btn" onClick={handleClickCancel}>
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIErrorModal;
