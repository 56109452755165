import EditorActionCreators from "../../actions/EditorActionCreators";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ColorPicker from "../ColorPicker.react";
import FontPicker from "../FontPicker.react";
import EditorStore from "../../stores/EditorStore";
import AssetStore from "../../stores/AssetStore";
import UserStore from "../../stores/UserStore";
import Lockr from "lockr";
import * as Filters from "pixi-filters";
import { decimalToHex, getOppositeColor } from "../../utils/utils";
import RangeSlider from "../RangeSlider";
import { ReactComponent as ItalicIcon } from "../../images/editor/italic_icon.svg";
import { ReactComponent as LineHeightIcon } from "../../images/editor/line_height_icon.svg";
import { ReactComponent as FontCheckIcon } from "../../images/editor/check_mark_icon.svg";
import { ReactComponent as LeftAlignIcon } from "../../images/editor/text_align_left_icon.svg";
import { ReactComponent as BoldIcon } from "../../images/editor/bold_icon.svg";
import { ReactComponent as RightAlignIcon } from "../../images/editor/text_align_right_icon.svg";
import { ReactComponent as CenterAlignIcon } from "../../images/editor/text_align_center_icon.svg";
import { ReactComponent as TransparencyIcon } from "../../images/editor/font_transparency.svg";
import { ReactComponent as DeleteIcon } from "../../images/editor/delete_icon.svg";
import { ReactComponent as FlipVerticalIcon } from "../../images/editor/flip_vertical_icon.svg";
import { ReactComponent as FlipHorizontalIcon } from "../../images/editor/flip_horizontal_icon.svg";
import OutlineIcon from "../../images/editor/text_outline_icon.png";
import ShadowIcon from "../../images/editor/text_shadow_icon.png";
import GlowIcon from "../../images/editor/text_glow_icon.png";
import BackgroundIcon from "../../images/editor/text_background_icon.png";
import CurvedIcon from "../../images/editor/text_curved_icon.png";
import LeftMenuActionCreators from "../../actions/LeftMenuActionCreators";
import LeftMenuStore from "../../stores/LeftMenuStore";

const getStateFromStores = () => {
  return {
    selectedObject: EditorStore.getSelectedObject(),
    fontColor: EditorStore.getFontColor(),
    glowColor: EditorStore.getGlowColor(),
    backgroundColor: EditorStore.getBackgroundColor(),
    outlineColor: EditorStore.getOutlineColor(),
    dropShadowColor: EditorStore.getDropShadowColor(),
    dropShadowAlpha: EditorStore.getDropShadowAlpha(),
    dropShadowBlur: EditorStore.getDropShadowBlur(),
    dropShadowAngle: EditorStore.getDropShadowAngle(),
    dropShadowDistance: EditorStore.getDropShadowDistance(),
    fontSize: EditorStore.getFontSize(),
    spacing: EditorStore.getLetterSpacing(),
    lineHeight: EditorStore.getLineHeight(),
    font: EditorStore.getFont(),
    text: EditorStore.getText(),
    localFonts: EditorStore.getLocalFonts(),
    ourFonts: AssetStore.getFonts(),
    glowVal: EditorStore.getGlowSliderVal(),
    outlineVal: EditorStore.getOutlineSliderVal(),
    curve: EditorStore.getTextCurve(),
    blankColor: EditorStore.getBlankColor(),
  };
};
const MenuText = () => {
  const [data, setData] = useState(getStateFromStores());
  const [glowSlideVale, setGlowSlideVal] = useState(2);
  const textArea = useRef();
  const [showTabs, setShowTabs] = useState(false);
  const [isShowFontChange, setIsShowFontChange] = useState(false);
  const [isShowLineHeight, setIsShowLineHeight] = useState(false);
  const [isShowTransparency, setIsShowTransparency] = useState(false);
  const [isShowAlign, setIsShowAlign] = useState(false);
  const [isCheckedOutline, setIsCheckedOutline] = useState(false);
  const [isCheckedGlow, setIsCheckedGlow] = useState(false);
  const [isCheckedBackground, setIsCheckedBackground] = useState(false);
  const [isCheckedShadow, setIsCheckedShadow] = useState(false);
  const [isCheckedCurved, setIsCheckedCurved] = useState(false);
  const spacingRef = useRef();
  const fontRef = useRef();
  const transparencyRef = useRef();
  const alignRef = useRef();
  const scrollRef = useRef();
  const isMounted = useRef(true);
  const [shadowExtend, setShadowExtend] = useState(false);
  const [backgroundExtend, setBackgroundExtend] = useState(false);
  const [glowExtend, setGlowExtend] = useState(false);
  const fontSizeArray = [
    6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88,
    96, 104, 120, 144, 170, 200, 240,
  ];

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    AssetStore.addChangeListener(_onChange);
    UserStore.addChangeListener(_onChange);
    onTextResize();

    return () => {
      EditorStore.removeChangeListener(_onChange);
      AssetStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
      // Indicate that the component has unmounted
      isMounted.current = false;
    };
  }, []);

  const _onChange = () => {
    if (isMounted.current) {
      setData(getStateFromStores());
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isShowAlign &&
        alignRef.current &&
        !alignRef.current.contains(e.target)
      ) {
        setIsShowAlign(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShowAlign]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isShowTransparency &&
        transparencyRef.current &&
        !transparencyRef.current.contains(e.target)
      ) {
        setIsShowTransparency(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShowTransparency]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isShowLineHeight &&
        spacingRef.current &&
        !spacingRef.current.contains(e.target)
      ) {
        setIsShowLineHeight(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShowLineHeight]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isShowFontChange &&
        fontRef.current &&
        !fontRef.current.contains(e.target)
      ) {
        setIsShowFontChange(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShowFontChange]);

  const fontSelected = (font, isLocal) => {
    const curveValue = data.curve;
    _onCurveReset();
    setShowTabs(false);
    if (isLocal) {
      EditorActionCreators.Font(font);
    } else {
      EditorActionCreators.Font({ value: font, curve: curveValue });
    }
  };

  const handleChangeOutline = (e) => {
    setIsCheckedOutline(e.target.checked);
    EditorActionCreators.OutlineEnable(e.target.checked);
  };

  const handleChangeShadow = (e) => {
    setIsCheckedShadow(e.target.checked);
    EditorActionCreators.DropShadowEnabled(e.target.checked);
    if (e.target.checked) {
      Lockr.set("shadowText", {
        alpha: data.selectedObject.dropShadowAlpha,
        blur: data.selectedObject.dropShadowBlur,
        color: data.selectedObject.dropShadowColor,
        distance: data.selectedObject.dropShadowDistance,
        angle: data.selectedObject.dropShadowAngle,
      });
    } else {
      Lockr.set("shadowText", null);
    }
  };

  const handleChangeGlow = (e) => {
    setIsCheckedGlow(e.target.checked);
    EditorActionCreators.GlowEnable(e.target.checked);
    if (e.target.checked) {
      Lockr.set("glowText", {
        color: data.selectedObject.glowColor,
        distance: 2,
      });
    } else {
      Lockr.set("glowText", null);
    }
  };

  const handleChangeBackground = (e) => {
    setIsCheckedBackground(e.target.checked);
    EditorActionCreators.BackgroundEnable(e.target.checked);
    if (!e.target.checked) {
      Lockr.set("backgroundText", null);
    }
  };

  const handleChangeCurved = (e) => {
    setIsCheckedCurved(e.target.checked);
    if (!e.target.checked) {
      _onCurveReset();
    } else {
      setIsCheckedBackground(false);
      EditorActionCreators.BackgroundEnable(false);
    }
    setTimeout(() => {
      if (e.target.checked && scrollRef && scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const hideFontPicker = () => {
    setShowTabs(false);
  };

  const colorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.FontColor(color);
  };

  const glowColorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.GlowColor(color);
    Lockr.set("glowText", {
      ...Lockr.get("glowText"),
      color: color,
    });
  };

  const outlineColorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.OutlineColor(color);
  };

  const backgroundColorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.BackgroundColor(color);
    Lockr.set("backgroundText", {
      color: color,
    });
  };

  const dropShadowColorPicked = (colorCode) => {
    const color = parseInt(colorCode.replace("#", ""), 16);
    EditorActionCreators.DropShadowColor(color);
    Lockr.set("shadowText", {
      ...Lockr.get("shadowText"),
      color: color,
    });
  };

  const handleShowFontChangeModal = () => {
    setIsShowFontChange((old) => !old);
  };

  const handleClickLineHeight = () => {
    setIsShowLineHeight((old) => !old);
  };

  const handleClickTransparency = () => {
    setIsShowTransparency((old) => !old);
  };

  const handleClickAlign = () => {
    setIsShowAlign((old) => !old);
  };

  const handleChangeFontSize = (value) => {
    if (value && value > 0) {
      EditorActionCreators.FontSize(value, true);
    }
  };

  const handleClickMinusFontSize = () => {
    if (data.fontSize && data.fontSize > 6) {
      EditorActionCreators.FontSize(data.fontSize - 2, true);
    }
  };

  const handleClickPlusFontSize = () => {
    if (data.fontSize && data.fontSize < 242) {
      EditorActionCreators.FontSize(data.fontSize + 2, true);
    }
  };

  const _onSpacingChange = (value) => {
    const spacing = parseInt(value, 10);
    if (spacing) {
      EditorActionCreators.LetterSpacing(spacing);
    }
  };

  const _onCurveChange = (value) => {
    const curve = parseInt(value, 10);
    if (curve) {
      EditorActionCreators.TextCurve(curve);
    }
  };

  const _onCurveReset = () => {
    EditorActionCreators.TextCurve(0);
  };

  const _onHeightChange = (value) => {
    EditorActionCreators.LineHeight(parseInt(value, 10));
  };

  const _onGlowSliderChange = (value) => {
    EditorActionCreators.GlowSliderChange(parseFloat(value));
    setGlowSlideVal(value);
    Lockr.set("glowText", {
      ...Lockr.get("glowText"),
      distance: parseFloat(value),
    });
  };

  const _onOutlineSliderChange = (value) => {
    EditorActionCreators.OutlineSliderChange(parseFloat(value));
  };

  const handleChangeTransparency = (value) => {
    data.selectedObject.alpha = value;
    if (data.selectedObject?.sourceUrl) {
      let txtObject = window.editor.getStickerById(
        data.selectedObject?.childId
      );
      txtObject.alpha = value;
    }
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const _onAlphaChange = (value) => {
    EditorActionCreators.DropShadowAlphaSliderChange(parseFloat(value));
    Lockr.set("shadowText", {
      ...Lockr.get("shadowText"),
      alpha: parseFloat(value),
    });
  };

  const _onBlurChange = (value) => {
    EditorActionCreators.DropShadowBlurSliderChange(parseFloat(value));
    Lockr.set("shadowText", {
      ...Lockr.get("shadowText"),
      blur: parseFloat(value),
    });
  };

  const _onAngleChange = (value) => {
    EditorActionCreators.DropShadowAngleSliderChange(parseFloat(value));
    Lockr.set("shadowText", {
      ...Lockr.get("shadowText"),
      angle: parseFloat(value),
    });
  };

  const _onDistanceChange = (value) => {
    EditorActionCreators.DropShadowDistanceSliderChange(parseFloat(value));
    Lockr.set("shadowText", {
      ...Lockr.get("shadowText"),
      distance: parseFloat(value),
    });
  };

  const _onTextChange = (event) => {
    if (Lockr.get("font")) {
      EditorActionCreators.TextAlign(Lockr.get("font")._align);
    } else {
      EditorActionCreators.TextAlign("center");
    }
    if (
      !EditorStore.getDesignBackground() &&
      data.fontColor === "#" + decimalToHex(data.blankColor)
    ) {
      const colorCode = getOppositeColor("#" + decimalToHex(data.blankColor));
      const _fontStyle = Lockr.get("font");
      Lockr.set("font", { ..._fontStyle, _fill: colorCode });
      const color = parseInt(colorCode.replace("#", ""), 16);
      EditorActionCreators.FontColor(color);
    }
    const curveValue = data.curve;
    _onCurveReset();
    EditorActionCreators.TextChange(event.currentTarget.value);
    if (event.currentTarget.value.length === 1) {
      if (Lockr.get("shadowText") && !shadowExtend) {
        setIsCheckedShadow(true);
        EditorActionCreators.DropShadowEnabled(true);
        EditorActionCreators.DropShadowBlurSliderChange(
          Lockr.get("shadowText").blur
        );
        EditorActionCreators.DropShadowAlphaSliderChange(
          Lockr.get("shadowText").alpha
        );
        EditorActionCreators.DropShadowAngleSliderChange(
          Lockr.get("shadowText").angle
        );
        EditorActionCreators.DropShadowDistanceSliderChange(
          Lockr.get("shadowText").distance
        );
        EditorActionCreators.DropShadowColor(Lockr.get("shadowText").color);
      }
      if (Lockr.get("backgroundText") && !backgroundExtend) {
        if (!data.selectedObject || !data.selectedObject?.sourceUrl) {
          setIsCheckedBackground(true);
          EditorActionCreators.BackgroundEnable(true);
          EditorActionCreators.BackgroundColor(
            Lockr.get("backgroundText").color
          );
        }
      }

      if (Lockr.get("glowText") && !glowExtend) {
        setIsCheckedGlow(true);
        EditorActionCreators.GlowEnable(true);
        EditorActionCreators.GlowColor(Lockr.get("glowText").color);
        EditorActionCreators.GlowSliderChange(Lockr.get("glowText").distance);
      }
    }
    if (curveValue) {
      EditorActionCreators.TextCurve(parseInt(curveValue));
    }
  };

  const _onAlignLeft = () => {
    EditorActionCreators.TextAlign("left");
  };

  const _onAlignCenter = () => {
    EditorActionCreators.TextAlign("center");
  };

  const _onAlignRight = () => {
    EditorActionCreators.TextAlign("right");
  };

  const _onBold = () => {
    EditorActionCreators.TextBold();
  };

  const _onItalic = () => {
    EditorActionCreators.TextItalic();
  };

  const onTextResize = () => {
    window.setTimeout(resize, 0);
  };

  const resize = () => {
    const text = textArea.current;
    if (text) {
      text.style.height = "auto";
      text.style.height = text.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (data.selectedObject) {
      setIsCheckedShadow(false);
      setIsCheckedGlow(false);
      setShadowExtend(false);
      setGlowExtend(false);
      Lockr.set("font", { ...data.selectedObject.style });
      if (data.selectedObject.backgroundEnabled) {
        Lockr.set("backgroundText", {
          color: data.selectedObject.backgroundColor,
        });
        setIsCheckedBackground(true);
        setBackgroundExtend(true);
      } else {
        setIsCheckedBackground(false);
        setBackgroundExtend(false);
      }
      if (
        data.selectedObject.filters?.length &&
        data.selectedObject.filters?.length !== 0
      ) {
        for (let z = 0; z < data.selectedObject.filters.length; z++) {
          if (
            data.selectedObject.filters[z] instanceof Filters.DropShadowFilter
          ) {
            setShadowExtend(true);
            setIsCheckedShadow(true);
            Lockr.set("shadowText", {
              alpha: data.selectedObject.dropShadowAlpha,
              blur: data.selectedObject.dropShadowBlur,
              color: data.selectedObject.dropShadowColor,
              distance: data.selectedObject.dropShadowDistance,
              angle: data.selectedObject.dropShadowRotation,
            });
          }
          if (data.selectedObject.filters[z] instanceof Filters.GlowFilter) {
            setIsCheckedGlow(true);
            setGlowExtend(true);
            Lockr.set("glowText", {
              color: data.selectedObject.glowColor,
              distance: 2,
            });
          }
        }
      }
      if (data.selectedObject.style) {
        if (data.selectedObject.style?.strokeThickness > 0) {
          setIsCheckedOutline(true);
        } else {
          setIsCheckedOutline(false);
        }

        if (data.selectedObject.style.font.includes("bold")) {
          data.selectedObject.bold = true;
        } else {
          data.selectedObject.bold = false;
        }

        if (data.selectedObject.style.font.includes("italic")) {
          data.selectedObject.italic = true;
        } else {
          data.selectedObject.italic = false;
        }
      }
      if (EditorStore.getTextCurve()) {
        setIsCheckedCurved(true);
      } else {
        setIsCheckedCurved(false);
      }
    }
  }, [data.selectedObject]);

  useEffect(() => {
    const edp = document.getElementById("editor-properties");
    if (showTabs) {
      if (edp) {
        edp.style.display = "none";
      }
    } else {
      if (edp && EditorStore.getSelectedObject() !== null) {
        window.editor.positionEdp(EditorStore.getSelectedObject());
      }
    }
  }, [showTabs]);

  const handleClickBack = () => {
    LeftMenuActionCreators.MenuSelected("text");
    window.editor.setSelectedObject(null);
  };

  const handleFlipHorizontal = (e) => {
    e.stopPropagation();
    EditorStore.getSelectedObject().scale.x *= -1;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const handleFlipVertical = (e) => {
    e.stopPropagation();
    EditorStore.getSelectedObject().scale.y *= -1;
    EditorStore.emitChange();
    window.editor.autoSave();
  };

  const FlipControls = useMemo(() => {
    if (
      !(data.selectedObject instanceof PIXI.Text) &&
      data.selectedObject instanceof PIXI.Sprite &&
      data.selectedObject.curve !== 0
    ) {
      return (
        <div className="space-between mt-2">
          <span style={{ paddingLeft: "10px" }}>Flip</span>
          <FlipHorizontalIcon
            className="clickable semi-clickable-button"
            onClick={handleFlipHorizontal}
            width={16}
          />
          <FlipVerticalIcon
            className="clickable semi-clickable-button"
            onClick={handleFlipVertical}
            width={16}
            height={16}
          />
        </div>
      );
    } else null;
  }, [data.selectedObject]);

  const handleClickDone = () => {
    EditorStore.setSelectedObject(null);
    LeftMenuStore.setMenuSelected("text");
    window.editor.stickerSelector.cancelSelector();
  };

  const handleClickDelete = () => {
    if (EditorStore.getSelectedObject()) {
      EditorActionCreators.RemoveSticker(EditorStore.getSelectedObject());
    }
    LeftMenuStore.setMenuSelected("text");
    window.editor.autoSave();
  };

  const textBackgroundComponent = useMemo(() => {
    if (
      data.selectedObject &&
      !data.selectedObject?.sourceUrl &&
      !isCheckedCurved
    ) {
      return (
        <>
          <div className="space-between mt-2">
            <div className="space-between">
              <img
                src={BackgroundIcon}
                className="image-border-radius"
                height={50}
                alt="background-icon"
              />
              <span className="text-control-title">Background</span>
            </div>
            <div className="transparent">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isCheckedBackground}
                  onChange={(e) => handleChangeBackground(e)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {isCheckedBackground && (
            <div className="space-between sub-menu mt-2">
              <ColorPicker
                text="Color"
                onColor={backgroundColorPicked}
                initialColor={`#${decimalToHex(data.backgroundColor)}`}
                showChecked="true"
                opacity={1}
              />
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  }, [data.selectedObject, isCheckedBackground, isCheckedCurved]);

  const curveComponent = useMemo(() => {
    if (data.selectedObject && !data.selectedObject?.sourceUrl) {
      return (
        <>
          <div className="space-between mt-2">
            <div className="space-between">
              <img
                src={CurvedIcon}
                className="image-border-radius"
                height={50}
                alt="curved-icon"
              />
              <span className="text-control-title">Curved Text</span>
            </div>
            <div className="transparent">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isCheckedCurved}
                  onChange={(e) => handleChangeCurved(e)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {isCheckedCurved && (
            <div className="mt-2 sub-menu" ref={scrollRef}>
              <RangeSlider
                name="clickable"
                title=""
                handleReset={_onCurveReset}
                isReset={data.curve !== 0}
                isBox={false}
                handleMouseEnter={() => window.editor.hideGizmo(true)}
                handleMouseLeave={() => window.editor.hideGizmo(false)}
                startPoint={0}
                min={-180}
                max={180}
                step={1}
                handleChange={_onCurveChange}
                value={data.curve}
              />
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  }, [data.curve, isCheckedCurved, data.selectedObject]);

  const boldActive = useCallback(() => {
    if (
      data.selectedObject &&
      !data.selectedObject?.sourceUrl &&
      data.selectedObject.bold
    ) {
      return "active";
    } else if (data.selectedObject && data.selectedObject?.childId) {
      const txtObject = window.editor.getStickerById(
        data.selectedObject?.childId
      );
      if (txtObject?.bold) {
        return "active";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [data.selectedObject]);

  const italicActive = useCallback(() => {
    if (
      data.selectedObject &&
      !data.selectedObject?.sourceUrl &&
      data.selectedObject.italic
    ) {
      return "active";
    } else if (data.selectedObject && data.selectedObject?.childId) {
      const txtObject = window.editor.getStickerById(
        data.selectedObject?.childId
      );
      if (txtObject?.italic) {
        return "active";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [data.selectedObject]);

  const alignLeftActive = useCallback(() => {
    if (
      data.selectedObject &&
      !data.selectedObject?.sourceUrl &&
      data.selectedObject.style.align
    ) {
      if (data.selectedObject.style.align === "left") return "active";
      else return "";
    } else if (data.selectedObject && data.selectedObject?.childId) {
      const txtObject = window.editor.getStickerById(
        data.selectedObject?.childId
      );
      if (txtObject.style.align === "left") {
        return "active";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [data.selectedObject]);

  const alignCenterActive = useCallback(() => {
    if (
      data.selectedObject &&
      !data.selectedObject?.sourceUrl &&
      data.selectedObject.style.align
    ) {
      if (data.selectedObject.style.align === "center") return "active";
      else return "";
    } else if (data.selectedObject && data.selectedObject?.childId) {
      const txtObject = window.editor.getStickerById(
        data.selectedObject?.childId
      );
      if (txtObject.style.align === "center") {
        return "active";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [data.selectedObject]);

  const alignRightActive = useCallback(() => {
    if (
      data.selectedObject &&
      !data.selectedObject?.sourceUrl &&
      data.selectedObject.style.align
    ) {
      if (data.selectedObject.style.align === "right") return "active";
      else return "";
    } else if (data.selectedObject && data.selectedObject?.childId) {
      const txtObject = window.editor.getStickerById(
        data.selectedObject?.childId
      );
      if (txtObject.style.align === "right") {
        return "active";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [data.selectedObject]);

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        <div className="clickable d-flex-start" onClick={handleClickBack}>
          <svg viewBox="0 0 960 560" transform="rotate(90)" height={14}>
            <path
              className="st0"
              d="M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z"
            ></path>
          </svg>
          <span>Text</span>
        </div>
      </div>
      <div id="animated-element" className="text-scroll">
        <div className="textTypeContainer">
          <div className="space-evenly range-slider">
            <div
              className="clickable semi-clickable-button"
              onClick={handleClickDelete}
            >
              <DeleteIcon width={16} />
              <span className="category-name">&nbsp;Delete</span>
            </div>
            <div className="my-btn done-btn" onClick={handleClickDone}>
              <span className="category-name">Done</span>
            </div>
          </div>
          <textarea
            autoFocus
            className="text-area mt-1"
            id="text-area"
            onChange={_onTextChange}
            value={data.text}
            placeholder="Type your text here"
            onCut={onTextResize}
            onPaste={onTextResize}
            onDrop={onTextResize}
            onKeyDown={onTextResize}
            ref={textArea}
          ></textarea>
          {data.selectedObject && (
            <div className="textControls">
              <div className="space-between mt-2">
                <div
                  className="fontSelected"
                  style={{
                    fontFamily: data.font,
                  }}
                  onClick={() => {
                    setShowTabs(true);
                  }}
                >
                  {data.font}
                </div>
                <ColorPicker
                  iconClass="brush-color col-3"
                  text=""
                  onColor={colorPicked}
                  initialColor={data.fontColor}
                  opacity={1}
                />
              </div>
              <div className="space-between mt-2">
                <div className="input-number-group">
                  <span
                    className={`input-number-decrement ${
                      data.fontSize === 6 ? `disabled-menu` : ``
                    }`}
                    onClick={handleClickMinusFontSize}
                  >
                    –
                  </span>
                  <div className="font-size-container">
                    <RangeSlider
                      title=""
                      name=""
                      handleChange={handleChangeFontSize}
                      min={6}
                      max={240}
                      step={1}
                      value={data.fontSize}
                      handleReset={() => {}}
                      isReset={false}
                      isBox={false}
                      handleMouseEnter={() => window.editor.hideGizmo(true)}
                      handleMouseLeave={() => window.editor.hideGizmo(false)}
                      startPoint={0}
                    />
                  </div>
                  <span
                    className={`input-number-increment clickable ${
                      data.fontSize === 240 ? `disabled-menu` : ``
                    }`}
                    onClick={handleClickPlusFontSize}
                  >
                    +
                  </span>
                </div>
                <span
                  className={`text-font-size fontSelected ${
                    isShowFontChange ? "active" : ""
                  }`}
                  ref={fontRef}
                  onClick={handleShowFontChangeModal}
                  style={{ cursor: "pointer" }}
                >
                  {parseFloat(data.fontSize).toFixed(0)}
                  <div
                    className="font-change-modal"
                    style={{ display: isShowFontChange ? "block" : "none" }}
                  >
                    {fontSizeArray.map((item) => {
                      return (
                        <div
                          key={item}
                          className="font-select-item"
                          onClick={() => handleChangeFontSize(item)}
                        >
                          <span>
                            {item}
                            {data.fontSize === item && (
                              <FontCheckIcon
                                className="font-check"
                                height={12}
                              />
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </span>
              </div>
              <div className="space-between mt-2">
                <div
                  ref={transparencyRef}
                  className={`tooltip alignIcon ${
                    isShowTransparency ? `active` : ``
                  }`}
                  onClick={handleClickTransparency}
                >
                  <TransparencyIcon height={16} />
                  <span className="tooltiptext">Opacity</span>
                  {isShowTransparency && (
                    <div className="font-click-modal">
                      <RangeSlider
                        title="Opacity"
                        min={0}
                        max={1}
                        step={0.01}
                        handleChange={handleChangeTransparency}
                        value={data.selectedObject.alpha}
                        handleReset={() => {}}
                        isReset={false}
                        isBox={false}
                        handleMouseEnter={() => window.editor.hideGizmo(true)}
                        handleMouseLeave={() => window.editor.hideGizmo(false)}
                        startPoint={0}
                      />
                    </div>
                  )}
                </div>
                <div
                  ref={alignRef}
                  className={`tooltip alignIcon clickable ${
                    isShowAlign ? `active` : ``
                  }`}
                  onClick={handleClickAlign}
                >
                  <CenterAlignIcon height={16} />
                  <span className="tooltiptext tooltip-align">Align</span>
                  {isShowAlign && (
                    <div className="font-click-modal align">
                      <LeftAlignIcon
                        className={`alignIcon ${alignLeftActive()}`}
                        onClick={_onAlignLeft}
                      />
                      <CenterAlignIcon
                        className={`alignIcon clickable ${alignCenterActive()}`}
                        onClick={_onAlignCenter}
                      />
                      <RightAlignIcon
                        className={`alignIcon-last clickable ${alignRightActive()}`}
                        onClick={_onAlignRight}
                      />
                    </div>
                  )}
                </div>
                <div
                  onClick={_onBold}
                  className={`tooltip alignIcon clickable ${boldActive()}`}
                >
                  <BoldIcon height={16} />
                  <span className="tooltiptext">Bold</span>
                </div>
                <div
                  onClick={_onItalic}
                  className={`tooltip alignIcon clickable ${italicActive()}`}
                >
                  <ItalicIcon height={16} />
                  <span className="tooltiptext">Italic</span>
                </div>
                <div
                  ref={spacingRef}
                  onClick={handleClickLineHeight}
                  className={`tooltip alignIcon-last ${
                    isShowLineHeight ? `active` : ``
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <LineHeightIcon height={16} />
                  <span className="tooltiptext">Spacing</span>
                  {isShowLineHeight && (
                    <div className="font-click-modal spacing">
                      <RangeSlider
                        title="Letter Spacing"
                        handleChange={_onSpacingChange}
                        min={-20}
                        max={100}
                        step={1}
                        value={data.spacing}
                        handleReset={() => {}}
                        isReset={false}
                        isBox={false}
                        handleMouseEnter={() => window.editor.hideGizmo(true)}
                        handleMouseLeave={() => window.editor.hideGizmo(false)}
                        startPoint={-20}
                        id="spacingRange"
                      />
                      <RangeSlider
                        title="Line Height"
                        handleChange={_onHeightChange}
                        handleReset={() => {}}
                        isReset={false}
                        isBox={false}
                        handleMouseEnter={() => window.editor.hideGizmo(true)}
                        handleMouseLeave={() => window.editor.hideGizmo(false)}
                        startPoint={2}
                        min={12}
                        max={300 / window.editor.getScale()}
                        step={1}
                        id="heightRange"
                        value={data.lineHeight}
                      />
                    </div>
                  )}
                </div>
              </div>
              {FlipControls}
            </div>
          )}
        </div>
        {data.selectedObject &&
          (data.selectedObject instanceof PIXI.Text ||
            data.selectedObject.curve !== 0) && <div className="divider"></div>}
        {data.selectedObject &&
          (data.selectedObject instanceof PIXI.Text ||
            data.selectedObject.curve !== 0) && (
            <div className="textTypeContainer">
              <div className="space-between">
                <div className="space-between">
                  <img
                    src={OutlineIcon}
                    className="image-border-radius"
                    height={50}
                    alt="outline-icon"
                  />
                  <span className="text-control-title">Outline</span>
                </div>
                <div className="transparent">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isCheckedOutline}
                      onChange={(e) => handleChangeOutline(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {isCheckedOutline && (
                <div className="mt-2">
                  <div className="space-between sub-menu">
                    <div className="slider-color-left">
                      <RangeSlider
                        title=""
                        name="text-controls-slider"
                        handleChange={_onOutlineSliderChange}
                        min={0}
                        max={20}
                        step={0.1}
                        value={data.outlineVal}
                        handleReset={() => {}}
                        isReset={false}
                        isBox={false}
                        handleMouseEnter={() => window.editor.hideGizmo(true)}
                        handleMouseLeave={() => window.editor.hideGizmo(false)}
                        startPoint={0}
                      />
                    </div>
                    <ColorPicker
                      text=""
                      onColor={outlineColorPicked}
                      initialColor={data.outlineColor}
                      opacity={1}
                    />
                  </div>
                </div>
              )}
              <div className="space-between mt-2">
                <div className="space-between">
                  <img
                    src={ShadowIcon}
                    className="image-border-radius"
                    height={50}
                  />
                  <span className="text-control-title">Shadow</span>
                </div>
                <div className="transparent">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isCheckedShadow}
                      onChange={(e) => handleChangeShadow(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {isCheckedShadow && (
                <div className="mt-2 sub-menu">
                  <RangeSlider
                    title="Blur"
                    name="clickable"
                    handleChange={_onBlurChange}
                    min={0}
                    max={3}
                    step={0.1}
                    value={data.dropShadowBlur}
                    handleReset={() => {}}
                    isReset={false}
                    isBox={false}
                    handleMouseEnter={() => window.editor.hideGizmo(true)}
                    handleMouseLeave={() => window.editor.hideGizmo(false)}
                    startPoint={0}
                  />
                  <RangeSlider
                    title="Offset"
                    name="clickable"
                    handleChange={_onDistanceChange}
                    min={1}
                    max={15}
                    step={1}
                    value={data.dropShadowDistance}
                    handleReset={() => {}}
                    isReset={false}
                    isBox={false}
                    handleMouseEnter={() => window.editor.hideGizmo(true)}
                    handleMouseLeave={() => window.editor.hideGizmo(false)}
                    startPoint={1}
                  />
                  <RangeSlider
                    title="Transparency"
                    name="clickable"
                    handleChange={_onAlphaChange}
                    min={0}
                    max={1}
                    step={0.1}
                    value={data.dropShadowAlpha}
                    handleReset={() => {}}
                    isReset={false}
                    isBox={false}
                    handleMouseEnter={() => window.editor.hideGizmo(true)}
                    handleMouseLeave={() => window.editor.hideGizmo(false)}
                    startPoint={0}
                  />
                  <RangeSlider
                    title="Angle"
                    name="clickable"
                    handleChange={_onAngleChange}
                    min={0}
                    max={359}
                    step={1}
                    value={data.dropShadowAngle}
                    handleReset={() => {}}
                    isReset={false}
                    isBox={false}
                    handleMouseEnter={() => window.editor.hideGizmo(true)}
                    handleMouseLeave={() => window.editor.hideGizmo(false)}
                    startPoint={0}
                  />
                  <ColorPicker
                    text="Color"
                    onColor={dropShadowColorPicked}
                    initialColor={`#${decimalToHex(data.dropShadowColor)}`}
                    opacity={1}
                  />
                </div>
              )}
              <div className="space-between mt-2">
                <div className="space-between">
                  <img
                    src={GlowIcon}
                    alt="glow-icon"
                    className="image-border-radius"
                    height={50}
                  />
                  <span className="text-control-title">Glow</span>
                </div>
                <div className="transparent">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isCheckedGlow}
                      onChange={(e) => handleChangeGlow(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {isCheckedGlow && (
                <div className="mt-2">
                  <div className="space-between sub-menu">
                    <div className="slider-color-left">
                      <RangeSlider
                        title=""
                        name="text-controls-slider"
                        handleChange={_onGlowSliderChange}
                        min={0}
                        max={4}
                        step={0.1}
                        // value={data.glowVal}
                        value={glowSlideVale}
                        handleReset={() => {}}
                        isReset={false}
                        isBox={false}
                        handleMouseEnter={() => window.editor.hideGizmo(true)}
                        handleMouseLeave={() => window.editor.hideGizmo(false)}
                        startPoint={0}
                      />
                    </div>
                    <ColorPicker
                      text=""
                      onColor={glowColorPicked}
                      initialColor={`#${decimalToHex(data.glowColor)}`}
                      opacity={1}
                    />
                  </div>
                </div>
              )}
              {textBackgroundComponent}
              {curveComponent}
            </div>
          )}
        {showTabs && (
          <FontPicker onSelected={fontSelected} onHide={hideFontPicker} />
        )}
      </div>
    </div>
  );
};

export default MenuText;
