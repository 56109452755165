import React from "react";

const Compare = () => {
  const _onCompare = () => {
    window.editor.compare();
  };

  const _onCompareUp = () => {
    window.editor.compareEnd();
  };

  return (
    <div className="compare-position">
      <div className="compare-container clickable">
        <div
          className="background-compare"
          title="Hold to compare to original"
          onMouseDown={_onCompare}
          onMouseUp={_onCompareUp}
        >
          <svg viewBox="0 0 253.1 360.7" height="18 ">
            <g transform="matrix( 2.2499847412109375, 0, 0, 2.2499847412109375, 282.95,200.25)">
              <path
                className="st0"
                d="M-13.3-58.7c0-1.7-0.6-3.1-1.8-4.4c-1.3-1.3-2.7-1.9-4.4-1.9h-43.7v-17.8c0-1.7-0.6-3.1-1.9-4.3
                                    c-1.3-1.3-2.7-1.9-4.4-1.9c-1.7,0-3.1,0.6-4.3,1.9c-1.3,1.2-1.9,2.7-1.9,4.3V-65h-43.7c-1.7,0-3.1,0.6-4.3,1.9
                                    c-1.3,1.2-1.9,2.7-1.9,4.4v100c0,1.7,0.6,3.1,1.9,4.4c1.2,1.2,2.7,1.9,4.3,1.9h43.7V65c0,1.7,0.6,3.1,1.9,4.4
                                    c1.2,1.2,2.7,1.8,4.3,1.8c1.7,0,3.1-0.6,4.4-1.8c1.2-1.3,1.9-2.7,1.9-4.4V47.5h43.7c1.7,0,3.1-0.6,4.4-1.9
                                    c1.2-1.3,1.8-2.7,1.8-4.4V-58.7 M-69.5-52.5V35h-43.7v-87.5H-69.5z"
              />
            </g>
          </svg>
          <span>Compare</span>
        </div>
      </div>
    </div>
  );
};

export default Compare;
