import EditorActionCreators from "../actions/EditorActionCreators";
import React, { useEffect, useMemo, useRef, useState } from "react";
import EditorStore from "../stores/EditorStore";
import ColorPicker from "./ColorPicker.react";
import { ReactComponent as AddImageIcon } from "../images/editor/photo_icon.svg";

let _checkboxState = false;

function getStateFromStores() {
  return {
    blankColor: EditorStore.getBlankColor(),
    paintColor: EditorStore.getPaintColor(),
    brushSize: EditorStore.getBrushSize(),
    checkboxState:
      window.editor.getBaseObject() &&
      window.editor.getBaseObject().transparent,
  };
}

const MenuDesign = () => {
  const [storesData, setStoresData] = useState(getStateFromStores());
  const cpElement = useRef();

  useEffect(() => {
    async () => {
      EditorStore.addChangeListener(_onChange);
    };
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setStoresData(getStateFromStores());
  };

  const _addBackgroundSelected = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowFilePicker(true, "design");
    window.editor.setSelectedObject(null);
  };

  const colorPicked = (colorCode) => {
    try {
      let _colorCode = parseInt(colorCode.replace("#", "0x"), 16);
      EditorActionCreators.updateBlankColor(_colorCode);
      let baseObject = window.editor.getBaseObject();
      baseObject.clear();
      baseObject.beginFill(
        _checkboxState ? 0xffffff : _colorCode,
        _checkboxState ? 0.05 : 1
      );
      baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
      baseObject.endFill();
      baseObject.fillColor = _colorCode;
      setStoresData(getStateFromStores());
    } catch (e) {
      console.log(e);
    }
  };

  const _sizeSelected = (event) => {
    event.preventDefault();
    EditorActionCreators.ShowDesignPicker(true);
    window.editor.setSelectedObject(null);
  };

  const isChecked = (event) => {
    _checkboxState = event.currentTarget.checked;
    let baseObject = window.editor.getBaseObject();
    baseObject.clear();
    baseObject.beginFill(
      _checkboxState
        ? 0xffffff
        : storesData.blankColor
        ? storesData.blankColor
        : 16777215,
      _checkboxState ? 0.05 : 1
    );
    baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
    baseObject.endFill();
    baseObject.transparent = _checkboxState;
    setStoresData(getStateFromStores());
  };

  useEffect(() => {
    let mainContent = document.getElementById("mainContent");
    let canvas = mainContent.getElementsByTagName("canvas");
    if (canvas && storesData.checkboxState) {
      canvas[0].className = "transparent";
    } else if (canvas && !storesData.checkboxState) {
      canvas[0].className = "background";
    }
  }, [storesData.checkboxState]);

  return (
    <div className="menu-container" id="menu-container">
      <div className="menu-header">
        <span>Background</span>
      </div>
      <div className="d-flex add-bg">
        {!storesData.checkboxState && (
          <ColorPicker
            refs={cpElement}
            text="Color"
            onColor={colorPicked}
            initialColor={storesData.blankColor}
            opacity={1}
          />
        )}
        <div className="transparent">
          <span>Transparent</span>
          <label className="switch">
            <input
              type="checkbox"
              onChange={isChecked}
              checked={storesData.checkboxState}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div
        className="background-image my-btn clickable"
        onClick={_addBackgroundSelected}
        title="Add background image"
      >
        <AddImageIcon className="add-background-image" width={20} />
        <span>Add background image</span>
      </div>
    </div>
  );
};

export default MenuDesign;
