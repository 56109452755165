import React from "react";
import { identifyDevice } from "../utils/utils";

const StartButton = (props) => {
  return (
    <>
      <button
        className="try-btn mobile-hidden"
        onClick={() => props.handleClick()}
      >
        {props.text}
      </button>
      <div className="desktop-hidden">
        {identifyDevice() === "iOS" ? (
          <a
            href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8"
            target="_blank"
          >
            <img
              className="apple-store"
              src="//cdn.pizap.com/pizapfiles/images/apple-store.png"
              width="120"
              alt="apple-store"
            />
          </a>
        ) : (
          <a
            href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap"
            target="_blank"
          >
            <img
              src="//cdn.pizap.com/pizapfiles/images/google-play.png"
              width="120"
              className="google-play"
              alt="google-play"
            />
          </a>
        )}
      </div>
    </>
  );
};

export default StartButton;
