import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import EditorActionCreators from "../actions/EditorActionCreators";
import TextEdit from "../components/TextEdit.react";
import { ReactComponent as ProBadgeSVG } from "../images/editor/pro_badge.svg";
import PlayStoreBadge from "../images/editor/play_store_badge.png";
import AppStoreBadge from "../images/editor/app_store_badge.png";
import "../styles/header.css";
import "../styles/icofont.min.css";
import ConfirmProjectDialog from "../components/ConfirmProjectDialog";
import { toast, Flip } from "react-toastify";

const getStateFromStores = () => {
  return {
    showProjectDel: EditorStore.showProjectDel(),
    delObj: EditorStore.getDeleteObj(),
  };
};

const Header = () => {
  const [data, setData] = useState(getStateFromStores());
  const navigate = useNavigate();
  const user = UserStore.getUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isEmailEdit, setIsEmailEdit] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _invite = () => {
    UserActionCreators.showHamburgerMenu(false);
    window.FB.ui({
      method: "send",
      name: "Photo Editor & Collage Maker",
      link: "https://www.pizap.com",
      picture:
        "https://cdn.pizap.com/pizapfiles/images/pizap-screenshot-invite.jpg",
    });
  };

  const _logout = () => {
    setMenuOpen(false);
    UserActionCreators.logout();
    UserActionCreators.showHamburgerMenu(false);
  };

  const _reportBug = () => {
    setMenuOpen(false);
    UserActionCreators.showHamburgerMenu(false);
    window.open("https://digipal.wufoo.com/forms/wf7ewo30714ggj/", "_blank");
  };

  const _onHelp = () => {
    setMenuOpen(false);
    UserActionCreators.showHamburgerMenu(false);
    window.open("https://pizapsupport.zendesk.com", "_blank");
  };

  const handleDeleteAccount = () => {
    EditorActionCreators.showDeleteAccount(true);
    setMenuOpen(false);
  };

  const onProClick = () => {
    setMenuOpen(false);
    if (UserStore.isPro()) {
      fetch(`${process.env.REACT_APP_SERVER_API}/user/managepro`, {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      })
        .then((res) => res.json())
        .then((proResp) => {
          if (proResp.url) {
            window.open(proResp.url, "_blank");
          }
        });
    } else {
      UserActionCreators.showProGet(true);
    }
  };

  const emailEdited = (newEmail) => {
    EditorActionCreators.updateEmail(newEmail);
    UserStore.emitChange();
  };

  const handleSetEmailEdit = () => {
    setIsEmailEdit(true);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) section.scrollIntoView({ behavior: "smooth" });
  };

  const getLoginType = useCallback(() => {
    if (user) {
      if (user?.GooglePlusUserId) {
        return "Google";
      } else if (user?.fbuserId || /^[0-9]*$/.test(user.UserName)) {
        return "Facebook";
      } else if (user?.twitterUserId) {
        return "Twitter";
      } else {
        return "Email";
      }
    }
  }, [user]);

  return (
    <div>
      {data.showProjectDel && (
        <ConfirmProjectDialog
          cancelFunc={() => {
            EditorStore.setShowProjectDel(false);
          }}
          acceptFunc={() => {
            if (data.delObj.cutout) {
              EditorActionCreators.deleteCutout(data.delObj.cutout);
            }
            if (data.delObj.project) {
              EditorActionCreators.deleteProject(data.delObj.project);
            }
            if (data.delObj.photo) {
              EditorActionCreators.deletePhoto(data.delObj.photo);
            }
            if (data.delObj.asset) {
              EditorActionCreators.deleteAsset(data.delObj.asset);
            }
            EditorStore.setShowProjectDel(false);
            setTimeout(() => {
              toast.success("Item Deleted!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Flip,
              });
            }, 50);
          }}
        />
      )}
      <CookieConsent
        location="bottom"
        visible="byCookieValue"
        buttonText="Accept Cookies"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#2B373B",
          fontSize: "18px",
        }}
        buttonStyle={{
          background: "#006dff",
          borderRadius: "4px",
          padding: "10px",
          color: "#FFF",
          fontSize: "18px",
        }}
        expires={365}
        flipButtons
        declineButtonText="Learn More"
        enableDeclineButton
        setDeclineCookie={false}
        hideOnDecline={false}
        declineButtonStyle={{
          color: "#006dff",
          background: "#fff",
          borderRadius: "4px",
          padding: "10px",
          border: "solid #006dff 2px",
          fontSize: "18px",
        }}
        onDecline={() => navigate("/privacypolicy")}
      >
        We use cookies to enhance your experience and analyze site traffic. By
        continuing to use this website, you consent to the use of cookies in
        accordance with our <Link to="/privacypolicy">Cookie Policy</Link>.
      </CookieConsent>
      <nav className="nav-bar">
        <div className="st-header">
          <input type="checkbox" id="drop-down-cbox" />
          <label htmlFor="drop-down-cbox">
            <span></span>
            <span></span>
            <span></span>
          </label>

          <Link
            to={UserStore.getUser() ? "/app/start" : "/"}
            className="site-logo"
          >
            <img
              src="https://static.pizap.com/html5/resources/editor/pizap_logo.svg"
              height={30}
            />
          </Link>

          <ul className={`main-nav small-caps`}>
            {!user && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("drop-down-cbox").checked = false;
                    scrollToSection("photo_editor");
                  }}
                >
                  Photo Editor
                </a>
              </li>
            )}
            {!user && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("drop-down-cbox").checked = false;
                    scrollToSection("design");
                  }}
                >
                  Design
                </a>
              </li>
            )}
            {!user && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("drop-down-cbox").checked = false;
                    scrollToSection("collage");
                  }}
                >
                  Collage
                </a>
              </li>
            )}
            {!user && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("drop-down-cbox").checked = false;
                    scrollToSection("meme_maker");
                  }}
                >
                  Meme Maker
                </a>
              </li>
            )}
            {!user && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("drop-down-cbox").checked = false;
                    scrollToSection("ai_tools");
                  }}
                >
                  A.I. Tools
                </a>
              </li>
            )}
            {!UserStore.isPro() && (
              <li>
                <a
                  onClick={() => {
                    document.getElementById("drop-down-cbox").checked = false;
                    UserActionCreators.showPro(true);
                  }}
                >
                  Plans & Pricing
                </a>
              </li>
            )}
            {user && (
              <li>
                <a
                  href="https://digipal.wufoo.com/forms/wf7ewo30714ggj/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Feedback
                </a>
              </li>
            )}
            {user && (
              <li>
                <a
                  href="https://pizapsupport.zendesk.com/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </li>
            )}
            {user && (
              <li>
                <div>
                  <a
                    href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://static.pizap.com/pizapfiles/app_store_badge_2024.png"
                      alt="App Store"
                      style={{ height: "35px", paddingTop: "18px" }}
                    />
                  </a>
                </div>
              </li>
            )}
            {user && (
              <li>
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://static.pizap.com/pizapfiles/play_store_badge_2024.png"
                      alt="Play Store"
                      style={{ height: "35px", paddingTop: "18px" }}
                    />
                  </a>
                </div>
              </li>
            )}
            {user && (
              <>
                <li>
                  <div className="social-media">
                    <a
                      href="https://www.facebook.com/piZap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-facebook"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="social-media">
                    <a
                      href="https://www.instagram.com/pizap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-instagram"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="social-media">
                    <a
                      href="https://www.twitter.com/piZap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-twitter"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="social-media">
                    <a
                      href="https://www.pinterest.com/pizap"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icofont-pinterest"></i>
                    </a>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
        {user ? (
          <div className="d-flex">
            {!UserStore.isPro() && (
              <div
                className="pro-badge-button d-flex clickable"
                onClick={() => {
                  UserActionCreators.showProGet(true);
                }}
              >
                <ProBadgeSVG />
                Get piZap Pro
              </div>
            )}
            <div ref={ref}>
              <div
                className="profile-image"
                onClick={() => {
                  setMenuOpen((oldState) => !oldState);
                }}
                title="Menu"
              >
                <img
                  src="//cdn.pizap.com/images/pizap_user_icon.png"
                  width={40}
                />
              </div>

              {menuOpen && (
                <div className="hamburgerMenu">
                  {!UserStore.isPro() ? (
                    <div className="proStatus">
                      <span className="proStatus-left" onClick={onProClick}>
                        <ProBadgeSVG />
                        <span style={{ paddingLeft: "15px !important" }}>
                          Try&nbsp;
                        </span>
                        <b style={{ fontWeight: "bold" }}>piZap Pro&nbsp;</b>
                        for free
                      </span>
                      <a nohref="true" onClick={onProClick}>
                        learn more
                      </a>
                    </div>
                  ) : (
                    <div className="proStatus">
                      <span className="proStatus-left">
                        <ProBadgeSVG />
                        <b style={{ fontWeight: "bold", paddingLeft: "15px" }}>
                          piZap Pro
                        </b>
                        <span className="proStatusPro">active</span>
                      </span>
                      <a nohref="true" onClick={onProClick}>
                        manage
                      </a>
                    </div>
                  )}
                  <div className="divide-line"></div>
                  {user && user.UserName && (
                    <div className="hamburgerProfile">
                      <div className="userInfo">
                        <div className="loginType">
                          <div className="label">Login type: </div>
                          {getLoginType()}
                        </div>
                        <div className="accountId">
                          <div className="label">Account ID: </div>
                          {user.UserName}
                        </div>
                        {getLoginType() === "Email" && (
                          <div className="email">
                            <div className="email-left">
                              {!isEmailEdit && (
                                <div className="label">Email: </div>
                              )}
                              <TextEdit
                                text={user.Email}
                                onEditing={true}
                                expanded={isEmailEdit}
                                setEdit={setIsEmailEdit}
                                handleChange={emailEdited}
                              />
                            </div>
                            {!isEmailEdit && (
                              <div
                                className="email-right"
                                onClick={handleSetEmailEdit}
                              >
                                edit
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="divide-line"></div>
                  <div className="profile-public">
                    <span onClick={_reportBug}>
                      Feedback &amp; Suggestions
                    </span>
                    <span onClick={_invite}>Invite Friends on Facebook</span>
                    <Link to="/privacypolicy">
                      <span>Privacy &amp; Terms</span>
                    </Link>
                    {user && user.UserName && (
                      <span onClick={_logout}>Log Out</span>
                    )}
                    <span onClick={_onHelp}>Help</span>
                    <span onClick={handleDeleteAccount}>Delete Account</span>
                  </div>
                  <div className="divide-line"></div>
                  <div className="d-flex app-store-container">
                    <div className="app-store">
                      <a
                        href="https://itunes.apple.com/us/app/pizap/id642936943?mt=8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={AppStoreBadge} alt="App Store" />
                      </a>
                    </div>
                    <div className="app-store">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.digitalpalette.pizap"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={PlayStoreBadge} alt="Play Store" />
                      </a>
                    </div>
                  </div>
                  <div className="d-flex social-media-container">
                    <div className="social-media">
                      <a
                        href="https://www.facebook.com/piZap/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-facebook"></i>
                      </a>
                    </div>
                    <div className="social-media">
                      <a
                        href="https://www.instagram.com/pizap/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-instagram"></i>
                      </a>
                    </div>
                    <div className="social-media">
                      <a
                        href="https://www.twitter.com/piZap/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-twitter"></i>
                      </a>
                    </div>
                    <div className="social-media">
                      <a
                        href="https://www.pinterest.com/pizap"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-pinterest"></i>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
<div className="user-navigation">
  <button onClick={() => window.location.href = "https://www.pizap.com/app/login"}>
    Log In
  </button>
  <button onClick={() => window.location.href = "https://www.pizap.com/app/signup"}>
    Sign Up
  </button>
</div>
        )}
      </nav>
    </div>
  );
};

export default Header;
