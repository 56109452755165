import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { useNavigate } from "react-router-dom";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import "../styles/home.css";
import "../styles/pages.css";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";
import StartButton from "../components/StartButton";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const SpeechBubblesPage = () => {
  const [data, setData] = useState(getStateFromStores);
  const navigate = useNavigate();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
    }

    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const handlePizap = () => {
    navigate("/app/start");
  };

  return (
    <>
      <HelmetDetails
        title="Speech Bubbles on Photos | Add Text Bubbles to Pics"
        description="Add speech bubbles and text bubbles to photos. Also use speech balloons and thought bubbles."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/speech_bubbles_photo_app"
        keywords="speech bubbles app, text bubbles to pictures, text and thought balloons"
      />
      <Header />
      <>
        <div className="img-hover-container mt-40">
          <p className="title">Add Speech and Thought Bubbles to Photos</p>
          <p className="desc">
            Make your pictures say more than a thousand words with text bubbles
            that pop
          </p>
          <StartButton
            text="Edit a Photo →"
            handleClick={() => {
              navigate("/app/?type=design");
            }}
          />
        </div>
        <div className="dashboard-image">
          <img
            src="//cdn.pizap.com/pizapfiles/images/speech_bubbles_photo_editor_app.jpg"
            alt="main-image"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/speech_bubbles_photo_editor_app01.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Speech and Thought Bubbles</span>
            <span className="desc">
              Looking for a way to add speech bubbles to your photos? piZap
              makes it easy to add text bubbles to your photos. Plus, there are
              hundreds of fonts and text effects to choose from.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">Speech Bubble Tool Overview</span>
            <span className="desc">
              Click and drag your speech bubble wherever you want to place it on
              your picture. Using the same editing tool, pick from hundreds of
              fonts and text effects to insert inside of the speaking bubble.
              Make your speech bubble any size you want. piZap’s editing tools
              include the option to add stickers, clip art, custom Emojis and
              more. Create clever comics or heartfelt cards, piZap makes it easy
              to let your imagination run wild.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/speech_bubbles_photo_editor_app02.jpg"
            className="section-image"
            alt="section"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/speech_bubbles_photo_editor_app03.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">Easily Add Text Bubbles</span>
            <span className="desc">
              piZap makes it easy to add speech bubbles to your favorite
              pictures, photo collages and more. Adding text bubbles doesn’t
              have to be complicated, not after you download piZap to your
              mobile device or desktop computer. Our goal was to create an app
              that’s easy to use without slacking off in terms of functionality
              or advanced features. As a result, piZap lets everyone enjoy many
              of the same features professional editors employ. Try piZap today
              and you’ll feel like a pro in minutes.
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>

        <div className="section reverse">
          <div className="section-description">
            <span className="title">The Fun Way to Add Speech Bubbles</span>
            <span className="desc">
              Make a comic bubble to spark some laughs, or make a sweet photo
              even more sentimental by adding a conversation bubble that
              perfectly represents the moment. Have a jolly good time creating
              photos with speech bubbles and then have even more fun sharing
              them with friends and family. You can save photos using piZap’s
              FREE storage space. Enjoy the ease of sharing your photos to
              popular social media networks from directly in the app. Did we
              mention piZap has a MEME making tool? I mean, what’s more fun than
              adding speech bubbles to MEMEs?!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
          <img
            src="//cdn.pizap.com/pizapfiles/images/speech_bubbles_photo_editor_app04.jpg"
            className="section-image"
            alt="piZap photo editor app"
          />
        </div>

        <div className="section">
          <img
            src="//cdn.pizap.com/pizapfiles/images/speech_bubbles_photo_editor_app05.jpg"
            className="section-image"
            alt="section"
          />
          <div className="section-description">
            <span className="title">
              Add Speech Bubbles to Photos + So Much More
            </span>
            <span className="desc">
              The Add Speech Bubbles Tool is just one of many ways to have fun
              editing your photos to perfection. piZap offers templates and
              tools to create flyers, cards, MEMEs and posters too. Easily add
              speech bubbles to any of your creations for greater impact. There
              are also design tools that allow users of all experience levels to
              make custom graphic designs and logos. piZap’s awesome tools and
              features are virtually endless. But the best part of all is you
              can try them all for free!
            </span>
            <StartButton
              text="Try piZap for free →"
              handleClick={handlePizap}
            />
          </div>
        </div>
      </>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default SpeechBubblesPage;
