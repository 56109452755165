import React, { useEffect, useRef, useState } from "react";
import AccordionCategory from "./Accordion.Category.react";
import AssetStore from "../stores/AssetStore";

function getStateFromStores() {
  return {
    selectedCategory: AssetStore.getSelectedItem(),
  };
}

const Accordion = (props) => {
  const [data, setData] = useState(getStateFromStores());
  const myRef = useRef();

  useEffect(() => {
    AssetStore.addChangeListener(_onChange);
    if (myRef && myRef.current) {
      let node = myRef.current;
      if (node && node.parentNode) {
        node.parentNode.scrollTop = 0;
      }
    }

    return () => {
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const _onChange = () => {
    setData(getStateFromStores());
  };

  const _onCategoryClick = (category) => {
    props.onSelected(category);
  };

  return (
    <div ref={myRef} className="accordion">
      {props.categories.map((category, index) => {
        if (
          data.selectedCategory &&
          category.Name === data.selectedCategory.Name &&
          category.Categories.length > 1
        ) {
          return (
            <AccordionCategory
              category={category}
              onSelected={_onCategoryClick}
              expanded={true}
              key={index}
            />
          );
        } else {
          if (category.Categories && category.Categories.length > 1) {
            for (let x = 0; x < category.Categories.length; x++) {
              const cat = category.Categories[x];
              if (
                data.selectedCategory &&
                cat.Name === data.selectedCategory.Name
              ) {
                return (
                  <AccordionCategory
                    category={category}
                    onSelected={_onCategoryClick}
                    expanded={true}
                    key={category.Name}
                  />
                );
              }
            }
          }
          return (
            <AccordionCategory
              category={category}
              onSelected={_onCategoryClick}
              key={category.Name}
            />
          );
        }
      })}
    </div>
  );
};

export default Accordion;
