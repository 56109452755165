import React, { useEffect } from "react";
import EditorActionCreators from "../actions/EditorActionCreators";
import UserStore from "../stores/UserStore";
import EditorStore from "../stores/EditorStore";
import UserActionCreators from "../actions/UserActionCreators";
import ProCrown from "./ProCrown.react";

const MenuDesignItem = (props) => {
  const isPro = UserStore.isPro();

  const onItemSelected = (event) => {
    if (props.item.isProOnly && !isPro) {
      UserActionCreators.showProGet(true);
    } else {
      EditorActionCreators.ShowFilePicker(false);
      switch (props.type) {
        case "sticker":
          window.editor.addSticker(
            props.item.src,
            null,
            null,
            function (sticker) {
              EditorStore.setShowSpinner(false);
            }
          );
          break;
        case "effect":
          window.editor.addSticker(
            props.item.src,
            null,
            null,
            function (sticker) {
              EditorStore.setShowSpinner(false);
            }
          );
          break;
        case "border":
          window.editor.addBorder(props.item.src, false);
          break;
        case "design":
          window.editor.addDesign(props.item, () => {
            EditorStore.setShowSpinner(false);
            window.editor.stickerSelector.updateSelector();
          });
          break;
        case "collage":
          window.editor.initCollage(props.item);
          break;
        case "background":
        case "facebook":
          if (EditorStore.getFilePickerTool() === "profile") {
            window.editor.addPicture(props.item.icon);
          } else {
            if (props.item.proSource && UserStore.isPro()) {
              window.editor.addPicture(props.item.proSource);
            } else {
              window.editor.addPicture(props.item.src);
            }
          }
          break;
        case "cutout":
        case "projectAsset":
          window.editor.addPicture(props.item.src);
          break;
        case "shape":
          EditorActionCreators.CutoutMode("shape-selected");
          window.editor.addShape(props.item.src);
          break;
        default:
      }
    }
  };

  const dragStart = (event) => {
    if (props.item.isProOnly && !isPro) {
      event.preventDefault();
    } else {
      switch (props.type) {
        case "sticker":
          event.dataTransfer.setData("text", props.item.src);
          break;
        default:
          event.preventDefault();
      }
    }
  };

  const cancelDrag = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="photopicker-photo-item clickable"
      data-id={props.item.key}
      data-src={
        props.item.proSource && isPro ? props.item.proSource : props.item.src
      }
      onClick={onItemSelected}
    >
      <img
        src={props.item.icon}
        draggable="true"
        onDragStart={dragStart}
        alt="item"
      />
      <span className="name">{props.item.name}</span>
      {props.item.isProOnly && !isPro && <ProCrown key={props.item.key} />}
    </div>
  );
};

export default MenuDesignItem;
