import React, { useState, useEffect, useRef } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import ColorPicker from "../ColorPicker.react";
import EditorStore from "../../stores/EditorStore";

function getStateFromStores() {
  return {
    checkboxState:
      window.editor.getBaseObject() &&
      window.editor.getBaseObject().transparent,
    backgroundColor:
      window.editor.getBaseObject() && window.editor.getBaseObject().fillColor
        ? window.editor.getBaseObject().fillColor
        : 0xffffff,
  };
}

const RangeSlider = (props) => {
  const [storesData, setStoresData] = useState(getStateFromStores());
  const isMounted = useRef(true);
  const {
    title,
    min,
    max,
    step,
    value,
    handleChange,
    isReset,
    handleReset,
    isBox,
    name,
    handleMouseEnter,
    handleMouseLeave,
    startPoint,
  } = props;

  const colorPicked = (colorCode) => {
    const _colorCode = parseInt(colorCode.replace("#", "0x"), 16);
    let baseObject = window.editor.getBaseObject();
    baseObject.clear();
    baseObject.beginFill(colorCode.replace("#", "0x"));
    baseObject.drawRect(0, 0, baseObject._width, baseObject._height);
    baseObject.endFill();
    baseObject.fillColor = _colorCode;
    setStoresData(getStateFromStores());
  };

  useEffect(() => {
    EditorStore.addChangeListener(_onChange);
    return () => {
      EditorStore.removeChangeListener(_onChange);
      // Indicate that the component has unmounted
      isMounted.current = false;
    };
  }, []);

  const _onChange = () => {
    if (isMounted.current) {
      setStoresData(getStateFromStores());
    }
  };

  return (
    <div className="range-slider">
      <div className="space-between">
        <span className="title">{title}</span>
        {isReset ? (
          <span className="reset clickable" onClick={() => handleReset()}>
            reset
          </span>
        ) : null}
      </div>
      <div className="space-between">
        <Slider
          onBeforeChange={handleMouseEnter}
          onChangeComplete={handleMouseLeave}
          className={name ? name : "input-range-slider"}
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          startPoint={startPoint}
          value={value}
        />
        {isBox && (
          <ColorPicker
            text=""
            onColor={colorPicked}
            initialColor={
              storesData.checkboxState ? 0x586979 : storesData.backgroundColor
            }
          />
        )}
      </div>
    </div>
  );
};

export default RangeSlider;
