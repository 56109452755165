import EditorDispatcher from "../dispatcher/EditorDispatcher";
import ActionTypes from "../constants/EditorConstants";

export default {
  PropertiesMinified: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_PROPERTIES_MINIFIED,
      value: value,
    });
  },
  InitPhoto: function (url) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_INIT_PHOTO,
      value: url,
    });
  },
  RemoveSticker: function (sticker) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_REMOVE_STICKER,
      value: sticker,
    });
  },
  ColorPickerPreviewClicked: function (colorCode, callback) {
    EditorDispatcher.dispatch({
      type: ActionTypes.COLOR_PICKER_PREVIEW,
      colorCode: colorCode,
      callback: callback,
    });
  },
  ColorPickerShow: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_COLOR_PICKER,
      value: value,
    });
  },
  CopySticker: function (sticker) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_COPY_STICKER,
      value: sticker,
    });
  },
  MoveFront: function (sticker) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_MOVE_FRONT,
      value: sticker,
    });
  },
  MoveForward: function (sticker) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_MOVE_FORWARD,
      value: sticker,
    });
  },
  MoveBack: function (sticker) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_MOVE_BACK,
      value: sticker,
    });
  },
  MoveBackward: function (sticker) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_MOVE_BACKWARD,
      value: sticker,
    });
  },
  PaintChangeColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PAINT_CHANGE_COLOR,
      value: color,
    });
  },
  TouchupBrushSize: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TOUCHUP_BRUSH_SIZE,
      value: size,
    });
  },
  TouchupFade: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TOUCHUP_FADE,
      value: size,
    });
  },
  showTagDialog: function (props) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_TAG_DIALOG,
      value: props,
    });
  },
  addTag: function (tag) {
    EditorDispatcher.dispatch({
      type: ActionTypes.ADD_TAG,
      value: tag,
    });
  },
  deleteTag: function (index) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_TAG,
      value: index,
    });
  },
  TouchupColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TOUCHUP_COLOR,
      value: color,
    });
  },
  TouchupHardness: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TOUCHUP_HARDNESS,
      value: size,
    });
  },
  PaintBrushSize: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PAINT_BRUSH_SIZE,
      value: size,
    });
  },
  PaintAlpha: function (val) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PAINT_ALPHA,
      value: val,
    });
  },
  PaintBlur: function (blur) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PAINT_BLUR,
      value: blur,
    });
  },
  PaintMode: function (mode) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PAINT_MODE,
      value: mode,
    });
  },
  GlowSliderChange: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_GLOW_SLIDER_VAL,
      value: size,
    });
  },
  // GlowDistanceSliderChange: function (size) {
  //   EditorDispatcher.dispatch({
  //     type: ActionTypes.TEXT_GLOW_DISTANCE_SLIDER_VAL,
  //     value: size,
  //   });
  // },
  GraphicGlowSliderChange: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_GLOW_SLIDER_VAL,
      value: size,
    });
  },
  OutlineSliderChange: function (size) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_OUTLINE_SLIDER_VAL,
      value: size,
    });
  },
  DropShadowDistanceSliderChange: function (distance) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_DROPSHADOW_DISTANCE_SLIDER_VAL,
      value: distance,
    });
  },
  GraphicDropShadowDistanceSliderChange: function (distance) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_DROPSHADOW_DISTANCE_SLIDER_VAL,
      value: distance,
    });
  },
  DropShadowAlphaSliderChange: function (alpha) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_DROPSHADOW_ALPHA_SLIDER_VAL,
      value: alpha,
    });
  },
  GraphicDropShadowAlphaSliderChange: function (alpha) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_DROPSHADOW_ALPHA_SLIDER_VAL,
      value: alpha,
    });
  },
  DropShadowBlurSliderChange: function (blur) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_DROPSHADOW_BLUR_SLIDER_VAL,
      value: blur,
    });
  },
  GraphicDropShadowBlurSliderChange: function (blur) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_DROPSHADOW_BLUR_SLIDER_VAL,
      value: blur,
    });
  },
  DropShadowAngleSliderChange: function (angle) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_DROPSHADOW_ANGLE_SLIDER_VAL,
      value: angle,
    });
  },
  GraphicDropShadowAngleSliderChange: function (angle) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_DROPSHADOW_ANGLE_SLIDER_VAL,
      value: angle,
    });
  },
  FontSize: function (size, isManual) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_FONT_SIZE,
      value: size,
      isManual: isManual,
    });
  },
  LetterSpacing: function (spacing) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_SPACING,
      value: spacing,
    });
  },
  TextCurve: function (curve) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_CURVE,
      value: curve,
    });
  },
  LineHeight: function (lineHeight) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_LINE_HEIGHT,
      value: lineHeight,
    });
  },
  FontColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_FONT_COLOR,
      value: color,
    });
  },
  GlowColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_GLOW_COLOR,
      value: color,
    });
  },
  GraphicGlowColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_GLOW_COLOR,
      value: color,
    });
  },
  OutlineColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_OUTLINE_COLOR,
      value: color,
    });
  },
  BackgroundColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_BACKGROUND_COLOR,
      value: color,
    });
  },
  DropShadowColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_DROPSHADOW_COLOR,
      value: color,
    });
  },
  GraphicDropShadowColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_DROPSHADOW_COLOR,
      value: color,
    });
  },
  Font: function (font) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_FONT,
      value: font,
    });
  },
  TextChange: function (text) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_CHANGE,
      value: text,
    });
  },
  GlowEnable: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GLOW_ENABLED,
      value: value,
    });
  },
  GraphicGlowEnable: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_GLOW_ENABLED,
      value: value,
    });
  },
  OutlineEnable: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.OUTLINE_ENABLED,
      value: value,
    });
  },
  GraphicOutlineEnable: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_OUTLINE_ENABLED,
      value: value,
    });
  },
  BackgroundEnable: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.BACKGROUND_ENABLED,
      value: value,
    });
  },
  DropShadowEnabled: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DROPSHADOW_ENABLED,
      value: value,
    });
  },
  GraphicDropShadowEnabled: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.GRAPHIC_DROPSHADOW_ENABLED,
      value: value,
    });
  },
  TextAlign: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_ALIGN,
      value: value,
    });
  },
  TextBold: function () {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_BOLD,
    });
  },
  TextItalic: function () {
    EditorDispatcher.dispatch({
      type: ActionTypes.TEXT_ITALIC,
    });
  },
  CutoutMode: function (mode) {
    EditorDispatcher.dispatch({
      type: ActionTypes.CUTOUT_MODE,
      value: mode,
    });
  },
  CutoutView: function (view) {
    EditorDispatcher.dispatch({
      type: ActionTypes.CUTOUT_VIEW,
      value: view,
    });
  },
  EditorMode: function (mode) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_MODE,
      value: mode,
    });
  },
  ModifyImageSettings: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_IMAGE_SETTINGS,
      value: value,
    });
  },
  ModifyHotspotSettings: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.EDITOR_HOTSPOT_SETTINGS,
      value: value,
    });
  },
  Save: function (name, quality, extension, width, height) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SAVE,
      value: quality,
      name: name,
      width: width,
      height: height,
      extension: extension,
    });
  },
  ShowSpinner: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_SPINNER,
      value: value,
    });
  },
  ShowStickerLoadError: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_STICKER_ERROR,
      value: value,
    });
  },
  ShowUsageLimitModal: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_USAGE_LIMIT,
      value: value,
    });
  },
  ShowSaveQuality: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_SAVE_QUALITY,
      data: value,
    });
  },
  ShowFilePicker: function (value, tool) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_FILE_PICKER,
      value: value,
      tool: tool,
    });
  },
  ShowCropPicker: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CROP_PICKER,
      value: value,
    });
  },
  ShowAIError: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_AI_ERROR,
      data: value,
    });
  },
  ShowContextMenu: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CONTEXT_MENU,
      data: value,
    });
  },
  SetPickerState: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PICKER_STATE,
      value: value,
    });
  },
  ShowEmbed: function (value, cloudKey) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_EMBED,
      data: value,
      key: cloudKey,
    });
  },
  ShowEmail: function (value, cloudKey) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_EMAIL,
      data: value,
      key: cloudKey,
    });
  },
  ShowLink: function (value, cloudKey) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_LINK,
      data: value,
      key: cloudKey,
    });
  },
  CollageHotspotSelected: function (hotspot) {
    EditorDispatcher.dispatch({
      type: ActionTypes.COLLAGE_HOTSPOT_SELECTED,
      hotspot: hotspot,
    });
  },
  CollageHotspotActiveSelected: function (hotspot) {
    EditorDispatcher.dispatch({
      type: ActionTypes.COLLAGE_HOTSPOT_ACTIVE_SELECTED,
      hotspot: hotspot,
    });
  },
  ShowPropertiesCollage: function (value, hotspot) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_PROPERTIES_COLLAGE,
      data: value,
      hotspot: hotspot,
    });
  },
  ShowCutoutInstructions: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CUTOUT_INSTRUCTIONS,
      data: value,
    });
  },
  ShowCutoutStyle: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CUTOUT_STYLE,
      data: value,
    });
  },
  ShowGraphicCutoutStyle: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_GRAPHIC_CUTOUT_STYLE,
      data: value,
    });
  },
  ShowCutoutRefine: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CUTOUT_REFINE,
      data: value,
    });
  },
  ShowFeedback: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_FEEDBACK,
      data: value,
    });
  },
  ShowCutoutTrace: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CUTOUT_TRACE,
      data: value,
    });
  },
  ShowDesignPicker: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_DESIGN_PICKER,
      data: value,
    });
  },
  ShowCollagePicker: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_COLLAGE_PICKER,
      data: value,
    });
  },
  CustomWidth: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.CUSTOM_BORDER_WIDTH,
      data: value,
    });
  },
  CustomColor: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.CUSTOM_BORDER_COLOR,
      data: value,
    });
  },
  showCustomSize: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CUSTOM_SIZE,
      data: value,
    });
  },
  ShowCutoutZoom: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_CUTOUT_ZOOM,
      data: value,
    });
  },
  ShowAccount: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_ACCOUNT,
      data: value,
    });
  },
  changeProfilePhoto: function (url) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PROFILE_PHOTO,
      data: url,
    });
  },
  updateEmail: function (email) {
    EditorDispatcher.dispatch({
      type: ActionTypes.UPDATE_EMAIL,
      data: email,
    });
  },
  createFolder: function (folder) {
    EditorDispatcher.dispatch({
      type: ActionTypes.CREATE_FOLDER,
      data: folder,
    });
  },
  deleteCutout: function (cutout) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_CUTOUT,
      data: cutout,
    });
  },
  deleteProject: function (project) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_PROJECT,
      data: project,
    });
  },
  deleteFolder: function (folder) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_FOLDER,
      data: folder,
    });
  },
  duplicateProject: function (project) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DUPLICATE_PROJECT,
      data: project,
    });
  },
  deletePhoto: function (photo) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_PHOTO,
      data: photo,
    });
  },
  deleteAsset: function (photo) {
    EditorDispatcher.dispatch({
      type: ActionTypes.DELETE_ASSET,
      data: photo,
    });
  },
  moveCutout: function (cutout) {
    EditorDispatcher.dispatch({
      type: ActionTypes.MOVE_CUTOUT,
      data: cutout,
    });
  },
  moveProject: function (project) {
    EditorDispatcher.dispatch({
      type: ActionTypes.MOVE_PROJECT,
      data: project,
    });
  },
  movePhoto: function (photo) {
    EditorDispatcher.dispatch({
      type: ActionTypes.MOVE_PHOTO,
      data: photo,
    });
  },
  projectTitleChange: function (title) {
    EditorDispatcher.dispatch({
      type: ActionTypes.PROJECT_TITLE_CHANGE,
      data: title,
    });
  },
  updateFolder: function (folder) {
    EditorDispatcher.dispatch({
      type: ActionTypes.UPDATE_FOLDER,
      data: folder,
    });
  },
  nameUpdated: function (newName) {
    EditorDispatcher.dispatch({
      type: ActionTypes.UPDATE_NAME,
      data: newName,
    });
  },
  removeBackground: function (url) {
    EditorDispatcher.dispatch({
      type: ActionTypes.REMOVE_BACKGROUND,
      data: url,
    });
  },
  updateBlankColor: function (color) {
    EditorDispatcher.dispatch({
      type: ActionTypes.UPDATE_BLACK_COLOR,
      data: color,
    });
  },
  showDeleteAccount: function (value) {
    EditorDispatcher.dispatch({
      type: ActionTypes.SHOW_DELETE_ACCOUNT,
      data: value,
    });
  },
};
